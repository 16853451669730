import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"

import DefaultIfNone from "components/Common/default"
import { Link } from "react-router-dom"

function BarCard(props) {
  const { branch, period, data } = props
  return (
    <React.Fragment>
      <Row>
        <Col lg="12" className="align-self-center">
          <Card>
            <CardBody>
              <div className="text-lg-center mt-4 mt-lg-0">
                <Row>
                  <Col xs="3">
                    <Link to={`/groups/${data.uid}/${data.name}`}>
                      <div>
                        <p className="text-muted text-truncate mb-2">
                          Groups
                        </p>
                        <h5 className="mb-1"><DefaultIfNone format={true} value={data.groups && data.groups.length} default_value={0} /></h5>
                      </div>
                    </Link>
                  </Col>
                  <Col xs="3">
                    <Link to={{pathname: "/users", search: `?branch=${branch}`}}>
                      <div>
                        <p className="text-muted text-truncate mb-2">
                          Agents
                        </p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.branch_agents} default_value={0} /></h5>
                      </div>
                    </Link>
                  </Col>
                  <Col xs="3">
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        Well performers
                      </p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.well_performers} default_value={0} /></h5>
                    </div>
                  </Col>
                  <Col xs="3">
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        Under performers
                      </p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.under_performers} default_value={0} /></h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BarCard
