export const KPI_LIST = "KPI_LIST"
export const KPI_LIST_SUCCESS = "KPI_LIST_SUCCESS"
export const KPI_LIST_FAIL = "KPI_LIST_FAIL"

export const KPI_CREATE = "KPI_CREATE"
export const KPI_CREATE_SUCCESS = "KPI_CREATE_SUCCESS"
export const KPI_CREATE_FAIL = "KPI_CREATE_FAIL"

export const KPI_UPDATE = "KPI_UPDATE"
export const KPI_UPDATE_SUCCESS = "KPI_UPDATE_SUCCESS"
export const KPI_UPDATE_FAIL = "KPI_UPDATE_FAIL"

export const KPI_DELETE = "KPI_DELETE"
export const KPI_DELETE_SUCCESS = "KPI_DELETE_SUCCESS"
export const KPI_DELETE_FAIL = "KPI_DELETE_FAIL"

