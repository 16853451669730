import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER_VERIFICATION,
  REGISTER_VERIFICATION_SUCCESSFUL,
  REGISTER_VERIFICATION_FAILED,
  ADD_USER,
  ADD_USER_SUCCESSFUL,
  ADD_USER_FAILED,
  MANUALSIGNUP_ACTIVATE,
  MANUALSIGNUP_ACTIVATE_SUCCESSFUL,
  MANUALSIGNUP_ACTIVATE_FAILED,
  MANUALSIGNUP_VERIFY,
  MANUALSIGNUP_VERIFY_SUCCESSFUL,
  MANUALSIGNUP_VERIFY_FAILED,
  RESET_STATE_ACCOUNT,
} from "./actionTypes"


export const resetAccount = () => ({
  type: RESET_STATE_ACCOUNT,
})

export const registerVerification = (data, key = null) => {
  return {
    type: REGISTER_VERIFICATION,
    payload: { data },
    key
  }
}
export const registerVerificationSuccessful = (data, key) => {
  return {
    type: REGISTER_VERIFICATION_SUCCESSFUL,
    payload: data,
    key: key
  }
}
export const registerVerificationFailed = (error, key) => {
  return {
    type: REGISTER_VERIFICATION_FAILED,
    payload: error,
    key: key
  }
}

export const registerUser = user => {
  return {
    type: REGISTER_USER,
    payload: { user },
  }
}
export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}
export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const addUser = user => {
  return {
    type: ADD_USER,
    payload: { user },
  }
}
export const addUserSuccessful = user => {
  return {
    type: ADD_USER_SUCCESSFUL,
    payload: user,
  }
}
export const addUserFailed = user => {
  return {
    type: ADD_USER_FAILED,
    payload: user,
  }
}


export const manualSignupActLinkVerify = token => {
  return {
    type: MANUALSIGNUP_VERIFY,
    payload: { token },
  }
}
export const manualSignupActLinkVerifySuccessful = data => {
  return {
    type: MANUALSIGNUP_VERIFY_SUCCESSFUL,
    payload: data,
  }
}
export const manualSignupActLinkVerifyFailed = error => {
  return {
    type: MANUALSIGNUP_VERIFY_FAILED,
    payload: error,
  }
}

export const manualSignupActivation = payload => {
  return {
    type: MANUALSIGNUP_ACTIVATE,
    payload: { payload },
  }
}
export const manualSignupActivationSuccessful = data => {
  return {
    type: MANUALSIGNUP_ACTIVATE_SUCCESSFUL,
    payload: data,
  }
}
export const manualSignupActivationFailed = error => {
  return {
    type: MANUALSIGNUP_ACTIVATE_FAILED,
    payload: error,
  }
}