import React, { useRef, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Dropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner, UncontrolledAlert, Offcanvas, OffcanvasHeader, OffcanvasBody, Table, ButtonGroup, Label } from "reactstrap"
import { deleteHms, getHms, getHmsOptions, postHms, postUploadHms, reportDownloadHms, resetHmsState, updateHms } from "store/actions";
import { cleanFilenameForSign, formatDateTime, paymentVerboseFetch, referenceTypeVerboseName, TableChip, visitModeVerboseName } from "components/Common/common";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FileUploader, HMSLink, Loader, PatientInformation, handlePhonenumberFocus } from "pages/HMS/common/common";
import toastr from "toastr"
import { AvForm } from "availity-reactstrap-validation";
import { EAvFieldInput, EAvFieldMask, EAvFieldNumber, EAvFieldSelect, ETextEditorInput, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields";
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import Flatpickr from "react-flatpickr"
import PreviewFile from "pages/HMS/common/preview-file";
import moment from "moment";
import { DownloadModal, HmsDeleteModal } from "pages/HMS/common/hms-crud-modals";

const OutpatientDetail = (props) => {
  const dispatch = useDispatch();
  const dateRef = useRef()
  const [patient, setPatient] = useState({});
  const [dropdown, setDropdown] = useState(false);
  const [submenu, setSubmenu] = useState(false);
  const [modals, setModals] = useState({
    opDownloadModal: false,
    digitalOpDownloadModal: false,
    prescriptionsModal: false,
    configureModal: false,
    medicineModal: false,
    previewModal: false,
    downloadInvoiceModal: false,
    deleteModal: false,
  })
  const [isLoading, setIsLoading] = useState(false);
  const [saveFlag, setSaveFlag] = useState(true);
  const [isShowUpload, setIsShowUpload] = useState(true)
  const [selectedFiles, setSelectedFiles] = useState({})
  const [signedLinks, setSignedLinks] = useState([])
  const [medicines, setMedicines] = useState([]);
  const [dosage, setDosage] = useState(null);
  const [dosageError, setDosageError] = useState('');
  const [editorStates, setEditorStates] = useState({
    complaints: EditorState.createEmpty(),
    previous_health_history: EditorState.createEmpty(),
    diagnosis: EditorState.createEmpty(),
    investigations: EditorState.createEmpty(),
    advises: EditorState.createEmpty(),
  });

  const { loading, error, hmsObject, apiKey, updateResponse, signedURLs, formError, options } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    updateResponse: state.Hms.updateResponse,
    signedURLs: state.Hms.signedURLs,
    formError: state.Hms.formError,
    options: state.Hms.options,
  }));

  useEffect(() => {
    if (apiKey === "op_details" && !isEmpty(hmsObject)) {
      setPatient(hmsObject);
      setSignedLinks(hmsObject?.raw_media || []);
    }
    if (apiKey === "op_report_download") {
      toggleViewModal('opDownloadModal')
    }
    if (apiKey === "download_invoice") {
      toggleViewModal('downloadInvoiceModal')
    }
    if (apiKey === "upload_op_prescription") {
      setIsShowUpload(false);
      setSaveFlag(false);
    }
    if (apiKey === "op_update") {
      toastr.success("Prescription updated successfully")
      toggleViewModal('prescriptionsModal')
      setSignedLinks(updateResponse?.raw_media);
      setIsShowUpload(true);
    }
    if (apiKey === "outpatients_update") {
      toastr.success("Prescription saved")
      toggleViewModal('configureModal')
      setPatient((prevVal) => ({
        ...prevVal,
        prescription_existed: true,
      }));
    }
    if (apiKey === "op_prescription") {
      const fields = ["complaints", "previous_health_history", "diagnosis", "investigations", "advises"];
      const editorStates = {};

      fields.forEach(field => {
        const contentHTML = hmsObject?.prescription?.[field];
        if (contentHTML !== undefined && contentHTML !== null) {
          const editorState = stateFromHTML(contentHTML);
          editorStates[field] = EditorState.createWithContent(editorState);
        } else {
          editorStates[field] = EditorState.createEmpty();
        }
      });

      const medicines = hmsObject?.prescription?.medicines;
      if (medicines !== undefined) {
        setMedicines(medicines);
      }

      setEditorStates(editorStates);
    }
    if (apiKey === "download_digital_report") {
      toggleViewModal('digitalOpDownloadModal')
    }
    if (apiKey === "next_visit" || apiKey === "clear_next_visit") {
      setPatient((prevVal) => ({
        ...prevVal,
        next_visit: updateResponse.next_visit,
      }));

      if (apiKey === "clear_next_visit") {
        dateRef?.current?.flatpickr?.clear();
      }
    }
    if (apiKey === "outpatient_detail_deleted") {
      toastr.success(`Outpatient is deleted`)
      setPatient({})
      toggleDelete()
      window.location.replace("/hms/patient/ops")
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/outpatients/?options=formschema", "fetch_op_options"))
    if (props.match.params.opID) dispatch(getHms("/hms/patient/outpatients/", props.match.params.opID, "op_details"))
    return () => {
      dispatch(resetHmsState());
    };
  }, []);

  const downloadReport = () => {
    if (patient.id) {
      dispatch(reportDownloadHms(`/hms/patient/outpatients/${patient.id}/report_prescription/`, "op_report_download"))
    }
  }

  const downloadInvoice = () => {
    if (patient.id) {
      dispatch(reportDownloadHms(`/hms/patient/outpatients/${patient.id}/invoice_receipt/`, "download_invoice"))
    }
  }

  useEffect(() => {
    if (modals.configureModal) dispatch(getHms(`/hms/patient/outpatients/${props.match.params.opID}/op_prescription/`, null, "op_prescription"))
  }, [modals.configureModal])

  const toggleViewModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  const handleAcceptedFiles = (files) => {
    let filenames = []
    let callBucket = {}
    files.map(file => {
      let uid = Date.now() + Math.floor(Math.random() * 1000)
      let filename = cleanFilenameForSign(file.name)
      filenames.push({ uid: uid, filename: filename })
      callBucket[uid] = file
    })
    setSelectedFiles((prevFiles) => ({ ...prevFiles, ...callBucket }))
    let payload = { filenames: filenames, target: "op_prescription" }
    dispatch(postUploadHms("/hms/uploader/upload/", payload, "upload_op_prescription"))
  }

  useEffect(() => {
    if (!isEmpty(signedURLs)) {
      setIsLoading(true)
      let filenames = []
      Promise.all(signedURLs.map(signedURL => fetch(signedURL.safelink, {
        method: 'PUT',
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: selectedFiles[signedURL.uid]
      }))).then(responses => {
        responses.map(response => {
          if (response.status === 200) {
            filenames.push(response.url)
          }
        })
        if (!isEmpty(filenames)) {
          filenames.map((filename) => {
            setSignedLinks((prevVal) => ([
              ...prevVal,
              {
                uid: Date.now() + Math.floor(Math.random() * 1000),
                file_path: filename.match(/hms\/[^?]+/)[0],
                file_name: filename.match(/\/media\/(.*?)\?X/)[1]
              }
            ]));
          });
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, [signedURLs])

  const previewFile = (url, fileName) => {
    dispatch(postHms("/hms/uploader/download/", { [fileName]: url }, "download_file", false))
  };

  const downloadDigitalReport = (id) => {
    if (id) {
      dispatch(getHms(`/hms/patient/outpatients/${patient.id}/report_digital_prescription/`, null, "download_digital_report"))
    }
  }

  const handleAddMedicine = (event, medicine) => {
    if (!dosage || dosage === "_-_-_") {
      setDosageError('This field is invalid');
      return;
    }
    setDosageError('');

    medicine = { ...medicine, dosage: dosage }
    const isMedicineExists = medicines.some((med) => med.name === medicine.name);
    if (isMedicineExists) {
      toastr.error('Medicine is already added!');
    } else {
      setMedicines((prevVal) => [...prevVal, medicine]);
      toggleViewModal('medicineModal');
    }
  };

  const removeCartItem = (name) => {
    const filtered = medicines.filter((item) => item.name !== name);
    setMedicines(filtered);
  };

  const handleSubmit = () => {
    let values = {
      parent: patient?.parent,
      prescription: {
        complaints: editorStates?.complaints ? stateToHTML(editorStates?.complaints?.getCurrentContent()) : "",
        previous_health_history: editorStates?.previous_health_history ? stateToHTML(editorStates?.previous_health_history?.getCurrentContent()) : "",
        diagnosis: editorStates?.diagnosis ? stateToHTML(editorStates?.diagnosis?.getCurrentContent()) : "",
        investigations: editorStates?.investigations ? stateToHTML(editorStates?.investigations?.getCurrentContent()) : "",
        advises: editorStates?.advises ? stateToHTML(editorStates?.advises?.getCurrentContent()) : "",
        medicines: medicines,
      },
    }

    dispatch(updateHms("/hms/patient/outpatients/", patient?.id, values, "outpatients_update"))
  }

  const handleEditorStateChange = (key, newState) => {
    setEditorStates(prevStates => ({
      ...prevStates,
      [key]: newState,
    }));
  };

  const onChangeDate = (selectedDates, dateStr) => {
    if (!isEmpty(dateStr)) {
      dispatch(updateHms("/hms/patient/outpatients/", patient?.id, { next_visit: dateStr }, "next_visit"))
    }
  }

  const clearDate = () => {
    dispatch(updateHms("/hms/patient/outpatients/", patient?.id, { next_visit: null }, "clear_next_visit"))
  }

  const toggleDelete = (def = modals.deleteModal) => {
    setModals(prevModals => ({ ...prevModals, deleteModal: !def }))
  }

  const handleDelete = () => {
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {patient?.patient_data?.user_data?.full_name && `${patient.patient_data.user_data.full_name} |`} Yanthura
          </title>
        </MetaTags>
        <Card>
          <CardBody>
            {loading && apiKey === "options_fetch_op_options" || apiKey === "get_op_details" ? (
              <Loader />
            ) : error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <>
                <Row>
                  <Col>
                    <div className="mt-2 d-flex justify-content-between">
                      <h5>Patient information</h5>
                      <div className="text-sm-end ms-2">
                        <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                          <DropdownToggle caret color="primary" size="sm">
                            Actions &nbsp;<i className="fa fa-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link className="dropdown-item" to={`/hms/patient/op/register/${patient.id}`}>
                              Modify
                            </Link>
                            <HMSLink onClick={downloadReport} apiStartKey="op_report_download">
                              Print OP report
                            </HMSLink>
                            {(patient?.mode_of_visit !== "FOLLOW_UP") &&
                              <HMSLink onClick={downloadInvoice} apiStartKey="download_invoice">
                                Print Invoice
                              </HMSLink>
                            }
                            <Link className="dropdown-item" to={"#"} onClick={() => toggleViewModal('prescriptionsModal')}>
                              Upload report files
                            </Link>
                            <Link className="dropdown-item" to={"#"} onClick={handleDelete}>
                              Remove
                            </Link>
                            <Dropdown isOpen={submenu} toggle={() => setSubmenu(!submenu)} direction="down">
                              <DropdownToggle className="dropdown-item" caret color="primary">
                                Prescription &nbsp;<i className="fa fa-caret-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {patient?.prescription_existed && (
                                  apiKey === "get_download_digital_report" ? (
                                    <Link className="dropdown-item" to={"#"}>
                                      Download
                                    </Link>
                                  ) : (
                                    <Link className="dropdown-item" disabled={apiKey === "get_download_digital_report"} to={"#"} onClick={() => downloadDigitalReport(patient.id)}>
                                      Download
                                    </Link>
                                  )
                                )}
                                {moment(patient?.created_at).isSame(moment(), 'day') ? (
                                  <Link className="dropdown-item" to={"#"} onClick={() => toggleViewModal('configureModal')}>
                                    Configure
                                  </Link>
                                ) : (
                                  <Link className="dropdown-item text-muted" to={"#"} title="Prescription can't be created or modified for the past OPs">
                                    Configure
                                  </Link>
                                )}
                              </DropdownMenu>
                            </Dropdown>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </div>
                    <hr />
                    {patient?.patient_data?.user_data ? (
                      <>
                        <PatientInformation
                          data={patient?.patient_data}
                          includedFields={[
                            "patient_name",
                            "patient_id",
                            "gender",
                            "marital_status",
                            "date_of_birth",
                            "age",
                            "mobile",
                            "email",
                          ]}
                        />
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="OP number"
                            value={patient?.number}
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-danger">Patient details not existed</p>
                    )}
                    <h5 className="mt-2 mb-2">Emergency contact details</h5>
                    <hr />
                    {patient?.patient_data?.emergency_contact_name || patient?.patient_data?.emergency_contact_relation || patient?.patient_data?.emergency_contact_number ? (
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Contact person"
                          value={patient?.patient_data?.emergency_contact_name}
                        />
                        <TableChip
                          label="Relation"
                          value={patient?.patient_data?.emergency_contact_relation}
                        />
                        <TableChip
                          label="Phone number"
                          value={patient?.patient_data?.emergency_contact_number}
                        />
                      </div>
                    ) : (
                      <p className="text-center text-danger">Emergency contact details not existed</p>
                    )}
                    <Row>
                      <h5 className="mt-2">Address</h5>
                      <hr />
                      {patient?.patient_data?.address_data ? (
                        <Col>
                          <TableChip
                            value={patient?.patient_data?.address_data?.line1}
                          />
                          {patient?.patient_data?.address_data?.line2 &&
                            <TableChip
                              value={patient?.patient_data?.address_data?.line2}
                            />
                          }
                          <Row>
                            <Col>
                              <TableChip
                                value={patient?.patient_data?.address_data?.city}
                              />
                            </Col>
                            <Col>
                              <TableChip
                                value={patient?.patient_data?.address_data?.state}
                              />
                            </Col>
                            <Col>
                              <TableChip
                                value={patient?.patient_data?.address_data?.pincode}
                              />
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <p className="text-center text-danger">Address details not existed</p>
                      )}
                    </Row>
                  </Col>
                  <Col lg={1}></Col>
                  <Col>
                    <div className="mt-2 d-flex justify-content-between">
                      <h5>Visit details</h5>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip
                        label="Consulting doctor"
                        value={patient.doctor_data?.user_data ? `${patient.doctor_data?.user_data?.full_name} - ${patient.doctor_data?.specialization}` : null}
                      />
                      <TableChip
                        label="Visit no"
                        value={patient?.visit_count}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip
                        label="OP date"
                        value={formatDateTime(patient?.created_at)}
                      />
                      <TableChip
                        label="Visit mode"
                        value={patient?.mode_of_visit ? visitModeVerboseName(patient?.mode_of_visit) : null}
                      />
                      <TableChip
                        label="Reference type"
                        value={patient?.reference_type ? referenceTypeVerboseName(patient?.reference_type) : null}
                      />
                      {patient?.reference_type === "REFERRAL" && patient?.referred_by && (
                        <TableChip
                          label="Reference by"
                          value={patient?.referred_by}
                        />
                      )}
                    </div>
                    <Row className="mb-3">
                      <Col md={6}>
                        <TableChip
                          label="Validity"
                          value={patient?.validity ? formatDateTime(patient?.validity) : null}
                        />
                      </Col>
                      <Col md={6}>
                        <Label>Next visit</Label>
                        <div className="d-flex justify-content-between">
                          <Flatpickr
                            className="form-control form-control-sm me-2"
                            placeholder="Select a next visit date"
                            options={{
                              mode: "single",
                              dateFormat: "Y-m-d",
                            }}
                            ref={dateRef}
                            onClose={onChangeDate}
                            value={patient?.next_visit || ''}
                          />
                          <ButtonGroup>
                            <Button
                              color="secondary"
                              className="btn btn-sm"
                              outline
                              onClick={clearDate}
                              disabled={apiKey === "update_next_visit"}
                            >
                              <i className="dripicons-cross"></i>
                            </Button>
                          </ButtonGroup>
                        </div>
                      </Col>
                    </Row>
                    <h5 className="mt-2">Health information</h5>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip
                        label="Pulse"
                        value={patient.pulse}
                      />
                      <TableChip
                        label="Blood pressure"
                        value={patient.blood_pressure}
                      />
                      <TableChip
                        label="SpO2"
                        value={patient.spo2}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip
                        label="Temperature"
                        value={patient.temperature}
                      />
                      <TableChip
                        label="Weight"
                        value={patient.weight ? `${patient.weight} kg` : null}
                      />
                      <TableChip
                        label="Height"
                        value={patient.height ? `${patient.height} Centimeters` : null}
                      />
                    </div>
                    {patient?.billing && (
                      <>
                        <h5 className="mt-2">Fee details</h5>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Sub total"
                            value={patient?.billing?.sub_total_amount}
                          />
                          <TableChip
                            label="Discount (%)"
                            value={patient?.billing?.discount_percent && `${patient?.billing?.discount_percent}%`}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Discounted by"
                            value={patient?.billing?.discount_by_name}
                          />
                          <TableChip label="Discounted amount"
                            value={patient?.billing?.discount_amount}
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Grand total"
                            value={patient?.billing?.grand_total_amount}
                          />
                          <TableChip
                            label="Payment mode"
                            value={patient?.billing?.payment_mode ? paymentVerboseFetch(patient?.billing?.payment_mode) : null}
                          />
                        </div>
                      </>
                    )}
                    {patient?.payment_mode === "CHEQUE" && (
                      <div className="d-flex justify-content-between">
                        <TableChip
                          label="Cheque number"
                          value={patient?.billing?.cheque_number}
                        />
                        <TableChip
                          label="Cheque date"
                          value={patient?.billing?.cheque_date}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </div>

      <DownloadModal
        modal={modals.opDownloadModal}
        toggle={() => toggleViewModal('opDownloadModal')}
        apiStartKey={"op_report_download"}
      />

      <DownloadModal
        modal={modals.downloadInvoiceModal}
        toggle={() => toggleViewModal('downloadInvoiceModal')}
        apiStartKey={"download_invoice"}
      />

      <DownloadModal
        modal={modals.digitalOpDownloadModal}
        toggle={() => toggleViewModal('digitalOpDownloadModal')}
        apiStartKey={"download_digital_report"}
      />

      <Modal isOpen={modals.prescriptionsModal} toggle={() => toggleViewModal('prescriptionsModal')} backdrop="static">
        <div className="modal-content">
          <ModalHeader toggle={() => toggleViewModal('prescriptionsModal')}>
            Upload Reports
          </ModalHeader>
          {error?.detail ? (
            <p className="text-center text-muted m-2">{error.detail}</p>
          ) : (
            <>
              <ModalBody>
                <CardBody>
                  <Row>
                    <Col>
                      <div>
                        {isShowUpload && apiKey !== "post_upload_op_prescription" && isEmpty(signedLinks) ? (
                          <FileUploader handleAcceptedFiles={handleAcceptedFiles} buttonText="Upload" buttonClass="btn" />
                        ) : (apiKey === "upload_op_prescription" || signedLinks) ? (
                          <span className="mt-n4">
                            {!isEmpty(signedLinks) && signedLinks.map((url, index) => (
                              <UncontrolledAlert
                                key={index}
                                title="Click to download"
                                className="cursor-pointer"
                                color="primary"
                                toggle={() => {
                                  setSignedLinks(signedLinks.filter(item => item.file_path !== url.file_path));
                                  setSaveFlag(false);
                                }}
                                onClick={(e) => {
                                  if (e.target.closest('.btn-close')) return;
                                  previewFile(url.file_path, url.file_name);
                                }}
                              >
                                {url.file_name}
                              </UncontrolledAlert>
                            ))}
                            <FileUploader handleAcceptedFiles={handleAcceptedFiles} buttonText="+ Add more reports" />
                          </span>
                        ) : apiKey === "post_upload_op_prescription" ? (
                          <div className="d-flex justify-content-center align-items-center mb-4">
                            <Spinner className="me-2" color="secondary" />
                            <span>Processing</span>
                          </div>
                        ) : apiKey === "fail_upload_op_prescription" && (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <p>Something went wrong. Please try again</p>
                            </div>
                            <FileUploader handleAcceptedFiles={handleAcceptedFiles} buttonText="Upload prescriptions" />
                          </>
                        )}
                        {isLoading && (
                          <p className="ms-2">Uploading files... <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="button"
                  color="primary"
                  disabled={loading || saveFlag}
                  onClick={() => dispatch(updateHms("/hms/patient/outpatients/", patient?.id, { raw_media: signedLinks }, "op_update"))}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </div>
      </Modal>

      <Offcanvas
        isOpen={modals?.configureModal}
        direction="end"
        toggle={() => toggleViewModal('configureModal')}
        backdrop={"static"}
        style={{ width: '75vw' }}
      >
        <OffcanvasHeader toggle={() => toggleViewModal('configureModal')}>
        </OffcanvasHeader>
        <OffcanvasBody>
          {apiKey === "get_op_prescription" ? (
            <Loader />
          ) : (
            <>
              <div className="text-sm-end">
                <Link
                  to="#"
                  className="me-2 btn btn-primary btn-sm"
                  onClick={() => toggleViewModal('medicineModal')}
                >
                  +&nbsp;Add&nbsp;Medicine
                </Link>
              </div>
              <Table>
                <thead className="thead-light text-capitalize">
                  <tr>
                    <th>Medicine</th>
                    <th>Dosage</th>
                    <th>Timing</th>
                    <th>Frequency</th>
                    <th>Duration</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(medicines) ? medicines?.map((med, index) => {
                    return (
                      <tr key={index}>
                        <td>{med.name}</td>
                        <td>{med.dosage}</td>
                        <td>{med.timing}</td>
                        <td>{med.frequency}</td>
                        <td>{med.duration} {med.duration_unit}</td>
                        <td>
                          <Link
                            to="#"
                            onClick={() => removeCartItem(med.name)}
                            className="action-icon text-danger"
                            title="Remove"
                          >
                            {" "} <i className="mdi mdi-close-thick font-size-18" />
                          </Link>
                        </td>
                      </tr>
                    );
                  }) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Please add medicines
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </Table>
              <>
                <div>
                  <Row className="mb-2">
                    <Col>
                      <ETextEditorInput
                        editorState={editorStates?.complaints}
                        onEditorStateChange={(newState) => handleEditorStateChange('complaints', newState)}
                        formError={formError?.complaints}
                        options={options?.form_schema?.prescription?.complaints}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <ETextEditorInput
                        editorState={editorStates?.previous_health_history}
                        onEditorStateChange={newState => handleEditorStateChange('previous_health_history', newState)}
                        formError={formError?.previous_health_history}
                        options={options?.form_schema?.prescription?.previous_health_history}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <ETextEditorInput
                        editorState={editorStates?.diagnosis}
                        onEditorStateChange={newState => handleEditorStateChange('diagnosis', newState)}
                        formError={formError?.diagnosis}
                        options={options?.form_schema?.prescription?.diagnosis}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <ETextEditorInput
                        editorState={editorStates?.investigations}
                        onEditorStateChange={newState => handleEditorStateChange('investigations', newState)}
                        formError={formError?.investigations}
                        options={options?.form_schema?.prescription?.investigations}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col>
                      <ETextEditorInput
                        editorState={editorStates?.advises}
                        onEditorStateChange={newState => handleEditorStateChange('advises', newState)}
                        formError={formError?.advises}
                        options={options?.form_schema?.prescription?.advises}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="text-sm-end">
                  {apiKey === "update_outpatients_update" ? (
                    <Button color="primary" disabled>
                      Submit
                      <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                    </Button>
                  ) : (
                    <Button type="submit" color="primary" disabled={isEmpty(medicines)} onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                </div>
              </>
            </>
          )
          }
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={modals.medicineModal} toggle={() => toggleViewModal('medicineModal')} backdrop="static" size="lg">
        <div className="modal-content">
          <ModalHeader toggle={() => toggleViewModal('medicineModal')}>
            Add Medicine
          </ModalHeader>
          {error?.detail ? (
            <p className="text-center text-muted m-2">{error.detail}</p>
          ) : (
            <AvForm className="form-horizontal" onValidSubmit={handleAddMedicine}>
              <ModalBody>
                <CardBody>
                  <Row>
                    <Col xs="12" md="12" lg="4">
                      <EAvFieldInput
                        field="name"
                        options={options?.form_schema?.prescription?.name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6" lg="2">
                      <EAvFieldMask
                        field="dosage"
                        value={dosage}
                        formError={dosageError}
                        options={options?.form_schema?.prescription?.dosage}
                        onChange={(e) => setDosage(e.target.value)}
                        onFocus={() => handlePhonenumberFocus(setDosageError)}
                        onBlur={() => {
                          if (!dosage || dosage === "_-_-_") {
                            setDosageError('This field is invalid');
                          }
                        }}
                      />
                    </Col>
                    <Col xs="12" md="6" lg="3">
                      <EAvFieldSelect
                        field="timing"
                        options={options?.form_schema?.prescription?.timing}
                        choices={
                          <>
                            <option value="">{"Select option"}</option>
                            {options?.form_schema?.prescription?.timing?.choices?.map((item, idx) => (
                              <option value={item.id} key={idx}>{item.display_name}</option>
                            )
                            )}
                          </>}
                      />
                    </Col>
                    <Col xs="12" md="6" lg="3">
                      <EAvFieldSelect
                        field="frequency"
                        options={options?.form_schema?.prescription?.frequency}
                        choices={
                          <>
                            <option value="">{"Select option"}</option>
                            {options?.form_schema?.prescription?.frequency?.choices?.map(
                              (item, idx) => (
                                <option value={item.id} key={idx}>
                                  {item.display_name}
                                </option>
                              )
                            )}
                          </>
                        }
                      />
                    </Col>
                    <Col xs="6" md="6" lg="2">
                      <EAvFieldNumber
                        field="duration"
                        options={options?.form_schema?.prescription?.duration}
                        min="0"
                      />
                    </Col>
                    <Col xs="6" md="6" lg="2">
                      <EAvFieldSelect
                        field="duration_unit"
                        options={options?.form_schema?.prescription?.duration_unit}
                        choices={
                          <>
                            <option value="">{"Select option"}</option>
                            {options?.form_schema?.prescription?.duration_unit?.choices?.map(
                              (item, idx) => (
                                <option value={item.id} key={idx}>
                                  {item.display_name}
                                </option>
                              )
                            )}
                          </>
                        }
                      />
                    </Col>
                  </Row>
                  <RequiredFieldsMessage />
                </CardBody>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" disabled={loading}>
                  Add
                </Button>
              </ModalFooter>
            </AvForm>
          )}
        </div>
      </Modal>

      <PreviewFile
        modal={modals?.previewModal}
        toggle={() => toggleViewModal('previewModal')}
      />

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If outpatient is removed. You cannot get it back</li>
            <li>All follow-up ops will be removed</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/outpatients/", patient?.id, "outpatient_detail_deleted")}
        deleteModal={modals.deleteModal}
      />
    </React.Fragment >
  );
};

export default OutpatientDetail;
