import { GET_CALLS, GET_CALLS_FAIL, GET_CALLS_SUCCESS, GET_DYNAMIC_FILTER_OPTIONS, GET_DYNAMIC_FILTER_OPTIONS_FAIL, GET_DYNAMIC_FILTER_OPTIONS_SUCCESS, GET_CALL_OPTIONS, GET_CALL_OPTIONS_FAIL, GET_CALL_OPTIONS_SUCCESS, GET_USERS_ON_SEARCH, GET_USERS_ON_SEARCH_FAIL, GET_USERS_ON_SEARCH_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  callOptions: {},
  filterOptions: {},
  usersObject: {},
  callsObject: {},
  error: null,
  userLoading: false,
  callLoading: false
}

const Calls = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_ON_SEARCH:
      return {
        ...state,
        error: null,
        userLoading: true,
      }
    case GET_USERS_ON_SEARCH_SUCCESS:
      return {
        ...state,
        error: null,
        userLoading: false,
        usersObject: action.payload
      }
    case GET_USERS_ON_SEARCH_FAIL:
      return {
        ...state,
        userLoading: false,
        error: action.payload
      }

    case GET_CALL_OPTIONS:
      return {
        ...state,
        error: null,
      }
    case GET_CALL_OPTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        callOptions: action.payload
      }
    case GET_CALL_OPTIONS_FAIL:
      return {
        ...state,
        error: action.payload
      }
    
    case GET_DYNAMIC_FILTER_OPTIONS:
      return {
        ...state,
        userLoading: true,
        error: null,
      }
    case GET_DYNAMIC_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        userLoading: false,
        filterOptions: action.payload
      }
    case GET_DYNAMIC_FILTER_OPTIONS_FAIL:
      return {
        ...state,
        userLoading: false,
        error: action.payload
      }
    
    case GET_CALLS:
      return {
        ...state,
        error: null,
        callLoading: true,
      }
    case GET_CALLS_SUCCESS:
      return {
        ...state,
        error: null,
        callLoading: false,
        callsObject: action.payload
      }
    case GET_CALLS_FAIL:
      return {
        ...state,
        callLoading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default Calls
