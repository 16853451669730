import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import {
  Card,
  CardBody,
  Container,
  Media,
} from "reactstrap"
import "assets/scss/datatables.scss"
import { allUsers as onGetAllUsers, getUserOptions as onGetUserOptions } from "store/actions"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import moment from 'moment';
import UserFilters, { UserSideFilters } from "./user-filters";
import { getURLParam } from "components/Common/common";
import GenericTable from "components/Common/table/table";


const Users = props => {
  const location = useLocation()
  const dispatch = useDispatch()
  const urlParams = location.search
  const [filters, setFilter] = useState({})

  const { allUsers, loading, detailError, userOptions } = useSelector(state => ({
    userOptions: state.DashboardGenericState.userOptions,
    allUsers: state.DashboardGenericState.allUsers,
    loading: state.DashboardGenericState.loading,
    detailError: state.DashboardGenericState.detailError,
  }))

  useEffect(async () => {
    await setTimeout(() => {
      let body = document.body;
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }, 1);
  }, [])

  useEffect(() => {
    if (urlParams.length>1) {
      dispatch(onGetUserOptions(decodeURIComponent(urlParams).replace("?", "")))
    } else {
      dispatch(onGetUserOptions())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(userOptions)) {
      dispatch(onGetAllUsers(decodeURIComponent(urlParams).replace("?", "")))
      setFilter(userOptions)
    }
  }, [userOptions])

  const columns = [{
    dataField: 'full_name',
    text: '',
    formatter: (cellContent, row) => (
      <Link to={`/user/${row.username}/dashboard`}>
        <Media>
          {!row.avatar ? (
            <div className="avatar-sm">
              <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-18">
                {row.full_name ? row.full_name.charAt(0) : 'A'}
              </span>
            </div>
          ) : (
            <div>
              <img
                className="rounded-circle avatar-sm"
                src={row.avatar}
                alt=""
              />
            </div>
          )}
          <Media className="ms-2 align-self-center">
            <div className="text-muted">
              <h5 className="mb-1">{row.full_name}</h5>
              <p className="mb-2"><span className="bg-info font-size- badge">#{row.username}</span></p>
              <p className="mb-0">{row.email}</p>
            </div>
          </Media>
        </Media>
      </Link>
    ),
  }, {
    dataField: 'user_type',
    text: 'User Type'
  }, {
    dataField: 'phone_number',
    text: 'Phone number'
  }, {
    dataField: 'date_joined',
    text: 'Joined date',
    formatter: (cellContent, row) => (
      <p>{moment(row.date_joined).format("LL")}</p>
    )
  }, {
    dataField: 'call_count',
    text: 'Performed calls'
  }]

  const apiTrigger = (urlParams=null) => {
    if (urlParams) {
      dispatch(onGetAllUsers(urlParams))
    } else {
      dispatch(onGetAllUsers())
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Users" />

          {detailError ?
            <Card>
              <CardBody>
                <p className="text-center text-danger">{detailError.detail}</p>
              </CardBody>
            </Card>
            :
            <GenericTable
              urlParams={urlParams}
              filters={filters}
              columns={columns}
              keyField={'username'}
              noDataIndication={"No users found"}
              data={allUsers}
              loading={loading}
              apiTrigger={apiTrigger}
            />
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

Users.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default Users
