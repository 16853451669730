import { Alert, Col, Modal, Row } from "reactstrap"
import { useEffect, useState } from "react";
import { get, post } from "helpers/api_helper";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import { deleteHms, getHms, getHmsOptions, postHms, updateHms } from "store/actions";
import toastr from "toastr";
import moment from "moment";
import { isEmpty, reverse } from "lodash";
import { ErrorMessage } from "pages/HMS/common/errored-avfields";


const InsuranceAddModal = (props) => {
  const { modalState, toggleModal, inpatient } = props
  const dispatch = useDispatch();
  const MODAL_KEY = "insuranceAdd";
  const [documents, setDocuments] = useState([])

  const { loading, error, options, isPosted, hmsObject, apiKey, crudStatus, isUpdated, formError } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    options: state.Hms.options,
    isPosted: state.Hms.isPosted,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    crudStatus: state.Hms.crudStatus,
    isUpdated: state.Hms.isUpdated,
    formError: state.Hms.formError,
  }));

  useEffect(() => {
    if (apiKey === "ins_docs") setDocuments(hmsObject)
    if (apiKey === "upload_docs") {
      toggleModal(MODAL_KEY, false)
      toastr.success("Documents uploaded successfully")
      dispatch(getHms(`/hms/patient/health_insurance/${inpatient?.patient_data?.health_insurance}/documents/`, null, "ins_docs"))
    }
    if (apiKey === "hi_create") {
      toggleModal(MODAL_KEY, false)
      toastr.success("Health insurance details successfully added")
      dispatch(getHms(`/hms/patient/health_insurance/${hmsObject?.id}/`, null, "insurance"))
      dispatch(getHms("/hms/patient/inpatients/", inpatient?.id, "ip_detail"));
    }
    if (apiKey === "delete_doc") {
      toastr.success("Document deleted successfully");
      dispatch(getHms(`/hms/patient/health_insurance/${inpatient?.patient_data?.health_insurance}/documents/`, null, "ins_docs"))
    }
  }, [hmsObject, apiKey])

  useEffect(() => {
    if (inpatient?.patient_data) dispatch(getHmsOptions("/hms/patient/health_insurance/"))
    if (inpatient?.patient_data?.health_insurance) {
      dispatch(getHms(`/hms/patient/health_insurance/${inpatient?.patient_data?.health_insurance}/documents/`, null, "ins_docs"))
    }
  }, [inpatient?.patient_data])

  const fetchAndUploadFile = async (fileObj) => {
    try {
      const psu_resp = await post("/hms/patient/health_insurance/safelink/", {
        filename: fileObj.file_name,
      });

      const response = await fetch(psu_resp.safelink, {
        method: 'PUT',
        headers: {
          "Content-Type": "multipart/formdata",
        },
        body: fileObj.file,
      });

      if (response.status === 200) {
        delete fileObj["file"];
        fileObj["file_path"] = psu_resp.file_path;
        return fileObj;
      } else {
        throw new Error(`File upload failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
      return { error: error.message };
    }
  };

  const handleSubmitDocuments = async (evt, values) => {
    const filesArray = [];
    for (const [k, v] of Object.entries(values)) {
      const file = evt.target.elements[k].files[0];
      if (v !== "" && file) {
        filesArray.push({
          "document_type": k.split("_")[1],
          "file_name": file.name,
          "file_type": file.name.split(".").pop(),
          "file_size": file.size,
          "file": file,
        });
      }
    }

    if (filesArray.length === 0) {
      console.log("No files to upload.");
      return;
    }

    try {
      const responseArray = await Promise.all(filesArray.map(fetchAndUploadFile));
      const errorResponses = responseArray.filter((resp) => resp?.error);
      if (errorResponses.length > 0) {
        console.error("Some file uploads failed:", errorResponses);
      } else {
        dispatch(postHms(`/hms/patient/health_insurance/${inpatient?.patient_data?.health_insurance}/documents/`, {
          data: responseArray,
        }, "upload_docs"));
      }
    } catch (error) {
      console.error("Error during file uploads:", error);
    }
  };

  const handleSubmitHI = (evt, values) => {
    dispatch(updateHms("/hms/patient/patients/", inpatient?.patient, { health_insurance_post: values }, "hi_create"))
  }

  const openFileInPopup = (docID) => {
    get(`/hms/patient/health_insurance/${inpatient?.patient_data.health_insurance}/document/${docID}/`).then(response => {
      if (response.doclink) {
        const popupWidth = 800;
        const popupHeight = 600;
        const left = (window.innerWidth - popupWidth) / 2;
        const top = (window.innerHeight - popupHeight) / 2;
        window.open(
          response.doclink,
          '_blank',
          `width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`
        );
        toastr.success("The file has been successfully downloaded. Please check your downloads")
      } else {
        toastr.error("Can't open file. It might be removed or corrupted");
      }
    })
  }

  const removeDocument = (docID) => {
    dispatch(deleteHms(`/hms/patient/health_insurance/${inpatient?.patient_data.health_insurance}/document/${docID}/`, null, "delete_doc"))
  }

  const fileStyle = {
    border: "1px solid lightblue",
    borderRadius: "4px",
    padding: "5px",
    marginBottom: "10px"
  }

  return (
    <>
      {modalState &&
        <Modal
          isOpen={modalState}
          toggle={() => toggleModal}
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={() => toggleModal(MODAL_KEY, false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {inpatient?.patient_data?.health_insurance ?
              <AvForm id="identity-form" onValidSubmit={handleSubmitDocuments}>
                {options.identity_types && options?.identity_types.map((id_type) => {
                  const hasDocument = documents.some((i) => i.document_type === id_type.id);

                  return (
                    <div key={id_type.id} className="d-flex justify-content-around">
                      {hasDocument ? (
                        <div style={fileStyle}>
                          <p
                            className="text-bold text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => openFileInPopup(id_type.id)}
                            title="Open document"
                          >{id_type.name}</p>
                          <div
                            onClick={() => { removeDocument(id_type.id) }}
                            style={{ cursor: "pointer", color: "red" }}
                            title="Delete document"
                          >
                            <i className="bx bx-trash" />
                          </div>
                        </div>
                      ) : (
                        <div style={fileStyle}>
                          <AvField
                            name={`filename_${id_type.id}`}
                            label={id_type.name}
                            type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </AvForm>
              :
              <AvForm id="identity-form" onValidSubmit={handleSubmitHI}>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <AvField
                        id={"id_issuer"}
                        type="select"
                        name={"issuer"}
                        className={formError?.issuer ? 'is-invalid' : ''}
                        label="Policy issuer"
                        value={hmsObject?.issuer}
                        required
                      >
                        <option className="text-center">--------Select an option--------</option>
                        {options.insurance_issuer && options?.insurance_issuer.map((choice, cidx) =>
                          <option value={choice.id} key={cidx}>{choice.name}</option>
                        )}
                      </AvField>
                      <ErrorMessage error={formError?.issuer} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3">
                      <AvField
                        id="id_policy_number"
                        name="policy_number"
                        className={formError?.policy_number ? 'is-invalid' : ''}
                        label="Policy number"
                        type="text"
                        validate={{
                          maxLength: { value: 50 }
                        }}
                        required
                        value={hmsObject?.policy_number}
                      />
                      <ErrorMessage error={formError?.policy_number} />
                    </div>
                  </Col>
                  <Col>
                    <div className="mb-3">
                      <AvField
                        id="id_policy_valid_till"
                        name="policy_valid_till"
                        className={formError?.policy_valid_till ? "is-invalid" : ""}
                        label="Valid till"
                        type="date"
                        value={hmsObject?.policy_valid_till}
                      />
                      <ErrorMessage
                        error={formError?.policy_valid_till}
                      />
                    </div>
                  </Col>
                </Row>
              </AvForm>
            }
            {formError?.detail && <Alert color="danger">{formError.detail}</Alert>}
            {error?.detail && <Alert color="danger">{error.detail}</Alert>}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => toggleModal(MODAL_KEY, false)}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              form="identity-form"
            >
              Save changes
            </button>
          </div>
        </Modal>
      }
    </>
  )
}
export default InsuranceAddModal;


export const InsuranceHistoryModal = (props) => {
  const { modalState, toggleModal, insurance } = props
  const MODAL_KEY = "insuranceHistory";
  const [statusHistory, setStatusHistory] = useState(null)

  useEffect(() => {
    setStatusHistory(reverse(insurance?.history))
  }, [insurance?.history])

  return (
    <>
      {modalState &&
        <Modal
          isOpen={modalState}
          toggle={() => toggleModal}
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={() => toggleModal(MODAL_KEY, false)}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="">
              <ul className="verti-timeline list-unstyled">
                {statusHistory ? statusHistory.map((item, key) => (
                  <li key={key} className="event-list">
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle" />
                    </div>
                    <div className="media">
                      <div className="media-body">
                        <div>
                          <h5>{item.status}</h5>
                          <p className="text-muted">
                            <a href="#">{item.user[1]}</a> updated status to {item.status}
                            <br />
                            <span>{moment(item.timestamp).format("lll")}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
                  :
                  <p className="text-danger text-center">No insurance history</p>
                }
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => toggleModal(MODAL_KEY, false)}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </Modal>
      }
    </>
  )
}
