import { MY_STATS, MY_STATS_FAIL, MY_STATS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
    stats: {},
    loading: false,
    statsError: null,
}

const Stats = (state=INIT_STATE, action) => {
    switch (action.type) {
        case MY_STATS:
            return {
                ...state,
                loading: true,
                statsError: null
            };
        case MY_STATS_SUCCESS:
            return {
                ...state,
                loading: false,
                stats: action.payload.data,
                statsError: null
            };
        case MY_STATS_FAIL:
            return {
                ...state,
                loading: false,
                statsError: action.payload.error
            };
        default:
            return state;
    }
}


export default Stats;