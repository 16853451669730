import { deletePaginationResult, prependToPaginationResult, updatePaginationResult } from "components/Common/common"
import { ASSIGN_TO_AGENTS, ASSIGN_TO_AGENTS_FAIL, ASSIGN_TO_AGENTS_SUCCESS, CHECK_HEADERS, CHECK_HEADERS_FAIL, CHECK_HEADERS_SUCCESS, DELETE_LEAD, DELETE_LEAD_FAIL, DELETE_LEAD_SUCCESS, DELETE_TEMPLATE, DELETE_TEMPLATE_FAIL, DELETE_TEMPLATE_SUCCESS, GET_LEAD, GET_LEAD_FAIL, GET_LEAD_SUCCESS, GET_TEMPLATE, GET_TEMPLATE_FAIL, GET_TEMPLATE_SUCCESS, LEAD_DASHBOARD, LEAD_DASHBOARD_FAIL, LEAD_DASHBOARD_SUCCESS, LIST_LEADS, LIST_LEADS_FAIL, LIST_LEADS_SUCCESS, LIST_TEMPLATES, LIST_TEMPLATES_FAIL, LIST_TEMPLATES_SUCCESS, MODIFY_DISPLAY_OPTIONS, MODIFY_DISPLAY_OPTIONS_FAIL, MODIFY_DISPLAY_OPTIONS_SUCCESS, OPTIONS_LEADS, OPTIONS_LEADS_FAIL, OPTIONS_LEADS_SUCCESS, POST_LEAD, POST_LEAD_FAIL, POST_LEAD_SUCCESS, POST_TEMPLATE, POST_TEMPLATE_FAIL, POST_TEMPLATE_SUCCESS, RESET_LEADS, STEPPED_FORM, UPDATE_LEAD, UPDATE_LEAD_FAIL, UPDATE_LEAD_SUCCESS, UPDATE_TEMPLATE, UPDATE_TEMPLATE_FAIL, UPDATE_TEMPLATE_SUCCESS, UPLOAD_LEADS, UPLOAD_LEADS_FAIL, UPLOAD_LEADS_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  loading: false,
  options: {},
  apiObject: {},
  leadObject: {},
  error: null,
  formError: null,

  isLeadUpdated: false,

  isPosted: false,
  isUpdated: false,
  isDeleted: false,
  uploadingLeads: false,
  autoAssign: false,
  assignToAgents: null,
  templates: {},
  templateObject: {},
  dropdownData: {},
  formData: {
    step: 1
  },
  displayOptionsModified: false,
  dashboardData: {}
}

const resetStateFields = ['error', 'formError', 'isLeadUpdated', 'isPosted', 'isUpdated', 'isDeleted', 'dropdownData', 'assignToAgents', 'uploadingLeads', 'options']

const Lms = (state=INIT_STATE, action) => {
  switch (action.type) {
    case RESET_LEADS:
      resetStateFields.map(item => {
        state[item] = INIT_STATE[item]
      })
      return state

    case STEPPED_FORM:
      return {
        ...state,
        formData: action.payload
      }

    case OPTIONS_LEADS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case OPTIONS_LEADS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        options: action.payload
      }
    case OPTIONS_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    
    case GET_LEAD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        leadObject: action.payload
      }
    case GET_LEAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    
    case LIST_LEADS:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case LIST_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        apiObject: action.payload
      }
    case LIST_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    
    case POST_LEAD:
      return {
        ...state,
        error: null,
        formError: null,
        isPosted: true,
        isUpdated: false,
        isDeleted: false,
        loading: true
      }
    case POST_LEAD_SUCCESS:
      return {
        ...state,
        isPosted: false,
        apiObject: prependToPaginationResult(state.apiObject, action.payload),
        loading: false
      }
    case POST_LEAD_FAIL:
      return {
        ...state,
        isPosted: true,
        formError: action.payload,
        loading: false
      }

    case UPDATE_LEAD:
      return {
        ...state,
        formError: null,
        isPosted: false,
        isLeadUpdated: false,
        isDeleted: false,
      }
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        isLeadUpdated: true,
        apiObject: updatePaginationResult(state.apiObject, action.payload, 'id'),
        leadObject: action.payload,
      }
    case UPDATE_LEAD_FAIL:
      return {
        ...state,
        isLeadUpdated: false,
        formError: action.payload,
      }
    
    case DELETE_LEAD:
      return {
        ...state,
        formError: null,
        error: null,
        loading: true,
        isPosted: false,
        isUpdated: false,
        isDeleted: true,
      }
    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        apiObject: deletePaginationResult(state.apiObject, 'id', action.payload),
        loading: false,
        isDeleted: false
      }
    case DELETE_LEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isDeleted: false
      }

    case UPLOAD_LEADS:
      return {
        ...state,
        error: null,
        formError: null,
        loading: true,
        isPosted: false,
        isUpdated: false,
        isDeleted: false,
        uploadingLeads: true
      }
    case UPLOAD_LEADS_SUCCESS:
      return {
        ...state,
        uploadingLeads: false,
        loading: false
      }
    case UPLOAD_LEADS_FAIL:
      return {
        ...state,
        uploadingLeads: false,
        formError: action.payload,
        loading: false
      }

    case ASSIGN_TO_AGENTS:
      return {
        ...state,
        error: null,
        autoAssign: true,
        assignToAgents: null,
      }
    case ASSIGN_TO_AGENTS_SUCCESS:
      return {
        ...state,
        autoAssign: false,
        assignToAgents: action.payload,
      }
    case ASSIGN_TO_AGENTS_FAIL:
      return {
        ...state,
        autoAssign: false,
        assignToAgents: false,
        error: action.payload,
      }

    case LIST_TEMPLATES:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case LIST_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload
      }
    case LIST_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
  
    case GET_TEMPLATE:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templateObject: action.payload
      }
    case GET_TEMPLATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    
    case POST_TEMPLATE:
      return {
        ...state,
        error: null,
        formError: null,
        isPosted: true,
        isUpdated: false,
        isDeleted: false,
        loading: true
      }
    case POST_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPosted: false,
        templates: prependToPaginationResult(state.templates, action.payload),
        loading: false
      }
    case POST_TEMPLATE_FAIL:
      return {
        ...state,
        isPosted: true,
        formError: action.payload,
        loading: false
      }

    case UPDATE_TEMPLATE:
      return {
        ...state,
        formError: null,
        isPosted: false,
        isUpdated: false,
        isDeleted: false,
        loading: true
      }
    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isUpdated: true,
        templates: updatePaginationResult(state.templates, action.payload, 'uid'),
        loading: false
      }
    case UPDATE_TEMPLATE_FAIL:
      return {
        ...state,
        isUpdated: false,
        formError: action.payload,
        loading: false
      }
    
    case DELETE_TEMPLATE:
      return {
        ...state,
        formError: null,
        error: null,
        loading: true,
        isPosted: false,
        isUpdated: false,
        isDeleted: true,
      }
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateObject: deletePaginationResult(state.templateObject, 'id', action.payload),
        loading: false,
        isDeleted: false
      }
    case DELETE_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isDeleted: false
      }

    case CHECK_HEADERS:
      return {
        ...state,
        error: null,
        formError: null,
        loading: true
      }
    case CHECK_HEADERS_SUCCESS:
      return {
        ...state,
        dropdownData: action.payload,
        loading: false
      }
    case CHECK_HEADERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    
  
    case MODIFY_DISPLAY_OPTIONS:
      return {
        ...state,
        error: null,
        displayOptionsModified: false,
        loading: true
      }
    case MODIFY_DISPLAY_OPTIONS_SUCCESS:
      return {
        ...state,
        displayOptionsModified: true,
        loading: false
      }
    case MODIFY_DISPLAY_OPTIONS_FAIL:
      return {
        ...state,
        loading: false
      }

    case LEAD_DASHBOARD:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LEAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload
      }
    case LEAD_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    
  
    default:
      return state
  }
}

export default Lms
