import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Col, Card, CardBody, Row, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { deleteHms, getHms, resetHmsState } from "store/actions"
import { genderVerboseFetch, TableChip } from "components/Common/common"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { Loader, stringToHumanTime } from "pages/HMS/common/common"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import toastr from "toastr"

const AppointmentsDetail = (props) => {
  const dispatch = useDispatch()
  const [appointment, setAppointment] = useState({})
  const [dropdown, setDropdown] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const { loading, error, hmsObject, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === "appointment_details") {
      setAppointment(hmsObject)
    }
    if (apiKey === "appointment_detail_deleted") {
      toastr.success(`Appointment is deleted`)
      setAppointment({})
      toggleDelete()
      window.location.replace("/hms/patient/appointments")
    }
  }, [apiKey])

  useEffect(() => {
    if (props.match.params.appointmentID) dispatch(getHms("/hms/patient/appointments/", props.match.params.appointmentID, "appointment_details"))
    return () => {
      dispatch(resetHmsState())
    }
  }, [])

  const toggleDelete = (def = deleteModal) => {
    setDeleteModal(!def)
  }

  const handleDelete = () => {
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {appointment?.first_name && `${appointment.first_name} ${appointment.last_name} |`} Yanthura
          </title>
        </MetaTags>
        <Card>
          <CardBody>
            {loading && apiKey === "get_appointment_details" ? (
              <Loader />
            ) : error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5>Patient Information</h5>
                      <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                        <DropdownToggle caret color="primary" size="sm">
                          Actions
                          &nbsp;<i className="fa fa-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <Link className={`dropdown-item ${appointment?.status === "Expired" ? "disabled" : ""}`} to={`/hms/patient/appointment/book/${appointment.id}`}>
                            Modify
                          </Link>
                          <Link className="dropdown-item" to={"#"} onClick={handleDelete}>
                            Remove
                          </Link>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <hr />
                    {appointment ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Patient Name"
                            value={!isEmpty(appointment?.first_name) ? `${appointment?.honorific} ${appointment?.first_name} ${appointment?.last_name}` : null}
                            defaultIfNull="NA"
                          />
                          <TableChip
                            label="Gender"
                            value={genderVerboseFetch(appointment?.gender)}
                            defaultIfNull="NA"
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Phone Number"
                            value={appointment?.phone_number}
                            defaultIfNull="NA"
                          />
                          {appointment?.alternate_phone_number &&
                            <TableChip
                              label="Alternate Phone Number"
                              value={appointment?.alternate_phone_number}
                              defaultIfNull="NA"
                            />
                          }
                          {!appointment?.alternate_phone_number &&
                            <TableChip
                              label="Address"
                              value={appointment?.coming_from}
                              defaultIfNull="NA"
                            />
                          }
                        </div>
                        {appointment?.alternate_phone_number &&
                          <TableChip
                            label="Address"
                            value={appointment?.coming_from}
                            defaultIfNull="NA"
                          />
                        }
                      </>
                    ) : (
                      <p className="text-center text-danger">Patient details not existed</p>
                    )}
                  </Col>
                  <Col lg={1}></Col>
                  <Col>
                    <div className="md-flex justify-content-between">
                      <h5 className="mt-2">Appointment Details</h5>
                    </div>
                    <hr />
                    {appointment ? (
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Consulting Doctor"
                            value={appointment.doctor_data?.user_data ? `${appointment.doctor_data?.user_data?.full_name} - ${appointment.doctor_data?.specialization}` : null}
                            defaultIfNull="NA"
                          />
                          <TableChip
                            label="OP Date"
                            value={appointment?.appointment_date}
                            defaultIfNull="NA"
                          />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Token Number"
                            value={appointment?.token_number}
                            defaultIfNull="NA"
                          />
                          <TableChip
                            label="Slot"
                            value={!isEmpty(appointment?.time_slot) ? stringToHumanTime(appointment?.time_slot) : null}
                            defaultIfNull="NA"
                          />
                        </div>
                      </>
                    ) : (
                      <p className="text-center text-danger">Appointment details not existed</p>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </div>

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If appointment is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/appointments/", appointment?.id, "appointment_detail_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment >
  )
}

export default AppointmentsDetail
