import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import {
  Badge,
  Card,
  CardBody,
  Container,
} from "reactstrap"
import { getCallOptions as onGetCallOptions, getCalls as onGetCalls } from "store/actions"
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import GenericTable from "components/Common/table/table"
import { isEmpty } from "lodash";


const Calls = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})

  const { callOptions, callsObject, callLoading, error } = useSelector(state => ({
    callOptions: state.Calls.callOptions,
    callsObject: state.Calls.callsObject,
    callLoading: state.Calls.callLoading,
    error: state.Calls.error
  }))

  useEffect(async () => {
    await setTimeout(() => {
      let body = document.body;
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }, 1);
  }, [])

  useEffect(() => {
    if (urlParams.length>1) {
      dispatch(onGetCallOptions(decodeURIComponent(urlParams).replace("?", "")))
    } else {
      dispatch(onGetCallOptions())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(callOptions)) {
      dispatch(onGetCalls(decodeURIComponent(urlParams).replace("?", "")))
      setFilter(callOptions)
    }
  }, [callOptions])

  const getCallStatusColor = (score) => {
    return score<40 ? "danger" : (score>40 && score<75) ? "info" : "success"
  }

  const apiTrigger = (urlParams=null) => {
    if (urlParams) {
      dispatch(onGetCalls(urlParams))
    } else {
      dispatch(onGetCalls())
    }
  }

  const columns = [{
    dataField: 'name',
    text: 'Name',
    formatter: (cellContent, row) => (
      <Link to={`/call/${row.uid}`} title={`${row.name}`}>
        <p>{row.name}</p>
      </Link>
    ),
    style: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
      maxWidth: 1,
      whiteSpace: 'nowrap',
    }
  }, 
  {
    dataField: 'user',
    text: 'Agent name',
    formatter: (cellContent, row) => (
      <Link to={`/user/${row.user.username}/dashboard`}>
        <p title={row.user.username}>{row.user.full_name}</p>
      </Link>
    )
  }, {
    dataField: 'created_at',
    text: 'Date',
    formatter: (cellContent, row) => (
      <p title={moment(row.created_at).format("LT")}>{moment(row.created_at).utc().format("MMMM D, YYYY")}</p>
    )
  }, {
    dataField: 'duration',
    text: 'Duration',
    formatter: (cellContent, row) => (
      <p>{row.duration < 60 ?
        moment.utc(row.duration * 1000).format('s[s]') :
        (row.duration >= 60 || row.duration < 3600) ?
          moment.utc(row.duration * 1000).format('m[m] ss[s]') :
          moment.utc(row.duration * 1000).format('h[h] m[m] ss[s]')}</p>
    )
  }, {
    dataField: 'score',
    text: 'Score',
    formatter: (cellContent, row) => (
      <>
        {row.score ?
          <Badge
            className={"font-size-12 badge-soft-" + getCallStatusColor(row.score)}
            color={getCallStatusColor(row.score)}
            pill
          >
            {row.score}
          </Badge>
          :
          <p title="Call might be in queue, processing or check if KPIs are configured">---</p>
        }
      </>
    )
  }, {
    dataField: 'category',
    text: 'Category',
    formatter: (cellContent, row) => (
      <p className="text-capitalize">{row.category ? row.category : "---"}</p>
    )
  }]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Calls | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error ?
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
            :
            <GenericTable
              urlParams={urlParams}
              filters={filters}
              columns={columns}
              keyField={'uid'}
              noDataIndication={"No calls found"}
              data={callsObject}
              loading={callLoading}
              // daterangeEnabled={false}
              apiTrigger={apiTrigger}
            />
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

Calls.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default Calls
