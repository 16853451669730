import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const HmsSidebarContent = props => {
  const ref = useRef()
  const isMobile = window.innerWidth <= 991

  const [role, setRole] = useState(null)
  const [userObject, setUserObject] = useState(null)

  useEffect(() => {
    let authObject = JSON.parse(localStorage.getItem("authUser"))
    setRole(authObject.role)
    setUserObject(authObject)
  }, [])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {(location.pathname === "/hms" || isMobile) && (
              <li>
                <Link to="/hms" className=" ">
                  <i className="bx bx-bar-chart-alt-2"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
            )}
            {(location.pathname.startsWith("/hms/patient") || isMobile) && (
              <li>
                <Link to="/hms/patient" className=" ">
                  <i className="bx bx-clinic"></i>
                  <span>Patient Management</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/hms/patient/appointment/book"><span>Book Appointment</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/appointments"><span>Appointments</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/register"><span>Patient Registration</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patients"><span>Patients</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/op/register"><span>OP Registration</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/ops"><span>Out-Patients</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/inpatient/register"><span>Inpatient Admission</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/inpatients"><span>In-Patients</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/insurance"><span>Insurance</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/inpatient/discharge"><span>Discharge</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/patient/treatment"><span>Treatment</span></Link>
                  </li>
                </ul>
              </li>
            )}
            {(location.pathname.startsWith("/hms/staff") || isMobile) && (
              <li>
                <Link to="/hms/staff" className=" ">
                  <i className="bx bx-id-card"></i>
                  <span>Staff Management</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/hms/staff/doctors"><span>Doctors</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/staff/doctors/add"><span>Add Doctor</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/staff/staff"><span>Staff</span></Link>
                  </li>
                </ul>
              </li>
            )}
            {(location.pathname.startsWith("/hms/pharmacy") || isMobile) && (
              <li>
                <Link to="/hms/pharmacy" className=" ">
                  <i className="mdi mdi-pharmacy"></i>
                  <span>Pharmacy</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/hms/pharmacy/home"><span>Home</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/pharmacy/medicines"><span>Medicines</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/pharmacy/stock"><span>Stock</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/pharmacy/order-medicine"><span>Orders</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/pharmacy/supplier"><span>Suppliers</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/pharmacy/medicine-sale"><span>Medicine Sale</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/pharmacy/sold-out-medicines"><span>Sold Out Medicines</span></Link>
                  </li>
                  {/* <li>
                  <Link to="/hms/pharmacy/printer/pdf"><span>PDF Printer</span></Link>
                </li> */}
                </ul>
              </li>
            )}
            {(location.pathname.startsWith("/hms/diagnosis") || isMobile) && (
              <li>
                <Link to="/hms/diagnosis" className=" ">
                  <i className="mdi mdi-clipboard-text-search-outline"></i>
                  <span>Diagnosis</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/hms/diagnosis/home"><span>Home</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/diagnosis/test/book"><span>Book Test</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/diagnosis/test/reports"><span>Reports</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/diagnosis/tests"><span>Tests</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/diagnosis/tests/categories"><span>Test Categories</span></Link>
                  </li>
                  <li>
                    <Link to="/hms/diagnosis/test/test-groups"><span>Test Group</span></Link>
                  </li>
                </ul>
              </li>
            )}
            {(location.pathname.startsWith("/hms/billing") || isMobile) && (
              <li>
                <Link to="/hms/billing" className=" ">
                  <i className="bx bx-wallet"></i>
                  <span>Billing and Finance</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/hms/billing/transactions"><span>Transactions</span></Link>
                  </li>
                </ul>
              </li>
            )}
            {(location.pathname.startsWith("/hms/settings") || isMobile) && (
              <>
                <li className="menu-title">Settings</li>
                <li>
                  <Link to="/hms/settings/administration" className=" ">
                    <i className="bx bx-cog"></i>
                    <span>Administration</span>
                  </Link>
                </li>
                <li>
                  <Link to="/hms/settings/reports" className=" ">
                    <i className="bx bx-chart"></i>
                    <span>Reports</span>
                  </Link>
                </li>
              </>
            )}
            {/* {(["ADMIN", "MANGR"].includes(role)) &&
              <>
                <li>
                  <Link to="/hms/templates" className=" ">
                    <i className="bx bx-notepad"></i>
                    <span>Templates</span>
                  </Link>
                </li>
                <li>
                  <Link to="/users/manual-signup" className=" ">
                    <i className="bx bx-user-plus"></i>
                    <span>Add Users</span>
                  </Link>
                </li>
              </>
            } */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

HmsSidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(HmsSidebarContent))
