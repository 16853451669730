import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, InputGroup, Row } from 'reactstrap';
import Chip from "components/Common/chips";
import Flatpickr from "react-flatpickr"
import { isEmpty } from 'lodash';
import moment from 'moment';
import { updateObject } from 'components/Common/common';
import { useDispatch, useSelector } from 'react-redux';
// import { getCallOptions as onGetFilterState } from "store/actions"

const UserFilters = (props) => {
  const dateRangeRef = useRef()
  const { filters } = props

  const updateDate = dateRange => {
    if (!isEmpty(dateRange)) {
      let tf = updateObject(filters, { date: [moment(dateRange[0]).format("YYYY-MM-DD"), moment(dateRange[1]).format("YYYY-MM-DD")] })
      props.setFilter(tf)
      props.shootFetcher(tf)
    }
  }

  const searchBar = (e) => {
    let value = e.target.value
    if (value==="" || value.length>2) {
      let tObj = filters
      tObj.search = value
      props.setFilter(tObj)
      props.shootFetcher(tObj)
    }
  }

  const removeChip = akey => {
    let tf = {}
    Object.entries(filters).map(([key, value]) => {
      if (key !== akey) {
        tf[key] = value
      }
    })
    props.setFilter(tf)
    if (akey === 'date') dateRangeRef.current.flatpickr.clear()
    props.shootFetcher(tf)
  }

  return (
    <React.Fragment>
      <Row>
        <Col lg="9">
          {Object.entries(filters).map(([k, v], idx) =>
            (v !== undefined && v !== null && v !== '' && v.length>0) && 
            <Chip key={idx} akey={k} value={v} onClick={removeChip} />
          )}
        </Col>
        <Col lg="3">
          {props.hideDateRangePicker ? null :
          <div className="ms-auto">
            <InputGroup>
              <Flatpickr
                className="form-control form-control"
                placeholder="Select joined date"
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d"
                }}
                ref={dateRangeRef}
                onClose={updateDate}
              />
            </InputGroup>
          </div>
          }
        </Col>
      </Row>

      <Row className="mb-2">
        <Col md="6">
          <div className="search-box mb-2 d-inline-block">
            <div className="position-relative">
              <input
                className="form-control"
                type="search"
                placeholder="Search here..."
                onInput={searchBar}
                defaultValue={filters ? filters.search : ""}
              />
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>
        </Col>
      </Row>

    </React.Fragment>
  )
}
export default UserFilters


export const UserSideFilters = (props) => {
  const { filters } = props
  const dispatch = useDispatch()
  const [filterInitialState, setFilterInitialState] = useState(null)

  const { filterState, error } = useSelector(state => ({
    filterState: state.Calls.filterState,
    error: state.Calls.error
  }))

  useEffect(() => {
    dispatch(onGetFilterState())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(filterState)) {
      let branches = [], groups = []
      filterState.branches.map(branch => {
        groups.push(branch.groups)
        branches.push(branch)
      })
      setFilterInitialState({
        branches: branches,
        groups: groups,
        user_types: filterState.user_types
      })
    }
  }, [filterState])

  const updatePerformance = (e) => {
    props.setFilter(prevState => ({...prevState, performance: e.target.value }))
    setFilterInitialState(prevState => ({ ...prevState, performance: e.target.value }))
  }

  const updateBranch = (branch, flag) => {
    let branches = filters.branch ? filters.branch : []
    if (flag) {
      branches.push(branch)
    } else {
      branches = branches.filter(item => item !== branch)
    }
    if (isEmpty(branches)) {
      let filt = filters
      delete filt.branch
      props.setFilter(filt)
    } else {
      props.setFilter(prevState => ({ ...prevState, branch: branches }))
    }
    let tObj = filters
    tObj.branch = branches
    props.shootFetcher(tObj)
    setFilterInitialState(prevState => ({ ...prevState, branches: prevState.branches.map(v => v.label === branch ? { ...v, value: flag } : v) }))
  }

  const updateGroup = (group, flag) => {
    let groups = filters.group ? filters.group : []
    if (flag) {
      groups.push(group)
    } else {
      groups = groups.filter(item => item !== group)
    }
    if (isEmpty(groups)) {
      let filt = filters
      delete filt.group
      props.setFilter(filt)
    } else {
      props.setFilter(prevState => ({ ...prevState, group: groups }))
    }
    let tObj = filters
    tObj.group = groups
    props.shootFetcher(tObj)
    setFilterInitialState(prevState => ({ ...prevState, groups: prevState.groups.map(v => v.label === group ? { ...v, value: flag } : v) }))
  }

  const updateUserType = (uType, flag) => {
    let uTypes = filters.user_types ? filters.user_types : []
    if (flag) {
      uTypes.push(uType)
    } else {
      uTypes = uTypes.filter(item => item!==uType)
    }
    let tObj = filters
    tObj.user_types = uTypes
    props.shootFetcher(tObj)
    setFilterInitialState(prevState => ({...prevState, user_types: prevState.user_types.map(v => v.label===uType ? {...v, value: flag } : v )} ))
  }

  const clearFilters = () => {
    props.setFilter({})
    props.updateURLParams({})
    props.shootFetcher()
  }


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div>
            {!isEmpty(filters) &&
              <Button type="button" color="danger" className="btn-sm" outline onClick={clearFilters}>Clear filters</Button>
            }
          </div>
          {filterInitialState ?
            <>
              {/* <div className="mt-4 pt-3">
                <h5 className="font-size-14 mb-4">Performance</h5>
                <input type="range" className="form-range" min="0" max={"100"} step="1" value={filterInitialState.performance ? filterInitialState.performance : 0} id="performance" onChange={updatePerformance} />
                <div className="d-flex justify-content-between">
                  <p></p>
                  <h5>{Math.ceil(filterInitialState.performance ? filterInitialState.performance : 0 / 60)}</h5>
                  <p>100</p>
                </div>
              </div> */}

              <div className="mt-4 pt-3">
                <h5 className="font-size-14 mb-2">Branches</h5>
                <br />
                {filterInitialState.branches.map((branch, idx) =>
                  <div key={idx} className="mb-1">
                    <ul className="metismenu list-unstyled">
                      <li>
                        <div className="has-arrow">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={branch.name}
                            value={branch.name}
                            checked={(filters.branch && filters.branch.includes(branch.name)) ? true : false}
                            onChange={(e) => updateBranch(e.target.value, e.target.checked)}
                          />
                          <label className="form-check-label ms-2 text-capitalize" htmlFor={branch.name}>
                            {branch.name}
                          </label>
                        </div>
                        <ul className="sub-menu" aria-expanded="false">
                          <li>
                            {branch.groups.map((group, gidx) =>
                              <div key={gidx}>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={group.name}
                                  value={group.uid}
                                  checked={(filters.group && filters.group.includes(group.uid)) ? true : false}
                                  onChange={(e) => updateGroup(e.target.value, e.target.checked)}
                                />
                                <label className="form-check-label ms-2 text-capitalize" htmlFor={group.name}>
                                  {group.name}
                                </label>
                                <br />
                              </div>
                            )}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="mt-4 pt-3">
                <h5 className="font-size-14 mb-2">User Types</h5>
                <br />
                {filterInitialState.user_types.map((userType, idx) => 
                <div key={idx} className="mb-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={userType.label}
                    value={userType.label}
                    checked={(filters.user_types && filters.user_types.includes(userType.label)) ? true : userType.value}
                    onChange={(e) => updateUserType(e.target.value, e.target.checked)}
                  />
                  <label className="form-check-label ms-2 text-capitalize" htmlFor={userType.title}>
                    {userType.title}
                  </label>
                  <br />
                </div>
                )}
              </div>
            </>
            :
            <p>Looks no data existed!</p>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
