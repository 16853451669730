import { call, put, takeEvery, all, fork, takeLatest } from "redux-saga/effects";

// Crypto Redux States
import { GET_DASHBOARD_DATA, API_SUCCESS, API_FAIL, ALL_USERS_SUCCESS, ALL_USERS_FAIL, ALL_USERS, AGENT_DATA_SUCCESS, AGENT_DATA_FAIL, AGENT_DATA, BRANCH_OPTIONS_SUCCESS, BRANCH_OPTIONS_FAIL, BRANCH_OPTIONS, BRANCH_GROUPS_OPTIONS, BRANCH_GROUPS_OPTIONS_SUCCESS, BRANCH_GROUPS_OPTIONS_FAIL, GET_BRANCH_DATA_FAIL, GET_BRANCH_DATA, GET_BRANCH_DATA_SUCCESS, GET_PENDING_REQUESTS_SUCCESS, GET_PENDING_REQUESTS_FAIL, UPDATE_PENDING_REQUESTS_SUCCESS, UPDATE_PENDING_REQUESTS_FAIL, UPDATE_PENDING_REQUESTS, GET_PENDING_REQUESTS, GET_DYNAMIC_USER_OPTIONS_SUCCESS, GET_DYNAMIC_USER_OPTIONS_FAIL, GET_DYNAMIC_USER_OPTIONS, RESEND_ACTIVATIONLINK_SUCCESS, RESEND_ACTIVATIONLINK_FAIL, RESEND_ACTIVATIONLINK, GET_USER_OPTIONS } from "./actionTypes";
import { apiSuccess, apiFail, getPendingRequestsSuccess, getPendingRequestsFail, updatePendingRequestsSuccess, updatePendingRequestsFail, getPendingRequestsUserTypesSuccess, getPendingRequestsUserTypesFail, resendActivationLinkSuccess, resendActivationLinkFail, getUserOptionsFail, getUserOptionsSuccess } from "./actions";

//Include Both Helper File with needed methods
import { fetchAgentDataAPI, fetchAllUsersAPI, fetchBranchDataAPI, fetchBranchGroupsOptionsAPI, fetchBranchOptionsAPI, getApiData, getPendingRequestsAPI, getDynamicUserOptionsAPI, updatePendingRequestsAPI, resendActivationLinkAPI, getUserOptionsAPI } from "./api_helper";
import { allUsersFail, allUsersSuccess, getAgentDataFail, getAgentDataSuccess, getBranchDataFail, getBranchDataSuccess, getBranchGroupsOptionsFail, getBranchGroupsOptionsSuccess, getBranchOptionsFail, getBranchOptionsSuccess } from "store/actions";

function* getDashboardData({ queryParamObject }) {
    try {
        var response = yield call(getApiData, queryParamObject);
        yield put(apiSuccess(API_SUCCESS, response));
    } catch (error) {
        yield put(apiFail(API_FAIL, error.response.data));
    }
}

function* fetchBranchData({ branchUID, queryParamObject }) {
    try {
        var response = yield call(fetchBranchDataAPI, branchUID, queryParamObject);
        yield put(getBranchDataSuccess(GET_BRANCH_DATA_SUCCESS, response));
    } catch (error) {
        yield put(getBranchDataFail(GET_BRANCH_DATA_FAIL, error.response.data));
    }
}

function* onGetUserOptionsSaga({ params }) {
    try {
        const response = yield call(getUserOptionsAPI, params)
        yield put(getUserOptionsSuccess(response))
    } catch (error) {
        yield put(getUserOptionsFail(error.response.data))
    }
}

function* fetchAllUsers({ queryParamObject }) {
    try {
        var response = yield call(fetchAllUsersAPI, queryParamObject);
        yield put(allUsersSuccess(ALL_USERS_SUCCESS, response));
    } catch (error) {
        yield put(allUsersFail(ALL_USERS_FAIL, error.response.data));
    }
}

function* fetchAgentData({ userID, queryParamObject }) {
    try {
        var response = yield call(fetchAgentDataAPI, userID, queryParamObject);
        yield put(getAgentDataSuccess(AGENT_DATA_SUCCESS, response));
    } catch (error) {
        yield put(getAgentDataFail(AGENT_DATA_FAIL, error.response.data));
    }
}

function* fetchBranchOptions() {
    try {
        var response = yield call(fetchBranchOptionsAPI);
        yield put(getBranchOptionsSuccess(BRANCH_OPTIONS_SUCCESS, response));
    } catch (error) {
        yield put(getBranchOptionsFail(BRANCH_OPTIONS_FAIL, error.response.data));
    }
}

function* fetchBranchGroupsOptions({ branchUID }) {
    try {
        var response = yield call(fetchBranchGroupsOptionsAPI, branchUID);
        yield put(getBranchGroupsOptionsSuccess(BRANCH_GROUPS_OPTIONS_SUCCESS, response));
    } catch (error) {
        yield put(getBranchGroupsOptionsFail(BRANCH_GROUPS_OPTIONS_FAIL, error.response.data));
    }
}

function* getDynamicUserOptionsSaga({ payload }) {
    try {
        var response = yield call(getDynamicUserOptionsAPI, payload);
        yield put(getPendingRequestsUserTypesSuccess(GET_DYNAMIC_USER_OPTIONS_SUCCESS, response));
    } catch (error) {
        yield put(getPendingRequestsUserTypesFail(GET_DYNAMIC_USER_OPTIONS_FAIL, error.response.data));
    }
}

function* getPendingRequestsSaga({ filterParams }) {
    try {
        var response = yield call(getPendingRequestsAPI, filterParams);
        yield put(getPendingRequestsSuccess(GET_PENDING_REQUESTS_SUCCESS, response));
    } catch (error) {
        yield put(getPendingRequestsFail(GET_PENDING_REQUESTS_FAIL, error.response.data));
    }
}

function* resendActivationLinksSaga({ usernames }) {
    try {
        var response = yield call(resendActivationLinkAPI, usernames);
        yield put(resendActivationLinkSuccess(RESEND_ACTIVATIONLINK_SUCCESS, response));
    } catch (error) {
        yield put(resendActivationLinkFail(RESEND_ACTIVATIONLINK_FAIL, error.response.data));
    }
}

function* updatePendingRequestsSaga({ usernames }) {
    try {
        var response = yield call(updatePendingRequestsAPI, usernames);
        yield put(updatePendingRequestsSuccess(UPDATE_PENDING_REQUESTS_SUCCESS, response));
    } catch (error) {
        yield put(updatePendingRequestsFail(UPDATE_PENDING_REQUESTS_FAIL, error.response.data));
    }
}

export function* watchGetDashboardGenericData() {
    yield takeEvery(GET_DASHBOARD_DATA, getDashboardData);
    yield takeEvery(GET_BRANCH_DATA, fetchBranchData);
    yield takeEvery(GET_USER_OPTIONS, onGetUserOptionsSaga);
    yield takeEvery(ALL_USERS, fetchAllUsers);
    yield takeEvery(AGENT_DATA, fetchAgentData);
    yield takeEvery(BRANCH_OPTIONS, fetchBranchOptions);
    yield takeEvery(BRANCH_GROUPS_OPTIONS, fetchBranchGroupsOptions);
    yield takeEvery(GET_DYNAMIC_USER_OPTIONS, getDynamicUserOptionsSaga);
    yield takeEvery(GET_PENDING_REQUESTS, getPendingRequestsSaga);
    yield takeEvery(RESEND_ACTIVATIONLINK, resendActivationLinksSaga);
    yield takeEvery(UPDATE_PENDING_REQUESTS, updatePendingRequestsSaga);
}

function* dashboardSaga() {
    yield all([fork(watchGetDashboardGenericData)]);
}

export default dashboardSaga;
