import {
    GET_CALL_DETAIL_WAVEFORM_SUCCESS,
    GET_CALL_DETAIL_WAVEFORM_FAIL,
    GET_CALL_DETAIL_WAVEFORM,
    GET_CALL_DETAIL_IN_DEPTH_SUCCESS,
    GET_CALL_DETAIL_IN_DEPTH_FAIL,
    GET_CALL_DETAIL_IN_DEPTH,
    GET_SCORES,
    GET_SCORES_SUCCESS,
    GET_SCORES_FAIL
} from "./actionTypes";

export const getWaveformData = callID => ({
    type: GET_CALL_DETAIL_WAVEFORM,
    callID,
})
export const getWaveformDataSuccess = waveFormData => ({
    type: GET_CALL_DETAIL_WAVEFORM_SUCCESS,
    payload: waveFormData,
})
export const getWaveformDataFail = detailError => ({
    type: GET_CALL_DETAIL_WAVEFORM_FAIL,
    payload: detailError,
})

export const getCallInDepthData = callID => ({
    type: GET_CALL_DETAIL_IN_DEPTH,
    callID,
})
export const getCallInDepthDataSuccess = waveFormData => ({
    type: GET_CALL_DETAIL_IN_DEPTH_SUCCESS,
    payload: waveFormData,
})
export const getCallInDepthDataFail = detailError => ({
    type: GET_CALL_DETAIL_IN_DEPTH_FAIL,
    payload: detailError,
})

export const getScores = callID => ({
    type: GET_SCORES,
    callID,
})
export const getScoresSuccess = evaluationScores => ({
    type: GET_SCORES_SUCCESS,
    payload: evaluationScores,
})
export const getScoresFail = detailError => ({
    type: GET_SCORES_FAIL,
    payload: detailError,
})
