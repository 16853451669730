import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { CardBody, Card, Row, Col, CardTitle, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Collapse, Badge, Spinner, UncontrolledAlert } from "reactstrap"
import { getHms, getHmsOptions, postHms, postUploadHms, resetHmsState } from "store/hms/actions"
import { get } from "helpers/api_helper";
import { AvForm } from "availity-reactstrap-validation"
import { isEmpty } from "lodash"
import { TableChip, formatDateTime } from "components/Common/common"
import { AsyncSearch, EAsyncSelect, EAvFieldDiscount, EAvFieldInput, EAvFieldSelect } from "pages/HMS/common/errored-avfields"
import Flatpickr from "react-flatpickr";
import moment from "moment"
import { Loader, PatientInformation, handleError } from "pages/HMS/common/common"
import ReportDownloadButton from "components/Common/print-report"
import toastr from "toastr"
import Dropzone from "react-dropzone";
import { cleanFilenameForSign } from "components/Common/common";

const InpatientDischarge = (props) => {
  const dispatch = useDispatch()
  const { loading, error, options, hmsObject, formError, apiKey, hmsSingleObject, signedURLs } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    options: state.Hms.options,
    hmsObject: state.Hms.hmsObject,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    hmsSingleObject: state.Hms.hmsSingleObject,
    signedURLs: state.Hms.signedURLs,
  }))

  const [patientSearch, setPatientSearch] = useState(true)
  const [selectedFiles, setSelectedFiles] = useState({})
  const [isShowUpload, setIsShowUpload] = useState(true)
  const [signedLinks, setSignedLinks] = useState([])
  const [patient, setPatient] = useState({})
  const [dischargeSummary, setDischargeSummary] = useState({})
  const [showDischarge, setShowDischarge] = useState(false)
  const [customActiveTab, setCustomActiveTab] = useState("1")
  const [accordion, setAccordion] = useState({ pharmacy: true, bed: false, prescription: true, healthStatus: false });
  const [discountedPerson, setDiscountedPerson] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState({ searchPatients: false, discountedPerson: false })
  const [customError, setCustomError] = useState({ insuranceClaimedAmount: false });
  const [dischargeData, setDischargeData] = useState({
    pharmacySubtotal: 0,
    bedSubtotal: 0,
    subTotal: 0,
    insuranceClaimedAmount: 0,
    pendingAmount: 0,
    discountPercentage: 0,
    discountAmount: 0,
    grandTotal: 0,
    cgstAmount: 0,
    sgstAmount: 0,
    cgstPercentage: 0,
    sgstPercentage: 0,
    paymentMode: "",
    chequeNumber: null,
    chequeDate: null,
  })

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/inpatients/?options=formschema", "disc_ip_options", true))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "selected_patient") {
      setPatient({
        ...hmsObject,
        current_bed: (hmsObject?.bed_history_data || []).reduce((max, current) => max?.id > current?.id ? max : current, null)
      })
      setDischargeData(prevState => ({
        ...prevState,
        pharmacySubtotal: hmsObject?.pharmacy?.reduce((acc, pharmacy) => acc + pharmacy.medicines.reduce((medAcc, medicine) => medAcc + medicine.total_cost, 0), 0),
      }));
    }

    if (apiKey === "discharge_ip_post") setShowDischarge(true)
    if (apiKey === "discharge_summary") setDischargeSummary(hmsObject)
    if (apiKey === "upload_discharge") setIsShowUpload(false)
    if (formError && apiKey.startsWith("fail_discharge")) handleError(formError, apiKey, "fail_discharge")
  }, [apiKey])

  useEffect(() => {
    if (customActiveTab === "3") {
      dispatch(getHms(`/hms/patient/inpatients/${patient.id}/discharge_summary/`, null, "discharge_summary"))
    }
  }, [customActiveTab])

  useEffect(() => {
    if (!isEmpty(signedURLs)) {
      let filenames = []
      Promise.all(signedURLs.map(signedURL => fetch(signedURL.safelink, {
        method: 'PUT',
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: selectedFiles[signedURL.uid]
      }))).then(responses => {
        responses.map(response => {
          if (response.status === 200) {
            filenames.push(response.url)
          }
        })
        if (!isEmpty(filenames)) {
          filenames.map((filename) => {
            setSignedLinks((preVal) => [
              ...preVal,
              {
                uid: Date.now() + Math.floor(Math.random() * 1000),
                file_path: filename.match(/hms\/[^?]+/)[0],
                file_name: filename.match(/\/discharge\/(.*?)\?X/)[1]
              }
            ]);
          });
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }, [signedURLs])

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab)
    }
  }
  var suTo
  const searchPatients = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, searchPatients: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/inpatients/?discharge_status=false&search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const patientSelect = (pat) => {
    if (!isEmpty(pat)) {
      dispatch(getHms(`/hms/patient/inpatients/${pat.id}/get_discharge_info/`, null, "selected_patient"))
      setPatientSearch(false)
    }
  }

  const searchDiscountUsers = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, discountedPerson: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/inpatients/search_hms_users/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 200)
    }
  }

  const toggleAccordion = (item) => {
    let newAccordionState = {
      pharmacy: true,
      bed: false,
      prescription: true,
      healthStatus: false,
    };

    switch (item) {
      case 'pharmacy':
        newAccordionState = {
          ...newAccordionState,
          pharmacy: !accordion.pharmacy,
          bed: false,
        };
        break;
      case 'bed':
        newAccordionState = {
          ...newAccordionState,
          pharmacy: false,
          bed: !accordion.bed,
        };
        break;
      case 'prescription':
        newAccordionState = {
          ...newAccordionState,
          prescription: !accordion.prescription,
          healthStatus: false,
        };
        break;
      case 'healthStatus':
        newAccordionState = {
          ...newAccordionState,
          prescription: false,
          healthStatus: !accordion.healthStatus,
        };
        break;
      default:
        break;
    }

    setAccordion(newAccordionState);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const insuranceClaimedAmount = e.target.value.replace(/\D/g, '');
    if (insuranceClaimedAmount <= (dischargeData?.pharmacySubtotal + (patient?.bed_subtotal || 0))) {
      setCustomError({ insuranceClaimedAmount: false })
      setDischargeData(prevState => ({ ...prevState, insuranceClaimedAmount }));
    } else {
      toastr.error("Claimed amount should not exceed grand total");
    }
  };

  const onDiscountPercentChange = (value) => {
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setDischargeData(prevState => ({ ...prevState, discountPercentage: value }));
    }
  }

  const floatRoundDown = (value, decimalPlaces = 2) => {
    return Math.floor(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
  }

  const onCgstPercentageChange = (e) => {
    const cgstPercentage = formatPercentage(e.target.value);
    setDischargeData(prevState => ({ ...prevState, cgstPercentage }));
  };

  const onSgstPercentageChange = (e) => {
    const sgstPercentage = formatPercentage(e.target.value);
    setDischargeData(prevState => ({ ...prevState, sgstPercentage }));
  };

  const formatPercentage = (value) => {
    const formattedValue = value.replace(/[^\d.]/g, '');
    const [integerPart, decimalPart] = formattedValue.includes('.') ? formattedValue.split('.').map((part) => part.slice(0, 2)) : [formattedValue.slice(0, 2), ''];
    return `${integerPart}${decimalPart && `.${decimalPart}`}`;
  };

  const calculateTotals = () => {
    const pharmacySubtotal = patient?.pharmacy?.reduce((acc, pharmacy) => acc + pharmacy.medicines.reduce((medAcc, medicine) => medAcc + medicine.total_cost, 0), 0) || 0
    const bedSubtotal = floatRoundDown(patient?.bed_subtotal || 0)
    const subTotal = floatRoundDown((pharmacySubtotal + bedSubtotal) - dischargeData?.insuranceClaimedAmount)
    const discountAmount = floatRoundDown(subTotal * (dischargeData?.discountPercentage / 100));
    const discountedSubTotal = floatRoundDown(subTotal - discountAmount);
    const cgstAmount = floatRoundDown(discountedSubTotal * (dischargeData?.cgstPercentage / 100));
    const sgstAmount = floatRoundDown(discountedSubTotal * (dischargeData?.sgstPercentage / 100));
    const grandTotal = floatRoundDown(discountedSubTotal + dischargeData?.pendingAmount + cgstAmount + sgstAmount);

    setDischargeData(prevState => ({ ...prevState, subTotal, discountAmount, grandTotal, cgstAmount, sgstAmount }));
  };

  useEffect(() => {
    calculateTotals();
  }, [dischargeData.insuranceClaimedAmount, dischargeData.discountPercentage, dischargeData.cgstPercentage, dischargeData.sgstPercentage, patient?.bed_subtotal, dischargeData?.pharmacySubtotal]);

  const handleSubmit = (e, values) => {
    values = {
      billing: {
        customer_name: patient?.patient_data?.user_data?.full_name,
        module: "Discharge",
        payment_mode: values.payment_mode,
        cgst_percent: dischargeData?.cgstPercentage,
        sgst_percent: dischargeData?.sgstPercentage,
        cgst: dischargeData?.cgstAmount,
        sgst: dischargeData?.sgstAmount,
        sub_total_amount: dischargeData?.subTotal,
        discount_percent: dischargeData?.discountPercentage,
        discount_amount: dischargeData?.discountAmount,
        discount_type: values?.discount_type,
        discount_by: discountedPerson?.id,
        grand_total_amount: dischargeData?.grandTotal,
        cheque_number: dischargeData?.paymentMode === "CHEQUE" ? dischargeData?.chequeNumber : null,
        cheque_date: dischargeData?.paymentMode === "CHEQUE" ? dischargeData?.chequeDate : null,
      },
      insurance_claim_amount: dischargeData?.insuranceClaimedAmount || 0,
      bed: {
        sub_total: patient?.bed_subtotal || 0,
        data: patient?.bed_history_data?.map(item => ({ id: item?.id, from_date: item?.from_date, to_date: item?.to_date, unit: item?.bed_detail?.unit || 0, price: item?.bed_detail?.price })),
      },
      pharmacy: {
        sub_total: dischargeData?.pharmacySubtotal,
        data: patient?.pharmacy,
      },
    }

    if (!isEmpty(values) && typeof values === 'object' && patient?.id) {
      dispatch(postHms(`/hms/patient/inpatients/${patient?.id}/discharge/`, values, "discharge_ip_post"))
    }
  }

  const updateKeys = (id, value, fromDate, toDate) => {
    setPatient(prevPatient => {
      const bedHistoryData = prevPatient.bed_history_data.map(item => {
        if (item.id === id) {
          return {
            ...item,
            bed_detail: {
              ...item.bed_detail,
              unit: value
            },
            from_date: fromDate,
            to_date: toDate
          };
        }
        return item;
      });

      return {
        ...prevPatient,
        bed_history_data: bedHistoryData,
        bed_subtotal: bedHistoryData?.reduce((acc, bed) => acc + parseFloat(bed.bed_detail.price) * (bed.bed_detail.unit || 0), 0) || 0
      };
    });
  };

  const handleAcceptedFiles = (files) => {
    let filenames = []
    let callBucket = {}
    files.map(file => {
      let uid = Date.now() + Math.floor(Math.random() * 1000)
      let filename = cleanFilenameForSign(file.name)
      filenames.push({ uid: uid, filename: filename })
      callBucket[uid] = file
    })
    setSelectedFiles((prevFiles) => ({ ...prevFiles, ...callBucket }))
    let payload = { filenames: filenames, target: "discharge" }
    dispatch(postUploadHms("/hms/uploader/upload/", payload, "upload_discharge"))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inpatient Discharge | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          {patientSearch ? (
            <Col xl={8}>
              <Card>
                <CardBody>
                  <AsyncSearch
                    onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, searchPatients: false }))}
                    menuIsOpen={isMenuOpen.searchPatients}
                    loadOptions={searchPatients}
                    onChange={patientSelect}
                    placeholder={"Search inpatients"}
                    noOptionsMessage={() => (
                      <p className="text-center my-4" style={{ letterSpacing: '1px' }}>
                        No patients found to discharge
                      </p>
                    )}
                    getOptionLabel={(e) => (
                      <div className="custom-option">
                        <div className="user-box">
                          <p className="user-title m-0">{e.patient_data.user_data.full_name}</p>
                          <p className="text-muted mb-0">
                            {e.patient_data.uid} {e.patient_data.user_data.email ? `| ${e.patient_data.user_data.email}` : ""} | {e.patient_data.user_data.phone_number}
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          ) : (
            <Col xl={apiKey === "discharged_ip" ? 6 : 12}>
              <Card>
                <CardBody>
                  {error ? (
                    <p className="text-center text-danger">{error.detail}</p>
                  ) : (apiKey === "discharged_ip" ? (
                    <div className="p-2">
                      <div className="text-center">
                        <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                        <div className="p-2 mt-2">
                          <h4>Patient discharged successfully.</h4>
                          <div className="mt-4">
                            <div className="d-flex justify-content-evenly">
                              <ReportDownloadButton
                                className={"btn btn-primary"}
                                url={`/hms/patient/inpatient_discharge/${hmsSingleObject.id}/download_report/`}
                                message={"Report downloaded"}
                              />
                              <Button
                                color="primary"
                                style={{ minWidth: "120px" }}
                                onClick={() => window.location.replace("/hms/patient/inpatient/discharge")}
                              >
                                OK
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : loading && apiKey === "get_selected_patient" ? (
                    <Loader />
                  ) : (
                    <>
                      <Nav tabs className="nav-tabs-custom nav-justified mb-4">
                        <NavItem>
                          <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "1", })} onClick={() => toggleCustom("1")}>
                            <span className="d-block d-sm-none">
                              <i className="fa fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">Patient Information</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "2" })} onClick={() => toggleCustom("2")} >
                            <span className="d-block d-sm-none">
                              <i className="bx bx-receipt"></i>
                            </span>
                            <span className="d-none d-sm-block">Billing Summary</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink style={{ cursor: "pointer" }} className={classnames({ active: customActiveTab === "3" })} onClick={() => toggleCustom("3")} disabled={!showDischarge}>
                            <span className="d-block d-sm-none">
                              <i className="bx bxs-user-check"></i>
                            </span>
                            <span className="d-none d-sm-block">Discharge</span>
                          </NavLink>
                        </NavItem>
                        <NavItem></NavItem>
                        <NavItem></NavItem>
                      </Nav>
                      <TabContent activeTab={customActiveTab} className="text-muted">
                        <TabPane tabId="1">
                          <Row>
                            <Col>
                              <h5 className="mt-2">Patient information</h5>
                              <hr />
                              {patient?.patient_data?.user_data ? (
                                <PatientInformation
                                  data={patient?.patient_data}
                                  includedFields={[
                                    "patient_name",
                                    "patient_id",
                                    "gender",
                                    "marital_status",
                                    "date_of_birth",
                                    "age",
                                    "mobile",
                                    "email",
                                  ]}
                                />
                              ) : (
                                <p className="text-center text-danger">Patient details not existed</p>
                              )}
                              <h5 className="mt-2">Admission Details</h5>
                              <hr />
                              {patient?.created_at ? (
                                <TableChip
                                  label="Admitted on"
                                  value={formatDateTime(patient?.created_at)}
                                  defaultIfNull="NA"
                                />
                              ) : (
                                <p className="text-center text-danger">Admission details not existed</p>
                              )}
                              <h5 className="mt-2">Address</h5>
                              <hr />
                              {patient?.patient_data?.address_data ? (
                                <Col>
                                  <TableChip
                                    value={patient?.patient_data?.address_data?.line1}
                                    defaultIfNull="NA"
                                  />
                                  <TableChip
                                    value={patient?.patient_data?.address_data?.line2}
                                    defaultIfNull="NA"
                                  />
                                  <Row>
                                    <Col>
                                      <TableChip
                                        value={patient?.patient_data?.address_data?.city}
                                        defaultIfNull="NA"
                                      />
                                    </Col>
                                    <Col>
                                      <TableChip
                                        value={patient?.patient_data?.address_data?.state}
                                        defaultIfNull="NA"
                                      />
                                    </Col>
                                    <Col>
                                      <TableChip
                                        value={patient?.patient_data?.address_data?.pincode}
                                        defaultIfNull="NA"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              ) : (
                                <p className="text-center text-danger">
                                  Address details not existed
                                </p>
                              )}
                            </Col>
                            <Col>
                              <h5 className="mt-2">Caretakers</h5>
                              <hr />
                              {patient?.duty_doctor_data || patient?.consulting_doctor_data || patient?.nurse_data ? (
                                <div className="d-flex justify-content-between">
                                  <TableChip
                                    label="Duty Doctor"
                                    value={patient?.duty_doctor_data?.user_data?.full_name}
                                    defaultIfNull="NA"
                                  />
                                  <TableChip
                                    label="Consulting Doctor"
                                    value={patient?.consulting_doctor_data?.user_data?.full_name}
                                    defaultIfNull="NA"
                                  />
                                  <TableChip
                                    label="Nurse"
                                    value={patient?.nurse_data?.user_data?.full_name}
                                    defaultIfNull="NA"
                                  />
                                </div>
                              ) : (
                                <p className="text-center text-danger">Caretakers details not existed</p>
                              )}
                              <h5 className="mt-2">Insurance</h5>
                              <hr />
                              {patient?.health_insurance ?
                                <>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Policy number"
                                      value={patient?.health_insurance?.policy_number}
                                      defaultIfNull="NA"
                                    />
                                    <TableChip
                                      label="Issuer"
                                      value={patient?.health_insurance?.issuer_name}
                                      defaultIfNull="NA"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Valid till"
                                      value={formatDateTime(patient?.health_insurance?.policy_valid_till, "ll")}
                                      defaultIfNull="NA"
                                    />
                                    <TableChip
                                      label="Current status"
                                      value={patient?.health_insurance?.status}
                                      defaultIfNull="NA"
                                    />
                                  </div>
                                </>
                                :
                                <p className="text-center text-danger">Insurance details not existed</p>
                              }
                              <h5 className="mt-2">Room Details</h5>
                              <hr />
                              {patient?.current_bed ? (
                                <>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Ward"
                                      value={patient?.current_bed?.bed_detail?.ward_name}
                                      defaultIfNull="NA"
                                    />
                                    <TableChip
                                      label="Room Category"
                                      value={patient?.current_bed?.bed_detail?.room_category_name} defaultIfNull="NA"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Floor"
                                      value={patient?.current_bed?.bed_detail?.floor}
                                      defaultIfNull="NA"
                                    />
                                    <TableChip
                                      label="Room Number"
                                      value={patient?.current_bed?.bed_detail?.room_number}
                                      defaultIfNull="NA"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Bed Number"
                                      value={patient?.current_bed?.bed_detail?.bed_number}
                                      defaultIfNull="NA"
                                    />
                                    <TableChip />
                                  </div>
                                </>
                              ) : (
                                <p className="text-center text-danger">No bed information available</p>
                              )}
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col lg={8}>
                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFlushOne">
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        "d-flex",
                                        "justify-content-between",
                                        { collapsed: !accordion.pharmacy }
                                      )}
                                      type="button"
                                      onClick={() => toggleAccordion("pharmacy")}
                                      style={{ cursor: "pointer", width: "100%", backgroundColor: "#EEF1FD" }}
                                    >
                                      <strong className="flex-grow-1">Pharmacy</strong>
                                      <span className="text-end me-2">Subtotal - {dischargeData?.pharmacySubtotal}</span>
                                    </button>
                                  </h2>
                                  <Collapse isOpen={accordion.pharmacy} className="accordion-collapse">
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          {!isEmpty(patient?.pharmacy) ? (
                                            patient?.pharmacy?.map((pharmacy, index) => (
                                              <Table key={index}>
                                                <thead className="thead-light text-capitalize">
                                                  <tr>
                                                    <th colSpan="8">Sale @{formatDateTime(pharmacy?.created_at)}</th>
                                                  </tr>
                                                  <tr>
                                                    <th>S No</th>
                                                    <th>Medicine Name</th>
                                                    <th>Batch No</th>
                                                    <th>Unit</th>
                                                    <th>MRP</th>
                                                    <th>Subtotal</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {pharmacy?.medicines.map((med, index) => (
                                                    <tr key={index}>
                                                      <td>{index + 1}</td>
                                                      <td>{med?.medicine}</td>
                                                      <td>{med?.batch_number}</td>
                                                      <td>{med?.unit}</td>
                                                      <td>{med?.mrp}</td>
                                                      <td>{med?.total_cost}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                                <tfoot>
                                                  <tr>
                                                    <td colSpan="3"></td>
                                                    <th colSpan="2" style={{ textAlign: "right" }}>Total Amount:</th>
                                                    <td>{pharmacy?.medicines?.reduce((sum, med) => sum + med.total_cost, 0) || 0}</td>
                                                  </tr>
                                                </tfoot>
                                              </Table>
                                            ))
                                          ) : (
                                            <Table className="mt-2">
                                              <tbody>
                                                <tr>
                                                  <th colSpan="6" className="text-center">No pending pharmacy bill</th>
                                                </tr>
                                              </tbody>
                                            </Table>
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Collapse>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="headingFlushOne">
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        "d-flex",
                                        "justify-content-between",
                                        { collapsed: !accordion.bed }
                                      )}
                                      type="button"
                                      onClick={() => toggleAccordion("bed")}
                                      style={{ cursor: "pointer", width: "100%", backgroundColor: "#EEF1FD" }}
                                    >
                                      <strong className="flex-grow-1">Bed</strong>
                                      <span className="text-end me-2">Subtotal - {patient?.bed_subtotal || 0}</span>
                                    </button>
                                  </h2>
                                  <Collapse isOpen={accordion.bed} className="accordion-collapse">
                                    <div className="table-responsive mb-2">
                                      {!isEmpty(patient?.bed_history_data) ? (
                                        patient?.bed_history_data?.map((bed) => (
                                          <BedTable key={bed.id} bed={bed} updateKeys={updateKeys} admissionDate={patient?.created_at} />
                                        ))
                                      ) : (
                                        <Table className="mt-2">
                                          <tbody>
                                            <tr>
                                              <th colSpan="6" className="text-center">No pending bed charges</th>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      )}
                                    </div>
                                  </Collapse>
                                </div>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <CardTitle className="mt-2 d-flex justify-content-between">
                                <span>Pharmacy</span>
                                <span>{dischargeData?.pharmacySubtotal}</span>
                              </CardTitle>
                              <hr />
                              <CardTitle className="mt-3 d-flex justify-content-between">
                                <span>Bed Charges</span>
                                <span>{patient?.bed_subtotal || 0}</span>
                              </CardTitle>
                              <hr />
                              <div className="table-responsive">
                                <AvForm onValidSubmit={handleSubmit}>
                                  <Table className="table mb-0 table table-sm">
                                    <tbody>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>Payable Total :</span>
                                          <span>{dischargeData?.pharmacySubtotal + (patient?.bed_subtotal || 0)}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>Insurance Claimed Amount :</span>
                                          <div style={{ width: "145px" }}>
                                            <input
                                              type="text"
                                              name="discount"
                                              className={`form-control-sm mb-0 text-end ${customError.insuranceClaimedAmount}`}
                                              style={{ width: "145px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                              pattern="\d*"
                                              onChange={handleInputChange}
                                              value={dischargeData?.insuranceClaimedAmount}
                                              onKeyDown={handleKeyDown}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>Discount(%) :</span>
                                          <EAvFieldDiscount
                                            mb={false}
                                            style={{ width: "145px" }}
                                            className="form-control-sm mb-0 text-end"
                                            value={dischargeData?.discountPercentage}
                                            formError={formError?.discount_percent}
                                            options={options?.form_schema?.discount_percent}
                                            onKeyDown={handleKeyDown}
                                            onChange={(e) => onDiscountPercentChange(e.target.value)}
                                            validate={{
                                              pattern: { value: '^[0-9]+(\.[0-9]{1,2})?$' },
                                              min: { value: 0 },
                                              max: { value: 100 }
                                            }}
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>Discount Amount :</span>
                                          <span>{dischargeData?.discountAmount}</span>
                                        </td>
                                      </tr>
                                      {dischargeData?.discountAmount ? (
                                        <tr>
                                          <td className="d-flex justify-content-between">
                                            <span>Discount Type :</span>
                                            <EAvFieldSelect
                                              mb={false}
                                              field="discount_type"
                                              bsSize="sm"
                                              isError={formError?.discount_type}
                                              options={{ required: dischargeData?.discountAmount }}
                                              style={{ width: "145px" }}
                                              choices={
                                                <>
                                                  <option value={""}>{"-Select option-"}</option>
                                                  {options?.form_schema?.discount_type?.choices.map((choice, cidx) =>
                                                    <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                                  )}
                                                </>
                                              }
                                            />
                                          </td>
                                        </tr>
                                      ) : null}
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>Discount By :</span>
                                          <div className="ajax-select select2-container" style={{ width: "145px" }}>
                                            <EAsyncSelect
                                              onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, discountedPerson: false }))}
                                              menuIsOpen={isMenuOpen.discountedPerson}
                                              className="form-control-sm p-0"
                                              field="discount_by"
                                              selectedOption={discountedPerson}
                                              fetchOptions={searchDiscountUsers}
                                              formError={formError?.discounted_by}
                                              onSelect={(value) => setDiscountedPerson(value)}
                                              onKeyDown={handleKeyDown}
                                              placeholder="Type to search users..."
                                              getOptionLabel={e => e.full_name}
                                              getOptionValue={e => e.id}
                                              additionalStyles={{
                                                control: (provided, state) => ({
                                                  ...provided,
                                                  minHeight: "30px",
                                                }),
                                              }}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>CGST(%) :</span>
                                          <div style={{ width: "145px" }}>
                                            <input
                                              type="text"
                                              name="cgst_percent"
                                              className="form-control-sm mb-0 text-end"
                                              maxLength="2"
                                              style={{ width: "145px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                              value={dischargeData?.cgstPercentage}
                                              onChange={onCgstPercentageChange}
                                              onKeyDown={handleKeyDown}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>CGST :</span>
                                          <span>{dischargeData?.cgstAmount}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>SGST(%) :</span>
                                          <div style={{ width: "145px" }}>
                                            <input
                                              type="text"
                                              name="sgst_percent"
                                              className="form-control-sm mb-0 text-end"
                                              maxLength="2"
                                              style={{ width: "145px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                              value={dischargeData?.sgstPercentage}
                                              onChange={onSgstPercentageChange}
                                              onKeyDown={handleKeyDown}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>SGST :</span>
                                          <span>{dischargeData?.sgstAmount}</span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="d-flex justify-content-between">
                                          <span>Payment Mode :</span>
                                          <EAvFieldSelect
                                            mb={false}
                                            field="payment_mode"
                                            bsSize="sm"
                                            isError={formError?.payment_mode}
                                            value={dischargeData.paymentMode}
                                            onChange={(e) => setDischargeData(prevState => ({ ...prevState, paymentMode: e.target.value }))}
                                            options={{ required: true }}
                                            style={{ width: "145px" }}
                                            choices={
                                              <>
                                                <option value={""}>{"-Select option-"}</option>
                                                {options?.form_schema?.payment_mode?.choices.map((choice, cidx) =>
                                                  <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                                )}
                                              </>
                                            }
                                          />
                                        </td>
                                      </tr>
                                      {dischargeData?.paymentMode === "CHEQUE" && (
                                        <>
                                          <tr>
                                            <td className="d-flex justify-content-between">
                                              <span>Cheque Number :</span>
                                              <EAvFieldInput
                                                field="cheque_number"
                                                mb={false}
                                                style={{ width: "145px" }}
                                                type="text"
                                                value={dischargeData?.chequeNumber}
                                                onChange={(e) => setDischargeData((prevState) => ({ ...prevState, chequeNumber: e.target.value }))}
                                                isError={formError?.cheque_number}
                                                bsSize="sm"
                                                options={options?.form_schema?.cheque_number}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="d-flex justify-content-between">
                                              <span>Cheque Date :</span>
                                              <EAvFieldInput
                                                field="cheque_date"
                                                mb={false}
                                                style={{ width: "145px" }}
                                                type="date"
                                                value={dischargeData?.chequeDate}
                                                onChange={(e) => setDischargeData((prevState) => ({ ...prevState, chequeDate: e.target.value }))}
                                                isError={formError?.cheque_date}
                                                bsSize="sm"
                                                options={options?.form_schema?.cheque_date}
                                              />
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                      <tr>
                                        <th className="d-flex justify-content-between">
                                          <span>Grand Total :</span>
                                          <span><h4>{dischargeData?.grandTotal}</h4></span>
                                        </th>
                                      </tr>
                                    </tbody>
                                  </Table>
                                  <div className="justify-content-end">
                                    <div className="mt-3">
                                      {showDischarge ? (
                                        <>
                                          <Button color="primary" className="float-end btn-sm ms-2" onClick={() => toggleCustom("3")}>
                                            Go To Discharge
                                          </Button>
                                          <Button color="primary" className="float-end btn-sm" disabled>
                                            Bills Paid
                                          </Button>
                                        </>
                                      ) : apiKey === "post_discharge_ip_post" ? (
                                        <Button color="primary" className="float-end btn-sm" disabled>
                                          Pay Bills<i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                                        </Button>
                                      ) : (
                                        <Button type="submit" color="primary" className="float-end btn-sm">
                                          Pay Bills
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </AvForm>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          {loading && apiKey === "get_discharge_summary" ? (
                            <Loader />
                          ) : (
                            <>
                              <Row>
                                <Col>
                                  <h5 className="mt-2">Patient information</h5>
                                  <hr />
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Patient name"
                                      value={dischargeSummary?.patient_name}
                                      defaultIfNull="NA"
                                    />
                                    <TableChip
                                      label="Patient ID"
                                      value={dischargeSummary?.uid}
                                      defaultIfNull="NA"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Mobile"
                                      value={dischargeSummary?.phone_number}
                                      defaultIfNull="NA"
                                    />
                                    <TableChip
                                      label="Admitted on"
                                      value={dischargeSummary?.created_at ? formatDateTime(dischargeSummary?.created_at) : null}
                                      defaultIfNull="NA"
                                    />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <TableChip
                                      label="Billing Status"
                                      value={dischargeSummary?.billing_status ? <Badge pill color="success">Paid</Badge> : <Badge pill color="danger">Unpaid</Badge>}
                                      defaultIfNull="NA"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div
                                    className="accordion accordion-flush"
                                    id="accordionFlushExample"
                                  >
                                    <div className="accordion-item">
                                      <h2 className="accordion-header" id="headingFlushOne">
                                        <button
                                          className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            "d-flex",
                                            "justify-content-between",
                                            { collapsed: !accordion.prescription }
                                          )}
                                          type="button"
                                          onClick={() => toggleAccordion("prescription")}
                                          style={{ cursor: "pointer", width: "100%", backgroundColor: "#EEF1FD" }}
                                        >
                                          <strong className="flex-grow-1">Prescription</strong>
                                        </button>
                                      </h2>
                                      <Collapse isOpen={accordion.prescription} className="accordion-collapse">
                                        <Row>
                                          <Col xl="12">
                                            <div className="table-responsive" style={{ maxHeight: "400px" }}>
                                              {!isEmpty(dischargeSummary?.prescription_history) ? (
                                                dischargeSummary?.prescription_history?.map((prescription, index) => (
                                                  <Table key={index}>
                                                    <thead className="thead-light text-capitalize">
                                                      <tr>
                                                        <th colSpan="8">{formatDateTime(prescription?.date, "MMM D, YYYY")}</th>
                                                      </tr>
                                                      <tr>
                                                        <th>Medication</th>
                                                        <th>Actual Time</th>
                                                        <th>Given Time</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {prescription?.prescription_data.map((med, index) => (
                                                        <tr key={index}>
                                                          <td>{med.name}</td>
                                                          <td>{med.time}</td>
                                                          <td>{formatDateTime(med.timestamp)}</td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </Table>
                                                ))
                                              ) : (
                                                <Table className="mt-2">
                                                  <tbody>
                                                    <tr>
                                                      <th colSpan="6" className="text-center">No prescription available</th>
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </Collapse>
                                    </div>
                                    <div className="accordion-item">
                                      <h2 className="accordion-header" id="headingFlushOne">
                                        <button
                                          className={classnames(
                                            "accordion-button",
                                            "fw-medium",
                                            "d-flex",
                                            "justify-content-between",
                                            { collapsed: !accordion.healthStatus }
                                          )}
                                          type="button"
                                          onClick={() => toggleAccordion("healthStatus")}
                                          style={{ cursor: "pointer", width: "100%", backgroundColor: "#EEF1FD" }}
                                        >
                                          <strong className="flex-grow-1">Health Status</strong>
                                        </button>
                                      </h2>
                                      <Collapse isOpen={accordion.healthStatus} className="accordion-collapse">
                                        <div className="table-responsive mb-2" style={{ maxHeight: "400px" }}>
                                          {!isEmpty(dischargeSummary?.health_metric_history) ? (
                                            dischargeSummary?.health_metric_history?.map((hm, index) => (
                                              <Table key={index}>
                                                <thead className="thead-light text-capitalize">
                                                  <tr>
                                                    <th colSpan="8">{formatDateTime(hm?.date, "MMM D, YYYY")}</th>
                                                  </tr>
                                                  <tr>
                                                    <th>Metric</th>
                                                    <th>Value</th>
                                                    <th>Capture Time</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {hm?.health_checks_data.map((hc, index) => (
                                                    <tr key={index}>
                                                      <td>{hc.name}</td>
                                                      <td>{hc.value}</td>
                                                      <td>{formatDateTime(hc.timestamp)}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </Table>
                                            ))
                                          ) : (
                                            <Table className="mt-2">
                                              <tbody>
                                                <tr>
                                                  <th colSpan="6" className="text-center">No health status available</th>
                                                </tr>
                                              </tbody>
                                            </Table>
                                          )}
                                        </div>
                                      </Collapse>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mt-4">
                                    {isShowUpload && apiKey !== "post_upload_discharge" ? (
                                      <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)} accept=".xls, .xlsx, .pdf, image/*">
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone">
                                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                              <input {...getInputProps()} />
                                              <div className="mb-3">
                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                              </div>
                                              <h4>Upload prescriptions</h4>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                    ) : apiKey === "upload_discharge" || apiKey === "fail_discharged_ip" ? (
                                      <>
                                        {!isEmpty(signedLinks) && (
                                          signedLinks.map((url, index) => (
                                            <UncontrolledAlert
                                              key={index}
                                              color="primary"
                                              toggle={() => setSignedLinks(signedLinks.filter(item => item.file_path !== url.file_path))}
                                            >
                                              {url.file_name}
                                            </UncontrolledAlert>
                                          ))
                                        )}
                                        <Button className="btn btn-sm" color="primary" onClick={() => setIsShowUpload(true)}>+ Add more prescriptions</Button>
                                      </>
                                    ) : apiKey === "post_upload_discharge" ? (
                                      <div className="d-flex justify-content-center align-items-center">
                                        <Spinner className="me-2" color="secondary" /> <span>Processing</span>
                                      </div>
                                    ) : apiKey === "fail_upload_discharge" && (
                                      <>
                                        <div className="d-flex justify-content-center align-items-center">
                                          <p>Something went wrong. Please try again</p>
                                        </div>
                                        <Button className="btn btn-sm" color="primary" onClick={() => setIsShowUpload(true)}>Upload prescriptions</Button>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="mt-3">
                                    {apiKey === "post_discharged_ip" ? (
                                      <Button color="primary" className="float-end btn-sm" disabled>
                                        Discharge<i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                                      </Button>
                                    ) : (
                                      <Button
                                        color="primary"
                                        className="float-end btn-sm"
                                        disabled={!dischargeSummary?.billing_status}
                                        onClick={() => dispatch(postHms(`/hms/patient/inpatients/${patient.id}/discharge_summary/`, { discharge_media: signedLinks }, "discharged_ip"))}
                                      >
                                        Discharge
                                      </Button>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        </TabPane>
                      </TabContent>
                    </>
                  ))}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  )
}
export default InpatientDischarge

const BedTable = ({ bed, updateKeys, admissionDate }) => {
  const [error, setError] = useState({ from_date: null, to_date: null });
  const [fromDate, setFromDate] = useState(bed.from_date);
  const [toDate, setToDate] = useState(bed.to_date);
  const [unit, setUnit] = useState(calculateUnitDifference(bed.from_date, bed.to_date));
  const [total, setTotal] = useState(unit * bed.bed_detail.price);

  useEffect(() => {
    const updatedUnit = calculateUnitDifference(fromDate, toDate);
    onBedChange(bed.id, updatedUnit, fromDate, toDate)
    setTotal(updatedUnit * bed.bed_detail.price);
  }, [fromDate, toDate]);

  useEffect(() => {
    setTotal(unit * bed.bed_detail.price);
  }, [unit]);

  const handleFromDateChange = (date) => {
    if (!toDate || moment(date[0]).isBefore(toDate)) {
      setError({ ...error, from_date: null })
      setFromDate(date[0])
    } else {
      setError({ ...error, from_date: "To Date not less than From Date" })
    }
  };

  const handleToDateChange = (date) => {
    if (moment(date[0]).isAfter(fromDate)) {
      setError({ from_date: null, to_date: null, })
      setToDate(date[0]);
    } else {
      setError({ ...error, to_date: "From Date not greater than To Date" })
    }
  };

  function calculateUnitDifference(fromDate, toDate) {
    if (!error.from_date && !error.to_date && fromDate && toDate) {
      const hoursDifference = moment(toDate).diff(moment(fromDate), 'hours');
      const unit = Math.ceil(hoursDifference / 24);
      return unit;
    } else {
      return 0;
    }
  }

  const onBedChange = (id, value, fromDate, toDate) => {
    if (value >= 0) {
      updateKeys(id, value, moment(fromDate).toISOString(), moment(toDate).toISOString())
      setUnit(value)
    }
  }

  return (
    <Table>
      <thead className="thead-light text-capitalize">
        <tr>
          <th>Category</th>
          <th>Ward</th>
          <th>Form Date</th>
          <th>To Date</th>
          <th>Unit</th>
          <th>Unit Price</th>
          <th>Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{bed.bed_detail.room_category_name}</td>
          <td>{bed.bed_detail.ward_name}</td>
          <td>
            <div style={{ width: "145px" }}>
              <Flatpickr
                className={`form-control form-control-sm mb-2 ${error.from_date ? 'is-invalid' : ''}`}
                placeholder={"Select a date"}
                options={{
                  mode: "single",
                  enableTime: true,
                  dateFormat: "d-M-Y h:i K",
                  time_24hr: false,
                  minDate: admissionDate,
                  maxDate: moment().toDate(),
                }}
                value={fromDate}
                onChange={handleFromDateChange}
              />
              <div className="text-danger small">
                {error.from_date}
              </div>
            </div>
          </td>
          <td>
            <div style={{ width: "145px" }}>
              <Flatpickr
                className={`form-control form-control-sm mb-2 ${error.to_date ? 'is-invalid' : ''}`}
                placeholder={"Select a date"}
                options={{
                  mode: "single",
                  enableTime: true,
                  dateFormat: "d-M-Y h:i K",
                  time_24hr: false,
                  minDate: admissionDate,
                  maxDate: moment().toDate(),
                }}
                value={toDate}
                onChange={handleToDateChange}
              />
              <div className="text-danger small">
                {error.to_date}
              </div>
            </div>
          </td>
          <td>
            <div style={{ width: "100px" }}>
              <input
                type="number"
                name="unit"
                className="form-control-sm"
                style={{ width: "100px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                value={unit}
                onChange={(e) => onBedChange(bed.id, e.target.value)}
              />
            </div>
          </td>
          <td>{bed.bed_detail.price}</td>
          <td>{total}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4"></td>
          <th colSpan="2" style={{ textAlign: "right" }}>Total Amount:</th>
          <td>{total}</td>
        </tr>
      </tfoot>
    </Table>
  )
}
