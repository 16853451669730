import React, { createRef, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  InputGroup,
  Row,
} from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import "assets/scss/datatables.scss"

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from 'moment';
import Flatpickr from "react-flatpickr"
import Chip from "components/Common/chips";
import { frameURLParam, tableSearchIconSizeFetch, loadOrNoData } from "components/Common/common"
import TableChipContainer from "components/Common/chips";
import AsyncSelect from "react-select/async";
import { get } from "helpers/api_helper"
import { updateURLParams } from "pages/HMS/common/common";


/**
 * Options must rendered in the server through OPTIONS api
 * 
 * To create options must follow a specific data format
 * 
 * Options is an object with field name as a key and structure of the input element as the value(Value also an object)
 * 
 * An example format of options data
 * @example
 * {
    "search": {
        "type": "text",
        "value": "",
        "apiKey": "search",
        "placeholder": "Search calls here"
    },
    "daterange": {
        "type": "daterange",
        "apiKey": [
            "date",
            "date_end"
        ],
        "value": [],
        "placeholder": "Select a date range"
    },
    "duration": {
        "label": "Duration",
        "type": "singlerange",
        "apiKey": "duration",
        "value": "222.2",
        "max": 525,
        "step": "0.1",
        "proxy": "time"
    },
    "score": {
        "label": "Score",
        "type": "singlerange",
        "apiKey": "score",
        "value": "62",
        "max": 82.0,
        "step": 1
    },
    "categories": {
        "label": "Categories",
        "type": "checkbox",
        "apiKey": "category",
        "data": [
            {
                "label": "Appointment",
                "value": "appointment",
                "checked": false
            },
            {
                "label": "Reschedule",
                "value": "reschedule",
                "checked": false
            },
            {
                "label": "Enquiry",
                "value": "enquiry",
                "checked": false
            }
        ]
    },
    "branches": {
        "label": "Branches",
        "type": "checkbox",
        "apiKey": "branch",
        "childApiKey": "group",
        "data": [
            {
                "label": "Mumbai",
                "value": "0nBLDYdjXkbxQ",
                "checked": true,
                "data": [
                    {
                        "label": "Creative clouds",
                        "value": "7vUURBWhG9mwQ",
                        "checked": true
                    },
                    {
                        "label": "Ring",
                        "value": "sG0HDlfmWFUlA",
                        "checked": true
                    }
                ]
            },
            {
                "label": "Hyderabad",
                "value": "aj35vT6x2l2bg",
                "checked": false,
                "data": [
                    {
                        "label": "Avatar",
                        "value": "lcNKR79saHA2SQ",
                        "checked": false
                    }
                ]
            }
        ]
    },
    "misc": {}
}
*/
const GenericTable = props => {
  var { urlParams, filters, data, columns, keyField = 'id', noDataIndication = "Records not found", searchbarEnabled = true, daterangeEnabled = true, loading = false, dropdownSearchURL = "" } = props
  const location = useLocation()
  const history = useHistory()

  urlParams = new URLSearchParams(urlParams)
  const [filtersCopy, setFiltersCopy] = useState({})

  const [dataList, setDataList] = useState([])
  const defaultCurrentPage = 1, defaultPageSize = (filters.misc && filters.misc.page_size) ? filters.misc.page_size : 15
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [dropdownSelectedOptions, setDropdownSelectedOptions] = useState({})
  var suTo = null

  const dateRangeRef = useRef()
  var parentCbRef = {}
  var timeoutObject = null

  const pageOptions = {
    custom: true,
    page: currentPage,
    hidePageListOnlyOnePage: true,
    sizePerPage: pageSize,
    totalSize: data.count,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '15', value: 15
    }, {
      text: '20', value: 20
    }, {
      text: '25', value: 25
    }, {
      text: '30', value: 30
    }]
  }

  useEffect(() => {
    !isEmpty(data.results) ? setDataList(data.results) : setDataList([])
  }, [data.results])

  useEffect(() => {
    setFiltersCopy(filters)
  }, [filters])

  if (filters) {
    Object.entries(filters).map(([key, val], idx) => {
      if (val.type === "checkbox" && val.data) {
        val.data.map(item => {
          if (item.data) parentCbRef[item.value] = createRef()
        })
      }
    })
  }

  const searchBar = (event) => {
    let value = event.target.value.trim()
    if (value === "" || value.length > 1) {
      let tObj = filtersCopy
      tObj["search"]["value"] = value
      shootFetcher(tObj, { lazy: true })
    }
  }

  const onDaterangeChange = (dateRange) => {
    if (!isEmpty(dateRange)) {
      let [start_date, end_date] = [moment(dateRange[0]), moment(dateRange[1])]
      let date_ranges = [start_date.format("YYYY-MM-DD"), end_date.format("YYYY-MM-DD")]
      let tObj = filtersCopy
      tObj["daterange"]["value"] = (start_date > end_date) ? date_ranges.reverse() : date_ranges
      shootFetcher(tObj, {})
    }
  }

  const onSingleRangeChange = (key, value) => {
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, { lazy: true })
  }

  const onParentCheckboxChange = (key, event) => {
    let { value, checked } = event.target
    let tObj = filtersCopy
    tObj[key].data.map(parentObject => {
      if (parentObject.value === value) {
        parentObject["checked"] = checked
        if (parentObject.data) parentObject.data.map(childObject => {
          childObject['checked'] = checked
        })
      }
    })
    shootFetcher(tObj, {})
  }

  const onChildCheckboxChange = (parentKey, key, value, checked) => {
    let tObj = filtersCopy
    parentCbRef[parentKey].current.indeterminate = null
    tObj[key].data.map(parentObject => {
      let parentDisable = []
      if (parentObject.data) parentObject.data.map(childObject => {
        if (childObject.value === value) {
          childObject['checked'] = checked
        }
        parentDisable.push(childObject["checked"])
      })
      if (!isEmpty(parentDisable)) {
        let boolValues = new Set(parentDisable)
        if (boolValues.size === 1) {
          boolValues.has(true) ? parentObject["checked"] = true : parentObject["checked"] = false;
        } else {
          parentObject["checked"] = false
          parentCbRef[parentKey].current.indeterminate = true
        }
      }
    })
    shootFetcher(tObj, {})
  }

  const onRadioChange = (key, event) => {
    let value = event.target.value
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, {})
  }

  const onSelectChange = (key, event) => {
    let value = event.target.value
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, {})
  }

  const onDropdownSelectChange = (key, value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`${dropdownSearchURL}?${key}=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 1000)
    }
  }

  const updateDropdownSelectedOptions = (key, value) => {
    setDropdownSelectedOptions(prevState => ({ ...prevState, [key]: value }))
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, {})
  }

  const shootFetcher = (filterData, { page = currentPage, pagesize = pageSize, isPageHit = false, lazy = false }) => {
    if (!isPageHit) page = defaultCurrentPage
    setFiltersCopy(filterData)
    if (lazy) {
      clearTimeout(timeoutObject)
      timeoutObject = setTimeout(() => {
        let urlParams = frameURLParam(filterData, page, pagesize)
        props.apiTrigger(urlParams)
        updateURLParams(history, urlParams)
      }, 500)
    } else {
      let urlParams = frameURLParam(filterData, page, pagesize)
      props.apiTrigger(urlParams)
      updateURLParams(history, urlParams)
    }
    setCurrentPage(page)
    setPageSize(pagesize)
  }

  const onPageChange = (type, { page, sizePerPage }) => {
    shootFetcher(filtersCopy, { page: page, isPageHit: true })
  }

  const pageSizeChange = (value) => {
    shootFetcher(filtersCopy, { pagesize: value, isPageHit: true })
  }

  const filtersPlace = () => {
    if (urlParams) {
      let urlParamKeys = []
      for (const key of urlParams.keys()) {
        urlParamKeys.push(key)
      }
      {
        filtersCopy && Object.entries(filtersCopy).map(([k, v], idx) => {
          if (v && urlParamKeys.includes(k)) {
            <Chip key={idx} label={v.label} data={v} onClick={removeChip} />
          }
        })
      }
    }
  }

  const clearFilters = () => {
    props.apiTrigger()
    updateURLParams(history)
  }

  const removeChip = tObj => {
    shootFetcher(tObj, {})
    if (isEmpty(tObj.daterange.value)) dateRangeRef.current.flatpickr.clear()
  }

  return (
    <React.Fragment>
      {/* Top row */}
      {/* Chip placement */}
      {/* <Row>
        <Col lg="12">
          <TableChipContainer filtersCopy={filtersCopy} removeChip={removeChip} />
        </Col>
      </Row> */}
      <Row>
        {/* Side Filters */}
        <Col lg="3">
          <Card>
            <CardBody>
              <>
                {/* Daterange selector */}
                {(daterangeEnabled && filtersCopy.daterange) &&
                  <div className="ms-auto">
                    <InputGroup>
                      <Flatpickr
                        className="form-control"
                        placeholder={filtersCopy.daterange.placeholder ? filtersCopy.daterange.placeholder : "Select a daterange range"}
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                          minDate: "2000-01",
                          maxDate: "today",
                          defaultDate: !isEmpty(filtersCopy.daterange.value) ? filtersCopy.daterange.value : []
                        }}
                        ref={dateRangeRef}
                        onClose={onDaterangeChange}
                      />
                    </InputGroup>
                  </div>
                }
                {filtersCopy && Object.entries(filtersCopy).map(([key, valObj], mainKeyIdx) =>
                  (valObj.type === "singlerange") ?
                    <div className="mt-4 pt-3" key={mainKeyIdx}>
                      <h5 className="font-size-14 mb-4">{valObj.label}</h5>
                      <input
                        type="range"
                        className="form-range"
                        min="0"
                        max={valObj.max}
                        step={valObj.step ? valObj.step : "0.1"}
                        value={valObj.value}
                        id={`id${key}`}
                        onChange={(e) => { onSingleRangeChange(key, e.target.value) }}
                      />
                      <div className="d-flex justify-content-between">
                        <p></p>
                        {valObj.proxy ?
                          <>
                            {valObj.proxy === "time" ?
                              <>
                                <h5>{moment.utc(Math.ceil(valObj.value) * 1000).format('m:ss')} mins</h5>
                                <p>{Math.ceil(valObj.max / 60)} mins</p>
                              </>
                              : null   // can add new ones here
                            }
                          </>
                          :
                          <>
                            <h5>{valObj.value}</h5>
                            <p>{valObj.max}</p>
                          </>
                        }
                      </div>
                    </div>
                    : valObj.type === "checkbox" ?
                      <div className="mt-4 pt-3" key={mainKeyIdx}>
                        <h5 className="font-size-14 mb-3">{valObj.label}</h5>
                        {valObj.data.map((parentCbObj, idx) =>
                          <div key={idx} className="mb-1">
                            <ul className="metismenu list-unstyled">
                              <li>
                                <div className="has-arrow">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`id${parentCbObj.label}`}
                                    value={parentCbObj.value}
                                    checked={parentCbObj.checked}
                                    onChange={(e) => onParentCheckboxChange(key, e)}
                                    ref={parentCbRef && parentCbRef[parentCbObj.value]}
                                  />
                                  <label className="form-check-label ms-2 text-capitalize" htmlFor={parentCbObj.label}>
                                    {parentCbObj.label}
                                  </label>
                                </div>
                                {parentCbObj.data &&
                                  <ul className="sub-menu" aria-expanded="false">
                                    <li>
                                      {parentCbObj.data.map((childCbObj, cidx) =>
                                        <div key={cidx}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={`id${childCbObj.label}`}
                                            value={childCbObj.value}
                                            checked={childCbObj.checked}
                                            onChange={(e) => onChildCheckboxChange(parentCbObj.value, key, e.target.value, e.target.checked)}
                                          />
                                          <label className="form-check-label ms-2 text-capitalize" htmlFor={childCbObj.label}>
                                            {childCbObj.label}
                                          </label>
                                          <br />
                                        </div>
                                      )}
                                    </li>
                                  </ul>
                                }
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      : valObj.type === "dropdownsearch" ?
                        <div className="mt-4 pt-3" key={mainKeyIdx}>
                          <h5 className="font-size-14 mb-3">{valObj.label}</h5>
                          <AsyncSelect
                            isMulti
                            value={dropdownSelectedOptions[key]}
                            loadOptions={(value, callback) => onDropdownSelectChange(valObj.apiKey, value, callback)}
                            onChange={(value) => updateDropdownSelectedOptions(key, value)}
                            classNamePrefix="select2-selection"
                            placeholder="Type keywords here..."
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            // components={animatedComponents}
                            closeMenuOnSelect={false}
                            getOptionLabel={e => e}
                            getOptionValue={e => e}
                          />
                        </div>
                        : valObj.type === "radio" ?
                          <div className="mt-4 pt-3" key={mainKeyIdx}>
                            <h5 className="font-size-14 mb-3">{valObj.label}</h5>
                            {valObj.data.map((item, idx) =>
                              <div className="form-check mb-1" key={idx}>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={item.value}
                                  id={`id${item.value}`}
                                  value={item.value}
                                  checked={valObj.value === item.value ? true : false}
                                  onChange={(e) => onRadioChange(key, e)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={item.value}
                                >
                                  {item.label}
                                </label>
                              </div>
                            )}
                          </div>
                          : valObj.type === "dropdown" ?
                            <div className="mt-4 pt-3" key={mainKeyIdx}>
                              <h5 className="font-size-14 mb-3">{valObj.label}</h5>
                              <select className="form-control" onChange={(e) => onSelectChange(key, e)} defaultValue={valObj.value}>
                                <option value="">---</option>
                                {valObj.data.map((item, idx) =>
                                  <option key={idx} value={item.value}>{item.label}</option>
                                )}
                              </select>
                            </div>
                            : null
                )}
              </>
            </CardBody>
          </Card>
        </Col>

        {/* Main Table */}
        <Col lg="9">
          <Card>
            <CardBody>
              {(searchbarEnabled && filtersCopy.search) &&
                <Row className="mb-2">
                  <Col md="6">
                    <div className="search-box mb-2 d-inline-block">
                      <div className="position-relative">
                        <input
                          className="form-control"
                          type="search"
                          onInput={searchBar}
                          placeholder={filtersCopy.search.placeholder ? filtersCopy.search.placeholder : ""}
                          defaultValue={filtersCopy.search.value ? filtersCopy.search.value : ""}
                        />
                        <i className="bx bx-search-alt search-icon" style={tableSearchIconSizeFetch()} />
                      </div>
                    </div>
                  </Col>
                </Row>
              }
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField={keyField}
                columns={columns}
                data={dataList}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField={keyField}
                    data={dataList}
                    columns={columns}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                remote
                                keyField={keyField}
                                responsive
                                bordered={false}
                                striped={false}
                                onTableChange={onPageChange}
                                classes={"table align-middle table-nowrap"}
                                noDataIndication={loadOrNoData(loading, noDataIndication)}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                                onSizePerPageChange={pageSizeChange}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GenericTable
