import { setTemporarySessionStorage } from "components/Common/common";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import { listServices } from "store/actions";


const Services = (props) => {
  const dispatch = useDispatch()
  const [serviceList, setServiceList] = useState([])
  const { error, loading, services } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
    services: state.Login.services,
  }))

  useEffect(() => {
    dispatch(listServices())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(services)) {
      if (!isEmpty(services.results)) {
        if (services.results.length === 1) props.history.replace(services.results[0].landing_url)
        setServiceList(services.results)
      } else {
        setTemporarySessionStorage("message", "No services available at this moment. Please contact your administrator")
        window.location.href = "/unknownhappen"
      }
    }
  }, [services])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Services | Yanthura</title>
        </MetaTags>
        {loading ?
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Loading Services...</p>
          :
          <Row className="justify-content-center">
            {serviceList.map(service =>
              <Col lg={4} key={service.name}>
                <Card>
                  <h5 className="card-header bg-transparent border-bottom text-uppercase">{service.name}</h5>
                  <CardBody>
                    <CardTitle className="mt-0">
                      {service.title}
                    </CardTitle>
                    <CardText>
                      {service.description}
                    </CardText>
                    <Link to={service.landing_url} className="btn btn-primary">
                      Explore now
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        }
      </div>
    </React.Fragment>
  )
};

export default Services;