import {
    API_SUCCESS,
    API_FAIL,
    GET_DASHBOARD_DATA,
    ALL_USERS,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    AGENT_DATA,
    AGENT_DATA_SUCCESS,
    AGENT_DATA_FAIL,
    BRANCH_OPTIONS,
    BRANCH_OPTIONS_SUCCESS,
    BRANCH_OPTIONS_FAIL,
    BRANCH_GROUPS_OPTIONS,
    BRANCH_GROUPS_OPTIONS_SUCCESS,
    BRANCH_GROUPS_OPTIONS_FAIL,
    GET_BRANCH_DATA,
    GET_BRANCH_DATA_SUCCESS,
    GET_BRANCH_DATA_FAIL,
    GET_PENDING_REQUESTS,
    GET_PENDING_REQUESTS_SUCCESS,
    GET_PENDING_REQUESTS_FAIL,
    UPDATE_PENDING_REQUESTS,
    UPDATE_PENDING_REQUESTS_SUCCESS,
    UPDATE_PENDING_REQUESTS_FAIL,
    GET_DYNAMIC_USER_OPTIONS,
    GET_DYNAMIC_USER_OPTIONS_SUCCESS,
    GET_DYNAMIC_USER_OPTIONS_FAIL,
    RESEND_ACTIVATIONLINK,
    RESEND_ACTIVATIONLINK_SUCCESS,
    RESEND_ACTIVATIONLINK_FAIL,
    GET_USER_OPTIONS,
    GET_USER_OPTIONS_SUCCESS,
    GET_USER_OPTIONS_FAIL
} from "./actionTypes";

const INIT_STATE = {
    dashboardData: [],
    branchData: {},
    loading: false,
    errorData: null,

    userOptions: {},
    allUsers: {},
    detailError: null,

    agentData: {},

    branches: {},
    groups: [],
    pendingRequestUserTypes: [],
    pendingRequests: {},
    usernames: [],
    usersActivated: false,
    successDataResendActLinks: {}
};

const DashboardGenericState = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            return {
                ...state,
                loading: true,
                errorData: null
            };
        case API_SUCCESS:
            return {
                ...state,
                loading: false,
                dashboardData: action.payload.data,
                errorData: null
            };
        case API_FAIL:
            return {
                ...state,
                loading: false,
                errorData: action.payload.error
            };
        
        case GET_BRANCH_DATA:
            return {
                ...state,
                loading: true,
                errorData: null,
                detailError: null
            };
        case GET_BRANCH_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                branchData: action.payload.data,
                detailError: null
            };
        case GET_BRANCH_DATA_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload.error
            };

        case GET_USER_OPTIONS:
            return {
                ...state,
                error: null,
            }
        case GET_USER_OPTIONS_SUCCESS:
            return {
                ...state,
                error: null,
                userOptions: action.payload
            }
        case GET_USER_OPTIONS_FAIL:
            return {
            ...state,
                error: action.payload
            }
  
        case ALL_USERS:
            return {
                ...state,
                loading: true,
                errorData: null,
                detailError: null
            };
        case ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                allUsers: action.payload.data,
                detailError: null
            };
        case ALL_USERS_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload.error
            };
        
        case AGENT_DATA:
            return {
                ...state,
                loading: true,
                detailError: null
            };
        case AGENT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                agentData: action.payload.data,
                detailError: null
            };
        case AGENT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload.error
            };
        
        case BRANCH_OPTIONS:
            return {
                ...state,
                loading: true,
                detailError: null
            };
        case BRANCH_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                branches: action.payload.data,
                detailError: null
            };
        case BRANCH_OPTIONS_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload.error
            };
        
        case BRANCH_GROUPS_OPTIONS:
            return {
                ...state,
                loading: true,
                detailError: null
            };
        case BRANCH_GROUPS_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                groups: action.payload.data.group,
                detailError: null
            };
        case BRANCH_GROUPS_OPTIONS_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload.error
            };

        case GET_DYNAMIC_USER_OPTIONS:
            return {
                ...state,
                loading: true,
            };
        case GET_DYNAMIC_USER_OPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                pendingRequestUserTypes: action.payload,
                detailError: null
            };
        case GET_DYNAMIC_USER_OPTIONS_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload
            };
    
        case GET_PENDING_REQUESTS:
            return {
                ...state,
                loading: true,
            };
        case GET_PENDING_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                pendingRequests: action.payload,
                detailError: null
            };
        case GET_PENDING_REQUESTS_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload
            };
        
        case RESEND_ACTIVATIONLINK:
            return {
                ...state,
                loading: true,
            };
        case RESEND_ACTIVATIONLINK_SUCCESS:
            return {
                ...state,
                loading: false,
                successDataResendActLinks: action.payload,
                detailError: null
            };
        case RESEND_ACTIVATIONLINK_FAIL:
            return {
                ...state,
                loading: false,
                detailError: action.payload
            };
    

        case UPDATE_PENDING_REQUESTS:
            return {
                ...state,
                loading: true,
                usersActivated: false
            };
        case UPDATE_PENDING_REQUESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                usersActivated: true,
                detailError: null
            };
        case UPDATE_PENDING_REQUESTS_FAIL:
            return {
                ...state,
                loading: false,
                usersActivated: false,
                detailError: action.payload
            };
        
        default:
            return state;
    }
}


export default DashboardGenericState;