import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import PropTypes from "prop-types"

import Waveform from "./waveform"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import { getCallInDepthData as onGetCallInDepthData } from "store/actions"
import moment from "moment"

const CallDetail = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [downloadDD, setDownloadDD] = useState(false)
  const dispatch = useDispatch()

  const { callInDepthDetail, detailError, loading } = useSelector(state => ({
    callInDepthDetail: state.CallDetail.callInDepthDetail,
    detailError: state.CallDetail.detailError,
    loading: state.CallDetail.loading,
  }))

  useEffect(() => {
    let body = document.body;
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }, [callInDepthDetail])

  useEffect(() => {
    dispatch(onGetCallInDepthData(props.match.params.callID))
  }, [dispatch])

  const downloadTranscript = (format="txt") => {
    const element = document.createElement("a")
    if (format==="txt") {
      var file = new Blob([callInDepthDetail.transcript.map(v => v.transcript).join(' ')], {type: 'text/plain'})
    } else if (format==="csv") {
      let content = callInDepthDetail.transcript.map(v => `${v.speaker},${v.start_time},${v.end_time},${v.transcript}`).join('\n')
      var file = new Blob([`Speaker,From,To,Transcript\n${content}`], {type: 'text/plain'})
    }
    element.href = URL.createObjectURL(file)
    element.download = `${Math.floor(Date.now() / 1000)}.${format}`
    document.body.appendChild(element)
    element.click()
    element.remove()
  }

  const downloadAudio = () => {
    var element = document.createElement('a')
    element.href = callInDepthDetail.nfs_path
    element.download = callInDepthDetail.name
    document.body.appendChild(element)
    element.click()
    element.remove()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Call Details | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Call" breadcrumbItem={(callInDepthDetail && callInDepthDetail.user) && `${callInDepthDetail.user.full_name} at ${moment(callInDepthDetail.created_at).format("lll")} about ${moment.utc(callInDepthDetail.duration*1000).format('mm:ss')} mins`} />
          {detailError ?
            <Card>
              <CardBody>
                <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {detailError.detail}</p>
              </CardBody>
            </Card>
          :
          <div>
            <Row>
              <Col lg="2">
                <div className="d-flex flex-wrap gap-3" style={{position: "fixed"}}>
                  <div className="btn-group-vertical" role="group" aria-label="Vertical button group">
                    <Button type="button" color={activeTab==="1" ? "primary" : "light"} className={activeTab==="1" ? "btn btn-primary" : "btn btn-light"} onClick={() => setActiveTab("1")}>Graphical</Button>
                    <Button type="button" color={activeTab==="2" ? "primary" : "light"} className={activeTab==="2" ? "btn btn-primary" : "btn btn-light"} onClick={() => setActiveTab("2")}>Info</Button>
                    <Button type="button" color={activeTab==="3" ? "primary" : "light"} className={activeTab==="3" ? "btn btn-primary" : "btn btn-light"} onClick={() => setActiveTab("3")}>Score card</Button>
                    {callInDepthDetail.transcript &&
                      <ButtonDropdown isOpen={downloadDD} toggle={() => setDownloadDD(!downloadDD)} >
                        <DropdownToggle caret color="light">
                          Download <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={downloadAudio}>Audio</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem header>Transcript</DropdownItem>
                          <DropdownItem onClick={() => downloadTranscript("txt")}>TXT</DropdownItem>
                          <DropdownItem onClick={() => downloadTranscript("csv")}>CSV</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    }
                  </div>
                </div>
              </Col>
              <Col lg="10">
                <Waveform loading={loading} callInDepthDetail={callInDepthDetail} detailError={detailError} activeTab={activeTab} />
              </Col>
            </Row>
          </div>
          }
        </Container>
      </div>

    </React.Fragment>
  )
}
CallDetail.propTypes = {
  callInDepthDetail: PropTypes.object,
  onGetCallInDepthData: PropTypes.func,
}
export default withTranslation()(CallDetail)
