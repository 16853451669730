import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Button, Card, CardBody, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Badge } from "reactstrap"
import { deleteHms, getHms, resetHmsState } from "store/actions";
import { formatDateTime, TableChip } from "components/Common/common";
import { ConfigureModal } from "./configure/modals";
import { MedicationModalAdd, MedicationModalTrack, MedicationModalHistory } from "./medication/modals";
import { HealthModalAdd, HealthModalHistory, HealthModalTrack } from "./health_status/modals";
import { InsuranceModal } from "./insurance/modals";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals";
import toastr from "toastr";
import { PatientInformation } from "pages/HMS/common/common";

const InpatientDetail = (props) => {
  const dispatch = useDispatch();

  const [MedDropdownOpen, setMedDropdownOpen] = useState(false);
  const [healthDropdownOpen, setHealthDropdownOpen] = useState(false);
  const [inpatient, setInpatient] = useState(null);
  const [insurance, setInsurance] = useState(null);
  const [modals, setModals] = useState({
    configureModal: false,
    insuranceModal: false,
    medicationModalAdd: false,
    medicationModalTrack: false,
    medicationModalHistory: false,
    healthMetricModalAdd: false,
    healthMetricModalTrack: false,
    healthMetricModalHistory: false,
    deleteModal: false,
  });
  const [errors, setErrors] = useState({});
  const [dropdown, setDropdown] = useState(false)

  const { loading, hmsObject, apiKey, error, updateResponse, formError } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
    updateResponse: state.Hms.updateResponse,
  }));

  useEffect(() => {
    return () => {
      dispatch(resetHmsState())
    }
  }, [dispatch]);

  useEffect(() => {
    if (apiKey === "ip_detail") {
      setInpatient(hmsObject);
      if (hmsObject?.patient_data?.health_insurance) {
        dispatch(getHms(`/hms/patient/health_insurance/${hmsObject?.patient_data?.health_insurance}/`, null, "insurance"))
      }
    }
    if (apiKey === "insurance") setInsurance(hmsObject);
    if (apiKey === "care_taker_update") setInpatient(updateResponse)
    if (apiKey === "insurance_update") setInsurance(updateResponse?.health_insurance_data)
    if (apiKey === "inpatient_detail_deleted") {
      toastr.success("Inpatient is deleted")
      setInpatient({})
      toggleDelete()
      window.location.replace("/hms/patient/inpatients")
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHms("/hms/patient/inpatients/", props.match.params.inpID, "ip_detail"));
    return () => {
      dispatch(resetHmsState());
    };
  }, []);

  const medDropdowntoggle = () => setMedDropdownOpen((prevState) => !prevState);
  const healthDropdowntoggle = () => setHealthDropdownOpen((prevState) => !prevState);

  const toggleViewModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
    setErrors({})
  };

  const toggleDelete = (def = modals.deleteModal) => {
    setModals(prevModals => ({ ...prevModals, deleteModal: !def }))
  }

  const handleDelete = () => {
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{inpatient?.patient_data?.user_data?.full_name && `${inpatient.patient_data.user_data.full_name} |`} Yanthura</title>
        </MetaTags>
        <Card>
          <CardBody>
            {error && apiKey === "fail_ip_detail" ?
              <p className="text-center text-danger">{error.detail}</p>
              :
              <>
                {loading && apiKey === "get_ip_detail" ?
                  <div className="text-center">
                    <div className="avatar-md mx-auto">
                      <Spinner className="mt-5 fs-1" color="secondary" />
                    </div>
                    <div className="p-2 mt-4">
                      <h4>Fetching information...</h4>
                      <p className="text-muted">Please wait while we load the details</p>
                    </div>
                  </div>
                  :
                  <>
                    <div className="d-flex flex-column flex-sm-row justify-content-end">
                      {insurance &&
                        <div className="text-sm-end mb-2 me-sm-2">
                          <Button
                            type="button"
                            color="primary"
                            className="btn-sm"
                            onClick={() => { setModals({ ...modals, insuranceModal: true }) }}
                            disabled={inpatient?.discharge_status}
                          >
                            INSURANCE
                          </Button>
                        </div>
                      }
                      <div className="text-sm-end mb-2 me-sm-2">
                        <Button
                          type="button"
                          color="primary"
                          className="btn-sm"
                          onClick={() => { setModals({ ...modals, configureModal: true }) }}
                          disabled={inpatient?.discharge_status}
                        >
                          CARETAKERS
                        </Button>
                      </div>
                      {(inpatient?.duty_doctor && inpatient?.consulting_doctor && inpatient?.nurse) &&
                        <>
                          <div className="text-sm-end mb-2 me-sm-2">
                            <Dropdown isOpen={MedDropdownOpen} toggle={medDropdowntoggle} direction="down">
                              <DropdownToggle caret color="primary" size="sm" disabled={inpatient?.discharge_status}>
                                MEDICATION &nbsp; <i className="fa fa-caret-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => { setModals({ ...modals, medicationModalAdd: true }) }}>Prescribe Medicine</DropdownItem>
                                <DropdownItem onClick={() => { setModals({ ...modals, medicationModalTrack: true }) }}>Track</DropdownItem>
                                <DropdownItem onClick={() => { setModals({ ...modals, medicationModalHistory: true }) }}>History</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                          <div className="text-sm-end me-sm-2">
                            <Dropdown isOpen={healthDropdownOpen} toggle={healthDropdowntoggle} direction="down">
                              <DropdownToggle caret color="primary" size="sm" disabled={inpatient?.discharge_status}>
                                HEALTH STATUS &nbsp; <i className="fa fa-caret-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => { setModals({ ...modals, healthMetricModalAdd: true }) }}>Assign Check-ups</DropdownItem>
                                <DropdownItem onClick={() => { setModals({ ...modals, healthMetricModalTrack: true }) }}>Track</DropdownItem>
                                <DropdownItem onClick={() => { setModals({ ...modals, healthMetricModalHistory: true }) }}>History</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </>
                      }
                    </div>
                    <Row>
                      <Col>
                        <div className="d-flex justify-content-between">
                          <h5>Patient Information</h5>
                          <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                            <DropdownToggle caret color="primary" size="sm">
                              Actions
                              &nbsp;<i className="fa fa-caret-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <Link className={`dropdown-item ${inpatient?.discharge_status ? "disabled" : ""}`} to={`/hms/patient/inpatient/register/${inpatient?.id}`}>
                                Modify
                              </Link>
                              <Link className="dropdown-item" to={"#"} onClick={handleDelete}>
                                Remove
                              </Link>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <hr />
                        {inpatient?.patient_data?.user_data ? (
                          <PatientInformation
                            data={inpatient?.patient_data}
                            instance={inpatient}
                            includedFields={[
                              "patient_name",
                              "patient_id",
                              "gender",
                              "marital_status",
                              "date_of_birth",
                              "age",
                              "mobile",
                              "email",
                              "ip_number",
                            ]}
                          />
                        ) : (
                          <p className="text-center text-danger">Patient details not existed</p>
                        )}
                        <h5 className="mt-2">Emergency contact details</h5>
                        <hr />
                        {inpatient?.patient_data?.emergency_contact_name || inpatient?.patient_data?.emergency_contact_relation || inpatient?.patient_data?.emergency_contact_number ? (
                          <div className="d-flex justify-content-between">
                            <TableChip label="Contact person" value={inpatient?.patient_data?.emergency_contact_name} />
                            <TableChip label="Relation" value={inpatient?.patient_data?.emergency_contact_relation} />
                            <TableChip label="Mobile number" value={inpatient?.patient_data?.emergency_contact_number} />
                          </div>
                        ) : (
                          <p className="text-center text-danger">Emergency contact details not existed</p>
                        )}
                        <h5 className="mt-2">Insurance</h5>
                        <hr />
                        {insurance ? (
                          <div className="d-flex justify-content-between">
                            <TableChip label="Policy number" value={insurance?.policy_number} />
                            <TableChip label="Issuer" value={insurance?.issuer_name} />
                            <TableChip label="Current status" value={insurance?.status} />
                          </div>
                        ) : (
                          <p className="text-center text-danger">Insurance details not existed</p>
                        )}
                      </Col>
                      <Col>
                        <h5 className="mt-2">Caretakers</h5>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <TableChip label="Duty Doctor" value={inpatient?.duty_doctor_data?.user_data?.full_name} />
                          <TableChip label="Consulting Doctor" value={inpatient?.consulting_doctor_data?.user_data?.full_name} />
                          <TableChip label="Nurse" value={inpatient?.nurse_data?.user_data?.full_name} />
                        </div>
                        <h5 className="mt-2">Admission Details</h5>
                        <hr />
                        <div className="d-flex justify-content-between" style={{ marginBottom: "10px" }}>
                          <TableChip label="Admitted On" value={formatDateTime(inpatient?.created_at)} />
                          <TableChip
                            label="Discharge Status"
                            value={inpatient?.discharge_status ? (
                              <Badge color="secondary" pill>Discharged</Badge>
                            ) : (
                              <Badge color="success" pill>Admitted</Badge>
                            )}
                          />
                          <TableChip />
                        </div>
                        <h5 className="mt-2">Bed Details</h5>
                        <hr />
                        {inpatient?.bed_detail_data ? (
                          <>
                            <div className="d-flex justify-content-between">
                              <TableChip label="Ward" value={inpatient?.bed_detail_data?.ward_name} />
                              <TableChip label="Room Category" value={inpatient?.bed_detail_data?.room_category_name} />
                              <TableChip label="Floor" value={inpatient?.bed_detail_data?.floor} />
                            </div>
                            <div className="d-flex justify-content-between">
                              <TableChip label="Room Number" value={inpatient?.bed_detail_data?.room_number} />
                              <TableChip label="Bed Number" value={inpatient?.bed_detail_data?.bed_number} />
                              <TableChip label="Price" value={inpatient?.bed_detail_data?.price} />
                            </div>
                            <div className="d-flex justify-content-between">
                              <TableChip label="Room Type" value={inpatient?.bed_detail_data?.room_type !== 1 ? "Shared" : "Private"} />
                              <TableChip
                                label="Bed Status"
                                value={
                                  inpatient?.bed_detail_data?.is_available ? (
                                    <Badge color="success" pill>Available</Badge>
                                  ) : (
                                    <Badge color="danger" pill>Occupied</Badge>
                                  )
                                }
                              />
                              <TableChip />
                            </div>
                          </>
                        ) : (
                          <p className="text-center text-danger">No bed information available</p>
                        )}
                      </Col>
                    </Row>
                  </>
                }
              </>
            }
          </CardBody>
        </Card>
      </div>
      <div>
        {modals?.insuranceModal &&
          <InsuranceModal
            modals={modals}
            toggleViewModal={toggleViewModal}
            patientID={inpatient?.patient}
            insurance={insurance}
          />
        }

        {modals?.configureModal &&
          <ConfigureModal
            modals={modals}
            toggleViewModal={toggleViewModal}
            errors={errors}
            setErrors={setErrors}
            inpID={props.match.params.inpID}
            dataObject={inpatient}
          />
        }

        {modals?.medicationModalAdd &&
          <MedicationModalAdd
            modals={modals}
            toggleViewModal={toggleViewModal}
            errors={errors}
            setErrors={setErrors}
            inpID={props.match.params.inpID}
          />
        }

        {modals?.medicationModalTrack &&
          <MedicationModalTrack
            modals={modals}
            toggleViewModal={toggleViewModal}
            inpID={props.match.params.inpID}
          />
        }

        {modals?.medicationModalHistory &&
          <MedicationModalHistory
            modals={modals}
            toggleViewModal={toggleViewModal}
            inpID={props.match.params.inpID}
          />
        }

        {modals?.healthMetricModalAdd &&
          <HealthModalAdd
            modals={modals}
            toggleViewModal={toggleViewModal}
            inpID={props.match.params.inpID}
          />
        }

        {modals?.healthMetricModalTrack &&
          <HealthModalTrack
            modals={modals}
            toggleViewModal={toggleViewModal}
            inpID={props.match.params.inpID}
          />
        }

        {modals?.healthMetricModalHistory &&
          <HealthModalHistory
            modals={modals}
            toggleViewModal={toggleViewModal}
            inpID={props.match.params.inpID}
          />
        }

        <HmsDeleteModal
          body={<>
            <ul>
              <li>If inpatient is removed. You cannot get it back</li>
              <li>If any data linked to this record will be vanished</li>
            </ul>
          </>}
          loading={loading}
          error={error}
          toggleDelete={toggleDelete}
          dispatchOperation={deleteHms("/hms/patient/inpatients/", inpatient?.id, "inpatient_detail_deleted")}
          deleteModal={modals.deleteModal}
        />
      </div>
    </React.Fragment >
  );
};

export default InpatientDetail;
