import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux";
import {
  checkHeaders as onCheckHeaders,
  listTemplates as onListTemplates,
  steppedForm,
} from "store/actions"
import Dropzone from "react-dropzone";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as XLSX from "xlsx";
import { isEmpty } from "lodash";


const Step1 = props => {
  const dispatch = useDispatch()
  const [fileSelected, setFileSelected] = useState(null)
  const [formData, setFormData] = useState({})
  const [template, setTemplate] = useState({})
  const [isMenu, setIsMenu] = useState(false)
  const [onProcessingFile, setOnProcessingFile] = useState(false)


  const { loading, templates } = useSelector(state => ({
    loading: state.Lms.loading,
    templates: state.Lms.templates,
  }))

  useEffect(() => {
    dispatch(onListTemplates())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(templates.results)) setTemplate(templates.results[0])
  }, [templates])

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  }

  const modifyTemplate = () => {
    dispatch(onCheckHeaders())
    dispatch(steppedForm({
      step: 2
    }))
  }

  const handleAcceptedFiles = (files) => {
    setOnProcessingFile(true)
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(files[0]);
    fileReader.onload = (event) => {
      let bstrdata = event.target.result
      let workbook = XLSX.read(bstrdata, { type: "binary" });
      let ws = workbook.Sheets[workbook.SheetNames[0]]
      let sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 })
      let headers = Object.fromEntries(sheetData[0].map(item => [item.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(), item]))
      if (new Set(sheetData[0]).size !== sheetData[0].length) {
        setCompError("File contains duplicate columns")
      } else {
        setFormData(prevState => ({ ...prevState, headers: headers }))
      }
    }
    fileReader.onloadend = (event) => {
      setOnProcessingFile(false)
    }
    setFileSelected(files[0])
  }

  const handleValidSubmit = (event, values) => {
    if (fileSelected === null) return
    dispatch(onCheckHeaders({ headers: formData.headers }))
    dispatch(steppedForm({
      step: 2,
      templateName: values.name
    }))
  }

  return (
    <React.Fragment>
      {loading ?
        <p className="text-center mt-5">Loading. please wait{" "}<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
        :
        <div className="p-2">
          {(!isEmpty(template)) ?
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody className="border-bottom">
                    <Dropdown
                      isOpen={isMenu}
                      toggle={toggleMenu}
                      className="float-end ms-2"
                    >
                      <DropdownToggle tag="a" className="text-muted">
                        <i className="mdi mdi-dots-horizontal font-size-18" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={modifyTemplate}>Modify</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>

                    <div>
                      <div>
                        <h5>{template.name}</h5>
                        <p className="text-muted mb-1">#{template.uid}</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            :
            <Card>
              <CardBody>
                <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Template name"
                      type="text"
                      required
                      value={""}
                    />
                  </div>

                  <div className="mb-3">
                    {fileSelected === null ?
                      <Dropzone onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }} multiple={false} accept=".xlsx, .xls">
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Select a file</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      :
                      <center>
                        <h5>Selected file: {fileSelected.name}</h5>
                        <Button onClick={() => setFileSelected(null)}>Upload Again</Button>
                      </center>
                    }
                  </div>

                  <div className="mt-3 d-flex flex-row-reverse">
                    {(loading || onProcessingFile===true) ?
                      <button className="btn btn-primary btn-block me-2" type="button" disabled>
                        Next{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </button>
                      :
                      <button className="btn btn-primary btn-block me-2" type="submit">Next</button>
                    }
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          }
        </div>
      }
    </React.Fragment >
  )
}

export default Step1;
