import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, getHmsOptions, resetHmsState, getHms } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { isEmpty } from "lodash"
import { formatDateTime } from "components/Common/common"
import { DownloadModal } from "../common/hms-crud-modals"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getLocalStorageItem, updateURLParams } from "../common/common"

const OrderMedicine = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const [orderMedicineData, setOrderMedicineData] = useState([])
  const [columns, setColumns] = useState([])
  const [downloadModal, setDownloadModal] = useState(false);

  const { loading, options, hmsList, apiKey, error, formError } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === "order-medicine-list") setOrderMedicineData(hmsList)
    if (apiKey === "order-medicine-refresh") setOrderMedicineData(hmsList)
    if (apiKey === "report_order") setDownloadModal(true)
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/medicine_orders/?options=table, search, daterange, filters"))
    if (urlParams.length > 1) {
      dispatch(listHms("/hms/pharmacy/medicine_orders/", decodeURIComponent(urlParams).replace("?", ""), "order-medicine-list"))
    } else {
      const savedFilters = getLocalStorageItem('filters')?.order_medicine
      updateURLParams(history, savedFilters)
      dispatch(listHms("/hms/pharmacy/medicine_orders/", savedFilters, "order-medicine-list"))
    }
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(options)) {
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "uid") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/pharmacy/orders/details/${row.uid}`}>
                  {row.uid}
                </Link>
              )
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <p>{formatDateTime(row.created_at)}</p>
              )
            }
          } else if (item.dataField === "supplier") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <p>{row.supplier || "---"}</p>
              )
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16" color="white">
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {row?.medicines_count === 0 ? (
                  <Link className="dropdown-item text-muted" to={"#"} title="Cannot print the report for this order">
                    Download
                  </Link>
                ) : (
                  <Link className="dropdown-item" to={"#"} onClick={() => downloadReport(row.uid)}>
                    Download
                  </Link>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/pharmacy/medicine_orders/', decodeURIComponent(urlParams).replace("?", ""), "order-medicine-refresh"))
    } else {
      dispatch(listHms('/hms/pharmacy/medicine_orders/', null, "order-medicine-refresh"))
    }
  }

  const createOrderButton = () => {
    return (
      <div className="text-sm-start">
        <Link to="/hms/pharmacy/place-order" className="me-2 btn btn-sm btn-primary">
          +&nbsp;Create&nbsp;Order
        </Link>
      </div>
    );
  }

  const downloadReport = (id) => {
    if (id) {
      dispatch(getHms(`/hms/pharmacy/medicine_orders/${id}/report_order/`, null, "report_order"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Medicine | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error ? (
            <p className="text-center text-danger">{error?.detail}</p>
          ) : (
            (!isEmpty(columns) && (
              <GenericTable
                moduleName="order_medicine"
                urlParams={urlParams}
                search={options?.search}
                daterange={options?.daterange}
                filters={options?.filters}
                columns={columns}
                keyField={"id"}
                noDataIndication={"No orders created"}
                data={orderMedicineData}
                loading={loading}
                apiTrigger={apiTrigger}
                defaultPageSize={options?.page_size}
                createButton={createOrderButton}
              />
            ))
          )}
        </Container>
      </div>

      <DownloadModal
        modal={downloadModal}
        toggle={() => setDownloadModal(false)}
        apiStartKey={"report_order"}
      />
    </React.Fragment>
  )
}

export default OrderMedicine
