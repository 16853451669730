import moment from "moment";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Spinner } from "reactstrap";
import toastr from "toastr";
import Dropzone from "react-dropzone";
import { EAvFieldInput } from "./errored-avfields";
import { get } from "helpers/api_helper";
import { useSelector } from "react-redux";
import { bloodGroupVerboseFetch, formatDateTime, genderVerboseFetch, occupationVerboseFetch, TableChip } from "components/Common/common";

export const sortByOrder = (data) => {
  let newArray = [...data]
  newArray.sort((a, b) => a.order - b.order)
  newArray.forEach(obj => {
    if (obj.children) {
      obj.children = sortByOrder(obj.children)
    }
  })
  return newArray
}

export const addObject = (newObj, data) => {
  let newArray = [...data];

  if (newObj.parent === null) {
    newArray.push(newObj);
    return sortByOrder(newArray);
  }

  const addHelper = (obj) => {
    if (obj.id === newObj.parent) {
      if (!obj.children) {
        obj.children = [];
      }
      obj.children.push(newObj);
      return { ...obj };
    } else if (obj.children) {
      return { ...obj, children: obj.children.map(addHelper) };
    }
    return obj;
  };

  newArray = newArray.map(addHelper);
  return sortByOrder(newArray);
};

export const updateObject = (updatedObj, data) => {
  let newArray = [...data]
  const updateHelper = (obj) => {
    if (obj.id === updatedObj.id) {
      return { ...obj, ...updatedObj }
    } else if (obj.children) {
      return { ...obj, children: obj.children.map(updateHelper) }
    }
    return obj
  }
  newArray = newArray.map(updateHelper)
  return sortByOrder(newArray)
}

export const removeObject = (id, data) => {
  data = data.filter(obj => {
    if (obj.id === id) {
      return false
    }
    if (obj.children) {
      obj.children = removeObject(id, obj.children)
    }
    return true
  })
  return sortByOrder(data)
}

export const formatDateString = (value, format = 'MMM DD, YYYY') => {
  return moment(value, 'YYYY-MM-DD').format(format);
}

export const stringToHumanTime = (value, format = 'hh:mm A') => {
  return moment(value, 'HH:mm:ss').format(format);
}

export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const convertDatetimeToDate = (value) => {
  const year = value.getFullYear();
  const month = String(value.getMonth() + 1).padStart(2, '0');
  const day = String(value.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const dateToTime = (date) => {
  const hours = date?.getHours();
  const minutes = date?.getMinutes();
  const seconds = date?.getSeconds();
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export const compareTime = (time) => {
  const currentTime = new Date().toLocaleTimeString('en-US', { hour12: false });
  const providedTime = new Date(`2023-01-01T${time}`);
  const currentTimeObject = new Date(`2023-01-01T${currentTime}`);
  return providedTime < currentTimeObject;
}

export const sortTimes = (time1, time2) => {
  const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
  const [hours2, minutes2, seconds2] = time2.split(':').map(Number);
  if (hours1 !== hours2) {
    return hours1 - hours2;
  }
  if (minutes1 !== minutes2) {
    return minutes1 - minutes2;
  }
  return seconds1 - seconds2;
};

export const cleanPayload = (values) => {
  let buffer = {};
  Object.entries(values).forEach(([key, value]) => {
    if (value === undefined) {
      buffer[key] = null;
    } else if (Array.isArray(value)) {
      buffer[key] = value.map((item) =>
        typeof item === "object" && item !== null ? cleanPayload(item) : item === "" || item === null ? null : item === "true" ? true : item === "false" ? false : item
      );
    } else if (typeof value === "object" && value !== null) {
      const cleanedObject = cleanPayload(value);
      buffer[key] = Object.values(cleanedObject).every((v) => v === null) ? null : cleanedObject;
    } else {
      buffer[key] = value === "" ? null : value === "true" ? true : value === "false" ? false : value;
    }
  });
  return buffer;
};

export const handleError = (error, apiKey, failPrefix, excludedKeys = []) => {
  if (error && apiKey.startsWith(failPrefix)) {
    const errorMessages = Object.entries(error)
      .filter(([key]) => !excludedKeys.includes(key))
      .map(([key, value]) => `<b>${key}:</b> ${value}`);

    if (errorMessages.length) toastr.error(errorMessages.join('<br>'));
  }
};

export const handleIncludedKeysError = (error, apiKey, failPrefix, includedKeys = [], keyName = true) => {
  if (error && apiKey.startsWith(failPrefix)) {
    const errorMessages = Object.entries(error)
      .filter(([key]) => includedKeys.length === 0 || includedKeys.includes(key))
      .map(([key, value]) => `${keyName ? `<b>${key}:</b>` : ''} ${value}`);

    if (errorMessages.length) toastr.error(errorMessages.join('<br>'));
  }
};

export const convertTo12HourFormat = (timeString) => {
  const [hours, minutes] = timeString?.split(":");
  const parsedHours = parseInt(hours, 10);
  const formattedHours = parsedHours === 0 || parsedHours === 12 ? "12" : (parsedHours % 12).toString();
  const ampm = parsedHours >= 12 ? "PM" : "AM";
  return `${formattedHours}:${minutes} ${ampm}`;
};

export const alterPaymentModeChoices = (obj) => {
  if (obj?.form_schema?.payment_mode) {
    const filteredChoices = obj.form_schema.payment_mode.choices.filter(choice => choice.value !== "SELF");
    const updatedObj = {
      ...obj,
      form_schema: {
        ...obj.form_schema,
        payment_mode: {
          ...obj.form_schema.payment_mode,
          choices: filteredChoices
        }
      }
    };
    return updatedObj;
  }
  return obj;
};

export const Loader = ({ heading = 'Fetching information...', paragraph = 'Please wait while we load the details' }) => {
  return (
    <div className="text-center">
      <div className="avatar-md mx-auto">
        <Spinner className="mt-5 fs-1" color="secondary" />
      </div>
      <div className="p-2 mt-4">
        <h4>{heading}</h4>
        <p className="text-muted">{paragraph}</p>
      </div>
    </div>
  );
}

// Component: GoBack returns to previous route; disabled if route not found.
export const GoBack = ({ historyProp, disabled = false, className = "float-end me-2" }) => {
  const history = useHistory();
  const handleCancel = () => {
    const previousRoute = history.location ? history.location.key : null;

    if (previousRoute) {
      historyProp.goBack();
    }
  }
  return (
    <Button color="secondary" className={className} disabled={!history.location.key || disabled} onClick={handleCancel}>
      Cancel
    </Button>
  )
}

// Function: handleKeyDown prevents the default behavior when the 'Enter' key is pressed. Example: onKeyDown={handleKeyDown}
export const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};

export const FileUploader = ({ handleAcceptedFiles, buttonText, buttonClass = "btn btn-sm" }) => (
  <Dropzone onDrop={handleAcceptedFiles} accept=".xls, .xlsx, .pdf, image/*">
    {({ getRootProps, getInputProps }) => (
      <div {...getRootProps()} className="d-flex justify-content-end">
        <input {...getInputProps()} />
        <Button type="button" className={buttonClass} color="primary">
          {buttonText}
        </Button>
      </div>
    )}
  </Dropzone>
);

export const ChequeSection = ({ billing, setBilling, formError, options, mb = false }) => {
  return (
    <>
      {billing?.paymentMode === "CHEQUE" && (
        <tr id="cheque-section">
          <td>
            <EAvFieldInput
              mb={mb}
              field="cheque_number"
              type="text"
              value={billing?.chequeNumber}
              onChange={(e) =>
                setBilling((prevState) => ({
                  ...prevState,
                  chequeNumber: e.target.value,
                }))
              }
              isError={formError?.cheque_number}
              bsSize="sm"
              options={options?.cheque_number}
            />
          </td>
          <td>
            <EAvFieldInput
              mb={mb}
              field="cheque_date"
              type="date"
              value={billing?.chequeDate}
              onChange={(e) =>
                setBilling((prevState) => ({
                  ...prevState,
                  chequeDate: e.target.value,
                }))
              }
              isError={formError?.cheque_date}
              bsSize="sm"
              options={options?.cheque_date}
            />
          </td>
        </tr>
      )}
    </>
  );
};

export const EAvFieldDOB = ({ showAgeInput, toggleSwitch, formError, required, ...props }) => {
  const toggle = () => {
    localStorage.setItem('showAgeInput', !showAgeInput)
    toggleSwitch()
  }
  return (
    <>
      {showAgeInput ? (
        <div className="d-flex align-items-center">
          <div className="w-100 me-2">
            <EAvFieldInput
              field="dob"
              type="number"
              isError={formError?.dob}
              options={{ label: "Age", placeholder: "in years", required: required }}
              step="0.1"
              min="0"
              max="150"
              validate={{
                min: { value: "0", errorMessage: 'Age must be at least 0' },
                max: { value: "150", errorMessage: 'Age must be at most 150' },
                pattern: { value: /^[0-9]*(\.[0-9]{0,1})?$/, errorMessage: 'More than one decimal place is not allowed' }
              }}
              {...props}
            />
          </div>
          <div className="mt-3">
            <i
              className="bx bx-sort font-size-18"
              style={{ transform: 'rotate(90deg)' }}
              title="Switch to Date Input"
              onClick={toggle}
            ></i>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <div className="w-100 me-2">
            <EAvFieldInput
              field="dob"
              type="date"
              isError={formError?.dob}
              options={{ label: "Date of birth", required: required }}
              daterange={{ start: { value: -150, units: "years" }, end: { value: 0, units: "years" } }}
              {...props}
            />
          </div>
          <div className="mt-3">
            <i
              className="bx bx-sort font-size-18"
              style={{ transform: 'rotate(90deg)' }}
              title="Switch to Age Input"
              onClick={toggle}
            ></i>
          </div>
        </div>
      )}
    </>
  );
};

export const handlePhonenumberFocus = (setError) => {
  setError('');
};

export const handlePhonenumberBlur = (value, setError) => {
  if (!value) {
    setError('This field is invalid');
  }
};

export const formatPercentage = (value = '', integerLength = 3, decimalLength = 2, maxValue = 100) => {
  const formattedValue = value.replace(/[^0-9.]/g, '');
  const [integerPart, decimalPart] = formattedValue.includes('.') ? formattedValue.split('.') : [formattedValue, ''];
  const truncatedIntegerPart = integerPart.slice(0, integerLength);
  const truncatedDecimalPart = decimalPart.slice(0, decimalLength);
  const result = `${truncatedIntegerPart}${truncatedDecimalPart && `.${truncatedDecimalPart}`}`;
  return parseFloat(result) > maxValue ? maxValue.toString() : result;
};

export const floatRoundDown = (value, decimalPlaces = 2) => {
  return Math.floor(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
}

export const savePinPage = (url, id) => {
  const currentData = JSON.parse(localStorage.getItem("pinPage")) || [];
  if (currentData.url === url) {
    localStorage.removeItem("pinPage");
  } else {
    localStorage.setItem("pinPage", JSON.stringify({ url, id }));
  }
};

export const SubmitLoaderButton = props => {
  const { loading, type = "button", onClick = undefined, disabled = false, size, children, goBack = false, history = false } = props

  return loading ? (
    <div className="justify-content-end">
      {goBack && history && <GoBack disabled className="me-2" />}
      <Button type="button" color="primary" disabled size={size}>
        {children}{" "}
        <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
      </Button>
    </div>
  ) : (
    <div className="justify-content-end">
      {goBack && history && <GoBack historyProp={history} className="me-2" />}
      <Button type={type} color="primary" onClick={onClick} disabled={disabled} size={size}>
        {children}
      </Button>
    </div>
  )
}

export const downloadTestReports = (testObject) => {
  if (testObject?.id) {
    get(`/hms/diagnosis/book-tests/${testObject?.id}/test_report/`, {
      responseType: 'blob'
    }).then((response) => {
      const blob = response;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `LAB-REPORTS-${testObject?.patient_uid}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      toastr.success("Report downloaded successfully!")
    }).catch((error) => {
      toastr.error("Failed to download report. This report tests might be removed. Open results and compare the tests");
    });
  }
}

export const ageToDateOfBirth = (age) => {
  if (age) {
    const today = moment();
    const birthYear = today.year() - Math.floor(age);
    const fraction = age - Math.floor(age);
    const birthDate = today.year(birthYear).subtract(fraction * 365, 'days').format('YYYY-MM-DD');
    return birthDate;
  }
  return null;
}

export const HMSLink = ({ children, className = "dropdown-item", disabled = false, apiStartKey, onClick, showDownloadIcon = false, ...props }) => {

  const { apiKey } = useSelector(state => ({
    apiKey: state.Hms.apiKey,
  }));

  return (
    <>
      {apiKey === `get_${apiStartKey}` || apiKey === `post_${apiStartKey}` ? (
        <Link className={`${disabled ? "disabled" : ""} ${className} text-muted`} to={"#"} {...props}>
          {children} {showDownloadIcon && <i className="bx bx-loader bx-spin align-middle ms-1" />}
        </Link>
      ) : (
        <Link className={`${disabled ? "disabled" : ""} ${className}`} to={"#"} onClick={onClick} {...props}>
          {children} {showDownloadIcon && <i className="mdi mdi-download align-middle ms-1" />}
        </Link>
      )}
    </>
  );
}

export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageItem = (key) => {
  return JSON.parse(localStorage.getItem(key)) || {};
};

export const updateURLParams = (history, filterParams = null) => {
  if (filterParams) {
    history.replace(location.pathname + "?" + filterParams.split("&").filter(value => !value.includes("page")).join("&"))
  } else {
    history.replace(location.pathname)
  }
}

export const PatientInformation = ({ data, instance, includedFields = [] }) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        {includedFields.includes("patient_name") && (
          <TableChip
            label="Patient name"
            value={`${data?.honorific} ${data?.first_name} ${data?.last_name} ${data?.guardian || ""}`}
          />
        )}
        {includedFields.includes("patient_id") && (
          <TableChip
            label="Patient ID"
            value={data?.uid}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        {includedFields.includes("gender") && (
          <TableChip
            label="Gender"
            value={genderVerboseFetch(data?.user_data?.gender)}
          />
        )}
        {includedFields.includes("marital_status") && (
          <TableChip
            label="Marital status"
            value={data?.marital_status === null ? null : data?.marital_status ? "Married" : "Unmarried"}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        {includedFields.includes("date_of_birth") && (
          <TableChip
            label="Date of birth"
            value={formatDateTime(data?.user_data?.dob, "MMM D, YYYY", "NA")}
          />
        )}
        {includedFields.includes("age") && (
          <TableChip
            label="Age"
            value={data?.user_data?.age ? `${data?.user_data?.age} years` : null}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        {includedFields.includes("mobile") && (
          <TableChip
            label="Mobile"
            value={data?.user_data?.phone_number}
          />
        )}
        {includedFields.includes("email") && (
          <TableChip
            label="Email"
            value={data?.user_data?.email}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        {includedFields.includes("alternate_mobile") && (
          <TableChip
            label="Alternate Mobile"
            value={data?.alternate_phone_number}
          />
        )}
        {includedFields.includes("occupation") && (
          <TableChip
            label="Occupation"
            value={occupationVerboseFetch(data?.occupation)}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        {includedFields.includes("blood_group") && (
          <TableChip
            label="Blood Group"
            value={bloodGroupVerboseFetch(data?.blood_group)}
          />
        )}
        {includedFields.includes("registered_on") && (
          <TableChip
            label="Registered On"
            value={data?.created_at ? formatDateTime(data?.created_at) : null}
          />
        )}
        {includedFields.includes("visit_no") && instance?.number && (
          <TableChip
            label="Visit no"
            value={instance?.number}
          />
        )}
        {includedFields.includes("op_date") && instance?.created_at && (
          <TableChip
            label="OP date"
            value={instance?.created_at ? formatDateTime(instance?.created_at) : null}
          />
        )}
        {includedFields.includes("ip_number") && (
          <TableChip
            label="IP number"
            value={instance?.number}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        {includedFields.includes("consulting_doctor") && (
          <TableChip
            label="Consulting doctor"
            value={instance?.doctor_data?.user_data ? `${instance?.doctor_data?.user_data?.full_name} - ${instance?.doctor_data?.specialization}` : null}
          />
        )}
        {includedFields.includes("op_number") && (
          <TableChip
            label="OP number"
            value={instance?.number}
          />
        )}
      </div>
      <div className="d-flex justify-content-between">
        {includedFields.includes("op_number") && includedFields.includes("username") && (
          <TableChip
            label="Username"
            infoTitle="This can be used for login purpose"
            value={data?.user_data?.username}
          />
        )}
      </div>
    </>
  )
}

export const timeSlotsStyle = {
  listStyleType: "none",
  padding: 0,
  display: "flex",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  maxWidth: "100%",
  overflowX: "auto",
};

export const timeSlotStyle = {
  padding: "9px",
  margin: "5px 5px 5px 0",
  cursor: "pointer",
  borderRadius: "5px",
};

export const errorMappings = {
  PHONE_EXISTS: {
    title: "Phone exists",
    message: "The entered phone number is already associated with a patient. Would you like to proceed to create patient anyway?"
  },
  EMAIL_EXISTS: {
    title: "Email exists",
    message: "The entered email is already associated with a patient. Would you like to proceed to create patient anyway?"
  },
  PHONE_EXISTS_IN_STAFF: {
    title: "Phone exists in staff",
    message: "The entered phone number is already associated with a staff. Would you like to add this person as doctor as well?"
  },
  EMAIL_EXISTS_IN_STAFF: {
    title: "Email exists in staff",
    message: "The entered email is already associated with a staff. Would you like to add this person as doctor as well?"
  },
  PHONE_EXISTS_IN_DOCTOR: {
    title: "Phone exists in doctor",
    message: "The entered phone number is already associated with a doctor. Would you like to add this person as staff as well?"
  },
  EMAIL_EXISTS_IN_DOCTOR: {
    title: "Email exists in doctor",
    message: "The entered email is already associated with a doctor. Would you like to add this person as staff as well?"
  }
};

export const formSchemaConversionAvField = (formSchema) => {
  const modifiedFormSchema = {}

  Object.keys(formSchema).forEach((key) => {
    const {
      label = "",
      type = "text",
      required = false,
      read_only: disabled = false,
      max_length: maxLength = undefined,
      min_value: minValue = undefined,
      max_value: maxValue = undefined,
      placeholder: placeholder = "",
      help_text: helpMessage = "",
    } = formSchema[key]

    const fieldType = ["string", "url", "field"].includes(type) ? "text" : ["integer", "float"].includes(type) ? "number" : type
    const validateRules = {}

    if (maxLength !== undefined) {
      validateRules.maxLength = { value: maxLength, errorMessage: `Maximum length is ${maxLength} characters` }
    }

    if (fieldType === "number") {
      if (minValue !== undefined) {
        validateRules.min = { value: minValue, errorMessage: `Minimum value is ${minValue}` }
      }
      if (maxValue !== undefined) {
        validateRules.max = { value: maxValue, errorMessage: `Maximum value is ${maxValue}` }
      }
    }

    if (fieldType === "email") {
      validateRules.email = { value: true, errorMessage: "Invalid email format" }
    }

    modifiedFormSchema[key] = {
      id: `id_${key}`,
      name: key,
      type: fieldType,
      required: required,
      disabled: disabled,
      label: `${label} ${required ? " *" : ""}`,
      placeholder: placeholder,
      helpMessage: helpMessage,
      validate: validateRules,
    }
  })

  return modifiedFormSchema
}
