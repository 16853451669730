import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const UserSidebarContent = props => {
  const ref = useRef()

  const [role, setRole] = useState(null)

  useEffect(() => {
    let authObject = JSON.parse(localStorage.getItem("authUser"))
    setRole(authObject.role)
  }, [])

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {(["ADMIN", "MANGR"].includes(role)) &&
              <>
                <li>
                  <Link to="/users">
                    <i className="bx bx-group"></i>
                    <span>Users</span>
                  </Link>
                </li>
                {role !== "MANGR" &&
                  <li>
                    <Link to="/users/manual-signup">
                      <i className="bx bx-user-plus"></i>
                      <span>Add Users</span>
                    </Link>
                  </li>
                }
                <li>
                  <Link to="/users/pending-requests">
                    <i className="bx bx-user-check"></i>
                    <span>Pending requests</span>
                  </Link>
                </li>
                <li>
                  <Link to="/users/manage"><i className="bx bxs-school"></i><span>{props.t("Organization")}</span></Link>
                </li>
              </>
            }
            <li>
              <Link to="/users/display-settings/#font-size">
                <i className="bx bxs-layout"></i>
                <span>General</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(UserSidebarContent))
