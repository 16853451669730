import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row } from "reactstrap"
import { getHms, resetHmsState } from "store/actions";
import { TableChip } from "components/Common/common";
import { formatDateString } from "../common/common";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const MedicineDetail = (props) => {
  const dispatch = useDispatch();
  const [medicine, setMedicine] = useState();

  const { loading, hmsObject, apiKey, error } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
  }));

  useEffect(() => {
    dispatch(getHms("/hms/pharmacy/inventory/", props.match.params.medicineID, "fetch-medicine"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch]);

  useEffect(() => {
    if (apiKey === "fetch-medicine") setMedicine(hmsObject)
  }, [hmsObject]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Medicine Details | Yanthura</title>
        </MetaTags>
        {loading ?
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching medicine details...</p>
          :
          <Card>
            <CardBody>
              {error ?
                <p className="text-center text-danger">{error.detail}</p>
                :
                <Row>
                  <Col>
                    <div className="mt-2 mb-0 d-flex justify-content-between">
                      <h5 className="mt-2">Medicine Details</h5>
                      <div className="d-flex justify-content-end">
                        <Link title="Modify" className="text-muted me-2" to={`/hms/pharmacy/medicine/medstock/${props.match.params.medicineID}`}>
                          <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                        </Link>
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip label="Medicine name" value={medicine?.medicine_data?.name} />
                      <TableChip label="mg / ml" value={medicine?.medicine_data?.dosage} defaultIfNull="---" />
                    </div>
                    <TableChip label="Formula" value={medicine?.medicine_data?.formula} defaultIfNull="---" />
                    <div className="d-flex justify-content-between">
                      <TableChip label="Stock Quantity" value={medicine?.quantity} defaultIfNull="---" />
                      <TableChip label="Manufacturer" value={medicine?.medicine_data?.manufacturer} defaultIfNull="---" />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip label="Batch Number" value={medicine?.batch_number} />
                      <TableChip label="Expiry Date" value={formatDateString(medicine?.expiry_date)} />
                    </div>
                    <TableChip label="Quantity" value={medicine?.quantity} />
                    <div className="d-flex justify-content-between">
                      <TableChip label="CGST (%)" value={medicine?.cgst_percent} defaultIfNull="---" />
                      <TableChip label="CGST" value={medicine?.cgst} defaultIfNull="---" />
                      <TableChip label="SGST (%)" value={medicine?.sgst_percent} defaultIfNull="---" />
                      <TableChip label="SGST" value={medicine?.sgst} defaultIfNull="---" />
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip label="MRP" value={medicine?.mrp} />
                      <TableChip label="Price" value={medicine?.price} />
                      <TableChip label="Total Price" value={medicine?.total_price} />
                    </div>
                  </Col>
                  <Col xl="1"></Col>
                  <Col>
                    <h5 className="mt-2">Supplier Information</h5>
                    <hr />
                    {medicine?.supplier_data?.id ?
                      <>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Name" value={medicine?.supplier_data?.name} />
                          <TableChip label="Supplier ID" value={medicine?.supplier_data?.uid} defaultIfNull="---" />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact Person" value={medicine?.supplier_data?.contact_person} />
                          <TableChip label="Contact Mobile" value={medicine?.supplier_data?.mobile} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact Person 2" value={medicine?.supplier_data?.contact_person_2} defaultIfNull="---" />
                          <TableChip label="Contact Mobile 2" value={medicine?.supplier_data?.alternate_mobile} defaultIfNull="---" />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact Mail" value={medicine?.supplier_data?.email} defaultIfNull="---" />
                          <TableChip label="Website" value={medicine?.supplier_data?.website} defaultIfNull="---" />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="GST Number" value={medicine?.supplier_data?.gst_number} defaultIfNull="---" />
                          <TableChip label="Customer Type" value={medicine?.supplier_data?.customer_type} />
                        </div>
                        <TableChip label="Notes" value={medicine?.supplier_data?.notes} defaultIfNull="---" />
                        <h5 className="mt-2">Address</h5>
                        <hr />
                        <TableChip label="Line 1" value={medicine?.supplier_data?.address?.line1} />
                        <TableChip label="Line 2" value={medicine?.supplier_data?.address?.line2} defaultIfNull="---" />
                        <div className="d-flex justify-content-between">
                          <TableChip label="City" value={medicine?.supplier_data?.address?.city} />
                          <TableChip label="District" value={medicine?.supplier_data?.address?.district} defaultIfNull="---" />
                          <TableChip label="State" value={medicine?.supplier_data?.address?.state} />
                          <TableChip label="Pincode" value={medicine?.supplier_data?.address?.pincode} />
                        </div>
                      </>
                      :
                      <p className="text-center text-muted">Supplier information not available or deleted</p>
                    }
                  </Col>
                </Row>
              }
            </CardBody>
          </Card>
        }
      </div>
    </React.Fragment >
  );
};

export default MedicineDetail;
