import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { ADD_USER, MANUALSIGNUP_ACTIVATE, MANUALSIGNUP_VERIFY, REGISTER_USER, REGISTER_VERIFICATION } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed, registerVerificationFailed, registerVerificationSuccessful, addUserSuccessful, addUserFailed, manualSignupActivationSuccessful, manualSignupActivationFailed, manualSignupActLinkVerifySuccessful, manualSignupActLinkVerifyFailed } from "./actions"
import { addUserAPI, manualSignupActivationAPI, manualSignupActLinkVerifyAPI, registerAPI, registerVerificationAPI } from "./api_helper"


function* registerVerificationSaga({ payload: { data }, key }) {
  try {
    const response = yield call(registerVerificationAPI, data)
    yield put(registerVerificationSuccessful(response, key))
  } catch (error) {
    yield put(registerVerificationFailed(error.response.data, key))
  }
}

function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(registerAPI, user)
    yield put(registerUserSuccessful(response))
  } catch (error) {
    yield put(registerUserFailed(error.response.data))
  }
}

function* addUserSaga({ payload: { user } }) {
  try {
    const response = yield call(addUserAPI, user)
    yield put(addUserSuccessful(response))
  } catch (error) {
    yield put(addUserFailed(error.response.data))
  }
}

function* manualSignupActLinkVerifySaga({ payload: token }) {
  try {
    const response = yield call(manualSignupActLinkVerifyAPI, token.token)
    yield put(manualSignupActLinkVerifySuccessful(response))
  } catch (error) {
    yield put(manualSignupActLinkVerifyFailed(error.response.data))
  }
}

function* manualSignupActivationSaga({ payload: payload }) {
  try {
    const response = yield call(manualSignupActivationAPI, payload)
    yield put(manualSignupActivationSuccessful(response))
  } catch (error) {
    yield put(manualSignupActivationFailed(error.response.data))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(ADD_USER, addUserSaga)
  yield takeEvery(MANUALSIGNUP_VERIFY, manualSignupActLinkVerifySaga)
  yield takeEvery(MANUALSIGNUP_ACTIVATE, manualSignupActivationSaga)
  yield takeEvery(REGISTER_VERIFICATION, registerVerificationSaga)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
