import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

import { Button, Card, CardBody, Col, Row, Badge, Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, Modal, ModalBody } from "reactstrap"
import { getPendingRequests as onGetPendingRequests, updatePendingRequests as onUpdatePendingRequests, getPendingRequestsUserTypes as onGetDynamicUserOptions, resendActivationLink as onResendActivationLink } from "store/actions"

import { useSelector, useDispatch } from "react-redux"
import { MetaTags } from "react-meta-tags"
import moment from "moment"
import { getURLParam, updateObject } from "components/Common/common"
import RemoteGenericTable from "components/Common/tables"
import UserFilters from "./user-filters"
import toastr from "toastr"
import Breadcrumbs from "components/Common/Breadcrumb"


const PendingRequests = props => {
  const dispatch = useDispatch()
  const [pendingRequestsList, setPendingRequestsList] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [userTypesList, setUserTypesList] = useState([])
  const [filter, setFilter] = useState({})
  const [filters, setFilters] = useState(props.location.state ? props.location.state : {})
  const [currentPage, setCurrentPage] = useState(1)
  const [userStatusList, setUserStatusList] = useState([])
  const [userStatusFlags, setUserStatusFlags] = useState({})
  const [userTypeSelect, setUserTypeSelect] = useState({})
  const [enableModal, setEnableModal] = useState(false)


  const { pendingRequestUserTypes, pendingRequests, detailError, usernames, loading, usersActivated, successDataResendActLinks } = useSelector(state => ({
    pendingRequestUserTypes: state.DashboardGenericState.pendingRequestUserTypes,
    pendingRequests: state.DashboardGenericState.pendingRequests,
    detailError: state.DashboardGenericState.detailError,
    usernames: state.DashboardGenericState.usernames,
    loading: state.DashboardGenericState.loading,
    usersActivated: state.DashboardGenericState.usersActivated,
    successDataResendActLinks: state.DashboardGenericState.successDataResendActLinks,
  }))

  const filterOptions = {
    ut: "comma",
    status: "comma"
  }

  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      (isSelect && row.status === 'OVRF') ? setSelectedUsers([...selectedUsers, row.username]) : setSelectedUsers(selectedUsers.filter(user => user !== row.username))
    },
    onSelectAll: (isSelect, rows, e) => {
      isSelect ? setSelectedUsers(rows.filter(row => row.status === 'OVRF').map(row => row.username)) : setSelectedUsers([])
    }
  }

  const columns = [{
    dataField: "full_name",
    text: "Name",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.full_name}
      </Link>
    ),
  }, {
    dataField: "user_type",
    text: "User Type",
    sort: true,
    formatter: (cellContent, row) => (<>
      {row.status === "OVRF" ?
        <select onChange={(e) => {
          setUserTypeSelect(prevState => ({ ...prevState, [row.username]: e.target.value }))
        }} className="form-control" defaultValue={row.user_type}>
          <option value="ADMIN">Admin</option>
          <option value="MANGR">Manager</option>
          <option value="AGENT">Agent</option>
        </select>
        :
        <Badge
          className={"font-size-12"}
          color={row.user_type === 'AGENT' ? 'success' : row.user_type === 'MANGR' ? 'warning' : row.user_type === 'ADMIN' ? 'danger' : 'secondary'}
          pill
        >
          {row.user_type === "ADMIN" ? "Admin" : row.user_type === "MANGR" ? "Manager" : "Agent"}
        </Badge>
      }
    </>),
  }, {
    dataField: "status",
    text: "Signup method",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={"font-size-12"}
        color={row.status === 'ECNF' ? 'secondary' : row.status === 'OVRF' ? 'info' : 'danger'}
        pill
      >
        {row.status === "ECNF" ? "Manual" : row.status === "OVRF" ? "Self" : "Unknown"}
      </Badge>
    ),
  }, {
    dataField: "date_joined",
    text: "Joined at",
    sort: true,
    formatter: (cellContent, row) => (
      <p title={moment(row.date_joined).format('MMM D, YYYY')}>{moment(row.date_joined).fromNow()}</p>
    )
  }, {
    dataField: "email",
    text: "Email",
    sort: true,
  }, {
    dataField: "phone_number",
    text: "Phonenumber",
    sort: true,
  }, {
    dataField: "action",
    text: "",
    isDummyField: true,
    formatter: (cellContent, row) => (
      <>
        {row.status === 'ECNF' &&
          <UncontrolledDropdown className="ms-auto">
            <DropdownToggle className="text-muted font-size-16" color="white">
              <i className="mdi mdi-dots-horizontal"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <Link className="dropdown-item" to="#" onClick={() => handleResendActivationLink([row.username])}>
                Resend verification link
              </Link>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
      </>
    ),
  }
  ]

  useEffect(() => {
    dispatch(onGetDynamicUserOptions(['ut', 'status']))
    dispatch(onGetPendingRequests())
  }, [dispatch]);

  useEffect(async () => {
    await setTimeout(() => {
      let body = document.body;
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }, 2)
  }, [])

  useEffect(() => {
    if (!isEmpty(pendingRequests.results)) {
      setPendingRequestsList(pendingRequests.results)
      pendingRequests.results.map(obj => setUserTypeSelect(prevState => ({ ...prevState, [obj.username]: obj.user_type })))
    }
  }, [pendingRequests])

  useEffect(() => {
    if (!isEmpty(successDataResendActLinks)) toastr.success(successDataResendActLinks.detail)
  }, [successDataResendActLinks])

  useEffect(() => {
    let options = pendingRequestUserTypes.user_type
    if (!isEmpty(options)) {
      setUserTypesList(options.map(i => i.type))
      setUserTypes(options)
      // toastr.success("Users activated")
    }
  }, [pendingRequestUserTypes.user_type])

  useEffect(() => {
    let options = pendingRequestUserTypes.status
    if (!isEmpty(options)) {
      let statusFlags = {}
      options.forEach(status => {
        statusFlags[status.value] = true
      })
      setUserStatusFlags(statusFlags)
      setUserStatusList(options)
      // toastr.success("Users activated")
    }
  }, [pendingRequestUserTypes.status])

  useEffect(() => {
    if (usersActivated) {
      dispatch(onGetPendingRequests())
      setSelectedUsers([])
      setEnableModal(false)
    }
  }, [usersActivated])

  const handleUserTypeChange = (userType, status) => {
    let uTL = userTypesList
    if (status) {
      uTL = uTL.filter(uT => uT != userType)
    } else {
      uTL.push(userType)
    }
    uTL = [...new Set(uTL)]
    setPendingRequestsList(pendingRequests.results.filter(user => (uTL).includes(user.user_type)))
    setUserTypesList(uTL)
    setUserTypes(userTypes.map(user => user.type === userType ? { ...user, checked: !user.checked } : user))

    let newState = updateObject(filter, { ut: uTL.map(uT => uT === "Agent" ? "AGENT" : uT === "Manager" ? "MANGR" : uT === "Admin" ? "ADMIN" : uT) })
    let urlParams = getURLParam(newState, filterOptions)
    delete newState.page
    dispatch(onGetPendingRequests(urlParams))
    setFilter(newState)
  }

  const makeActive = () => {
    if (!isEmpty(selectedUsers)) {
      let payload = {}
      selectedUsers.map(value => {
        payload[value] = userTypeSelect[value]
      })
      dispatch(onUpdatePendingRequests(payload))
    }
  }

  const fetchNewPage = (page, sizePerPage) => {
    let newState = updateObject(filter, { page: page })
    let urlParams = getURLParam(newState, filterOptions)
    // urlParams += newState.page ? `&page=${page}` : ""
    dispatch(onGetPendingRequests(urlParams))
    setFilter(newState)
  }

  const handleResendActivationLink = (usernames) => {
    dispatch(onResendActivationLink(usernames))
  }


  const shootFetcher = (inFilters = null, page = null) => {
    if (inFilters) {
      let urlParam = getURLParam(inFilters, filterOptions)
      if (page) urlParam += `&page=${page}`
      dispatch(onGetPendingRequests(urlParam))
    } else {
      dispatch(onGetPendingRequests())
    }
    setFilters(inFilters ? inFilters : {})
    setCurrentPage(page ? page : 1)
  }

  const handleUserStatusChanged = (value, flag) => {
    let newState = userStatusList.map(item => item.value === value ? { ...item, checked: flag } : item)
    setUserStatusList(newState)
    let filtState = newState.map(i => i.checked && i.value).filter(i => typeof (i) == 'string')
    newState = updateObject(filter, { status_choice: filtState })
    let urlParams = getURLParam(newState, filterOptions)
    dispatch(onGetPendingRequests(urlParams))
  }

  function toggleModal() {
    setEnableModal(!enableModal)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pending requests | Yanthura</title>
        </MetaTags>
        <Modal isOpen={enableModal} toggle={toggleModal} backdrop="static">
          <ModalBody>
            <p>Manual signup users(<Badge className="font-size-12 badge-soft-secondary" color="secondary" pill>Manual</Badge>) will be activated by email confirmation. You don't have to take extra actions for these users. They will disappear from here when they activated by email link</p>
            <p>Users who are signed up using Signup form(<Badge className="font-size-12 badge-soft-info" color="info" pill>Self</Badge>). They'll be activated when you make them active from this screen.</p>
            <Row className="float-end">
              <Col>
                <button type="button" className="btn btn-primary btn-sm me-2" onClick={makeActive}>Understood & Submit</button>
                <button type="button" className="btn btn-sm me-2" onClick={toggleModal}>Cancel</button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Container fluid>
          <Breadcrumbs title="Manage" breadcrumbItem="Pending requests" />
          <UserFilters filters={filters} setFilter={setFilters} shootFetcher={shootFetcher} hideDateRangePicker={true} />
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <div>
                    <h5 className="font-size-14">User Types</h5>
                    {userTypes.map((type, idx) =>
                      <div className="form-check form-check-end" key={idx}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={type.type}
                          defaultChecked={type.checked}
                          onChange={() => handleUserTypeChange(type.type, type.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={type.type}
                        >
                          {type.type}
                        </label>
                      </div>
                    )}
                  </div>
                  <br />
                  <div>
                    <h5 className="font-size-14">Signup method</h5>
                    {userStatusList.map((status, idx) =>
                      <div className="form-check form-check-end" key={idx}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id={status.value}
                          defaultChecked={status.checked}
                          onChange={() => handleUserStatusChanged(status.value, !status.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={status.label}
                        >
                          {status.label}
                        </label>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="9">
              <Card>
                <CardBody>
                  <div className="text-sm-end">
                    <Button
                      type="button"
                      color="primary"
                      className="mb-2 me-2 btn-sm"
                      onClick={toggleModal}
                      disabled={isEmpty(selectedUsers)}
                    >
                      <i className="mdi mdi-check-all me-1" />
                      Make active
                    </Button>
                  </div>
                  <RemoteGenericTable loading={loading} data={pendingRequests} columns={columns} tKey={'username'} noDataMessage={"No pending requests"} pageChangeHandler={fetchNewPage} selectRow={selectRow} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PendingRequests
