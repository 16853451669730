import React, { useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import moment from 'moment';

const UserComponent = (props) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        {props.data ? 
        <div>
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="12">
                <div className="text-primary p-3">
                  <h5 className="text-primary text-end">{props.data.full_name}</h5>
                </div>
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="4">
                {!props.data.avatar ?
                  <div className="avatar-md profile-user-wid">
                    <span className="avatar-title rounded-circle bg-success font-size-24">
                      {props.data.full_name ? props.data.full_name.charAt(0) : 'A'}
                    </span>
                  </div>
                :
                  <div className="avatar-md profile-user-wid mb-4">
                    <img
                      src={props.data.avatar}
                      alt=""
                      className="img-thumbnail rounded-circle"
                    />
                  </div>
                }
              </Col>

              <Col sm="8">
                <div className="pt-4">
                  <Row>
                    <Col xs="12">
                      <h5 className="font-size-15">{props.data.email}</h5>
                      <p className="text-muted mb-0">Joined at {moment(props.data.date_joined).format("LL")}</p>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Link to={`/user/${props.data.username}/dashboard`} className="btn btn-primary btn-sm">
                      View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </div>
        :
        <CardBody>
          No data available
        </CardBody>
        }
      </Card>
    </React.Fragment>
  )
}
export default UserComponent
