import React, { createRef, useEffect, useRef, useState } from "react"
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  Row,
} from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import "assets/scss/datatables.scss"

import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from 'moment';
import Flatpickr from "react-flatpickr"
import Chip from "components/Common/chips";
import { frameURLParam, tableSearchIconSizeFetch, loadOrNoData } from "components/Common/common"
import TableChipContainer from "components/Common/chips";
import AsyncSelect from "react-select/async";
import { get } from "helpers/api_helper"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getLocalStorageItem, setLocalStorageItem, updateURLParams } from "./common";


/**
 * Options must rendered in the server through OPTIONS api
 * 
 * To create options must follow a specific data format
 * 
 * Options is an object with field name as a key and structure of the input element as the value(Value also an object)
 * 
 * An example format of options data
 * @example
 * {
    "search": {
        "type": "text",
        "value": "",
        "apiKey": "search",
        "placeholder": "Search calls here"
    },
    "daterange": {
        "type": "daterange",
        "apiKey": [
            "date",
            "date_end"
        ],
        "value": [],
        "placeholder": "Select a date range"
    },
    "duration": {
        "label": "Duration",
        "type": "singlerange",
        "apiKey": "duration",
        "value": "222.2",
        "max": 525,
        "step": "0.1",
        "proxy": "time"
    },
    "score": {
        "label": "Score",
        "type": "singlerange",
        "apiKey": "score",
        "value": "62",
        "max": 82.0,
        "step": 1
    },
    "categories": {
        "label": "Categories",
        "type": "checkbox",
        "apiKey": "category",
        "data": [
            {
                "label": "Appointment",
                "value": "appointment",
                "checked": false
            },
            {
                "label": "Reschedule",
                "value": "reschedule",
                "checked": false
            },
            {
                "label": "Enquiry",
                "value": "enquiry",
                "checked": false
            }
        ]
    },
    "branches": {
        "label": "Branches",
        "type": "checkbox",
        "apiKey": "branch",
        "childApiKey": "group",
        "data": [
            {
                "label": "Mumbai",
                "value": "0nBLDYdjXkbxQ",
                "checked": true,
                "data": [
                    {
                        "label": "Creative clouds",
                        "value": "7vUURBWhG9mwQ",
                        "checked": true
                    },
                    {
                        "label": "Ring",
                        "value": "sG0HDlfmWFUlA",
                        "checked": true
                    }
                ]
            },
            {
                "label": "Hyderabad",
                "value": "aj35vT6x2l2bg",
                "checked": false,
                "data": [
                    {
                        "label": "Avatar",
                        "value": "lcNKR79saHA2SQ",
                        "checked": false
                    }
                ]
            }
        ]
    },
    "misc": {}
}
*/
const GenericTable = props => {
  let {
    urlParams,
    filters,
    columns,
    keyField = "id",
    noDataIndication = "Records not found",
    search,
    daterange,
    loading = false,
    defaultPageSize = 15,
    data,
    removeQueryParams = false,
    moduleName = null,
  } = props

  const location = useLocation()
  const history = useHistory()
  let suTo = null
  let parentCbRef = {}
  let timeoutObject = null
  const dateRangeRef = useRef()
  const inputRef = useRef()
  urlParams = new URLSearchParams(urlParams)

  const [filtersCopy, setFiltersCopy] = useState({})
  const [showFilters, setShowFilters] = useState(localStorage.getItem('showFilters') === null ? true : localStorage.getItem('showFilters') === 'true');
  const defaultCurrentPage = 1
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [dropdownSelectedOptions, setDropdownSelectedOptions] = useState({})
  const [period, setPeriod] = useState({})
  const [defaultDate, setDefaultDate] = useState([])
  const [periodDropdown, setPeriodDropdown] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const pageOptions = {
    custom: true,
    page: currentPage,
    hidePageListOnlyOnePage: true,
    sizePerPage: pageSize,
    totalSize: data.count,
    sizePerPageList: [{
      text: '10', value: 10
    }, {
      text: '15', value: 15
    }, {
      text: '20', value: 20
    }, {
      text: '25', value: 25
    }, {
      text: '30', value: 30
    }]
  }

  // Set filters state after page refresh
  useEffect(() => {
    const loadedFilters = filtersCopy;
    filters &&
      Object.entries(filters).forEach(([key, val]) => {
        const paramValue = urlParams.get(key);
        if (paramValue !== null) {
          loadedFilters[key] =
            val.type === "checkbox"
              ? { ...val, data: val.data.map((item) => ({ ...item, checked: paramValue.includes(item.value) })) }
              : val.type === "singlerange"
                ? { ...val, value: Number(paramValue) }
                : val.type === "boolean"
                  ? { ...val, value: paramValue === "true" }
                  : { ...val, value: paramValue };
        }
      });

    if (search && loadedFilters?.search) {
      loadedFilters.search.value = urlParams.get("search") || "";
    }

    if (loadedFilters?.daterange) {
      const dateStart = urlParams.get("date");
      const dateEnd = urlParams.get("date_end");
      if (dateStart && dateEnd) {
        loadedFilters.daterange.value = [dateStart, dateEnd];
      } else {
        loadedFilters.daterange.value = "";
      }
    }

    setFiltersCopy(loadedFilters);
  }, [filters, search, daterange, urlParams]);

  useEffect(() => {
    if (filters) setFiltersCopy(filters)
    if (search) setFiltersCopy(prevState => ({ ...prevState, search: search }))
    if (daterange) setFiltersCopy(prevState => ({ ...prevState, daterange: daterange }))
  }, [filters, search, daterange])

  if (filters) {
    Object.entries(filters).map(([key, val], idx) => {
      if (val.type === "checkbox" && val.data) {
        val.data.map(item => {
          if (item.data) parentCbRef[item.value] = createRef()
        })
      }
    })
  }

  const searchBar = (event) => {
    let value = event.target.value.trim()
    if (value === "" || value.length > 1) {
      let tObj = filtersCopy
      tObj["search"]["value"] = value
      shootFetcher(tObj, { lazy: true })
    }
  }

  const onDaterangeChange = (dateRange) => {
    if (!isEmpty(dateRange)) {
      let [start_date, end_date] = [moment(dateRange[0]), moment(dateRange[1])]
      let date_ranges = [start_date.format("YYYY-MM-DD"), end_date.format("YYYY-MM-DD")]
      let tObj = filtersCopy
      tObj["daterange"]["value"] = (start_date > end_date) ? date_ranges.reverse() : date_ranges
      shootFetcher(tObj, {})
      setPeriod({})
    }
  }

  const onSingleRangeChange = (key, value) => {
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, { lazy: true })
  }

  const onParentCheckboxChange = (key, event) => {
    let { value, checked } = event.target
    let tObj = filtersCopy
    tObj[key].data.map(parentObject => {
      if (parentObject.value === value) {
        parentObject["checked"] = checked
        if (parentObject.data) parentObject.data.map(childObject => {
          childObject['checked'] = checked
        })
      }
    })
    shootFetcher(tObj, {})
  }

  const onChildCheckboxChange = (parentKey, key, value, checked) => {
    let tObj = filtersCopy
    parentCbRef[parentKey].current.indeterminate = null
    tObj[key].data.map(parentObject => {
      let parentDisable = []
      if (parentObject.data) parentObject.data.map(childObject => {
        if (childObject.value === value) {
          childObject['checked'] = checked
        }
        parentDisable.push(childObject["checked"])
      })
      if (!isEmpty(parentDisable)) {
        let boolValues = new Set(parentDisable)
        if (boolValues.size === 1) {
          boolValues.has(true) ? parentObject["checked"] = true : parentObject["checked"] = false;
        } else {
          parentObject["checked"] = false
          parentCbRef[parentKey].current.indeterminate = true
        }
      }
    })
    shootFetcher(tObj, {})
  }

  const onNumberInputChange = (key, event) => {
    let value = event.target.value
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, { lazy: true })
  }

  const onRadioChange = (key, event) => {
    let value = event.target.value
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, {})
  }

  const onSelectChange = (key, event) => {
    let value = event.target.value
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, {})
  }

  const onDropdownSelectChange = (key, value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`${dropdownSearchURL}?${key}=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 1000)
    }
  }

  const onBooleanChange = (key, e) => {
    let tObj = filtersCopy;
    tObj[key]["value"] = e.target.checked
    shootFetcher(tObj, {})
  }

  const updateDropdownSelectedOptions = (key, value) => {
    setDropdownSelectedOptions(prevState => ({ ...prevState, [key]: value }))
    let tObj = filtersCopy
    tObj[key]["value"] = value
    shootFetcher(tObj, {})
  }

  const shootFetcher = (filterData, { page = currentPage, pagesize = pageSize, isPageHit = false, lazy = false }) => {
    if (!isPageHit) page = defaultCurrentPage
    setFiltersCopy(filterData)
    if (lazy) {
      clearTimeout(timeoutObject)
      timeoutObject = setTimeout(() => {
        let urlParams = frameURLParam(filterData, page, pagesize)
        props.apiTrigger(urlParams)
        updateURLParams(history, urlParams)
      }, 500)
    } else {
      let urlParams = frameURLParam(filterData, page, pagesize)
      props.apiTrigger(urlParams)
      updateURLParams(history, urlParams)
    }
    setCurrentPage(page)
    setPageSize(pagesize)
  }

  const onPageChange = (type, { page, sizePerPage }) => {
    shootFetcher(filtersCopy, { page: page, isPageHit: true })
  }

  const pageSizeChange = (value) => {
    shootFetcher(filtersCopy, { pagesize: value, isPageHit: true })
  }

  const filtersPlace = () => {
    if (urlParams) {
      let urlParamKeys = []
      for (const key of urlParams.keys()) {
        urlParamKeys.push(key)
      }
      {
        filtersCopy && Object.entries(filtersCopy).map(([k, v], idx) => {
          if (v && urlParamKeys.includes(k)) {
            <Chip key={idx} label={v.label} data={v} onClick={removeChip} />
          }
        })
      }
    }
  }

  const refreshPage = () => {
    props.apiTrigger(decodeURIComponent(location.search).replace("?", ""))

    if (removeQueryParams) {
      inputRef.current.value = '';
    }
  }

  const clearFilters = () => {
    setFiltersCopy(prevState => {
      let updatedState = { ...prevState };

      Object.keys(updatedState).forEach(key => {
        if (updatedState[key].type === 'checkbox') {
          updatedState[key].data = updatedState[key].data.map(item => ({
            ...item,
            checked: false,
          }));
        } else {
          updatedState[key].value = '';
        }
      });

      return updatedState;
    });

    props.apiTrigger();
    updateURLParams(history);
    setDefaultDate([]);
    dateRangeRef?.current?.flatpickr?.clear();
    inputRef.current.value = '';
    setPeriod({});
  };


  const removeChip = tObj => {
    shootFetcher(tObj, {})
    if (isEmpty(tObj.daterange.value)) dateRangeRef.current.flatpickr.clear()
  }

  const onPeriodChange = (period) => {
    let now = moment.utc();
    let start, end;

    if (period === 0) {
      start = now.clone().startOf('day').format('YYYY-MM-DD');
      end = now.clone().endOf('day').format('YYYY-MM-DD');
    } else {
      start = now.clone().subtract(period, 'days').format('YYYY-MM-DD');
      end = now.clone().format('YYYY-MM-DD');
    }

    onDaterangeChange([start, end]);
    dateRangeRef?.current?.flatpickr?.clear();
    setPeriod({
      value: period,
      start: start,
      end: end
    });
    setDefaultDate([start, end]);
  };


  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const date = urlSearchParams.get('date');
    const dateEnd = urlSearchParams.get('date_end');

    if (date && dateEnd) {
      setDefaultDate([date, dateEnd]);
      let days = moment(dateEnd).diff(moment(date), 'days');
      setPeriod((preVal) => ({
        ...preVal,
        value: days
      }))
    }

    const filters = JSON.parse(localStorage.getItem('filters'))?.[moduleName];
    setIsLocked(filters || filters === "");
  }, [])

  const toggleFilters = () => {
    setShowFilters(!showFilters);
    localStorage.setItem('showFilters', !showFilters);
  };

  const toggleLock = () => {
    const savedFilters = getLocalStorageItem('filters');

    if (moduleName) {
      setIsLocked(prevState => !prevState);

      if (!isLocked) {
        savedFilters[moduleName] = location.search.substring(1);
      } else {
        delete savedFilters[moduleName];
      }

      setLocalStorageItem('filters', savedFilters);
    }
  };


  return (
    <React.Fragment>
      <Row>
        {/* Side Filters */}
        {!isEmpty(filters) && showFilters &&
          <Col lg="3">
            <Card>
              <CardBody>
                <>
                  {filtersCopy && Object.entries(filtersCopy).map(([key, valObj], mainKeyIdx) =>
                    (valObj.type === "singlerange") ?
                      <div className="mt-3 pt-3" key={mainKeyIdx}>
                        <h5 className="font-size-14 mb-2">{valObj.label}</h5>
                        <input
                          type="range"
                          className="form-range"
                          min="0"
                          max={valObj.max}
                          step={valObj.step ? valObj.step : "0.1"}
                          value={valObj.value}
                          id={`id${key}`}
                          onChange={(e) => { onSingleRangeChange(key, e.target.value) }}
                        />
                        <div className="d-flex justify-content-between">
                          <p></p>
                          {valObj.proxy ?
                            <>
                              {valObj.proxy === "time" ?
                                <>
                                  <h5>{moment.utc(Math.ceil(valObj.value) * 1000).format('m:ss')} mins</h5>
                                  <p>{Math.ceil(valObj.max / 60)} mins</p>
                                </>
                                : null   // can add new ones here
                              }
                            </>
                            :
                            <>
                              <h5>{valObj.value}</h5>
                              <p>{valObj.max}</p>
                            </>
                          }
                        </div>
                      </div>
                      : valObj.type === "checkbox" ?
                        <div className="mt-3 pt-3" key={mainKeyIdx}>
                          <h5 className="font-size-14 mb-2">{valObj.label}</h5>
                          {valObj.data.map((parentCbObj, idx) =>
                            <div key={idx} className="mb-1">
                              <ul className="metismenu list-unstyled">
                                <li>
                                  <div className="has-arrow">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={`id${parentCbObj.label}`}
                                      value={parentCbObj.value}
                                      checked={parentCbObj.checked}
                                      onChange={(e) => onParentCheckboxChange(key, e)}
                                      ref={parentCbRef && parentCbRef[parentCbObj.value]}
                                    />
                                    <label className="form-check-label ms-2 text-capitalize" htmlFor={`id${parentCbObj.label}`}>
                                      {parentCbObj.label}
                                    </label>
                                  </div>
                                  {parentCbObj.data &&
                                    <ul className="sub-menu" aria-expanded="false">
                                      <li>
                                        {parentCbObj.data.map((childCbObj, cidx) =>
                                          <div key={cidx}>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`id${childCbObj.label}`}
                                              value={childCbObj.value}
                                              checked={childCbObj.checked}
                                              onChange={(e) => onChildCheckboxChange(parentCbObj.value, key, e.target.value, e.target.checked)}
                                            />
                                            <label className="form-check-label ms-2 text-capitalize" htmlFor={`id${childCbObj.label}`}>
                                              {childCbObj.label}
                                            </label>
                                            <br />
                                          </div>
                                        )}
                                      </li>
                                    </ul>
                                  }
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                        : valObj.type === "dropdownsearch" ?
                          <div className="mt-3 pt-3" key={mainKeyIdx}>
                            <h5 className="font-size-14 mb-2">{valObj.label}</h5>
                            <AsyncSelect
                              isMulti
                              value={dropdownSelectedOptions[key]}
                              loadOptions={(value, callback) => onDropdownSelectChange(valObj.apiKey, value, callback)}
                              onChange={(value) => updateDropdownSelectedOptions(key, value)}
                              classNamePrefix="select2-selection"
                              placeholder="Type keywords here..."
                              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                              // components={animatedComponents}
                              closeMenuOnSelect={false}
                              getOptionLabel={e => e}
                              getOptionValue={e => e}
                            />
                          </div>
                          : valObj.type === "radio" ?
                            <div className="mt-3 pt-3" key={mainKeyIdx}>
                              <h5 className="font-size-14 mb-2">{valObj.label}</h5>
                              {valObj.data.map((item, idx) =>
                                <div className="form-check mb-1" key={idx}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name={item.value}
                                    id={`id${item.value}`}
                                    value={item.value}
                                    checked={valObj.value === item.value ? true : false}
                                    onChange={(e) => onRadioChange(key, e)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`id${item.value}`}
                                  >
                                    {item.label}
                                  </label>
                                </div>
                              )}
                            </div>
                            : valObj.type === "dropdown" ?
                              <div className="mt-3 pt-3" key={mainKeyIdx}>
                                <h5 className="font-size-14 mb-2">{valObj.label}</h5>
                                <select className="form-control" onChange={(e) => onSelectChange(key, e)} defaultValue={valObj.value}>
                                  <option value="">---</option>
                                  {valObj.data.map((item, idx) =>
                                    <option key={idx} value={item.value}>{item.label}</option>
                                  )}
                                </select>
                              </div>
                              : valObj.type === "number" ?
                                <div className="mt-3 pt-3" key={mainKeyIdx}>
                                  <h5 className="font-size-14 mb-2">{valObj.label}</h5>
                                  <div className="mb-1">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name={key}
                                      id={`id${key}`}
                                      value={valObj.data}
                                      onChange={(e) => onNumberInputChange(key, e)}
                                    />
                                  </div>
                                </div>
                                : valObj.type === "boolean" ?
                                  <div className="mt-3 pt-3" key={mainKeyIdx}>
                                    <h5 className="font-size-14 mb-2">{valObj.label}</h5>
                                    <div className="mb-1">
                                      <ul className="metismenu list-unstyled">
                                        <li>
                                          <div className="has-arrow">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id={`id${key}`}
                                              value={valObj.value}
                                              checked={valObj.value}
                                              onChange={(e) => onBooleanChange(key, e)}
                                            // ref={booleanRef && booleanRef[valObj.value]}
                                            />
                                            <label className="form-check-label ms-2 text-capitalize" htmlFor={`id${key}`}>
                                              {valObj.label}
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  : null
                  )}
                </>
              </CardBody>
            </Card>
          </Col>
        }

        {/* Main Table */}
        <Col lg={isEmpty(filters) || !showFilters ? "12" : "9"}>
          <Card>
            <CardBody>
              {(search || daterange) &&
                <Row className="mb-2 d-flex justify-content-between">
                  {search && (
                    <Col md="4">
                      <div className="d-flex align-items-center">
                        {!isEmpty(filters) && (
                          <button
                            type="button"
                            className="btn btn-sm font-size-16 me-2"
                            onClick={toggleFilters}
                          >
                            <i className="bx bx-slider" />
                          </button>
                        )}
                        <div className="search-box d-inline-block mb-2">
                          <div className="position-relative">
                            <input
                              className="form-control"
                              type="search"
                              onInput={searchBar}
                              placeholder={search.placeholder || ""}
                              defaultValue={search.value || ""}
                              ref={inputRef}
                            />
                            <i className="bx bx-search-alt search-icon" style={tableSearchIconSizeFetch(localStorage.getItem("fontSize"))} />
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}

                  <Col>
                    <div className={`d-flex ${window.innerWidth <= 767 ? "justify-content-start flex-wrap" : "justify-content-end"}`}>
                      {props.createButton && <span className="mb-2">{props.createButton()}</span>}
                      <div className="text-center mb-2">
                        <ButtonGroup>
                          <Button color="secondary" className="btn btn-sm" outline onClick={refreshPage}>
                            <i className="mdi mdi-refresh" />
                          </Button>
                          <Button color="secondary" className="btn btn-sm" outline onClick={clearFilters}>
                            <i className="dripicons-cross" />
                          </Button>
                          {moduleName && (
                            <Button color={isLocked ? "success" : "secondary"} className="btn btn-sm" outline={!isLocked} onClick={toggleLock}>
                              {isLocked ? <i className="bx bx-lock-alt" /> : <i className="bx bx-lock-open-alt" />}
                            </Button>
                          )}
                        </ButtonGroup>
                      </div>

                      {daterange && (
                        <>
                          <div className={`${window.innerWidth <= 767 ? "mb-2" : "ms-2"}`}>
                            <InputGroup>
                              <Flatpickr
                                className="form-control form-control-sm"
                                placeholder={!isEmpty(defaultDate) ? defaultDate : "Select daterange range"}
                                options={{
                                  mode: "range",
                                  dateFormat: "Y-m-d",
                                  minDate: "2000-01",
                                  defaultDate: !isEmpty(daterange.value) ? defaultDate : []
                                }}
                                value={!isEmpty(defaultDate) ? defaultDate : []}
                                ref={dateRangeRef}
                                onClose={onDaterangeChange}
                              />
                            </InputGroup>
                          </div>

                          <div className={`${window.innerWidth <= 767 ? "mb-2" : "ms-2"}`}>
                            <Dropdown isOpen={periodDropdown} toggle={() => setPeriodDropdown(!periodDropdown)} direction="down">
                              <DropdownToggle caret color="primary" size="sm">
                                {period.value === 0 ? "Today"
                                  : period.value === 7 ? "Last Week"
                                    : period.value === 30 ? "Last Month"
                                      : period.value === 90 ? "Last 3 Months"
                                        : period.value === 180 ? "Last 6 Months"
                                          : "Period"}
                                &nbsp;<i className="fa fa-caret-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => onPeriodChange(0)} disabled={period.value === 1}>
                                  Today
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange(7)} disabled={period.value === 7}>
                                  Last Week
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange(30)} disabled={period.value === 30}>
                                  Last Month
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange(90)} disabled={period.value === 90}>
                                  Last 3 Months
                                </DropdownItem>
                                <DropdownItem onClick={() => onPeriodChange(180)} disabled={period.value === 180}>
                                  Last 6 Months
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              }
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField={keyField}
                    columns={columns}
                    data={!isEmpty(data?.results) ? data?.results : []}
                    bootstrap4
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                remote
                                responsive
                                bordered={false}
                                striped={false}
                                onTableChange={onPageChange}
                                classes={"table align-middle table-nowrap table-sm"}
                                noDataIndication={loadOrNoData(loading, noDataIndication)}
                                headerWrapperClasses={"thead-light text-capitalize"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                                onSizePerPageChange={pageSizeChange}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default GenericTable
