import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.js"
import { Card, CardBody, Table } from "reactstrap"
import moment from "moment";
import CallInfo from "./call-info";
import ScoreCard from "./scorecard";


const Waveform = props => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [waveformReady, setWaveformReady] = useState(false)
  const [playSpeedValue, setPlaySpeedValue] = useState(1)
  const [elapsedTime, setElapsedTime] = useState("00:00")
  const wavesurfer = useRef(null)
  const { loading, detailError, callInDepthDetail, activeTab } = props

  useEffect(() => {
    if (!isEmpty(callInDepthDetail.transcript)) {
      wavesurfer.current = WaveSurfer.create({
        container: '#wave-form',
        // waveColor: 'gray',
        progressColor: '#bababa',
        plugins: [
          RegionsPlugin.create({}),
        ]
      })

      wavesurfer.current.on('ready', function () {
        let chunks = callInDepthDetail.transcript
        var colors = ["hsla(360, 86%, 65%, 0.2)", "hsla(192, 97%, 69%, 0.2)", "hsla(49, 87%, 65%, 0.2)", "hsla(174, 87%, 65%, 0.2)", "hsla(287, 45%, 32%, 0.2)", "hsla(80, 8%, 15%, 0.2)"];
        for (var i = 0; i < chunks.length; i++) {
          // Each region
          let region_object = {
            start: parseInt(chunks[i].start_time),
            end: parseInt(chunks[i].end_time),
            drag: false,
            resize: false,
            // data: { note: chunks[i].speaker },
            data: "tablerow" + chunks[i].end_time,
            // attributes: { label: spkId, highlight: true }
          }
          if (chunks[i].htr==="ring") {
            region_object.color = "hsla(11, 2%, 2%, 0.2)"
          } else if (chunks[i].htr==="hold") {
            region_object.color = "hsla(210, 1%, 58%, 0.2)"
          } else {
            region_object.color = chunks[i].speaker==="Agent" ? "hsla(250, 100%, 47%, 0.30)" : "hsla(360, 100%, 47%, 0.15)"
          }
          wavesurfer.current.addRegion(region_object);
        }
      })
      
      wavesurfer.current.on('ready', () => {
        setWaveformReady(true)
      })

      wavesurfer.current.on('region-in', (region) => {
        scrollTo(region.data)
      })

      wavesurfer.current.on('region-out', (region) => {
        let row = document.getElementById(region.data)
        if (row) row.classList.remove('table-info')
      })

      wavesurfer.current.on('finish', function () {
        setIsPlaying(false)
      })

      wavesurfer.current.on('play', function () {
        setIsPlaying(true)
      })

      wavesurfer.current.on('pause', function () {
        setIsPlaying(false)
      })

      wavesurfer.current.on('audioprocess', function() {
        if (wavesurfer.current.isPlaying()) {
          setElapsedTime(moment.utc(parseInt(parseFloat(wavesurfer.current.getCurrentTime()))*1000).format('mm:ss'))
        }
      })

      wavesurfer.current.on('interaction', function () {
        setElapsedTime(moment.utc(parseInt(parseFloat(wavesurfer.current.getCurrentTime()))*1000).format('mm:ss'))
      })

      wavesurfer.current.load(`${callInDepthDetail.nfs_path}`)  // This URL must be global and from server

      return () => wavesurfer.current.destroy()
    }
  }, [callInDepthDetail]);

  const stop = () => {
    wavesurfer.current.stop();
    setIsPlaying(false)
  }

  const skipBackward = () => {
    wavesurfer.current.skipBackward();
  }

  const skipForward = () => {
    wavesurfer.current.skipForward();
  }

  const playSpeed = () => {
    let rate = playSpeedValue+0.5
    rate = rate>2 ? 0.5 : rate
    setPlaySpeedValue(rate)
    wavesurfer.current.setPlaybackRate(rate)
  }

  function scrollTo(position) {
    const row = document.getElementById(position)
    if (row) {
      row.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
      row.classList.add("table-info")
    }
  }

  const onPlayRegion = (start, end) => {
    wavesurfer.current.backend.play(start, end)
  }

  const getIntegerValue = (value) => {
    return parseInt(parseFloat(value))
  }

  const getTimedDuration = (start_time, end_time) => {
    return `${moment.utc(getIntegerValue(start_time)*1000).format('mm:ss')}-${moment.utc(getIntegerValue(end_time)*1000).format('mm:ss')}`
  }

  const getDuration = (start_time, end_time) => {
    return getIntegerValue(end_time)-getIntegerValue(start_time)
  }


  return (
    <React.Fragment>
      {detailError ?
        <Card>
          <CardBody>
            <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {detailError.detail}</p>
          </CardBody>
        </Card>
        :
        <div>
          {loading ?
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Loading...</p>
          :
          <>
            <Card className="text-center">
              <CardBody>
                <div id="wave-form"></div>
                {waveformReady ?
                  <div
                    className="btn-group mt-2"
                    role="group"
                    aria-label="button group"
                  >
                    <button type="button" className="btn btn-light" onClick={skipBackward}>
                      <i className="bx bx-skip-previous"></i>
                    </button>
                    <button type="button" className="btn btn-light" onClick={() => wavesurfer.current.playPause()}>
                      {isPlaying ? <i className="bx bx-pause"></i> : <i className="bx bx-play"></i> }
                    </button>
                    <button type="button" className="btn btn-light" onClick={stop}>
                      <i className="bx bx-stop"></i>
                    </button>
                    <button type="button" className="btn btn-light" onClick={skipForward}>
                      <i className="bx bx-skip-next"></i>
                    </button>
                    <button type="button" className="btn btn-light" onClick={playSpeed}>
                      {playSpeedValue}x
                    </button>
                    <button type="button" className="btn btn-light" disabled>
                      {elapsedTime}
                    </button>
                  </div>
                :
                <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Loading, Please wait...</p>
                }
              </CardBody>
            </Card>
            
          </>
          }
          <Card>
            <CardBody>
              {activeTab==="1" ?
              <>
                {callInDepthDetail.on_process!==null ?
                  <>
                    {!callInDepthDetail.on_process ?
                    <>
                      {callInDepthDetail.transcript && callInDepthDetail.transcript.length !== 0 ?
                        <div className="table-responsive" style={{ maxHeight: 300 }}>
                          <table className="table align-middle table-nowrap mb-0" style={{borderCollapse: "separate", borderSpacing: "0 5px"}}>
                            <tbody>
                              {callInDepthDetail.transcript.map((slice, idx) =>
                                <tr key={idx} id={`tablerow${slice.end_time}`} className="trans-row">
                                  {slice.speaker==="Agent" ?
                                    <td>
                                        <i className="bx bx-support font-size-16 align-middle me-2" style={{color: "blue"}}></i>
                                    </td>
                                  :
                                    <td>
                                      <i className="bx bxs-phone-call font-size-16 align-middle me-2" style={{color: "red"}}></i>
                                    </td>
                                  }
                                  <td onClick={() => onPlayRegion(getIntegerValue(slice.start_time), getIntegerValue(slice.end_time))}>
                                    <h5 className="font-size-13 text-truncate mb-1">
                                      <div className="text-dark">
                                        {slice.transcript}
                                      </div>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      <small><span className="text-muted">{slice.speaker}  |  {getTimedDuration(slice.start_time, slice.end_time)}  |  {getDuration(slice.start_time, slice.end_time)} secs</span></small>
                                    </p>
                                  </td>
                                  {/* <td>
                                    <i className="bx bx-like align-middle me-1"></i>{" "}
                                    {popularpost.like}
                                  </td>
                                  <td>
                                    <i className="bx bx-comment-dots align-middle me-1"></i>{" "}
                                    {popularpost.comment}
                                  </td>
                                  <td>
                                    <UncontrolledDropdown className="dropdown">
                                      <DropdownToggle
                                        className="text-muted font-size-16"
                                        color="white"
                                      >
                                        <i className="mdi mdi-dots-horizontal"></i>
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-end"
                                      >
                                        <Link className="dropdown-item" to="#">
                                          Action
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                          Another action
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                          Something else
                                        </Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="#">
                                          Separated link
                                        </Link>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td> */}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      :
                      <p className="text-center text-muted">This call doesn't have a transcript</p>
                      }
                    </>
                    :
                      <p className="text-center text-muted">This call is on processing. Results will be displayed soon</p>
                    }
                    
                  </>
                  :
                  <p className="text-center text-danger"><i className="bx bxs-chevron-up-circle search-icon" /> This call is in queue to process</p>
                }
              </>
              : activeTab==="2" ?
                <CallInfo loading={loading} detailError={detailError} callInDepthDetail={callInDepthDetail} />
              : activeTab==="3" ?
                <ScoreCard loading={loading} detailError={detailError} evaluationScores={callInDepthDetail} />
              : null
              }
            </CardBody>
          </Card>
        </div>
      }
    </React.Fragment>
  )
}

export default withRouter(Waveform)
