import React, { useEffect, useState } from "react"
import { Row, Col, Alert } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { getLocalstorage, setLocalstorage } from "components/Common/common"
import { registerVerification } from "store/actions"
import { Link } from "react-router-dom"
import { EAvFieldInput, EAvFieldSelect } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"


const InitiateRegister = props => {
  const dispatch = useDispatch()
  const [phonenumber, setPhonenumber] = useState(null)
  const [cache, setCache] = useState(null)

  const { loading, verificationData, formError, apiKey } = useSelector(state => ({
    loading: state.Account.loading,
    verificationData: state.Account.verificationData,
    formError: state.Account.formError,
    apiKey: state.Account.apiKey,
  }))

  useEffect(() => {
    let storedCache = getLocalstorage('registration-cache')?.value
    setCache(storedCache)
  }, [])

  useEffect(() => {
    if (apiKey == "capture") {
      props.handler('verification', verificationData)
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    values.phone_number = phonenumber
    setLocalstorage('registration-cache', values, 60 * 60 * 8)
    values.action = "capture"
    dispatch(registerVerification(values, "capture"))
  }

  return (
    <React.Fragment>
      {formError?.detail && <Alert color="danger">{formError?.detail}</Alert>}
      {formError?.otp && <Alert color="danger">{formError?.otp}</Alert>}
      <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
        <div className="mb-3">
          <EAvFieldInput
            field="full_name"
            type="text"
            placeholder="Enter fullname"
            value={cache?.full_name}
            isError={formError?.full_name}
            options={{ label: "Fullname", required: true, max_length: 30 }}
          />
        </div>
        <div className="mb-3">
          <EAvFieldInput
            field="email"
            type="email"
            placeholder="Enter email"
            value={cache?.email}
            isError={formError?.email}
            options={{ label: "Email", max_length: 50 }}
          />
        </div>
        <div className="mb-3">
          <PhonenumberInput
            label="Phonenumber"
            name="phone_number"
            value={cache?.phone_number}
            error={formError?.phone_number}
            required={true}
            updateValue={setPhonenumber}
          />
        </div>
        <Row className="mb-3">
          <Col>
            <EAvFieldInput
              field="dob"
              type="date"
              value={cache?.dob}
              isError={formError?.dob}
              options={{ label: "Date of Birth", required: true }}
              daterange={{ start: { value: -100, units: 'years' }, end: { value: -13, units: 'years' } }}
            />
          </Col>
          <Col>
            <EAvFieldSelect
              field="gender"
              value={cache?.gender ? cache?.gender : "MALE"}
              isError={formError?.gender}
              options={{ label: "Gender", required: true }}
              choices={<>
                <option value="">----------</option>
                <option value="MALE">Male</option>
                <option value="FEML">Female</option>
                <option value="NSAY">Not to say</option>
              </>}
            />
          </Col>
        </Row>
        <div className="mt-4">
          {loading ?
            <button className="btn btn-primary btn-block" disabled>
              Register{" "}
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            </button>
            :
            <button
              className="btn btn-primary btn-block "
              type="submit"
            >
              Register
            </button>
          }
        </div>

        <div className="mt-4 text-center">
          <p className="mb-0">
            By registering you agree to the Yanthura{" "}
            <Link to="#" className="text-primary">
              Terms of Use
            </Link>
          </p>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

export default InitiateRegister
