import React, { useEffect, useState } from "react"
import { Card, Col, Row, CardBody } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { resetHmsState } from "store/actions"
import { fontSizeVerboseFetch } from "components/Common/common"
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

const FontSize = (props) => {
  const dispatch = useDispatch()
  const [fontSize, setFontSize] = useState(fontSizeVerboseFetch(localStorage.getItem("fontSize")))

  const { error } = useSelector(state => ({
    error: state.Hms.error,
  }))

  const handleFontSizeChange = (newFontSize) => {
    localStorage.setItem("fontSize", newFontSize)
    document.documentElement.style.setProperty('--font-size', newFontSize ? `${newFontSize}rem` : null);
  };

  useEffect(() => {
    if (fontSize === 1) {
      handleFontSizeChange(null);
    }
    if (fontSize === 2) {
      handleFontSizeChange(1.10);
    }
    if (fontSize === 3) {
      handleFontSizeChange(1.15);
    }
    if (fontSize === 4) {
      handleFontSizeChange(1.25);
    }
  }, [fontSize]);

  useEffect(() => {
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  const onSingleRangeChange = (value) => {
    setFontSize(value)
  }

  const labels = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
  }

  return (
    <React.Fragment>
      <div className="font-size-1">
        <Row className="justify-content-center">
          <Col xl={6}>
            <Card>
              <CardBody>
                {error ? (
                  <p className="text-center text-danger">{error.detail}</p>
                ) : (
                  <div className="p-3">
                    <h5 className="font-size-14 mb-3 mt-0">
                      Font Size
                    </h5>
                    <Slider
                      value={fontSize}
                      min={1}
                      max={4}
                      labels={labels}
                      orientation="horizontal"
                      onChange={onSingleRangeChange}
                    />
                  </div>
                )
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default FontSize;