import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { deleteHms, getHmsOptions, listHms, resetHmsState } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatDateTime, genderVerboseFetch } from "components/Common/common"
import toastr from "toastr"
import { getLocalStorageItem, Loader, updateURLParams } from "pages/HMS/common/common"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"

const Patients = props => {
  const location = useLocation()
  const history = useHistory()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [patientObj, setPatientObj] = useState({})
  const [patientList, setPatientList] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)

  const { loading, error, hmsList, options, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    hmsList: state.Hms.hmsList,
    options: state.Hms.options,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/patients/?options=table, search, daterange, filters", "patient_options"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "patient_options") {
      if (options.filters) setFilter(options.filters)
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "username") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.uid
            }
          } else if (item.dataField === "full_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/patient/${row.id}/detail`}>
                  {row?.first_name} {row?.last_name}
                </Link>
              )
            }
          } else if (item.dataField === "phone_number") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.user_data?.phone_number
            }
          } else if (item.dataField === "gender") {
            return {
              ...item,
              formatter: (cellContent, row) => genderVerboseFetch(row?.user_data?.gender)
            }
          } else if (item.dataField === "address") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.address?.city || "---"
            }
          } else if (item.dataField === "age") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.user_data?.age || "---"
            }
          } else if (item.dataField === "emergency_contact_number") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.emergency_contact_number || "---"
            }
          } else if (item.dataField === "marital_status") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.marital_status ? "Married" : "Unmarried"
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => formatDateTime(row?.created_at)
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to={`/hms/patient/register/${row.id}`}>
                  Modify
                </Link>
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [apiKey])

  useEffect(() => {
    if (apiKey === "patient_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/patient/patients/", decodeURIComponent(urlParams).replace("?", ""), "patients_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.patients
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/patient/patients/", savedFilters, "patients_list"))
      }
    }
    if (apiKey === "patients_list") {
      setPatientList(hmsList)
    }
    if (apiKey === "patient_deleted") {
      setPatientObj(hmsList)
      toggleDelete()
      toastr.success(`Patient ${patientObj?.user_data?.full_name} is deleted`)
    }
  }, [apiKey])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/patient/patients/", urlParams, "patients_list"))
      setPatientList({})
    } else {
      dispatch(listHms("/hms/patient/patients/", null, "patients_list"))
      setPatientList({})
    }
  }

  const toggleDelete = (def = deleteModal) => {
    setDeleteModal(!def)
  }

  const handleDelete = (patient_object) => {
    setPatientObj(patient_object)
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Patients | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_patient_options" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="patients"
              urlParams={urlParams}
              filters={filters}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField={"id"}
              noDataIndication={"No patients created"}
              data={patientList}
              loading={apiKey === "list_patients_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
            />
          )}
        </Container>
      </div>

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If patient is removed. You cannot get it back</li>
            <li>All the inpatients, outpatients and any other data linked to this record will be deleted</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/patients/", patientObj?.id, "patient_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  )
}

export default Patients
