export const GET_PROFORMA_LIST = "GET_PROFORMA_LIST";
export const GET_PROFORMA_LIST_SUCCESS = "GET_PROFORMA_LIST_SUCCESS";
export const GET_PROFORMA_LIST_FAIL = "GET_PROFORMA_LIST_FAIL";

export const ADD_PROFORMA = "ADD_PROFORMA";
export const ADD_PROFORMA_SUCCESS = "ADD_PROFORMA_SUCCESS";
export const ADD_PROFORMA_FAIL = "ADD_PROFORMA_FAIL";

export const UPDATE_PROFORMA = "UPDATE_PROFORMA";
export const UPDATE_PROFORMA_SUCCESS = "UPDATE_PROFORMA_SUCCESS";
export const UPDATE_PROFORMA_FAIL = "UPDATE_PROFORMA_FAIL";

export const DELETE_PROFORMA = "DELETE_PROFORMA";
export const DELETE_PROFORMA_SUCCESS = "DELETE_PROFORMA_SUCCESS";
export const DELETE_PROFORMA_FAIL = "DELETE_PROFORMA_FAIL";

export const GET_PROFORMA_DETAIL = "GET_PROFORMA_DETAIL";
export const GET_PROFORMA_DETAIL_SUCCESS = "GET_PROFORMA_DETAIL_SUCCESS";
export const GET_PROFORMA_DETAIL_FAIL = "GET_PROFORMA_DETAIL_FAIL";

export const ADD_STATEMENT = "ADD_STATEMENT";
export const ADD_STATEMENT_SUCCESS = "ADD_STATEMENT_SUCCESS";
export const ADD_STATEMENT_FAIL = "ADD_STATEMENT_FAIL";

export const UPDATE_STATEMENT = "UPDATE_STATEMENT";
export const UPDATE_STATEMENT_SUCCESS = "UPDATE_STATEMENT_SUCCESS";
export const UPDATE_STATEMENT_FAIL = "UPDATE_STATEMENT_FAIL";

export const DELETE_STATEMENT = "DELETE_STATEMENT";
export const DELETE_STATEMENT_SUCCESS = "DELETE_STATEMENT_SUCCESS";
export const DELETE_STATEMENT_FAIL = "DELETE_STATEMENT_FAIL";
