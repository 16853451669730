import React from "react"
import { Redirect } from "react-router-dom"

// // Pages Component
import Chat from "../pages/Chat/Chat"

// // File Manager
import FileManager from "../pages/FileManager/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import MyStats from "../pages/Stats/my-stats"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

// //Tasks
import TasksList from "../pages/Tasks/tasks-list"
import TasksKanban from "../pages/Tasks/tasks-kanban"
import TasksCreate from "../pages/Tasks/tasks-create"

// //Projects
import ProjectsGrid from "../pages/Projects/projects-grid"
import ProjectsList from "../pages/Projects/projects-list"
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"
import ProjectsCreate from "../pages/Projects/projects-create"

// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailBasicTemplte from "../pages/Email/email-basic-templte"
import EmailAlertTemplte from "../pages/Email/email-template-alert"
import EmailTemplateBilling from "../pages/Email/email-template-billing"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/PasswordReset/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import CallDetail from "../pages/Dashboard/Call/index"
import DashboardSaas from "../pages/Dashboard/SingleBranch/index"
import TopLevelDashboard from "../pages/Dashboard/TopLevelDashboard/index"

//Crypto
import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet"
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell"
import CryptoExchange from "../pages/Crypto/crypto-exchange"
import CryptoLending from "../pages/Crypto/crypto-lending"
import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application"
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"
import ToastUIChart from "../pages/Charts/ToastUIChart"
import ChartsKnob from "../pages/Charts/charts-knob"
import ReCharts from "../pages/Charts/ReCharts"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons"
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"
import DragDropTables from '../pages/Tables/DragDropTables'

//Blog
import BlogList from "../pages/Blog/BlogList/index"
import BlogGrid from "../pages/Blog/BlogGrid/index"
import BlogDetails from "../pages/Blog/BlogDetails"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormLayouts from "../pages/Forms/FormLayouts"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"
import DualListbox from "../pages/Tables/DualListbox"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiSweetAlert from "../pages/Ui/UiSweetAlert"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiNotifications from "../pages/Ui/ui-notifications"
import UiDrawer from "pages/Ui/UiDrawer"
import UiBreadcrumb from '../pages/Ui/UiBreadcrumb';

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

// Teja added
import Manage from "pages/Manage/manage"
import Groups from "pages/Manage/groups"
import GroupDetail from "pages/Manage/group-detail"
import CallUpload from "pages/Process/uploader"
import settings from "pages/Settings/settings"
// import Services from "pages/Settings/services"
import { SwitchToMyDashboard } from "components/Common/DashboardSwitch"
import Proforma from "pages/Proforma/proforma"
import Users from "pages/ManageUsers/Users"
import UserDashboard from "pages/Dashboard/Agent/agent-dashboard"
import UserCreationLinkGeneration from "pages/ManageUsers/creation-form"
import ProformaList from "pages/Proforma/proforma-list"
import Calls from "pages/Calls/Calls"
import PendingRequests from "pages/ManageUsers/pending-requests"
import InActive from "pages/Authentication/in-active-ack"
import KPI from "pages/Settings/kpi-config"
import Configurations from "pages/Settings/configs"
import addUsers from "pages/ManageUsers/manual-signup"
import manualSignupActivation from "pages/ManageUsers/manual-signup-activation"
import Lms from "pages/LMS/lms"
import TemplateRules from "pages/LMS/template"
import Dashboard from "pages/LMS/dashboard/index"
import Services from "pages/services/index";
import DisplayMe from "pages/Authentication/common-message-display"
import HMS from "pages/HMS/patientmanagement"
import Appointments from "pages/HMS/patientmanagement/appointment/appointments"
import BookAppointment from "pages/HMS/patientmanagement/appointment/book-appointment"
import AddDoctor from "pages/HMS/staff/doctors/doctors-add"
import Doctors from "pages/HMS/staff/doctors/doctors"
import CreateOP from "pages/HMS/patientmanagement/op/create-op"
import CreatePatient from "pages/HMS/patientmanagement/patient/create-patient"
import Patients from "pages/HMS/patientmanagement/patient/patients"
import OutPatients from "pages/HMS/patientmanagement/op/ops"
import CreateIP from "pages/HMS/patientmanagement/inpatient/create-ip"
import InPatients from "pages/HMS/patientmanagement/inpatient/inpatients"
import Setup from "pages/HMS/settings/setup"
import InpatientDetail from "pages/HMS/patientmanagement/inpatient/detail/index"
import Insurance from "pages/HMS/patientmanagement/insurance"
import InsuranceDetail from "pages/HMS/patientmanagement/insurance/detail"
import Staff from "pages/HMS/staff/staff/staff"
import Supplier from "pages/HMS/pharmacy/supplier"
import Stock from "pages/HMS/pharmacy/stock"
import OrderMedicine from "pages/HMS/pharmacy/order-medicine"
import PlaceOrder from "pages/HMS/pharmacy/place-order"
import OrderDetails from "pages/HMS/pharmacy/order-details"
import MedicineDetail from "pages/HMS/pharmacy/medicine-details"
import MedicineStock from "pages/HMS/pharmacy/add-stock"
import MedicineSale from "pages/HMS/pharmacy/medicine-sale"
import Medicines from "pages/HMS/pharmacy/medicines"
import PatientDetail from "pages/HMS/patientmanagement/patient/patient-details"
import PDFPrinter from "pages/HMS/common/pdf-printer"
import MedicineSaleReports from "pages/HMS/pharmacy/medicine/medicine-sale-reports"
import MedicineSaleDetails from "pages/HMS/pharmacy/medicine/medicine-sale-details"
import InpatientDischarge from "pages/HMS/patientmanagement/inpatient/discharge-ip"
import OutpatientDetail from "pages/HMS/patientmanagement/op/op-details"
import DoctorDetail from "pages/HMS/staff/doctors/doctor-details"
import AppointmentsDetail from "pages/HMS/patientmanagement/appointment/appointment-details"
import DisplaySettings from "pages/HMS/settings/display-settings/index"
import Tests from "pages/HMS/diagnosis/tests"
import TestDetail from "pages/HMS/diagnosis/tests/detail"
import Transactions from "pages/HMS/billing/transactions"
import TransactionDetail from "pages/HMS/billing/transactions-details"
import BookTest from "pages/HMS/diagnosis/bt/book-test"
import { NotFound } from "components/Common/common"
import Treatment from "pages/HMS/patientmanagement/treatment/index"
import AddTreatment from "pages/HMS/patientmanagement/treatment/treatment"
import TreatmentDetail from "pages/HMS/patientmanagement/treatment/detail"
import TestCategories from "pages/HMS/diagnosis/tests/categories"
import TestGroup from "pages/HMS/diagnosis/test-group"
import TestGroupDetail from "pages/HMS/diagnosis/test-group/detail"
import BookTestReport from "pages/HMS/diagnosis/bt/reports"
import BookTestDetail from "pages/HMS/diagnosis/bt/detail"
import Reports from "pages/HMS/settings/reports/reports"
import PharmacyIndex from "pages/HMS/pharmacy/home"
import DiagnosisIndex from "pages/HMS/diagnosis/home"
import { getLocalStorageItem } from "pages/HMS/common/common"
import userVerification from "pages/Authentication/user-verification"


const userRoutes = [
  { path: "/dashboard/admin", component: TopLevelDashboard },
  { path: "/dashboard/admin/:branch", component: DashboardSaas },
  // Agent Dashboard
  { path: "/user/:userID/dashboard", component: UserDashboard },

  { path: "/users/manage", component: Manage },
  { path: "/groups/:branchID/:branchName", component: Groups },
  { path: "/group/:groupID/watch", component: GroupDetail },
  { path: "/process", component: CallUpload },
  { path: "/settings", component: settings },
  { path: "/services/:server", component: Services },
  // All users
  { path: "/users", component: Users },
  // Call detail page
  { path: "/call/:callID", component: CallDetail },
  // All calls
  { path: "/calls", component: Calls },
  // Proforma
  { path: "/proforma", component: ProformaList },
  { path: "/proforma/:action/:proforma/:pid/:title", component: Proforma },
  { path: "/kpi-config", component: KPI },
  { path: "/configurations", component: Configurations },
  // User creation link generation
  { path: "/users/link-gen", component: UserCreationLinkGeneration },
  { path: "/users/pending-requests", component: PendingRequests },
  { path: "/users/manual-signup", component: addUsers },



  // Lead Management
  { path: "/lms", component: Dashboard },
  { path: "/lms/leads", component: Lms },
  { path: "/lms/templates", component: TemplateRules },


  // Hospital Management
  { path: "/hms", component: HMS },
  { path: "/hms/patient/appointment/book", component: BookAppointment },
  { path: "/hms/patient/appointment/book/:appointmentID", component: BookAppointment },
  { path: "/hms/patient/appointment/:appointmentID/detail", component: AppointmentsDetail },
  { path: "/hms/patient/appointments", component: Appointments },
  { path: "/hms/patient/register", component: CreatePatient },
  { path: "/hms/patient/register/:patientID", component: CreatePatient },
  { path: "/hms/patients", component: Patients },
  { path: "/hms/patient/:patientID/detail", component: PatientDetail },
  { path: "/hms/patient/op/register", component: CreateOP },
  { path: "/hms/patient/op/register/:opID", component: CreateOP },
  { path: "/hms/patient/ops", component: OutPatients },
  { path: "/hms/patient/inpatient/register", component: CreateIP },
  { path: "/hms/patient/inpatient/register/:ipID", component: CreateIP },
  { path: "/hms/patient/inpatients", component: InPatients },
  { path: "/hms/patient/inpatient/:inpID/detail", component: InpatientDetail },
  { path: "/hms/patient/insurance", component: Insurance },
  { path: "/hms/patient/insurance/:insuranceID/detail", component: InsuranceDetail },
  { path: "/hms/staff/doctors/add", component: AddDoctor },
  { path: "/hms/staff/doctors/add/:doctorID", component: AddDoctor },
  { path: "/hms/staff/doctors", component: Doctors },
  { path: "/hms/staff/doctors/:doctorID/detail", component: DoctorDetail },
  { path: "/hms/patient/inpatient/discharge", component: InpatientDischarge },
  { path: "/hms/settings/administration", component: Setup },
  { path: "/hms/patient/op/:opID/detail", component: OutpatientDetail },
  { path: "/hms/staff/staff", component: Staff },
  { path: "/hms/pharmacy/medicines", component: Medicines },
  { path: "/hms/pharmacy/stock", component: Stock },
  { path: "/hms/pharmacy/medicine/medstock", component: MedicineStock },
  { path: "/hms/pharmacy/medicine/medstock/:stockID", component: MedicineStock },
  { path: "/hms/pharmacy/medicine/details/:medicineID", component: MedicineDetail },
  { path: "/hms/pharmacy/order-medicine", component: OrderMedicine },
  { path: "/hms/pharmacy/orders/details/:orderID", component: OrderDetails },
  { path: "/hms/pharmacy/place-order", component: PlaceOrder },
  { path: "/hms/pharmacy/supplier", component: Supplier },
  { path: "/hms/pharmacy/medicine-sale", component: MedicineSale },
  { path: "/hms/pharmacy/medicine-sale/:medID", component: MedicineSale },
  { path: "/hms/pharmacy/sold-out-medicines", component: MedicineSaleReports },
  { path: "/hms/pharmacy/sold-out-medicines/:tnxID", component: MedicineSaleDetails },
  { path: "/hms/settings/administration/:tab", component: Setup },
  { path: "/hms/pharmacy/printer/pdf", component: PDFPrinter },
  { path: "/hms/diagnosis/tests", component: Tests },
  { path: "/hms/diagnosis/tests/:testID/detail", component: TestDetail },
  { path: "/hms/diagnosis/test/book", component: BookTest },
  { path: "/hms/diagnosis/test/book/:testID", component: BookTest },
  { path: "/hms/billing/transactions", component: Transactions },
  { path: "/hms/billing/transactions/:tnxID/detail", component: TransactionDetail },
  { path: "/hms/patient/treatment", component: Treatment },
  { path: "/hms/patient/treatment/add", component: AddTreatment },
  { path: "/hms/patient/treatment/add/:treatmentID", component: AddTreatment },
  { path: "/hms/patient/treatment/:treatmentID/detail", component: TreatmentDetail },
  { path: "/hms/diagnosis/tests/categories", component: TestCategories },
  { path: "/hms/diagnosis/test/test-groups", component: TestGroup },
  { path: "/hms/diagnosis/test/test-groups/:testID/detail", component: TestGroupDetail },
  { path: "/hms/diagnosis/test/reports", component: BookTestReport },
  { path: "/hms/diagnosis/test/:testID/detail", component: BookTestDetail },
  { path: "/hms/settings/reports", component: Reports },

  //Pharmacy
  { path: "/hms/pharmacy/home", component: PharmacyIndex },

  //Diagnosis
  { path: "/hms/diagnosis/home", component: DiagnosisIndex },






  // Came with template
  // Crypto
  { path: "/crypto-wallet", component: CryptoWallet },
  { path: "/crypto-buy-sell", component: CryptoBuySell },
  { path: "/crypto-exchange", component: CryptoExchange },
  { path: "/crypto-lending", component: CryptoLending },
  { path: "/crypto-orders", component: CryptoOrders },
  { path: "/crypto-kyc-application", component: CryptoKYCApplication },

  //chat
  { path: "/chat", component: Chat },

  //File Manager
  { path: "/apps-filemanager", component: FileManager },

  // //calendar
  { path: "/calendar", component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },
  { path: "/my-stats", component: MyStats },
  { path: "/users/display-settings", component: DisplaySettings },

  //Ecommerce
  { path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },
  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-template-basic", component: EmailBasicTemplte },
  { path: "/email-template-alert", component: EmailAlertTemplte },
  { path: "/email-template-billing", component: EmailTemplateBilling },

  //Invoices
  { path: "/invoices-list", component: InvoicesList },
  { path: "/invoices-detail/:id?", component: InvoiceDetail },

  // Tasks
  { path: "/tasks-list", component: TasksList },
  { path: "/tasks-kanban", component: TasksKanban },
  { path: "/tasks-create", component: TasksCreate },

  //Projects
  { path: "/projects-grid", component: ProjectsGrid },
  { path: "/projects-list", component: ProjectsList },
  { path: "/projects-overview", component: ProjectsOverview },
  { path: "/projects-overview/:id", component: ProjectsOverview },
  { path: "/projects-create", component: ProjectsCreate },

  //Blog
  { path: "/blog-list", component: BlogList },
  { path: "/blog-grid", component: BlogGrid },
  { path: "/blog-details", component: BlogDetails },

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/tui-charts", component: ToastUIChart },
  { path: "/charts-knob", component: ChartsKnob },
  { path: "/re-charts", component: ReCharts },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },
  { path: "/tables-dragndrop", component: DragDropTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-layouts", component: FormLayouts },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },
  { path: "/dual-listbox", component: DualListbox },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-drawer", component: UiDrawer },
  { path: "/ui-breadcrumb", component: UiBreadcrumb },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },







  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to={getLocalStorageItem("pinPage")?.url || "/hms"} /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/user-inactive", component: InActive },
  { path: "/register/:token/activate", component: manualSignupActivation },
  { path: "/user-verification/:token", component: userVerification },

  // Pages without sidebar
  { path: "/services", component: Services },
  { path: "/unknownhappen", component: DisplayMe },
  { path: "/notfound", component: NotFound },






  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/crypto-ico-landing", component: CryptoIcoLanding },








  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
