import React, { useRef, useEffect } from "react"
import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { updateHms, postHms } from "store/actions"
import { EAvFieldInput, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields"

import toastr from "toastr"

export const ProcedureCRUDModal = (props) => {
  const { modal, toggle, rudFlag, options, customError, data, prepend = false } = props
  const dispatch = useDispatch()
  const formRef = useRef()

  const { loading, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === "procedure_created") {
      toggle()
      toastr.success("Procedure created successfully")
    }
    if (apiKey === "procedure_updated") {
      toggle()
      toastr.success("Procedure updated successfully")
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/diagnosis/procedures/", data?.id, values, "procedure_updated"))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/diagnosis/procedures/", values, "procedure_created", prepend))
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static" scrollable={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {rudFlag === 0 ? "Modify Procedure" : rudFlag === 1 ? "Add Procedure" : ""}
      </ModalHeader>
      <ModalBody>
        <AvForm
          onValidSubmit={handleValidSubmit}
          ref={formRef}
        >
          {options && (
            <>
              <Row>
                <Col>
                  <EAvFieldInput
                    field="name"
                    value={data?.name}
                    isError={customError?.name}
                    options={options?.name}
                  />
                </Col>
                <Col>
                  <EAvFieldInput
                    field="cost"
                    type="number"
                    value={data?.cost}
                    isError={customError?.cost}
                    options={options?.cost}
                  />
                </Col>
              </Row>
              <RequiredFieldsMessage />
              <Row>
                <Col>
                  <div className="text-end mb-2 mt-3">
                    {loading ?
                      <button disabled className="btn btn-primary btn-sm save-user">
                        SAVE{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </button>
                      :
                      <button type="submit" className="btn btn-primary btn-sm save-user">
                        SAVE
                      </button>
                    }
                  </div>
                </Col>
              </Row>
            </>
          )}
        </AvForm>
      </ModalBody>
    </Modal>
  )
}