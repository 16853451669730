export const RESET_STATE_SETTINGS = "RESET_STATE_SETTINGS"

export const GET_SETTINGS = "GET_SETTINGS"
export const CREATE_KEYS = "CREATE_KEYS"
export const ROTATE_KEYS = "ROTATE_KEYS"
export const BATCH_LIMIT_UPDATE = "BATCH_LIMIT_UPDATE"
export const API_FAIL = "API_FAIL"
export const API_SUCCESS = "API_SUCCESS"

export const POST_NFS = "POST_NFS"
export const POST_NFS_SUCCESS = "POST_NFS_SUCCESS"
export const POST_NFS_FAIL = "POST_NFS_FAIL"
export const UPDATE_NFS = "UPDATE_NFS"
export const UPDATE_NFS_SUCCESS = "UPDATE_NFS_SUCCESS"
export const UPDATE_NFS_FAIL = "UPDATE_NFS_FAIL"

export const GET_SERVERS = "GET_SERVERS"
export const SERVER_SUCCESS = "SERVER_SUCCESS"
export const SERVER_FAIL = "SERVER_FAIL"
export const POST_SERVER = "POST_SERVER"
export const POST_SERVER_SUCCESS = "POST_SERVER_SUCCESS"
export const POST_SERVER_FAIL = "POST_SERVER_FAIL"
export const UPDATE_SERVER = "UPDATE_SERVER"
export const UPDATE_SERVER_SUCCESS = "UPDATE_SERVER_SUCCESS"
export const UPDATE_SERVER_FAIL = "UPDATE_SERVER_FAIL"
export const DELETE_SERVER = "DELETE_SERVER"
export const DELETE_SERVER_SUCCESS = "DELETE_SERVER_SUCCESS"
export const DELETE_SERVER_FAIL = "DELETE_SERVER_FAIL"

export const GET_SERVICES = "GET_SERVICES"
export const SERVICE_SUCCESS = "SERVICE_SUCCESS"
export const SERVICE_FAIL = "SERVICE_FAIL"
export const POST_SERVICE = "POST_SERVICE"
export const POST_SERVICE_SUCCESS = "POST_SERVICE_SUCCESS"
export const POST_SERVICE_FAIL = "POST_SERVICE_FAIL"
export const UPDATE_SERVICE = "UPDATE_SERVICE"
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS"
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL"
export const DELETE_SERVICE = "DELETE_SERVICE"
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS"
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL"

export const CHECK_STORAGE = "CHECK_STORAGE"
export const CHECK_STORAGE_SUCCESS = "CHECK_STORAGE_SUCCESS"
export const CHECK_STORAGE_FAIL = "CHECK_STORAGE_FAIL"

