import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { getProfile as onGetProfile, myStats as onGetMyStats } from "../../store/actions"
import { isEmpty } from "lodash"
import ReactApexChart from "react-apexcharts"
import moment from "moment"

const MyStats = props => {
  const dispatch = useDispatch()

  const { loading, error, profile, stats } = useSelector(state => ({
    loading: state.MyStats.loading,
    error: state.MyStats.error,
    profile: state.Profile.profile,
    stats: state.MyStats.stats,
  }))

  const [userObject, setUserObject] = useState({})
  const [statsData, setStatsData] = useState({})

  useEffect(() => {
    if(!isEmpty(profile)) {
      setUserObject(profile)
    }
  }, [profile])

  useEffect(() => {
    if(!isEmpty(stats)) {
      setStatsData(stats)
    }
  }, [stats])

  useEffect(() => {
    dispatch(onGetProfile())
    dispatch(onGetMyStats())
  }, [])

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
          formatter: function(val, timestamp) {
            return moment(new Date(timestamp)).format("MMM DD, YYYY")
        }
      }
    },
    yaxis: {
      labels: {
          formatter: function(val) {
            return val ? val.toFixed(2) : val
        }
      }
    },
    markers: {
      size: 3,
      strokeWidth: 3,
      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>My Stats | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="User" breadcrumbItem="Stats" />
          {error && <Alert color="danger">{error.detail}</Alert>}
          <Row>
            <Col lg="3">
              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3 me-4">
                      {!userObject.avatar ?
                        <div className="avatar-md rounded-circle img-thumbnail">
                          <span className="avatar-title rounded-circle bg-success bg-soft text-success font-size-24">
                            {userObject.full_name ? userObject.full_name.charAt(0) : 'A'}
                          </span>
                        </div>
                      :
                        <img
                          src={userObject.avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      }
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>{userObject.full_name}</h5>
                        <p className="mb-1">{userObject.user_type==="ADMIN" ? "Admin" : userObject.user_type==="MANGR" ? "Manager" : userObject.user_type==="AGENT" ? "Agent" : null}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
            <Col lg="9">
              <Card>
                <CardBody>
              {loading ?
                <p className="text-center mt-5">Loading your stats. please wait{" "}<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
                :
                <div id="my-stats" dir="ltr">
                  {statsData.data && statsData.data.length>0 ?
                  <ReactApexChart
                    options={options}
                    series={[statsData]}
                    type="area"
                    height={350}
                    className="apex-charts"
                  />
                  :
                  <p className="text-center">No stats existed</p>
                  }
                </div>
              }
              </CardBody>
            </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(MyStats)
