import React, { useEffect, useRef, useState } from "react"
import { Container, Row, Col, Card, CardBody, InputGroup, Button } from "reactstrap"
import MetaTags from "react-meta-tags"

import Breadcrumbs from "components/Common/Breadcrumb"
import CardUser from "./card-user"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { getAgentData as onGetAgentData, myStats as onGetMyStats } from "store/actions"

import { Link } from "react-router-dom"
import moment from 'moment';
import Flatpickr from "react-flatpickr"
import ReactApexChart from "react-apexcharts"


const UserDashboard = props => {
  const dispatch = useDispatch()
  const [callData, setCallData] = useState([])
  const [userData, setUserData] = useState({})
  const [statsData, setStatsData] = useState({})
  const [period, setPeriod] = useState('months')
  const dateRangeRef = useRef()

  const { agentData, detailError, loading, stats } = useSelector(state => ({
    agentData: state.DashboardGenericState.agentData,
    detailError: state.DashboardGenericState.detailError,
    loading: state.DashboardGenericState.loading,
    stats: state.MyStats.stats,
  }))

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
          formatter: function(val, timestamp) {
            return moment(new Date(timestamp)).format("MMM DD, YYYY")
        }
      }
    },
    yaxis: {
      labels: {
          formatter: function(val) {
            return val ? val.toFixed(2) : val
        }
      }
    },
    markers: {
      size: 3,
      strokeWidth: 3,
      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  useEffect(() => {
    dispatch(onGetAgentData(props.match.params.userID))
    dispatch(onGetMyStats(props.match.params.userID))
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(agentData)) {
      setCallData(agentData.results)
      setUserData(agentData.user)
    }
  }, [agentData])

  useEffect(() => {
    if(!isEmpty(stats)) {
      setStatsData(stats)
    }
  }, [stats]);

  const columns = [{
    dataField: 'name',
    text: 'Name',
    formatter: (cellContent, row) => (
      <Link to={`/call/${row.uid}`}>
        <p>{row.name}</p>
      </Link>
    ),
  }, {
    dataField: 'uid',
    text: 'Call ID'
  }, {
    dataField: 'created_at',
    text: 'Date',
    formatter: (cellContent, row) => (
      <p>{moment(row.created_at).format("LL")}</p>
    )
  }, {
    dataField: 'duration',
    text: 'Duration',
    formatter: (cellContent, row) => (
      <p>{row.duration<60 ?
        moment.utc(row.duration*1000).format('s[s]') : 
        (row.duration>=60 || row.duration<3600) ? 
        moment.utc(row.duration*1000).format('m[m] ss[s]') : 
        moment.utc(row.duration*1000).format('h[h] m[m] ss[s]')}</p>
    )
  }, {
    dataField: 'score',
    text: 'Score',
    formatter: (cellContent, row) => (
      <p>{row.score ? parseInt(row.score) : "---"}</p>
    )
  }, {
    dataField: 'category',
    text: 'Category',
    formatter: (cellContent, row) => (
      <p className="text-capitalize">{row.category ? row.category : "---"}</p>
    )
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: callData.length,
    custom: true,
  }

  function getURLParam(urlParams) {
    let urlParam = []
    Object.entries(urlParams).map(([k, v]) => {
      if (k!=='page' || k!==undefined || k!==null || k!=='') {
        if (typeof(v)=='object') {
          urlParam.push(`${k}=${v[0]}&${k}_end=${v[1]}`)
        } else {
          urlParam.push(`${k}=${v}`)
        }
      }
    })
    return urlParam.join('&')
  }

  const onPeriodChange = (period, unit) => {
    if (unit==='days') {
      period = Number(period)-1
    }
    let now = moment.utc()
    let end = now.format('YYYY-MM-DD')
    let start = now.subtract(period, unit).format('YYYY-MM-DD')
    let queryParam = getURLParam({
      start: start,
      end: end
    })
    dispatch(onGetAgentData(props.match.params.userID, queryParam))
    dateRangeRef.current.flatpickr.clear()
    if (period===6 && unit==='days') unit = 'weeks'
    setPeriod(unit)
  }

  const onChangeDateRange = dateRange => {
    if (!isEmpty(dateRange)) {
      let queryParam = getURLParam({
        start: moment(dateRange[0]).format("YYYY-MM-DD"),
        end: moment(dateRange[1]).format("YYYY-MM-DD")
      })
      dispatch(onGetAgentData(props.match.params.userID, queryParam))
    }
    setPeriod(null)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Dashboard-Agent | Yanthura
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Agent" />
          {detailError ?
            <Card>
              <CardBody>
                <p className="text-center text-danger">{detailError.detail}</p>
              </CardBody>
            </Card>
            :
            <div>
              <div className="d-flex flex-row mb-2">
                <div className="ms-auto">
                  <InputGroup>
                    <Flatpickr
                      className="form-control form-control-sm"
                      placeholder="Select a date range"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d"
                      }}
                      ref={dateRangeRef}
                      onClose={onChangeDateRange}
                    />
                  </InputGroup>
                </div>
                <div className="ms-2">
                  <Button className="btn-sm" color={period==='days' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'days')}>Day</Button>
                  <Button className="btn-sm" color={period==='weeks' ? 'primary' : 'light'} onClick={() => onPeriodChange(7, 'days')}>Week</Button>
                  <Button className="btn-sm" color={period==='months' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'months')}>Month</Button>
                  <Button className="btn-sm" color={period==='years' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'years')}>Year</Button>
                </div>
              </div>
              {loading ?
                <p className="text-center mt-5">Fetching results. please wait{" "}<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
              :
              <>
                <CardUser data={agentData} />
                <ReactApexChart
                  options={options}
                  series={[statsData]}
                  type="area"
                  height={350}
                  className="apex-charts"
                />
              </>
              }
            </div>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserDashboard
