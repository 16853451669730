import { isEmpty } from "lodash"
import React from "react"
import { Card, CardBody, Col, Media, Row } from "reactstrap"
import CallProperties from "./call-properties"
import NER from "./ner"
import Transcript from "./transcript"
import UserComponent from "./user-component"

const CallInfo = props => {
  const { loading, detailError, callInDepthDetail } = props

  return (
    <React.Fragment>
      {loading ?
        <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Loading...</p>
        :
        <Row>
          <Col xl="4">
            <UserComponent data={callInDepthDetail.user} error={detailError} />
            <NER ner={callInDepthDetail.ner} />
            <CallProperties data={callInDepthDetail} error={detailError} />
          </Col>
          <Col xl="8">
            <Row>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted fw-medium">
                          Score
                        </p>
                        <h4 className="mb-0">{callInDepthDetail.score ? parseInt(callInDepthDetail.score) : 0}</h4>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted fw-medium">
                          Sentiment
                        </p>
                        <h4 className="mb-0">{(callInDepthDetail.sentiment && !isEmpty(callInDepthDetail.sentiment)) ? (callInDepthDetail.sentiment[0]['score']).toFixed(1) : 0}</h4>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted fw-medium">
                          Category
                        </p>
                        <h4 className="mb-0 text-capitalize">{callInDepthDetail.category ? callInDepthDetail.category : "NA"}</h4>
                      </Media>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {!isEmpty(callInDepthDetail.transcript) &&
              <Transcript transcriptObject={callInDepthDetail.transcript} nerObject={callInDepthDetail.ner} wordcloud={callInDepthDetail.wordcloud} />
            }
          </Col>
        </Row>
      }
    </React.Fragment>
  )
}

export default CallInfo