import {
  GET_SETTINGS,
  CREATE_KEYS,
  ROTATE_KEYS,
  BATCH_LIMIT_UPDATE,
  API_SUCCESS,
  API_FAIL,
  POST_NFS,
  POST_NFS_SUCCESS,
  POST_NFS_FAIL,
  UPDATE_NFS,
  GET_SERVERS,
  GET_SERVICES,
  SERVER_SUCCESS,
  SERVER_FAIL,
  SERVICE_FAIL,
  SERVICE_SUCCESS,
  POST_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  POST_SERVICE_SUCCESS,
  POST_SERVICE_FAIL,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  DELETE_SERVER_FAIL,
  DELETE_SERVER_SUCCESS,
  DELETE_SERVER,
  UPDATE_SERVER_FAIL,
  UPDATE_SERVER_SUCCESS,
  UPDATE_SERVER,
  POST_SERVER_FAIL,
  POST_SERVER_SUCCESS,
  POST_SERVER,
  UPDATE_NFS_SUCCESS,
  UPDATE_NFS_FAIL,
  CHECK_STORAGE,
  CHECK_STORAGE_SUCCESS,
  CHECK_STORAGE_FAIL,
  RESET_STATE_SETTINGS,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  settings: null,
  error: null,
  nfsData: {},
  nfsError: null,
  detailError: null,
  formError: null,
  servers: [],
  updatedServer: false,
  createdServer: false,
  services: [],
  updatedService: false,
  createdService: false,
  rotatedKeys: false,
  nfsCUStatus: null,
  storageCheck: null
}

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_SETTINGS:
      return INIT_STATE
    case GET_SETTINGS:
      return {
          ...state,
          error: null,
          loading: true
      }
    case CREATE_KEYS:
      return {
          ...state,
          error: null
      }
    case ROTATE_KEYS:
      return {
          ...state,
          error: null,
          rotatedKeys: false,
      }
    case BATCH_LIMIT_UPDATE:
      return {
          ...state,
          error: null,
      }
    case API_SUCCESS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
        rotatedKeys: true,
      }
    case API_FAIL:
      return {
        ...state,
        error: action.payload,
        settings: null,
        loading: false,
        rotatedKeys: false,
      }
    case POST_NFS:
      return {
        ...state,
        loading: true,
        nfsError: null
      }
    case POST_NFS_SUCCESS:
      return {
        ...state,
        nfsData: action.payload,
        loading: false
      }
    case POST_NFS_FAIL:
      return {
        ...state,
        nfsError: action.payload,
        loading: false
      }

    case UPDATE_NFS:
      return {
          ...state,
          loading: true,
          nfsError: null
      }
    case UPDATE_NFS_SUCCESS:
      return {
        ...state,
        nfsData: action.payload,
        loading: false
      }
    case UPDATE_NFS_FAIL:
      return {
        ...state,
        nfsError: action.payload,
        loading: false
      }
    
    case GET_SERVERS:
      return {
        ...state,
        error: null,
        formError: null,
        detailError: null,
        updatedServer: false,
        createdServer: false,
        loading: true
      }
    case SERVER_SUCCESS:
      return {
        ...state,
        error: null,
        formError: null,
        detailError: null,
        updatedServer: false,
        createdServer: false,
        servers: action.payload,
        loading: false
      }
    case SERVER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case POST_SERVER:
      return {
        ...state,
        formError: null,
        createdServer: true,
        loading: true
      }
    case POST_SERVER_SUCCESS:
      return {
        ...state,
        formError: null,
        createdServer: true,
        servers: [action.payload, ...state.servers],
        loading: false
      }
    case POST_SERVER_FAIL:
      return {
        ...state,
        createdServer: false,
        formError: action.payload,
        loading: false
      }
    case UPDATE_SERVER:
      return {
        ...state,
        updatedServer: false,
        formError: null,
        loading: true
      }
    case UPDATE_SERVER_SUCCESS:
      return {
        ...state,
        updatedServer: true,
        formError: null,
        servers: state.servers.map(server =>
          server.id.toString() === action.payload.id.toString() ? { server, ...action.payload } : server
        ),
        loading: false
      }
    case UPDATE_SERVER_FAIL:
      return {
        ...state,
        updatedServer: false,
        formError: action.payload,
        loading: false
      }
    case DELETE_SERVER:
      return {
        ...state,
        formError: null,
        detailError: null,
        loading: true
      }
    case DELETE_SERVER_SUCCESS:
      return {
        ...state,
        formError: null,
        detailError: null,
        servers: state.servers.filter(
          server => server.name.toString() !== action.payload.toString()
        ),
        loading: false
      }
    case DELETE_SERVER_FAIL:
      return {
        ...state,
        detailError: action.payload,
        loading: false
      }
    case GET_SERVICES:
      return {
        ...state,
        error: null,
        formError: null,
        detailError: null,
        updatedService: false,
        createdService: false,
        loading: true
      }
    case SERVICE_SUCCESS:
      return {
        ...state,
        error: null,
        services: action.payload,
        formError: null,
        detailError: null,
        updatedService: false,
        createdService: false,
        loading: false
      }
    case SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case POST_SERVICE:
      return {
        ...state,
        formError: null,
        createdService: true,
        loading: true
      }
    case POST_SERVICE_SUCCESS:
      return {
        ...state,
        formError: null,
        createdService: true,
        services: [action.payload, ...state.services],
        loading: false
      }
    case POST_SERVICE_FAIL:
      return {
        ...state,
        createdService: false,
        formError: action.payload,
        loading: false
      }
    case UPDATE_SERVICE:
      return {
        ...state,
        updatedService: false,
        formError: null,
        loading: true
      }
    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        updatedService: true,
        formError: null,
        services: state.services.map(service =>
          service.id.toString() === action.payload.id.toString() ? { service, ...action.payload } : service
        ),
        loading: false
      }
    case UPDATE_SERVICE_FAIL:
      return {
        ...state,
        updatedService: false,
        formError: action.payload,
        loading: false
      }
    case DELETE_SERVICE:
      return {
        ...state,
        formError: null,
        detailError: null,
        loading: true
      }
    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        formError: null,
        detailError: null,
        services: state.services.filter(
          service => service.id.toString() !== action.payload.toString()
        ),
        loading: false
      }
    case DELETE_SERVICE_FAIL:
      return {
        ...state,
        detailError: action.payload,
        loading: false
      }
    case CHECK_STORAGE:
      return {
        ...state,
        error: null,
        loading: true
      }
    case CHECK_STORAGE_SUCCESS:
      return {
        ...state,
        error: null,
        storageCheck: action.payload,
        loading: false
      }
    case CHECK_STORAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    
    default:
      return state
  }
}

export default Settings
