import { call, put, takeEvery } from "redux-saga/effects"

import { KPI_CREATE, KPI_DELETE, KPI_LIST, KPI_UPDATE } from "./actionTypes"

import { createKPIFail, createKPISuccess, deleteKPIFail, deleteKPISuccess, getKPIListFail, getKPIListSuccess, updateKPIFail, updateKPISuccess } from "./actions"

import { deleteKpiAPI, kpiListAPI, postKpiAPI, updateKpiAPI } from "./api_helper"

function* onGetKPIList() {
  try {
    const response = yield call(kpiListAPI)
    yield put(getKPIListSuccess(response))
  } catch (error) {
    yield put(getKPIListFail(error.response.data))
  }
}

function* onCreateKpi({payload: data}) {
  try {
    const response = yield call(postKpiAPI, data)
    yield put(createKPISuccess(response))
  } catch (error) {
    yield put(createKPIFail(error.response.data))
  }
}

function* onUpdateKpi({ payload: { kid, data } }) {
  try {
    const response = yield call(updateKpiAPI, {kid, data})
    yield put(updateKPISuccess(response))
  } catch (error) {
    yield put(updateKPIFail(error.response.data))
  }
}

function* onDeleteKpi(kid) {
  try {
    yield call(deleteKpiAPI, kid)
    yield put(deleteKPISuccess(serverName))
  } catch (error) {
    yield put(deleteKPIFail(error.response.data))
  }
}

function* configsSaga() {
  yield takeEvery(KPI_LIST, onGetKPIList)
  yield takeEvery(KPI_CREATE, onCreateKpi)
  yield takeEvery(KPI_UPDATE, onUpdateKpi)
  yield takeEvery(KPI_DELETE, onDeleteKpi)
}

export default configsSaga
