import React from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import error from "../../assets/images/error-img.png"
import { isEmpty } from "lodash"
import moment from "moment"
import { lazy } from "react"
import { Alert, Card, CardBody, Label, Container, Row, Col, Badge } from "reactstrap"

export const setLocalstorage = (key, value, ttl = 3600) => {
  const now = new Date()
  const item = {
    value: value,
    ttl: now.getTime() + parseInt(ttl) * 1000,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export const getLocalstorage = (key) => {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.ttl) {
    localStorage.removeItem(key)
    return null
  }
  return item
}

export const updateLocalstorage = (key, value = {}) => {
  let storage = localStorage.getItem(key)
  if (storage) {
    storage = JSON.parse(storage)
    let storageValue = storage.value
    storage.value = { ...storageValue, ...value }
    localStorage.setItem(key, JSON.stringify(storage))
  }
}


export const setTemporarySessionStorage = (key, value) => {
  // The data will be removed once it is accessed
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const getBaseURL = () => process.env.REACT_APP_DEBUG === "true" ? `${process.env.REACT_APP_DEV_URL}/api` : `${location.protocol}//${location.host}/api`

export const getAuthToken = () => {
  let userObject = localStorage.getItem("authUser")
  if (userObject) return `Bearer ${JSON.parse(userObject).access}`
}

export const getTemporarySessionStorage = (key) => {
  const itemStr = sessionStorage.getItem(key)
  sessionStorage.removeItem(key)
  if (!itemStr) {
    return null
  }
  return JSON.parse(itemStr)
}

export const urlParamsToObject = (urlParams) => {
  let urlParamsObject = {}
  decodeURIComponent(urlParams).split('&').map(urlParam => {
    let kv = urlParam.split('=')
    urlParamsObject[kv[0].replace("?", "")] = kv[1].includes(",") ? kv[1].split(",") : kv[1]
  })
  return urlParamsObject
}

export const frameURLParam = (filterData, currentPage, pageSize) => {
  let urlParam = []
  if (currentPage) urlParam.push(`page=${currentPage}`)
  if (pageSize) urlParam.push(`page_size=${pageSize}`)
  Object.entries(filterData).map(([k, v], idx) => {
    if (["text"].includes(v.type)) {
      if (v.value !== "") urlParam.push(`${v.apiKey}=${v.value}`)
    } else if (["singlerange"].includes(v.type)) {
      if (v.value && v.max && parseFloat(v.value) !== parseFloat(v.max)) urlParam.push(`${v.apiKey}=${v.value}`)
    } else if (["checkbox"].includes(v.type)) {
      let l1ObjParams = [], l2ObjParams = [];
      v.data.map((l1Obj, l1Idx) => {
        if (l1Obj.checked) l1ObjParams.push(l1Obj.value)
        if (l1Obj.data) {
          l1Obj.data.map((l2Obj, l2Idx) => {
            if (l2Obj.checked) l2ObjParams.push(l2Obj.value)
          })
        }
      })
      if (!isEmpty(l1ObjParams)) urlParam.push(`${v.apiKey}=${l1ObjParams.join(",")}`)
      if (!isEmpty(l2ObjParams)) urlParam.push(`${v.childApiKey}=${l2ObjParams.join(",")}`)
    } else if (["daterange"].includes(v.type)) {
      if (!isEmpty(v.value)) urlParam.push(`${v.apiKey[0]}=${v.value[0]}`, `${v.apiKey[1]}=${v.value[1]}`)
    } else if (["dropdownsearch"].includes(v.type)) {
      if (!isEmpty(v.value)) urlParam.push(`${v.apiKey}=${v.value.join(",")}`)
    } else if (["radio", "dropdown", "number", "boolean"].includes(v.type)) {
      if (v.value !== "" && v.value !== v.actual_value) urlParam.push(`${v.apiKey}=${v.value}`)
    }
  })
  return urlParam.join('&')
}

export const getURLParam = (filters, filterOptions) => {
  let urlParam = []
  Object.entries(filters).map(([k, v]) => {
    if ((k !== 'page' || k !== undefined || k !== null || k !== '') && (v !== undefined && v !== null && v !== '')) {
      let filterType = filterOptions[k]
      if (filterType) {
        if (filterType === "comma") {
          typeof (v[0]) === 'object'
            ? urlParam.push(`${k}=${v.map(item => item.value).join(',')}`)
            : urlParam.push(`${k}=${v.join(',')}`)
        } else if (filterType === "individual") {
          urlParam.push(`${k}=${v[0]}&${k}_end=${v[1]}`)
        }
      } else {
        urlParam.push(`${k}=${v}`)
      }
    }
  })
  return urlParam.join('&')
}


export const loadOrNoData = (loading, message = "No data") => {
  if (loading) {
    return <p className="text-center mt-5">Fetching results... <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
  } else {
    return <p className="text-center mt-2">{message}</p>
  }
}

export const updateObject = (oldObject, newObject) => {
  return { ...oldObject, ...newObject }
}


export const prependToArray = (array, object) => {
  array.unshift(object)
  return array
}
export const appendToArray = (array, object) => {
  array.push(object)
  return array
}
export const updateArrayObject = (array, object, key = "id") => {
  return array.map(item => {
    const itemKey = item[key] !== undefined && item[key] !== null ? item[key].toString() : undefined;
    const objectKey = object[key] !== undefined && object[key] !== null ? object[key].toString() : undefined;

    return itemKey !== undefined && objectKey !== undefined && itemKey === objectKey ? { ...item, ...object } : item;
  });
};

export const deleteArrayObject = (array, key, value) => {
  return array.filter(item => item[key].toString() !== value.toString())
}

export const prependToPaginationResult = (paginationObject, object) => {
  if (Array.isArray(paginationObject)) {
    return prependToArray(paginationObject, object)
  }
  let results = paginationObject.results
  if (results) {
    results.unshift(object)
    paginationObject['results'] = results
    paginationObject['count'] = results.length
    return paginationObject
  }
}
export const appendToPaginationResult = (paginationObject, object) => {
  if (Array.isArray(paginationObject)) {
    return appendToArray(paginationObject, object)
  }
  paginationObject['results'] = paginationObject.results.push(object)
  return paginationObject
}

export const updatePaginationResult = (paginationObject, object, key) => {
  if (Array.isArray(paginationObject)) {
    return updateArrayObject(paginationObject, object, key)
  }
  paginationObject['results'] = paginationObject?.results?.map(item =>
    item[key]?.toString() === object[key]?.toString() ? { ...item, ...object } : item
  )
  return paginationObject
}
export const deletePaginationResult = (paginationObject, key, value) => {
  if (Array.isArray(paginationObject)) {
    return deleteArrayObject(paginationObject, key, value)
  }
  paginationObject['results'] = paginationObject.results.filter(item =>
    item[key].toString() !== value.toString()
  )
  return paginationObject
}

export const getDatesFromPeriod = (period) => {
  let periodValue = period.value
  if (period.unit === "days") {
    periodValue = Number(periodValue) - 1
  }
  let now = moment.utc()
  let end = now.format('YYYY-MM-DD')
  let start = now.subtract(periodValue, period.unit).format('YYYY-MM-DD')
  return [start, end]
}

export const hybridErrorAlertDisplay = (error, keys = true) => {
  if (error.detail) {
    return <Alert color="danger">{error.detail}</Alert>
  } else {
    return (<Alert color="danger">
      <ul>
        {Object.entries(error).map(([k, v]) => (
          <li key={k}>{keys ? <b>{k}: </b> : null}{v[0]}</li>
        ))}
      </ul>
    </Alert>)
  }
}

export const hybridErrorCommonDisplay = (error) => {
  if (error.detail) {
    return (<Card>
      <CardBody>
        <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {error.detail}</p>
      </CardBody>
    </Card>)
  } else {
    return (<Card>
      <CardBody>
        <ul>
          {Object.entries(error).map(([k, v]) => (
            <li key={k}><b>{k}: </b>{v[0]}</li>
          ))}
        </ul>
      </CardBody>
    </Card>)
  }
}

export const MailTo = ({ className = "", subject = "", body = "", ...props }) => {
  return (
    <a className={className} href={`mailto:""?subject=${subject}&body=${body}`}>
      {props.children}
    </a>
  );
}

export const passwordStrength = (e) => {
  var password = document.querySelector("input[type=password]")
  // If patterns length increases, if else conditions also increases
  var value = e.target.value, strength = 0, patterns = [/\d/, /[A-Z]/, /[a-z]/, /[!-\/:-@[-`{-~]/, /^(.{8,30})/]
  if (value.trim() !== "") {
    patterns.forEach(element => {
      if (element.test(value)) strength += 1
    });
    let resultant_strength = (100 * strength) / patterns.length
    if (resultant_strength > 20 && resultant_strength <= 40) {
    } else if (resultant_strength > 40 && resultant_strength <= 60) {
      password.style.border = "1px solid #eb4034"
    } else if (resultant_strength > 60 && resultant_strength <= 80) {
      password.style.border = "1px solid #f7c80a"
    } else if (resultant_strength > 80 && resultant_strength <= 100) {
      password.style.border = "1px solid #2ba127"
    } else {
      password.style.removeProperty('border')
    }
  } else {
    password.style.removeProperty('border')
  }
}

export function lazyLoad(path, namedExport) {
  return lazy(() => {
    const promise = import(path)
    if (namedExport == null) {
      return promise
    } else {
      return promise.then(module => ({ default: module[namedExport] }))
    }
  })
}

export const cleanFilenameForSign = (filename) => {
  return filename.replace(/[^-_.a-z0-9]/gi, '')
}

export const genderVerboseFetch = (value) => {
  return value === "FEML" ? "Female" : value === "MALE" ? "Male" : value === "NSAY" ? "Other" : value === "F" ? "FEML" : value === "M" ? "MALE" : value === "O" ? "NSAY" : value
}

export const TableChip = ({ label, value, defaultIfNull = "NA", className = "w-100 me-1", infoTitle, textErrorColor = "text-muted" }) => {
  return (
    <div className={className}>
      {label && (
        <>
          <Label>{label}</Label>
          {infoTitle && <i className="bx bx-info-circle ms-1" title={infoTitle} />}
        </>
      )}
      <p className={value ? 'text-muted' : textErrorColor}>
        {value || defaultIfNull}
      </p>
    </div>
  );
};

export const TableBadge = ({ label, value, defaultIfNull = "NA", keyName = 'name', className = "w-100 me-1", infoTitle, textErrorColor = "text-muted" }) => {
  return (
    <div className={className}>
      {label && (
        <>
          <Label>{label}</Label>
          {infoTitle && <i className="bx bx-info-circle ms-1" title={infoTitle} />}
        </>
      )}
      <p className={value ? 'text-muted' : textErrorColor}>
        {!isEmpty(value) ? (value.map((item, index) => (
          <Badge key={index} color="secondary" className="me-1 ms-1 mb-2 p-1 cursor-pointer">
            {item[keyName]} {item.short_name ? `(${item.short_name})` : ''}
          </Badge>
        ))) : (
          defaultIfNull
        )}
      </p>
    </div>
  );
};

export const NotFound = () => {
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>Not Found | Yanthura</title>
        </MetaTags>
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />
                  4
                </h1>
                <h4 className="text-uppercase">The page you are looking for is not found. Please check the URL and try again</h4>
                <div className="mt-2 text-center">
                  <Link to="#">
                    {`${location.host}`}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export const formatDateTime = (dateTimeString = null, format = "lll", defaultIfNull = "---") => {
  // Convert the received date/time string into the formatted date and time
  let formattedDateTime = dateTimeString ? moment(dateTimeString).format(format) : defaultIfNull
  return formattedDateTime
}

export const occupationVerboseFetch = (value) => {
  return value === "JOB" ? "Job" : value === "BUSINESS" ? "Business" : value === "SELF_EMP" ? "Self Employed" : value === "NO_EMP" ? "Not Employed" : value === "HOUSEWIFE" ? "Housewife" : value
}

export const paymentVerboseFetch = (value) => {
  return value === "SELF" ? "Self" : value === "CASH" ? "Cash" : value === "CARD" ? "Card" : value === "UPI" ? "UPI" : value === "NETBNK" ? "Net Banking" : value === "CHEQUE" ? "Cheque" : value
}

export const bloodGroupVerboseFetch = (value) => {
  return value === "AP" ? "A+" : value === "AN" ? "A-" : value === "BP" ? "B+" : value === "BN" ? "B-" : value === "OP" ? "O+" : value === "ON" ? "O-" : value === "ABP" ? "AB+" : value === "ABN" ? "AB-" : value
}

export const visitModeVerboseName = (value) => {
  return value === "FOLLOW_UP" ? "Follow-up" : value === "APPOINTMENT" ? "Appointment" : value === "DIRECT" ? "Direct" : value
}

export const referenceTypeVerboseName = (value) => {
  return value === "DIRECT" ? "Direct" : value === "REFERRAL" ? "Referral" : value
}

export const fontSizeVerboseFetch = (value) => {
  return value === "null" ? 1 : value === "1.1" ? 2 : value === "1.15" ? 3 : value === "1.25" ? 4 : value
}

export const tableSearchIconSizeFetch = (value = localStorage.getItem("fontSize")) => {
  return value === "null" ? { fontSize: "16px" } : value === "1.1" ? { fontSize: "17px", marginTop: "1px" } : value === "1.15" ? { fontSize: "18px", marginTop: "2px" } : value === "1.25" ? { fontSize: "19px", marginTop: "3px" } : "16px"
}

export const searchBarIconSizeFetch = (value = localStorage.getItem("fontSize")) => {
  return value === "null" ? { fontSize: "18px", marginTop: "1px" } : value === "1.1" ? { fontSize: "18px", marginTop: "3px" } : value === "1.15" ? { fontSize: "19px", marginTop: "4px" } : value === "1.25" ? { fontSize: "20px", marginTop: "6px" } : "16px"
}