import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Input,
} from "reactstrap"

const Transcript = props => {
  const { transcriptObject, nerObject, wordcloud } = props
  const [transcript, setTranscript] = useState("")

  useEffect(() => {
    var transStr = (transcriptObject.map((chunk) => chunk.transcript)).join(' ')
    if (!isEmpty(nerObject)) {
      var mapObj = {}
      nerObject.map((ner) => {
        mapObj[ner.text] = ner.label
      })
      var re = new RegExp(Object.keys(mapObj).join("|"), "gi")
      transStr = transStr.replace(re, function (matched) { 
        // console.log(matched, mapObj[matched.toLowerCase()]);
        return `<span style="background-color: lightgray;
        padding-left: 4px;
        padding-right: 4px;
        border: 1px solid gray;
        border-radius: 3px;
        margin-right: 2px;">
          <span style="
            background-color: white;
            padding-left: 2px;
            padding-right: 2px;
            border-radius: 2px;">${matched}</span> 
        ${mapObj[matched.toLowerCase()]}
      </span>`
      })
    }
    setTranscript(transStr)
  })

  const textSearch = (e) => {
    var innerHTML = transcript;
    var index = innerHTML.indexOf(e.target.value);
    if (index >= 0) { 
      innerHTML = innerHTML.substring(0,index) + `<span style="background-color: yellow;">${innerHTML.substring(index,index+e.target.value.length)} </span>` + innerHTML.substring(index + e.target.value.length);
      document.getElementById("transcript-text").innerHTML = innerHTML
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="d-sm-flex flex-wrap">
            <h4 className="card-title mb-4">Transcript</h4>
            <div className="ms-auto">
              <div className="search-box">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Search transcript"
                    // onChange={textSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="transcript-text" style={{textAlign: 'justify', maxHeight: '400px', overflowY: 'auto'}} dangerouslySetInnerHTML={{__html: transcript}} />
        </CardBody>
      </Card>

      <Card>
        <CardBody className="text-center">
          {wordcloud ?
            <img src={`data:image/jpg;base64,${wordcloud}`} />
          :
            <p className="text-center text-muted">No map existed</p>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default Transcript
