import React, { useState } from "react"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

import {
  Col,
  Row,
} from "reactstrap"
import { loadOrNoData } from "./common"


const RemoteGenericTable = props => {
  const { loading, data, columns, tKey, noDataMessage = "No data existed", selectRow = undefined, showPagination = true } = props
  const [currentPage, setCurrentPage] = useState(1)

  const pageOptions = {
    page: currentPage,
    totalSize: data.count,
    custom: true,
    hidePageListOnlyOnePage: true,
    sizePerPage: 15,
  }

  const onPageChange = (type, { page, sizePerPage }) => {
    setCurrentPage(page)
    props.pageChangeHandler(page, sizePerPage)
  }

  return (
    <React.Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField={tKey}
        columns={columns}
        data={data.results ? data.results : []}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField={tKey}
            data={data.results ? data.results : []}
            columns={columns}
            bootstrap4
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        remote
                        keyField={tKey}
                        responsive
                        bordered={false}
                        striped={false}
                        onTableChange={onPageChange}
                        selectRow={selectRow}
                        noDataIndication={loadOrNoData(loading, noDataMessage)}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
                {showPagination &&
                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </Col>
                  </Row>
                }
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  )
}

export default RemoteGenericTable;



