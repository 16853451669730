import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledAlert,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getBranchGroups as onGetBranchGroups,
  addGroup as onAddGroup,
  updateGroup as onUpdateGroup,
  deleteGroup as onDeleteGroup,
} from "store/actions"

import toastr from "toastr"
import AsyncSelect from "react-select/async";
import { get } from "helpers/api_helper"
import { loadOrNoData, MailTo } from "components/Common/common"
import RemoteGenericTable from "components/Common/tables"

const Groups = props => {
  const dispatch = useDispatch()
  const formRef = useRef()

  const { loading, groups, error, formError, detailError, updatedGroup, createdGroup, removedGroup, rudStatus } = useSelector(state => ({
    loading: state.Manage.loading,
    groups: state.Manage.groups,
    error: state.Manage.error,
    formError: state.Manage.formError,
    detailError: state.Manage.detailError,
    updatedGroup: state.Manage.updatedGroup,
    createdGroup: state.Manage.createdGroup,
    removedGroup: state.Manage.removedGroup,
    rudStatus: state.Manage.rudStatus,
  }))

  const [modal, setModal] = useState(false)
  const [groupObject, setGroupObject] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteEnabled, setDeleteEnabled] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const GroupColumns = [{
    dataField: "name",
    text: "Group",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to={`/group/${row.uid}/watch`} className="text-primary">
        {row.name}
      </Link>
    ),
  }, {
    dataField: "owner",
    text: "Lead",
    formatter: (cellContent, row) => (
      <Link to={`/user/${row.owner.username}/dashboard`}>{row.owner.full_name}</Link>
    )
  }, {
    dataField: "uid",
    text: "Key",
    sort: false,
  }, {
    dataField: "users",
    text: "Users count",
    sort: true,
  }, {
    dataField: "action",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => (
      <UncontrolledDropdown className="ms-auto">
        <DropdownToggle
          className="text-muted font-size-16"
          color="white"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link className="dropdown-item" to="#" onClick={() => handleEditGroup(row)}>
            Edit
          </Link>
          <Link className="dropdown-item" to="#" onClick={() => copyToClipboard(`${window.location.origin}/register?group=${row.uid}`)}>
            Copy
          </Link>
          <MailTo className="dropdown-item" subject="Yanthura | Signup Link" body={encodeURIComponent(`Hey, \nUse the below link to register in yanthura \n${window.location.origin}/register?group=${row.uid} \nThanks`)}>
            Share via mail
          </MailTo>
          <Link className="dropdown-item" to="#" onClick={() => handleDeleteGroup(row)}>
            Remove
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }
  ]
  
  useEffect(() => {
    if (createdGroup) {
      setModal(!createdGroup)
      toastr.success("Group created successfully")
      formRef.current.reset()
    }
    if (updatedGroup) {
      setModal(!updatedGroup)
      toastr.success("Group updated successfully")
    }
    if (removedGroup) {
      setModal(!removedGroup)
    }
    if (!isEmpty(groups.results)) {
      setRUDFlag(null)
    }
  }, [groups, groups.results, createdGroup, updatedGroup])

  useEffect(() => {
    if (rudStatus && deleteEnabled) {
      toggleDelete()
      toastr.success(`Group ${groupObject.name} deleted`)
    }
  }, [rudStatus])

  useEffect(() => {
    dispatch(onGetBranchGroups({ branchID: props.match.params.branchID }))
  }, [dispatch])

  const fetchNewPage = (page, sizePerPage) => {
    dispatch(onGetBranchGroups({ branchID: props.match.params.branchID, page: `page=${page}` }))
  }

  // useEffect(() => {
    
  // }, [createdGroup])

  // useEffect(() => {
  // }, [groups])
  
  const handleEditGroup = arg => {
    const group = arg
    setGroupObject({
      uid: group.uid,
      name: group.name,
      owner: group.owner
    })
    setRUDFlag(0)
    setSelectedUser(group.owner)
    toggle(false)
  }

  const handleDeleteGroup = arg => {
    setGroupObject(arg)
    toggleDelete(false)
  }

  const handleValidGroupSubmit = (e, values) => {
    let groupUid = values.uid
    values.owner = Array.isArray(selectedUser) ? selectedUser[0].username : selectedUser.username
    if (rudFlag === 0) {
      dispatch(onUpdateGroup(groupUid, values))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(onAddGroup(props.match.params.branchID, values))
    }
  }

  const handleCreateGroup = () => {
    setGroupObject({})
    setRUDFlag(1)
    setSelectedUser(null)
    toggle(false)
  }

  function toggle(def = modal) {
    setModal(!def)
  }

  function toggleDelete(state=true) {
    setDeleteModal(!state)
  }

  const copyToClipboard = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    toastr.success(text, "Group link copied")
  }

  const updateUsers = value => {
    setSelectedUser(value)
  }

  var suTo = null
  const searchUsers = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 2) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        // dispatch(onGetUnassignedGroupMembers(`query=${value}`))
        get(`/options/top-lvl-users?query=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 1000)
    }
  }

  const colorStyles = {
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: "black"
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Groups | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={`Branch`} breadcrumbItem={props.match.params.branchName} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2 me-2 btn-sm"
                          onClick={handleCreateGroup}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Create a group
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <RemoteGenericTable loading={loading} data={groups} columns={GroupColumns} tKey={'uid'} noDataMessage={"No groups created"} pageChangeHandler={fetchNewPage} />
                  <Modal isOpen={deleteModal} toggle={toggleDelete} backdrop="static">
                    <ModalHeader toggle={toggleDelete} tag="h4">
                      ⚠️ Warning: By deleting group {groupObject.name}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col className="col-12">
                          {detailError &&
                            <FlashAlert color="danger" delay="10000" className="text-center">{detailError.detail}</FlashAlert>
                          }
                          <ul>
                            <li>Group will be removed permanently</li>
                            <li>Respective users will be released from the group</li>
                          </ul>
                          <p>Type <i><b>delete permanently</b></i> to proceed delete operation</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <input
                              name="confirmation-text"
                              type="text"
                              className="form-control"
                              required={false}
                              onChange={(e) => {
                                e.target.value === "delete permanently" ? setDeleteEnabled(true) : setDeleteEnabled(false)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="float-end">
                        <Col>
                          <button type="button" className="btn btn-outline-danger btn-sm me-2" disabled={!deleteEnabled}
                            onClick={() => {
                              dispatch(onDeleteGroup(groupObject.uid))
                            }}>
                            Yes
                          </button>
                          <button type="button" className="btn btn-light btn-sm"
                            onClick={toggleDelete}>
                            No
                          </button>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal} toggle={toggle} backdrop="static">
                    <ModalHeader toggle={toggle} tag="h4">
                      {rudFlag === 0 ? "Edit Group" : rudFlag === 1 ? "Add Group" : rudFlag === 2 ? "Delete Group" : ""}
                    </ModalHeader>
                    <ModalBody>
                      {formError ?
                        <UncontrolledAlert color="danger" role="alert" className="ms-1 me-1">
                          {Object.entries(formError).map(([key, value]) =>
                            <p key={key}><b>{key}:</b> {value[0]}</p>
                          )}
                        </UncontrolledAlert>
                        : null}
                      <div>
                        {rudFlag === 2 ?
                          <div>
                            <h6 className="mb-4">Are you sure, you want to delete {groupObject.name} group ?</h6>
                            <div className="float-end">
                              {loading ?
                                <button disabled className="btn btn-outline-danger btn-sm me-2">
                                  Yes{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </button>
                                :
                                <button type="button" className="btn btn-outline-danger btn-sm me-2"
                                  onClick={() => {
                                    dispatch(onDeleteGroup(groupObject.uid))
                                    toggle()
                                    toastr.warning(`Group ${groupObject.name} deleted`)
                                  }}>
                                  Yes
                                </button>
                              }
                              <button
                                type="button"
                                className="btn btn-light btn-sm"
                                onClick={() => toggle()}>
                                No
                              </button>
                            </div>
                          </div>
                          :
                          <AvForm
                            onValidSubmit={handleValidGroupSubmit}
                            ref={formRef}
                          >
                            <Row>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="name"
                                    label="Group name"
                                    type="text"
                                    errorMessage="Enter a valid group name"
                                    value={groupObject.name || ""}
                                    required
                                  />
                                  <AvField name="uid" type="hidden" value={groupObject.uid || ""} />
                                </div>
                              </Col>
                              <Col className="col-12">
                                <div className="mt-4 mb-3 ajax-select select2-container">
                                  <AsyncSelect
                                    value={selectedUser}
                                    loadOptions={searchUsers}
                                    onChange={updateUsers}
                                    placeholder="Type to search users..."
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    // components={animatedComponents}
                                    getOptionLabel={e => `${e.full_name}-${e.email}`}
                                    getOptionValue={e => e.username}
                                    styles={colorStyles}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  {loading ?
                                    <button disabled className="btn btn-primary btn-sm save-user">
                                      SAVE{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </button>
                                    :
                                    <button type="submit" className="btn btn-primary btn-sm save-user">
                                      SAVE
                                    </button>
                                  }
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        }
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Groups.propTypes = {
  onGetBranchGroups: PropTypes.func,
  onAddGroup: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  onUpdateGroup: PropTypes.func,
}

export default withRouter(Groups)
