import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER_VERIFICATION,
  REGISTER_VERIFICATION_SUCCESSFUL,
  REGISTER_VERIFICATION_FAILED,
  ADD_USER,
  ADD_USER_SUCCESSFUL,
  ADD_USER_FAILED,
  MANUALSIGNUP_ACTIVATE,
  MANUALSIGNUP_ACTIVATE_SUCCESSFUL,
  MANUALSIGNUP_ACTIVATE_FAILED,
  MANUALSIGNUP_VERIFY,
  MANUALSIGNUP_VERIFY_SUCCESSFUL,
  MANUALSIGNUP_VERIFY_FAILED,
  RESET_STATE_ACCOUNT,
} from "./actionTypes"

const INIT_STATE = {
  verificationData: null,
  formError: null,
  registrationError: null,
  message: null,
  loading: false,
  linkValidated: false,
  user: null,
  data: null,
  error: null,
  apiKey: null,
}

const account = (state=INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_ACCOUNT:
      return INIT_STATE
    case REGISTER_USER:
      state = {
        ...state,
        loading: true,
        formError: null,
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        formError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        formError: action.payload
      }
      break
    
    case ADD_USER:
      state = {
        ...state,
        loading: true,
        formError: null,
      }
      break
    case ADD_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        formError: null,
      }
      break
    case ADD_USER_FAILED:
      state = {
        ...state,
        loading: false,
        user: null,
        formError: action.payload,
      }
      break

    case MANUALSIGNUP_VERIFY:
      state = {
        ...state,
        loading: true,
        linkValidated: false,
        error: null,
      }
      break
    case MANUALSIGNUP_VERIFY_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        linkValidated: true,
        error: null,
      }
      break
    case MANUALSIGNUP_VERIFY_FAILED:
      state = {
        ...state,
        loading: false,
        linkValidated: false,
        error: action.payload,
      }
      break
  
    case MANUALSIGNUP_ACTIVATE:
      state = {
        ...state,
        loading: true,
        error: null,
        formError: null,
      }
      break
    case MANUALSIGNUP_ACTIVATE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
        linkValidated: false
      }
      break
    case MANUALSIGNUP_ACTIVATE_FAILED:
      state = {
        ...state,
        loading: false,
        data: null,
        formError: action.payload,
      }
      break

    case REGISTER_VERIFICATION:
      state = {
        ...state,
        loading: true,
        verificationData: null,
        formError: null,
        apiKey: `post_${action.key}`,
      }
      break
    case REGISTER_VERIFICATION_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        verificationData: action.payload,
        formError: null,
        apiKey: action.key,
      }
      break
    case REGISTER_VERIFICATION_FAILED:
      state = {
        ...state,
        loading: false,
        verificationData: null,
        formError: action.payload,
        apiKey: `fail_${action.key}`,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
