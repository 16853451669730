import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { deleteHms, getHmsOptions, listHms, reportDownloadHms, resetHmsState } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatDateTime, genderVerboseFetch, paymentVerboseFetch, visitModeVerboseName } from "components/Common/common"
import { DownloadModal, HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import { getLocalStorageItem, HMSLink, Loader, updateURLParams } from "pages/HMS/common/common"
import toastr from "toastr"

const OutPatients = props => {
  const location = useLocation()
  const history = useHistory()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [modals, setModals] = useState({ downloadModal: false, InvoiceModal: false, deleteModal: false })
  const [outpatientList, setOutpatientList] = useState({})
  const [outpatientObj, setOutpatientObj] = useState({})

  const { loading, error, hmsList, options, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    hmsList: state.Hms.hmsList,
    options: state.Hms.options,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/outpatients/?options=table, search, daterange, filters", "outpatients_options"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "download_op_report") setModals(prevModals => ({ ...prevModals, downloadModal: true }))
    if (apiKey === "invoice_receipt") setModals(prevModals => ({ ...prevModals, InvoiceModal: true }))
    if (apiKey === "outpatients_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/patient/outpatients/", decodeURIComponent(urlParams).replace("?", ""), "outpatients_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.outpatients
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/patient/outpatients/", savedFilters, "outpatients_list"))
      }
    }
    if (apiKey === "outpatients_list") {
      setOutpatientList(hmsList)
    }
    if (apiKey === "outpatient_deleted") {
      setOutpatientObj(hmsList)
      toggleDelete()
      toastr.success(`Outpatient ${outpatientObj?.patient_data?.user_data?.full_name} is deleted`)
    }
  }, [apiKey])

  useEffect(() => {
    if (apiKey === "outpatients_options") {
      if (options.filters) setFilter(options.filters)
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "full_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link
                  to={`/hms/patient/op/${row.id}/detail`}
                  title={`Marital Status: ${row.patient_data.marital_status ? "Married" : "Unmarried"} \nCreated By: ${row.created_by}`}
                >
                  {row.patient_data.first_name} {row.patient_data.last_name}
                </Link>
              )
            }
          } else if (item.dataField === "username") {
            return {
              ...item,
              formatter: (cellContent, row) => row.patient_data.user_data.username
            }
          } else if (item.dataField === "doctor") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link
                  to={`/hms/staff/doctors/${row.doctor_data.id}/detail`}
                  title={`Doctor ID: ${row.doctor_data.user_data.username}\nEmail: ${row.doctor_data.user_data.email}\nGender: ${genderVerboseFetch(row.doctor_data.user_data.gender)}`}
                >
                  {row.doctor_data.user_data.full_name}
                </Link>
              )
            }
          } else if (item.dataField === "phone_number") {
            return {
              ...item,
              formatter: (cellContent, row) => row.patient_data.user_data.phone_number
            }
          } else if (item.dataField === "gender") {
            return {
              ...item,
              formatter: (cellContent, row) => genderVerboseFetch(row.patient_data.user_data.gender)
            }
          } else if (item.dataField === "age") {
            return {
              ...item,
              formatter: (cellContent, row) => row.patient_data.user_data.age || "---"
            }
          } else if (item.dataField === "grand_total_amount") {
            return {
              ...item,
              formatter: (cellContent, row) => row.grand_total_amount == 0 ? "0" : row.grand_total_amount || "---"
            }
          } else if (item.dataField === "payment_mode") {
            return {
              ...item,
              formatter: (cellContent, row) => paymentVerboseFetch(row.payment_mode) || "---"
            }
          } else if (item.dataField === "mode_of_visit") {
            return {
              ...item,
              formatter: (cellContent, row) => visitModeVerboseName(row.mode_of_visit) || "---"
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => formatDateTime(row.created_at) || "---"
            }
          } else if (item.dataField === "uid") {
            return {
              ...item,
              formatter: (cellContent, row) => row.patient_data.uid || "---"
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to={`/hms/patient/op/register/${row.id}`}>
                  Modify
                </Link>
                <HMSLink onClick={() => downloadReport(row?.id)} apiStartKey="download_op_report">
                  Download Report
                </HMSLink>
                <HMSLink
                  disabled={row?.mode_of_visit === "FOLLOW_UP"}
                  onClick={() => downloadInvoice(row?.id)} apiStartKey="invoice_receipt"
                >
                  Print Invoice
                </HMSLink>
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [apiKey])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/patient/outpatients/", urlParams, "outpatients_list"))
      setOutpatientList({})
    } else {
      dispatch(listHms("/hms/patient/outpatients/", null, "outpatients_list"))
      setOutpatientList({})
    }
  }

  const downloadReport = (id) => {
    if (id) {
      dispatch(reportDownloadHms(`/hms/patient/outpatients/${id}/report_prescription/`, "download_op_report"))
    }
  }

  const downloadInvoice = (id) => {
    if (id) {
      dispatch(reportDownloadHms(`/hms/patient/outpatients/${id}/invoice_receipt/`, "invoice_receipt"))
    }
  }

  const toggleDelete = (def = modals.deleteModal) => {
    setModals(prevModals => ({ ...prevModals, deleteModal: !def }))
  }

  const handleDelete = (outpatient_object) => {
    setOutpatientObj(outpatient_object)
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Outpatients | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_outpatients_options" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="outpatients"
              urlParams={urlParams}
              filters={filters}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField="id"
              noDataIndication="No records existed"
              data={outpatientList}
              loading={apiKey === "list_outpatients_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
            />
          )}
        </Container>
      </div>

      <DownloadModal
        modal={modals.downloadModal}
        toggle={() => setModals(prevModals => ({ ...prevModals, downloadModal: false }))}
        apiStartKey={"download_op_report"}
      />

      <DownloadModal
        modal={modals.InvoiceModal}
        toggle={() => setModals(prevModals => ({ ...prevModals, InvoiceModal: false }))}
        apiStartKey={"invoice_receipt"}
      />

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If outpatient is removed. You cannot get it back</li>
            <li>All follow-up ops will be removed</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/outpatients/", outpatientObj?.id, "outpatient_deleted")}
        deleteModal={modals.deleteModal}
      />
    </React.Fragment>
  )
}

export default OutPatients
