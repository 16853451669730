export const RESET_LEADS = "RESET_LEADS"
export const STEPPED_FORM = "STEPPED_FORM"

export const OPTIONS_LEADS = "OPTIONS_LEADS"
export const OPTIONS_LEADS_SUCCESS = "OPTIONS_LEADS_SUCCESS"
export const OPTIONS_LEADS_FAIL = "OPTIONS_LEADS_FAIL"

export const LIST_LEADS = "LIST_LEADS"
export const LIST_LEADS_SUCCESS = "LIST_LEADS_SUCCESS"
export const LIST_LEADS_FAIL = "LIST_LEADS_FAIL"

export const GET_LEAD = "GET_LEAD"
export const GET_LEAD_SUCCESS = "GET_LEAD_SUCCESS"
export const GET_LEAD_FAIL = "GET_LEAD_FAIL"

export const POST_LEAD = "POST_LEAD"
export const POST_LEAD_SUCCESS = "POST_LEAD_SUCCESS"
export const POST_LEAD_FAIL = "POST_LEAD_FAIL"

export const UPDATE_LEAD = "UPDATE_LEAD"
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS"
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL"

export const DELETE_LEAD = "DELETE_LEAD"
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS"
export const DELETE_LEAD_FAIL = "DELETE_LEAD_FAIL"

export const UPLOAD_LEADS = "UPLOAD_LEADS"
export const UPLOAD_LEADS_SUCCESS = "UPLOAD_LEADS_SUCCESS"
export const UPLOAD_LEADS_FAIL = "UPLOAD_LEADS_FAIL"

export const ASSIGN_TO_AGENTS = "ASSIGN_TO_AGENTS"
export const ASSIGN_TO_AGENTS_SUCCESS = "ASSIGN_TO_AGENTS_SUCCESS"
export const ASSIGN_TO_AGENTS_FAIL = "ASSIGN_TO_AGENTS_FAIL"


export const LIST_TEMPLATES = "LIST_TEMPLATES"
export const LIST_TEMPLATES_SUCCESS = "LIST_TEMPLATES_SUCCESS"
export const LIST_TEMPLATES_FAIL = "LIST_TEMPLATES_FAIL"

export const GET_TEMPLATE = "GET_TEMPLATE"
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS"
export const GET_TEMPLATE_FAIL = "GET_TEMPLATE_FAIL"

export const POST_TEMPLATE = "POST_TEMPLATE"
export const POST_TEMPLATE_SUCCESS = "POST_TEMPLATE_SUCCESS"
export const POST_TEMPLATE_FAIL = "POST_TEMPLATE_FAIL"

export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS"
export const UPDATE_TEMPLATE_FAIL = "UPDATE_TEMPLATE_FAIL"

export const DELETE_TEMPLATE = "DELETE_TEMPLATE"
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS"
export const DELETE_TEMPLATE_FAIL = "DELETE_TEMPLATE_FAIL"

export const CHECK_HEADERS = "CHECK_HEADERS"
export const CHECK_HEADERS_SUCCESS = "CHECK_HEADERS_SUCCESS"
export const CHECK_HEADERS_FAIL = "CHECK_HEADERS_FAIL"

export const MODIFY_DISPLAY_OPTIONS = "MODIFY_DISPLAY_OPTIONS"
export const MODIFY_DISPLAY_OPTIONS_SUCCESS = "MODIFY_DISPLAY_OPTIONS_SUCCESS"
export const MODIFY_DISPLAY_OPTIONS_FAIL = "MODIFY_DISPLAY_OPTIONS_FAIL"

export const LEAD_DASHBOARD = "LEAD_DASHBOARD"
export const LEAD_DASHBOARD_SUCCESS = "LEAD_DASHBOARD_SUCCESS"
export const LEAD_DASHBOARD_FAIL = "LEAD_DASHBOARD_FAIL"

