import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row, CardBody, Label } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { resetHmsState, getHms, updateHms, postHms, listHms, getHmsOptions } from "store/actions"
import toastr from "toastr"
import { Loader } from "pages/HMS/common/common"
import { AvForm } from "availity-reactstrap-validation"
import { EAvFieldSelect } from "pages/HMS/common/errored-avfields"
import { DownloadModal } from "pages/HMS/common/hms-crud-modals"
import { ConfigureCRUModal } from "../configure/modals"
import { prependToArray, updateArrayObject } from "components/Common/common"

const Utilize = () => {
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState([])
  const [settings, setSettings] = useState(null)
  const [rudFlag, setRUDFlag] = useState(null)
  const [modals, setModals] = useState({ configureModal: false, downloadModal: false })
  const [report, setReport] = useState({ header_type: "Default" })

  const { loading, options, hmsList, apiKey, error, formError, hmsObject, postResponse, updateResponse } = useSelector((state) => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsObject: state.Hms.hmsObject,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
  }))

  useEffect(() => {
    if (apiKey === "fetch_settings") {
      setSettings(hmsObject)
    }
    if (apiKey === "settings_created") {
      toastr.success("Settings created")
      setSettings(postResponse)
    }
    if (apiKey === "settings_updated") {
      toastr.success("Settings updated")
      setSettings(updateResponse)
    }
    if (apiKey === "fetch_all_layouts") {
      setReportData(hmsList)
    }
    if (apiKey === "preview_layout") {
      toggleModal("downloadModal")
    }
    if (apiKey === "configure_utilize_created") {
      setReportData((prevData) => (prependToArray(prevData, postResponse)))
    }
    if (apiKey === "configure_utilize_updated") {
      setReportData((prevData) => (updateArrayObject(prevData, updateResponse, "id")))
      setReport(updateResponse)
    }
    if (apiKey === "preview_report_layout") {
      dispatch(postHms("/hms/hms-report-layouts/preview/", hmsObject, "preview_layout", false))
      setReport(hmsObject)
    }
    if (apiKey === "edit_report_layout") {
      setReport(hmsObject)
      handleEdit()
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/hms-report-layouts/?options=formschema", "fetch_config_formschema", false, true))
    dispatch(getHms("/hms/hms-settings/", "unique", "fetch_settings"))
    dispatch(listHms("/hms/hms-report-layouts/fetch_all/", null, "fetch_all_layouts"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  const handleSelect = (e) => {
    const { name, value } = e.target
    const payload = { [name]: value }

    if (settings?.id) {
      dispatch(updateHms("/hms/hms-settings/", "unique", payload, "settings_updated"))
    } else {
      dispatch(postHms("/hms/hms-settings/", { ...payload, type: "unique" }, "settings_created", false))
    }
  }

  const editReportLayout = () => {
    if (settings?.diagnosis_lab_report) {
      dispatch(getHms("/hms/hms-report-layouts/", settings.diagnosis_lab_report, "edit_report_layout"))
    }
  }

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }))
  }

  const handleCreate = () => {
    setRUDFlag(1)
    toggleModal("configureModal")
  }

  const handleEdit = () => {
    setRUDFlag(0)
    toggleModal("configureModal")
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs="12">
            <Card>
              <CardBody>
                {loading && apiKey === "get_fetch_settings" ? (
                  <Loader />
                ) : (
                  <AvForm>
                    <Label>Diagnosis Report</Label>
                    <hr className="mt-0" />
                    <Row>
                      <Col md={4}>
                        <div className="d-flex">
                          <div className="w-100">
                            <EAvFieldSelect
                              field="diagnosis_lab_report"
                              value={settings?.diagnosis_lab_report || ""}
                              isError={formError?.diagnosis_lab_report}
                              options={{ ...options?.formschema?.diagnosis_lab_report, label: "" }}
                              onChange={handleSelect}
                              disabled={["get_preview_report_layout", "preview_report_layout", "post_preview_layout", "update_settings_updated", "post_settings_created", "get_edit_report_layout"].includes(apiKey)}
                              choices={
                                <>
                                  <option value="">--Select an option--</option>
                                  {reportData?.map((choice, index) => (
                                    <option value={choice.id} key={index}>{choice.name}</option>
                                  ))}
                                </>
                              }
                            />
                          </div>
                          {settings?.diagnosis_lab_report ? (
                            <i
                              className="mdi mdi-pencil text-success font-size-18 ms-2 mt-1"
                              title="Modify Report Layout"
                              style={{ cursor: "pointer" }}
                              onClick={loading ? undefined : editReportLayout}
                            />
                          ) : (
                            <i
                              className="mdi mdi-plus-thick text-success font-size-18 ms-2 mt-1"
                              title="Add Report Layout"
                              style={{ cursor: "pointer" }}
                              onClick={handleCreate}
                            />
                          )}
                          {settings?.diagnosis_lab_report && (
                            <div
                              title={loading ? "" : "Preview Report Layout"}
                              style={{ cursor: "pointer" }}
                              className="ms-2 mt-1"
                              onClick={["get_preview_report_layout", "preview_report_layout", "post_preview_layout"].includes(apiKey) ? undefined : () => dispatch(getHms("/hms/hms-report-layouts/", settings.diagnosis_lab_report, "preview_report_layout"))}
                            >
                              <i className={`${["get_preview_report_layout", "preview_report_layout", "post_preview_layout"].includes(apiKey) ? "bx bx-loader bx-spin mt-1" : "mdi mdi-eye text-primary"} font-size-18`} />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ConfigureCRUModal
        modal={modals.configureModal}
        toggle={() => toggleModal("configureModal")}
        rudFlag={rudFlag}
        data={rudFlag === 0 ? report : { header_type: "Default" }}
        apiStartKey="configure_utilize"
        prepend={false}
      />

      <DownloadModal
        modal={modals.downloadModal}
        toggle={() => toggleModal("downloadModal")}
        apiStartKey="preview_layout"
        base64String={postResponse}
      />
    </React.Fragment>
  )
}

export default Utilize
