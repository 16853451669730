import React, { useEffect, useState } from "react";
import { Alert, Badge } from "reactstrap";

const FlashAlert = props => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);
        }, props.delay);
    }, [props.delay]);

    return (
        <>
        {visible &&
            <Alert color={props.color} className={props.className}>
                {props.children}
            </Alert>
        }
        </>
    )
}

export const FlashBadge = props => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);
            props.reset()
        }, props.delay);
    }, [props.delay]);

    return (
        <>
        {visible &&
            <Badge pill className={`${props.role ? props.role : "badge-soft-info"} ${props.className}`}>
                {props.children}
            </Badge>
        }
        </>
    )
}

export default FlashAlert;