import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Badge, Col, Container, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { isEmpty } from "lodash"
import { PharmacyDeleteModal } from "./pharmacy-pharm-crud-modals"
import { formatDateTime } from "components/Common/common"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getLocalStorageItem, updateURLParams } from "../common/common"

const Stock = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const formRef = useRef()
  const [medicine, setMedicine] = useState({})
  const [medicineData, setMedicineData] = useState([])
  const [columns, setColumns] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

  const { loading, options, hmsList, apiKey, error, formError } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === "medicine-list") setMedicineData(hmsList)
    if (apiKey === "medicine-deleted") {
      setMedicineData(hmsList)
      toggleDelete()
      toastr.success("Medicine is deleted successfully")
    }
    if (apiKey === "medicine-refresh") setMedicineData(hmsList)
  }, [apiKey])

  useEffect(() => {
    setMedicineData(hmsList)
  }, [hmsList])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/inventory/?options=formschema, table, search, daterange"))
    if (urlParams.length > 1) {
      dispatch(listHms("/hms/pharmacy/inventory/", decodeURIComponent(urlParams).replace("?", ""), "medicine-list"))
    } else {
      const savedFilters = getLocalStorageItem('filters')?.stock
      updateURLParams(history, savedFilters)
      dispatch(listHms("/hms/pharmacy/inventory/", savedFilters, "medicine-list"))
    }
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(options)) {
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/pharmacy/medicine/details/${row?.id}`}>
                  {row?.medicine_data?.name}
                </Link>
              )
            }
          } else if (item.dataField === "dosage") {
            return {
              ...item,
              headerStyle: { textTransform: "none" },
              formatter: (cellContent, row) => row?.medicine_data?.dosage || "---"
            }
          } else if (item.dataField === "formula") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <div title={row?.medicine_data?.formula} className="text-truncate" style={{ width: '150px' }}>
                  {row?.medicine_data?.formula}
                </div>
              )
            }
          } else if (item.dataField === "availability") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row?.current_stock > 0 ? <Badge color="success" pill>Instock</Badge> : <Badge color="danger" pill>Out of stock</Badge>
              )
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => formatDateTime(row?.created_at)
            }
          } else if (item.dataField === "added_by") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.created_by_data?.full_name
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "Action",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className='dropdown-item text-0' to={`/hms/pharmacy/medicine/medstock/${row.id}`}>
                  Modify
                </Link>
                <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const handleDelete = medicine_object => {
    setMedicine(medicine_object)
    toggleDelete(false)
  }

  function toggleDelete(state = true) {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/pharmacy/inventory/', decodeURIComponent(urlParams).replace("?", ""), "medicine-refresh"))
    } else {
      dispatch(listHms('/hms/pharmacy/inventory/', null, "medicine-refresh"))
    }
  }

  const createButton = () => {
    return (
      <div className="text-sm-start">
        <Link
          to="/hms/pharmacy/medicine/medstock"
          className="me-2 btn btn-primary btn-sm"
        >
          +&nbsp;Add&nbsp;Stock
        </Link>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stock | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error ? (
            <p className="text-center text-danger">{error?.detail}</p>
          ) : (
            <Row>
              <Col xs="12">
                {(!isEmpty(columns) && (
                  <GenericTable
                    moduleName="stock"
                    urlParams={urlParams}
                    search={options?.search}
                    daterange={options?.daterange}
                    filters={options?.filters}
                    columns={columns}
                    keyField={"id"}
                    noDataIndication={"No records found"}
                    data={medicineData}
                    loading={loading}
                    apiTrigger={apiTrigger}
                    defaultPageSize={options?.page_size}
                    createButton={createButton}
                  />
                ))}
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <PharmacyDeleteModal
        body={<>
          <ul>
            <li>If medicine is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/pharmacy/inventory/", medicine?.id, "medicine-deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  )
}

export default Stock
