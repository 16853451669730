import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux";
import {
  postTemplate as onPostTemplate,
  updateTemplate as onUpdateTemplate,
  resetState,
  steppedForm,
} from "store/actions"
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { isEmpty } from "lodash";
import toastr from "toastr"


const Step2 = props => {
  const dispatch = useDispatch()
  const [headers, setHeaders] = useState({})
  const [enableModal, setEnableModal] = useState(false)

  const { error, formError, loading, isPosted, isUpdated, dropdownData, formData } = useSelector(state => ({
    error: state.Lms.error,
    formError: state.Lms.formError,
    loading: state.Lms.loading,
    isPosted: state.Lms.isPosted,
    isUpdated: state.Lms.isUpdated,
    dropdownData: state.Lms.dropdownData,
    formData: state.Lms.formData,
  }))

  useEffect(() => {
    if (!isEmpty(dropdownData)) setHeaders(dropdownData.headers)
  })

  const displayMessage = (message) => {
    toastr.success(message)
    dispatch(steppedForm({
      step: 1
    }))
    dispatch(resetState())
  }

  useEffect(() => {
    if (isPosted) {
      displayMessage("Template created successfully")
    }
  }, [isPosted])

  useEffect(() => {
    if (isUpdated) {
      displayMessage("Template modified successfully")
    }
  }, [isUpdated])

  useEffect(() => {
    if (formError) {
      Object.entries(formError).map(([key, value]) => {
        toastr.error(value[0])
      })
    }
  }, [formError])

  const handleValidSubmit = (event, values) => {
    let framedObject = {}
    // need to check data types. that matching headers with fields datatypes. if not display an error
    // 1. Get datatype also from server
    // 2. Get first n records from file in frontend only
    // 3. identify each field is what type it is
    // 4. lets compare them
    if (dropdownData.templateUID) {
      Object.entries(headers).map(([header, object], idx) => {
        framedObject[header] = {
          label: object.label,
          field: object.value,
          field_label: dropdownData.fields[object.value].label,
          filter: values[`fs_${header}`] === 'filter' ? true : false,
          search: values[`fs_${header}`] === 'search' ? true : false,
          show_in_table: object.show_in_table,
        }
      })
      dispatch(onUpdateTemplate(dropdownData.templateUID, { schema: framedObject }))
    } else {
      Object.entries(headers).map(([header, object], idx) => {
        framedObject[header] = {
          label: object.label,
          field: values[header],
          field_label: dropdownData.fields[values[header]].label,
          filter: values[`fs_${header}`] === 'filter' ? true : false,
          search: values[`fs_${header}`] === 'search' ? true : false,
          show_in_table: true,
        }
      })
      dispatch(onPostTemplate({ schema: framedObject, name: formData.templateName }))
    }
  }

  const dropdownChange = (key, old_value, new_value) => {
    // disable selected options
    let elems_to_disable = document.getElementsByClassName(`class_${new_value}`)
    for (let index = 1; index < elems_to_disable.length; index++) {
      elems_to_disable[index].setAttribute("disabled", true)
    }
    // enable deselected options
    let elems_to_enable = document.getElementsByClassName(`class_${old_value}`)
    for (let index = 0; index < elems_to_enable.length; index++) {
      elems_to_enable[index].removeAttribute("disabled")
    }
    // update state
    let tObj = headers
    tObj[key].value = new_value
    setHeaders(tObj)
  }

  const goBack = () => {
    let storeObj = formData
    storeObj.step = 1
    dispatch(steppedForm(storeObj))
  }

  function toggleModal() {
    setEnableModal(!enableModal)
  }

  return (
    <React.Fragment>
      {error ?
        <Card>
          <CardBody>
            {Object.entries(error).map(([key, value], idx) =>
              <p className="text-center text-danger" key={idx}>{value}</p>
            )}
          </CardBody>
        </Card>
        :
        <>
          <Modal isOpen={enableModal} toggle={toggleModal} backdrop="static">
            <ModalBody>
              <p>Unsaved changes will be removed. Are you sure want to leave this page?</p>
              <Row className="float-end">
                <Col>
                  <button type="button" className="btn btn-primary btn-sm me-2" onClick={goBack}>Yes</button>
                  <button type="button" className="btn btn-sm me-2" onClick={toggleModal}>No</button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Card>
            <CardBody>
              {!isEmpty(dropdownData) &&
                <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
                  <Row>
                    <Col>
                      <h4>{(dropdownData && dropdownData.templateUID) ? "Edit Display Options" : "Columns Mapping"}</h4>
                    </Col>
                    <Col>
                      <div className="mb-3 d-flex flex-row-reverse">
                        {loading ?
                          <button className="btn btn-primary btn-block me-2" disabled>
                            Next{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </button>
                          :
                          <button className="btn btn-primary btn-block me-2" type="submit">Save</button>
                        }
                        <button className="btn btn-muted btn-block me-2" type="button" onClick={toggleModal}>Back</button>
                      </div>
                    </Col>
                  </Row>
                  {Object.entries(headers).map(([key, keyObj], idx) =>
                    <Row key={idx} className="align-items-center">
                      {(dropdownData && dropdownData.templateUID) ?
                        <Col>
                          <Label for={`for_${key}`}>{keyObj.label}</Label>
                          <AvRadioGroup inline name={`fs_${key}`} value={keyObj.filter ? "filter" : keyObj.search ? "search" : ""}>
                            <AvRadio label="Filter" value="filter" />
                            <AvRadio label="Search" value="search" />
                          </AvRadioGroup>
                        </Col>
                        :
                        <>
                          <Col>
                            <AvField
                              id={`id_${key}`}
                              name={key}
                              label={keyObj.label}
                              className="form-control"
                              type="select"
                              value={keyObj.value}
                              required
                              onChange={(e) => dropdownChange(key, keyObj.value, e.target.value)}
                            >
                              <option>---------------</option>
                              {Object.entries(dropdownData.fields).map(([optKey, optValue], optIdx) =>
                                <option
                                  value={optKey}
                                  key={optIdx}
                                  className={`class_${optKey}`}
                                  disabled={optValue.disabled}
                                >
                                  {optValue.label}
                                </option>
                              )}
                            </AvField>
                          </Col>
                          <Col>
                            <AvRadioGroup inline name={`fs_${key}`} value={keyObj.filter ? "filter" : keyObj.search ? "search" : ""}>
                              <AvRadio label="Filter" value="filter" />
                              <AvRadio label="Search" value="search" />
                            </AvRadioGroup>
                          </Col>
                        </>
                      }
                    </Row>
                  )}
                </AvForm>
              }
            </CardBody>
          </Card>
        </>
      }
    </React.Fragment>
  )
}

export default Step2;