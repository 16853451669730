import React, { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, Label } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { listHms, updateHms, postHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import RemoteGenericTable from "components/Common/tables"
import { EAsyncSelect, EAvFieldGenericInput, EAvFieldSelect, RequiredFieldsMessage } from "../common/errored-avfields"
import { get } from "helpers/api_helper"
import { HmsDeleteModal, SingleFieldCRUModal } from "../common/hms-crud-modals"
import { SubmitLoaderButton } from "../common/common"
import { deletePaginationResult, updatePaginationResult } from "components/Common/common"

const BedConfig = props => {
  const dispatch = useDispatch()
  const formRef = useRef()

  const { loading, options, hmsList, apiKey, error, formError, hmsSingleObject, updateResponse, hmsObject, modifiedFormSchema } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    hmsSingleObject: state.Hms.hmsSingleObject,
    updateResponse: state.Hms.updateResponse,
    hmsObject: state.Hms.hmsObject,
    modifiedFormSchema: state.Hms.modifiedFormSchema,
  }))

  const [modal, setModal] = useState(false)
  const [bed, setBed] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [customError, setCustomError] = useState(null)
  const [bedData, setBedData] = useState({})
  const [selectedWard, setSelectedWard] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [modalWard, setModalWard] = useState(false)
  const [modalCategory, setModalCategory] = useState(false)
  const [wardOptions, setWardOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])

  const columns = [{
    dataField: "ward",
    text: "Ward",
    sort: false,
    formatter: (cellContent, row) => (
      <Link to="#" onClick={() => handleEdit(row)}>{row.ward_name}</Link>
    )
  }, {
    dataField: "bed_number",
    text: "Bed number",
    sort: false,
  }, {
    dataField: "room_number",
    text: "Room number",
    sort: false,
  }, {
    dataField: "floor",
    text: "Floor",
    sort: false,
  }, {
    dataField: "is_available",
    text: "Available",
    sort: false,
    formatter: (cellContent, row) => (
      <span>{row.is_available
        ? <i className="bx bxs-check-circle font-size-16 text-success"></i>
        : <i className="bx bxs-x-circle font-size-16 text-danger"></i>
      }</span>
    )
  }, {
    dataField: "room_category",
    text: "Room category",
    sort: false,
    formatter: (cellContent, row) => (
      <span>{row.room_category_name}</span>
    )
  }, {
    dataField: "room_type",
    text: "Sharing",
    sort: false,
  }, {
    dataField: "price",
    text: "Price",
    sort: false,
  }, {
    dataField: "action",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => (
      <UncontrolledDropdown className="ms-auto">
        <DropdownToggle
          className="text-muted font-size-16"
          color="white"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
            Modify
          </Link>
          <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
            Remove
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }
  ]

  useEffect(() => { setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === "list_bed") setBedData(hmsList)

    if (apiKey === "post_bed") {
      setBedData({ ...bedData, results: [hmsSingleObject, ...bedData.results] })
      setModal(false)
      toastr.success("Bed created successfully")
      formRef.current?.reset()
    }
    if (apiKey === "update_bed") {
      setBedData(updatePaginationResult(bedData, updateResponse, 'id'))
      setModal(false)
      toastr.success("Bed updated successfully")
    }
    if (apiKey === "delete_bed") {
      setBedData(deletePaginationResult(bedData, 'id', hmsObject))
      setModal(false)
      toggleDelete()
      toastr.success(`Bed ${bed?.bed_number} deleted from room number ${bed?.room_number}`)
    }

    if (apiKey === "options_ward") setWardOptions(hmsList?.results)
    if (apiKey === "options_category") setCategoryOptions(hmsList?.results)
    if (apiKey === "ward_created") setSelectedWard(hmsSingleObject)
    if (apiKey === "category_created") setSelectedCategory(hmsSingleObject)
    if (apiKey === "fecth_new_bed") setBedData(hmsList)

    if (formError?.non_field_errors) {
      toastr.error(formError?.non_field_errors?.map((error, idx) =>
        `<b>${idx + 1}:</b> ${error}</br>`
      ));
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/bedconfig/?options=formschema", "options_bed", false, true));
    dispatch(listHms("/hms/patient/bedconfig/", null, "list_bed"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  const fetchNewPage = (page, sizePerPage) => {
    dispatch(listHms("/hms/patient/bedconfig/", `page=${page}`, "fecth_new_bed"))
  }

  const handleEdit = bed_object => {
    setSelectedWard({ id: bed_object?.ward, name: bed_object?.ward_name })
    setSelectedCategory({ id: bed_object?.room_category, name: bed_object?.room_category_name })
    setBed(bed_object)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = bed_object => {
    setBed(bed_object)
    toggleDelete(false)
  }

  const handleValidSubmit = (e, values) => {
    values.ward = selectedWard?.id
    values.room_category = selectedCategory?.id

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/patient/bedconfig/", bed?.id, values, "update_bed"))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/patient/bedconfig/", values, "post_bed"))
    }
  }

  const handleCreate = () => {
    setSelectedWard(null)
    setSelectedCategory(null)
    setBed({})
    setRUDFlag(1)
    toggle(false)
  }

  const toggle = (def = modal) => {
    setModal(!def)
    setCustomError(null)
  }

  const toggleDelete = (state = true) => {
    setDeleteModal(!state)
  }

  const toggleWard = (def = modalWard) => {
    setModalWard(!def)
  }

  const toggleCategory = (def = modalCategory) => {
    setModalCategory(!def)
  }

  var suTo = null
  const searchWards = (value, callback) => {
    value = value.trim()
    if (value !== "" && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/bed-ward/?search=${value}`).then(resp => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const searchCategories = (value, callback) => {
    value = value.trim()
    if (value !== "" && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/bed-category/?search=${value}`).then(resp => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  return (error && !["BED_IN_USE"].includes(error.code) ? (
    <p className="text-center text-danger">{error?.detail}</p>
  ) : (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2 me-2 btn-sm"
                          onClick={handleCreate}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Create a bed
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <RemoteGenericTable
                    loading={loading}
                    data={bedData}
                    columns={columns}
                    tKey={'id'}
                    noDataMessage={"No beds created"}
                    pageChangeHandler={fetchNewPage}
                  />

                  <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
                    <ModalHeader toggle={toggle} tag="h4">
                      {rudFlag === 0 ? "Edit Bed" : rudFlag === 1 ? "Add Bed" : rudFlag === 2 ? "Delete Bed" : ""}
                    </ModalHeader>
                    <ModalBody>
                      <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
                        {options?.form_schema &&
                          <>
                            <Row>
                              <Col xs={12} md={6}>
                                <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                                  <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                    <Label className={customError?.ward ? "text-danger" : ''}>
                                      {options?.form_schema?.ward?.label} {options?.form_schema?.ward?.required && " *"}
                                    </Label>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <div style={{ width: "100%" }}>
                                        <EAsyncSelect
                                          cacheOptions
                                          selectedOption={[selectedWard]}
                                          fetchOptions={searchWards}
                                          formError={customError?.ward}
                                          onSelect={(value) => setSelectedWard(value)}
                                          placeholder="Type to search Ward..."
                                          getOptionLabel={e => `${e?.name}`}
                                          getOptionValue={e => e?.id}
                                          defaultOptions={wardOptions}
                                          onFocus={() => dispatch(listHms("/hms/patient/bed-ward/", null, "options_ward"))}
                                        />
                                      </div>
                                      <div onClick={() => toggleWard(false)} title="Add ward" style={{ cursor: "pointer" }} className="ms-2">
                                        <i className="bx bx-plus-medical text-success" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} md={6}>
                                <div className="mb-3" style={{ display: "flex", alignItems: "center" }}>
                                  <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                    <Label className={customError?.room_category ? "text-danger" : ''}>
                                      {options?.form_schema?.room_category?.label} {options?.form_schema?.room_category?.required && " *"}
                                    </Label>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <div style={{ width: "100%" }}>
                                        <EAsyncSelect
                                          cacheOptions
                                          selectedOption={[selectedCategory]}
                                          fetchOptions={searchCategories}
                                          formError={customError?.room_category}
                                          onSelect={(value) => setSelectedCategory(value)}
                                          placeholder="Type to search Category..."
                                          getOptionLabel={e => `${e?.name}`}
                                          getOptionValue={e => e?.id}
                                          defaultOptions={categoryOptions}
                                          onFocus={() => dispatch(listHms("/hms/patient/bed-category/", null, "options_category"))}
                                        />
                                      </div>
                                      <div onClick={() => toggleCategory(false)} title="Add category" style={{ cursor: "pointer" }} className="ms-2">
                                        <i className="bx bx-plus-medical text-success" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={4}>
                                <EAvFieldGenericInput
                                  value={bed?.floor}
                                  isError={customError?.floor}
                                  {...modifiedFormSchema?.floor}
                                />
                              </Col>
                              <Col xs={12} md={4}>
                                <EAvFieldGenericInput
                                  value={bed?.room_number}
                                  isError={customError?.room_number}
                                  {...modifiedFormSchema?.room_number}
                                />
                              </Col>
                              <Col xs={12} md={4}>
                                <EAvFieldGenericInput
                                  value={bed?.bed_number}
                                  isError={customError?.bed_number}
                                  {...modifiedFormSchema?.bed_number}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={4}>
                                <EAvFieldGenericInput
                                  value={bed?.room_type}
                                  isError={customError?.room_type}
                                  {...modifiedFormSchema?.room_type}
                                />
                              </Col>
                              <Col xs={12} md={4}>
                                <EAvFieldGenericInput
                                  value={bed?.price}
                                  isError={customError?.price}
                                  {...modifiedFormSchema?.price}
                                />
                              </Col>
                              <Col xs={12} md={4}>
                                <EAvFieldSelect
                                  field="is_available"
                                  value={bed?.is_available !== undefined && bed?.is_available !== null ? (bed?.is_available ? "true" : "false") : ""}
                                  isError={customError?.is_available}
                                  options={options?.form_schema?.is_available}
                                  choices={
                                    <>
                                      <option value={""}>{"Select option"}</option>
                                      <option value={"true"}>Empty</option>
                                      <option value={"false"}>Occupied</option>
                                    </>
                                  }
                                />
                              </Col>
                              <RequiredFieldsMessage />
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end mb-2">
                                  <SubmitLoaderButton type="submit" size="sm" loading={apiKey === "post_post_bed" || apiKey === "update_update_bed"}>
                                    SAVE
                                  </SubmitLoaderButton>
                                </div>
                              </Col>
                            </Row>
                          </>
                        }
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <HmsDeleteModal
          body={<>
            <ul>
              <li>Move patients to another beds before removing bed</li>
              <li>If bed is removed. You cannot get it back</li>
            </ul>
          </>}
          loading={loading}
          error={error}
          toggleDelete={toggleDelete}
          dispatchOperation={deleteHms("/hms/patient/bedconfig/", bed?.id, "delete_bed")}
          deleteModal={deleteModal}
        />

        <SingleFieldCRUModal
          modalLabel={"Ward"}
          modal={modalWard}
          toggle={toggleWard}
          rudFlag={1}
          options={{ label: "Name", required: true }}
          apiStartKey={"ward"}
          apiEndpoint={"/hms/patient/bed-ward/"}
        />

        <SingleFieldCRUModal
          modalLabel={"Category"}
          modal={modalCategory}
          toggle={toggleCategory}
          rudFlag={1}
          options={{ label: "Name", required: true }}
          apiStartKey={"category"}
          apiEndpoint={"/hms/patient/bed-category/"}
        />
      </div>
    </React.Fragment>
  )
  )
}

export default BedConfig;
