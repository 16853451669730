import { get, post, put, del, patch } from "../../helpers/api_helper"

export const getAllBranches = data => {
    let url = "/branch/all"
    if (data) url = `${url}?${data}`
    return get(url)
}
export const addNewBranch = branch => post("/branch/create", branch)
export const updateABranch = branch => patch(`/branch/${branch.uid}/rud`, branch)
export const deleteABranch = branchID => del(`/branch/${branchID}/rud`)

export const getAllUnassignedGroupMembers = data => {
    let url = "/group/unassigners"
    if (data) url = `${url}?${data}`
    return get(url)
}

export const getAllBranchGroups = data => {
    let url = `/branch/${data.branchID}/groups`
    if (data.page) url = `${url}?${data.page}`
    return get(url)
}
export const addNewGroup = ({branchID, group}) => post(`/branch/${branchID}/group/create`, group)
export const updateAGroup = ({groupUid, data}) => put(`/group/${groupUid}/update`, data)
export const deleteAGroup = groupID => del(`/group/${groupID}/rud`, { headers: { groupID } })
export const getAGroupDetail = groupID => get(`/group/${groupID}/detail`)
export const addUsersAPI = ({groupUid, data}) => post(`/group/${groupUid}/users/add`, data)
export const removeGroupUserAPI = ({groupUid, userID}) => del(`/group/${groupUid}/user/${userID}/release`)


