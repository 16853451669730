import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Badge, Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getHmsOptions, listHms, reportDownloadHms, resetHmsState } from "store/actions";
import GenericTable from "pages/HMS/common/table";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getLocalStorageItem, handleIncludedKeysError, HMSLink, updateURLParams } from "pages/HMS/common/common";
import { DownloadModal } from "pages/HMS/common/hms-crud-modals";

const BookTestReport = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [previewReportModal, setPreviewReportModal] = useState(false);
  const [downloadInvoiceModal, setDownloadInvoiceModal] = useState(false);

  const { loading, error, hmsList, options, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    hmsList: state.Hms.hmsList,
    options: state.Hms.options,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/book-tests/?options=table, search, daterange, filters", "results_options"))
    if (urlParams.length > 1) {
      dispatch(listHms('/hms/diagnosis/book-tests/', decodeURIComponent(urlParams).replace("?", "")))
    } else {
      const savedFilters = getLocalStorageItem('filters')?.test_reports
      updateURLParams(history, savedFilters)
      dispatch(listHms('/hms/diagnosis/book-tests/', savedFilters))
    }
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (error && error.code !== "TEST_DOES_NOT_EXIST" && apiKey.startsWith("fail_")) {
      const includedKeys = ['detail']
      handleIncludedKeysError(error, apiKey, "fail_", includedKeys, false)
    }
    if (apiKey === "preview_lab_report") setPreviewReportModal(true)
    if (apiKey === "bt_invoice_receipt") setDownloadInvoiceModal(true)
  }, [apiKey]);

  useEffect(() => {
    if (!isEmpty(options) && apiKey === "results_options") {
      if (options.filters) setFilter(options.filters);
      if (options.table_columns) {
        const buffer = options.table_columns.map(item => {
          if (item.dataField === "patient_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/diagnosis/test/${row.id}/detail`} >
                  {row.patient_name}
                </Link>
              )
            }
          } else if (item.dataField === "test_count") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                !isEmpty(row?.tests_data) ? (
                  row.tests_data.slice(0, 2).map((test, index) =>
                    index === 0 ? (
                      <Link
                        to="#"
                        className="badge badge-soft-primary font-size-11 m-1 text-truncate"
                        style={{ maxWidth: "100px" }}
                        key={test.id || index}
                        title={`${test.name} ${test?.short_name ? `(${test.short_name})` : ""}`}
                      >
                        {test.name} {test?.short_name ? `(${test.short_name})` : ""}
                      </Link>
                    ) : (
                      <Link
                        to="#"
                        className="badge badge-soft-primary font-size-11 m-1 text-truncate"
                        key={index}
                        title={row.tests_data.slice(1).map(test => test.name).join(", ")}
                      >
                        {row.tests_data.length - 1}+ more
                      </Link>
                    )
                  )
                ) : (
                  "---"
                )
              )
            }
          } else if (item.dataField === "referred_by") {
            return {
              ...item,
              formatter: (cellContent, row) => row.referred_by || "---"
            }
          } else if (item.dataField === "is_finished") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row?.is_finished ? (
                  <Badge pill color="success">Yes</Badge>
                ) : (
                  <Badge pill color="danger">No</Badge>
                )
              )
            };
          } else {
            return { ...item };
          }
        });

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16 border-0" color="white" disabled={row?.status === "Expired"}>
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {row.is_finished && (
                  <HMSLink onClick={() => downloadLabReport(row?.id)} apiStartKey="preview_lab_report">
                    Download Report
                  </HMSLink>
                )}
                <HMSLink
                  onClick={() => downloadInvoice(row?.id)} apiStartKey="bt_invoice_receipt">
                  Print Invoice
                </HMSLink>
                <Link className="dropdown-item" to={`/hms/diagnosis/test/book/${row.id}`}>
                  Modify
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        };

        setColumns([...buffer, actionColumn]);
      }
    }
  }, [options]);

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/diagnosis/book-tests/", urlParams))
    } else {
      dispatch(listHms("/hms/diagnosis/book-tests/",))
    }
  }

  const downloadLabReport = (id) => {
    if (id) {
      dispatch(reportDownloadHms(`/hms/diagnosis/book-tests/${id}/test_report/`, "preview_lab_report"))
    }
  }

  const downloadInvoice = (id) => {
    if (id) {
      dispatch(reportDownloadHms(`/hms/diagnosis/book-tests/${id}/invoice_receipt/`, "bt_invoice_receipt"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error && error.code !== "TEST_DOES_NOT_EXIST" ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (
            <>
              {(!isEmpty(columns)) &&
                <GenericTable
                  moduleName="test_reports"
                  urlParams={urlParams}
                  filters={filters}
                  search={options.search}
                  daterange={options.daterange}
                  columns={columns}
                  keyField={"id"}
                  noDataIndication={"No tests created"}
                  data={hmsList}
                  loading={loading}
                  apiTrigger={apiTrigger}
                  defaultPageSize={options.page_size}
                />
              }
            </>
          )}
        </Container>
      </div>

      <DownloadModal
        modal={previewReportModal}
        toggle={() => setPreviewReportModal(false)}
        apiStartKey={"preview_lab_report"}
      />

      <DownloadModal
        modal={downloadInvoiceModal}
        toggle={() => setDownloadInvoiceModal(false)}
        apiStartKey={"bt_invoice_receipt"}
      />
    </React.Fragment>
  )
}

export default BookTestReport
