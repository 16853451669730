import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { AvForm } from "availity-reactstrap-validation"
import { getHms, postHms, resetHmsState } from "../../store/actions"
import profile from "assets/images/profile-img.png"
import logo from "assets/yanthura/images/logoIcon.png"
import { Loader } from "pages/HMS/common/common"
import { EAvFieldInput, EAvFieldNumber } from "pages/HMS/common/errored-avfields"
import { passwordStrength } from "components/Common/common"
import SweetAlert from "react-bootstrap-sweetalert"

const UserVerification = props => {
  const dispatch = useDispatch()

  const { error, formError, apiKey, postResponse } = useSelector(state => ({
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
  }))

  useEffect(() => {
    if (props.match.params.token) {
      dispatch(getHms(`/accounts/user-verification/${props.match.params.token}`, null, "verify_token"))
    }
    return () => dispatch(resetHmsState())
  }, [])

  const onCheckPasswordStrength = (e) => {
    passwordStrength(e)
  }

  const handleSubmit = (event, values) => {
    values.otp = Number(values.otp)
    dispatch(postHms(`/accounts/user-verification/${props.match.params.token}`, values, "user_verification"))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>User Verification | Yanthura</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            {error?.detail || formError?.detail ? (
              <Card>
                <CardBody>
                  <p className="text-center text-danger">{error?.detail || formError?.detail}</p>
                </CardBody>
              </Card>
            ) : apiKey === "user_verification" ? (
              <SweetAlert
                title="Success"
                success
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => window.location = "/login"}
                confirmBtnText="Login"
              >
                {postResponse?.detail}
              </SweetAlert>
            ) : apiKey === "get_verify_token" ? (
              <Loader />
            ) : (
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">User Verification</h5>
                          <p>Verify and reset password.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="#" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                        <div className="mb-3">
                          <EAvFieldNumber
                            field="otp"
                            isError={formError?.otp}
                            options={{ label: "One Time Password", required: true, minValue: 99999, maxValue: 999999 }}
                            placeholder="Enter 6-digit OTP"
                          />
                        </div>
                        <div className="mb-3">
                          <EAvFieldInput
                            field="password"
                            type="password"
                            placeholder="Enter Password"
                            isError={formError?.password}
                            options={{ label: "Password", required: true, max_length: 30 }}
                            onInput={onCheckPasswordStrength}
                          />
                        </div>
                        <div className="mb-3">
                          <EAvFieldInput
                            field="confirm_password"
                            type="password"
                            placeholder="Retype password"
                            isError={formError?.confirm_password}
                            options={{ label: "Confirm password", required: true, max_length: 30 }}
                          />
                        </div>
                        <div className="mt-3 d-grid">
                          {apiKey === "post_user_verification" ? (
                            <button className="btn btn-primary btn-block" disabled>
                              Submit & Verify account{" "}
                              <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                            </button>
                          ) : (
                            <button className="btn btn-primary btn-block" type="submit">
                              Submit & Verify account
                            </button>
                          )}
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} Yanthura. Crafted with{" "}
                    <i className="mdi mdi-heart text-danger" /> by Yanthura
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserVerification
