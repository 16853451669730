import {
  GET_BRANCHES,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  
  GET_UNAS_USERS,
  GET_UNAS_USERS_SUCCESS,
  GET_UNAS_USERS_FAIL,
  
  GET_BRANCH_GROUPS,
  GET_BRANCH_GROUPS_FAIL,
  GET_BRANCH_GROUPS_SUCCESS,
  GET_GROUP_DETAIL,
  GET_GROUP_DETAIL_FAIL,
  GET_GROUP_DETAIL_SUCCESS,
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  ADD_USERS,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAIL,
  REMOVE_USER_FROM_GROUP,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  REMOVE_USER_FROM_GROUP_FAIL,
} from "./actionTypes"

// Get all un-assigned users
export const getUnassignedGroupMembers = data => ({
  type: GET_UNAS_USERS,
  data
})
export const getUnassignedGroupMembersSuccess = members => ({
  type: GET_UNAS_USERS_SUCCESS,
  payload: members,
})
export const getUnassignedGroupMembersFail = error => ({
  type: GET_UNAS_USERS_FAIL,
  payload: error,
})

// Get all branches list
export const getBranches = data => ({
  type: GET_BRANCHES,
  data
})
export const getBranchesSuccess = branches => ({
  type: GET_BRANCHES_SUCCESS,
  payload: branches,
})
export const getBranchesFail = error => ({
  type: GET_BRANCHES_FAIL,
  payload: error,
})
// Add branch
export const addBranch = branch => ({
  type: ADD_BRANCH,
  payload: branch,
})
export const addBranchSuccess = branch => ({
  type: ADD_BRANCH_SUCCESS,
  payload: branch,
})
export const addBranchFail = error => ({
  type: ADD_BRANCH_FAIL,
  payload: error,
})
// Update branch
export const updateBranch = branchUid => ({
  type: UPDATE_BRANCH,
  payload: branchUid,
})
export const updateBranchSuccess = branchUid => ({
  type: UPDATE_BRANCH_SUCCESS,
  payload: branchUid,
})
export const updateBranchFail = error => ({
  type: UPDATE_BRANCH_FAIL,
  payload: error,
})
// Delete branch
export const deleteBranch = branchUid => ({
  type: DELETE_BRANCH,
  payload: branchUid,
})
export const deleteBranchSuccess = branchUid => ({
  type: DELETE_BRANCH_SUCCESS,
  payload: branchUid,
})
export const deleteBranchFail = error => ({
  type: DELETE_BRANCH_FAIL,
  payload: error,
})


// Get branch group list
export const getBranchGroups = data => ({
  type: GET_BRANCH_GROUPS,
  data,
})
export const getBranchGroupsSuccess = groups => ({
  type: GET_BRANCH_GROUPS_SUCCESS,
  payload: groups,
})
export const getBranchGroupsFail = error => ({
  type: GET_BRANCH_GROUPS_FAIL,
  payload: error,
})
// Get group details
export const getGroupDetail = groupUid => ({
  type: GET_GROUP_DETAIL,
  groupUid,
})
export const getGroupDetailSuccess = group => ({
  type: GET_GROUP_DETAIL_SUCCESS,
  payload: group,
})
export const getGroupDetailFail = error => ({
  type: GET_GROUP_DETAIL_FAIL,
  payload: error,
})
// Add group
export const addGroup = (branchID, group) => ({
  type: ADD_GROUP,
  payload: {branchID, group},
})
export const addGroupSuccess = group => ({
  type: ADD_GROUP_SUCCESS,
  payload: group,
})
export const addGroupFail = error => ({
  type: ADD_GROUP_FAIL,
  payload: error,
})
// Update group
export const updateGroup = (groupUid, data) => ({
  type: UPDATE_GROUP,
  payload: {groupUid, data},
})
export const updateGroupSuccess = groupUid => ({
  type: UPDATE_GROUP_SUCCESS,
  payload: groupUid,
})
export const updateGroupFail = error => ({
  type: UPDATE_GROUP_FAIL,
  payload: error,
})
// Delete group
export const deleteGroup = groupUid => ({
  type: DELETE_GROUP,
  payload: groupUid,
})
export const deleteGroupSuccess = groupUid => ({
  type: DELETE_GROUP_SUCCESS,
  payload: groupUid,
})
export const deleteGroupFail = error => ({
  type: DELETE_GROUP_FAIL,
  payload: error,
})
// Add users to group
export const addUsers = (groupUid, data) => ({
  type: ADD_USERS,
  groupUid, 
  data,
})
export const addUsersSuccess = users => ({
  type: ADD_USERS_SUCCESS,
  payload: users,
})
export const addUsersFail = error => ({
  type: ADD_USERS_FAIL,
  payload: error,
})

// Remove single user from group
export const removeGroupUser = (groupUid, userID) => ({
  type: REMOVE_USER_FROM_GROUP,
  groupUid, 
  userID,
})
export const removeGroupUserSuccess = users => ({
  type: REMOVE_USER_FROM_GROUP_SUCCESS,
  payload: users,
})
export const removeGroupUserFail = error => ({
  type: REMOVE_USER_FROM_GROUP_FAIL,
  payload: error,
})
