import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { OTP } from "./actionTypes"
import { userOtpSuccess, userOtpError } from "./actions"
import {
  postJwtOtp,
} from "../../../helpers/fakebackend_helper"


//If user is send successfully send otp then dispatch redux action's are directly from here.
function* otpUser({ payload: { user } }) {
  console.log('===========> ', user);
  try {
    const response = yield call(postJwtOtp, {
      otp: user.otp,
      flag: "validate"
    })
    console.log('RESPY==>  ', response);
    if (response) {
      yield put(
        userOtpSuccess(
          "Otp sent to your email, check there first"
        )
      )
    }
  } catch (error) {
    yield put(userOtpError(error))
  }
}

export function* watchUserOtp() {
  yield takeEvery(OTP, otpUser)
}

function* otpSaga() {
  yield all([fork(watchUserOtp)])
}

export default otpSaga
