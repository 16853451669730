import React, { useEffect, useState } from "react"
import { isEmpty } from "lodash"

import { Button, Card, CardBody, Col, Row, Container, Label } from "reactstrap"
import { addUser as onAddUser, resetAccount } from "store/actions"

import { useSelector, useDispatch } from "react-redux"
import { MetaTags } from "react-meta-tags"
import { AvField, AvForm } from "availity-reactstrap-validation"
import PhoneInput from "react-phone-input-2"
import { hybridErrorAlertDisplay } from "components/Common/common"
import toastr from "toastr"

const addUsers = () => {
  const dispatch = useDispatch()
  const [phonenumber, setPhonenumber] = useState(null)
  const [users, setUsers] = useState([])
  const [error, setError] = useState(null)
  const [userObject, setUserObject] = useState({})


  const { loading, user, formError } = useSelector(state => ({
    loading: state.Account.loading,
    user: state.Account.user,
    formError: state.Account.formError,
  }))

  useEffect(() => {
    if(!isEmpty(user)) {
      setUsers(user)
      setUserObject({})
      setError(null)
      toastr.success("User added successfully")
    }
  }, [user])

  useEffect(() => {
    if (!isEmpty(formError)) {
      setError(formError)
    }
  }, [formError])

  const handleSubmit = (e, values) => {
    values = { ...values, phone_number: `+${phonenumber}` }
    setUserObject(values)
    if (phonenumber === null) {
      setError({
        phone_number: ['Phone number must required']
      })
      return
    }
    dispatch(onAddUser(values))
  }

  useEffect(() => {
    return () => {resetState()}
  }, [])

  const resetState = () => {
    dispatch(resetAccount())
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add users | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title">Add users</div>
              {error && hybridErrorAlertDisplay(error)}
              <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                <div className="mb-3">
                  <AvField
                    name="full_name"
                    label="Fullname"
                    type="text"
                    required
                    placeholder="Enter fullname"
                    value={userObject.full_name ? userObject.full_name : ""}
                    validate={{required: {value: false}}}
                  />
                </div>
                <Row className="mb-3">
                  <Col>
                    <AvField
                      id="email"
                      name="email"
                      label="Email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      required
                      value={userObject.email ? userObject.email : ""}
                      validate={{required: {value: false}}}
                    />
                  </Col>
                  <Col>
                    <Label>Phonenumber</Label>
                    <PhoneInput
                      inputClass="w-100"
                      country={'in'}
                      specialLabel="Phonenumber"
                      onChange={number => setPhonenumber(number)}
                      inputProps={{
                        name: 'phone_number',
                        required: true
                      }}
                      value={userObject.phone_number ? userObject.phone_number : "+91"}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <AvField
                      id="dob"
                      name="dob"
                      label="Birth date"
                      className="form-control"
                      placeholder="Enter birth date"
                      type="date"
                      validate={{ required: {value: false}, dateRange: { start: { value: -100, units: 'years' }, end: { value: -13, units: 'years' } } }}
                      required
                      value={userObject.dob ? userObject.dob : ""}
                    />
                  </Col>
                  <Col>
                    <AvField
                      id="user_type"
                      name="user_type"
                      label="User Type"
                      className="form-control"
                      type="select"
                      required
                      value={userObject.user_type ? userObject.user_type : "AGENT"}
                    >
                      <option value="AGENT">Agent</option>
                      <option value="MANGR">Manager</option>
                      <option value="ADMIN">Admin</option>
                    </AvField>
                  </Col>
                  <Col>
                    <AvField
                      id="gender"
                      name="gender"
                      label="Gender"
                      className="form-control"
                      type="select"
                      required
                      value={userObject.gender ? userObject.gender : "MALE"}
                    >
                      <option value="">----------</option>
                      <option value="MALE">Male</option>
                      <option value="FEML">Female</option>
                      <option value="NSAY">Not to say</option>
                    </AvField>
                  </Col>
                </Row>
                <div className="mt-4 text-end">
                  {loading ?
                    <button className="btn btn-primary btn-block" disabled>
                      Submit{" "}
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    </button>
                    :
                    <button
                      className="btn btn-primary btn-block "
                      type="submit"
                    >
                      Submit
                    </button>
                  }
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default addUsers
