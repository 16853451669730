import React, { useEffect, useState } from 'react'
import "assets/scss/custom/components/_chip.scss"
import moment from 'moment'


const TableChipContainer = props => {
  const [urlParamKeys, setUrlParamKeys] = useState([])
  const [chipFilters, setChipFilters] = useState({})

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search)
    let keys = []
    for (const key of urlParams.keys()) {
      keys.push(key)
    }
    setUrlParamKeys(keys)
    setChipFilters(props.filtersCopy)
  }, [props.filtersCopy])

  const resetFilterState = (key) => {
    let tObj = {...chipFilters}
    let filterObject = tObj[key]
    if (["singlerange"].includes(filterObject.type)) {
      tObj[key]["value"] = filterObject.max
    } else if (["text", "dropdown", "radio"].includes(filterObject.type)) {
      tObj[key]["value"] = filterObject.max
    } else if (["checkbox", ].includes(filterObject.type)) {
      tObj[key]["value"] = filterObject.data.map(item => {item["checked"]=false; return item})
    } else if (["daterange", ].includes(filterObject.type)) {
      tObj[key]["value"] = []
    }
    props.removeChip(tObj)
  }

  return (
    <React.Fragment>
    {(urlParamKeys && chipFilters) && Object.entries(chipFilters).map(([key, data], idx) => 
      urlParamKeys.includes(Array.isArray(data.apiKey) ? data.apiKey[0] : data.apiKey) ?
        <div className="chip" key={idx}>
          {(["singlerange"].includes(data.type) && data.proxy==="time") ? 
            <span><b><span style={{ textTransform: 'capitalize' }}>{data.label}:</span></b> {`${moment.utc(Math.ceil(data.value)*1000).format('m:ss')} mins`}</span>
          : ["daterange", "dualrange"].includes(data.type) ? 
            <span><b><span style={{ textTransform: 'capitalize' }}>{data.label ? `${data.label}: ` : ""}</span></b>{`${data.value[0]} - ${data.value[1]}`}</span> 
          : ["text", "radio", "singlerange", "dropdown"].includes(data.type) ?
            <span><b><span style={{ textTransform: 'capitalize' }}>{data.label}:</span></b> {data.value}</span>
          : ["checkbox"].includes(data.type) ?
            <span><b><span style={{ textTransform: 'capitalize' }}>{data.label}:</span></b> {data.data.map(item => (item.checked && item.data) ? item.data.map(innItem => innItem.checked && `${item.label}(${innItem.label})`) : item.checked ? item.label : false).filter(item => item).join(", ")}</span>
          : null }
          <span className="closebtn" onClick={() => resetFilterState(key)}>&times;</span>
        </div>
      : null
    )}
    </React.Fragment>
  )
}

export default TableChipContainer;