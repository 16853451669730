import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

const InActive = (props) => {
  const urlParams = new URLSearchParams(props.location.search);
  const status = Object.fromEntries(urlParams);
  const [data, setData] = useState(status)
  return (
    <React.Fragment>
      <MetaTags>
        <title>In active user | Yanthura</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">

        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx bx-error h1 mb-0 text-danger"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Your account is {data.code==='OVRF' ? 'On Verification'.toLowerCase() : data.code==='ECNF' ? 'waiting for the email confirmation'  : 'Suspended'.toLowerCase()}</h4>
                        <p className="text-muted">
                          {data.code==='ECNF' ?
                            "Please check your mail and click on the activation link. Incase the link is expired please contact your administrator to resend the new activation link"
                          :
                            "Your account will be ativated after your administrator verified. Please contact your administrator"
                          }
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-primary">
                            Go back to login
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
              {new Date().getFullYear()} © Yanthura. All rights reserved by <a href="https://yanthura.com" target='_blank'>yanthura</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InActive
