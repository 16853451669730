import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import OtpSaga from "./auth/otp/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import statsSaga from "./stats/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
// Teja added
import dashboardHome from "./dashboard-home/saga";
import manageSaga from "./manage/saga"
import commonSaga from "./common/saga"
import settingsSaga from "./settings/saga"
import callDetailSaga from "./call-detail/saga"
import proformaSaga from "./proforma/saga"
import configsSaga from "./config/saga"

// Calls page
import callSaga from "./call/saga"

// Leads
import leadsSaga from "./lms/saga"
// HMS
import hmsSaga from "./hms/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(OtpSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    // Teja added
    fork(dashboardHome),
    fork(manageSaga),
    fork(commonSaga),
    fork(settingsSaga),
    fork(callDetailSaga),
    fork(proformaSaga),
    fork(configsSaga),
    fork(callSaga),
    fork(statsSaga),
    fork(leadsSaga),
    fork(hmsSaga),
  ])
}
