import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { listHms, resetHmsState } from "store/actions"
import DiagnosisHomeDetail from "./home-details"
import DiagnosisHome from "./home"

const DiagnosisIndex = (props) => {
  const [activeTab, setActiveTab] = useState(null);
  const [configuration, setConfiguration] = useState({})
  const dispatch = useDispatch()

  const { hmsList, apiKey, postResponse, updateResponse } = useSelector((state) => ({
    hmsList: state.Hms.hmsList,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
  }))

  useEffect(() => {
    if (apiKey === "fetch_diagnosis_configuration") {
      setConfiguration(hmsList?.results?.[0])
      setActiveTab(hmsList?.results?.length > 0 ? "DiagnosisHomeDetail" : "DiagnosisHome")
    }
    if (apiKey === "configuration_diagnosis_post") {
      setConfiguration(postResponse)
      setActiveTab("DiagnosisHome")
    }
    if (apiKey === "configuration_diagnosis_update") {
      setConfiguration(updateResponse)
      setActiveTab("DiagnosisHome")
    }

  }, [apiKey])

  useEffect(() => {
    dispatch(listHms("hms/diagnosis-configuration/", null, "fetch_diagnosis_configuration"))
    return () => dispatch(resetHmsState())
  }, [])

  return activeTab === "DiagnosisHomeDetail" ? (
    <DiagnosisHomeDetail
      configuration={configuration}
      setActiveTab={setActiveTab}
    />
  ) : activeTab === "DiagnosisHome" ? (
    <DiagnosisHome
      configuration={configuration}
      setActiveTab={setActiveTab}
    />
  ) : null
}

export default DiagnosisIndex
