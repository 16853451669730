import {
  API_SUCCESS,
  API_FAIL,
  GET_DASHBOARD_DATA,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  ALL_USERS,
  AGENT_DATA,
  AGENT_DATA_SUCCESS,
  AGENT_DATA_FAIL,
  BRANCH_OPTIONS,
  BRANCH_OPTIONS_SUCCESS,
  BRANCH_OPTIONS_FAIL,
  BRANCH_GROUPS_OPTIONS,
  BRANCH_GROUPS_OPTIONS_SUCCESS,
  BRANCH_GROUPS_OPTIONS_FAIL,
  GET_BRANCH_DATA,
  GET_BRANCH_DATA_SUCCESS,
  GET_BRANCH_DATA_FAIL,
  GET_PENDING_REQUESTS,
  GET_PENDING_REQUESTS_FAIL,
  GET_PENDING_REQUESTS_SUCCESS,
  UPDATE_PENDING_REQUESTS,
  UPDATE_PENDING_REQUESTS_FAIL,
  UPDATE_PENDING_REQUESTS_SUCCESS,
  GET_DYNAMIC_USER_OPTIONS,
  GET_DYNAMIC_USER_OPTIONS_SUCCESS,
  GET_DYNAMIC_USER_OPTIONS_FAIL,
  RESEND_ACTIVATIONLINK,
  RESEND_ACTIVATIONLINK_SUCCESS,
  RESEND_ACTIVATIONLINK_FAIL,
  GET_USER_OPTIONS,
  GET_USER_OPTIONS_SUCCESS,
  GET_USER_OPTIONS_FAIL
} from "./actionTypes";

export const getDashboardData = (queryParamObject = null) => ({
  type: GET_DASHBOARD_DATA,
  queryParamObject
});
export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
});
export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
});

export const getBranchData = (branchUID, queryParamObject = null) => ({
  type: GET_BRANCH_DATA,
  branchUID,
  queryParamObject
})
export const getBranchDataSuccess = (actionType, data) => ({
  type: GET_BRANCH_DATA_SUCCESS,
  payload: { actionType, data },
});
export const getBranchDataFail = (actionType, error) => ({
  type: GET_BRANCH_DATA_FAIL,
  payload: { actionType, error },
});


export const getUserOptions = (params) => ({
  type: GET_USER_OPTIONS,
  params
})
export const getUserOptionsSuccess = userOptions => ({
  type: GET_USER_OPTIONS_SUCCESS,
  payload: userOptions,
})
export const getUserOptionsFail = error => ({
  type: GET_USER_OPTIONS_FAIL,
  payload: error,
})


export const allUsers = queryParamObject => ({
  type: ALL_USERS,
  queryParamObject
});
export const allUsersSuccess = (actionType, data) => ({
  type: ALL_USERS_SUCCESS,
  payload: { actionType, data },
});
export const allUsersFail = (actionType, error) => ({
  type: ALL_USERS_FAIL,
  payload: { actionType, error },
});


export const getAgentData = (userID, queryParamObject = null) => ({
  type: AGENT_DATA,
  userID,
  queryParamObject
})
export const getAgentDataSuccess = (actionType, data) => ({
  type: AGENT_DATA_SUCCESS,
  payload: { actionType, data },
});
export const getAgentDataFail = (actionType, error) => ({
  type: AGENT_DATA_FAIL,
  payload: { actionType, error },
});

export const getBranchOptions = () => ({
  type: BRANCH_OPTIONS,
})
export const getBranchOptionsSuccess = (actionType, data) => ({
  type: BRANCH_OPTIONS_SUCCESS,
  payload: { actionType, data },
});
export const getBranchOptionsFail = (actionType, error) => ({
  type: BRANCH_OPTIONS_FAIL,
  payload: { actionType, error },
});

export const getBranchGroupsOptions = branchUID => ({
  type: BRANCH_GROUPS_OPTIONS,
  branchUID
})
export const getBranchGroupsOptionsSuccess = (actionType, data) => ({
  type: BRANCH_GROUPS_OPTIONS_SUCCESS,
  payload: { actionType, data },
});
export const getBranchGroupsOptionsFail = (actionType, error) => ({
  type: BRANCH_GROUPS_OPTIONS_FAIL,
  payload: { actionType, error },
});


export const getPendingRequestsUserTypes = payload => ({
  type: GET_DYNAMIC_USER_OPTIONS,
  payload
})
export const getPendingRequestsUserTypesSuccess = (actionType, types) => ({
  type: GET_DYNAMIC_USER_OPTIONS_SUCCESS,
  payload: types,
})
export const getPendingRequestsUserTypesFail = (actionType, error) => ({
  type: GET_DYNAMIC_USER_OPTIONS_FAIL,
  payload: error,
})

export const getPendingRequests = filterParams => ({
  type: GET_PENDING_REQUESTS,
  filterParams
})
export const getPendingRequestsSuccess = (actionType, pendingRequests) => ({
  type: GET_PENDING_REQUESTS_SUCCESS,
  payload: pendingRequests,
})
export const getPendingRequestsFail = (actionType, error) => ({
  type: GET_PENDING_REQUESTS_FAIL,
  payload: error,
})

export const resendActivationLink = usernames => ({
  type: RESEND_ACTIVATIONLINK,
  usernames
})
export const resendActivationLinkSuccess = (actionType, successDataResendActLinks) => ({
  type: RESEND_ACTIVATIONLINK_SUCCESS,
  payload: successDataResendActLinks,
})
export const resendActivationLinkFail = (actionType, error) => ({
  type: RESEND_ACTIVATIONLINK_FAIL,
  payload: error,
})

export const updatePendingRequests = (usernames) => ({
  type: UPDATE_PENDING_REQUESTS,
  usernames
})
export const updatePendingRequestsSuccess = (actionType, usernames) => ({
  type: UPDATE_PENDING_REQUESTS_SUCCESS,
  payload: usernames,
})
export const updatePendingRequestsFail = (actionType, error) => ({
  type: UPDATE_PENDING_REQUESTS_FAIL,
  payload: error,
})