import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { getCallInDepthDataFail, getCallInDepthDataSuccess, getWaveformDataFail, getWaveformDataSuccess } from "store/actions";
import { getScoresFail, getScoresSuccess } from "./actions";
import { GET_CALL_DETAIL_IN_DEPTH, GET_CALL_DETAIL_WAVEFORM, GET_SCORES } from "./actionTypes";
import { getCallInDepthDetailAPI, getScoresAPI, getTranscriptAPI } from "./api_helper";


function* fetchTranscript({ callID }) {
    try {
        const response = yield call(getTranscriptAPI, callID)
        yield put(getWaveformDataSuccess(response))
    } catch (error) {
        yield put(getWaveformDataFail(error.response.data))
    }
}

function* fetchCallInDepthDetail({ callID }) {
    try {
        const response = yield call(getCallInDepthDetailAPI, callID)
        yield put(getCallInDepthDataSuccess(response))
    } catch (error) {
        yield put(getCallInDepthDataFail(error.response.data))
    }
}

function* fetchScores({ callID }) {
    try {
        const response = yield call(getScoresAPI, callID)
        yield put(getScoresSuccess(response))
    } catch (error) {
        yield put(getScoresFail(error.response.data))
    }
}

function* callDetailSaga() {
    yield takeEvery(GET_CALL_DETAIL_WAVEFORM, fetchTranscript)
    yield takeEvery(GET_CALL_DETAIL_IN_DEPTH, fetchCallInDepthDetail)
    yield takeEvery(GET_SCORES, fetchScores)
}

export default callDetailSaga
