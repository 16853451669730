export const GET_CALL_DETAIL_WAVEFORM = "GET_CALL_DETAIL_WAVEFORM";
export const GET_CALL_DETAIL_WAVEFORM_SUCCESS = "GET_CALL_DETAIL_WAVEFORM_SUCCESS";
export const GET_CALL_DETAIL_WAVEFORM_FAIL = "GET_CALL_DETAIL_WAVEFORM_FAIL";

export const GET_CALL_DETAIL_IN_DEPTH = "GET_CALL_DETAIL_IN_DEPTH";
export const GET_CALL_DETAIL_IN_DEPTH_SUCCESS = "GET_CALL_DETAIL_IN_DEPTH_SUCCESS";
export const GET_CALL_DETAIL_IN_DEPTH_FAIL = "GET_CALL_DETAIL_IN_DEPTH_FAIL";

export const GET_SCORES = "GET_SCORES";
export const GET_SCORES_SUCCESS = "GET_SCORES_SUCCESS";
export const GET_SCORES_FAIL = "GET_SCORES_FAIL";