import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row, CardBody, Label } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { resetHmsState, updateHms, postHms, getHms } from "store/actions"
import toastr from "toastr"
import { Loader } from "pages/HMS/common/common"
import { AvForm } from "availity-reactstrap-validation"
import { EAvFieldCheck } from "pages/HMS/common/errored-avfields"

const Notifications = () => {
  const dispatch = useDispatch()
  const [settings, setSettings] = useState(null)

  const { loading, apiKey, error, hmsObject, postResponse, updateResponse } = useSelector((state) => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
  }))

  useEffect(() => {
    if (apiKey === "fetch_settings") {
      setSettings(hmsObject)
    }
    if (apiKey === "settings_created") {
      toastr.success("Settings created")
      setSettings(postResponse)
    }
    if (apiKey === "settings_updated") {
      toastr.success("Settings updated")
      setSettings(updateResponse)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHms("/hms/hms-settings/", "unique", "fetch_settings"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  const handleCheck = (e) => {
    const { name, checked } = e.target
    const payload = { [name]: checked }

    if (settings?.id) {
      dispatch(updateHms("/hms/hms-settings/", "unique", payload, "settings_updated"))
    } else {
      dispatch(postHms("/hms/hms-settings/", { ...payload, type: "unique" }, "settings_created", false))
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs="12">
            <Card>
              <CardBody>
                {loading && apiKey === "get_fetch_settings" ? (
                  <Loader />
                ) : (
                  <AvForm>
                    <Label>Notifications</Label>
                    <hr className="mt-0" />
                    <Row>
                      <Col>
                        <EAvFieldCheck
                          name="sms_notifications"
                          defaultValue={true}
                          checked={settings?.sms_notifications}
                          options={{ label: "SMS" }}
                          onChange={handleCheck}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <EAvFieldCheck
                          name="email_notifications"
                          defaultValue={true}
                          checked={settings?.email_notifications}
                          options={{ label: "Email" }}
                          onChange={handleCheck}
                        />
                      </Col>
                    </Row>
                  </AvForm>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Notifications
