import {
    ADD_PROFORMA,
    ADD_PROFORMA_FAIL,
    ADD_PROFORMA_SUCCESS,
    ADD_STATEMENT,
    ADD_STATEMENT_FAIL,
    ADD_STATEMENT_SUCCESS,
    DELETE_PROFORMA,
    DELETE_PROFORMA_FAIL,
    DELETE_PROFORMA_SUCCESS,
    DELETE_STATEMENT,
    DELETE_STATEMENT_FAIL,
    DELETE_STATEMENT_SUCCESS,
    GET_PROFORMA_DETAIL,
    GET_PROFORMA_DETAIL_FAIL,
    GET_PROFORMA_DETAIL_SUCCESS,
    GET_PROFORMA_LIST, GET_PROFORMA_LIST_FAIL, GET_PROFORMA_LIST_SUCCESS, UPDATE_PROFORMA, UPDATE_PROFORMA_FAIL, UPDATE_PROFORMA_SUCCESS, UPDATE_STATEMENT, UPDATE_STATEMENT_FAIL, UPDATE_STATEMENT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    statement: {},
    proformaList: [],
    statement: {},
    statements: [],
    detailError: null,
    formError: null
};

const Proforma = (state=INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROFORMA_LIST:
            return {
                ...state,
                detailError: null,
            }
        case GET_PROFORMA_LIST_SUCCESS:
            return {
                ...state,
                proformaList: action.payload,
                detailError: null,
            }
        case GET_PROFORMA_LIST_FAIL:
            return {
                ...state,
                detailError: action.payload.detail,
            }
        case ADD_PROFORMA:
            return {
                ...state,
                formError: null,
            }
        case ADD_PROFORMA_SUCCESS:
            return {
                ...state,
                formError: null,
                proforma: action.payload,
            }
        case ADD_PROFORMA_FAIL:
            return {
                ...state,
                formError: action.payload,
            }
        case UPDATE_PROFORMA:
            return {
                ...state,
                formError: null,
            }
        case UPDATE_PROFORMA_SUCCESS:
            return {
                ...state,
                formError: null,
                proforma: action.payload,
            }
        case UPDATE_PROFORMA_FAIL:
            return {
                ...state,
                formError: action.payload,
            }
        case DELETE_PROFORMA:
            return {
                ...state,
                detailError: null,
            }  
        case DELETE_PROFORMA_SUCCESS:
            return {
                ...state,
                detailError: null,
                proforma: action.payload,
            }
        case DELETE_PROFORMA_FAIL:
            return {
                ...state,
                detailError: action.payload,
            }
        
        case GET_PROFORMA_DETAIL:
            return {
            ...state,
            detailError: null,
            }
        case GET_PROFORMA_DETAIL_SUCCESS:
            return {
                ...state,
                statements: action.payload,
                detailError: null,
            }
        case GET_PROFORMA_DETAIL_FAIL:
            return {
                ...state,
                detailError: action.payload,
            }
        
        case ADD_STATEMENT:
            return {
                ...state,
                formError: null,
            }
        case ADD_STATEMENT_SUCCESS:
            return {
                ...state,
                formError: null,
                statement: action.payload,
            }
        case ADD_STATEMENT_FAIL:
            return {
                ...state,
                formError: action.payload,
            }
        case UPDATE_STATEMENT:
            return {
                ...state,
                formError: null,
            }
        case UPDATE_STATEMENT_SUCCESS:
            {
                let tObj = state.statements
                tObj.results = tObj.results.map(stmt => stmt.id.toString() === action.payload.id.toString() ? { stmt, ...action.payload } : stmt)
                return {
                    ...state,
                    formError: null,
                    statements: tObj,
                }
            }
        case UPDATE_STATEMENT_FAIL:
            return {
                ...state,
                formError: action.payload,
            }
        case DELETE_STATEMENT:
            return {
                ...state,
                detailError: null,
            }  
        case DELETE_STATEMENT_SUCCESS:
            return {
                ...state,
                detailError: null,
                statement: action.payload,
            }
        case DELETE_STATEMENT_FAIL:
            return {
                ...state,
                detailError: action.payload,
            }
        default:
            return state;
    }
}

export default Proforma;