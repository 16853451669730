import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

import profileImg from "assets/images/profile-img.png"
import DefaultIfNone from "components/Common/default";
import moment from "moment";

function CardUser(props) {
  const { data } = props

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  {data.user &&
                  <Media>
                    {!data.user.avatar ? (
                      <div className="avatar-sm me-3">
                        <span className="avatar-title rounded-circle bg-soft bg-success text-success font-size-18">
                          {data.user.full_name ? data.user.full_name.charAt(0).toUpperCase() : 'A'}
                        </span>
                      </div>
                    ) : (
                      <div className="me-3">
                        <img
                          src={data.user.avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                    )}
                    <Media className="align-self-center" body>
                      <div className="text-muted">
                        <p className="mb-2"><span className="bg-light font-size- badge">#{data.user.username}</span></p>
                        <h5 className="mb-1"><DefaultIfNone value={data.user.full_name} default_value="No name" /></h5>
                        <p className="mb-0"><DefaultIfNone value={data.user.phone_number} default_value="No phonenumber" /></p>
                        <p className="mb-0"><DefaultIfNone value={data.user.email} default_value="No email" /></p>
                      </div>
                    </Media>
                  </Media>
                  }
                </Col>

                <Col lg="8" className="align-self-center">
                  {data.call_counts &&
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="2">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Calls
                          </p>
                          <h5 className="mb-0"><DefaultIfNone format={true} value={data.call_counts.total} default_value={0} /> <small className="text-muted">/{data.call_counts.overall_total}</small></h5>
                        </div>
                      </Col>
                      <Col xs="2">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Good
                          </p>
                          <h5 className="mb-0"><DefaultIfNone value={data.call_counts.good} default_value={0} /></h5>
                        </div>
                      </Col>
                      <Col xs="2">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Bad
                          </p>
                          <h5 className="mb-0"><DefaultIfNone value={data.call_counts.bad} default_value={0} /></h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            Avg. Call Time
                          </p>
                          <h5 className="mb-0">
                            {data.call_counts.avg_handling_time<60 ?
                            moment.utc(data.call_counts.avg_handling_time*1000).format('s[s]') : 
                            (data.call_counts.avg_handling_time>=60 || data.call_counts.avg_handling_time<3600) ? 
                            moment.utc(data.call_counts.avg_handling_time*1000).format('m[m] ss[s]') : 
                            moment.utc(data.call_counts.avg_handling_time*1000).format('h[h] m[m] ss[s]')}
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xl="4">
          <Card className="bg-primary bg-soft">
            <div>
              <Row>
                <Col xs="7">
                  <div className="text-primary p-3">
                    <p>Coach Notes</p>
                    <h5 className="text-primary"><DefaultIfNone value={0} default_value={0} /></h5>

                    <ul className="ps-3 mb-0">
                      <li className="py-1"><DefaultIfNone value={0} default_value={0} /> Reviewed</li>
                      <li className="py-1"><DefaultIfNone value={0} default_value={0} /> Verified</li>
                    </ul>
                  </div>
                </Col>
                <Col xs="5" className="align-self-end">
                  <img src={profileImg} alt="" className="img-fluid" />
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col lg="8">
          {data.call_counts &&
          <Row>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-camera-home" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Score</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>
                      <DefaultIfNone value={data.call_counts.score} default_value={0} />
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-smile" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Sentiment</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>
                      <DefaultIfNone value={data.call_counts.sentiment} default_value={0} />
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="4">
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className="bx bx-microphone-off" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">Hold Time</h5>
                  </div>
                  <div className="text-muted mt-4">
                    <h4>
                      <DefaultIfNone value={"0s"} default_value={0} />
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          }
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
