import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
} from "reactstrap"
import {
  getLead as onGetLead,
  resetState,
  updateLeads as onUpdateLead,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux";
import Editable from "react-bootstrap-editable"
import toastr from "toastr";
import { isEmpty } from "lodash";
import moment from "moment";


export const LeadInfo = (props) => {
  const { leadID, columns, statusOptions } = props
  const dispatch = useDispatch()
  const [columnNames, setColumnNames] = useState({})
  const [status, setStatus] = useState(null);

  const { loading, error, formError, leadObject, isLeadUpdated } = useSelector(state => ({
    loading: state.Lms.loading,
    error: state.Lms.error,
    formError: state.Lms.formError,
    leadObject: state.Lms.leadObject,
    isLeadUpdated: state.Lms.isLeadUpdated
  }))

  useEffect(() => {
    return () => {dispatch(resetState())}
  }, [])

  useEffect(() => {
    if (isLeadUpdated) toastr.success("Lead is updated")
    if (!isEmpty(formError)) toastr.error(Object.entries(formError).map(([key, value]) => 
      `${key.replace("_", " ").toUpperCase()}: ${value[0]}\n`
    ), )
  }, [isLeadUpdated, formError])

  const handleStatus = (e) => {
    setStatus(e.target.value)
    dispatch(onUpdateLead(leadID, { status: e.target.value }))
  }

  const handleFollowupDate = (e) => {
    let datetime = new Date(e.target.value).toJSON()
    dispatch(onUpdateLead(leadID, { followup_date: datetime }))
  }

  const handleComments = (value) => {
    dispatch(onUpdateLead(leadID, { comments: value }))
  }

  useEffect(() => {
    setColumnNames(columns.reduce((obj, item) => ({ ...obj, [item.value]: item.label }), {}))
    dispatch(onGetLead(leadID))
  }, [dispatch])

  const confirmElement = (
    <button type="submit" className="btn editable-submit btn-sm me-1"><i className="mdi mdi-check"></i></button>
  )
  const cancelElement = (
    <button type="button" className="btn editable-cancel btn-sm"><i className="mdi mdi-close"></i></button>
  )

  return (
    <React.Fragment>
      {loading ?
        <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching Lead...</p>
        :
        <>
          {error ?
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
            :
            <table>
              <tbody>
                {Object.entries(leadObject).map(([field, value], idx) => {
                  let columnName = columnNames[field]
                  columnName = columnName === undefined ? field.replace("_", " ").toUpperCase() : columnName
                  if (["assigned_to", "modified_by"].includes(field) && value===null) {
                    return
                  } else if (field === "followup_date") {
                    return (<tr key={idx}>
                      <td><b>{columnName}</b></td>
                      <td className="ps-3">
                        <input
                          name={field}
                          className="form-control"
                          type="datetime-local"
                          value={value ? moment(value).format("YYYY-MM-DDTHH:mm") : ""}
                          min={moment(new Date()).format("YYYY-MM-DDTHH:mm")}
                          id={`id_${field}`}
                          onChange={handleFollowupDate}
                        /></td>
                    </tr>)
                  } else if (field === "comments") {
                    return (<tr key={idx}>
                      <td><b>{columnName}</b></td>
                      <td className="ps-3">
                        <Editable
                          ajax={null}
                          className={null}
                          alwaysEditing={false}
                          disabled={false}
                          editText={value ? value : "Click To Comment"}
                          id={null}
                          isValueClickable={false}
                          label={null}
                          mode="inline"
                          onSubmit={handleComments}
                          onValidated={null}
                          options={null}
                          placement="top"
                          renderCancelElement={cancelElement}
                          renderConfirmElement={confirmElement}
                          showText={false}
                          initialValue={value}
                          type="textarea"
                          validate={null}
                        />
                      </td>
                    </tr>)
                  } else if (field === "status") {
                    return (
                      <tr key={idx}>
                        <td><b>{columnName}</b></td>
                        <td className="ps-3">
                          <select name={field} value={status ? status : value} onChange={handleStatus}>
                            <option value="">------------</option>
                            {statusOptions && statusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    )
                  } else if (["string", "number"].includes(typeof (value))) {
                    return <tr key={idx}><td><b>{columnName}</b></td><td className="ps-3">{value}</td></tr>
                  } else if (typeof (value) === "object" && ["created_by", "modified_by", "assigned_to"].includes(field)) {
                    return (<tr key={idx}>
                      <td><b>{columnName}</b></td>
                      <td className="ps-3" title={value && value.email}>{value && value.full_name}</td>
                    </tr>)
                  }
                })}
              </tbody>
            </table>
          }
        </>}
    </React.Fragment>
  )
}

export default LeadInfo;