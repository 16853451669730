import {
  OTP,
  OTP_SUCCESS,
  OTP_ERROR,
} from "./actionTypes"

export const userOtp = (user) => {
  console.log('user otp user===> ', user)
  return {
    type: OTP,
    payload: { user },
  }
}

export const userOtpSuccess = message => {
  console.log('userotpsuccess===> ', message);
  return {
    type: OTP_SUCCESS,
    payload: message,
  }
}

export const userOtpError = message => {
  console.log('userotperror===> ', message);
  return {
    type: OTP_ERROR,
    payload: message,
  }
}
