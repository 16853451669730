import { call, put, takeLatest } from "redux-saga/effects";

import { MY_STATS, MY_STATS_FAIL, MY_STATS_SUCCESS } from "./actionTypes";
import { myStatsFail, myStatsSuccess } from "./actions";
import { myStatsAPI } from "./api_helper";

function* myStatsSaga({payload}) {
    try {
        var response = yield call(myStatsAPI, payload)
        yield put(myStatsSuccess(MY_STATS_SUCCESS, response))
    } catch (error) {
        yield put(myStatsFail(MY_STATS_FAIL, error.response.data))
    }
}

function* statsSaga() {
    yield takeLatest(MY_STATS, myStatsSaga)
}
export default statsSaga
