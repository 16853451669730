import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { OTP_SEND, OTP_RESEND, OTP_VERIFY, FORGET_PASSWORD } from "./actionTypes"
import { otpSendSuccess, otpSendError, otpResendSuccess, otpResendError, otpVerifySuccess, otpVerifyError, userForgetPasswordSuccess, userForgetPasswordError } from "./actions"
import { otpSendAPI, otpResendAPI, otpVerifyAPI, resetPasswordAPI } from "./api_helper"

function* otpSendSaga({ payload: requestPayload }) {
  try {
      const response = yield call(otpSendAPI, requestPayload)
      yield put(otpSendSuccess(response))
  } catch (error) {
      yield put(otpSendError(error.response.data))
  }
}

function* otpResendSaga({ payload: requestPayload }) {
  try {
      const response = yield call(otpResendAPI, requestPayload)
      yield put(otpResendSuccess(response))
  } catch (error) {
      yield put(otpResendError(error.response.data))
  }
}

function* otpVerifySaga({ payload: requestPayload }) {
  try {
      const response = yield call(otpVerifyAPI, requestPayload)
      yield put(otpVerifySuccess(response))
  } catch (error) {
      yield put(otpVerifyError(error.response.data))
  }
}

function* forgetUser({ payload: requestPayload }) {
  try {
      const response = yield call(resetPasswordAPI, requestPayload)
      yield put(userForgetPasswordSuccess(response))
  } catch (error) {
      console.log(error.response);
      yield put(userForgetPasswordError(error.response.data))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(OTP_SEND, otpSendSaga)
  yield takeEvery(OTP_RESEND, otpResendSaga)
  yield takeEvery(OTP_VERIFY, otpVerifySaga)
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
