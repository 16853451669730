import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DigitalSignature = ({ modal, toggle, onSave, signature }) => {
  const sigCanvas = useRef({});

  const handleClear = () => {
    sigCanvas.current.clear();
  };

  const handleSave = () => {
    const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    onSave(dataUrl);
    toggle();
  };

  const handleOpened = () => {
    if (signature) {
      sigCanvas.current.fromDataURL(signature);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" onOpened={handleOpened}>
        <ModalHeader toggle={toggle}>Draw your signature</ModalHeader>
        <ModalBody>
          <SignatureCanvas
            penColor={"black"}
            ref={sigCanvas}
            canvasProps={{ className: 'sig-canvas' }}
            backgroundColor="rgba(255,255,255,1)"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClear}>Clear</Button>
          <Button color="primary" onClick={handleSave}>Save</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DigitalSignature;
