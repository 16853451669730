import DefaultIfNone from "components/Common/default";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";


const Admin = (props) => {
  const { data } = props

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Row>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total No. Of Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.total} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total Unassigned Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.total_unassigned} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total Assigned Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.total_assigned} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total Remainders</p>
                        <h5 className="mb-0"><DefaultIfNone value={data.total_remainders} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl={12}>
          <Row>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total Followups</p>
                        <h5 className="mb-0"><DefaultIfNone value={data.total_followups} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Qualified Leads</p>
                        <h5 className="mb-0"><DefaultIfNone value={data.qualified} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Today Qualified Leads</p>
                        <h5 className="mb-0"><DefaultIfNone value={data.today_qualified} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Today Followups</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.today_followups} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Today Remainders</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.today_reminders} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Not Interested</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.not_interested} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">No Response</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.no_response} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Disqualified</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.disqualified} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default Admin;


export const Agent = (props) => {
  const { data } = props

  return (
    <React.Fragment>
      <Row>
        <Col xl={12}>
          <Row>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Today Assigned Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.today_assigned} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total No. of Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.total} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Today Remainders</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.today_reminders} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total Followups</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.total_followups} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl={12}>
          <Row>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Not Interested Leads</p>
                        <h5 className="mb-0"><DefaultIfNone value={data.not_interested} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">No Response</p>
                        <h5 className="mb-0"><DefaultIfNone value={data.no_response} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Today Qualified Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.today_qualified} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Today Pending Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.today_pending} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Total Pending Leads</p>
                        <h5 className="mb-0"><DefaultIfNone format={true} value={data.total_pending} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="blog-stats-wid">
                <CardBody>
                  <Link to="#">
                    <div className="d-flex flex-wrap">
                      <div className="me-3">
                        <p className="text-muted mb-2">Qualified Leads</p>
                        <h5 className="mb-0"><DefaultIfNone value={data.qualified} default_value={0} /></h5>
                      </div>
                    </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}