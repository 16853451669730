import { getLocalstorage } from "components/Common/common"

var client = null

export const WebsocketConnection = (ws_url) => {
  if (client!==null) {
    client.close()
    console.log("Connection closed...");
  }
  const protocol = location.protocol === "https:" ? "wss:" : "ws:"
  const WS_URL = `${protocol}//${process.env.REACT_APP_SERVER_ADDRESS}/rtc${ws_url}?key=${getLocalstorage("authUser").access}`
  client = new WebSocket(WS_URL)
  return client
}

export const getWebsocketConnection = () => {
  return client
}