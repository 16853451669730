import React, { useEffect, useRef, useState } from "react"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledAlert,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { useSelector, useDispatch } from "react-redux"

import {
  getServers as onGetServers,
  postServer as onPostServer,
  updateServer as onUpdateServer,
  deleteServer as onDeleteServer
} from "store/actions"
import { Link } from "react-router-dom"
import toastr from "toastr"


const Servers = props => {
  const dispatch = useDispatch()
  const formRef = useRef()

  const { formError, detailError, servers, updatedServer, createdServer, loading } = useSelector(state => ({
    formError: state.Settings.formError,
    detailError: state.Settings.detailError,
    servers: state.Settings.servers,
    updatedServer: state.Settings.updatedServer,
    createdServer: state.Settings.createdServer,
    loading: state.Settings.loading,
  }))

  const [modal, setModal] = useState(false)
  const [serverList, setServerList] = useState([])
  const [rudFlag, setRUDFlag] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)

  const pageOptions = {
    custom: true,
    page: currentPage,
    totalSize: servers.count,
    sizePerPage: serverList.length,
    hidePageListOnlyOnePage: true
  }
  const { SearchBar } = Search

  const columns = [
    {
      dataField: "name",
      text: "Server",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/services/${row.name}`}>
          <p className="text-primary">{row.name}</p>
        </Link>
      ),
    }, {
      dataField: 'topic',
      text: 'Address',
      sort: true
    }, {
      dataField: "location",
      text: "Location",
      sort: true,
      formatter: (cellContent, row) => (
        row.location.replace(/[^\w\s,.]/g, '')
      )
    }, {
      dataField: 'mac',
      text: 'MAC',
      sort: true
    }, {
      dataField: "action",
      isDummyField: true,
      formatter: (cellContent, row) => (
        <UncontrolledDropdown className="ms-auto">
          <DropdownToggle
            className="text-muted font-size-16"
            color="white"
          >
            <i className="mdi mdi-dots-horizontal"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <Link className="dropdown-item" to="#" onClick={() => handleUpdateServer(row)}>
              Edit
            </Link>
            <Link className="dropdown-item" to="#" onClick={() => handleDeleteServer(row)}>
              Remove
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    }
  ]

  useEffect(() => {
    dispatch(onGetServers())
  }, [dispatch])

  const fetchNewPage = (type, { page, sizePerPage }) => {
    setCurrentPage(page)
    dispatch(onGetServers(`page=${page}`))
  }

  useEffect(() => {
    setServerList(servers)
    if (updatedServer) {
      setModal(!updatedServer)
      toastr.success("Server updated successfully")
    }
    if (createdServer) {
      formRef.current.reset()
      toastr.success("Server created successfully")
    }
  }, [servers])
  
  useEffect(() => {
    if (!isEmpty(servers)) {
      setServerList(servers)
    }
  }, [servers])

  const handleUpdateServer = arg => {
    const server = arg
    setServerList({
      name: server.name,
      location: server.location.replace(/[^\w\s,.]/g, ''),
      mac: server.mac,
    })
    setRUDFlag(0)
    toggle(false)
  }

  const handleServerUpdateSubmit = (e, values) => {
    dispatch(onUpdateServer(serverList.name, values))
  }

  const handleServerSubmit = (e, values) => {
    dispatch(onPostServer(values))
  }

  const handleDeleteServer = arg => {
    setServerList(arg)
    setRUDFlag(2)
    toggle()
  }
  
  function toggle(def=modal) {
    setModal(!def)
  }

  return (
    <div>
      <Row>
        <Col className="col-12">
          <Card>
            <CardTitle className="h4 mt-3 ms-4">Configure new server</CardTitle>
            {formError ?
            <UncontrolledAlert color="danger" role="alert" className="ms-5 me-5">
              {Object.entries(formError).map(([key, value]) =>
                <p key={key}><b>{key==="name"?"Server name":key==="location"?"Location":"MAC Address"}</b>: {value[0]}</p>
              )}
            </UncontrolledAlert>
            : null}
            <AvForm className="form-horizontal m-4 mt-1" onValidSubmit={(e, v) => handleServerSubmit(e, v)} ref={formRef}>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Server name"
                      className="form-control"
                      type="text"
                      errorMessage="Enter a valid server name"
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="location"
                      label="Location"
                      className="form-control"
                      type="text"
                      placeholder="12.345678, 87.654321(Latitude, Longitude)"
                      required
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <AvField
                      name="mac"
                      label="MAC"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <div>
                {loading ?
                <button type="submit" className="btn btn-primary float-end w-md" disabled>
                  Submit{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                </button>
                :
                <button type="submit" className="btn btn-primary float-end w-md">
                  Submit
                </button>
                }
              </div>
            </AvForm>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4">All Servers</CardTitle>
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField='id'
                columns={columns}
                data={servers}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField='id'
                    columns={columns}
                    data={servers}
                    bootstrap4
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <Row className="mb-2 justify-content-end">
                          <Col md="5">
                            <div className="search-box me-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitProps.searchProps}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                // remote
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                // onTableChange={fetchNewPage}
                                noDataIndication={() =>
                                  <p className="text-center display-6">No server is created</p>
                                }
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone
                                {...paginationProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xl="12">
                            <Modal isOpen={modal} toggle={toggle}>
                              <ModalHeader toggle={toggle} tag="h5">
                                {rudFlag === 0 ? "Modify Server" : rudFlag === 1 ? "Add Server" : rudFlag === 2 ? "Delete Server" : ""}
                              </ModalHeader>
                              <ModalBody>
                                {formError ?
                                  <UncontrolledAlert color="danger" role="alert" className="ms-5 me-5">
                                    {Object.entries(formError).map(([key, value]) =>
                                      <p key={key}><b>{key==="name"?"Server name":key==="location"?"Location":"MAC Address"}</b>: {value[0]}</p>
                                    )}
                                  </UncontrolledAlert>
                                : null}
                                <div>
                                  {rudFlag === 2 ?
                                    <div>
                                      <h6 className="mb-4">Are you sure, you want to delete {serverList.name} server ?</h6>
                                      <div className="float-end">
                                        {loading ?
                                        <button className="btn btn-outline-danger btn-sm me-2" disabled>
                                          Yes{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                        </button>
                                        :
                                        <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={() => {
                                            dispatch(onDeleteServer(serverList.name))
                                            toggle()
                                          }}>
                                          Yes
                                        </button>
                                        }
                                        <button
                                          type="button"
                                          className="btn btn-light btn-sm"
                                          onClick={() => toggle()}>
                                          No
                                        </button>
                                      </div>
                                    </div>
                                    :
                                    <AvForm
                                      onValidSubmit={handleServerUpdateSubmit}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <AvField
                                              name="name"
                                              label="Server"
                                              type="text"
                                              errorMessage="Enter a valid server name"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={serverList.name || ""}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="col-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="location"
                                              label="Location"
                                              type="text"
                                              errorMessage="Enter a valid server location"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={serverList.location || ""}
                                            />
                                          </div>
                                        </Col>
                                        <Col className="col-6">
                                          <div className="mb-3">
                                            <AvField
                                              name="mac"
                                              label="MAC Address"
                                              type="text"
                                              errorMessage="Enter a valid server mac address"
                                              validate={{
                                                required: { value: true },
                                              }}
                                              value={serverList.mac || ""}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            {loading ?
                                            <button className="btn btn-primary btn-sm save-user" disabled>
                                              SAVE{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                            </button>
                                            :
                                            <button type="submit" className="btn btn-primary btn-sm save-user">
                                              SAVE
                                            </button>
                                            }
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                  }
                                </div>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>

                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>
                )
                }</PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Servers;
