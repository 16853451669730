import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container, FormGroup, InputGroup, Label, Row } from "reactstrap"
import MetaTags from 'react-meta-tags';

import CardUser from "./CardUser"
import Settings from "./Settings"
import BranchMap from "./Vectormap"
import Flatpickr from "react-flatpickr"

import { useDispatch, useSelector } from "react-redux"
import { getDashboardData as onGetDashboardData } from "store/actions"
import Breadcrumbs from "components/Common/Breadcrumb"
import { isEmpty } from "lodash";
import moment from "moment";
import TopBranches from "./TopBranches";

const index = props => {
  const dispatch = useDispatch()
  const [data, setData] = useState({})

  const { dashboardData, errorData, loading } = useSelector(state => ({
    dashboardData: state.DashboardGenericState.dashboardData,
    errorData: state.DashboardGenericState.errorData,
    loading: state.DashboardGenericState.loading,
  }))

  useEffect(() => {
    dispatch(onGetDashboardData())
  }, [dispatch]);

  useEffect(() => {
    if(!isEmpty(dashboardData)) {
      setData(dashboardData)
    }
  }, [dashboardData]);

  const [period, setPeriod] = useState({
    value: 1,
    unit: 'block_month',    // months
    start: moment.utc().subtract(1, 'months').format('YYYY-MM-DD'),
    end: moment.utc().format('YYYY-MM-DD')
  })
  const dateRangeRef = useRef()
  function getURLParam(urlParams) {
    let urlParam = []
    Object.entries(urlParams).map(([k, v]) => {
      if (k!=='page' || k!==undefined || k!==null || k!=='') {
        if (typeof(v)=='object') {
          urlParam.push(`${k}=${v[0]}&${k}_end=${v[1]}`)
        } else {
          urlParam.push(`${k}=${v}`)
        }
      }
    })
    return urlParam.join('&')
  }

  const onPeriodChange = (period, unit) => {
    let now = moment.utc()
    if (unit.startsWith("block_")) {
      if (unit==="block_today") {
        var end = now
        var start = now
      } else if (unit==="block_yesterday") {
        var end = now.subtract(1, 'days')
        var start = end
      }  else if (unit==="block_month") {
        var end = now
        var start = moment().startOf('month')
      }
    } else {
      if (unit==='days') period = Number(period)-1
      var end = now
      var start = now.subtract(period, unit)
    }
    start = start.format('YYYY-MM-DD')
    end = end.format('YYYY-MM-DD')
    
    let queryParam = getURLParam({
      start: start,
      end: end
    })
    dispatch(onGetDashboardData(queryParam))
    dateRangeRef.current.flatpickr.clear()
    if (period===6 && unit==='days') unit = 'weeks'
    setPeriod({
      value: period,
      unit: unit,
      start: start,
      end: end
    })
  }

  const onChangeDateRange = dateRange => {
    if (!isEmpty(dateRange)) {
      let start = moment(dateRange[0]).format("YYYY-MM-DD")
      let end = moment(dateRange[1]).format("YYYY-MM-DD")
      let queryParam = getURLParam({
        start: start,
        end: end
      })
      dispatch(onGetDashboardData(queryParam))
      setPeriod({
        start: start,
        end: end
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Organization performance" />
          <div className="d-flex flex-row mb-2">
            <div className="ms-auto">
              <InputGroup>
                <Flatpickr
                  className="form-control form-control-sm"
                  placeholder="Select a date range"
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d"
                  }}
                  ref={dateRangeRef}
                  onClose={onChangeDateRange}
                />
              </InputGroup>
            </div>
            <div className="ms-2">
              <Button className="btn-sm" color={period.unit==='block_today' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'block_today')}>Today</Button>
              <Button className="btn-sm" color={period.unit==='block_yesterday' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'block_yesterday')}>Yesterday</Button>
              <Button className="btn-sm" color={period.unit==='block_month' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'block_month')}>Month</Button>
              {/* <Button className="btn-sm" color={period.unit==='days' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'days')}>Day</Button>
              <Button className="btn-sm" color={period.unit==='weeks' ? 'primary' : 'light'} onClick={() => onPeriodChange(7, 'days')}>Week</Button>
              <Button className="btn-sm" color={period.unit==='months' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'months')}>Month</Button>
              <Button className="btn-sm" color={period.unit==='years' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'years')}>Year</Button> */}
            </div>
          </div>
          {errorData ?
            <Card>
              <CardBody>
                <p className="text-center text-danger">{errorData.detail}</p>
              </CardBody>
            </Card>
          :
          <>
            {loading ?
              <p className="text-center mt-5">Fetching results. please wait{" "}<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
            :
              <Row>
                <CardUser data={data} period={period} />
                {!isEmpty(data.branches) &&
                  <TopBranches data={data} />
                }
              </Row>
            }
          </>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
