import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Badge, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { deleteHms, getHms, resetHmsState } from "store/actions";
import { TableChip, formatDateTime } from "components/Common/common";
import { Loader } from "pages/HMS/common/common";
import BillingSummary from "./billing-summary";
import { HmsDeleteModal } from "../common/hms-crud-modals";
import toastr from "toastr";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const TransactionDetail = (props) => {
  const dispatch = useDispatch();
  const [billingDetail, setBillingDetail] = useState({});
  const [dropdown, setDropdown] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const { loading, hmsObject, apiKey, error } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
  }));

  useEffect(() => {
    dispatch(getHms(`/hms/billing/billing/${props.match.params.tnxID}/`, null, "transactions_details"))
    return () => dispatch(resetHmsState())
  }, []);

  useEffect(() => {
    if (apiKey === "transactions_details") setBillingDetail(hmsObject)
    if (apiKey === "transaction_detail_deleted") {
      toastr.success("Transaction is deleted")
      setBillingDetail({})
      toggleDelete()
      window.location.replace("/hms/billing/transactions")
    }
  }, [apiKey]);

  const toggleDelete = (def = deleteModal) => {
    setDeleteModal(!def)
  }

  const handleDelete = () => {
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Transaction details | Yanthura</title>
        </MetaTags>
        {apiKey === "get_fetch-order" ?
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching Transactions...</p>
          :
          <Card>
            <CardBody>
              {loading && apiKey === "get_transactions_details" ? (
                <Loader />
              ) : error ? (
                <p className="text-center text-danger">{error.detail}</p>
              ) : (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between mt-2">
                      <h5>Patient Information {" "} <Badge color="primary" pill>{billingDetail?.module}</Badge></h5>
                      <Dropdown isOpen={dropdown} toggle={() => setDropdown(!dropdown)} direction="down">
                        <DropdownToggle caret color="primary" size="sm">
                          Actions
                          &nbsp;<i className="fa fa-caret-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <Link className="dropdown-item" to={"#"} onClick={handleDelete}>
                            Remove
                          </Link>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <hr />
                    <>
                      <Row>
                        <Col>
                          <TableChip
                            label="Patient name"
                            value={billingDetail?.customer_name}
                            defaultIfNull="NA"
                          />
                        </Col>
                        <Col>
                          <TableChip
                            label="Bill ID"
                            value={billingDetail?.uid}
                            defaultIfNull="NA"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TableChip
                            label="Discount type"
                            value={billingDetail?.discount_type}
                            defaultIfNull="NA"
                          />
                        </Col>
                        <Col>
                          <TableChip
                            label="Discount by"
                            value={billingDetail?.discount_by_name}
                            defaultIfNull="NA"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TableChip
                            label="Billing date"
                            value={formatDateTime(billingDetail?.created_at)}
                            defaultIfNull="NA"
                          />
                        </Col>
                      </Row>
                    </>
                  </Col>
                  <Col>
                    <BillingSummary billingDetail={billingDetail} />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        }
      </div>

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If transaction is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/billing/billing/", billingDetail?.id, "transaction_detail_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment >
  );
};

export default TransactionDetail;
