import { KPI_CREATE, KPI_CREATE_FAIL, KPI_CREATE_SUCCESS, KPI_DELETE, KPI_DELETE_FAIL, KPI_DELETE_SUCCESS, KPI_LIST, KPI_LIST_FAIL, KPI_LIST_SUCCESS, KPI_UPDATE, KPI_UPDATE_FAIL, KPI_UPDATE_SUCCESS } from "./actionTypes"

export const getKPIList = () => ({
  type: KPI_LIST
})
export const getKPIListSuccess = data => ({
  type: KPI_LIST_SUCCESS,
  payload: data,
})
export const getKPIListFail = error => ({
  type: KPI_LIST_FAIL,
  payload: error,
})

export const createKPI = (data) => ({
  type: KPI_CREATE,
  payload: data,
})
export const createKPISuccess = data => ({
  type: KPI_CREATE_SUCCESS,
  payload: data,
})
export const createKPIFail = error => ({
  type: KPI_CREATE_FAIL,
  payload: error,
})

export const updateKPI = (kid, data) => ({
  type: KPI_UPDATE,
  payload: {kid, data},
})
export const updateKPISuccess = data => ({
  type: KPI_UPDATE_SUCCESS,
  payload: data,
})
export const updateKPIFail = error => ({
  type: KPI_UPDATE_FAIL,
  payload: error,
})


export const deleteKPI = kid => ({
  type: KPI_DELETE,
  payload: kid,
})
export const deleteKPISuccess = kid => ({
  type: KPI_DELETE_SUCCESS,
  payload: kid,
})
export const deleteKPIFail = error => ({
  type: KPI_DELETE_FAIL,
  payload: error,
})




