import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Row, Col, CardTitle, Button } from "reactstrap";
import { getHms, getHmsOptions, postHms, postHmsFail, resetHmsState, updateHms } from "store/hms/actions";
import { AvForm } from "availity-reactstrap-validation";
import { get } from "helpers/api_helper";
import SuccessMessage from "./book-appointment-success";
import { isEmpty } from "lodash";
import { AsyncSearch, EAsyncSelect, EAvFieldInput, EAvFieldSelect, ErrorMessage, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields";
import TimeSlotSelector from "./time-slot";
import { useHistory } from "react-router-dom";
import { PhonenumberInput } from "components/Common/input-advanced";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { genderVerboseFetch } from "components/Common/common";
import { GoBack, Loader } from "pages/HMS/common/common";
import { DoctorCRUModal } from "pages/HMS/staff/doctors/doctor-crud-modals";

const BookAppointment = (props) => {
  const dispatch = useDispatch()
  const { loading, error, options, postResponse, updateResponse, formError, apiKey, hmsObject } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    options: state.Hms.options,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    hmsObject: state.Hms.hmsObject,
  }))
  const history = useHistory();
  const [patientSearch, setPatientSearch] = useState(!props.match.params.appointmentID);
  const [appointment, setAppointment] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [timeSlot, setTimeSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [phonenumber, setPhonenumber] = useState(null);
  const [alternatePhonenumber, setAlternatePhonenumber] = useState(null);
  const [formOptions, setFormOptions] = useState(null)
  const [patient, setPatient] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState({ searchPatients: false, consultingDoctor: false })
  const [inputValue, setInputValue] = useState()
  const [doctorModal, setDoctorModal] = useState(false)

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/appointments/?options=formschema", "fetch_options"))
    if (props.match.params.appointmentID) {
      dispatch(getHms("/hms/patient/appointments/", props.match.params.appointmentID, "fetch_appointment"))
    }
    return () => dispatch(resetHmsState())
  }, [])

  const touchState = (respObj) => {
    if (respObj?.doctor_data) {
      setSelectedDoctor(respObj?.doctor_data);
      setSelectedDate(respObj?.appointment_date)
      fetchDoctorSlots(respObj?.doctor_data?.id)
    } else {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setSelectedDate(formattedDate)
      fetchDoctorSlots(selectedDoctor?.id)
    }
    setAppointment(respObj)
    setPatient({ id: respObj?.patient })
  }

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname === "/hms/patient/appointment/book") {
        dispatch(resetHmsState());
      }
    });
    if (apiKey === "create_appointment") {
      touchState(postResponse)
    } else if (apiKey === "update_appointment") {
      touchState(updateResponse)
    } else if (apiKey === "fetch_appointment") {
      touchState(hmsObject)
    } else if (apiKey === "fetch_options") {
      touchState()
    } else if (apiKey === "fail_fetch_appointment") {
      dispatch(resetHmsState())
      setPatientSearch(true)
    }
    if (apiKey === "fetch_options") {
      setFormOptions(options.form_schema)
    }
    if (apiKey === "book_appointment_doctor_created") {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setSelectedDate(formattedDate)
      setTimeSlot(null)
      fetchDoctorSlots(postResponse?.id)
      setSelectedDoctor(postResponse)
    }

    return () => {
      unlisten();
    };
  }, [apiKey, history]);

  const searchPatients = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/patients/search_phone/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 200)
    }
  }

  const patientSelect = (patient) => {
    if (!isEmpty(patient)) {
      setPatient(patient)
      setPatientSearch(false)
    }
  }

  const onInputChange = (value) => {
    value = value.replace(/[^0-9]/g, "")
    setIsMenuOpen((prev) => ({ ...prev, searchPatients: value.length > 0 }))
    setInputValue(value)
  }

  const fetchDoctorSlots = (doctorId) => {
    if (doctorId && selectedDate) {
      get(`/hms/staff/doctors/${doctorId}/slots/${selectedDate}/`)
        .then((resp) => {
          setDateError(false)
          setSelectedDoctor((preValues) => ({
            ...preValues,
            time_slots: resp
          }
          ))
        })
        .catch((error) => {
          dispatch(postHmsFail(error.response.data))
          setDateError(true)

          setSelectedDoctor((preValues) => ({
            ...preValues,
            time_slots: []
          }
          ))
        })
    }
  };

  const updateDoctorSelect = (doctor) => {
    fetchDoctorSlots(doctor.id)
    setSelectedDoctor(doctor)
    setTimeSlot(null)
  }

  useEffect(() => {
    fetchDoctorSlots(selectedDoctor?.id);
  }, [selectedDate]);

  var suTo = null
  const searchDoctors = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, consultingDoctor: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/staff/doctors/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 1000)
    }
  }

  const handleTimeSlotSelection = (selectedTimeSlot) => {
    setTimeSlot(selectedTimeSlot);
  };

  const isSameDate = appointment?.appointment_date === selectedDate;
  const isSameDoctor = selectedDoctor?.id === appointment?.doctor_data?.id;
  const defaultTimeSlot = isSameDate && isSameDoctor ? appointment?.time_slot : null;

  const getTimeSlots = () => {
    if (isSameDate && !formError?.date && isSameDoctor) {
      return [...selectedDoctor?.time_slots, appointment?.time_slot];
    } else {
      return selectedDoctor?.time_slots;
    }
  };

  const handleSubmit = (event, values) => {
    values.patient = patient?.id
    values.phone_number = phonenumber
    values.alternate_phone_number = alternatePhonenumber
    values.time_slot = timeSlot
    values.doctor = selectedDoctor ? selectedDoctor.id : null
    values.appointment_date = selectedDate;
    let appointmentID = appointment?.id
    if (appointmentID) {
      dispatch(updateHms("/hms/patient/appointments/", appointmentID, values, "update_appointment"))
    } else {
      dispatch(postHms("/hms/patient/appointments/", values, "create_appointment"))
    }
  }

  function toggleDoctor(def = doctorModal) {
    setDoctorModal(!def);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Book appointment | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          {patientSearch ?
            <Col xl={8}>
              <Card>
                <CardBody>
                  <AsyncSearch
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, searchPatients: false }))}
                    menuIsOpen={isMenuOpen.searchPatients}
                    loadOptions={searchPatients}
                    onChange={patientSelect}
                    placeholder={"Search with mobile number"}
                    noOptionsMessage={() => (
                      <p className="text-center my-4" style={{ letterSpacing: '1px' }}>
                        No patient records found?{" "}
                        <Link to="#" onClick={() => setPatientSearch(false)} className="font-weight-bold">
                          Book a new appointment
                        </Link>
                      </p>
                    )}
                    getOptionLabel={(e) => (
                      <div className="custom-option">
                        <div className="user-box">
                          <p className="user-title m-0">{`${e?.first_name} ${e?.last_name}`}</p>
                          <p className="text-muted mb-0">
                            {`${e?.first_name} ${e?.last_name} | ${e?.user__phone_number}`}
                            {e?.alternate_phone_number && ` | ALT PHN: ${e.alternate_phone_number}`}
                            {e?.user__gender && ` | ${genderVerboseFetch(e.user__gender)}`}
                          </p>
                        </div>
                      </div>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
            :
            <Col xl={6}>
              <Card>
                <CardBody>
                  {error ? (
                    <p className="text-center text-danger">{error.detail}</p>
                  ) : (["create_appointment", "update_appointment"].includes(apiKey)) ? (
                    <SuccessMessage data={appointment} />
                  ) : loading && apiKey === "options_fetch_options" || apiKey === "get_fetch_appointment" ? (
                    <Loader />
                  ) : (
                    <>
                      <CardTitle className="mb-4">{isEmpty(appointment) ? "Book an" : "Modify"} appointment</CardTitle>
                      <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                        {formOptions &&
                          <>
                            <Row>
                              <Col sm={2}>
                                <EAvFieldSelect
                                  field="honorific"
                                  value={appointment?.honorific || patient?.honorific || "Mr"}
                                  isError={formError?.honorific}
                                  options={{ label: ".", required: false }}
                                  choices={<>
                                    {formOptions?.honorific?.choices?.map((choice, cidx) =>
                                      <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                    )}
                                  </>}
                                />
                              </Col>
                              <Col sm={5}>
                                <EAvFieldInput
                                  field="first_name"
                                  type="text"
                                  value={appointment?.first_name || patient?.first_name}
                                  isError={formError?.first_name}
                                  options={formOptions?.first_name}
                                />
                              </Col>
                              <Col sm={5}>
                                <EAvFieldInput
                                  field="last_name"
                                  type="text"
                                  value={appointment?.last_name || patient?.last_name}
                                  isError={formError?.last_name}
                                  options={formOptions?.last_name}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <EAvFieldSelect
                                  field="gender"
                                  value={appointment?.gender || patient?.user__gender}
                                  isError={formError?.gender}
                                  options={formOptions?.gender}
                                  choices={<>
                                    <option>{"Select an option"}</option>
                                    {formOptions?.gender?.choices?.map((choice, cidx) =>
                                      <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                    )}
                                  </>}
                                />
                              </Col>
                            </Row>
                            <h5 className="mt-2">Contact Information</h5>
                            <hr />
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <PhonenumberInput
                                    label={formOptions?.phone_number?.label}
                                    name="phone_number"
                                    value={appointment?.phone_number || patient?.user__phone_number}
                                    error={formError?.phone_number}
                                    required={formOptions?.phone_number?.required}
                                    updateValue={setPhonenumber}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <PhonenumberInput
                                    label={formOptions?.alternate_phone_number?.label}
                                    name="alternate_phone_number"
                                    value={appointment?.alternate_phone_number || patient?.alternate_phone_number}
                                    error={formError?.alternate_phone_number}
                                    required={formOptions?.alternate_phone_number?.required}
                                    updateValue={setAlternatePhonenumber}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <EAvFieldInput
                                  field={'coming_from'}
                                  value={appointment?.coming_from}
                                  isError={formError?.coming_from}
                                  options={formOptions?.coming_from}
                                />
                              </Col>
                            </Row>
                            <h5 className="mt-2">Appointment Details</h5>
                            <hr />
                            <Row>
                              <Col md={6}>
                                <div className="d-flex align-items-center">
                                  <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                                    <EAsyncSelect
                                      onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, consultingDoctor: false }))}
                                      menuIsOpen={isMenuOpen.consultingDoctor}
                                      options={{ ...formOptions?.doctor, label: "Consulting Doctor" }}
                                      selectedOption={[selectedDoctor]}
                                      fetchOptions={searchDoctors}
                                      formError={formError?.doctor}
                                      onSelect={updateDoctorSelect}
                                      placeholder="Type to search doctors..."
                                      getOptionLabel={e => `${e.user_data?.full_name} - ${e.specialization}`}
                                      getOptionValue={e => e.id}
                                    />
                                  </div>
                                  <div
                                    onClick={() => toggleDoctor(false)}
                                    title="Add category"
                                    className={`cursor-pointer ms-2 ${formError?.doctor ? "" : "mt-3"}`}
                                  >
                                    <i className="bx bx-plus-medical text-success" />
                                  </div>
                                </div>
                              </Col>
                              {selectedDoctor && selectedDoctor?.time_slots && (
                                <Col md={6}>
                                  <div className="form-group">
                                    <label className={dateError ? "text-danger" : ""}>{formOptions?.time_slot?.label}</label>
                                    <input
                                      id={`id_time_slot`}
                                      name={"time_slot"}
                                      className={dateError ? "form-control is-invalid" : "form-control"}
                                      type="date"
                                      defaultValue={selectedDate}
                                      onChange={(e) => {
                                        setSelectedDate(e.target.value);
                                        setTimeSlot(null);
                                      }}
                                    />
                                    <ErrorMessage error={dateError && formError?.date} marginTop={"2px"} />
                                  </div>
                                </Col>
                              )}
                            </Row>
                            {selectedDoctor && selectedDoctor?.time_slots && (
                              <Row>
                                <Col>
                                  <div className="input-group">
                                    <TimeSlotSelector
                                      isInvalid={formError?.time_slot}
                                      timeSlots={getTimeSlots()}
                                      defaultValue={defaultTimeSlot}
                                      onSelectTimeSlot={handleTimeSlotSelection}
                                    />
                                  </div>
                                  <ErrorMessage error={formError?.time_slot} />
                                </Col>
                              </Row>
                            )}
                            <RequiredFieldsMessage />
                          </>
                        }
                        <Row className="justify-content-end">
                          <Col sm={9}>
                            <div>
                              {apiKey === "post_create_appointment" || apiKey === "update_update_appointment" ? (
                                <>
                                  <Button color="primary" className="float-end" disabled>
                                    {isEmpty(appointment) ? "Book" : "Modify"} Appointment{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                                  </Button>
                                  <GoBack disabled />
                                </>
                              ) : (
                                <>
                                  <Button type="submit" color="primary" className="float-end">
                                    {isEmpty(appointment) ? "Book" : "Modify"} Appointment
                                  </Button>
                                  <GoBack historyProp={props.history} />
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          }
        </Row>

        <DoctorCRUModal
          apiStartKey={"book_appointment_doctor"}
          modal={doctorModal}
          toggle={toggleDoctor}
          rudFlag={1}
        />
      </div>
    </React.Fragment>
  )
}
export default BookAppointment;