import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { LIST_SERVICES, LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, listServicesFail, listServicesSuccess, loginSuccess, logoutUserSuccess } from "./actions"
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postJwtLogin, postSocialLogin } from "../../../helpers/fakebackend_helper"
import { listServicesAPI } from "./api_helper"
import { getLocalStorageItem } from "pages/HMS/common/common"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      username: user.username,
      password: user.password,
    })
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    history.replace(getLocalStorageItem("pinPage")?.url || "/hms")
  } catch (error) {
    yield put(apiError(error.response.data))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* listServicesSaga({ params }) {
  try {
    const response = yield call(listServicesAPI, params)
    yield put(listServicesSuccess(response))
  } catch (error) {
    yield put(listServicesFail(error.response.data))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(LIST_SERVICES, listServicesSaga)
}

export default authSaga
