import { get, patch, post, del, options } from "../../helpers/api_helper"


export const optionsHmsAPI = (api_url) => options(api_url)
export const listHmsAPI = ({ api_url, params }) => {
    let url = api_url
    if (params) url += `?${params}`
    return get(url)
}
export const getHmsAPI = ({ api_url, id }) => id ? get(`${api_url}${id}/`) : get(api_url)
export const postHmsAPI = ({ api_url, payload }) => post(api_url, payload)
export const updateHmsAPI = ({ api_url, id, payload }) => id ? patch(`${api_url}${id}/`, payload) : patch(api_url, payload)
export const deleteHmsAPI = ({ api_url, id }) => id ? del(`${api_url}${id}/`) : del(api_url)
export const reportDownloadHmsAPI = ({ api_url }) => get(api_url)
export const postUploadHmsAPI = ({ api_url, payload }) => post(api_url, payload)
export const listDiagTestParamAPI = ({ api_url, params }) => params ? get(`${api_url}?${params}`) : get(api_url)
export const getDiagTestParamAPI = ({ api_url, id }) => id ? get(`${api_url}${id}/`) : get(api_url)
export const postDiagTestParamAPI = ({ api_url, payload }) => post(api_url, payload)
export const updateDiagTestParamAPI = ({ api_url, id, payload }) => id ? patch(`${api_url}${id}/`, payload) : patch(api_url, payload)
export const deleteDiagTestParamAPI = ({ api_url, id }) => id ? del(`${api_url}${id}/`) : del(api_url)
export const chainedOptionsHmsAPI = ({ api_url, params }) => params ? get(`${api_url}?${params}`) : get(api_url)
