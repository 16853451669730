import React, { useEffect, useRef, useState } from "react"
import { Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { getHmsOptions, postHms, updateHms } from "store/actions"
import { EAvFieldInput, EAvFieldSelect, EInputDropdown } from "pages/HMS/common/errored-avfields"
import toastr from "toastr"
import { EAvFieldDOB, Loader, SubmitLoaderButton, handlePhonenumberFocus, handlePhonenumberBlur, ageToDateOfBirth, errorMappings } from "pages/HMS/common/common"
import { PhonenumberInput } from "components/Common/input-advanced"
import ConfirmationDialog from "pages/HMS/common/confirmation-dialog"
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"
import { GUARDIAN_RELATION } from "pages/HMS/common/constants"

export const PatientCRModal = (props) => {
  const { modal, toggle, rudFlag, data } = props
  const dispatch = useDispatch()
  const formRef = useRef()
  const [formOptions, setFormOptions] = useState(null)
  const [showAgeInput, setShowAgeInput] = useState(localStorage.getItem('showAgeInput') === "true" || localStorage.getItem('showAgeInput') === null)
  const [activeOPAlert, setActiveOPAlert] = useState(false)
  const [phonenumber, setPhonenumber] = useState(null)
  const [phoneError, setPhoneError] = useState('')
  const [addMoreField, setAddMoreField] = useState(false)
  const [alternatePhonenumber, setAlternatePhonenumber] = useState(null)
  const [emergencyNumber, setEmergencyNumber] = useState(null)
  const [modalIssuer, setModalIssuer] = useState(false)
  const [modalIdentity, setModalIdentity] = useState(false)
  const [state, setState] = useState({ title: '', message: '', isExist: false })
  const [guardianError, setGuardianError] = useState("")
  const [guardian, setRelation] = useState({ relation: null, name: null })

  const { apiKey, postResponse, formError, options, updateResponse } = useSelector(state => ({
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
    formError: state.Hms.formError,
    options: state.Hms.options,
  }))

  useEffect(() => {
    if (modal) {
      dispatch(getHmsOptions("/hms/patient/patients/?options=formschema", "patient_options"))
      const [relation, ...rest] = data?.guardian?.split(" ") || []
      setRelation({ relation: relation, name: rest.join(" ") })
    }
  }, [modal])

  useEffect(() => {
    if (options.form_schema) setFormOptions(options.form_schema)
  }, [options])

  useEffect(() => {
    if (apiKey === "patient_options") setFormOptions(options.form_schema)
    if (apiKey === "patient_created") {
      patientToggle()
      toastr.success(`${postResponse?.user_data?.full_name} created successfully`)
      formRef.current?.reset()
    }
    if (apiKey === "patient_updated") {
      patientToggle()
      toastr.success(`${updateResponse?.user_data?.full_name} updated successfully`)
    }
    if (apiKey === "identity_type_patient_bt_created" || apiKey === "insurance_issuer_patient_bt_created") {
      dispatch(getHmsOptions("/hms/patient/patients/?options=formschema", "patient_options"))
    }
    if (formError?.detail && apiKey.startsWith("fail_patient")) {
      setActiveOPAlert(true)
      setState((prevState) => ({ ...prevState, ...errorMappings[formError?.detail] }))
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    if (!phonenumber) {
      setPhoneError('This field is invalid')
      return
    }
    setPhoneError('')

    if (guardian?.name && !guardian?.relation) {
      setGuardianError("Please select a guardian relation")
      return
    }
    setGuardianError("")

    values.user_post = {
      full_name: `${values.first_name} ${values.last_name}`,
      phone_number: phonenumber,
      gender: values.gender,
      dob: showAgeInput ? ageToDateOfBirth(values.dob) : values.dob,
    }
    delete values.email
    delete values.phone_number
    delete values.gender
    delete values.dob
    values.guardian = guardian?.relation && guardian?.name ? `${guardian.relation} ${guardian.name}` : null
    if (addMoreField) {
      values.alternate_phone_number = alternatePhonenumber
      values.emergency_contact_number = emergencyNumber
      values.address = {
        line1: values.line1,
        line2: values.line2,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
      }
      delete values.line1
      delete values.line2
      delete values.city
      delete values.state
      delete values.pincode
      values.health_insurance_post = {
        issuer: values.issuer,
        policy_number: values.policy_number,
        policy_valid_till: values.policy_valid_till,
      }
      delete values.issuer
      delete values.policy_number
      delete values.policy_valid_till
    }
    if (state?.isExist) values.user_verification = 0
    if (rudFlag === 0) {
      dispatch(updateHms("/hms/patient/patients/", data?.id, values, "patient_updated"))
    } else {
      dispatch(postHms("/hms/patient/patients/", values, "patient_created"))
    }
  }

  const onConfirm = () => {
    setState((prevState) => ({ ...prevState, isExist: true }))
    setActiveOPAlert(false)
    formRef.current.submit()
  }

  const patientToggle = () => {
    setAddMoreField(false)
    setRelation({})
    toggle()
  }

  return (
    <>
      <Modal isOpen={modal} toggle={patientToggle} backdrop="static" size="lg" scrollable>
        <ModalHeader toggle={patientToggle} tag="h4">
          {rudFlag === 0 ? "Modify Patient" : "Add Patient"}
        </ModalHeader>
        <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
          <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
            {apiKey === "options_patient_options" ? (
              <Loader />
            ) : (
              <Row>
                <Col>
                  <Row>
                    <Col sm={2}>
                      <EAvFieldSelect
                        field="honorific"
                        value={data?.honorific || "Mr"}
                        isError={formError?.honorific}
                        options={{ ...formOptions?.honorific, label: "." }}
                        choices={
                          <>
                            {formOptions?.honorific?.choices.map((choice, cidx) => (
                              <option value={choice.value} key={cidx}>
                                {choice.display_name}
                              </option>
                            ))}
                          </>
                        }
                      />
                    </Col>
                    <Col sm={5}>
                      <EAvFieldInput
                        field="first_name"
                        value={data?.first_name}
                        type="text"
                        isError={formError?.first_name}
                        options={{ ...formOptions?.first_name, label: "Firstname" }}
                      />
                    </Col>
                    <Col sm={5}>
                      <EAvFieldInput
                        field="last_name"
                        value={data?.last_name}
                        type="text"
                        isError={formError?.last_name}
                        options={{ ...formOptions?.last_name, label: "Lastname" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <EInputDropdown
                        name="guardian"
                        formError={formError?.guardian || guardianError}
                        options={formOptions?.guardian}
                        dropdownValue={guardian.relation}
                        inputValue={guardian.name}
                        setDropdownValue={(value) => setRelation(prevState => ({ ...prevState, relation: value }))}
                        setInputValue={(e) => setRelation(prevState => ({ ...prevState, name: e.target.value }))}
                        dropdownOptions={GUARDIAN_RELATION}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={6}>
                      <PhonenumberInput
                        label="Mobile"
                        name="phone_number"
                        value={data?.user_data?.phone_number}
                        error={formError?.phone_number || phoneError}
                        required={formOptions?.phone_number?.required}
                        updateValue={setPhonenumber}
                        onFocus={() => handlePhonenumberFocus(setPhoneError)}
                        onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                      />
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <PhonenumberInput
                          label="Alternate Mobile"
                          name="alternate_phone_number"
                          value={data?.alternate_phone_number}
                          error={formError?.alternate_phone_number}
                          required={formOptions?.alternate_phone_number?.required}
                          updateValue={setAlternatePhonenumber}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={6}>
                      <EAvFieldSelect
                        field="gender"
                        value={data?.user_data?.gender}
                        isError={formError?.gender}
                        options={{ ...formOptions?.gender, label: "Sex" }}
                        choices={
                          <>
                            <option value="">--Select an option--</option>
                            {formOptions?.gender?.choices?.map((choice, cidx) => (
                              <option value={choice.value} key={cidx}>
                                {choice.display_name}
                              </option>
                            ))}
                          </>
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <EAvFieldDOB
                        value={showAgeInput ? data?.user_data?.age : data?.user_data?.dob}
                        formError={formError}
                        showAgeInput={showAgeInput}
                        toggleSwitch={() => setShowAgeInput((prev) => !prev)}
                        required={formOptions?.dob?.required}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm={6}>
                      <EAvFieldSelect
                        field="marital_status"
                        value={data?.marital_status !== undefined && data?.marital_status.toString()}
                        isError={formError?.marital_status}
                        options={formOptions?.marital_status}
                        choices={
                          <>
                            <option value="">-----Select an option-----</option>
                            <option value="true">Married</option>
                            <option value="false">Unmarried</option>
                          </>
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <EAvFieldSelect
                        field="occupation"
                        value={data?.occupation}
                        isError={formError?.occupation}
                        options={formOptions?.occupation}
                        choices={
                          <>
                            <option value="">-----Select an option-----</option>
                            {formOptions?.occupation?.choices.map((choice, cidx) => (
                              <option value={choice.value} key={cidx}>
                                {choice.display_name}
                              </option>
                            ))}
                          </>
                        }
                      />
                    </Col>
                  </Row>
                  {addMoreField && (
                    <>
                      <Row>
                        <Col>
                          <h5 className="mt-2 text-muted">Address</h5>
                          <hr />
                          <EAvFieldInput
                            field="line1"
                            type="text"
                            value={data?.address?.line1}
                            isError={formError?.address?.line1}
                            options={{ ...formOptions?.line1, label: "", required: false }}
                            placeholder="Address Line1"
                          />
                          <EAvFieldInput
                            field="line2"
                            type="text"
                            value={data?.address?.line2}
                            isError={formError?.address?.line2}
                            options={{ ...formOptions?.line2, label: "", required: false }}
                            placeholder="Address line2"
                          />
                          <Row>
                            <Col>
                              <EAvFieldInput
                                field="city"
                                type="text"
                                value={data?.address?.city}
                                isError={formError?.address?.city}
                                options={{ ...formOptions?.city, label: "", required: false }}
                                placeholder="City"
                              />
                            </Col>
                            <Col>
                              <EAvFieldInput
                                field="state"
                                type="text"
                                value={data?.address?.state}
                                isError={formError?.address?.state}
                                options={{ ...formOptions?.state, label: "", required: false }}
                                placeholder="State"
                              />
                            </Col>
                            <Col>
                              <EAvFieldInput
                                field="pincode"
                                type="text"
                                value={data?.address?.pincode}
                                isError={formError?.address?.pincode}
                                options={{ ...formOptions?.pincode, label: "", required: false }}
                                placeholder="pincode"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <h5 className="mt-2 text-muted">Health information</h5>
                      <hr />
                      <Row>
                        <Col md={6}>
                          <EAvFieldSelect
                            field="blood_group"
                            value={data?.blood_group}
                            isError={formError?.blood_group}
                            options={{ ...formOptions?.blood_group, label: <span>Blood&nbsp;group</span> }}
                            choices={<>
                              <option value={""}>--Select an option--</option>
                              {formOptions?.blood_group?.choices.map((choice, cidx) =>
                                <option value={choice.value} key={cidx}>{choice.display_name}</option>
                              )}
                            </>}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <h5 className="mt-2 text-muted">Emergency contact information</h5>
                        <hr />
                        <Col>
                          <EAvFieldInput
                            field="emergency_contact_name"
                            type="text"
                            value={data?.emergency_contact_name}
                            isError={formError?.emergency_contact_name}
                            options={{ ...formOptions?.emergency_contact_name, label: "Contact person" }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="mb-3">
                            <PhonenumberInput
                              label="Mobile number"
                              name="emergency_contact_number"
                              value={data?.emergency_contact_number}
                              error={formError?.emergency_contact_number}
                              required={formOptions?.emergency_contact_number?.required}
                              updateValue={setEmergencyNumber}
                            />
                          </div>
                        </Col>
                        <Col>
                          <EAvFieldInput
                            field="emergency_contact_relation"
                            type="text"
                            value={data?.emergency_contact_relation}
                            isError={formError?.emergency_contact_relation}
                            options={{ ...formOptions?.emergency_contact_relation, label: "Relation" }}
                          />
                        </Col>
                      </Row>
                      <h5 className="mt-2 text-muted">Identity information</h5>
                      <hr />
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center">
                            <div className="ajax-select select2-container" style={{ flex: "1" }}>
                              <EAvFieldSelect
                                field="identity_type"
                                value={data?.identity_type}
                                isError={formError?.identity_type}
                                options={formOptions?.identity_type}
                                choices={<>
                                  <option value={""}>-----Select an option-----</option>
                                  {formOptions?.identity_type?.choices.map((choice, cidx) =>
                                    <option value={choice.id} key={cidx}> {choice.name} </option>
                                  )}
                                </>}
                              />
                            </div>
                            <div onClick={() => setModalIdentity(true)} title="Add identity type" className="cursor-pointer ms-2 mt-3">
                              <i className="bx bx-plus-medical text-success" />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <EAvFieldInput
                            field="identity_number"
                            type="text"
                            value={data?.identity_number}
                            isError={formError?.identity_number}
                            options={{ ...formOptions?.identity_number, label: "ID number" }}
                          />
                        </Col>
                      </Row>
                      <h5 className="mt-2 text-muted">Insurance</h5>
                      <hr />
                      <Row>
                        <Col>
                          <div className="d-flex align-items-center">
                            <div className="ajax-select select2-container" style={{ flex: "1" }}>
                              <EAvFieldSelect
                                field="issuer"
                                value={data?.health_insurance_data?.issuer}
                                isError={formError?.issuer}
                                options={{ ...formOptions?.issuer, label: <span>Insurance&nbsp;issuer</span> }}
                                choices={<>
                                  <option value={""}>--Select an option--</option>
                                  {formOptions?.insurance_issuer?.choices.map((choice, cidx) =>
                                    <option value={choice.id} key={cidx}> {choice.name} </option>
                                  )}
                                </>}
                              />
                            </div>
                            <div onClick={() => setModalIssuer(true)} title="Add insurance issuers" className="cursor-pointer ms-2 mt-3">
                              <i className="bx bx-plus-medical text-success" />
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <EAvFieldInput
                            field="policy_valid_till"
                            type="date"
                            value={data?.health_insurance_data?.policy_valid_till}
                            isError={formError?.policy_valid_till}
                            options={{ ...formOptions?.policy_valid_till, label: "Valid till" }}
                            daterange={{ start: { value: -100, units: "years" }, end: { value: 100, units: "years" } }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <EAvFieldInput
                            field="policy_number"
                            type="text"
                            value={data?.health_insurance_data?.policy_number}
                            isError={formError?.policy_number}
                            options={{ ...formOptions?.policy_number, label: <span>Policy&nbsp;number</span> }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Row>
                <Col>
                  <div
                    className="text-primary mb-1 cursor-pointer d-flex align-items-center"
                    onClick={() => setAddMoreField(!addMoreField)}
                  >
                    <span>{addMoreField ? 'Show less' : 'Add more fields'}</span>
                    <i className={`bx font-size-18 ms-1 ${addMoreField ? 'bx-up-arrow-alt' : 'bx-down-arrow-alt'}`} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="text-end">
                  <SubmitLoaderButton
                    type="submit"
                    size="sm"
                    loading={apiKey === "post_patient_created" || apiKey === "update_patient_updated"}
                  >
                    Submit
                  </SubmitLoaderButton>
                </Col>
              </Row>
            </div>
          </ModalFooter>
        </AvForm>
      </Modal>

      <SingleFieldCRUModal
        modalLabel={"Insurance issuer"}
        modal={modalIssuer}
        toggle={() => setModalIssuer(false)}
        rudFlag={1}
        options={{ label: "Name", required: true }}
        apiStartKey={"insurance_issuer_patient_bt"}
        apiEndpoint={"/hms/patient/insurance_issuer/"}
      />

      <SingleFieldCRUModal
        modalLabel={"Identity Type"}
        modal={modalIdentity}
        toggle={() => setModalIdentity(false)}
        rudFlag={1}
        options={{ label: "Name", required: true }}
        apiStartKey={"identity_type_patient__bt"}
        apiEndpoint={"/hms/patient/identity_type/"}
      />

      <ConfirmationDialog
        activeOPAlert={activeOPAlert}
        setActiveOPAlert={setActiveOPAlert}
        state={state}
        onConfirm={onConfirm}
      />
    </>
  )
}