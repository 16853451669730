import { deletePaginationResult, prependToPaginationResult, updatePaginationResult } from "components/Common/common"
import {
  GET_BRANCHES,
  GET_BRANCHES_FAIL,
  GET_BRANCHES_SUCCESS,
  ADD_BRANCH,
  ADD_BRANCH_SUCCESS,
  ADD_BRANCH_FAIL,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAIL,
  DELETE_BRANCH,
  DELETE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAIL,
  
  GET_UNAS_USERS,
  GET_UNAS_USERS_FAIL,
  GET_UNAS_USERS_SUCCESS,

  GET_BRANCH_GROUPS,
  GET_BRANCH_GROUPS_FAIL,
  GET_BRANCH_GROUPS_SUCCESS,
  GET_GROUP_DETAIL,
  GET_GROUP_DETAIL_FAIL,
  GET_GROUP_DETAIL_SUCCESS,
  ADD_GROUP,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAIL,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  ADD_USERS,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAIL,
  REMOVE_USER_FROM_GROUP,
  REMOVE_USER_FROM_GROUP_SUCCESS,
  REMOVE_USER_FROM_GROUP_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  loading: false,
  rudStatus: false,
  branches: {},
  branch: {},
  members: [],
  groups: {},
  group: {},
  error: null,
  detailError: null,
  formError: null,
  updatedBranch: false,
  createdBranch: false,
  removedBranch: false,
  updatedGroup: false,
  createdGroup: false,
  removedGroup: false
}

const Manage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANCHES:
      return {
        ...state,
        error: null,
        detailError: null,
        formError: null,
        updatedBranch: false,
        createdBranch: false,
        loading: true
      }
    case GET_BRANCHES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_BRANCHES_SUCCESS:
      return {
        ...state,
        detailError: null,
        formError: null,
        updatedBranch: false,
        createdBranch: false,
        branches: action.payload,
        loading: false
      }
    case ADD_BRANCH:
      return {
        ...state,
        formError: null,
        removedBranch: false,
        createdBranch: false,
        updatedBranch: false,
        loading: true
      }
    case ADD_BRANCH_SUCCESS:
      return {
        ...state,
        formError: null,
        createdBranch: true,
        branches: prependToPaginationResult(state.branches, action.payload),
        loading: false
      }
    case ADD_BRANCH_FAIL:
      return {
        ...state,
        createdBranch: false,
        formError: action.payload,
        loading: false
      }
    case UPDATE_BRANCH:
      return {
        ...state,
        removedBranch: false,
        createdBranch: false,
        updatedBranch: false,
        formError: null,
        loading: true
      }
    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        updatedBranch: true,
        formError: null,
        branches: updatePaginationResult(state.branches, action.payload, 'uid'),
        loading: false
      }
    case UPDATE_BRANCH_FAIL:
      return {
        ...state,
        updatedBranch: false,
        formError: action.payload,
        loading: false
      }
    case DELETE_BRANCH:
      return {
        ...state,
        formError: null,
        detailError: null,
        loading: true,
        rudStatus: false,
        removedBranch: false,
        createdBranch: false,
        updatedBranch: false
      }  
    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        formError: null,
        detailError: null,
        rudStatus: true,
        branches: deletePaginationResult(state.branches, 'uid', action.payload),
        loading: false,
        removedBranch: true
      }
    case DELETE_BRANCH_FAIL:
      return {
        ...state,
        detailError: action.payload,
        loading: false,
        rudStatus: false,
        removedBranch: false
      }

    case GET_UNAS_USERS:
      return {
        ...state,
        error: null,
        loading: true
      }
    case GET_UNAS_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_UNAS_USERS_SUCCESS:
      return {
        ...state,
        members: action.payload.results,
        loading: false
      }  
    case GET_BRANCH_GROUPS:
      return {
        ...state,
        loading: true,
        formError: null,
        detailError: null,
        updatedGroup: false,
        createdGroup: false,
        error: null,
      }
    case GET_BRANCH_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_BRANCH_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        formError: null,
        detailError: null,
        updatedGroup: false,
        createdGroup: false,
        groups: action.payload,
      }
    case ADD_GROUP:
      return {
        ...state,
        loading: true,
        formError: null,
        createdGroup: false,
        updatedGroup: false,
        removedGroup: false,
        error: null,
      }
    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        formError: null,
        createdGroup: true,
        groups: prependToPaginationResult(state.groups, action.payload),
      }
    case ADD_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        createdGroup: false,
        formError: action.payload,
      }
    case UPDATE_GROUP:
      return {
        ...state,
        createdGroup: false,
        updatedGroup: false,
        removedGroup: false,
        formError: null,
        loading: true
      }
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        updatedGroup: true,
        formError: null,
        groups: updatePaginationResult(state.groups, action.payload, 'uid'),
        loading: false
      }
    case UPDATE_GROUP_FAIL:
      return {
        ...state,
        updatedGroup: false,
        formError: action.payload,
        loading: false
      }
    case DELETE_GROUP:
      return {
        ...state,
        formError: null,
        detailError: null,
        loading: true,
        rudStatus: false,
        createdGroup: false,
        updatedGroup: false,
        removedGroup: false,
      }  
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        formError: null,
        detailError: null,
        rudStatus: true,
        groups: deletePaginationResult(state.groups, 'uid', action.payload),
        loading: false,
        removedGroup: true
      }
    case DELETE_GROUP_FAIL:
      return {
        ...state,
        detailError: action.payload,
        loading: false,
        rudStatus: false,
        removedGroup: false
      }
    
    case GET_GROUP_DETAIL:
      return {
        ...state,
        error: null,
        loading: true
      }
    case GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        group: action.payload,
        loading: false
      }
    case GET_GROUP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case ADD_USERS:
      return {
        ...state,
        loading: true,
        detailError: null,
        loading: true
      }
    case ADD_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        detailError: null,
        group: action.payload,
        loading: false
      }
    case ADD_USERS_FAIL:
      return {
        ...state,
        loading: false,
        detailError: action.payload,
        loading: false
      }
    case REMOVE_USER_FROM_GROUP:
      return {
        ...state,
        detailError: null,
        loading: true
      }
    case REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        detailError: null,
        group: {...state.group, users: state.group.users.filter(user => user.username.toString() !== action.payload.username.toString())},
        loading: false
      }
    case REMOVE_USER_FROM_GROUP_FAIL:
      return {
        ...state,
        detailError: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default Manage
