import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Row, Col, CardTitle, Button } from "reactstrap";
import { getHms, getHmsOptions, resetHmsState } from "store/hms/actions";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { Loader } from "pages/HMS/common/common";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DoctorCRUForm } from "./doctor-crud-modals";

const AddDoctor = (props) => {
  const { loading, error, options, hmsObject, actionStatus, apiKey, postResponse, hmsSingleObject } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    options: state.Hms.options,
    hmsObject: state.Hms.hmsObject,
    actionStatus: state.Hms.actionStatus,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    hmsSingleObject: state.Hms.hmsSingleObject
  }))

  const dispatch = useDispatch()
  const history = useHistory();
  const id = props.match.params.doctorID
  const [isChecked, setIsChecked] = useState(false);
  const [state, setState] = useState({ title: '', message: '', isExist: false, full_name: '', username: null });

  useEffect(() => {
    dispatch(getHmsOptions("/hms/staff/doctors/?options=formschema", "fetch_doctor_options"))
    if (id) {
      dispatch(getHms('/hms/staff/doctors/', id, "get_doctor"))
    }
    return () => { dispatch(resetHmsState()) }
  }, [history])

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setState((prevState) => ({ ...prevState, isExist: false }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Doctor | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          <Col xl={6}>
            <Card>
              <CardBody>
                {error ? (
                  <p className="text-center text-danger">{error.detail}</p>
                ) : loading && apiKey === "options_fetch_doctor_options" || apiKey === "get_get_doctor" ? (
                  <Loader />
                ) : (["doctor_created_success", "doctor_updated_success"].includes(actionStatus)) ? (
                  <div className="p-2">
                    <div className="text-center">
                      <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                      <div className="p-2 mt-2">
                        {actionStatus === "doctor_created_success" ?
                          <>
                            <h4>Doctor added successfully</h4>
                            <p className="text-muted">
                              A verification link sent to the doctor<br />
                            </p>
                          </>
                          :
                          <h4>Doctor modified successfully</h4>
                        }
                        <div className="mt-4">
                          <div className="d-flex justify-content-center">
                            <Button
                              color="primary"
                              onClick={() => window.location.replace("/hms/staff/doctors/add")}
                              className={window.innerWidth <= 425 ? 'btn-sm me-1' : 'me-1'}
                            >
                              Add&nbsp;Another
                            </Button>
                            <Link
                              to={"/hms/staff/doctors"}
                              className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary me-1' : 'me-1 btn btn-primary'}
                            >
                              Doctors
                            </Link>
                            <Button
                              color="primary"
                              className={window.innerWidth <= 425 ? 'btn-sm' : ''}
                              onClick={() => window.location.replace(`/hms/staff/doctors/add/${hmsSingleObject?.id}`)}
                            >
                              Modify
                            </Button>
                            <Link
                              to={`/hms/staff/doctors/${hmsSingleObject.id}/detail`}
                              className={window.innerWidth <= 425 ? 'btn-sm ms-1 btn btn-primary' : 'ms-1 btn btn-primary'}
                            >
                              Explore
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="mb-4 d-flex">
                      <CardTitle>{isEmpty(id) ? "Add" : "Modify"} Doctor</CardTitle>
                      {isEmpty(id) && (
                        <div className="form-check form-switch form-switch-md ms-2" style={{ marginTop: "-2px" }}>
                          <input type="checkbox" className="form-check-input" id="customSwitchsizemd" checked={isChecked} onChange={handleCheckboxChange} />
                          <label className="form-check-label" htmlFor="customSwitchsizemd"><CardTitle>Convert To Doctor</CardTitle></label>
                        </div>
                      )}
                    </div>
                    <DoctorCRUForm
                      options={options}
                      historyProp={props.history}
                      apiStartKey={"doctor"}
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                      state={state}
                      setState={setState}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}
export default AddDoctor;