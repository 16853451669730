import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { passwordStrength } from "components/Common/common"
import { EAvFieldInput } from "pages/HMS/common/errored-avfields"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "reactstrap"
import { registerVerification } from "store/actions"


const ConfirmPassword = (props) => {
  const dispatch = useDispatch()
  const [token, setToken] = useState(null)

  const { verificationData, loading, formError, apiKey } = useSelector(state => ({
    verificationData: state.Account.verificationData,
    loading: state.Account.loading,
    formError: state.Account.formError,
    apiKey: state.Account.apiKey,
  }))

  useEffect(() => {
    if (apiKey == "finish") {
      props.handler('finish', verificationData)
      localStorage.removeItem('registration-cache')
    }
    if (verificationData?.token) setToken(verificationData?.token)
  }, [apiKey])

  const handleSubmit = (event, values) => {
    values.token = token
    values.action = props.purpose
    dispatch(registerVerification(values, "finish"))
  }

  const onCheckPasswordStrength = (e) => {
    passwordStrength(e)
  }

  return (
    <div className="p-2">
      <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
        <div className="mb-3">
          <EAvFieldInput
            field="password"
            type="password"
            placeholder="Enter Password"
            isError={formError?.password}
            options={{ label: "Password", required: true, max_length: 30 }}
            onInput={onCheckPasswordStrength}
          />
        </div>
        <div className="mb-3">
          <EAvFieldInput
            field="confirm_password"
            type="password"
            placeholder="Retype password"
            isError={formError?.confirm_password}
            options={{ label: "Confirm password", required: true, max_length: 30 }}
            onInput={onCheckPasswordStrength}
          />
        </div>
        <div className="text-center mt-4">
          {loading ?
            <Button color="primary" className="btn btn-primary" disabled>
              Submit{" "}
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            </Button>
            :
            <Button color="primary" className="btn btn-primary" type="submit">Submit</Button>
          }
        </div>
      </AvForm>
    </div>
  )
}

export default ConfirmPassword
