import React, { useState } from 'react'

const DefaultIfNone = props => {
    const { value, default_value, format=false, tooltip=true } = props
    function formatNumber(value) {
        if (value > 999 && value < 1000000) {
            return (value/1000).toFixed(1) + ' K';
        } else if (value >= 1000000 && value < 1000000000) {
            return (value/1000000).toFixed(1) + ' M';
        } else if (value >= 1000000000) {
            return (value/1000000000).toFixed(1) + ' B';
        } else {
            return value;
        }
    }

    function clearedValue(value) {
        if (value===undefined || value===null || value==="") {
            return default_value
        } else {
            return format ? formatNumber(value) : value
        }
    }
    return (
        <span title={tooltip ? value : null}>{clearedValue(value)}</span>
    )
}
export default DefaultIfNone
