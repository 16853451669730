import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { GET_CALLS, GET_DYNAMIC_FILTER_OPTIONS, GET_CALL_OPTIONS, GET_USERS_ON_SEARCH } from "./actionTypes"

import { getCallsFail, getCallsSuccess, getCallOptionsFail, getCallOptionsSuccess, userDynamicFilterOptionsFail, userDynamicFilterOptionsSuccess } from "./actions"

import { getCallsAPI, getDynamicUserFilterOptionsAPI, getCallOptionsAPI, getUsersAPI } from "./api_helper"

import { getUsersFail, getUsersSuccess } from "store/actions"

function* onGetUsers({data}) {
  try {
    const response = yield call(getUsersAPI, data)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error.response.data))
  }
}

function* onGetCallOptionsSaga({params}) {
  try {
    const response = yield call(getCallOptionsAPI, params)
    yield put(getCallOptionsSuccess(response))
  } catch (error) {
    yield put(getCallOptionsFail(error.response.data))
  }
}

function* getDynamicUserFilterOptionsSaga() {
  try {
    const response = yield call(getDynamicUserFilterOptionsAPI)
    yield put(userDynamicFilterOptionsSuccess(response))
  } catch (error) {
    yield put(userDynamicFilterOptionsFail(error.response.data))
  }
}

function* onGetCalls({data}) {
  try {
    const response = yield call(getCallsAPI, data)
    yield put(getCallsSuccess(response))
  } catch (error) {
    yield put(getCallsFail(error.response.data))
  }
}


function* callsSaga() {
  yield takeLatest(GET_USERS_ON_SEARCH, onGetUsers)
  yield takeLatest(GET_CALL_OPTIONS, onGetCallOptionsSaga)
  yield takeLatest(GET_DYNAMIC_FILTER_OPTIONS, getDynamicUserFilterOptionsSaga)
  yield takeLatest(GET_CALLS, onGetCalls)
}

export default callsSaga
