import { MY_STATS, MY_STATS_FAIL, MY_STATS_SUCCESS } from "./actionTypes";

export const myStats = user_id => ({
    type: MY_STATS,
    payload: user_id
})
export const myStatsSuccess = (actionType, data) => ({
    type: MY_STATS_SUCCESS,
    payload: { actionType, data },
});
export const myStatsFail = (actionType, error) => ({
    type: MY_STATS_FAIL,
    payload: { actionType, error },
});
