import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  CHECK_CALLS,
  GET_SIGNED_URLS,
  POST_BATCHES,
} from "./actionTypes"

import {
  postBatchesSuccess,
  postBatchesFail,
  checkCallsSuccess,
  checkCallsFail,
  getSignedURLsSuccess,
  getSignedURLsFail,
} from "./actions"

import {
  checkCallsAPI,
  getSignedURLsAPI,
  postBatchesAPI,
} from "./api_helper"

function* onPostBatches({ payload: data }) {
  try {
    const response = yield call(postBatchesAPI, data)
    yield put(postBatchesSuccess(response))
  } catch (error) {
    yield put(postBatchesFail(error.response.data))
  }
}

function* onCheckCalls({ data }) {
  try {
    const response = yield call(checkCallsAPI, {data})
    yield put(checkCallsSuccess(response))
  } catch (error) {
    yield put(checkCallsFail(error.response.data))
  }
}

function* getSignedURLs({ data }) {
  try {
    const response = yield call(getSignedURLsAPI, data)
    yield put(getSignedURLsSuccess(response))
  } catch (error) {
    yield put(getSignedURLsFail(error.response.data))
  }
}

function* commonSaga() {
  yield takeEvery(POST_BATCHES, onPostBatches)
  yield takeEvery(CHECK_CALLS, onCheckCalls)
  yield takeEvery(GET_SIGNED_URLS, getSignedURLs)
}

export default commonSaga
