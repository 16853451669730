import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useSelector, useDispatch } from "react-redux"
import { postHms } from "store/actions"
import toastr from "toastr"
import { Button, Col, Container, Row, Input } from "reactstrap"


const PDFPrinter = () => {
  const dispatch = useDispatch()
  const [snippet, setSnippet] = useState("");

  const { apiKey, postResponse } = useSelector((state) => ({
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse
  }))

  useEffect(() => {
    if (apiKey === "download-report") {
      const fileName = `${new Date().toISOString().split('.')[0].replace(/[^\d]/gi, '')}.pdf`;
      const linkSource = `data:application/pdf;base64,${postResponse}`;
      const downloadLink = document.createElement("a");

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      toastr.success("Report downloaded. Please check your downloads")
    }
  }, [apiKey])

  const handleSubmit = () => {
    if (snippet.trim() !== "") {
      dispatch(postHms("/hms/pharmacy/printer/pdf/", {
        snippet: snippet
      }, "download-report"))
    }
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>Printer | Yanthura</title>
      </MetaTags>
      <Container fluid>
        <Row>
          <Col>
            <Input
              id="id_snippet"
              name="snippet"
              type="textarea"
              className="form-control"
              onChange={(e) => setSnippet(e.target.value)}
              rows={25}
            />
            <Button color="primary" className="btn btn-sm float-end ms-2 waves-effect" onClick={handleSubmit}>Submit</Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PDFPrinter
