import React, { useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Col, Card, CardBody, Row, Badge } from "reactstrap"
import { resetHmsState } from "store/actions"
import { TableBadge, TableChip } from "components/Common/common"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { Loader, stringToHumanTime } from "pages/HMS/common/common"

const DiagnosisHomeDetail = ({ configuration, setActiveTab }) => {
  const dispatch = useDispatch()

  const { loading, error, apiKey } = useSelector((state) => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    return () => dispatch(resetHmsState())
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Diagnosis Home | Yanthura</title>
        </MetaTags>
        <CardBody>
          {loading && apiKey === "get_diagnosis_configuration_details" ? (
            <Loader />
          ) : error ? (
            <p className="text-center text-danger">{error?.detail}</p>
          ) : (
            <>
              <Row>
                {/* First Column */}
                <Col xl={4} lg={6} md={12} sm={12}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h5>Diagnosis</h5>
                        <span>
                          <Link title="Modify" className="text-muted" to="#" onClick={() => setActiveTab("DiagnosisHome")}>
                            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                          </Link>
                        </span>
                      </div>
                      <hr />
                      {configuration?.name || configuration?.phone_number || configuration?.email || configuration?.website || !isEmpty(configuration?.affiliated_doctors_data) || !isEmpty(configuration?.affiliated_hospitals_data) ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Name"
                              value={configuration?.name}
                            />
                            <TableChip
                              label="Mobile"
                              value={configuration?.phone_number}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Email"
                              value={configuration?.email}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Website"
                              value={configuration?.website}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableBadge
                              label="Affiliated doctors"
                              value={configuration?.affiliated_doctors_data}
                              keyName="user__full_name"
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableBadge
                              label="Affiliated hospitals"
                              value={configuration?.affiliated_hospitals_data}
                            />
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">Configuration details not existed</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                {/* Second Column */}
                <Col xl={4} lg={6} md={12} sm={12}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h5 className="mt-2">Registration Details</h5>
                      </div>
                      <hr />
                      {configuration?.registration_number || configuration?.registration_date || configuration?.issuing_authority ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Registration number"
                              value={configuration?.registration_number}
                            />
                            <TableChip
                              label="Registration date"
                              value={configuration?.registration_date}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Issuing authority"
                              value={configuration?.issuing_authority}
                            />
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">Registration details not existed</p>
                      )}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h5 className="mt-2">Tax Information</h5>
                      </div>
                      <hr />
                      {configuration?.business_identification_number || configuration?.tax_country ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Business identification number"
                              value={configuration?.business_identification_number}
                            />
                            <TableChip
                              label="Tax country"
                              value={configuration?.tax_country}
                            />
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">Tax details not existed</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                {/* Third Column */}
                <Col xl={4} lg={6} md={12} sm={12}>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h5 className="mt-2">Address</h5>
                      </div>
                      <hr />
                      {configuration?.address1 || configuration?.address2 ? (
                        <div className="d-flex justify-content-between">
                          <TableChip
                            label="Address1"
                            value={configuration?.address1}
                          />
                          <TableChip
                            label="Address2"
                            value={configuration?.address2}
                          />
                        </div>
                      ) : (
                        <p className="text-center text-danger">Address details not existed</p>
                      )}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h5 className="mt-2">Operating Hours</h5>
                      </div>
                      <hr />
                      {configuration?.opening_time || configuration?.closing_time || configuration?.days_of_operation ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Opening time"
                              value={!isEmpty(configuration?.opening_time) ? stringToHumanTime(configuration?.opening_time) : null}
                            />
                            <TableChip
                              label="Closing time"
                              value={!isEmpty(configuration?.closing_time) ? stringToHumanTime(configuration?.closing_time) : null}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Operating days"
                              value={configuration?.days_of_operation}
                            />
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">Operating hours not existed</p>
                      )}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h5 className="mt-2">Owner/Manager Details</h5>
                      </div>
                      <hr />
                      {configuration?.owner_name || configuration?.owner_contact || configuration?.owner_email || configuration?.owner_designation ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Name"
                              value={configuration?.owner_name}
                            />
                            <TableChip
                              label="Mobile"
                              value={configuration?.owner_contact}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Email address"
                              value={configuration?.owner_email}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Role"
                              value={configuration?.owner_designation}
                            />
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">Owner/Manager details not existed</p>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </div>
    </React.Fragment>
  )
}

export default DiagnosisHomeDetail

