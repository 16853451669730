import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Badge, Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { deleteHms, getHmsOptions, listHms, resetHmsState } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"
import { formatDateTime, genderVerboseFetch } from "components/Common/common"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import toastr from "toastr"
import { getLocalStorageItem, Loader, updateURLParams } from "pages/HMS/common/common"

const Appointments = props => {
  const location = useLocation()
  const history = useHistory()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [appointmentObj, setAppointmentObj] = useState({})
  const [appointmentList, setAppointmentList] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)

  const { loading, error, hmsList, options, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    hmsList: state.Hms.hmsList,
    options: state.Hms.options,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/appointments/?options=table, search, daterange, filters", "appointment_options"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "appointment_options") {
      if (options.filters) setFilter(options.filters)
      if (options.table_columns) {
        const buffer = options.table_columns.map(item => {
          if (item.dataField === "sno") {
            return {
              ...item,
              formatter: (cell, row, rowIndex, formatExtraData) => rowIndex + 1,
            }
          } else if (item.dataField === "full_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link
                  to={`/hms/patient/appointment/${row.id}/detail`}
                  title={`Address: ${row.coming_from}\nBooked by: ${row.created_by.full_name} at ${moment(row.created_at).format("h:mm A, MMM D")}`}
                >
                  {row.first_name} {row.last_name}
                </Link>
              )
            }
          } else if (item.dataField === "gender") {
            return {
              ...item,
              formatter: (cellContent, row) => genderVerboseFetch(row.gender)
            }
          } else if (item.dataField === "phone_number") {
            return {
              ...item,
              formatter: (cellContent, row) => row.phone_number,
            }
          } else if (item.dataField === "doctor") {
            return {
              ...item,
              formatter: (cellContent, row) => row.doctor_data.user_data.full_name,
            }
          } else if (item.dataField === "time_slot") {
            return {
              ...item,
              formatter: (cellContent, row) => formatDateTime(`${row.appointment_date}T${row.time_slot}`)
            }
          } else if (item.dataField === "status") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row?.status === "Active" ? (
                  <Badge pill color="success">{row.status}</Badge>
                ) : (
                  <Badge pill color="danger">{row.status}</Badge>
                )
              )
            }
          } else if (item.dataField === "patient") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row.status === "Expired" ? (
                  "---"
                ) : row?.patient ? (
                  "Registered"
                ) : (
                  <Link to={{ pathname: "/hms/patient/register/", state: row }}>Register Here</Link>
                )
              )
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16 border-0" color="white">
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className={`dropdown-item ${row?.status === "Expired" ? "disabled" : ""}`} to={`/hms/patient/appointment/book/${row.id}`}>
                  Modify
                </Link>
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [apiKey])

  useEffect(() => {
    if (apiKey === "appointment_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/patient/appointments/", decodeURIComponent(urlParams).replace("?", ""), "appointments_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.appointments
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/patient/appointments/", savedFilters, "appointments_list"))
      }
    }
    if (apiKey === "appointments_list") {
      setAppointmentList(hmsList)
    }
    if (apiKey === "appointment_deleted") {
      setAppointmentObj(hmsList)
      toggleDelete()
      toastr.success(`Appointment ${appointmentObj?.name} is deleted`)
    }
  }, [apiKey])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/patient/appointments/", urlParams, "appointments_list"))
      setAppointmentList({})
    } else {
      dispatch(listHms("/hms/patient/appointments/", null, "appointments_list"))
      setAppointmentList({})
    }
  }

  const toggleDelete = (def = deleteModal) => {
    setDeleteModal(!def)
  }

  const handleDelete = (appointment_object) => {
    setAppointmentObj(appointment_object)
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Appointments | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_appointment_options" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="appointments"
              urlParams={urlParams}
              filters={filters}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField="id"
              noDataIndication="No appointments created"
              data={appointmentList}
              loading={apiKey === "list_appointments_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
            />
          )}
        </Container>
      </div>

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If appointment is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/appointments/", appointmentObj?.id, "appointment_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  )
}

export default Appointments
