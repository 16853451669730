import { get, patch, post, del, options } from "../../helpers/api_helper"

export const optionsLeadAPI = () => options("/lms/leads/")
export const listLeadsAPI = params => {
    let url = "/lms/leads/"
    if (params) url+=`?${params}`
    return get(url)
}
export const getLeadAPI = (leadID) => get(`/lms/leads/${leadID}/`)
export const postLeadAPI = (payload) => post("/lms/leads/", payload)
export const updateLeadAPI = ({leadID, payload}) => patch(`/lms/leads/${leadID}/`, payload)
export const deleteLeadAPI = (leadID) => del(`/lms/leads/${leadID}/`)
export const uploadLeadsAPI = (payload) => post("/lms/leads/upload/", payload)
export const assignToAgentsAPI = (payload) => post("/lms/leads/auto_assign/", payload)

export const listTemplatesAPI = params => {
    let url = "/lms/templates/"
    if (params) url+=`?${params}`
    return get(url)
}
export const getTemplateAPI = (templateID) => get(`/lms/templates/${templateID}/`)
export const postTemplateAPI = (payload) => post("/lms/templates/", payload)
export const updateTemplateAPI = ({templateID, payload}) => patch(`/lms/templates/${templateID}/`, payload)
export const deleteTemplateAPI = (templateID) => del(`/lms/templates/${templateID}/`)
export const checkHeadersAPI = (payload) => post("/lms/templates/check_headers/", payload)
export const modifyDisplayOptionsAPI = (payload) => post("/lms/templates/modify_display_options/", payload)

export const leadDashboardAPI = () => get('/lms/dashboard')

