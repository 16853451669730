import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_SETTINGS,
  ROTATE_KEYS,
  BATCH_LIMIT_UPDATE,
  CREATE_KEYS,
  POST_NFS,
  UPDATE_NFS,
  GET_SERVERS,
  GET_SERVICES,
  POST_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  POST_SERVER,
  UPDATE_SERVER,
  DELETE_SERVER,
  CHECK_STORAGE,
} from "./actionTypes"

import {
  apiSuccess,
  apiFail,
  postNfsSuccess,
  postNfsFail,
  updateNfs,
  serverSuccess,
  serverFail,
  serviceSuccess,
  serviceFail,
  postServiceSuccess,
  postServiceFail,
  updateServiceSuccess,
  updateServiceFail,
  deleteServiceSuccess,
  deleteServiceFail,
  postServerSuccess,
  postServerFail,
  updateServerSuccess,
  updateServerFail,
  deleteServerSuccess,
  deleteServerFail,
  updateNfsSuccess,
  updateNfsFail,
  checkStorageSuccess,
  checkStorageFail,
} from "./actions"

import {
  getSettingsAPI,
  createKeysAPI,
  rotateKeysAPI,
  updateBatchLimitAPI,
  postNfsAPI,
  updateNfsAPI,
  getServersAPI,
  getServicesAPI,
  postServiceAPI,
  updateServiceAPI,
  deleteServiceAPI,
  postServerAPI,
  updateServerAPI,
  deleteServerAPI,
  checkStorageAPI,
} from "./api_helper"

function* onGetSettings({ payload: data }) {
  try {
    const response = yield call(getSettingsAPI, data)
    yield put(apiSuccess(response))
  } catch (error) {
    yield put(apiFail(error.response.data))
  }
}

function* onCheckStorage() {
  try {
    const response = yield call(checkStorageAPI)
    yield put(checkStorageSuccess(response))
  } catch (error) {
    yield put(checkStorageFail(error.response.data))
  }
}

function* onCreateKeys() {
  try {
    const response = yield call(createKeysAPI)
    yield put(apiSuccess(response))
  } catch (error) {
    yield put(apiFail(error.response.data))
  }
}

function* onRotateKeys({ payload: { settingId, data } }) {
  try {
    const response = yield call(rotateKeysAPI, {settingId, data})
    yield put(apiSuccess(response))
  } catch (error) {
    yield put(apiFail(error.response.data))
  }
}

function* onUpdateBatchLimit({ payload: { settingId, data } }) {
  try {
    const response = yield call(updateBatchLimitAPI, {settingId, data})
    yield put(apiSuccess(response))
  } catch (error) {
    yield put(apiFail(error.response.data))
  }
}

function* onPostNfsDetails({ payload: { data } }) {
  try {
    const response = yield call(postNfsAPI, { data})
    yield put(postNfsSuccess(response))
  } catch (error) {
    yield put(postNfsFail(error.response.data))
  }
}

function* onUpdateNfsDetails({ payload: { data } }) {
  try {
    const response = yield call(updateNfsAPI, { data})
    yield put(updateNfsSuccess(response))
  } catch (error) {
    yield put(updateNfsFail(error.response.data))
  }
}

// Servers part
function* onGetServers({data}) {
  try {
    const response = yield call(getServersAPI, data)
    yield put(serverSuccess(response.results))
  } catch (error) {
    yield put(serverFail(error.response.data))
  }
}
function* onPostServer({ payload: {data} }) {
  try {
    const response = yield call(postServerAPI, {data})
    yield put(postServerSuccess(response))
  } catch (error) {
    yield put(postServerFail(error.response.data))
  }
}
function* onUpdateServer({ payload: {serverName, data} }) {
  try {
    const response = yield call(updateServerAPI, {serverName, data})
    yield put(updateServerSuccess(response))
  } catch (error) {
    yield put(updateServerFail(error.response.data))
  }
}
function* onDeleteServer({ payload: serverName }) {
  try {
    yield call(deleteServerAPI, serverName)
    yield put(deleteServerSuccess(serverName))
  } catch (error) {
    yield put(deleteServerFail(error.response.data))
  }
}
// End servers part

// Services part
function* onGetServices({ payload: serviceObject }) {
  try {
    const response = yield call(getServicesAPI, { serviceObject })
    yield put(serviceSuccess(response.results))
  } catch (error) {
    yield put(serviceFail(error.response.data))
  }
}
function* onPostService({ payload: {server, data} }) {
  try {
    const response = yield call(postServiceAPI, {server, data})
    yield put(postServiceSuccess(response))
  } catch (error) {
    yield put(postServiceFail(error.response.data))
  }
}
function* onUpdateService({ payload: {serviceName, data} }) {
  try {
    const response = yield call(updateServiceAPI, {serviceName, data})
    yield put(updateServiceSuccess(response))
  } catch (error) {
    yield put(updateServiceFail(error.response.data))
  }
}
function* onDeleteService({ payload: serviceName }) {
  try {
    yield call(deleteServiceAPI, serviceName)
    yield put(deleteServiceSuccess(serviceName))
  } catch (error) {
    yield put(deleteServiceFail(error.response.data))
  }
}
// End services part


function* settingsSaga() {
  yield takeEvery(GET_SETTINGS, onGetSettings)
  yield takeEvery(CHECK_STORAGE, onCheckStorage)
  yield takeLatest(CREATE_KEYS, onCreateKeys)
  yield takeLatest(ROTATE_KEYS, onRotateKeys)
  yield takeLatest(BATCH_LIMIT_UPDATE, onUpdateBatchLimit)
  yield takeLatest(POST_NFS, onPostNfsDetails)
  yield takeLatest(UPDATE_NFS, onUpdateNfsDetails)

  yield takeEvery(GET_SERVERS, onGetServers)
  yield takeEvery(POST_SERVER, onPostServer)
  yield takeEvery(UPDATE_SERVER, onUpdateServer)
  yield takeEvery(DELETE_SERVER, onDeleteServer)

  yield takeEvery(GET_SERVICES, onGetServices)
  yield takeEvery(POST_SERVICE, onPostService)
  yield takeEvery(UPDATE_SERVICE, onUpdateService)
  yield takeEvery(DELETE_SERVICE, onDeleteService)
}

export default settingsSaga
