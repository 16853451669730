import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import { Link } from "react-router-dom";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { getBranchOptions as onGetBranchOptions } from "store/actions"
import { getBranchGroupsOptions as onGetBranchGroupsOptions } from "store/actions"
import { isEmpty } from "lodash";

const UserCreationLinkGeneration = props => {
  const dispatch = useDispatch()
  const [linkObject, setLinkObject] = useState({
    userType: null,
    branch: null,
    group: null
  })
  const [assignToGroup, setAssignToGroup] = useState(false)
  const [ajax_div, setajax_div] = useState(false)
  const [link, setLink] = useState("")
  const [error, setError] = useState("")

  const [userTypes, setUserTypes] = useState({})
  const [branchesList, setBranchesList] = useState([])
  const [groupsList, setGroupsList] = useState([])

  const { branches, groups, detailError } = useSelector(state => ({
    branches: state.DashboardGenericState.branches,
    groups: state.DashboardGenericState.groups,
    detailError: state.DashboardGenericState.detailError,
  }))

  useEffect(() => {
    dispatch(onGetBranchOptions())
  }, [dispatch])
  useEffect(() => {
    if (!isEmpty(branches)) {
      setBranchesList(branches.branches)
      setUserTypes(branches.user_types)
    }
  }, [branches])

  useEffect(() => {
    setGroupsList(groups)
  }, [groups])



  const copy = () => {
    let copyTextField = document.getElementById("text2copy")
    copyTextField.select()
    copyTextField.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(copyTextField.value)
    // setInterval(() => {
    //   setajax_div(false)
    // }, 5000)
  }

  const createLink = () => {
    setTimeout(()=>{
      setError("")
    }, 3000)
    if (!linkObject.userType) {
      setError("Please select a user type")
      return
    }
    if (linkObject.userType==="ADMIN") {
      setLinkObject({...linkObject, branch: null, group: null})
    } else if (linkObject.userType==="MANGR") {
      setLinkObject({...linkObject, group: null})
    }
    if (linkObject.userType==="MANGR" && linkObject.branch===null) {
      setError("Please select a branch to assign manager")
      return
    }
    if (linkObject.userType==="AGENT" && (linkObject.branch===null || linkObject.group===null)) {
      setError("Please select a branch and group to assign an agent")
      return
    }
    let linkArray = []
    Object.keys(linkObject).map(key => {
      if (linkObject[key]) {
        linkArray.push(key+'='+linkObject[key])
      }
    })
    setLink(`${window.location.protocol}//${window.location.host}/register?${linkArray.join('&')}`)
    setajax_div(true)
  }

  const getGroups = (e) => {
    setLinkObject({...linkObject, branch: e.target.value});
    console.log(e.target.value);
    dispatch(onGetBranchGroupsOptions(e.target.value));
  }
  return (
    <React.Fragment>
      <div className="page-content">
      {ajax_div ? (
        <SweetAlert
          title="Copy and share the link"
          confirmBtnBsStyle="primary"
          onConfirm={copy}
          onCancel={() => setajax_div(false)}
          showCancel={true}
          confirmBtnText="Copy"
          confirmBtnCssClass="btn-sm"
          cancelBtnCssClass="btn-sm"
          cancelBtnText="OK"
          >
            {ajax_div &&
              <p className="text-success text-center">Link copied to clipboard</p>
            }
            <input
              type="text"
              id="text2copy"
              className="form-control form-control-sm text-primary"
              contentEditable={false}
              value={link}
              onChange={() => {}}
            />
          </SweetAlert>
        ) : null}
        <MetaTags>
          <title>User creation link generation | Yanthura</title>
        </MetaTags>
        <Breadcrumbs title="Manage users" breadcrumbItem="Link creation" />
        <Container>
          <div className="account-pages">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-4">
                    {error && 
                    <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {error}</p>
                    }
                    <Form>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputState">User Type</Label>
                            <select
                              id="formrow-InputState"
                              className="form-control"
                              onChange={(e) => {
                                setLinkObject({...linkObject, userType: e.target.value});
                                (e.target.value==="MANGR" || e.target.value==="ADMIN") && setAssignToGroup(true)
                              }}
                            >
                              <option defaultValue className="text-center">-------- Select an option -------</option>
                              {Object.entries(userTypes).map(([k, v]) => 
                                <option key={k} value={k}>{v}</option>
                              )}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      {linkObject.userType!=="ADMIN" &&
                      <>
                        <Row>
                          <Col lg={12}>
                            <div className="mb-3 mt-3">
                              <div className="form-check">
                                <Input
                                  type="checkbox"
                                  className="form-check-Input"
                                  id="formrow-customCheck"
                                  checked={assignToGroup}
                                  onChange={() => {
                                    setAssignToGroup(!assignToGroup)
                                    if (assignToGroup) {
                                      setLinkObject({...linkObject, branch: null, group: null})
                                    }
                                  }}
                                />
                                <Label
                                  className="form-check-Label"
                                  htmlFor="formrow-customCheck"
                                >
                                  Assign to branch or group ?
                                </Label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {assignToGroup &&
                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputState">Branch</Label>
                                <select
                                  id="formrow-InputState"
                                  className="form-control"
                                  onChange={getGroups}
                                  >
                                  <option defaultValue className="text-center">--- Select a branch ---</option>
                                  {branchesList.map(v => 
                                    <option key={v.uid} value={v.uid}>{v.name}</option>
                                  )}
                                </select>
                              </div>
                            </Col>
                            {linkObject.userType!=="MANGR" &&
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label htmlFor="formrow-InputState">Group</Label>
                                <select
                                  id="formrow-InputState"
                                  className="form-control"
                                  onChange={(e) => {setLinkObject({...linkObject, group: e.target.value})}}
                                >
                                  <option defaultValue className="text-center">--- Select a group ---</option>
                                  {groupsList.map(v => 
                                    <option key={v.uid} value={v.uid}>{v.name}</option>
                                  )}
                                </select>
                              </div>
                            </Col>
                            }
                          </Row>
                        }
                      </>
                      }
                      <div>
                        <button type="button" className="btn btn-primary w-md" onClick={createLink} id="ajax-alert">
                          Create Link
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserCreationLinkGeneration
