import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Label,
  Input,
  Button,
  Alert,
  CardTitle,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"
import {
  getSettings as onGetSettings,
  updateBatchLimit as onUpdateBatchLimit,
  rotateKeys as onRotateKeys,
  createKeys as onCreateKeys,
  postNfs as onPostNfs,
  updateNfs as onUpdateNfs,
} from "store/actions"
import * as moment from "moment"
import Servers from "./servers"
import toastr from "toastr"


const Settings = props => {
  const [activeTab, setactiveTab] = useState("1")
  const [nfsMode, setNfsMode] = useState(null)
  const [batchLimit, setBatchLimit] = useState(4)
  const dispatch = useDispatch()

  const { settings, loading, error, nfsData, nfsError, rotatedKeys, nfsCUStatus } = useSelector(state => ({
    settings: state.Settings.settings,
    loading: state.Settings.loading,
    error: state.Settings.error,
    nfsData: state.Settings.nfsData,
    nfsError: state.Settings.nfsError,
    rotatedKeys: state.Settings.rotatedKeys,
    nfsCUStatus: state.Settings.nfsCUStatus,
  }))

  useEffect(() => {
    dispatch(onGetSettings())
  }, [dispatch])

  useEffect(() => {
    setBatchLimit(settings.batch_limit)
    setNfsMode(settings.nfs_mode)
  }, [settings])

  useEffect(() => {
    if (nfsCUStatus===11) toastr.success("Settings created successfully")
    if (nfsCUStatus===21) toastr.success("Settings updated successfully")
  }, [nfsCUStatus])

  const rotateKeys = settingId => {
    dispatch(onRotateKeys(settingId, { action: 'rotate_keys' }))
  }

  const createKeys = () => {
    dispatch(onCreateKeys())
  }

  const updateBatchLimit = (e, settingId) => {
    setBatchLimit(e.target.value)
    dispatch(onUpdateBatchLimit(settingId, { action: 'batch_limit_update', batch_limit: parseInt(e.target.value) }))
  }

  const handleNfsSubmit = (event, values) => {
    if (!isEmpty(settings.object_id)) {
      values.action = 'nfs_update'
      values.nfs_mode = nfsMode
      values.nfs_id = settings.object_id.id
      dispatch(onUpdateNfs(values))
    } else {
      values.action = 'nfs_create'
      values.nfs_mode = nfsMode
      dispatch(onPostNfs(values))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Setup" breadcrumbItem="Settings" />
          <Row>
            <Col xs="12">
              <Card className="email-leftbar">
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1")
                        }}
                      >
                        <i className="mdi mdi-shape me-2"></i>NFS
                        {/* <span className="ml-1 float-end">(18)</span> */}
                      </NavLink>
                    </NavItem>
{/* 
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2")
                        }}
                      >
                        <i className="mdi mdi-server me-2"></i>Servers
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>
              </Card>

              <div className="email-rightbar">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {/* <Card className="p-3">
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-exchange-Input">Exchange</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-exchange-Input"
                              value={settings.rabbit_exchange}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-topic-Input">Topic</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="formrow-topic-Input"
                              value={settings.rabbit_topic}
                              disabled
                            />
                          </div>
                        </Col>
                        <Row>
                          <Col md={8}>
                            <p className="text-warning small">Last updated at {moment(settings.updated_at).local().format('MMM DD, YYYY')}</p>
                          </Col>
                          <Col md={4}>
                            {settings.id ?
                              <Button type="button" className="btn-sm float-end" color="light" onClick={() => rotateKeys(settings.id)}>Rotate keys now</Button>
                              :
                              <Button type="button" className="btn-sm float-end" color="light" onClick={createKeys}>Create keys</Button>
                            }
                          </Col>
                        </Row>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div>
                            <Label htmlFor="batch_limit" className="form-label">Batch Limit</Label>
                            <Input type="range" className="form-range" id="batch_limit" value={batchLimit} min="4" max="20" step="2" onChange={(e) => updateBatchLimit(e, settings.id)} />
                            <Label className="h4 text-primary">{batchLimit}</Label>
                          </div>
                        </Col>
                      </Row>
                    </Card> */}
                    {settings.id &&
                      <Card className="p-3">
                        <CardTitle className="mb-4">Configure Call Storage</CardTitle>
                        <Row>
                          <Col xl={3} sm={6}>
                            <div className="mt-4">
                              {Object.entries(settings.nfs_options).map(([key, value]) =>
                                <div className="form-check mb-3" key={key}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="nfs_mode"
                                    id={key}
                                    value={key}
                                    defaultChecked={key === settings.nfs_mode ? true : false}
                                    onChange={(e) => setNfsMode(e.target.value)}
                                  />
                                  <label className="form-check-label" htmlFor={key}>{value}</label>
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col xl={9} sm={6}>
                            {nfsMode==="AWS" ?
                            <AvForm className="form-horizontal" onValidSubmit={(e, v) => handleNfsSubmit(e, v)}>
                              <Row>
                                <Col lg={5}>
                                  <div className="mb-3">
                                    <AvField
                                      name="ak"
                                      label="Access key"
                                      className="form-control"
                                      type="text"
                                      value={settings.object_id ? settings.object_id.ak : ""}
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col lg={7}>
                                  <div className="mb-3">
                                    <AvField
                                      name="sak"
                                      label="Secret access key"
                                      className="form-control"
                                      type="text"
                                      value={settings.object_id ? settings.object_id.sak : ""}
                                      required
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <AvField
                                      name="bucket_name"
                                      label="Bucket name"
                                      className="form-control"
                                      type="text"
                                      value={settings.object_id ? settings.object_id.bucket_name : ""}
                                      required
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <AvField
                                      name="region"
                                      label="Region"
                                      className="form-control"
                                      type="text"
                                      value={settings.object_id ? settings.object_id.region : ""}
                                      required
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div className="mb-3">
                                <AvField
                                  name="bucket_url"
                                  label="Bucket URL"
                                  className="form-control"
                                  type="text"
                                  value={settings.object_id ? settings.object_id.bucket_url : ""}
                                  required
                                />
                              </div>
                              <div>
                              {loading ?
                                <button className="btn btn-primary float-end w-md" disabled>
                                  {settings.object_id ? "Update" : "Submit"}{" "}
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </button>
                              :
                                <button type="submit" className="btn btn-primary float-end w-md">
                                  {settings.object_id ? "Update" : "Submit"}
                                </button>
                              }
                              </div>
                            </AvForm>
                            : nfsMode==="AZR" ?
                            <p>Upcoming...</p>
                            : nfsMode==="GGL" ?
                            <p>Upcoming...</p>
                            : nfsMode==="DBX" ?
                            <p>Upcoming...</p>
                            : null
                            }
                            <p></p>
                          </Col>
                        </Row>
                      </Card>
                    }
                  </TabPane>
                  <TabPane tabId="2">
                    <Servers />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Settings.propTypes = {
  settings: PropTypes.array,
  onGetSettings: PropTypes.func,
  onAddBranch: PropTypes.func,
  onDeleteBranch: PropTypes.func,
  onUpdateBranch: PropTypes.func,
}

export default withRouter(Settings)
