import { Table } from "reactstrap";
import { isEmpty } from "lodash";
import { Fragment } from "react";

export const NestedTable = ({ index, test, handleChange }) => {
  const renderRow = (testItem, level = 0) => {
    const isHeader = !testItem?.value_type && !testItem?.reference_result && !testItem?.unit

    return (
      <Fragment key={testItem?.id}>
        <tr>
          <td className="p-2" colSpan={isHeader ? '4' : '1'}>
            {Array(level).fill('\u00A0\u00A0\u00A0\u00A0').join('')}
            <span style={{ fontWeight: isHeader ? 'bold' : 'normal' }}>{testItem?.name}</span>
          </td>
          {!isHeader && (
            <>
              <td className="p-0 pt-1">
                <input
                  type="text"
                  name="actual_result"
                  className="form-control-sm mb-0 ms-1 me-1"
                  style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                  onChange={e => handleChange(e, testItem)}
                  value={testItem?.result}
                />
              </td>
              <td className="p-2">
                <div
                  style={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{ __html: testItem?.reference_result }}
                />
              </td>
              <td className="p-2">{testItem?.unit_name}</td>
            </>
          )}
        </tr>
        {!isEmpty(testItem?.children) && (testItem?.children?.map((child) => renderRow(child, level + 1)))}
      </Fragment>
    )
  };

  return (
    <div className="table-responsive mb-1" key={index}>
      <Table className="table-striped table-bordered mb-0">
        <thead>
          <tr>
            <th scope="col" colSpan="4">
              {test?.name} {test?.short_name ? `(${test?.short_name})` : ""}
            </th>
          </tr>
        </thead>
        <tbody>
          {!isEmpty(test?.parameters) ? (
            <>
              <tr>
                <th className="p-2">Name</th>
                <th className="p-2">Actual Result</th>
                <th className="p-2">Reference Result</th>
                <th className="p-2">Unit</th>
              </tr>
              {test?.parameters?.map((parameter) => (
                <Fragment key={parameter?.id || parameter?.name}>
                  {renderRow(parameter)}
                </Fragment>
              ))}
            </>
          ) : (
            <tr>
              <td className="text-center text-danger" scope="col" colSpan="4">Parameters not existed</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
