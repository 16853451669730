import React, { useEffect, useRef, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledAlert,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "components/Common/Breadcrumb"

import {
  getBranches as onGetBranches,
  addBranch as onAddBranch,
  updateBranch as onUpdateBranch,
  deleteBranch as onDeleteBranch,
} from "store/actions"
import toastr from "toastr"
import FlashAlert from "components/Common/flash-message"
import AsyncSelect from "react-select/async";
import { get } from "helpers/api_helper"
import RemoteGenericTable from "components/Common/tables"


const Manage = props => {
  const dispatch = useDispatch()
  const formRef = useRef()

  const { branches, error, formError, detailError, updatedBranch, createdBranch, removedBranch, loading, rudStatus } = useSelector(state => ({
    branches: state.Manage.branches,
    error: state.Manage.error,
    formError: state.Manage.formError,
    detailError: state.Manage.detailError,
    updatedBranch: state.Manage.updatedBranch,
    createdBranch: state.Manage.createdBranch,
    removedBranch: state.Manage.removedBranch,
    loading: state.Manage.loading,
    rudStatus: state.Manage.rudStatus,
  }))

  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [paginatedBranch, setPaginatedBranch] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteEnabled, setDeleteEnabled] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  const BranchColumns = [
    {
      dataField: "name",
      text: "Branch",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/groups/${row.uid}/${row.name}`} className="text-body">
          <p className="text-primary">{row.name}</p>
        </Link>
      ),
    }, {
      dataField: "owner",
      text: "Manager",
      formatter: (cellContent, row) => (
        <Link to={`/user/${row.owner.username}/dashboard`}>{row.owner.full_name}</Link>
      )
    }, {
      dataField: "uid",
      text: "Key",
      sort: true,
    }, {
      dataField: "group_count",
      text: "Groups",
      sort: true,
    }, {
      dataField: "action",
      text: "",
      isDummyField: true,
      formatter: (cellContent, row) => (
        <UncontrolledDropdown className="ms-auto">
          <DropdownToggle
            className="text-muted font-size-16"
            color="white"
          >
            <i className="mdi mdi-dots-horizontal"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <Link className="dropdown-item" to="#" onClick={() => handleEditBranch(row)}>
              Edit
            </Link>
            <Link className="dropdown-item" to="#" onClick={() => handleDeleteBranch(row)}>
              Remove
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    }
  ]

  useEffect(() => {
    dispatch(onGetBranches())
  }, [dispatch])

  const fetchNewPage = (page, sizePerPage) => {
    dispatch(onGetBranches(`page=${page}`))
  }

  useEffect(() => {
    if (createdBranch) {
      setModal(!createdBranch)
      toastr.success("Branch created successfully")
      formRef.current.reset()
    }
  }, [createdBranch])
  
  useEffect(() => {
    setPaginatedBranch(branches)
    if (!isEmpty(branches) && rudFlag) setRUDFlag(null)
    if (updatedBranch) {
      setModal(!updatedBranch)
      toastr.success("Branch updated successfully")
    }
    if (removedBranch) setModal(!removedBranch)
  }, [branches.results])

  useEffect(() => {
    if (rudStatus && deleteEnabled) {
      toggleDelete()
      toastr.success(`Branch ${modalData.name} deleted`)
    }
  }, [rudStatus])


  const handleEditBranch = arg => {
    const branch = arg
    setModalData({
      uid: branch.uid,
      name: branch.name,
      owner: branch.owner
    })
    setSelectedUser(branch.owner)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDeleteBranch = arg => {
    setModalData(arg)
    toggleDelete()
  }

  const handleValidBranchSubmit = (e, values) => {
    values.owner = Array.isArray(selectedUser) ? selectedUser[0].username : selectedUser.username
    if (rudFlag === 0) {
      dispatch(onUpdateBranch(values))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(onAddBranch(values))
    }
  }

  const handleCreateBranch = () => {
    setModalData({})
    setSelectedUser(null)
    setRUDFlag(1)
    toggle(false)
  }

  function toggle(def = modal) {
    setModal(!def)
  }

  function toggleDelete() {
    setDeleteModal(!deleteModal)
  }


  const updateUsers = value => {
    setSelectedUser(value)
  }

  var suTo = null
  const searchUsers = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 2) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        // dispatch(onGetUnassignedGroupMembers(`query=${value}`))
        get(`/options/top-lvl-users?query=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 1000)
    }
  }

  const colorStyles = {
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: "black"
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Manage | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Manage" breadcrumbItem="Branches" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="4">
                      {/* <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div> */}
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="mb-2 me-2 btn-sm"
                          onClick={handleCreateBranch}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Create a branch
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <RemoteGenericTable loading={loading} data={paginatedBranch} columns={BranchColumns} tKey={'uid'} noDataMessage={"No branches created"} pageChangeHandler={fetchNewPage} />
                  <Modal isOpen={deleteModal} toggle={toggleDelete} backdrop="static">
                    <ModalHeader toggle={toggleDelete} tag="h4">
                      ⚠️ Warning: By deleting branch {modalData.name}
                    </ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col className="col-12">
                          {detailError &&
                            <FlashAlert color="danger" delay="10000" className="text-center">{detailError.detail}</FlashAlert>
                          }
                          <ul>
                            <li>Branch will be removed permanently</li>
                            <li>Respective groups in this branch will be removed</li>
                            <li>All users will be released from the removed groups</li>
                          </ul>
                          <p>Type <i><b>delete permanently</b></i> to proceed delete operation</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <input
                              name="confirmation-text"
                              type="text"
                              className="form-control"
                              required={false}
                              onChange={(e) => {
                                e.target.value === "delete permanently" ? setDeleteEnabled(true) : setDeleteEnabled(false)
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="float-end">
                        <Col>
                          <button type="button" className="btn btn-outline-danger btn-sm me-2" disabled={!deleteEnabled}
                            onClick={() => {
                              dispatch(onDeleteBranch(modalData.uid))
                            }}>
                            Yes
                          </button>
                          <button type="button" className="btn btn-light btn-sm"
                            onClick={() => toggleDelete()}>
                            No
                          </button>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal} toggle={toggle} backdrop="static">
                    <ModalHeader toggle={toggle} tag="h4">
                      {rudFlag === 0 ? "Edit Branch" : rudFlag === 1 ? "Add Branch" : rudFlag === 2 ? "Delete Branch" : ""}
                    </ModalHeader>
                    <ModalBody>
                      {formError ?
                        <UncontrolledAlert color="danger" role="alert" className="ms-1 me-1">
                          {Object.entries(formError).map(([key, value]) =>
                            <p key={key}>{value[0]}</p>
                          )}
                        </UncontrolledAlert>
                        : null}
                      <div>
                        {rudFlag === 2 ?
                          <div>
                            <h6 className="mb-4">Are you sure, you want to delete {modalData.name} branch ?</h6>
                            <div className="float-end">
                              {loading ?
                                <button disabled className="btn btn-outline-danger btn-sm me-2">
                                  Yes{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </button>
                                :
                                <button type="button" className="btn btn-outline-danger btn-sm me-2"
                                  onClick={() => {
                                    dispatch(onDeleteBranch(modalData.uid))
                                    toggle()
                                    toastr.warning(`Branch ${modalData.name} deleted`)
                                  }}>
                                  Yes
                                </button>
                              }
                              <button
                                type="button"
                                className="btn btn-light btn-sm"
                                onClick={() => toggle()}>
                                No
                              </button>
                            </div>
                          </div>
                          :
                          <AvForm
                            onValidSubmit={handleValidBranchSubmit}
                            ref={formRef}
                          >
                            <Row>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <AvField
                                    name="name"
                                    label="Branch name"
                                    type="text"
                                    errorMessage="Enter a valid branch name"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={modalData.name || ""}
                                  />
                                  <AvField name="uid" type="hidden" value={modalData.uid || ""} />
                                </div>
                              </Col>
                              <Col className="col-12">
                                <div className="mt-4 mb-3 ajax-select select2-container">
                                  <AsyncSelect
                                    value={selectedUser}
                                    loadOptions={searchUsers}
                                    onChange={updateUsers}
                                    placeholder="Type to search users..."
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    getOptionLabel={e => `${e.full_name}-${e.email}`}
                                    getOptionValue={e => e.username}
                                    styles={colorStyles}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="text-end">
                                  {loading ?
                                    <button disabled className="btn btn-primary btn-sm save-user">
                                      SAVE{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </button>
                                    :
                                    <button type="submit" className="btn btn-primary btn-sm save-user">
                                      SAVE
                                    </button>
                                  }
                                </div>
                              </Col>
                            </Row>
                          </AvForm>
                        }
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Manage.propTypes = {
  branches: PropTypes.array,
  onGetBranches: PropTypes.func,
  onAddBranch: PropTypes.func,
  onDeleteBranch: PropTypes.func,
  onUpdateBranch: PropTypes.func,
}

export default withRouter(Manage)
