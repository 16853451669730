import { call, put, takeEvery } from "redux-saga/effects";
import { addProformaFail, addProformaSuccess, deleteProformaFail, deleteProformaSuccess, updateProformaFail, updateProformaSuccess } from "store/actions";
import { addStatementFail, addStatementSuccess, deleteStatementFail, deleteStatementSuccess, getProformaDetailFail, getProformaDetailSuccess, getProformaListFail, getProformaListSuccess, updateStatementFail, updateStatementSuccess } from "./actions";
import { ADD_PROFORMA, ADD_STATEMENT, DELETE_PROFORMA, DELETE_STATEMENT, GET_PROFORMA_DETAIL, GET_PROFORMA_LIST, UPDATE_PROFORMA, UPDATE_STATEMENT } from "./actionTypes";
import { addNewProformaAPI, addNewStatementAPI, deleteProformaAPI, deleteStatementAPI, getProformaDetailAPI, getProformasAPI, updateProformaAPI, updateStatementAPI } from "./api_helper";


function* fetchProformas() {
    try {
        const response = yield call(getProformasAPI)
        yield put(getProformaListSuccess(response))
    } catch (error) {
        yield put(getProformaListFail(error.response.data))
    }
}
function* onAddNewProforma({ payload: proforma }) {
    try {
        const response = yield call(addNewProformaAPI, proforma)
        yield put(addProformaSuccess(response))
    } catch (error) {
        yield put(addProformaFail(error.response.data))
    }
}
function* onUpdateProforma({ payload: {proformaID, proforma} }) {
    try {
        const response = yield call(updateProformaAPI, {proformaID, proforma})
        yield put(updateProformaSuccess(response))
    } catch (error) {
        yield put(updateProformaFail(error.response.data))
    }
}
function* onDeleteProforma({ payload: proformaID }) {
    try {
        yield call(deleteProformaAPI, proformaID)
        yield put(deleteProformaSuccess(proformaID))
    } catch (error) {
        yield put(deleteProformaFail(error.response.data))
    }
}

function* fetchProformaDetails({ proformaID }) {
    try {
        const response = yield call(getProformaDetailAPI, proformaID)
        yield put(getProformaDetailSuccess(response))
    } catch (error) {
        yield put(getProformaDetailFail(error.response.data))
    }
}
function* onAddNewStatement({ payload: statement }) {
    try {
        const response = yield call(addNewStatementAPI, statement)
        yield put(addStatementSuccess(response))
    } catch (error) {
        yield put(addStatementFail(error.response.data))
    }
}
function* onUpdateStatement({ payload: {statementID, statement} }) {
    console.log(statementID, statement);
    try {
        const response = yield call(updateStatementAPI, {statementID, statement})
        yield put(updateStatementSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(updateStatementFail(error.response.data))
    }
}
function* onDeleteStatement({ payload: statementID }) {
    try {
        yield call(deleteStatementAPI, statementID)
        yield put(deleteStatementSuccess(statementID))
    } catch (error) {
        yield put(deleteStatementFail(error.response.data))
    }
}

function* proformaSaga() {
    yield takeEvery(GET_PROFORMA_LIST, fetchProformas)
    yield takeEvery(ADD_PROFORMA, onAddNewProforma)
    yield takeEvery(UPDATE_PROFORMA, onUpdateProforma)
    yield takeEvery(DELETE_PROFORMA, onDeleteProforma)

    yield takeEvery(GET_PROFORMA_DETAIL, fetchProformaDetails)
    yield takeEvery(ADD_STATEMENT, onAddNewStatement)
    yield takeEvery(UPDATE_STATEMENT, onUpdateStatement)
    yield takeEvery(DELETE_STATEMENT, onDeleteStatement)
}

export default proformaSaga
