import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Card, Col, Container, Row, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import Configure from "./configure/configure"
import Utilize from "./utilize/utilize"
import { resetHmsState } from "store/actions"
import { useDispatch } from "react-redux"

const Reports = (props) => {
  const [activeTab, setActiveTab] = useState("configure");
  const dispatch = useDispatch()

  const onTabChange = (tab) => {
    dispatch(resetHmsState())
    setActiveTab(tab)
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Reports | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card className="email-leftbar">
                <div className="mail-list mt-4">
                  <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "configure" })}
                        onClick={() => onTabChange("configure")}
                      >
                        <span className="ms-2">Configure</span>
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === "utilize" })}
                        onClick={() => onTabChange("utilize")}
                      >
                        <span className="ms-2">Utilize</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Card>

              <div className="email-rightbar mb-3">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="configure">
                    {activeTab === "configure" && <Configure />}
                  </TabPane>
                  <TabPane tabId="utilize">
                    {activeTab === "utilize" && <Utilize />}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reports