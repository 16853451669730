import DefaultIfNone from "components/Common/default";
import React, { useEffect, useRef, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, InputGroup, Row } from "reactstrap";
import Flatpickr from "react-flatpickr"
import moment from "moment";
import { isEmpty } from "lodash";
import ReactApexChart from "react-apexcharts"
import { leadDashboard as onLeadDashboard } from "store/actions";
import Admin, { Agent } from "./dashboards";


const Dashboard = (props) => {
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const [period, setPeriod] = useState({
    value: 1,
    unit: 'block_month',    // months
    start: moment.utc().subtract(1, 'months').format('YYYY-MM-DD'),
    end: moment.utc().format('YYYY-MM-DD')
  })
  const [role, setRole] = useState(null)
  const dateRangeRef = useRef()

  const { errorData, loading, dashboardData } = useSelector(state => ({
    errorData: state.DashboardGenericState.errorData,
    loading: state.DashboardGenericState.loading,
    dashboardData: state.Lms.dashboardData
  }))

  useEffect(() => {
    let authObject = JSON.parse(localStorage.getItem("authUser"))
    setRole(authObject.role)
  }, [])

  useEffect(() => {
    dispatch(onLeadDashboard())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(dashboardData)) {
      setData(dashboardData)
    }
  }, [dashboardData]);

  const onPeriodChange = (period, unit) => {
    let now = moment.utc()
    if (unit.startsWith("block_")) {
      if (unit === "block_today") {
        var end = now
        var start = now
      } else if (unit === "block_yesterday") {
        var end = now.subtract(1, 'days')
        var start = end
      } else if (unit === "block_month") {
        var end = now
        var start = moment().startOf('month')
      }
    } else {
      if (unit === 'days') period = Number(period) - 1
      var end = now
      var start = now.subtract(period, unit)
    }
    start = start.format('YYYY-MM-DD')
    end = end.format('YYYY-MM-DD')

    let queryParam = getURLParam({
      start: start,
      end: end
    })
    dispatch(onGetDashboardData(queryParam))
    dateRangeRef.current.flatpickr.clear()
    if (period === 6 && unit === 'days') unit = 'weeks'
    setPeriod({
      value: period,
      unit: unit,
      start: start,
      end: end
    })
  }

  const onChangeDateRange = dateRange => {
    if (!isEmpty(dateRange)) {
      let start = moment(dateRange[0]).format("YYYY-MM-DD")
      let end = moment(dateRange[1]).format("YYYY-MM-DD")
      let queryParam = getURLParam({
        start: start,
        end: end
      })
      dispatch(onGetDashboardData(queryParam))
      setPeriod({
        start: start,
        end: end
      })
    }
  }


  const series = [
    {
      name: 'Series 1',
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    },
    {
      name: 'Series 2',
      data: [23, 12, 54, 61, 32, 56, 81, 19, 90]
    }
  ]
  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: function (val, timestamp) {
          return moment(new Date(timestamp)).format(data.period === "day" ? "MMM DD, YYYY" : "MMM YYYY")
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val ? val.toFixed(2) : val
        }
      }
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>LMS | Dashboard</title>
        </MetaTags>
        <Container fluid>
          <div className="d-flex flex-row mb-2">
            <div className="ms-auto">
              <InputGroup>
                <Flatpickr
                  className="form-control form-control-sm"
                  placeholder="Select a date range"
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d"
                  }}
                  ref={dateRangeRef}
                  onClose={onChangeDateRange}
                />
              </InputGroup>
            </div>
            <div className="ms-2">
              <Button className="btn-sm" color={period.unit === 'block_today' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'block_today')}>Today</Button>
              <Button className="btn-sm" color={period.unit === 'block_yesterday' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'block_yesterday')}>Yesterday</Button>
              <Button className="btn-sm" color={period.unit === 'block_month' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'block_month')}>Month</Button>
            </div>
          </div>
          {errorData ?
            <Card>
              <CardBody>
                <p className="text-center text-danger">{errorData.detail}</p>
              </CardBody>
            </Card>
            :
            <>
              {loading ?
                <p className="text-center mt-5">Fetching results. please wait{" "}<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
                :
                <>
                {role==="AGENT" ? <Agent data={dashboardData} /> : <Admin data={dashboardData} /> }
                </>
              }
            </>
          }
        </Container>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Trends(Qualified Leads)</h5>
            </div>
            <div id="area-chart" dir="ltr">
              {series.length > 0 ?
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height={350}
                  className="apex-charts"
                />
                :
                <p className="text-center">No trends existed</p>
              }
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
};

export default Dashboard;