import { get, options, post } from "../../helpers/api_helper"

export const getUsersAPI = data => {
    let url = "/accounts/options/users"
    if (data) url+=`?${data}`
    return get(url)
}

export const getCallOptionsAPI = params => {
    let url = "/calls"
    if (params) url+=`?${params}`
    return options(url)
}


export const getDynamicUserFilterOptionsAPI = (payload) => post("/options/user/dyn", payload)

export const getCallsAPI = data => {
    let url = "/calls"
    if (data) url+=`?${data}`
    return get(url)
}


