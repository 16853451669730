import { get, post, patch, options } from "../../helpers/api_helper"

export const getApiData = queryParamObject => {
    let url = "/dashboard"
    if (queryParamObject) url = `${url}?${queryParamObject}`
    return get(url)
}

export const fetchBranchDataAPI = (branchUID, queryParamObject) => {
    let url = `/dashboard/branch/${branchUID}`
    if (queryParamObject) url = `${url}?${queryParamObject}`
    return get(url)
}

export const getUserOptionsAPI = params => {
    let url = "/accounts/users"
    if (params) url+=`?${params}`
    return options(url)
}

export const fetchAllUsersAPI = queryParamObject => {
    let url = "/accounts/users"
    if (queryParamObject) url = `${url}?${queryParamObject}`
    return get(url)
}

export const fetchAgentDataAPI = (userID, queryParamObject) => {
    let url = `/dashboard/agent/${userID}`
    if (queryParamObject) url = `${url}?${queryParamObject}`
    return get(url)
}

export const fetchBranchOptionsAPI = () => get("/accounts/options/usertypes/branches")

export const fetchBranchGroupsOptionsAPI = (branchUID) => get(`/accounts/options/branch/${branchUID}/groups`)

export const getDynamicUserOptionsAPI = payload => post("/accounts/options/user/custom", {'data': payload})

export const getPendingRequestsAPI = queryParamObject => {
    let url = "/accounts/users/pending/activation"
    if (queryParamObject) url = `${url}?${queryParamObject}`
    return get(url)
}

export const resendActivationLinkAPI = payload => post("/accounts/manualsignup/resend/activationlink", {'usernames': payload})

export const updatePendingRequestsAPI = (usernames) => patch("/accounts/users/pending/activation/activate", {payload: usernames})
