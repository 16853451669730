export const GET_BRANCHES = "GET_BRANCHES"
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL"
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS"

export const ADD_BRANCH = "ADD_BRANCH"
export const ADD_BRANCH_SUCCESS = "ADD_BRANCH_SUCCESS"
export const ADD_BRANCH_FAIL = "ADD_BRANCH_FAIL"

export const UPDATE_BRANCH = "UPDATE_BRANCH"
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS"
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL"

export const DELETE_BRANCH = "DELETE_BRANCH"
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS"
export const DELETE_BRANCH_FAIL = "DELETE_BRANCH_FAIL"

export const GET_UNAS_USERS = "GET_UNAS_USERS"
export const GET_UNAS_USERS_FAIL = "GET_UNAS_USERS_FAIL"
export const GET_UNAS_USERS_SUCCESS = "GET_UNAS_USERS_SUCCESS"

export const GET_BRANCH_GROUPS = "GET_BRANCH_GROUPS"
export const GET_BRANCH_GROUPS_FAIL = "GET_BRANCH_GROUPS_FAIL"
export const GET_BRANCH_GROUPS_SUCCESS = "GET_BRANCH_GROUPS_SUCCESS"

export const GET_GROUP_DETAIL = "GET_GROUP_DETAIL"
export const GET_GROUP_DETAIL_FAIL = "GET_GROUP_DETAIL_FAIL"
export const GET_GROUP_DETAIL_SUCCESS = "GET_GROUP_DETAIL_SUCCESS"

export const ADD_GROUP = "ADD_GROUP"
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS"
export const ADD_GROUP_FAIL = "ADD_GROUP_FAIL"

export const UPDATE_GROUP = "UPDATE_GROUP"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL"

export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL"

export const ADD_USERS = "ADD_USERS"
export const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS"
export const ADD_USERS_FAIL = "ADD_USERS_FAIL"

export const REMOVE_USER_FROM_GROUP = "REMOVE_USER_FROM_GROUP"
export const REMOVE_USER_FROM_GROUP_SUCCESS = "REMOVE_USER_FROM_GROUP_SUCCESS"
export const REMOVE_USER_FROM_GROUP_FAIL = "REMOVE_USER_FROM_GROUP_FAIL"
