import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import {
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Container,
  Button,
} from "reactstrap"
import Dropzone from "react-dropzone"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import 'react-circular-progressbar/dist/styles.css';
import { isEmpty } from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { postBatches, getSignedURLs as onGetSignedURLs, checkStorage as onCheckStorage, resetStateCommon, resetStateSettings } from "store/actions";
import ReactApexChart from "react-apexcharts";


const CallUpload = () => {
  const dispatch = useDispatch()

  const [uploadingCalls, setUploadingCalls] = useState([])
  const [selectedFiles, setSelectedFiles] = useState({})
  const [uploadStatus, setUploadStatus] = useState(null)
  const [callStat, setCallStat] = useState({
    success: 0, failed: 0, total: 0, percentUploaded: 0
  })

  const { success, error, storageCheck, loading, checkedCalls, signedURLs, commonError } = useSelector(state => ({
    success: state.Common.success,
    error: state.Settings.error,
    storageCheck: state.Settings.storageCheck,
    loading: state.Settings.loading,
    checkedCalls: state.Common.checkedCalls,
    signedURLs: state.Common.signedURLs,
    commonError: state.Common.error
  }))

  var callsToDump = []

  useEffect(() => {
    dispatch(onCheckStorage())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(checkedCalls)) {
      let dateObject = moment(new Date(), moment.defaultFormatUtc).format('YYYY/MM/DD');
      let buffer = []
      checkedCalls.map(val => {
        if (!val.existed) {
          uploadingCalls.forEach(file => {
            if (val.name === cleanFilename(file.name)) {
              upload(file, dateObject, val.uid)
            }
          })
        } else {
          val.progress = 100
        }
        return buffer.push(val)
      })
      setResponsedCalls(buffer)
    }
  }, [checkedCalls])

  var suc = 0
  var fail = 0
  useEffect(() => {
    if (!isEmpty(signedURLs)) {
      setUploadStatus("Preparing calls. please wait...")
      signedURLs.map(async (signedURL) => {
        await fetch(signedURL.url, {
          method: 'PUT',
          headers: {
            "Content-Type": "multipart/formdata"
          },
          body: selectedFiles[signedURL.uid]
        }).then(response => {
          setUploadStatus("Uploading...")
          if (response.status===200) {
            let call = selectedFiles[signedURL.uid]
            suc++
            let uploadPercent = Math.ceil(((callStat.failed+suc)/callStat.total)*100)
            let url = signedURL.url.split("?")[0].split(".com/")[1]

            upload(call, url, uploadPercent)

            setCallStat(preState => ({ ...preState, success: suc, percentUploaded: uploadPercent>100 ? 100 : uploadPercent }))
          } else {
            fail++
            let uploadPercent = Math.ceil(((fail+callStat.success)/callStat.total)*100)
            setCallStat(preState => ({ ...preState, failed: fail, percentUploaded: uploadPercent>100 ? 100 : uploadPercent }))
          }
        }).catch(err => {
          setUploadStatus("Uploading...")
          fail++
          let uploadPercent = Math.ceil(((fail+callStat.success)/callStat.total)*100)
          setCallStat(preState => ({ ...preState, failed: fail, percentUploaded: uploadPercent>100 ? 100 : uploadPercent }))
        })
      })
    }
  }, [signedURLs])

  useEffect(() => {
    return () => {resetState()}
  }, [])

  const resetState = () => {
    setUploadStatus(null)
    dispatch(resetStateCommon())
    dispatch(resetStateSettings())
    dispatch(onCheckStorage())
  }

  const upload = (file, storage_path, uploadPercent) => {
    callsToDump.push({
      client_path: cleanFilename(file.name),
      nfs_path: storage_path,
      name: file.name,
    })
    if (uploadPercent===100 && callsToDump.length===callStat.total) {
      dispatch(postBatches(callsToDump))
    }
  }

  function handleAcceptedFiles(files) {
    let uids = []
    let callBucket = {}
    let date = moment(new Date(), moment.defaultFormatUtc).format('YYYY/MM/DD')
    files.map(file => {
      let filename = `${date}/${cleanFilename(file.name)}`
      uids.push(filename)
      callBucket[filename] = file
    })
    dispatch(onGetSignedURLs({uids: uids}))
    setCallStat({...callStat, total: files.length})
    setSelectedFiles(callBucket)
  }

  const cleanFilename = (filename) => {
    return filename.replace(/[^-_.a-z0-9]/gi, '')
  }

  const chartOptions = {
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 5,
          size: "35%",
          background: "transparent",
          image: void 0,
        },
        track: {
          show: !0,
          startAngle: void 0,
          endAngle: void 0,
          background: "#f2f2f2",
          strokeWidth: "97%",
          opacity: 1,
          margin: 12,
          dropShadow: {
            enabled: !1,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
        dataLabels: {
          name: {
            show: !0,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10,
          },
          value: {
            show: !0,
            fontSize: "14px",
            offsetY: 4,
            formatter: function (e) {
              return e + "%"
            },
          },
          total: {
            show: !0,
            label: (success && success.status) ? "Uploaded" : "Uploading",
            color: "#343a40",
            fontSize: "16px",
            fontFamily: void 0,
            fontWeight: 600,
            formatter: function (e) {
              return (
                e.globals.seriesTotals.reduce(function (e, t) {
                  return e + t
                }, 0) + " %"
              )
            },
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Process calls | Yanthura</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Process" breadcrumbItem="Calls upload" />
          {loading ?
            <div className="">
              <p className="placeholder-glow"><span className="placeholder col-12"></span></p>
            </div>
            :
            <>
              {commonError ?
              <Card>
                <CardBody>
                  <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {commonError.detail}</p>
                </CardBody>
              </Card>
              :
              <>
              {error ?
                <Card>
                  <CardBody>
                    <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {error.detail}</p>
                  </CardBody>
                </Card>
                :
                <>
                  {(storageCheck && storageCheck.detail) ?
                    <Row>
                      <Col className="col-12">
                        <Card>
                          {(success && success.status) &&
                            <div>
                              <h5 className="m-3 mb-0 text-success">Your calls are uploaded and sent for processing</h5>
                              <p className="ms-3 small text-muted"><i>(It will take a while and your results will be shown after processing)</i></p>
                              <div className="mt-3 text-end me-4">
                                <Link to={"/"} className="btn btn-primary btn-sm">Goto Dashboard</Link>
                                &nbsp;&nbsp;
                                <Button onClick={resetState} className="btn btn-primary btn-sm">Upload again</Button>
                              </div>
                            </div>
                          }
                          <CardBody>
                            <Form>
                              {uploadStatus ?
                                <div className="dropzone-previews mt-3" id="file-previews">
                                  <Card className="mt-1 mb-0 shadow-none border">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col>
                                          <p className="display-6 text-center">{(success && success.status) ? "Upload success" : uploadStatus}</p>
                                          <p className="display-6 text-end">{callStat.success + callStat.failed} / {callStat.total}</p>
                                        </Col>
                                        <Col>
                                          <div id="wallet-balance-chart">
                                            <ReactApexChart
                                              options={chartOptions}
                                              series={[callStat.percentUploaded]}
                                              type="radialBar"
                                              height={300}
                                              className="apex-charts"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                </div>
                                :
                                <Dropzone onDrop={acceptedFiles => {handleAcceptedFiles(acceptedFiles)}} accept="audio/*">
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                      <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>Drop folders here or click to upload files</h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              }
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    :
                    <Card>
                      <CardBody>
                        <p className="text-center text-danger">Storage not ready yet. {localStorage.getItem("authUser") && JSON.parse(localStorage.getItem("authUser")).role==='ADMIN' && <span><Link to={"/configurations"}>Please configure storage here</Link></span> }</p>
                      </CardBody>
                    </Card>
                  }
                </>
              }
              </>
              }
            </>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CallUpload
