import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner
} from "reactstrap"
import {
  getOptions as onGetOptions,
  listLeads as onListLeads,
  getSignedURLs as onGetSignedURLs,
  uploadLeads as onUploadLeads,
  postLeads as onPostLeads,
  updateLeads as onUpdateLeads,
  assignToAgents as onAssignToAgents,
  modifyDisplayOptions as onModifyDisplayOptions,
  resetState,
  resetStateCommon
} from "store/actions"
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import GenericTable from "components/Common/table/table"
import { isEmpty } from "lodash";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import { cleanFilenameForSign } from "components/Common/common";
import toastr from "toastr";
import LeadInfo from "./detail/lms-detail";
import { getLocalstorage } from "components/Common/common";
import { WebsocketConnection } from "components/helpers/websocket_helper";
import CheckboxDropdown from "components/Common/checkbox-dropdowns";


const Lms = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [uploadLeadsModalOpen, setUploadLeadsModalOpen] = useState(false)
  const [isOffCanvOpen, setIsOffCanvOpen] = useState(false)
  const [dataAction, setDataAction] = useState()
  const [updatingLead, setUpdatingLead] = useState({})
  const [uploadStatus, setUploadStatus] = useState(null)
  const [selectedFiles, setSelectedFiles] = useState({})
  const [columns, setColumns] = useState([])
  const [displayHeaderOptions, setDisplayHeaderOptions] = useState({})
  const [isRight, setIsRight] = useState(false)
  const [selectedLeadID, setSelectedLeadID] = useState(null)
  const [role, setRole] = useState(null)
  const [wsMessages, setWsMessages] = useState({})
  var ws = null

  const toggleEntryModal = () => {
    setIsOffCanvOpen(!isOffCanvOpen)
  }

  const { loading, error, formError, options, apiObject, signedURLs, isPosted, isUpdated, isDeleted, uploadingLeads, autoAssign, assignToAgents } = useSelector(state => ({
    loading: state.Lms.loading,
    error: state.Lms.error,
    formError: state.Lms.formError,
    options: state.Lms.options,
    apiObject: state.Lms.apiObject,
    signedURLs: state.Common.signedURLs,
    isPosted: state.Lms.isPosted,
    isUpdated: state.Lms.isUpdated,
    isDeleted: state.Lms.isDeleted,
    uploadingLeads: state.Lms.uploadingLeads,
    autoAssign: state.Lms.autoAssign,
    assignToAgents: state.Lms.assignToAgents
  }))

  useEffect(async () => {
    await setTimeout(() => {
      let body = document.body;
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }, 1);
    let authObject = JSON.parse(localStorage.getItem("authUser"))
    setRole(authObject.role)
  }, [])

  useEffect(() => {
    if (urlParams.length > 1) {
      dispatch(onGetOptions(decodeURIComponent(urlParams).replace("?", "")))
    } else {
      dispatch(onGetOptions())
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(options)) {
      dispatch(onListLeads(decodeURIComponent(urlParams).replace("?", "")))
      setFilter(options)
      let buffer = options.misc.display_columns.map(item => {
        if (item.dataField === "id") {
          return {
            ...item,
            formatter: (cellContent, row) => (
              <Link to="#" title={`${row.id}`} onClick={() => toggleRightCanvas(row.id)}>{row.id}</Link>
            )
          }
        } else if (item.dataField === "status") {
          return {
            ...item,
            formatter: (cellContent, row) => (
              getStatusColor(row.status)
            )
          }
        } else if (item.dataField === "assigned_to") {
          return {
            ...item,
            formatter: (cellContent, row) => (
              <p>{row.assigned_to ? row.assigned_to.full_name : "---"}</p>
            )
          }
        } else {
          return { ...item }
        }
      })
      setColumns(buffer)
      setDisplayHeaderOptions({
        all: options.misc.all_columns,
        statusOptions: options.misc.status_options
      })
    }
  }, [options])

  useEffect(() => {
    if (!isEmpty(signedURLs)) {
      setUploadStatus("Importing leads")
      let filenames = []
      Promise.all(signedURLs.map(signedURL => fetch(signedURL.url, {
        method: 'PUT',
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: selectedFiles[signedURL.uid]
      }))).then(responses => {
        responses.map(response => {
          if (response.status === 200) {
            filenames.push(response.url)
          }
        })
        if (!isEmpty(filenames)) {
          // console.log("now uploading leads api");
          setUploadStatus("Finishing")
          dispatch(onUploadLeads({ filenames: filenames }))
        }
      }).catch(err => {
        console.log(err);
        setUploadStatus("UPLOAD_ERROR")
      })
    }
  }, [signedURLs])

  useEffect(() => {
    return () => {
      closeWsConnection()
      dispatch(resetState())
      dispatch(resetStateCommon())
    }
  }, [])

  useEffect(() => {
    if (uploadingLeads === true) {
      // console.log("dumped leads in database useEffect(uploadingLeads)")
      setWsMessages({ message: "LEADS_PROCESSING" })
      ws = WebsocketConnection(`/leads/${getLocalstorage("authUser").uid}`)
      ws.onmessage = function (response) {
        if (response.data && !isEmpty(JSON.parse(response.data).errors)) {
          let wsErrors = JSON.parse(response.data).errors
          setWsMessages({ errors: wsErrors })
        } else if (response.data && JSON.parse(response.data).code === "SUCCESS") {
          setUploadStatus("UPLOAD_DONE")
          dispatch(onListLeads())
        }
        closeWsConnection()
      }
    } else if (uploadingLeads === false) {
      setUploadStatus("UPLOAD_ERROR")
    }
  }, [uploadingLeads])

  useEffect(() => {
    if (isPosted) {
      // console.log('leads posted useEffect(isPosted)');
      setDataAction(null)
      toggleEntryModal()
    }
  }, [isPosted])

  useEffect(() => {
    if (isUpdated) {
      setDataAction(null)
      toggleEntryModal()
      setUpdatingLead({})
    }
  }, [isUpdated])

  useEffect(() => {
    if (assignToAgents) {
      toastr.success(assignToAgents.detail)
      dispatch(onListLeads())
    }
  }, [assignToAgents])

  const closeWsConnection = () => {
    if (ws !== null) ws.close()
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(onListLeads(urlParams))
    } else {
      dispatch(onListLeads())
    }
  }

  function toggleLeadUpload(def = uploadLeadsModalOpen) {
    setUploadLeadsModalOpen(!def)
    closeWsConnection()
  }

  const onKeyPress = (event) => {
    if (event.which === 13) event.preventDefault()
  }

  const handleLeadSubmission = (event, values) => {
    values.enquiry_date = moment(values.enquiry_date).toISOString()
    values.followup_date = moment(values.followup_date).toISOString()
    if (dataAction === "add") {
      dispatch(onPostLeads(values))
    } else if (dataAction === "edit") {
      dispatch(onUpdateLeads(updatingLead.id, values))
    }
  }

  const handleAcceptedFiles = (files) => {
    setUploadStatus("Parsing")
    let filenames = []
    let callBucket = {}
    let date = moment(new Date(), moment.defaultFormatUtc).format('YYYY/MM/DD')
    files.map(file => {
      let filename = `leads/${date}/${cleanFilenameForSign(file.name)}`
      filenames.push(filename)
      callBucket[filename] = file
    })
    setSelectedFiles(callBucket)
    dispatch(onGetSignedURLs({ uids: filenames, custom_path: true }))
  }

  const autoAssignToAgents = () => {
    dispatch(onAssignToAgents({ limit_to: "ALL" }))
  }

  const toggleRightCanvas = (leadID) => {
    setIsRight(!isRight)
    setSelectedLeadID(leadID)
  }

  const refreshPage = () => {
    apiTrigger(decodeURIComponent(location.search).replace("?", ""))
  }

  const handleSelectedOptions = (selectedOptions) => {
    dispatch(onModifyDisplayOptions({ headers: selectedOptions }))
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Leads | Yanthura</title>
        </MetaTags>
        <Offcanvas
          isOpen={isRight}
          direction="end"
          toggle={toggleRightCanvas}>
          <OffcanvasHeader toggle={toggleRightCanvas}>
            Lead Info
          </OffcanvasHeader>
          <OffcanvasBody>
            <LeadInfo leadID={selectedLeadID} columns={displayHeaderOptions.all} statusOptions={displayHeaderOptions.statusOptions} />
          </OffcanvasBody>
        </Offcanvas>
        <Container fluid>
          {error ?
            <Card>
              <CardBody>
                {error.code === "NO_TEMPLATE" ?
                  <>
                    <div className="d-flex justify-content-center">
                      <p className="text-center text-danger">Template does not existed. Please create one here</p>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Link to="/lms/templates">Create Template</Link>
                    </div>
                  </>
                  :
                  <p className="text-center text-danger">{error.detail}</p>
                }
              </CardBody>
            </Card>
            :
            <>
              <Modal isOpen={uploadLeadsModalOpen} toggle={toggleLeadUpload} backdrop="static">
                <ModalHeader toggle={toggleLeadUpload} tag="h4">Supported formats (xlsx, xls)</ModalHeader>
                <ModalBody>
                  <div className="d-flex justify-content-center align-items-center">
                    {(uploadStatus === null) ?
                      <Dropzone onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }} accept=".xlsx, .xls">
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files/folders here or click to upload</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      : (uploadStatus === "UPLOAD_DONE") ?
                        <div className="d-flex flex-column">
                          <p className="text-muted">Leads uploading success</p><br />
                          <Button type="button" color="primary" onClick={() => setUploadStatus(null)}>Upload again</Button>
                        </div>
                        : (uploadStatus === "UPLOAD_ERROR") ?
                          <div>
                            {wsMessages.message === "LEADS_PROCESSING" &&
                              <div className="d-flex justify-content-center align-items-center">
                                <Spinner className="me-2" color="secondary" /> <span>Processing</span>
                              </div>
                            }
                            <ul>
                              {!isEmpty(wsMessages.errors) &&
                                <>
                                  <h5>Please correct the below errors and upload again</h5>
                                  <div>{wsMessages.errors.map(error => <li className="text-danger">{error}</li>)}</div>
                                </>
                              }
                            </ul>
                            {(wsMessages.message === "" && isEmpty(wsMessages.errors)) &&
                              <p>Something went wrong. Please try again</p>
                            }
                          </div>
                          :
                          <><Spinner className="me-2" color="secondary" /> {uploadStatus}</>
                    }
                  </div>
                </ModalBody>
              </Modal>
              <Modal size="xl" isOpen={isOffCanvOpen} toggle={toggleEntryModal} backdrop="static">
                <ModalHeader toggle={toggleEntryModal} tag="h4">Create Lead</ModalHeader>
                <ModalBody>
                  <AvForm className="form-horizontal" onValidSubmit={handleLeadSubmission} onKeyPress={onKeyPress}>
                    {options.misc && Object.entries(options.misc.form_schema).map(([k, v], idx) =>
                      <Row key={idx}>
                        <Col>
                          {(v.type === "text") ?
                            <div className="mb-3">
                              <AvField
                                id={`id_${k}`}
                                name={k}
                                label={v.label}
                                type={v.type}
                                required={v.required}
                                // placeholder={}
                                value={updatingLead[k] && updatingLead[k]}
                              />
                            </div>
                            : (v.type === "email") ?
                              <div className="mb-3">
                                <AvField
                                  id={`id_${k}`}
                                  name={k}
                                  label={v.label}
                                  type={v.type}
                                  errorMessage="Invalid Email"
                                  validate={{
                                    required: { value: v.required },
                                    email: { value: true },
                                  }}
                                  value={updatingLead[k] && updatingLead[k]}
                                />
                              </div>
                              : (v.type === "textarea") ?
                                <div className="mb-3">
                                  <AvField
                                    id={`id_${k}`}
                                    name={k}
                                    label={v.label}
                                    type={v.type}
                                    validate={{
                                      required: { value: v.required },
                                    }}
                                    value={updatingLead[k] && updatingLead[k]}
                                  />
                                </div>
                                : (v.type === "datetime") ?
                                  <div className="mb-3">
                                    <AvField
                                      id={`id_${k}`}
                                      name={k}
                                      label={v.label}
                                      type={v.type}
                                      value={updatingLead[k] && new Date(updatingLead[k]).toJSON().split('T')[0]}
                                    />
                                  </div>
                                  : null}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>{(isPosted || isUpdated) ?
                        <Button color="primary" className="float-end" disabled>
                          Submitting{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        </Button>
                        : <Button type="submit" color="primary" className="float-end">Submit</Button>
                      }</Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
              <div className="d-flex flex-wrap gap-2 flex-row-reverse">
                {role !== "AGENT" &&
                  <>
                    <Button type="button" color="primary" className="btn-sm" onClick={() => {
                      toggleEntryModal()
                      setDataAction("add")
                    }}>Create Lead</Button>
                    <Button type="button" color="primary" className="btn-sm" onClick={() => { setUploadLeadsModalOpen(true); setUploadStatus(null); }}>Upload Leads</Button>
                    {autoAssign ?
                      <Button type="button" color="primary" className="btn-sm" disabled>
                        Assign{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </Button>
                      :
                      <Button type="button" color="primary" className="btn-sm" onClick={() => autoAssignToAgents()}>Assign</Button>
                    }
                    <Button type="button" color="light" className="btn-sm" onClick={() => refreshPage()}>
                      Refresh{" "}<i className="bx bx-revision font-size-16 align-middle me-2"></i>
                    </Button>
                    {options.misc &&
                      <CheckboxDropdown
                        options={displayHeaderOptions.all}
                        defaultSelected={options.misc.display_columns.map(item => item.dataField)}
                        handleSelectedOptions={handleSelectedOptions}
                      />
                    }
                  </>
                }
              </div>
              {!isEmpty(columns) &&
                <GenericTable
                  urlParams={urlParams}
                  filters={filters}
                  columns={columns}
                  keyField={'id'}
                  noDataIndication={"No leads found"}
                  data={apiObject}
                  loading={loading}
                  apiTrigger={apiTrigger}
                  dropdownSearchURL={"/lms/filter/options"}
                />
              }
            </>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Lms


export const getStatusColor = (status) => {
  return (status === "UNASND" ? <Badge color="light" className="text-dark">Unassigned</Badge>
    : status === "ASND" ? <Badge>Assigned</Badge>
      : status === "CNTCD" ? <Badge color="success" className="text-dark">Contacted</Badge>
        : status === "INTR" ? <Badge color="success" className="text-dark">Interested</Badge>
          : status === "NOINTSD" ? <Badge color="danger" className="text-dark">Not Interested</Badge>
            : status === "BUSY" ? <Badge color="warning" className="text-dark">Busy</Badge>
              : status === "CNVRTD" ? <Badge color="success" className="text-dark">Converted</Badge>
                : <p>---</p>)
}