import React, { useState, useEffect } from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"

const TopBranches = (props) => {
  const { data } = props
  const [highPerformer, setHighPerformer] = useState(null)
  useEffect(() => {
    if (!isEmpty(data.branches)) {
      let topper = data.branches.reduce((max, branch) => max.value > branch.value ? max : branch)
      setHighPerformer(topper)
    }
  }, [data])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top performers</CardTitle>
          {data.branches ?
            <>
              {highPerformer &&
                <div className="text-center">
                  <div className="mb-4">
                    <i className="bx bx-map-pin text-primary display-4" />
                  </div>
                  <Link to={{pathname: `/dashboard/admin/${highPerformer.name}`, state: highPerformer}}>
                    <h3>{highPerformer.value}</h3>
                    <p>{highPerformer.name}</p>
                  </Link>
                </div>
              }

              <div className="table-responsive mt-4">
                <table className="table align-middle table-nowrap">
                  <tbody>
                    {data.branches.map((branch, idx) => 
                      <tr key={idx}>
                        <td style={{ width: "30%" }}>
                          <Link to={{pathname: `/dashboard/admin/${branch.name}`, state: branch}}>
                            <p className="mb-0">{branch.name}</p>
                          </Link>
                        </td>
                        <td style={{ width: "25%" }}>
                          <h5 className="mb-0">{branch.value}</h5>
                        </td>
                        <td>
                          <Progress
                            value={branch.value}
                            color={(branch.value>0 && branch.value<=40) ? "danger" : (branch.value>40 && branch.value<=75) ? "warning" : "success"  }
                            className="bg-transparent progress-sm"
                            size="sm"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          :
            <p className="text-center mt-4">No performers</p>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopBranches
