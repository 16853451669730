import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, Modal, ModalHeader, ModalBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, CardBody } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import FlashAlert from "components/Common/flash-message"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { formatDateTime, genderVerboseFetch } from "components/Common/common"
import { isEmpty } from "lodash"
import { StaffCRUDModal } from "../staff/staff-crud-modals"
import { get } from "helpers/api_helper";
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getLocalStorageItem, Loader, updateURLParams } from "pages/HMS/common/common"

const Staff = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteEnabled, setDeleteEnabled] = useState(false)
  const [customError, setCustomError] = useState(null)
  const [columns, setColumns] = useState([])
  const [selectedQualifications, setSelectedQualifications] = useState(null);
  const [modalQualification, setModalQualification] = useState(false)
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [state, setState] = useState({ title: '', message: '', isExist: false, full_name: '', username: null });
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [staffList, setStaffList] = useState({})
  const [staffObj, setStaffObj] = useState({})

  const { options, hmsList, apiKey, error, formError, postResponse } = useSelector(state => ({
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
  }))

  useEffect(() => { setCustomError(formError) }, [formError])

  useEffect(() => {
    if (apiKey === "staff_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/staff/staff/", decodeURIComponent(urlParams).replace("?", ""), "staff_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.staff
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/staff/staff/", savedFilters, "staff_list"))
      }
    }
    if (["staff_list", "staff_created", "staff_updated"].includes(apiKey)) {
      setStaffList(hmsList)
    }
    if (apiKey === "staff_deleted") {
      setStaffList(hmsList)
      toggleDelete()
      toastr.success("Staff is deleted")
    }
    if (apiKey === "staff_qualification_created") {
      setSelectedQualifications(selectedQualifications === null ? [postResponse] : [...selectedQualifications, postResponse]);
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/staff/staff/?options=table, search, formschema, daterange", "staff_options"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "staff_options") {
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "full_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link
                  to="#"
                  onClick={() => handleEdit(row)}
                  title={row.user_data.email ? `Email: ${row.user_data.email}` : ""}
                >
                  {row.user_data.full_name}
                </Link>
              )
            }
          } else if (item.dataField === "username") {
            return {
              ...item,
              formatter: (cellContent, row) => row.user_data.username
            }
          } else if (item.dataField === "gender") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                genderVerboseFetch(row.user_data.gender)
              )
            }
          } else if (item.dataField === "phone_number") {
            return {
              ...item,
              formatter: (cellContent, row) => row.user_data.phone_number
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                formatDateTime(row.created_at)
              )
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "Action",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
                  Modify
                </Link>
                <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }

        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const handleEdit = staff_object => {
    setStaffObj(staff_object)
    setSelectedRoles(staff_object.user_data.groups)
    setSelectedQualifications(staff_object.qualifications)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = staff_object => {
    setStaffObj(staff_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setStaffObj({})
    setSelectedQualifications(null)
    setRUDFlag(1)
    toggle(false)
  }

  function toggle(def = modal) {
    setModal(!def)
    setCustomError(null)
    setSelectedPerson(null)
    setState((prevState) => ({ ...prevState, isExist: false }));
    handleCheckboxChange()
    if (def) {
      setSelectedRoles(null);
    }
  }

  function toggleQualification(def = modalQualification) {
    setModalQualification(!def)
    setCustomError(null)
  }

  function toggleDelete(state = true) {
    setDeleteModal(!state)
  }

  const handleCheckboxChange = (value = false) => {
    setIsChecked(value);
  };

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/staff/staff/', decodeURIComponent(urlParams).replace("?", ""), "staff_list"))
      setStaffList({})
    } else {
      dispatch(listHms('/hms/staff/staff/', null, "staff_list"))
      setStaffList({})
    }
  }

  const refreshPage = () => {
    apiTrigger(decodeURIComponent(location.search).replace("?", ""))
  }

  function createButton() {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          +&nbsp;Create
        </Button>
      </div>
    );
  }

  const updateQualificationSelect = (qualifications) => {
    setSelectedQualifications(qualifications)
  }

  var suTo = null
  const searchQualifications = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/staff/qualifications/fetch_all/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 1000)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Staff | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Row>
            {apiKey === "options_staff_options" ? (
              <Loader />
            ) : error ? (
              <Card>
                <CardBody>
                  <p className="text-center text-danger">{error.detail}</p>
                </CardBody>
              </Card>
            ) : (!isEmpty(columns)) && (
              <GenericTable
                moduleName="staff"
                urlParams={urlParams}
                search={options?.search}
                daterange={options?.daterange}
                columns={columns}
                keyField={"id"}
                noDataIndication={"No staff created"}
                data={staffList}
                loading={apiKey === "list_staff_list"}
                apiTrigger={apiTrigger}
                defaultPageSize={options?.page_size}
                refreshPage={refreshPage}
                createButton={createButton}
              />
            )}
          </Row>
        </Container>

        <Modal isOpen={deleteModal} toggle={toggleDelete} backdrop="static">
          <ModalHeader toggle={toggleDelete} tag="h4">
            ⚠️ Warning: Before deleting staff, Make sure the below things
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="col-12">
                {error &&
                  <FlashAlert color="danger" delay="10000" className="text-center">{error?.detail}</FlashAlert>
                }
                <ul>
                  <li>If staff is removed. You cannot get it back</li>
                </ul>
                <p>Type <i><b>delete permanently</b></i> to proceed delete operation</p>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <input
                    name="confirmation-text"
                    type="text"
                    className="form-control"
                    required={false}
                    onChange={(e) => e.target.value === "delete permanently" ? setDeleteEnabled(true) : setDeleteEnabled(false)}
                  />
                </div>
              </Col>
            </Row>
            <Row className="float-end">
              <Col>
                <button type="button" className="btn btn-outline-danger btn-sm me-2" disabled={!deleteEnabled}
                  onClick={() => dispatch(deleteHms("/hms/staff/staff/", staffObj?.id, "staff_deleted"))}>
                  Yes
                </button>
                <button type="button" className="btn btn-light btn-sm"
                  onClick={toggleDelete}>
                  No
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <StaffCRUDModal
          modal={modal}
          toggle={toggle}
          rudFlag={rudFlag}
          formOptions={options?.form_schema}
          data={staffObj}
          customError={customError}
          updateQualificationSelect={updateQualificationSelect}
          searchQualifications={searchQualifications}
          selectedQualifications={selectedQualifications}
          toggleQualification={toggleQualification}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          handleCheckboxChange={handleCheckboxChange}
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
          state={state}
          setState={setState}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
        />

        <SingleFieldCRUModal
          modalLabel={"Qualification"}
          modal={modalQualification}
          toggle={toggleQualification}
          rudFlag={1}
          options={{ label: "Name", required: true }}
          apiStartKey={"staff_qualification"}
          apiEndpoint={"/hms/staff/qualifications/"}
        />
      </div>
    </React.Fragment>
  )
}

export default Staff
