import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { DELETE_HMS, DELETE_DIAG_TEST_PARAM, DOWNLOAD_REPORT_HMS, GET_HMS, GET_DIAG_TEST_PARAM, LIST_HMS, LIST_DIAG_TEST_PARAM, OPTIONS_HMS, POST_HMS, POST_DIAG_TEST_PARAM, UPDATE_HMS, UPDATE_DIAG_TEST_PARAM, UPLOAD_HMS, CHAINED_OPTIONS_HMS } from "./actionTypes"
import { deleteHmsFail, deleteHmsSuccess, deleteDiagTestParamFail, deleteDiagTestParamSuccess, getHmsFail, getHmsOptionsFail, getHmsOptionsSuccess, getHmsSuccess, getDiagTestParamFail, getDiagTestParamSuccess, listHmsFail, listHmsSuccess, listDiagTestParamFail, listDiagTestParamSuccess, postHmsFail, postHmsSuccess, postDiagTestParamFail, postDiagTestParamSuccess, postUploadHmsFail, postUploadHmsSuccess, reportDownloadHmsFail, reportDownloadHmsSuccess, updateHmsFail, updateHmsSuccess, updateDiagTestParamFail, updateDiagTestParamSuccess, chainedOptionsHmsSuccess, chainedOptionsHmsFail } from "./actions"
import { deleteHmsAPI, deleteDiagTestParamAPI, getHmsAPI, getDiagTestParamAPI, listHmsAPI, listDiagTestParamAPI, optionsHmsAPI, postHmsAPI, postDiagTestParamAPI, postUploadHmsAPI, reportDownloadHmsAPI, updateHmsAPI, updateDiagTestParamAPI, chainedOptionsHmsAPI } from "./api_helper"


function* optionsHmsSaga({ api_url, key, alterPaymentModeChoices, modifiedFormSchema }) {
  try {
    const response = yield call(optionsHmsAPI, api_url)
    yield put(getHmsOptionsSuccess(response, key, alterPaymentModeChoices, modifiedFormSchema))
  } catch (error) {
    yield put(getHmsOptionsFail(error.response.data, key))
  }
}

function* listHmsSaga({ api_url, params, key }) {
  try {
    const response = yield call(listHmsAPI, { api_url, params })
    yield put(listHmsSuccess(response, key))
  } catch (error) {
    yield put(listHmsFail(error.response.data, key))
  }
}

function* getHmsSaga({ api_url, id, key }) {
  try {
    const response = yield call(getHmsAPI, { api_url, id })
    yield put(getHmsSuccess(response, key))
  } catch (error) {
    yield put(getHmsFail(error.response.data, key))
  }
}

function* postHmsSaga({ api_url, payload, key, prepend }) {
  try {
    const response = yield call(postHmsAPI, { api_url, payload })
    yield put(postHmsSuccess(response, key, prepend))
  } catch (error) {
    yield put(postHmsFail(error.response.data, key))
  }
}

function* updateHmsSaga({ api_url, id, payload, key, updateKey }) {
  try {
    const response = yield call(updateHmsAPI, { api_url, id, payload })
    yield put(updateHmsSuccess(response, key, updateKey))
  } catch (error) {
    yield put(updateHmsFail(error.response.data, key))
  }
}

function* deleteHmsSaga({ api_url, id, key }) {
  try {
    const response = yield call(deleteHmsAPI, { api_url, id })
    yield put(deleteHmsSuccess(id, key))
  } catch (error) {
    yield put(deleteHmsFail(error.response.data, key))
  }
}

function* reportDownloadHmsSaga({ api_url, key }) {
  try {
    const response = yield call(reportDownloadHmsAPI, { api_url, key })
    yield put(reportDownloadHmsSuccess(response, key))
  } catch (error) {
    yield put(reportDownloadHmsFail(error.response.data, key))
  }
}

function* postUploadHmsSaga({ api_url, payload, key }) {
  try {
    const response = yield call(postUploadHmsAPI, { api_url, payload })
    yield put(postUploadHmsSuccess(response, key))
  } catch (error) {
    yield put(postUploadHmsFail(error.response, key))
  }
}

function* listDiagTestParamSaga({ api_url, params, key }) {
  try {
    const response = yield call(listDiagTestParamAPI, { api_url, params })
    yield put(listDiagTestParamSuccess(response, key))
  } catch (error) {
    yield put(listDiagTestParamFail(error.response.data, key))
  }
}

function* getDiagTestParamSaga({ api_url, id, key }) {
  try {
    const response = yield call(getDiagTestParamAPI, { api_url, id })
    yield put(getDiagTestParamSuccess(response, key))
  } catch (error) {
    yield put(getDiagTestParamFail(error.response.data, key))
  }
}

function* postDiagTestParamSaga({ api_url, payload, key, prepend }) {
  try {
    const response = yield call(postDiagTestParamAPI, { api_url, payload })
    yield put(postDiagTestParamSuccess(response, key, prepend))
  } catch (error) {
    yield put(postDiagTestParamFail(error.response.data, key))
  }
}

function* updateDiagTestParamSaga({ api_url, id, payload, key }) {
  try {
    const response = yield call(updateDiagTestParamAPI, { api_url, id, payload })
    yield put(updateDiagTestParamSuccess(response, key))
  } catch (error) {
    yield put(updateDiagTestParamFail(error.response.data, key))
  }
}

function* deleteDiagTestParamSaga({ api_url, id, key }) {
  try {
    const response = yield call(deleteDiagTestParamAPI, { api_url, id })
    yield put(deleteDiagTestParamSuccess(id, key))
  } catch (error) {
    yield put(deleteDiagTestParamFail(error.response.data, key))
  }
}

function* chainedOptionsHmsSaga({ api_url, params, key }) {
  try {
    const response = yield call(chainedOptionsHmsAPI, { api_url, params })
    yield put(chainedOptionsHmsSuccess(response, key))
  } catch (error) {
    yield put(chainedOptionsHmsFail(error.response.data, key))
  }
}

function* hmsSaga() {
  yield takeEvery(OPTIONS_HMS, optionsHmsSaga)
  yield takeLatest(LIST_HMS, listHmsSaga)
  yield takeEvery(GET_HMS, getHmsSaga)
  yield takeEvery(POST_HMS, postHmsSaga)
  yield takeEvery(UPDATE_HMS, updateHmsSaga)
  yield takeEvery(DELETE_HMS, deleteHmsSaga)
  yield takeEvery(DOWNLOAD_REPORT_HMS, reportDownloadHmsSaga)
  yield takeEvery(UPLOAD_HMS, postUploadHmsSaga)
  yield takeLatest(LIST_DIAG_TEST_PARAM, listDiagTestParamSaga)
  yield takeEvery(GET_DIAG_TEST_PARAM, getDiagTestParamSaga)
  yield takeEvery(POST_DIAG_TEST_PARAM, postDiagTestParamSaga)
  yield takeEvery(UPDATE_DIAG_TEST_PARAM, updateDiagTestParamSaga)
  yield takeEvery(DELETE_DIAG_TEST_PARAM, deleteDiagTestParamSaga)
  yield takeLatest(CHAINED_OPTIONS_HMS, chainedOptionsHmsSaga)
}

export default hmsSaga