import { useEffect } from "react"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import { Alert, Button, Col, Row } from "reactstrap"
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from "react-redux"
import { registerVerification } from "store/actions"
import { EAvFieldInput } from "pages/HMS/common/errored-avfields"

const OTPSend = props => {
  const dispatch = useDispatch()

  const { loading, formError, apiKey } = useSelector(state => ({
    loading: state.Account.loading,
    formError: state.Account.formError,
    apiKey: state.Account.apiKey,
  }))

  useEffect(() => {
    if (apiKey === "forgot-password") {
      props.handler('verification')
    }
  }, [apiKey])

  const handleSubmit = (event, values) => {
    values.action = "forgot-password"
    dispatch(registerVerification(values, "forgot-password"))
  }

  return (
    <div className="p-2">
      {formError?.detail && <Alert color="danger">{formError?.detail}</Alert>}
      {formError?.otp && <Alert color="danger">{formError?.otp}</Alert>}
      <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
        <div className="form-group">
          <EAvFieldInput
            field="username"
            type="text"
            placeholder="Enter username or Login ID"
            isError={formError?.username}
            options={{ label: "Username", required: true }}
          />
        </div>
        <Row className="form-group mt-4">
          <Col className="text-end">
            <div className="text-end">
              {loading ?
                <Button color="primary" className="btn btn-primary btn-sm w-md" disabled>
                  Next{" "}
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                </Button>
                :
                <Button color="primary" className="btn btn-primary btn-sm w-md" type="submit">Next</Button>
              }
            </div>
          </Col>
        </Row>
      </AvForm>
    </div>
  )
}

export default OTPSend;