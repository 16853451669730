import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "assets/yanthura/images/logoIcon.png"
import SweetAlert from "react-bootstrap-sweetalert"
import InitiateRegister from "./Register/register-form"
import RegisterVerification from "./Register/verify"
import ConfirmPassword from "./Register/ConfirmPassword"
import { hybridErrorAlertDisplay } from "components/Common/common"

const Register = props => {
  const [state, setState] = useState('initiate')

  const { formError } = useSelector(state => ({
    formError: state.Account.formError,
  }))

  function getQueryParam(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return (false);
  }

  const handler = (state, componentObject) => {
    setState(state)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Yanthura | Register</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Yanthura</h5>
                        <p>Create an account here</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {state === 'initiate' ?
                      <InitiateRegister handler={handler} getQueryParam={getQueryParam} />
                      : state === 'verification' ?
                        <RegisterVerification handler={handler} />
                        : state === 'confirmation' ?
                          <ConfirmPassword handler={handler} purpose={"signup"} />
                          : state === 'finish' ?
                            <SweetAlert
                              title="Registration is success"
                              success
                              confirmBtnBsStyle="success"
                              cancelBtnBsStyle="danger"
                              onConfirm={() => {
                                window.location = "/login"
                              }}
                              confirmBtnText="Login"
                            >
                              Your registration process is successful. Please contact your administrator to activate the account
                            </SweetAlert>
                            :
                            <Alert color="danger">Signup process corrupted</Alert>
                    }
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Yanthura. All rights reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
