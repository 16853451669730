import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import moment from "moment"
import { WebsocketConnection } from "components/helpers/websocket_helper"

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false)
  const [notifications, setNotifications] = useState([])

  // useEffect(() => {
  //   const ws = WebsocketConnection("/notify/admman")
  //   // ws.onopen = function (ev) {
  //   //   console.log(ev);
  //   // }
  //   ws.onmessage = function (ev) {
  //     if (ev.data) {
  //       let responseData = JSON.parse(ev.data).data
  //       if (Array.isArray(responseData)) {
  //         setNotifications(responseData)
  //       } else {
  //         setNotifications(notifications => [responseData, ...notifications]);
  //       }
  //     }
  //   }
  //   return () => {
  //     ws.close()
  //   }
  // }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell ${notifications.length>0 && 'bx-tada'}`} />
          <span className="badge bg-danger rounded-pill">{notifications.length}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          {notifications.length===0 ?
            <div className="p-2 border-top d-grid">
              <Link
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
                to="#"
              >
                <i className="mdi mdi-bell-off-outline me-1"></i>
                {" "}
                No new notifications
              </Link>
            </div>
          :
          <>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  {" "}
                  View All
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.map((notification, idx) => (
              <Link to={notification.url ? notification.url : "#"} className="text-reset notification-item" key={idx}>
                <div className="media">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bxs-user-badge" />
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-0 mb-1 text-capitalize">
                      {notification.title}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {notification.description}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {moment(notification.created_at).fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>
              {" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
          </>
          }
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}