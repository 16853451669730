import {
    GET_CALL_DETAIL_WAVEFORM_SUCCESS,
    GET_CALL_DETAIL_WAVEFORM_FAIL,
    GET_CALL_DETAIL_WAVEFORM,
    GET_CALL_DETAIL_IN_DEPTH_SUCCESS,
    GET_CALL_DETAIL_IN_DEPTH_FAIL,
    GET_CALL_DETAIL_IN_DEPTH,
    GET_SCORES,
    GET_SCORES_SUCCESS,
    GET_SCORES_FAIL
} from "./actionTypes";

const INIT_STATE = {
    transcript: {},
    callInDepthDetail: {},
    evaluationScores: null,
    detailError: null,
    loading: false
};

const CallDetail = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CALL_DETAIL_WAVEFORM:
            return {
                ...state,
                detailError: null,
                loading: true
            }
        case GET_CALL_DETAIL_WAVEFORM_SUCCESS:
            return {
                ...state,
                transcript: action.payload,
                detailError: null,
                loading: false
            }
        case GET_CALL_DETAIL_WAVEFORM_FAIL:
            return {
                ...state,
                detailError: action.payload,
                loading: false
            }
        case GET_CALL_DETAIL_IN_DEPTH:
            return {
            ...state,
            detailError: null,
            loading: true
            }
        case GET_CALL_DETAIL_IN_DEPTH_SUCCESS:
            return {
                ...state,
                callInDepthDetail: action.payload,
                detailError: null,
                loading: false
            }
        case GET_CALL_DETAIL_IN_DEPTH_FAIL:
            return {
                ...state,
                detailError: action.payload,
                loading: false
            }
        
        case GET_SCORES:
            return {
            ...state,
            detailError: null,
            loading: true
            }
        case GET_SCORES_SUCCESS:
            return {
                ...state,
                evaluationScores: action.payload,
                detailError: null,
                loading: false
            }
        case GET_SCORES_FAIL:
            return {
                ...state,
                detailError: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default CallDetail;