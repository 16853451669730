import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
// import user1 from "../../../assets/images/users/avatar-1.jpg"
import userico from "../../../assets/yanthura/icons/user.png"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setUsername] = useState(null)
  const [role, setRole] = useState(null)
  const [avatar, setAvatar] = useState()

  useEffect(() => {
    let authUserObject = localStorage.getItem("authUser")

    if (authUserObject) {
      let obj = JSON.parse(authUserObject)
      setRole(obj.role)
      setUsername(obj.username)
      setAvatar(obj.avatar)
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          {avatar ?
            <img
              className="rounded-circle header-profile-user"
              src={avatar}
              alt="Header Avatar"
            />
            :
            <img
              className="rounded-circle header-profile-user"
              src={userico}
              alt="Header Avatar"
            />}
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{props.t("Profile")}</span>
          </Link>
          {/* <DropdownItem tag="a" href="/my-stats">
            {" "}
            <i className="bx bx-trending-up font-size-16 align-middle me-1" /> {props.t("My Stats")}{" "}
          </DropdownItem> */}
          <Link to="/users/display-settings/#font-size" className="dropdown-item">
            <i className="bx bx-cog font-size-16 align-middle me-1" />
            <span>{props.t("Display Settings")}</span>
          </Link>
          {/* {role==="ADMIN" &&
          <div>
            <DropdownItem tag="a" href="/settings">
              <i className="bx bx-cog font-size-16 align-middle me-1"/>
              {props.t("Settings")}
            </DropdownItem>
          </div>
          } */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
