import { getTemporarySessionStorage } from "components/Common/common";
import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Container } from "reactstrap"

const DisplayMe = (props) => {
  const [message, setMessage] = useState("Something went wrong. Please contact your administrator")
  const [role, setRole] = useState(null)
  useEffect(() => {
    let session_value = getTemporarySessionStorage("message")
    if (session_value) setMessage(session_value)
    let authUserObject = localStorage.getItem("authUser")
    if (authUserObject) {
      let obj = JSON.parse(authUserObject)
      setRole(obj.role)
    }
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Something went wrong | Yanthura</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Card>
            <CardBody>
              <p className="text-center text-danger">
                {message}
                <br />
                {role === "ADMIN" &&
                  <a className="text-center" href={'https://yanthura.com/client/dashboard'} target="_blank">Setup services here</a>
                }
              </p>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DisplayMe
