import { Label } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { isEmpty } from "lodash";

export const Parameter = ({ index, parameter, onParamAdd, onParamModify, onParamDelete }) => {

  const handleParamAdd = () => {
    onParamAdd(parameter)
  }

  const handleParamModify = () => {
    onParamModify(parameter)
  }

  const handleParamDelete = () => {
    onParamDelete(parameter)
  }

  return (
    <li key={index} className="mb-1">
      <Label className="mb-0 d-flex align-items-baseline">
        {!parameter?.value_type && !parameter?.reference_result && !parameter?.unit ? (
          <span className="me-3 text-truncate d-inline-block" style={{ maxWidth: "100px", fontWeight: 'bold' }} title={parameter?.name}>{parameter?.name}</span>
        ) : (
          <>
            <span className="me-3 text-truncate d-inline-block" style={{ maxWidth: "100px" }} title={parameter?.name}>{parameter?.name}</span>
            <span className="me-3">{parameter?.value_type}</span>
            <span className="me-3 text-truncate d-inline-block" style={{ maxWidth: "100px" }} title={parameter?.reference_result}>
              {parameter?.reference_result}
            </span>
          </>
        )}
        <Link
          to="#"
          className="text-secondary me-2 ms-4"
          title="Add"
          onClick={handleParamAdd}
        >
          <i className="mdi mdi-plus-thick" />
        </Link>
        <Link
          to="#"
          className="text-secondary me-2"
          title="Modify"
          onClick={handleParamModify}
        >
          <i className="mdi mdi-pencil" id="edittooltip" />
        </Link>
        <Link
          to="#"
          className="text-secondary"
          title="Delete"
          onClick={handleParamDelete}
        >
          <i className="mdi mdi-close-thick" id="deletetooltip" />
        </Link>
      </Label>
      {!isEmpty(parameter?.children) && (
        <ul className="mb-2">
          {parameter?.children?.map((child_param, idx) => (
            <Parameter
              key={idx}
              index={idx}
              parameter={child_param}
              onParamAdd={onParamAdd}
              onParamModify={onParamModify}
              onParamDelete={onParamDelete}
            />
          ))}
        </ul>
      )}
    </li>
  )
}