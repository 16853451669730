import React from "react"
import MetaTags from 'react-meta-tags';
import {
  Container,
} from "reactstrap"
import { useSelector } from "react-redux";
import Step1 from "./step1";
import Step2 from "./step2";


const TemplateRules = props => {

  const { formData } = useSelector(state => ({
    formData: state.Lms.formData,
  }))

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Template Rules</title>
        </MetaTags>
        <Container fluid>
          {formData.step===1 ?
            <Step1 />
          : formData.step===2 ?
            <Step2 />
          : null
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TemplateRules
