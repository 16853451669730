import {
  OTP,
  OTP_SUCCESS,
  OTP_ERROR,
} from "./actionTypes"

const initialState = {
  otpSuccessMsg: null,
  otpError: null,
}

const userOtp = (state = initialState, action) => {
  switch (action.type) {
    case OTP:
      state = {
        ...state,
        otpSuccessMsg: null,
        otpError: null,
      }
      break
    case OTP_SUCCESS:
      state = {
        ...state,
        otpSuccessMsg: action.payload,
      }
      break
    case OTP_ERROR:
      state = { ...state, otpError: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default userOtp
