import DefaultIfNone from "components/Common/default"
import React from "react"
import ReactApexChart from "react-apexcharts"
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Media,
  CardTitle,
  Table,
} from "reactstrap"
import Doughnut from "./doughnutchart"

const Settings = props => {
  const {data} = props

  var radialOptions = {
    chart: { sparkline: { enabled: !0 } },
    dataLabels: { enabled: !1 },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: !1 },
      },
    },
  }
  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="pe-5">
                <p className="text-muted mb-1">Hold violation</p>
                <h4 className="mb-3"><DefaultIfNone value={0} default_value={0} /></h4>
              </div>
              <div className="ps-2">
                <p className="text-muted mb-1">Flagged</p>
                <h4 className="mb-3"><DefaultIfNone value={0} default_value={0} /></h4>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="table-responsive mt-0">
              <Table className="table align-middle mb-0">
                <tbody>
                  <tr>
                    <td>
                      <h5 className="font-size-14 mb-1">Overall Score</h5>
                    </td>
                    <td>
                      <div id="score-card">
                        <ReactApexChart
                          options={radialOptions}
                          series={[data.score ? parseInt(data.score) : 0]}
                          type="radialBar"
                          height={60}
                          width={60}
                          className="apex-charts"
                        />
                      </div>
                    </td>
                    <td>
                      <h5 className="mb-0"><DefaultIfNone value={data.score && parseInt(data.score)} default_value={0} /></h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="font-size-14 mb-1">Overall Sentiment</h5>
                    </td>
                    <td>
                      <div id="score-card">
                        <ReactApexChart
                          options={radialOptions}
                          series={[data.sentiment ? parseInt(data.sentiment) : 0]}
                          type="radialBar"
                          height={60}
                          width={60}
                          className="apex-charts"
                        />
                      </div>
                    </td>
                    <td>
                      <h5 className="mb-0"><DefaultIfNone value={data.sentiment && parseInt(data.sentiment)} default_value={0} /></h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="font-size-14 mb-1">Well performed agents</h5>
                    </td>
                    <td>
                      <h5 className="mb-0"><DefaultIfNone value={data.well_performed_agents} default_value={0} /></h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h5 className="font-size-14 mb-1">Under performed agents</h5>
                    </td>
                    <td>
                      <h5 className="mb-0"><DefaultIfNone value={data.under_performed_agents} default_value={0} /></h5>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Settings
