import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, CardFooter, Collapse } from "reactstrap";
import { listHms, deleteHms, getHmsOptions, resetHmsState, updateHms, postHms, chainedOptionsHms } from "store/actions";
import toastr from "toastr";
import { useDispatch, useSelector } from "react-redux";
import GenericTable from "pages/HMS/common/table";
import { useLocation } from "react-router-dom";
import { HmsDeleteModal } from "../common/hms-crud-modals";
import { handleError } from "pages/HMS/common/common";
import { AvForm } from "availity-reactstrap-validation";
import { EAsyncSelect, EAvFieldInput } from "pages/HMS/common/errored-avfields";
import { isEmpty } from "lodash";
import { get } from "helpers/api_helper"

const Roles = props => {
  const { loading, options, hmsList, apiKey, error, formError, optionsChainedHms } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    optionsChainedHms: state.Hms.optionsChainedHms,
  }));

  const location = useLocation();
  const urlParams = location.search;
  const formRef = useRef();
  const parentCheckboxRefs = useRef({});
  const dispatch = useDispatch();
  const [role, setRole] = useState({});
  const [roleData, setRoleData] = useState({});
  const [roleModal, setRoleModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rudFlag, setRUDFlag] = useState(null);
  const [selectedPerms, setSelectedPerms] = useState([]);
  const [perms, setPerms] = useState(null);
  const [openItems, setOpenItems] = useState({});

  const columns = [
    {
      dataField: "name",
      text: "Role",
      sort: false,
      formatter: (cellContent, row) => (
        <Link to="#" onClick={() => handleEdit(row)}>{row?.name}</Link>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "",
      formatter: (cellContent, row) => (
        <UncontrolledDropdown className="ms-auto">
          <DropdownToggle className="text-muted font-size-16" color="white">
            <i className="mdi mdi-dots-horizontal"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu">
            <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
              Modify
            </Link>
            <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
              Remove
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  useEffect(() => {
    if (apiKey === "role_created") {
      toggle()
      toastr.success(`Role created successfully`)
      formRef.current?.reset()
    }
    if (apiKey === "role_modified") {
      toggle()
      toastr.success(`Role updated successfully`)
    }
    if (apiKey === "role_list" || apiKey === "role_created" || apiKey === "role_modified") {
      setRoleData(hmsList);
    }
    if (apiKey === "role_deleted") {
      setRoleData(hmsList);
      toggleDelete();
      toastr.success(`Role is deleted`);
    }
    if (apiKey === "perms_groups") {
      setPerms(prevPerms => ({ ...optionsChainedHms, results: [...prevPerms?.results || [], ...optionsChainedHms?.results] }));
    }
    if (error || (formError && apiKey.startsWith("fail_role"))) {
      const excludedKeys = ["name", "permissions"];
      handleError(error || formError, apiKey, "fail_role", excludedKeys)
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHmsOptions("/hms/roles/?options=table, search"))
    dispatch(listHms("/hms/roles/", null, "role_list"))
    dispatch(chainedOptionsHms("/accounts/permission-groups/", null, "perms_groups"))
    return () => { dispatch(resetHmsState()) };
  }, [dispatch]);

  useEffect(() => {
    requestAnimationFrame(() => {
      perms?.results?.forEach((perms) => {
        const checkboxRef = parentCheckboxRefs.current[perms.id];
        if (checkboxRef) {
          const checkedCount = perms.permissions.filter(option => selectedPerms.some(perm => perm.id === option.id)).length;
          checkboxRef.indeterminate = checkedCount > 0 && checkedCount < perms.permissions.length;
        }
      });
    });
  }, [selectedPerms, perms]);

  const handleEdit = role_object => {
    setRole(role_object);
    setSelectedPerms(role_object?.permissions_data);
    setRUDFlag(0);
    toggle(false);
  };

  const handleDelete = role_object => {
    setRole(role_object);
    toggleDelete(false);
  };

  const handleCreate = () => {
    setRole({});
    setRUDFlag(1);
    toggle(false);
  };

  const toggle = (def = roleModal) => {
    setRoleModal(!def);
    setOpenItems({});
    if (def) {
      setSelectedPerms([]);
    }
  };

  const toggleDelete = (state = true) => {
    setDeleteModal(!state);
  };

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/roles/", decodeURIComponent(urlParams).replace("?", ""), "role_list"));
      setRoleData({});
    } else {
      dispatch(listHms("/hms/roles/", null, "role_list"));
      setRoleData({});
    }
  };

  const createButton = () => {
    return (
      <div className="text-sm-start">
        <Button type="button" color="primary" className="me-2 btn-sm" onClick={handleCreate}>
          <i className="mdi mdi-plus me-1" />
          Create
        </Button>
      </div>
    );
  };

  const handleValidSubmit = (e, values) => {
    values.permissions = !isEmpty(selectedPerms) ? selectedPerms?.map(perms => (perms?.id)) : null;

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/roles/", role?.id, values, "role_modified"))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/roles/", values, "role_created"))
    }
  }

  const toggleItem = id => {
    setOpenItems(prevOpenItems => ({
      ...prevOpenItems,
      [id]: !prevOpenItems[id],
    }))
  };

  const handleCheckboxChange = (checked, permission) => {
    setSelectedPerms(prevSelectedPerms => {
      if (checked) {
        return prevSelectedPerms?.some(perm => perm?.id === permission?.id) ? prevSelectedPerms : [...prevSelectedPerms || [], permission];
      }
      return prevSelectedPerms?.filter(perm => perm?.id !== permission?.id);
    });
  };

  var suTo = null
  const searchPerms = (value, callback) => {
    value = value.trim()
    if (value !== "" && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/accounts/permission-groups/?search=${value}`).then(resp => {
          callback(resp?.results?.flatMap(item => item?.permissions))
        })
      }, 200)
    }
  }

  const togglePerms = (e, perms, selectedPerms, setSelectedPerms) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedPerms = [
        ...selectedPerms,
        ...perms?.permissions?.filter(option => !selectedPerms?.some(perm => perm?.id === option?.id))
      ];
      setSelectedPerms(newSelectedPerms);
    } else {
      const newSelectedPerms = selectedPerms?.filter(perm => !perms?.permissions?.some(option => option?.id === perm?.id));
      setSelectedPerms(newSelectedPerms);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            {error ? (
              <p className="text-center text-danger">{error?.detail}</p>
            ) : (
              <Col xs="12">
                <Card>
                  {roleData && (
                    <GenericTable
                      urlParams={urlParams}
                      search={options?.search}
                      columns={columns}
                      keyField={"id"}
                      noDataIndication={"No Roles created"}
                      data={roleData}
                      loading={loading}
                      apiTrigger={apiTrigger}
                      defaultPageSize={options?.page_size}
                      createButton={createButton}
                      removeQueryParams
                    />
                  )}
                </Card>
              </Col>
            )}
          </Row>

          <HmsDeleteModal
            body={
              <>
                <ul>
                  <li>If Role is removed. You cannot get it back</li>
                  <li>If any data linked to this role will be vanished</li>
                  <li>All permissions will be released</li>
                  <li>All users are unassigned from this role. Make sure you assign a different role for those users before deleting the current role</li>
                </ul>
              </>
            }
            loading={loading}
            error={false}
            toggleDelete={toggleDelete}
            dispatchOperation={deleteHms("/hms/roles/", role?.id, "role_deleted")}
            deleteModal={deleteModal}
          />

          <Modal isOpen={roleModal} toggle={toggle} size="lg" fade={true}>
            <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
              <ModalHeader toggle={toggle} tag="h4">{rudFlag === 0 ? "Modify Role" : rudFlag === 1 ? "Add Role" : ""}</ModalHeader>
              <ModalBody>
                <Row style={{ height: "50vh", overflowY: "auto" }}>
                  <Col xl="6" sm="12">
                    <EAvFieldInput
                      field={"name"}
                      type="text"
                      value={role?.name}
                      isError={formError?.name}
                      options={{ label: "Name", required: true }}
                    />
                    <div className="mb-3 ajax-select select2-container text-start">
                      <div className="mb-3 ajax-select select2-container text-start">
                        <EAsyncSelect
                          isMulti
                          cacheOptions
                          options={{ label: "Permissions", required: true }}
                          selectedOption={selectedPerms}
                          fetchOptions={searchPerms}
                          onSelect={(roles) => setSelectedPerms(roles || [])}
                          formError={formError?.permissions}
                          placeholder="Select permissions..."
                          getOptionLabel={e => e?.name}
                          getOptionValue={e => e.id}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl="6" sm="12" className="text-center">
                    <div>
                      {perms?.results?.map((perms) => (
                        <div className="custom-accordion" key={perms?.id}>
                          <div className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              ref={(el) => parentCheckboxRefs.current[perms.id] = el}
                              checked={perms?.permissions.every(option => selectedPerms?.some(perm => perm.id === option.id))}
                              onChange={(e) => togglePerms(e, perms, selectedPerms, setSelectedPerms)}
                              className="me-2"
                            />
                            <Link
                              className="text-body fw-medium py-1 d-flex align-items-center justify-content-between w-100"
                              onClick={() => toggleItem(perms?.id)}
                              to="#"
                            >
                              {perms?.name}
                              <i className={openItems[perms?.id] ? "mdi mdi-chevron-up accor-down-icon ms-auto" : "mdi mdi-chevron-down accor-down-icon ms-auto"} />
                            </Link>
                          </div>
                          <Collapse isOpen={openItems[perms?.id]}>
                            <div className="card border-0 shadow-none ps-2 mb-0">
                              <ul className="list-unstyled mb-0">
                                {perms?.permissions?.map((option) => (
                                  <li key={option?.id} className="d-flex align-items-start mb-n1">
                                    <input
                                      id={option?.id}
                                      type="checkbox"
                                      checked={selectedPerms?.some(perm => perm.id === option.id) ?? false}
                                      onChange={(e) => handleCheckboxChange(e.target.checked, option)}
                                      className="me-2 mt-1"
                                    />
                                    <label htmlFor={option?.id}>{option?.name}</label>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </Collapse>
                        </div>
                      ))}
                      {perms?.next && (
                        <div className="text-sm-end">
                          <Link
                            to="#"
                            className="me-2 mt-2 btn-sm btn-primary"
                            disabled={loading}
                            onClick={() => dispatch(chainedOptionsHms(perms?.next, null, "perms_groups"))}
                            title="Click to view more records"
                          >
                            View more
                          </Link>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <CardFooter className="bg-transparent border-top">
                <div className="d-flex justify-content-end m-2">
                  {loading && apiKey === "update_role_modified" || apiKey === "post_role_created" ? (
                    <Button color="primary" disabled>
                      Save
                      <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                    </Button>
                  ) : (
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                  )}
                </div>
              </CardFooter>
            </AvForm>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Roles;
