import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Card, CardBody, Container, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState, reportDownloadHms } from "store/actions"
import toastr from "toastr"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { isEmpty } from "lodash"
import { formatDateTime } from "components/Common/common"
import { PharmacyDeleteModal } from "pages/HMS/pharmacy/pharmacy-pharm-crud-modals"
import { getLocalStorageItem, HMSLink, Loader, updateURLParams } from "pages/HMS/common/common"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { DownloadModal } from "pages/HMS/common/hms-crud-modals"

const Treatment = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])
  const [treatmentObj, setTreatmentObj] = useState({})
  const [treatmentList, setTreatmentList] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [downloadInvoiceModal, setDownloadInvoiceModal] = useState(false);

  const { loading, options, hmsList, apiKey, error } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
  }))


  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/treatments/?options=formschema, table, search, daterange", "treatment_options"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => {
    if (apiKey === "treatment_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/diagnosis/treatments/", decodeURIComponent(urlParams).replace("?", ""), "treatments_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.treatment
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/diagnosis/treatments/", savedFilters, "treatments_list"))
      }
    }
    if (apiKey === "treatments_list") setTreatmentList(hmsList)
    if (apiKey === "treatment_deleted") {
      setTreatmentList(hmsList)
      toggleDelete()
      toastr.success("Treatment is deleted successfully")
    }
    if (apiKey === "treatment_invoice_receipt") setDownloadInvoiceModal(true)
  }, [apiKey])

  useEffect(() => {
    setTreatmentList(hmsList)
  }, [hmsList])

  useEffect(() => {
    if (!isEmpty(options)) {
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "patient") {
            return {
              ...item,
              formatter: (cellContent, row) => row.patient_name
            }
          } else if (item.dataField === "procedure") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/patient/treatment/${row.id}/detail`}>
                  {row.procedure_name}
                </Link>
              )
            }
          } else if (item.dataField === "performed_doctor") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.performed_doctor_name
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => formatDateTime(row.created_at)
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <HMSLink
                  onClick={() => downloadInvoice(row?.id)} apiStartKey="treatment_invoice_receipt">
                  Print Invoice
                </HMSLink>
                <Link className="dropdown-item text-0" to={`/hms/patient/treatment/add/${row.id}`}>
                  Modify
                </Link>
                <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  const handleDelete = treatment_object => {
    setTreatmentObj(treatment_object)
    toggleDelete(false)
  }

  function toggleDelete(state = true) {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/diagnosis/treatments/", decodeURIComponent(urlParams).replace("?", ""), "treatments_list"))
      setTreatmentList({})
    } else {
      dispatch(listHms("/hms/diagnosis/treatments/", null, "treatments_list"))
      setTreatmentList({})
    }
  }

  const createButton = () => {
    return (
      <div className="text-sm-start">
        <Link
          to="/hms/patient/treatment/add"
          className="me-2 btn btn-primary btn-sm"
        >
          +&nbsp;Add&nbsp;Treatment
        </Link>
      </div>
    )
  }

  const downloadInvoice = (id) => {
    if (id) {
      dispatch(reportDownloadHms(`/hms/diagnosis/treatments/${id}/invoice_receipt/`, "treatment_invoice_receipt"))
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Treatment | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Row>
            {apiKey === "options_treatment_options" ? (
              <Loader />
            ) : error ? (
              <Card>
                <CardBody>
                  <p className="text-center text-danger">{error.detail}</p>
                </CardBody>
              </Card>
            ) : (!isEmpty(columns)) && (
              <GenericTable
                moduleName="treatment"
                urlParams={urlParams}
                search={options?.search}
                daterange={options?.daterange}
                filters={options?.filters}
                columns={columns}
                keyField="id"
                noDataIndication="No records found"
                data={treatmentList}
                loading={apiKey === "list_treatments_list"}
                apiTrigger={apiTrigger}
                defaultPageSize={options?.page_size}
                createButton={createButton}
              />
            )}
          </Row>
        </Container>
      </div>
      <PharmacyDeleteModal
        body={<>
          <ul>
            <li>If treatment is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/diagnosis/treatments/", treatmentObj?.id, "treatment_deleted")}
        deleteModal={deleteModal}
      />

      <DownloadModal
        modal={downloadInvoiceModal}
        toggle={() => setDownloadInvoiceModal(false)}
        apiStartKey={"treatment_invoice_receipt"}
      />
    </React.Fragment>
  )
}

export default Treatment
