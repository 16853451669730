import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Table, Button, Label } from "reactstrap"
import { getHms, getHmsOptions, resetHmsState, updateHms } from "store/actions";
import { TableChip, formatDateTime } from "components/Common/common";
import { DownloadModal } from "../common/hms-crud-modals";

const OrderDetails = (props) => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState();
  const [downloadModal, setDownloadModal] = useState(false);

  const { loading, options, hmsObject, apiKey, error, updateResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
    updateResponse: state.Hms.updateResponse,
  }));

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy/medicine_orders/?options=formschema"))
    dispatch(getHms(`/hms/pharmacy/medicine_orders/${props.match.params.orderID}/?detail=true`, null, "fetch-order"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch]);

  useEffect(() => {
    if (apiKey === "fetch-order") setOrder(hmsObject)
    if (apiKey === "download-order-report") {
      setDownloadModal(true)
    }
    if (apiKey === "status_change") setOrder({ ...order, status: updateResponse.status })
  }, [apiKey]);

  const downloadReport = () => {
    if (order?.uid) {
      dispatch(getHms(`/hms/pharmacy/medicine_orders/${props.match.params.orderID}/report_order/`, null, "download-order-report"))
    }
  }

  const onStatusChange = (evt) => {
    dispatch(updateHms("/hms/pharmacy/medicine_orders/", order.uid, { status: evt.target.value }, "status_change"))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Order Details | Yanthura</title>
        </MetaTags>
        {apiKey === "get_fetch-order" ? (
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching Order...</p>
        ) :
          <Card>
            <CardBody>
              {error ? (
                <p className="text-center text-danger">{error.detail}</p>
              ) : (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <h5 className="mt-2">Order Details</h5>
                      <div className="mt-2">
                        {apiKey === "get_download-order-report" ? (
                          <Button color="primary" className="btn btn-sm" disabled>
                            PRINT REPORT{" "}
                            <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                          </Button>
                        ) : (
                          <Button color="primary" className="btn btn-sm" onClick={downloadReport} disabled={order?.medicines_count === 0}>
                            PRINT REPORT
                          </Button>
                        )}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip label="ID" value={order?.uid} />
                      <div className="d-flex flex-column">
                        <Label>Status</Label>
                        <select
                          value={order?.status ? order?.status : ""}
                          onChange={onStatusChange}
                          style={{
                            border: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            padding: '0',
                          }}
                          className="text-end me-3 text-muted mb-2"
                        >
                          {options?.form_schema?.status?.choices.map((choice, cidx) =>
                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <TableChip label="Ordered Date" value={formatDateTime(order?.created_at)} />
                      <TableChip label="Ordered Medicines" value={order?.medicines_count} defaultIfNull={0} />
                    </div>

                    <h5 className="mt-2">Supplier Information</h5>
                    <hr />
                    {order?.supplier?.id ?
                      <>
                        <TableChip label="Name" value={order?.supplier?.name} />
                        <TableChip label="Supplier ID" value={order?.supplier?.uid} defaultIfNull="---" />
                        <TableChip label="Contact Mail" value={order?.supplier?.email} defaultIfNull="---" />
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact Person" value={order?.supplier?.contact_person} />
                          <TableChip label="Contact Mobile" value={order?.supplier?.mobile} />
                        </div>
                        <div className="d-flex justify-content-between">
                          <TableChip label="Contact Person 2" value={order?.supplier?.contact_person_2} defaultIfNull="---" />
                          <TableChip label="Contact Mobile 2" value={order?.supplier?.alternate_mobile} defaultIfNull="---" />
                        </div>
                        <TableChip label="Website" value={order?.supplier?.website} defaultIfNull="---" />
                        <div className="d-flex justify-content-between">
                          <TableChip label="GST Number" value={order?.supplier?.gst_number} defaultIfNull="---" />
                          <TableChip label="Customer Type" value={order?.supplier?.customer_type} />
                        </div>
                        <TableChip label="Notes" value={order?.supplier?.notes} defaultIfNull="---" />

                        <h5 className="mt-2">Supplier Address</h5>
                        <hr />
                        <TableChip label="Line 1" value={order?.supplier?.address?.line1} />
                        <TableChip label="Line 2" value={order?.supplier?.address?.line2} defaultIfNull="---" />
                        <div className="d-flex justify-content-between">
                          <TableChip label="City" value={order?.supplier?.address?.city} />
                          <TableChip label="District" value={order?.supplier?.address?.district} defaultIfNull="---" />
                          <TableChip label="State" value={order?.supplier?.address?.state} />
                          <TableChip label="Pincode" value={order?.supplier?.address?.pincode} />
                        </div>
                      </>
                      :
                      <p className="text-center text-muted">Supplier information not available or deleted</p>
                    }
                  </Col>
                  <Col xl="1"></Col>
                  <Col>
                    <h5 className="mt-2">Ordered Medicines</h5>
                    <hr />
                    {order?.ordered_medicines ?
                      <div className="table-responsive" style={{ maxHeight: '74vh', overflowY: 'scroll' }}>
                        <Table>
                          <thead className="thead-light text-capitalize">
                            <tr>
                              <th>Medicine</th>
                              <th>Mg</th>
                              <th>Formula</th>
                              <th>Ordered Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order?.ordered_medicines?.map(item =>
                              <tr key={item?.id}>
                                <td>{item?.medicine?.name}</td>
                                <td>{item?.medicine?.dosage || "---"}</td>
                                <td>{item?.medicine?.formula || "---"}</td>
                                <td>{item?.quantity}</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                      :
                      <p className="text-center text-muted">Medicines not available for this order</p>
                    }
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        }
      </div>

      <DownloadModal
        modal={downloadModal}
        toggle={() => setDownloadModal(false)}
        apiStartKey={"download-order-report"}
      />
    </React.Fragment >
  );
};

export default OrderDetails;
