import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Badge, Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { deleteHms, getHmsOptions, listHms, resetHmsState } from "store/actions"
import GenericTable from "pages/HMS/common/table"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { formatDateTime, genderVerboseFetch } from "components/Common/common"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import { getLocalStorageItem, Loader, updateURLParams } from "pages/HMS/common/common"

const InPatients = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const history = useHistory()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [inpatientList, setInpatientList] = useState({})
  const [inpatientObj, setInpatientObj] = useState({})

  const { loading, error, hmsList, options, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    hmsList: state.Hms.hmsList,
    options: state.Hms.options,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    if (apiKey === "inpatient_deleted") setDeleteModal(false)
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/inpatients/?options=table, search, daterange, filters", "inpatients_options"))
    return () => { dispatch(resetHmsState()) }
  }, [])

  useEffect(() => {
    if (apiKey === "inpatients_options") {
      if (options.filters) setFilter(options.filters)
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "full_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link
                  title={`Patient ID: ${row.patient_data.uid} \nMobile No: ${row.patient_data.user_data.phone_number}`}
                  to={`/hms/patient/inpatient/${row.id}/detail`}
                >
                  {row.patient_data.first_name} {row.patient_data.last_name}
                </Link>
              )
            }
          } else if (item.dataField === "gender") {
            return {
              ...item,
              formatter: (cellContent, row) => genderVerboseFetch(row.patient_data.user_data.gender)
            }
          } else if (item.dataField === "marital_status") {
            return {
              ...item,
              formatter: (cellContent, row) => row.patient_data.marital_status ? "Married" : "Unmarried"
            }
          } else if (item.dataField === "discharge_status") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row.discharge_status ? (
                  <Badge color="secondary" pill>Discharged</Badge>
                ) : (
                  <Badge color="success" pill>Admitted</Badge>
                )
              )
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link title={formatDateTime(row.created_at)} to="#" className="text-muted">
                  {formatDateTime(row.created_at, "MMM D, YYYY")}
                </Link>
              )
            }
          } else if (item.dataField === "admission_type") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row.admission_type === "INSURANCE" ?
                  <Link to={`/hms/patient/insurance/${row.id}/detail`}>{row.admission_type}</Link> : row.admission_type
              )
            }
          } else {
            return { ...item }
          }
        }).filter(item => item.dataField !== "uid" && item.dataField !== "phone_number")

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to={`/hms/patient/inpatient/${row.id}/detail`}>
                  Detail
                </Link>
                <Link className="dropdown-item" to={`/hms/patient/inpatient/register/${row.id}`}>
                  Modify
                </Link>
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [apiKey])

  useEffect(() => {
    if (apiKey === "inpatients_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/patient/inpatients/", decodeURIComponent(urlParams).replace("?", ""), "inpatients_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.inpatients
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/patient/inpatients/", savedFilters, "inpatients_list"))
      }
    }
    if (apiKey === "inpatients_list") {
      setInpatientList(hmsList)
    }
    if (apiKey === "outpatient_deleted") {
      setInpatientObj(hmsList)
      toggleDelete()
      toastr.success(`Outpatient ${inpatientObj?.patient_data?.user_data?.full_name} is deleted`)
    }
  }, [apiKey])

  const toggleDelete = (def = deleteModal) => {
    setDeleteModal(!def)
  }

  const handleDelete = (outpatient_object) => {
    setInpatientObj(outpatient_object)
    toggleDelete(false)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/patient/inpatients/", urlParams, "inpatients_list"))
      setInpatientList({})
    } else {
      dispatch(listHms("/hms/patient/inpatients/", null, "inpatients_list"))
      setInpatientList({})
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Inpatients | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_inpatients_options" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="inpatients"
              urlParams={urlParams}
              filters={filters}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField="id"
              noDataIndication="No records existed"
              data={inpatientList}
              loading={apiKey === "list_inpatients_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
            />
          )}
        </Container>
      </div>

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If inpatient is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/patient/inpatients/", inpatientObj?.id, "inpatient_deleted")}
        deleteModal={deleteModal}
      />
    </React.Fragment>
  )
}

export default InPatients
