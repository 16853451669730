import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Row, Col } from "reactstrap"
import { getHmsOptions, postHms, resetHmsState, updateHms } from "store/hms/actions"
import { AvForm } from "availity-reactstrap-validation"
import { EAvFieldCheck, EAvFieldGenericInput, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import { Loader, handlePhonenumberBlur, handlePhonenumberFocus, SubmitLoaderButton } from "pages/HMS/common/common"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const PharmacyHome = ({ configuration }) => {
  const dispatch = useDispatch()
  const formRef = useRef(null)
  const [phonenumber, setPhonenumber] = useState(null)
  const [ownerContact, setOwnerContact] = useState(null)
  const [phoneError, setPhoneError] = useState('')

  const { error, options, formError, apiKey, actionStatus, modifiedFormSchema } = useSelector(state => ({
    error: state.Hms.error,
    options: state.Hms.options,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    actionStatus: state.Hms.actionStatus,
    modifiedFormSchema: state.Hms.modifiedFormSchema,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/pharmacy-configuration/?options=formschema", "pharmacy_configuration_options", false, true))
    return () => dispatch(resetHmsState())
  }, [])

  const handleSubmit = (event, values) => {
    if (!phonenumber) {
      setPhoneError('This field is invalid')
      return
    }
    setPhoneError('')

    values.phone_number = phonenumber
    values.owner_contact = ownerContact

    if (configuration?.id) {
      dispatch(updateHms("hms/pharmacy-configuration/", configuration?.id, values, "configuration_pharmacy_update"))
    } else {
      dispatch(postHms("hms/pharmacy-configuration/", values, "configuration_pharmacy_post"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pharmacy | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          <Col xl={(["configuration_pharmacy_post_success", "configuration_pharmacy_update_success"].includes(actionStatus)) ? 6 : 12}>
            <Card>
              <CardBody>
                {error ? (
                  <p className="text-center text-danger">{error?.detail}</p>
                ) : (["options_pharmacy_configuration_options", "get_fetch_pharmacy_configuration"].includes(apiKey)) ? (
                  <Loader />
                ) : (
                  <>
                    {(["configuration_pharmacy_post_success", "configuration_pharmacy_update_success"].includes(actionStatus)) ? (
                      <div className="p-2">
                        <div className="text-center">
                          <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                          <div className="p-2 mt-2">
                            <h4>Configuration {actionStatus === "configuration_pharmacy_update_success" ? "modified" : "created"} successfully</h4>
                            <div className="mt-4 d-flex justify-content-center flex-wrap">
                              <Link
                                to="#"
                                className={window.innerWidth <= 425 ? "btn-sm ms-1 btn btn-primary mb-1" : "ms-1 btn btn-primary mb-1"}
                                onClick={() => window.location.replace("/hms/pharmacy/home")}
                              >
                                Explore
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (options?.form_schema && (
                      <AvForm className="form-horizontal" onValidSubmit={handleSubmit} ref={formRef}>
                        <Row className="m2-4">
                          <Col>
                            <Row>
                              <Col>
                                <EAvFieldGenericInput
                                  value={configuration?.name}
                                  isError={formError?.name}
                                  {...modifiedFormSchema?.name}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={12}>
                                <div className="mb-3">
                                  <PhonenumberInput
                                    label="Mobile"
                                    name="phone_number"
                                    value={configuration?.phone_number}
                                    error={formError?.phone_number || phoneError}
                                    required={options?.form_schema?.phone_number?.required}
                                    updateValue={setPhonenumber}
                                    onFocus={() => handlePhonenumberFocus(setPhoneError)}
                                    onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                                  />
                                </div>
                              </Col>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.email}
                                  isError={formError?.email}
                                  {...modifiedFormSchema?.email}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Row>
                                <Col>
                                  <EAvFieldGenericInput
                                    value={configuration?.website}
                                    isError={formError?.website}
                                    {...modifiedFormSchema?.website}
                                  />
                                </Col>
                              </Row>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.drug_license_number1}
                                  isError={formError?.drug_license_number1}
                                  {...modifiedFormSchema?.drug_license_number1}
                                />
                              </Col>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.drug_license_number2}
                                  isError={formError?.drug_license_number2}
                                  {...modifiedFormSchema?.drug_license_number2}
                                />
                              </Col>
                            </Row>
                            <Row className="mt-1">
                              <Col>
                                <EAvFieldCheck
                                  name="home_delivery"
                                  value={configuration?.home_delivery}
                                  checked={configuration?.home_delivery}
                                  formError={formError?.home_delivery}
                                  options={options?.form_schema?.home_delivery}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <h5 className="mt-2 text-muted">Address</h5>
                                <hr />
                                <Row>
                                  <Col md={6} sm={12}>
                                    <EAvFieldGenericInput
                                      value={configuration?.address1}
                                      isError={formError?.address1}
                                      style={{ height: "150px", resize: "none" }}
                                      {...modifiedFormSchema?.address1}
                                      type="textarea"
                                    />
                                  </Col>
                                  <Col md={6} sm={12}>
                                    <EAvFieldGenericInput
                                      value={configuration?.address2}
                                      isError={formError?.address2}
                                      style={{ height: "150px", resize: "none" }}
                                      {...modifiedFormSchema?.address2}
                                      type="textarea"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl="1"></Col>
                          <Col>
                            <h5 className="mt-2 text-muted">Tax Information</h5>
                            <hr />
                            <Row>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.business_identification_number}
                                  isError={formError?.business_identification_number}
                                  {...modifiedFormSchema?.business_identification_number}
                                />
                              </Col>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.tax_country}
                                  isError={formError?.tax_country}
                                  {...modifiedFormSchema?.tax_country}
                                />
                              </Col>
                            </Row>
                            <h5 className="mt-2 text-muted">Registration Details</h5>
                            <hr />
                            <Row>
                              <Col md={4} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.registration_number}
                                  isError={formError?.registration_number}
                                  {...modifiedFormSchema?.registration_number}
                                />
                              </Col>
                              <Col md={4} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.registration_date}
                                  isError={formError?.registration_date}
                                  {...modifiedFormSchema?.registration_date}
                                  daterange={{ start: { value: -100, units: "years" }, end: { value: 100, units: "years" } }}
                                />
                              </Col>
                              <Col md={4} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.issuing_authority}
                                  isError={formError?.issuing_authority}
                                  {...modifiedFormSchema?.issuing_authority}
                                />
                              </Col>
                            </Row>
                            <h5 className="mt-2 text-muted">Operating Hours</h5>
                            <hr />
                            <Row>
                              <Col md={4} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.opening_time}
                                  isError={formError?.opening_time}
                                  {...modifiedFormSchema?.opening_time}
                                />
                              </Col>
                              <Col md={4} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.closing_time}
                                  isError={formError?.closing_time}
                                  {...modifiedFormSchema?.closing_time}
                                />
                              </Col>
                              <Col md={4} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.days_of_operation}
                                  isError={formError?.days_of_operation}
                                  {...modifiedFormSchema?.days_of_operation}
                                />
                              </Col>
                            </Row>
                            <h5 className="mt-2 text-muted">Owner/Manager Details</h5>
                            <hr />
                            <Row>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.owner_name}
                                  isError={formError?.owner_name}
                                  {...modifiedFormSchema?.owner_name}
                                />
                              </Col>
                              <Col md={6} sm={12}>
                                <div className="mb-3">
                                  <PhonenumberInput
                                    label="Mobile"
                                    name="phone_number"
                                    value={configuration?.owner_contact}
                                    error={formError?.owner_contact}
                                    required={options?.form_schema?.owner_contact?.required}
                                    updateValue={setOwnerContact}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.owner_email}
                                  isError={formError?.owner_email}
                                  {...modifiedFormSchema?.owner_email}
                                />
                              </Col>
                              <Col md={6} sm={12}>
                                <EAvFieldGenericInput
                                  value={configuration?.owner_designation}
                                  isError={formError?.owner_designation}
                                  {...modifiedFormSchema?.owner_designation}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <RequiredFieldsMessage />
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <SubmitLoaderButton
                                type="submit"
                                loading={[`update_configuration_pharmacy_update`, `post_configuration_pharmacy_post`].includes(apiKey)}
                              >
                                Submit
                              </SubmitLoaderButton>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    )
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment >
  )
}

export default PharmacyHome
