import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { ASSIGN_TO_AGENTS, CHECK_HEADERS, DELETE_LEAD, DELETE_TEMPLATE, GET_LEAD, GET_TEMPLATE, LEAD_DASHBOARD, LIST_LEADS, LIST_TEMPLATES, MODIFY_DISPLAY_OPTIONS, OPTIONS_LEADS, POST_LEAD, POST_TEMPLATE, UPDATE_LEAD, UPDATE_TEMPLATE, UPLOAD_LEADS } from "./actionTypes"
import { assignToAgentsFail, assignToAgentsSuccess, checkHeadersFail, checkHeadersSuccess, deleteLeadFail, deleteLeadSuccess, deleteTemplateFail, deleteTemplateSuccess, getLeadFail, getLeadSuccess, getOptionsFail, getOptionsSuccess, getTemplateFail, getTemplateSuccess, leadDashboardFail, leadDashboardSuccess, listLeadsFail, listLeadsSuccess, listTemplatesFail, listTemplatesSuccess, modifyDisplayOptionsFail, modifyDisplayOptionsSuccess, postLeadsFail, postLeadsSuccess, postTemplateFail, postTemplateSuccess, updateLeadsFail, updateLeadsSuccess, updateTemplateFail, updateTemplateSuccess, uploadLeadsFail, uploadLeadsSuccess } from "./actions"
import { assignToAgentsAPI, checkHeadersAPI, deleteLeadAPI, deleteTemplateAPI, getLeadAPI, getTemplateAPI, leadDashboardAPI, listLeadsAPI, listTemplatesAPI, modifyDisplayOptionsAPI, optionsLeadAPI, postLeadAPI, postTemplateAPI, updateLeadAPI, updateTemplateAPI, uploadLeadsAPI } from "./api_helper"


function* optionsSaga({ params }) {
  try {
    const response = yield call(optionsLeadAPI, params)
    yield put(getOptionsSuccess(response))
  } catch (error) {
    yield put(getOptionsFail(error.response.data))
  }
}

function* getLeadSaga({ leadID }) {
  try {
    const response = yield call(getLeadAPI, leadID)
    yield put(getLeadSuccess(response))
  } catch (error) {
    yield put(getLeadFail(error.response.data))
  }
}

function* listLeadsSaga({ params }) {
  try {
    const response = yield call(listLeadsAPI, params)
    yield put(listLeadsSuccess(response))
  } catch (error) {
    yield put(listLeadsFail(error.response.data))
  }
}

function* postLeadSaga({ payload }) {
  try {
    const response = yield call(postLeadAPI, payload)
    yield put(postLeadsSuccess(response))
  } catch (error) {
    yield put(postLeadsFail(error.response.data))
  }
}

function* updateLeadSaga({ leadID, payload }) {
  try {
    const response = yield call(updateLeadAPI, {leadID, payload})
    yield put(updateLeadsSuccess(response))
  } catch (error) {
    yield put(updateLeadsFail(error.response.data))
  }
}

function* deleteLeadSaga({ leadID }) {
  try {
    const response = yield call(deleteLeadAPI, leadID)
    yield put(deleteLeadSuccess(response))
  } catch (error) {
    yield put(deleteLeadFail(error.response.data))
  }
}

function* uploadLeadsSaga({ payload }) {
  try {
    const response = yield call(uploadLeadsAPI, payload)
    yield put(uploadLeadsSuccess(response))
  } catch (error) {
    yield put(uploadLeadsFail(error.response.data))
  }
}

function* assignToAgentsSaga({ payload }) {
  try {
    const response = yield call(assignToAgentsAPI, payload)
    yield put(assignToAgentsSuccess(response))
  } catch (error) {
    yield put(assignToAgentsFail(error.response.data))
  }
}


function* listTemplatesSaga({ params }) {
  try {
    const response = yield call(listTemplatesAPI, params)
    yield put(listTemplatesSuccess(response))
  } catch (error) {
    yield put(listTemplatesFail(error.response.data))
  }
}

function* getTemplateSaga({ templateID }) {
  try {
    const response = yield call(getTemplateAPI, templateID)
    yield put(getTemplateSuccess(response))
  } catch (error) {
    yield put(getTemplateFail(error.response.data))
  }
}

function* postTemplateSaga({ payload }) {
  try {
    const response = yield call(postTemplateAPI, payload)
    yield put(postTemplateSuccess(response))
  } catch (error) {
    yield put(postTemplateFail(error.response.data))
  }
}

function* updateTemplateSaga({ templateID, payload }) {
  try {
    const response = yield call(updateTemplateAPI, {templateID, payload})
    yield put(updateTemplateSuccess(response))
  } catch (error) {
    yield put(updateTemplateFail(error.response.data))
  }
}

function* deleteTemplateSaga({ templateID }) {
  try {
    const response = yield call(deleteTemplateAPI, templateID)
    yield put(deleteTemplateSuccess(response))
  } catch (error) {
    yield put(deleteTemplateFail(error.response.data))
  }
}

function* checkHeadersSaga({ payload }) {
  try {
    const response = yield call(checkHeadersAPI, payload)
    yield put(checkHeadersSuccess(response))
  } catch (error) {
    yield put(checkHeadersFail(error.response.data))
  }
}

function* modifyDisplayOptionsSaga({ payload }) {
  try {
    const response = yield call(modifyDisplayOptionsAPI, payload)
    yield put(modifyDisplayOptionsSuccess(response))
  } catch (error) {
    yield put(modifyDisplayOptionsFail(error.response.data))
  }
}

function* leadDashboardSaga() {
  try {
    const response = yield call(leadDashboardAPI)
    yield put(leadDashboardSuccess(response))
  } catch (error) {
    yield put(leadDashboardFail(error.response.data))
  }
}
function* leadsSaga() {
  yield takeEvery(OPTIONS_LEADS, optionsSaga)
  yield takeEvery(GET_LEAD, getLeadSaga)
  yield takeLatest(LIST_LEADS, listLeadsSaga)
  yield takeEvery(POST_LEAD, postLeadSaga)
  yield takeEvery(UPDATE_LEAD, updateLeadSaga)
  yield takeEvery(DELETE_LEAD, deleteLeadSaga)
  yield takeEvery(UPLOAD_LEADS, uploadLeadsSaga)
  yield takeEvery(ASSIGN_TO_AGENTS, assignToAgentsSaga)

  yield takeLatest(LIST_TEMPLATES, listTemplatesSaga)
  yield takeEvery(GET_TEMPLATE, getTemplateSaga)
  yield takeEvery(POST_TEMPLATE, postTemplateSaga)
  yield takeEvery(UPDATE_TEMPLATE, updateTemplateSaga)
  yield takeEvery(DELETE_TEMPLATE, deleteTemplateSaga)
  yield takeEvery(CHECK_HEADERS, checkHeadersSaga)
  yield takeEvery(MODIFY_DISPLAY_OPTIONS, modifyDisplayOptionsSaga)
  yield takeEvery(LEAD_DASHBOARD, leadDashboardSaga)
}

export default leadsSaga