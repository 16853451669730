import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import Otp from "./auth/otp/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import MyStats from "./stats/reducer"
//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

// Teja added
import DashboardGenericState from "./dashboard-home/reducer";
import Manage from "./manage/reducer"
import Common from "./common/reducer"
import Settings from "./settings/reducer"
import CallDetail from "./call-detail/reducer"
import Proforma from "./proforma/reducer"
import Configs from "./config/reducer"

// Calls page
import Calls from "./call/reducer"

// Leads
import Lms from "./lms/reducer"

// HMS
import Hms from "./hms/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  Otp,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  DashboardGenericState,
  Manage,
  Common,
  Settings,
  CallDetail,
  Proforma,
  Configs,
  Calls,
  MyStats,
  Lms,
  Hms
})

export default rootReducer
