import {
  OTP_SEND,
  OTP_SEND_SUCCESS,
  OTP_SEND_ERROR,

  OTP_RESEND,
  OTP_RESEND_SUCCESS,
  OTP_RESEND_ERROR,

  OTP_VERIFY,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_ERROR,

  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  otpSendData: null,
  otpResendData: null,
  otpVerifyData: null,
  finalData: null,
  error: null,
}

const forgetPassword = (state=initialState, action) => {
  switch (action.type) {
    case OTP_SEND:
      return {
        ...state,
        otpSendData: null,
        error: null,
        loading: true,
      }
    case OTP_SEND_SUCCESS:
      return {
        ...state,
        otpSendData: action.payload,
        error: null,
        loading: false,
      }
    case OTP_SEND_ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false,
      }
    
    case OTP_RESEND:
      return {
        ...state,
        otpResendData: null,
        error: null,
        loading: true
      }
    case OTP_RESEND_SUCCESS:
      return {
        ...state,
        otpResendData: action.payload,
        error: null,
        loading: false,
      }
    case OTP_RESEND_ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false,
      }
    
    case OTP_VERIFY:
      return {
        ...state,
        otpVerifyData: null,
        error: null,
        loading: true
      }
    case OTP_VERIFY_SUCCESS:
      return {
        ...state,
        otpVerifyData: action.payload,
        error: null,
        loading: false,
      }
    case OTP_VERIFY_ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false,
      }

    case FORGET_PASSWORD:
      return {
        ...state,
        finalData: null,
        error: null,
        loading: true
      }
    case FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        finalData: action.payload,
        error: null,
        loading: false,
      }
    case FORGET_PASSWORD_ERROR:
      return { 
        ...state, 
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
  return state
}

export default forgetPassword
