import React, { useRef, useState, useEffect } from "react";
import { Card, CardTitle, InputGroup, CardBody, Button } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { postHms, listHms, deleteHms, updateHms, resetHmsState } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import { isEmpty } from "lodash";
import { convertTo12HourFormat, handleError } from "../common/common";

const Timeslot = (props) => {
  const dispatch = useDispatch();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [currentSlotValue, setCurrentSlotValue] = useState(false);
  const flatpickrRef = useRef(null);

  const { loading, hmsList, error, formError, apiKey } = useSelector((state) => ({
    loading: state.Hms.loading,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
  }));

  const toggleSlotSelection = (timeSlot) => {
    if (timeSlot?.id === selectedTimeSlot?.id) {
      setSelectedTimeSlot(null);
    } else {
      setSelectedTimeSlot(timeSlot);
    }
  };

  const convertToFormattedTime = (timeString) => {
    const dateTimeString = timeString;
    const date = new Date(dateTimeString);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    return formattedTime;
  };

  const handleDeleteTime = (tid) => {
    dispatch(deleteHms("/hms/staff/timeslots/", tid, "slot_delete"))
  }

  const handleSubmit = () => {
    if (currentSlotValue && Array.isArray(currentSlotValue) && !isEmpty(currentSlotValue)) {
      const slotValue = convertToFormattedTime(currentSlotValue);
      if (selectedTimeSlot?.id) {
        dispatch(updateHms("/hms/staff/timeslots/", selectedTimeSlot.id, { time_slot: slotValue }, "slot_update"));
      } else {
        dispatch(postHms("/hms/staff/timeslots/", { time_slot: slotValue }, "slot_post"));
      }
    }
  };

  useEffect(() => {
    dispatch(listHms("/hms/staff/timeslots/", null, "slot_list"));
    return () => { dispatch(resetHmsState()) }
  }, [dispatch]);

  const resetOnSubmit = () => {
    flatpickrRef.current.flatpickr.clear();
    setSelectedTimeSlot(null)
  }

  useEffect(() => {
    if (apiKey === "slot_post") {
      toastr.success("A time slot is added")
      setTimeSlots(hmsList)
      resetOnSubmit()
    }
    if (apiKey === "slot_update") {
      toastr.success("Time slot is updated")
      setTimeSlots(hmsList)
      resetOnSubmit()
    }
    if (apiKey === "slot_delete") {
      toastr.success("Slot removed")
      setTimeSlots(hmsList)
      resetOnSubmit()
    }
    if (apiKey === "slot_list") setTimeSlots(hmsList)
    if (error || formError && apiKey.startsWith("fail_slot")) handleError(error || formError, apiKey, "fail_slot")

  }, [apiKey]);

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
  };

  const timeSlotsStyle = {
    listStyleType: "none",
    padding: 0,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    maxWidth: "100%",
    overflowX: "auto",
  };

  const timeSlotStyle = {
    padding: "10px",
    margin: "5px",
    cursor: "pointer",
    border: "1px solid #ccc",
    borderRadius: "5px",
  };

  const selectedTimeSlotStyle = {
    ...timeSlotStyle,
    color: "#fff",
    backgroundColor: "#5465f7",
  };

  const disabledTimeSlotStyle = {
    ...timeSlotStyle,
    cursor: "not-allowed",
    backgroundColor: "#eee",
  };

  const deleteIconStyle = {
    marginLeft: "8px",
    cursor: "pointer",
  };

  return (error ? (
    <p className="text-center text-danger">{error?.detail}</p>
  ) : (
    <div>
      <Card>
        <CardBody>
          <CardTitle>Timeslots</CardTitle>
          <div className="d-flex align-items-start">
            <InputGroup>
              <Flatpickr
                className="form-control me-3"
                placeholder="Select a slot"
                options={{ enableTime: true, noCalendar: true, dateFormat: "h:i K" }}
                ref={flatpickrRef}
                onChange={value => setCurrentSlotValue(value)}
                value={selectedTimeSlot?.time_slot}
              />
            </InputGroup>
            <Button className="btn btn-sm btn-primary" disabled={loading} onClick={handleSubmit}>SAVE</Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {loading && apiKey === "list_slot_list" ? (
            <p className="text-center mt-5">Fetching results... <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
          ) : (
            isEmpty(hmsList) ? (
              <div className="text-center">
                <div className="p-2 mt-4">
                  <p className="text-muted">
                    No time slots created. Please consider adding slots.
                  </p>
                </div>
              </div>
            ) : (
              <div style={containerStyle}>
                <ul style={timeSlotsStyle}>
                  {timeSlots.length > 0 &&
                    timeSlots.map((item, idx) => (
                      <li
                        key={item.id}
                        style={item.disable ? disabledTimeSlotStyle : selectedTimeSlot === item ? selectedTimeSlotStyle : timeSlotStyle}
                        onClick={() => toggleSlotSelection(item)}
                      >
                        {convertTo12HourFormat(item.time_slot)}
                        <i className="fas fa-times" style={deleteIconStyle} onClick={() => handleDeleteTime(item.id)}></i>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
        </CardBody>
      </Card>
    </div>
  ))
};

export default Timeslot;
