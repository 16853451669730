import React, { useState, useEffect } from 'react';

const TimeSlotSelector = (props) => {
  const { timeSlots, defaultValue, isInvalid } = props;
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      handleTimeSlotSelection(defaultValue)
    } else {
      setSelectedTimeSlot('')
    }
  }, [defaultValue])

  const handleTimeSlotSelection = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
    props.onSelectTimeSlot(timeSlot)
  };

  const convertTo12HourFormat = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    const parsedHours = parseInt(hours, 10);
    // Handle 12:00 AM and 12:00 PM cases
    const formattedHours = parsedHours === 0 || parsedHours === 12 ? '12' : (parsedHours % 12).toString();
    const ampm = parsedHours >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${ampm}`;
  }


  const containerStyle = {
    fontFamily: 'Arial, sans-serif',
  };

  const timeSlotsStyle = {
    listStyleType: 'none',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '100%',
    overflowX: 'auto',
  };

  const timeSlotStyle = {
    padding: '10px',
    margin: '5px',
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderColor: isInvalid ? '#f46a6a' : '#ccc',
    borderRadius: '5px',
  };

  const selectedTimeSlotStyle = {
    ...timeSlotStyle,
    color: '#fff',
    backgroundColor: '#5465f7',
  };

  const disabledTimeSlotStyle = {
    ...timeSlotStyle,
    cursor: 'not-allowed',
    backgroundColor: '#eee',
  };

  return (
    <div style={containerStyle}>
      {timeSlots?.length > 0 ?
        <ul style={timeSlotsStyle}>
          {[...new Set(timeSlots)].sort((a, b) => a.localeCompare(b)).map((item, index) => (
            <li
              key={index}
              onClick={() => handleTimeSlotSelection(item)}
              style={item.disable
                ? disabledTimeSlotStyle : selectedTimeSlot === item
                  ? selectedTimeSlotStyle : timeSlotStyle}
            >
              {convertTo12HourFormat(item)}
            </li>
          ))}
        </ul>
        :
        <p className="text-danger ms-1">No slots available for this day</p>
      }
    </div>
  );
};

export default TimeSlotSelector;