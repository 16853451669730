import { AvField, AvForm } from "availity-reactstrap-validation"
import { hybridErrorAlertDisplay } from "components/Common/common"
import React from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { useDispatch } from "react-redux"
import { Alert, CardBody, Form } from "reactstrap"
import {
  updateNfs as onUpdateNfs
} from "store/actions"

const Verification = props => {
  const { loading, settings, nfsError } = props
  const dispatch = useDispatch()

  const handleSubmit = (event, values) => {
    dispatch(onUpdateNfs({
      action: "nfs_verify",
      nfs_mode: settings.nfs_mode,
      nfs_id: settings.object_id.id,
      code: parseInt(values.code)
    }))
  }

  return (
    <React.Fragment>
      <CardBody>
        <Alert color="success">
          Please check your bucket for the file with 6-digit verification code as a filename. Please enter the filename in the verification code
        </Alert>
        <Alert color="warning">
          If you are unable to find the file. Please go back and correct the settings
        </Alert>
        {nfsError &&
          hybridErrorAlertDisplay(nfsError)
        }
        <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
          <div className="mb-3">
            <AvField
              name="code"
              label="Verification code"
              className="form-control"
              placeholder="Enter 6-digit code"
              type="number"
              min={99999}
              max={999999}
            />
          </div>
          <div className="mt-4">
            <button className="btn btn-primary" onClick={() => props.updateStatus('VIEW')} type="button">
              Back
            </button>
            {loading ?
              <button className="btn btn-primary float-end w-md" disabled>
                Submit
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
              </button>
            :
              <button type="submit" className="btn btn-primary float-end w-md">
                Submit
              </button>
            }
          </div>
        </AvForm>
      </CardBody>
    </React.Fragment>
  )
}

export default Verification


export const Success = props => {
  const { success_message } = props

  return (
    <React.Fragment>
      <CardBody>
        <SweetAlert
          title={success_message}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            window.location = "/configurations"
          }}
          confirmBtnText="OK"
        >
        </SweetAlert>
      </CardBody>
    </React.Fragment>
  )
}

