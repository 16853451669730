import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import paginationFactory, { PaginationListStandalone, PaginationProvider, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { getProformaList as onGetProformaList } from "store/proforma/actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";


const ProformaList = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [proformasList, setProformasList] = useState([])

  const { proformaList, detailError } = useSelector(state => ({
    proformaList: state.Proforma.proformaList,
    detailError: state.Proforma.detailError,
  }))

  useEffect(() => {
    dispatch(onGetProformaList())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(proformaList)) {
      setProformasList(proformaList.results)
    }
  }, [proformaList])

  const columns = [{
    dataField: 'proforma',
    text: 'Proforma',
    formatter: (cellContent, row) => (
      <Link to={`/proforma/edit/${row.pid}/${row.id}/${row.title}`}>
        <p className="mb-2">{row.title}</p>
      </Link>
    ),
  }, {
    dataField: 'created_at',
    text: 'Time'
  }];

  const pageOptions = {
    sizePerPage: 10,
    custom: true,
  }

  const { SearchBar } = Search
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Proforma | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="View" breadcrumbItem="Proformas" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={proformasList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={proformasList}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="mb-2 me-2"
                                    onClick={() => history.push('/proforma/create/new/467/UntitledProforma')}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Create proforma
                                  </Button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    noDataIndication={() =>
                                      <h5 className="text-center text-danger">{detailError ? detailError : "No proformas found"}</h5>
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ProformaList
