export const OTP_SEND = "OTP_SEND"
export const OTP_SEND_SUCCESS = "OTP_SEND_SUCCESS"
export const OTP_SEND_ERROR = "OTP_SEND_ERROR"

export const OTP_RESEND = "OTP_RESEND"
export const OTP_RESEND_SUCCESS = "OTP_RESEND_SUCCESS"
export const OTP_RESEND_ERROR = "OTP_RESEND_ERROR"

export const OTP_VERIFY = "OTP_VERIFY"
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS"
export const OTP_VERIFY_ERROR = "OTP_VERIFY_ERROR"

export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"
