import React, { useEffect, useState } from "react"
import { Alert } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { registerVerification } from "store/actions"
import { EAvFieldNumber } from "pages/HMS/common/errored-avfields"


const RegisterVerification = props => {
  const dispatch = useDispatch()
  var timer;
  const [resendSucMsg, setResendSucMsg] = useState(null)
  const [counter, setCounter] = useState(null)
  const [token, setToken] = useState(null)

  const { loading, verificationData, formError, apiKey } = useSelector(state => ({
    loading: state.Account.loading,
    verificationData: state.Account.verificationData,
    formError: state.Account.formError,
    apiKey: state.Account.apiKey,
  }))

  useEffect(() => {
    if (apiKey == "capture") {
      props.handler('verification', verificationData)
    } else if (apiKey == "resend") {
      setTimer()
    } else if (apiKey == "verify") {
      props.handler("confirmation", verificationData)
    }
    if (verificationData?.token) setToken(verificationData?.token)
  }, [apiKey])

  useEffect(() => {
    setTimer()
    return () => { clearInterval(timer) }
  }, [])

  const setTimer = () => {
    let timeDelta = 30
    timeDelta = parseInt(timeDelta)
    setCounter(timeDelta)
    timer = setInterval(() => {
      setCounter(prevCount => {
        if (prevCount === 0) {
          clearInterval(timer)
          setCounter(null)
        } else {
          return prevCount - 1
        }
      })
    }, 1000)
  }

  const handleResend = () => {
    dispatch(registerVerification({
      token: token,
      action: 'resend'
    }, "resend"))
  }

  const handleSubmit = (event, values) => {
    values.token = token
    values.action = "verify"
    dispatch(registerVerification(values, "verify"))
  }

  return (
    <div className="p-2">
      {verificationData &&
        <Alert color="success">{verificationData?.detail}</Alert>
      }
      <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
        <div className="mb-3">
          <EAvFieldNumber
            field="otp"
            isError={formError?.otp}
            options={{
              label: "One Time Password",
              required: true,
              minValue: 99999,
              maxValue: 999999
            }}
            placeholder="Enter 6-digit OTP"
          />
        </div>
        <div className="mt-4">
          <button
            className="btn btn-block"
            type="button"
            onClick={handleResend}
            disabled={counter === null ? false : true}
          >
            Resend OTP {counter && <span>({counter})</span>}
          </button>
          {loading ?
            <button className="btn btn-primary float-end" disabled>
              Verify{" "}
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            </button>
            :
            <button className="btn btn-primary float-end" type="submit">
              Verify
            </button>
          }
          {resendSucMsg &&
            <p className="text-success text-center">
              {resendSucMsg.detail}
            </p>
          }
        </div>
      </AvForm>
    </div>
  )
}

export default RegisterVerification
