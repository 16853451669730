import React, { useEffect, useRef, useState } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Row, Col, Button, Label, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getHms, getHmsOptions, postHms, reportDownloadHms, resetHmsState, updateHms } from "store/hms/actions";
import { AvForm } from "availity-reactstrap-validation";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "helpers/api_helper";
import { EAsyncSelect, EAvFieldDiscount, EAvFieldInput, EAvFieldSelect } from "pages/HMS/common/errored-avfields";
import toastr from "toastr"
import { ChequeSection, HMSLink, Loader, PatientInformation, floatRoundDown, formatPercentage, handleError, handleKeyDown } from "../../common/common";
import { PatientCRModal } from "./modals";
import { DownloadModal } from "pages/HMS/common/hms-crud-modals";
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { TestCRUModal } from "../tests/modals";
import { updateArrayObject } from "components/Common/common";

const BookTest = (props) => {
  const { error, options, hmsObject, formError, apiKey, hmsSingleObject, postResponse, updateResponse, actionStatus } = useSelector(state => ({
    error: state.Hms.error,
    options: state.Hms.options,
    hmsObject: state.Hms.hmsObject,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    hmsSingleObject: state.Hms.hmsSingleObject,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
    actionStatus: state.Hms.actionStatus,
  }))

  const dispatch = useDispatch()
  const formRef = useRef(null);
  const [patient, setPatient] = useState(null)
  const [discountBy, setDiscountBy] = useState(null)
  const [bookTestObject, setBookTestObject] = useState({})
  const [testsData, setTestsData] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState({ searchPatients: false, tests: false, discount_by: false })
  const [modals, setModals] = useState({ testModal: false, modalPatient: false, downloadInvoiceModal: false, warningModal: false });
  const [testObject, setTestObject] = useState(null)
  const [isFeeChecked, setIsFeeChecked] = useState(false);
  const [editorStates, setEditorStates] = useState(EditorState.createEmpty());
  const [formOptions, setFormOptions] = useState(null)
  const [rudFlag, setRudFlag] = useState(null)
  const [billing, setBilling] = useState({
    subTotal: 0,
    discountPercentage: 0,
    discountAmount: 0,
    cgstPercentage: 0,
    sgstPercentage: 0,
    cgstAmount: 0,
    sgstAmount: 0,
    paymentMode: "",
    grandTotal: 0,
    chequeNumber: null,
    chequeDate: null
  })

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/book-tests/?options=formschema,table,search", "bt_options", true))
    if (props.match.params.testID) dispatch(getHms("/hms/diagnosis/book-tests/", props.match.params.testID, "get_test"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "get_test") {
      setBookTestObject(hmsObject)
      setTestsData(hmsObject.tests_data)
      dispatch(getHms(`/hms/billing/billing/${hmsObject.billing}/`, null, "billing_details"))
      dispatch(getHms("/hms/patient/patients/", hmsObject.patient, "get_patient"))
    }
    if (apiKey === "get_patient") {
      setPatient(hmsObject);
    }
    if (apiKey === "billing_details") {
      setBilling({
        subTotal: hmsObject?.sub_total_amount,
        discountPercentage: hmsObject?.discount_percent,
        discountAmount: hmsObject?.discount_amount,
        cgstPercentage: hmsObject?.cgst_percent,
        sgstPercentage: hmsObject?.sgst_percent,
        cgstAmount: hmsObject?.cgst,
        sgstAmount: hmsObject?.sgst,
        paymentMode: hmsObject?.payment_mode,
        grandTotal: hmsObject?.grand_total_amount,
        chequeNumber: hmsObject?.cheque_number,
        chequeDate: hmsObject?.cheque_date,
      })
      setDiscountBy({ id: hmsObject.discount_by, full_name: hmsObject?.discount_by_name })
    }
    if (formError && apiKey.startsWith("fail_test")) {
      const excludedKeys = [
        "tests",
        "referred_by",
        "sample_collected_by",
        "result_requested_through",
        "result_delivery",
        "payment_mode",
        "discount_by",
        "discount_percent",
        "cgst_percent",
        "sgst_percent",
        "cheque_number",
        "cheque_date",
        "patient",
      ]
      handleError(formError, apiKey, "fail_test", excludedKeys)
    }
    if (apiKey === "patient_created") {
      setPatient(postResponse)
    }
    if (apiKey === "patient_updated") {
      setPatient(updateResponse)
    }
    if (apiKey === "bt_options") {
      setFormOptions(options.form_schema)
    }
    if (apiKey === "bt_receipt_invoice") {
      toggleModal("downloadInvoiceModal")
    }
    if (apiKey === "edit_test_updated") {
      setTestsData((prevData) => updateArrayObject(prevData, updateResponse))
    }
  }, [apiKey])

  const handleSubmit = (e, values) => {
    if (testsData?.some(item => item.fee === 0) && !isFeeChecked) {
      toggleModal("warningModal")
      return
    }
    values = {
      patient: patient?.id,
      tests: !isEmpty(testsData) ? testsData?.map(test => test?.id) : null,
      module: values.module ? values.module : "Direct",
      referred_by: values?.referred_by,
      sample_collected_by: values?.sample_collected_by,
      result_requested_through: values?.result_requested_through,
      result_delivery: values?.result_requested_through === "Offline" ? null : values?.result_delivery,
      billing_post: {
        customer_name: patient?.user_data?.full_name,
        payment_mode: billing?.paymentMode,
        sub_total_amount: billing?.subTotal,
        discount_percent: billing?.discountPercentage,
        discount_by: billing?.discountPercentage > 0 ? discountBy?.id : null,
        cgst_percent: billing?.cgstPercentage || 0,
        sgst_percent: billing?.sgstPercentage || 0,
        cheque_number: billing?.paymentMode === "CHEQUE" ? billing?.chequeNumber : null,
        cheque_date: billing?.paymentMode === "CHEQUE" ? billing?.chequeDate : null,
      },
    }

    const testID = bookTestObject?.id
    if (testID) {
      dispatch(updateHms("/hms/diagnosis/book-tests/", testID, values, "test_update"))
    } else {
      dispatch(postHms("/hms/diagnosis/book-tests/", values, "test_post"))
    }
  }

  var suTo = null
  const searchTests = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, tests: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/diagnosis/tests/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const searchPatients = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, searchPatients: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/patients/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const searchDiscountBy = (value, callback) => {
    value = value.trim()
    setIsMenuOpen((prev) => ({ ...prev, discount_by: value.length > 0 }))
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/inpatients/search_hms_users/?search=${value}`).then((resp) => {
          callback(resp)
        })
      }, 200)
    }
  }

  const updateTestSelect = (newTest) => {
    setTestsData((prevTests) => {
      const isAlreadyAdded = prevTests.some((prevTest) => prevTest.id === newTest.id);
      if (!isAlreadyAdded) {
        toastr.success(`${newTest.name}-${newTest.category_name} added`);
        return [...prevTests, newTest];
      } else {
        toastr.error(`${newTest.name} is already added`);
      }
      return prevTests;
    });
  };

  const onAdmissionTypeChange = (e) => {
    let value = e.target.value
    document.getElementById("result_delivery").style.display = value === "Offline" || value === "" ? "none" : "block";
  }

  const removeCartItem = (id) => {
    const filtered = testsData.filter((item) => item.id !== id);
    setTestsData(filtered);
  };

  const onDiscountPercentChange = (e) => {
    let value = e.target.value
    if (!isNaN(value) && value >= 0 && value <= 100) {
      let discountPercentage = value
      setBilling(prevState => ({ ...prevState, discountPercentage }));
    }
  }

  const onCgstPercentageChange = (e) => {
    const cgstPercentage = formatPercentage(e.target.value);
    setBilling(prevState => ({ ...prevState, cgstPercentage }));
  };

  const onSgstPercentageChange = (e) => {
    const sgstPercentage = formatPercentage(e.target.value);
    setBilling(prevState => ({ ...prevState, sgstPercentage }));
  };

  const calculateTotals = () => {
    let subTotal = testsData?.reduce((acc, test) => acc + test.fee, 0) || 0;
    subTotal = floatRoundDown(subTotal);
    const discountAmount = floatRoundDown(subTotal * (billing?.discountPercentage / 100));
    const discountedSubTotal = floatRoundDown(subTotal - discountAmount);
    const cgstAmount = floatRoundDown(discountedSubTotal * (billing?.cgstPercentage / 100));
    const sgstAmount = floatRoundDown(discountedSubTotal * (billing?.sgstPercentage / 100));
    const grandTotal = floatRoundDown(discountedSubTotal + cgstAmount + sgstAmount);

    setBilling(prevState => ({ ...prevState, subTotal, discountAmount, grandTotal, cgstAmount, sgstAmount }));
  };

  useEffect(() => {
    calculateTotals();
  }, [testsData, billing.discountPercentage, billing.cgstPercentage, billing.sgstPercentage]);

  const handleEdit = () => {
    setRudFlag(0)
    toggleModal("modalPatient")
  }

  const handleCreate = () => {
    setRudFlag(1)
    toggleModal("modalPatient")
  }

  const downloadInvoice = () => {
    if (hmsSingleObject?.id) {
      dispatch(reportDownloadHms(`/hms/diagnosis/book-tests/${hmsSingleObject?.id}/invoice_receipt/`, "bt_receipt_invoice"))
    }
  }

  const handleTestEdit = test_object => {
    setTestObject(test_object)
    if (test_object?.interpretation) {
      const editorState = stateFromHTML(test_object?.interpretation);
      setEditorStates(EditorState.createWithContent(editorState));
    } else {
      setEditorStates(EditorState.createEmpty());
    }
    toggleModal("testModal")
  }

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  const handleWarningSubmit = () => {
    if (formRef.current) {
      toggleModal("warningModal")
      setIsFeeChecked(true);
      formRef.current.submit();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Book Test | Yanthura</title>
        </MetaTags>
        <Row className="justify-content-center">
          <Col xl={(["test_post_success", "test_update_success"].includes(actionStatus)) ? 6 : 12}>
            <Card>
              <CardBody>
                {error ? (
                  <p className="text-center text-danger">{error.detail}</p>
                ) : (["test_post_success", "test_update_success"].includes(actionStatus)) ? (
                  <div className="p-2">
                    <div className="text-center">
                      <i className="bx bx-check-circle display-4 mb-0 text-success"></i>
                      <div className="p-2 mt-2">
                        <h4>Test {props.match.params.testID ? "modified" : "created"} successfully</h4>
                        <div className="mt-4 d-flex justify-content-center flex-wrap">
                          <Button
                            color="primary"
                            className={window.innerWidth <= 425 ? 'btn-sm mb-1' : 'mb-1'}
                            onClick={() => window.location.replace("/hms/diagnosis/test/book")}
                          >
                            Add&nbsp;Another
                          </Button>
                          <Link
                            to="/hms/diagnosis/test/reports"
                            className={window.innerWidth <= 425 ? 'btn-sm ms-1 btn btn-primary mb-1' : 'ms-1 btn btn-primary mb-1'}
                          >
                            Reports
                          </Link>
                          <Button
                            color="primary"
                            className={window.innerWidth <= 425 ? 'btn-sm ms-1 mb-1' : ' ms-1 mb-1'}
                            onClick={() => window.location.replace(`/hms/diagnosis/test/book/${hmsSingleObject?.id}`)}
                          >
                            Modify
                          </Button>
                          <HMSLink
                            className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary ms-1 mb-1' : 'ms-1 btn btn-primary mb-1'}
                            onClick={downloadInvoice}
                            apiStartKey="bt_receipt_invoice"
                          >
                            Print Invoice
                          </HMSLink>
                          <Link
                            to={`/hms/diagnosis/test/${hmsSingleObject.id}/detail`}
                            className={window.innerWidth <= 425 ? 'btn-sm ms-1 btn btn-primary mb-1' : 'ms-1 btn btn-primary mb-1'}
                          >
                            Explore
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : apiKey === "get_get_test" || apiKey === "options_bt_options" ? (
                  <Loader />
                ) : (
                  <AvForm ref={formRef} onValidSubmit={handleSubmit}>
                    <Row>
                      <Col md={12} lg={6}>
                        <Row>
                          <Col>
                            <div className="d-flex align-items-center mb-3">
                              <div className="ajax-select select2-container" style={{ flex: "1" }}>
                                <Label className={formError?.patient ? "text-danger" : ""}>
                                  Patient
                                </Label>
                                <div className="d-flex align-items-center">
                                  <div className="w-100">
                                    <EAsyncSelect
                                      isDisabled={props.match.params.testID}
                                      onMenuClose={() => setIsMenuOpen(prev => ({ ...prev, searchPatients: false }))}
                                      menuIsOpen={isMenuOpen.searchPatients}
                                      loadOptions={searchPatients}
                                      onSelect={(patient) => setPatient(patient)}
                                      placeholder="Search with patient name, uid or patient ID, mobile number"
                                      selectedOption={[patient]}
                                      formError={formError?.patient}
                                      getOptionLabel={e => `${e?.user_data?.full_name} | ${e?.uid} ${e?.user_data?.email ? `| ${e?.user_data?.email}` : ""} | ${e?.user_data?.phone_number}`}
                                      getOptionValue={e => e?.id}
                                    />
                                  </div>
                                  <div className="d-flex align-items-center cursor-pointer font-size-18">
                                    {!props.match.params.testID && (
                                      <div onClick={handleCreate} title="Create a new patient" className={`ms-2 ${formError?.patient ? "mb-2" : ""}`}>
                                        <i className="mdi mdi-plus-thick text-success" />
                                      </div>
                                    )}
                                    {patient?.id && (
                                      <div onClick={handleEdit} title="Modify patient" className="ms-2">
                                        <i className="mdi mdi-pencil text-success" />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3 ajax-select select2-container">
                              <Label className={formError?.tests ? "text-danger" : ''}>
                                Add a test
                              </Label>
                              <EAsyncSelect
                                onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, tests: false }))}
                                menuIsOpen={isMenuOpen.tests}
                                field="tests"
                                selectedOption={null}
                                fetchOptions={searchTests}
                                formError={formError?.tests}
                                onSelect={updateTestSelect}
                                placeholder="Search with test name..."
                                getOptionLabel={e => `${e.name} ${e.short_name ? `(${e.short_name})` : ''} | ${e.category_name} | Fee-${e.fee}`}
                                getOptionValue={e => e.id}
                                noOptionsMessage={() => ("No test existed")}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lx="8">
                            <Card>
                              <div className="table-responsive" style={{ overflowY: 'auto', maxHeight: '300px' }}>
                                <Table className="table align-middle mb-0 table-nowrap">
                                  <thead className="table-light">
                                    <tr>
                                      <th>S no</th>
                                      <th>Test name</th>
                                      <th>Category</th>
                                      <th colSpan="3">Cost</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!isEmpty(testsData) ? (testsData?.map((test, index) => (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {test.name}
                                          {test.short_name && ` (${test.short_name})`}
                                        </td>
                                        <td>{test.category_name || "---"}</td>
                                        <td style={{ width: "120px" }}>{test.fee}</td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={() => handleTestEdit(test)}
                                            className="action-icon text-success"
                                            title="Modify"
                                          >
                                            <i className="mdi mdi-pencil font-size-18" />
                                          </Link>
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            onClick={() => removeCartItem(test.id)}
                                            className="action-icon text-danger"
                                            title="Remove"
                                          >
                                            {" "} <i className="mdi mdi-close-thick font-size-18" />
                                          </Link>
                                        </td>
                                      </tr>
                                    ))) : (
                                      <tr>
                                        <td colSpan="6" className="text-center">
                                          Please add tests
                                        </td>
                                      </tr>
                                    )}
                                    {!isEmpty(testsData) && (
                                      <tr>
                                        <td colSpan="2"></td>
                                        <th style={{ textAlign: "right" }}>
                                          Total Net Amount
                                          {props.match.params.testID && (
                                            <i
                                              className="bx bx-info-circle ms-1"
                                              title="Displayed total net amount may be outdated. Please refer to the detail page for accurate billing information."
                                            />)} :
                                        </th>
                                        <td><b>{billing?.subTotal}</b></td>
                                        <td colSpan="2"></td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </div>
                            </Card>
                            <hr />
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} md={4}>
                            <div>
                              <EAvFieldSelect
                                field="module"
                                bsSize="sm"
                                isError={formError?.module}
                                options={formOptions?.module}
                                defaultValue={"Direct"}
                                value={bookTestObject?.module}
                                choices={
                                  <>
                                    <option value={""}>-------Select an option-------</option>
                                    {formOptions?.module?.choices.map((item, idx) =>
                                      <option value={item.value} key={idx}>{item.display_name}</option>
                                    )}
                                  </>
                                }
                              />
                            </div>
                          </Col>
                          <Col sm={6} md={4}>
                            <EAvFieldInput
                              field={'referred_by'}
                              bsSize="sm"
                              isError={formError?.referred_by}
                              options={formOptions?.referred_by}
                              value={bookTestObject?.referred_by}
                            />
                          </Col>
                          <Col sm={6} md={4}>
                            <EAvFieldInput
                              field={'sample_collected_by'}
                              bsSize="sm"
                              isError={formError?.sample_collected_by}
                              options={formOptions?.sample_collected_by}
                              value={bookTestObject?.sample_collected_by}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Row>
                              <Col md={"6"}>
                                <div>
                                  <EAvFieldSelect
                                    field="result_requested_through"
                                    bsSize="sm"
                                    isError={formError?.result_requested_through}
                                    options={{ ...formOptions?.result_requested_through, required: true }}
                                    value={bookTestObject?.result_requested_through}
                                    choices={
                                      <>
                                        <option value={""}>-------Select an option-------</option>
                                        {formOptions?.result_requested_through?.choices.map((item, idx) =>
                                          <option value={item.value} key={idx}>{item.display_name}</option>
                                        )}
                                      </>
                                    }
                                    onChange={onAdmissionTypeChange}
                                  />
                                </div>
                              </Col>
                              <Col id="result_delivery" style={{ display: bookTestObject?.result_requested_through === "Offline" ? "none" : "block" }}>
                                <EAvFieldInput
                                  field="result_delivery"
                                  bsSize="sm"
                                  type="text"
                                  isError={formError?.result_delivery}
                                  options={formOptions?.result_delivery}
                                  value={bookTestObject?.result_delivery}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="table-responsive">
                              <Table className="table mb-0 table table-sm">
                                <tbody>
                                  <tr>
                                    <th>
                                      Total Net Amount
                                      {props.match.params.testID && (
                                        <i
                                          className="bx bx-info-circle ms-1"
                                          title="Displayed total net amount may be outdated. Please refer to the detail page for accurate billing information."
                                        />)} :
                                    </th>
                                    <td><b>{billing?.subTotal}</b></td>
                                  </tr>
                                  <tr>
                                    <td className="w-50">
                                      <div className="d-flex align-items-baseline">
                                        <span className={formError?.discount_percent ? "text-danger" : ''}>Discount(%) :</span>
                                        <EAvFieldDiscount
                                          style={{ width: "120px" }}
                                          mb={false}
                                          className="form-control-sm mb-0 ms-2"
                                          bsSize="sm"
                                          value={billing?.discountPercentage}
                                          disabled={billing?.subTotal == 0}
                                          formError={formError?.discount_percent}
                                          options={{ ...formOptions?.discount_percent, label: "" }}
                                          onChange={onDiscountPercentChange}
                                          onKeyDown={handleKeyDown}
                                        />
                                      </div>
                                    </td>
                                    <td className="w-50">
                                      <span>Discount amount : </span>
                                      <span><b>- {billing?.discountAmount}</b></span>
                                    </td>
                                  </tr>
                                  {billing?.discountPercentage > 0 && (
                                    <tr>
                                      <td colSpan={"2"}>
                                        <div className="d-flex align-items-baseline ajax-select select2-container">
                                          <span className={formError?.discount_by ? "text-danger" : ''}>Discount&nbsp;by&nbsp;:</span>
                                          <EAsyncSelect
                                            onMenuClose={() => setIsMenuOpen((prev) => ({ ...prev, discount_by: false }))}
                                            menuIsOpen={isMenuOpen.discount_by}
                                            field="discount_by"
                                            selectedOption={discountBy}
                                            fetchOptions={searchDiscountBy}
                                            formError={formError?.discount_by}
                                            onSelect={(value) => setDiscountBy(value)}
                                            placeholder="Type to search users..."
                                            getOptionLabel={e => e.full_name}
                                            getOptionValue={e => e.id}
                                            onKeyDown={handleKeyDown}
                                            className="form-control-sm"
                                            additionalStyles={{
                                              control: (provided, state) => ({
                                                ...provided,
                                                minHeight: "30px",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td className="w-50">
                                      <span>CGST(%) :</span>
                                      <input
                                        type="number"
                                        name="discount"
                                        className="form-control-sm mb-0 ms-2"
                                        value={billing?.cgstPercentage}
                                        disabled={billing?.subTotal == 0}
                                        onChange={onCgstPercentageChange}
                                        maxLength="2"
                                        style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                        onKeyDown={handleKeyDown}
                                      />
                                    </td>
                                    <td className="w-50">
                                      <span>CGST : </span>
                                      <span><b>{billing?.cgstAmount}</b></span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="w-50">
                                      <span>SGST(%) :</span>
                                      <input
                                        type="number"
                                        name="discount"
                                        className="form-control-sm mb-0 ms-2"
                                        value={billing?.sgstPercentage}
                                        disabled={billing?.subTotal == 0}
                                        onChange={onSgstPercentageChange}
                                        maxLength="2"
                                        style={{ width: "120px", border: "1px solid #ced4da", borderRadius: "0.2rem" }}
                                        onKeyDown={handleKeyDown}
                                      />
                                    </td>
                                    <td className="w-50">
                                      <span>SGST : </span>
                                      <span><b>{billing?.sgstAmount}</b></span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Total amount : </th>
                                    <td><b>{billing?.grandTotal}</b></td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <EAvFieldSelect
                                        mb={false}
                                        field="payment_mode"
                                        value={billing.paymentMode}
                                        onChange={(e) => setBilling(prevState => ({ ...prevState, paymentMode: e.target.value }))}
                                        isError={formError?.payment_mode}
                                        bsSize="sm"
                                        options={{ ...formOptions?.payment_mode, required: true }}
                                        choices={<>
                                          <option value={""}>{"-Select option-"}</option>
                                          {formOptions?.payment_mode?.choices.map((choice, cidx) =>
                                            <option value={choice.value} key={cidx}>{choice.display_name}</option>
                                          )}
                                        </>}
                                      />
                                    </td>
                                    <td></td>
                                  </tr>
                                  <ChequeSection
                                    billing={billing}
                                    setBilling={setBilling}
                                    formError={formError}
                                    options={formOptions}
                                  />
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col sm="12">
                            <div className="text-sm-end mt-2 mt-sm-0">
                              {apiKey === "post_test_post" || apiKey === "update_test_update" ? (
                                <Button color="primary" className="float-end" disabled>
                                  Submit <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                                </Button>
                              ) : (
                                <Button type="submit" color="primary" className="float-end">
                                  Submit
                                </Button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12} lg={6}>
                        <h5 className="mt-2">Patient details</h5>
                        {patient?.user_data ? (
                          <PatientInformation
                            data={patient}
                            includedFields={[
                              "patient_name",
                              "patient_id",
                              "gender",
                              "marital_status",
                              "date_of_birth",
                              "age",
                              "mobile",
                              "email",
                              "blood_group",
                              "registered_on",
                            ]}
                          />
                        ) : (
                          <p className="text-center text-danger">
                            Patient information is not existed
                          </p>
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <PatientCRModal
        data={rudFlag === 0 ? patient : null}
        modal={modals.modalPatient}
        toggle={() => toggleModal("modalPatient")}
        rudFlag={rudFlag}
      />

      <DownloadModal
        modal={modals.downloadInvoiceModal}
        toggle={() => toggleModal("downloadInvoiceModal")}
        apiStartKey={"bt_receipt_invoice"}
      />

      <TestCRUModal
        modal={modals.testModal}
        toggle={() => toggleModal("testModal")}
        rudFlag={0}
        data={testObject}
        editorStates={editorStates}
        setEditorStates={setEditorStates}
        apiStartKey="edit_test"
      />

      <Modal isOpen={modals.warningModal} toggle={() => toggleModal("warningModal")} backdrop="static" scrollable>
        <ModalHeader toggle={() => toggleModal("warningModal")} tag="h4">
          Warning
        </ModalHeader>
        <ModalBody>
          {testsData.length > 0 && (
            <>
              <p>Some tests are detected with zero fee. You want to proceed anyway?</p>
              <ul>
                {testsData.map(test => test.fee <= 0 && <li key={test.id}>{test.name}</li>)}
              </ul>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleModal("warningModal")}>
            Cancel & Add fee
          </Button>
          <Button color="primary" className="float-end" onClick={handleWarningSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default BookTest