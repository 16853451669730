import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import DefaultIfNone from "components/Common/default"
import moment from "moment"
import { Link } from "react-router-dom"

const CardUser = props => {
  const { data, period } = props

  const getDates = (period) => {
    return [period.start, period.end]
  }

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
          formatter: function(val, timestamp) {
            return moment(new Date(timestamp)).format(data.period==="day" ? "MMM DD, YYYY" : "MMM YYYY")
        }
      }
    },
    yaxis: {
      labels: {
          formatter: function(val) {
            return val ? val.toFixed(2) : val
        }
      }
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  return (
    <React.Fragment>
      <Col xl={12}>
        <Row>
          <Col lg={2}>
            <Card className="mini-stats-wid">
              <CardBody>
                <Link to={{pathname: "/calls", search: `?date=${getDates(period)[0]}&date_end=${getDates(period)[1]}`}}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Calls</p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.period_call_count} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={{pathname: "/calls", search: `?date=${getDates(period)[0]}&date_end=${getDates(period)[1]}&call_score_type=well`}}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Well Performed</p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.period_good_call_count} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to={{pathname: "/calls", search: `?date=${getDates(period)[0]}&date_end=${getDates(period)[1]}&call_score_type=under`}}>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Under Performed</p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.period_bad_call_count} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to="#">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Hold violation</p>
                      <h5 className="mb-0"><DefaultIfNone value={0} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to="#">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Flagged</p>
                      <h5 className="mb-0"><DefaultIfNone value={0} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={2}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to="#">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Avg Handling Time</p>
                      <h5 className="mb-0"><DefaultIfNone value={0} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xl={12}>
        <Row>
          <Col lg={3}>
            <Card className="mini-stats-wid">
              <CardBody>
                <Link to="#">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Score</p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.score && parseInt(data.score)} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to="#">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Sentiment</p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.sentiment && parseInt(data.sentiment)} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to="#">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Well performers</p>
                      <h5 className="mb-0"><DefaultIfNone format={true} value={data.well_performed_agents} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link to="#">
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Under performers</p>
                      <h5 className="mb-0"><DefaultIfNone value={data.under_performed_agents} default_value={0} /></h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xl={12}>
        {data.trends &&
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Score & Sentiment Trends</h5>
            </div>

            <div id="area-chart" dir="ltr">
              <ReactApexChart
                options={options}
                series={data.trends}
                type="area"
                height={350}
                className="apex-charts"
              />
            </div>
          </CardBody>
        </Card>
        }
      </Col>
    </React.Fragment>
  )
}

export default CardUser
