import { ASSIGN_TO_AGENTS, ASSIGN_TO_AGENTS_FAIL, ASSIGN_TO_AGENTS_SUCCESS, CHECK_HEADERS, CHECK_HEADERS_FAIL, CHECK_HEADERS_SUCCESS, DELETE_LEAD, DELETE_LEAD_FAIL, DELETE_LEAD_SUCCESS, DELETE_TEMPLATE, DELETE_TEMPLATE_FAIL, DELETE_TEMPLATE_SUCCESS, GET_LEAD, GET_LEAD_FAIL, GET_LEAD_SUCCESS, GET_TEMPLATE, GET_TEMPLATE_FAIL, GET_TEMPLATE_SUCCESS, LEAD_DASHBOARD, LEAD_DASHBOARD_FAIL, LEAD_DASHBOARD_SUCCESS, LIST_LEADS, LIST_LEADS_FAIL, LIST_LEADS_SUCCESS, LIST_TEMPLATES, LIST_TEMPLATES_FAIL, LIST_TEMPLATES_SUCCESS, MODIFY_DISPLAY_OPTIONS, MODIFY_DISPLAY_OPTIONS_FAIL, MODIFY_DISPLAY_OPTIONS_SUCCESS, OPTIONS_LEADS, OPTIONS_LEADS_FAIL, OPTIONS_LEADS_SUCCESS, POST_LEAD, POST_LEAD_FAIL, POST_LEAD_SUCCESS, POST_TEMPLATE, POST_TEMPLATE_FAIL, POST_TEMPLATE_SUCCESS, RESET_LEADS, STEPPED_FORM, UPDATE_LEAD, UPDATE_LEAD_FAIL, UPDATE_LEAD_SUCCESS, UPDATE_TEMPLATE, UPDATE_TEMPLATE_FAIL, UPDATE_TEMPLATE_SUCCESS, UPLOAD_LEADS, UPLOAD_LEADS_FAIL, UPLOAD_LEADS_SUCCESS } from "./actionTypes"

export const resetState = () => ({
  type: RESET_LEADS,
})
export const steppedForm = data => ({
  type: STEPPED_FORM,
  payload: data
})


export const getOptions = (params) => ({
  type: OPTIONS_LEADS,
  params
})
export const getOptionsSuccess = options => ({
  type: OPTIONS_LEADS_SUCCESS,
  payload: options,
})
export const getOptionsFail = error => ({
  type: OPTIONS_LEADS_FAIL,
  payload: error,
})


export const getLead = (leadID) => ({
  type: GET_LEAD,
  leadID
})
export const getLeadSuccess = leadObject => ({
  type: GET_LEAD_SUCCESS,
  payload: leadObject,
})
export const getLeadFail = error => ({
  type: GET_LEAD_FAIL,
  payload: error,
})


export const listLeads = params => ({
  type: LIST_LEADS,
  params
})
export const listLeadsSuccess = apiObject => ({
  type: LIST_LEADS_SUCCESS,
  payload: apiObject,
})
export const listLeadsFail = error => ({
  type: LIST_LEADS_FAIL,
  payload: error,
})


export const postLeads = payload => ({
  type: POST_LEAD,
  payload: payload,
})
export const postLeadsSuccess = data => ({
  type: POST_LEAD_SUCCESS,
  payload: data,
})
export const postLeadsFail = error => ({
  type: POST_LEAD_FAIL,
  payload: error,
})


export const updateLeads = (leadID, payload) => ({
  type: UPDATE_LEAD,
  leadID,
  payload,
})
export const updateLeadsSuccess = data => ({
  type: UPDATE_LEAD_SUCCESS,
  payload: data,
})
export const updateLeadsFail = error => ({
  type: UPDATE_LEAD_FAIL,
  payload: error,
})


export const deleteLead = leadID => ({
  type: DELETE_LEAD,
  payload: leadID,
})
export const deleteLeadSuccess = leadID => ({
  type: DELETE_LEAD_SUCCESS,
  payload: leadID,
})
export const deleteLeadFail = error => ({
  type: DELETE_LEAD_FAIL,
  payload: error,
})


export const assignToAgents = payload => ({
  type: ASSIGN_TO_AGENTS,
  payload: payload,
})
export const assignToAgentsSuccess = data => ({
  type: ASSIGN_TO_AGENTS_SUCCESS,
  payload: data,
})
export const assignToAgentsFail = error => ({
  type: ASSIGN_TO_AGENTS_FAIL,
  payload: error,
})


export const uploadLeads = payload => ({
  type: UPLOAD_LEADS,
  payload: payload,
})
export const uploadLeadsSuccess = data => ({
  type: UPLOAD_LEADS_SUCCESS,
  payload: data,
})
export const uploadLeadsFail = error => ({
  type: UPLOAD_LEADS_FAIL,
  payload: error,
})


export const listTemplates = params => ({
  type: LIST_TEMPLATES,
  params
})
export const listTemplatesSuccess = templates => ({
  type: LIST_TEMPLATES_SUCCESS,
  payload: templates,
})
export const listTemplatesFail = error => ({
  type: LIST_TEMPLATES_FAIL,
  payload: error,
})


export const getTemplate = (templateID) => ({
  type: GET_TEMPLATE,
  templateID
})
export const getTemplateSuccess = templateObject => ({
  type: GET_TEMPLATE_SUCCESS,
  payload: templateObject,
})
export const getTemplateFail = error => ({
  type: GET_TEMPLATE_FAIL,
  payload: error,
})


export const postTemplate = payload => ({
  type: POST_TEMPLATE,
  payload: payload,
})
export const postTemplateSuccess = data => ({
  type: POST_TEMPLATE_SUCCESS,
  payload: data,
})
export const postTemplateFail = error => ({
  type: POST_TEMPLATE_FAIL,
  payload: error,
})


export const updateTemplate = (templateID, payload) => ({
  type: UPDATE_TEMPLATE,
  templateID,
  payload,
})
export const updateTemplateSuccess = data => ({
  type: UPDATE_TEMPLATE_SUCCESS,
  payload: data,
})
export const updateTemplateFail = error => ({
  type: UPDATE_TEMPLATE_FAIL,
  payload: error,
})


export const deleteTemplate = templateID => ({
  type: DELETE_TEMPLATE,
  templateID,
})
export const deleteTemplateSuccess = templateID => ({
  type: DELETE_TEMPLATE_SUCCESS,
  payload: templateID,
})
export const deleteTemplateFail = error => ({
  type: DELETE_TEMPLATE_FAIL,
  payload: error,
})



export const checkHeaders = payload => ({
  type: CHECK_HEADERS,
  payload: payload,
})
export const checkHeadersSuccess = data => ({
  type: CHECK_HEADERS_SUCCESS,
  payload: data,
})
export const checkHeadersFail = error => ({
  type: CHECK_HEADERS_FAIL,
  payload: error,
})


export const modifyDisplayOptions = payload => ({
  type: MODIFY_DISPLAY_OPTIONS,
  payload: payload,
})
export const modifyDisplayOptionsSuccess = data => ({
  type: MODIFY_DISPLAY_OPTIONS_SUCCESS,
  payload: data,
})
export const modifyDisplayOptionsFail = error => ({
  type: MODIFY_DISPLAY_OPTIONS_FAIL,
  payload: error,
})


export const leadDashboard = () => ({
  type: LEAD_DASHBOARD,
})
export const leadDashboardSuccess = dashboardData => ({
  type: LEAD_DASHBOARD_SUCCESS,
  payload: dashboardData,
})
export const leadDashboardFail = error => ({
  type: LEAD_DASHBOARD_FAIL,
  payload: error,
})
