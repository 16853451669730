import { del, get, post, put } from "../../helpers/api_helper"

export const getProformasAPI = () => get("/proformas")
export const addNewProformaAPI = proforma => post("/proforma/create", proforma)
export const updateProformaAPI = ({proformaID, proforma}) => put(`proforma/${proformaID}/rud`, proforma)
export const deleteProformaAPI = proformaID => del(`proforma/${proformaID}/rud`)

export const getProformaDetailAPI = proformaID => get(`/proforma/${proformaID}/statements`)
export const addNewStatementAPI = statement => post("/statement/create", statement)
export const updateStatementAPI = ({statementID, statement}) => put(`statement/${statementID}/rud`, statement)
export const deleteStatementAPI = statementID => del(`statement/${statementID}/rud`)