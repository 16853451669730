import React, { useEffect, useState } from "react"
import { Card, Col, Row, CardBody } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, resetHmsState, updateHms } from "store/actions"
import toastr from "toastr"
import { AvForm } from "availity-reactstrap-validation"
import { EAvFieldNumber } from "../common/errored-avfields"
import { Loader } from "../common/common"

const OPValidity = (props) => {
  const dispatch = useDispatch()
  const [validity, setValidity] = useState({})

  const { loading, hmsList, apiKey, error, formError, updateResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    updateResponse: state.Hms.updateResponse,
  }))

  useEffect(() => {
    if (apiKey === "validity_get") {
      setValidity(hmsList?.results[0])
    }
    if (apiKey === "validity_updated") {
      setValidity(updateResponse)
      toastr.success("Validity updated successfully")
      document.activeElement.blur()
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(listHms("/hms/patient/outpatient-validity/", null, "validity_get"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])


  const handleValidSubmit = (e, values) => {
    if (validity.id) {
      dispatch(updateHms(`/hms/patient/outpatient-validity/${validity.id}/`, null, { id: validity.id, days: values.days }, "validity_updated"))
    }
  }

  return (
    <React.Fragment>
      <div>
        <Row className="justify-content-center">
          <Col xl={6}>
            <Card>
              <CardBody>
                {loading && apiKey === "list_validity_get" ? (
                  <Loader />
                ) : error ? (
                  <p className="text-center text-danger">{error.detail}</p>
                ) : (
                  <AvForm onValidSubmit={handleValidSubmit}>
                    <Row>
                      <Col>
                        <EAvFieldNumber
                          field="days"
                          value={validity?.days}
                          isError={formError?.days}
                          options={{ label: "Validity", required: true }}
                          placeholder={"In days"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          {loading ?
                            <button disabled className="btn btn-primary btn-sm save-user">
                              SAVE <i className="bx bx-loader bx-spin font-size-16 align-middle ms-1"></i>
                            </button>
                            :
                            <button type="submit" className="btn btn-primary btn-sm save-user">
                              SAVE
                            </button>
                          }
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                )
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default OPValidity;