import { DELETE_HMS, DELETE_HMS_FAIL, DELETE_HMS_SUCCESS, DELETE_DIAG_TEST_PARAM, DELETE_DIAG_TEST_PARAM_FAIL, DELETE_DIAG_TEST_PARAM_SUCCESS, DELETE_PATIENT, DELETE_PATIENT_FAIL, DELETE_PATIENT_SUCCESS, DELETE_TIMESLOT, DELETE_TIMESLOT_FAIL, DELETE_TIMESLOT_SUCCESS, DOWNLOAD_REPORT_HMS, DOWNLOAD_REPORT_HMS_FAIL, DOWNLOAD_REPORT_HMS_SUCCESS, GET_HMS, GET_HMS_FAIL, GET_HMS_SUCCESS, GET_DIAG_TEST_PARAM, GET_DIAG_TEST_PARAM_FAIL, GET_DIAG_TEST_PARAM_SUCCESS, GET_PATIENT, GET_PATIENT_FAIL, GET_PATIENT_SUCCESS, GET_TIMESLOT, GET_TIMESLOT_FAIL, GET_TIMESLOT_SUCCESS, LIST_HMS, LIST_HMS_FAIL, LIST_HMS_SUCCESS, LIST_DIAG_TEST_PARAM, LIST_DIAG_TEST_PARAM_FAIL, LIST_DIAG_TEST_PARAM_SUCCESS, LIST_PATIENTS, LIST_PATIENTS_FAIL, LIST_PATIENTS_SUCCESS, LIST_TIMESLOTS, LIST_TIMESLOTS_FAIL, LIST_TIMESLOTS_SUCCESS, OPTIONS_HMS, OPTIONS_HMS_FAIL, OPTIONS_HMS_SUCCESS, OPTIONS_PATIENT, OPTIONS_PATIENT_FAIL, OPTIONS_PATIENT_SUCCESS, OPTIONS_TIMESLOT, OPTIONS_TIMESLOT_FAIL, OPTIONS_TIMESLOT_SUCCESS, POST_HMS, POST_HMS_FAIL, POST_HMS_SUCCESS, POST_DIAG_TEST_PARAM, POST_DIAG_TEST_PARAM_FAIL, POST_DIAG_TEST_PARAM_SUCCESS, POST_PATIENT, POST_PATIENT_FAIL, POST_PATIENT_SUCCESS, POST_TIMESLOT, POST_TIMESLOT_FAIL, POST_TIMESLOT_SUCCESS, RESET_HMS, UPDATE_HMS, UPDATE_HMS_FAIL, UPDATE_HMS_SUCCESS, UPDATE_DIAG_TEST_PARAM, UPDATE_DIAG_TEST_PARAM_FAIL, UPDATE_DIAG_TEST_PARAM_SUCCESS, UPDATE_PATIENT, UPDATE_PATIENT_FAIL, UPDATE_PATIENT_SUCCESS, UPDATE_TIMESLOT, UPDATE_TIMESLOT_FAIL, UPDATE_TIMESLOT_SUCCESS, UPLOAD_HMS, UPLOAD_HMS_FAIL, UPLOAD_HMS_SUCCESS, CHAINED_OPTIONS_HMS, CHAINED_OPTIONS_HMS_SUCCESS, CHAINED_OPTIONS_HMS_FAIL } from "./actionTypes"

export const resetHmsState = () => ({
  type: RESET_HMS,
})


export const getHmsOptions = (api_url, key = null, alterPaymentModeChoices = false, modifiedFormSchema = false) => ({
  type: OPTIONS_HMS,
  api_url,
  key,
  alterPaymentModeChoices,
  modifiedFormSchema
})
export const getHmsOptionsSuccess = (response, key, alterPaymentModeChoices, modifiedFormSchema) => ({
  type: OPTIONS_HMS_SUCCESS,
  payload: response,
  key: key,
  alterPaymentModeChoices: alterPaymentModeChoices,
  modifiedFormSchema: modifiedFormSchema
})
export const getHmsOptionsFail = (error, key) => ({
  type: OPTIONS_HMS_FAIL,
  payload: error,
  key: key
})


export const listHms = (api_url, params, key = null) => ({
  type: LIST_HMS,
  api_url,
  params,
  key,
})
export const listHmsSuccess = (response, key) => ({
  type: LIST_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const listHmsFail = (error, key) => ({
  type: LIST_HMS_FAIL,
  payload: error,
  key: key,
})


export const getHms = (api_url, id, key = null) => ({
  type: GET_HMS,
  api_url,
  id,
  key
})
export const getHmsSuccess = (response, key) => ({
  type: GET_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const getHmsFail = (error, key) => ({
  type: GET_HMS_FAIL,
  payload: error,
  key: key,
})


export const postHms = (api_url, payload, key = null, prepend = true) => ({
  type: POST_HMS,
  api_url,
  payload,
  key,
  prepend
})
export const postHmsSuccess = (response, key, prepend) => ({
  type: POST_HMS_SUCCESS,
  payload: response,
  key: key,
  prepend: prepend
})
export const postHmsFail = (error, key) => ({
  type: POST_HMS_FAIL,
  payload: error,
  key: key
})


export const updateHms = (api_url, id, payload, key = null, updateKey = "id") => ({
  type: UPDATE_HMS,
  api_url,
  id,
  payload,
  key,
  updateKey,
})
export const updateHmsSuccess = (response, key, updateKey) => ({
  type: UPDATE_HMS_SUCCESS,
  payload: response,
  key: key,
  updateKey,
})
export const updateHmsFail = (error, key) => ({
  type: UPDATE_HMS_FAIL,
  payload: error,
  key: key,
})


export const deleteHms = (api_url, id, key = null) => ({
  type: DELETE_HMS,
  api_url,
  id,
  key,
})
export const deleteHmsSuccess = (response, key) => ({
  type: DELETE_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const deleteHmsFail = (error, key) => ({
  type: DELETE_HMS_FAIL,
  payload: error,
  key: key,
})


export const reportDownloadHms = (api_url, key = null) => ({
  type: DOWNLOAD_REPORT_HMS,
  api_url,
  key: key,
})
export const reportDownloadHmsSuccess = (response, key) => ({
  type: DOWNLOAD_REPORT_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const reportDownloadHmsFail = (error, key) => ({
  type: DOWNLOAD_REPORT_HMS_FAIL,
  payload: error,
  key: key,
})


export const postUploadHms = (api_url, payload, key = null) => ({
  type: UPLOAD_HMS,
  api_url,
  payload,
  key,
})
export const postUploadHmsSuccess = (response, key) => ({
  type: UPLOAD_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const postUploadHmsFail = (error, key) => ({
  type: UPLOAD_HMS_FAIL,
  payload: error,
  key: key
})

export const listDiagTestParam = (api_url, params, key = null) => ({
  type: LIST_DIAG_TEST_PARAM,
  api_url,
  params,
  key,
})
export const listDiagTestParamSuccess = (response, key) => ({
  type: LIST_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const listDiagTestParamFail = (error, key) => ({
  type: LIST_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})


export const getDiagTestParam = (api_url, id, key = null) => ({
  type: GET_DIAG_TEST_PARAM,
  api_url,
  id,
  key
})
export const getDiagTestParamSuccess = (response, key) => ({
  type: GET_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const getDiagTestParamFail = (error, key) => ({
  type: GET_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})


export const postDiagTestParam = (api_url, payload, key = null) => ({
  type: POST_DIAG_TEST_PARAM,
  api_url,
  payload,
  key,
})
export const postDiagTestParamSuccess = (response, key) => ({
  type: POST_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const postDiagTestParamFail = (error, key) => ({
  type: POST_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key
})


export const updateDiagTestParam = (api_url, id, payload, key = null) => ({
  type: UPDATE_DIAG_TEST_PARAM,
  api_url,
  id,
  payload,
  key,
})
export const updateDiagTestParamSuccess = (response, key) => ({
  type: UPDATE_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const updateDiagTestParamFail = (error, key) => ({
  type: UPDATE_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})


export const deleteDiagTestParam = (api_url, id, key = null) => ({
  type: DELETE_DIAG_TEST_PARAM,
  api_url,
  id,
  key,
})
export const deleteDiagTestParamSuccess = (response, key) => ({
  type: DELETE_DIAG_TEST_PARAM_SUCCESS,
  payload: response,
  key: key,
})
export const deleteDiagTestParamFail = (error, key) => ({
  type: DELETE_DIAG_TEST_PARAM_FAIL,
  payload: error,
  key: key,
})

export const chainedOptionsHms = (api_url, params, key = null) => ({
  type: CHAINED_OPTIONS_HMS,
  api_url,
  params,
  key,
})
export const chainedOptionsHmsSuccess = (response, key) => ({
  type: CHAINED_OPTIONS_HMS_SUCCESS,
  payload: response,
  key: key,
})
export const chainedOptionsHmsFail = (error, key) => ({
  type: CHAINED_OPTIONS_HMS_FAIL,
  payload: error,
  key: key,
})