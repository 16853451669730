import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Alert,
  CardTitle,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getSettings as onGetSettings,
  postNfs as onPostNfs,
  updateNfs as onUpdateNfs,
} from "store/actions"
import Verification, { Success } from "./upload-test"
import { hybridErrorAlertDisplay, hybridErrorCommonDisplay } from "components/Common/common"


const Configurations = props => {
  const [activeTab, setactiveTab] = useState("1")
  const [nfsMode, setNfsMode] = useState(null)
  const [status, setStatus] = useState('VIEW')
  const dispatch = useDispatch()

  const { settings, loading, error, nfsData, nfsError, rotatedKeys, nfsCUStatus } = useSelector(state => ({
    settings: state.Settings.settings,
    loading: state.Settings.loading,
    error: state.Settings.error,
    nfsData: state.Settings.nfsData,
    nfsError: state.Settings.nfsError,
    rotatedKeys: state.Settings.rotatedKeys,
    nfsCUStatus: state.Settings.nfsCUStatus,
  }))

  useEffect(() => {
    dispatch(onGetSettings())
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(settings)) {
      setNfsMode(settings.nfs_mode)
    }
  }, [settings])
  
  useEffect(() => {
    if (!isEmpty(nfsData)) {
      nfsData.status && setStatus(nfsData.status)
    }
  }, [nfsData])

  const handleNfsSubmit = (event, values) => {
    if (!isEmpty(settings.object_id)) {
      values.action = 'nfs_update'
      values.nfs_mode = nfsMode
      values.nfs_id = settings.object_id.id
      dispatch(onUpdateNfs(values))
    } else {
      values.action = 'nfs_create'
      values.nfs_mode = nfsMode
      dispatch(onPostNfs(values))
    }
  }

  const updateStatus = (value) => {
    console.log(value, ' <<<> STATUS');
    setStatus(value)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Storage configuration | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Storage" breadcrumbItem="NFS" />
          {error ?
            hybridErrorCommonDisplay(error)
          :
          <Row>
            <Col xs="12">
              {(settings && settings.nfs_options) &&
                <Card className="p-3">
                  <CardTitle className="mb-4">Configure Call Storage</CardTitle>
                  <Row>
                    <Col xl={3} sm={4}>
                      <div className="mt-4">
                        {Object.entries(settings.nfs_options).map(([key, value]) =>
                          <div className="form-check mb-3" key={key}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="nfs_mode"
                              id={key}
                              value={key}
                              defaultChecked={key === settings.nfs_mode ? true : false}
                              onChange={(e) => setNfsMode(e.target.value)}
                            />
                            <label className="form-check-label" htmlFor={key}>{value}</label>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xl={9} sm={8}>
                      {nfsMode==="AWS" ?
                      <>
                      {status==='VIEW' ?
                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => handleNfsSubmit(e, v)}>
                          {settings.object_id &&
                            <Alert color="info">
                              <ul>
                                {(!settings.is_storage_empty && (!settings.object_id || isEmpty(settings.object_id))) &&
                                <li><b>Warning: Before configuring storage</b>
                                  <ul>
                                    <li>Storage is not empty. It is already have some of uploaded calls</li>
                                    <li>Raise a request to <a href="mailto:info@yanthura.com" className="text-success"><i>info@yanthura.com</i></a></li>
                                  </ul>
                                </li>
                                }
                                <li>As per security reasons we cannot show complete keys</li>
                                <li>Please do not leave the configurations in the middle. Uploaded calls will be switched to yanthura bucket</li>
                              </ul>
                            </Alert>
                          }
                          {(settings.object_id && !settings.object_id.verified) &&
                            <Alert color="danger">Storage configurations yet to be verified. Your calls won't be uploaded to your bucket</Alert>
                          }
                          {nfsError &&
                            hybridErrorAlertDisplay(nfsError)
                          }
                          <Row>
                            <Col lg={5}>
                              <div className="mb-3">
                                <AvField
                                  name="ak"
                                  label="Access key"
                                  className="form-control"
                                  type="text"
                                  required
                                  helpMessage={settings.object_id ? settings.object_id.ak : ""}
                                />
                              </div>
                            </Col>
                            <Col lg={7}>
                              <div className="mb-3">
                                <AvField
                                  name="sak"
                                  label="Secret access key"
                                  className="form-control"
                                  type="text"
                                  required
                                  helpMessage={settings.object_id ? settings.object_id.sak : ""}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <AvField
                                  name="bucket_name"
                                  label="Bucket name"
                                  className="form-control"
                                  type="text"
                                  value={settings.object_id ? settings.object_id.bucket_name : ""}
                                  required
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <AvField
                                  name="region"
                                  label="Region"
                                  className="form-control"
                                  type="text"
                                  value={settings.object_id ? settings.object_id.region : ""}
                                  required
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="mb-3">
                            <AvField
                              name="storage_path"
                              label="Storage path"
                              className="form-control"
                              type="text"
                              value={settings.object_id ? settings.object_id.storage_path : ""}
                              helpMessage={settings.object_id ? "Warning on update path: Please check the existing calls must be in this path for smooth functioning" : ""}
                              color="red"
                              required
                            />
                          </div>
                          <div>
                          {loading ?
                            <button className="btn btn-primary float-end w-md" disabled>
                              Verify
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </button>
                          :
                            <button type="submit" className="btn btn-primary float-end w-md">
                              Verify
                            </button>
                          }
                          </div>
                        </AvForm>
                      : (status==='CREATED' || status==='UPDATED') ?
                        <Verification loading={loading} settings={settings} nfsError={nfsError} updateStatus={updateStatus} />
                        : (status==='VERIFIED') ?
                        <Success success_message={nfsData.detail} updateStatus={updateStatus} />
                      :
                      null
                      }
                      </>
                      : nfsMode==="AZR" ?
                      <p>Upcoming...</p>
                      : nfsMode==="GGL" ?
                      <p>Upcoming...</p>
                      : nfsMode==="DBX" ?
                      <p>Upcoming...</p>
                      : null
                      }
                      <p></p>
                    </Col>
                  </Row>
                </Card>
              }
            </Col>
          </Row>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Configurations
