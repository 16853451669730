import React, { useRef, useState, useEffect } from "react"
import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useSelector, useDispatch } from "react-redux"
import { updateHms, postHms, chainedOptionsHms } from "store/actions"
import { EAvFieldInput, EAvFieldSelect, EAvFieldNumber, RequiredFieldsMessage, EAsyncSelect } from "pages/HMS/common/errored-avfields"
import { PhonenumberInput } from "components/Common/input-advanced"
import { handlePhonenumberBlur, handlePhonenumberFocus, errorMappings, SubmitLoaderButton, EAvFieldDOB, ageToDateOfBirth } from "pages/HMS/common/common"
import toastr from "toastr"
import { isEmpty } from "lodash"
import { get } from "helpers/api_helper";
import ConfirmationDialog from "pages/HMS/common/confirmation-dialog"

export const StaffCRUDModal = (props) => {
  const { modal, toggle, rudFlag, formOptions, data = {}, customError, updateQualificationSelect, searchQualifications, selectedQualifications, toggleQualification, isChecked, handleCheckboxChange, selectedPerson, setSelectedPerson, state, setState, setIsChecked, selectedRoles, setSelectedRoles } = props
  const dispatch = useDispatch()
  const formRef = useRef()
  const [phonenumber, setPhonenumber] = useState(null);
  const [alternatePhonenumber, setAlternatePhonenumber] = useState(null);
  const [emergencyNumber, setEmergencyNumber] = useState(null);
  const [phoneError, setPhoneError] = useState('');
  const [searchOptions, setSearchOptions] = useState([])
  const [activeOPAlert, setActiveOPAlert] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [roleDefaultOptions, setRoleDefaultOptions] = useState([])
  const [showAgeInput, setShowAgeInput] = useState(localStorage.getItem('showAgeInput') === "true" || localStorage.getItem('showAgeInput') === null);

  const { loading, apiKey, optionsChainedHms, formError } = useSelector(state => ({
    loading: state.Hms.loading,
    apiKey: state.Hms.apiKey,
    optionsChainedHms: state.Hms.optionsChainedHms,
    formError: state.Hms.formError
  }))

  useEffect(() => {
    if (apiKey === "staff_created") {
      toggle()
      toastr.success("Staff created successfully")
      formRef.current?.reset()
    }
    if (apiKey === "staff_updated") {
      toggle()
      toastr.success("Staff updated successfully")
    }
    if (apiKey === "fetch_roles") {
      setRoleDefaultOptions(optionsChainedHms?.results);
    }
    if (apiKey === "fetch_qualifications") {
      setSearchOptions(optionsChainedHms);
    }
    if (formError?.detail && apiKey.startsWith("fail_staff_")) {
      setActiveOPAlert(true)
      const [code, username, full_name] = formError?.detail.split("_-_");
      setState((prevState) => ({ ...prevState, ...errorMappings[code], username, full_name }));
      setSelectedPerson({ username, full_name })
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    if (!phonenumber && !isChecked) {
      setPhoneError('This field is invalid');
      return;
    }
    setPhoneError('');
    values.alternate_phone_number = alternatePhonenumber
    values.emergency_contact_number = emergencyNumber
    values.qualifications = !isEmpty(selectedQualifications) ? selectedQualifications?.map(qualification => qualification?.id) : null;

    values.user_post = {
      username: values.username,
      full_name: values.full_name,
      email: values.email,
      phone_number: phonenumber,
      gender: values.gender,
      dob: showAgeInput ? ageToDateOfBirth(values.dob) : values.dob,
      groups: !isEmpty(selectedRoles) ? selectedRoles?.map(role => role?.id) : []
    }
    delete values.username
    delete values.full_name
    delete values.email
    delete values.phone_number
    delete values.gender
    delete values.dob

    if (state.isExist || isChecked) {
      delete values.user_post
      values.username = state?.username || selectedPerson?.username
    }

    if (rudFlag === 0) {
      dispatch(updateHms("/hms/staff/staff/", data.id, values, "staff_updated"))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/staff/staff/", values, "staff_created"))
    }
  }

  var suTo = null
  const searchRoles = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/roles/?search=${value}`).then((resp) => {
          callback(resp?.results)
        })
      }, 1000)
    }
  }

  const searchDiscountUsers = (value, callback) => {
    value = value.trim()
    setIsMenuOpen(value.length > 0)
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/patient/outpatients/fetch_discount_users/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const onConfirm = () => {
    setState((prevState) => ({ ...prevState, isExist: true }));
    setActiveOPAlert(false)
    setIsChecked(true);
    formRef.current.submit();
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" scrollable={true} size="lg">
        <ModalHeader toggle={toggle} tag="h4">
          <div className="d-flex">
            <span>{rudFlag === 0 ? "Edit Staff" : rudFlag === 1 ? "Add Staff" : rudFlag === 2 ? "Delete Staff" : ""}</span>
            {rudFlag === 1 && (
              <div className="form-check form-switch form-switch-sm" style={{ marginLeft: "10px" }}>
                <input type="checkbox" className="form-check-input" id="customSwitchsizemd" checked={isChecked} onChange={(e) => handleCheckboxChange(e.target.checked)} />
                <label className="form-check-label" htmlFor="customSwitchsizemd">Convert To Staff</label>
              </div>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
            {formOptions && (
              <>
                {state.isExist || isChecked ? (
                  <Row>
                    <Col>
                      <div className="ajax-select select2-container">
                        <EAsyncSelect
                          isDisabled={!isChecked}
                          options={{ ...formOptions?.full_name, label: "Full name" }}
                          getOptionLabel={e => e?.full_name}
                          getOptionValue={e => e?.username}
                          placeholder="Search users..."
                          onMenuClose={() => setIsMenuOpen(false)}
                          menuIsOpen={isMenuOpen}
                          selectedOption={[selectedPerson]}
                          fetchOptions={searchDiscountUsers}
                          onSelect={(value) => setSelectedPerson(value)}
                          formError={formError?.username}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col md={6}>
                        <EAvFieldInput
                          field="full_name"
                          type="text"
                          value={data?.user_data?.full_name}
                          isError={customError?.full_name}
                          options={{ label: formOptions?.full_name?.label, required: true }}
                        />
                      </Col>
                      <Col md={6}>
                        <EAvFieldInput
                          field="username"
                          type="text"
                          value={data?.user_data?.username}
                          isError={customError?.username}
                          options={{ label: formOptions?.username?.label, required: true }}
                          placeholder={"Ex: user-123"}
                          helpMessage={"An unique id to identify the user"}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={4}>
                        <PhonenumberInput
                          label="Phone number"
                          name="phone_number"
                          value={data?.user_data?.phone_number}
                          error={customError?.phone_number || phoneError}
                          required={true}
                          updateValue={setPhonenumber}
                          onFocus={() => handlePhonenumberFocus(setPhoneError)}
                          onBlur={() => handlePhonenumberBlur(phonenumber, setPhoneError)}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <PhonenumberInput
                          label={formOptions?.alternate_phone_number?.label}
                          name="alternate_phone_number"
                          value={data?.alternate_phone_number}
                          error={customError?.alternate_phone_number}
                          required={formOptions?.alternate_phone_number?.required}
                          updateValue={setAlternatePhonenumber}
                        />
                      </Col>
                      <Col xs={12} md={4}>
                        <EAvFieldInput
                          field="email"
                          type="email"
                          value={data?.user_data?.email}
                          isError={customError?.email}
                          options={{ ...formOptions?.email }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} lg={4}>
                        <EAvFieldSelect
                          field="gender"
                          value={data?.user_data?.gender}
                          isError={customError?.gender}
                          options={{ label: formOptions?.gender?.label, required: true }}
                          choices={
                            <>
                              <option>{"-Select an option-"}</option>
                              {formOptions?.gender?.choices?.map((choice, cidx) =>
                                <option value={choice.value} key={cidx}>{choice.display_name}</option>
                              )}
                            </>
                          }
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4}>
                        <EAvFieldDOB
                          formError={formError}
                          value={showAgeInput ? data?.user_data?.age : data?.user_data?.dob}
                          showAgeInput={showAgeInput}
                          toggleSwitch={() => setShowAgeInput(prev => !prev)}
                          required={formOptions?.dob?.required}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={4}>
                        <EAvFieldSelect
                          field="marital_status"
                          value={data.marital_status !== undefined && data.marital_status.toString()}
                          isError={customError?.marital_status}
                          options={{ label: formOptions?.marital_status?.label, required: true }}
                          choices={
                            <>
                              <option>{"---Select an option---"}</option>
                              {[{ value: "true", display_name: "Married" }, { value: "false", display_name: "Unmarried" }].map((choice, cidx) =>
                                <option value={choice.value} key={cidx}>{choice.display_name}</option>
                              )}
                            </>
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                          <EAsyncSelect
                            isMulti
                            cacheOptions
                            defaultOptions={roleDefaultOptions}
                            onFocus={() => dispatch(chainedOptionsHms("/hms/roles/", null, "fetch_roles"))}
                            options={formOptions?.groups}
                            selectedOption={selectedRoles}
                            fetchOptions={searchRoles}
                            formError={formError?.groups}
                            onSelect={(role) => setSelectedRoles(role)}
                            placeholder="Type to search roles..."
                            getOptionLabel={e => e?.name}
                            getOptionValue={e => e?.id}
                            helpMessage={formOptions?.groups?.help_text}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                <h6 className="mt-4 text-muted">Address</h6>
                <hr />
                <Row>
                  <Col>
                    <EAvFieldInput
                      field="address"
                      type="text"
                      value={data?.address}
                      isError={customError?.address}
                      options={formOptions?.address}
                    />
                  </Col>
                  <Col>
                    <EAvFieldInput
                      field="city"
                      type="text"
                      value={data?.city}
                      isError={customError?.city}
                      options={formOptions?.city}
                    />
                  </Col>
                </Row>
                <h6 className="mt-4 text-muted">Emergency information</h6>
                <hr />
                <Row>
                  <Col>
                    <EAvFieldInput
                      field="emergency_contact_name"
                      type="text"
                      value={data?.emergency_contact_name}
                      isError={customError?.emergency_contact_name}
                      options={formOptions?.emergency_contact_name}
                    />
                  </Col>
                  <Col>
                    <EAvFieldInput
                      field="emergency_contact_relation"
                      type="text"
                      value={data?.emergency_contact_relation}
                      isError={customError?.emergency_contact_relation}
                      options={formOptions?.emergency_contact_relation}
                    />
                  </Col>
                  <Col>
                    <PhonenumberInput
                      label="Emergency contact number"
                      name="emergency_contact_number"
                      value={data?.emergency_contact_number}
                      error={customError?.emergency_contact_number}
                      required={formOptions?.emergency_contact_number?.required}
                      updateValue={setEmergencyNumber}
                    />
                  </Col>
                </Row>
                <h6 className="mt-4 text-muted">Professional information</h6>
                <hr />
                <Row>
                  <Col xs={12} md={7}>
                    <div className="d-flex align-items-center">
                      <div className="mb-3 ajax-select select2-container" style={{ flex: "1" }}>
                        <EAsyncSelect
                          isMulti
                          cacheOptions
                          defaultOptions={searchOptions}
                          onFocus={() => dispatch(chainedOptionsHms("/hms/staff/qualifications/fetch_all/", null, "fetch_qualifications"))}
                          options={formOptions?.qualifications}
                          selectedOption={selectedQualifications}
                          fetchOptions={searchQualifications}
                          formError={customError?.qualifications}
                          onSelect={updateQualificationSelect}
                          placeholder="Type to search qualifications..."
                          getOptionLabel={e => e?.name}
                          getOptionValue={e => e?.id}
                        />
                      </div>
                      <div onClick={() => toggleQualification(false)} title="Add qualifications" className={`cursor-pointer ms-2 ${customError?.qualifications ? '' : 'mt-3'}`}>
                        <i className="bx bx-plus-medical text-success" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={5}>
                    <EAvFieldNumber
                      field="experience"
                      value={data?.experience}
                      isError={customError?.experience}
                      options={formOptions?.experience}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <EAvFieldInput
                      field="certifications"
                      type="text"
                      value={data?.certifications}
                      isError={customError?.certifications}
                      options={formOptions?.certifications}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <EAvFieldInput
                      field="medical_license_number"
                      type="text"
                      value={data?.medical_license_number}
                      isError={customError?.medical_license_number}
                      options={formOptions?.medical_license_number}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <EAvFieldInput
                      field="date_of_join"
                      type="date"
                      value={data?.date_of_join}
                      isError={customError?.date_of_join}
                      options={formOptions?.date_of_join}
                      daterange={{ start: { value: -150, units: "years" }, end: { value: 90, units: "days" } }}
                    />
                  </Col>
                  <Col>
                    <EAvFieldSelect
                      field="role"
                      value={data?.role}
                      isError={customError?.role}
                      options={formOptions?.role}
                      choices={<>
                        <option>{"---------Select an option---------"}</option>
                        {formOptions?.role?.choices?.map((choice, cidx) =>
                          <option value={choice.value} key={cidx}>{choice.display_name}</option>
                        )}
                      </>}
                    />
                  </Col>
                </Row>
                <RequiredFieldsMessage />
                <Row>
                  <Col>
                    <div className="text-end mb-2 mt-3">
                      <SubmitLoaderButton type="submit" size="sm" loading={apiKey === "update_staff_updated" || apiKey === "post_staff_created"}>
                        SAVE
                      </SubmitLoaderButton>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </AvForm>
        </ModalBody>
      </Modal>
      <ConfirmationDialog
        activeOPAlert={activeOPAlert}
        setActiveOPAlert={setActiveOPAlert}
        state={state}
        onConfirm={onConfirm}
      />
    </div>
  )
}
