export const RESET_STATE_COMMON = "RESET_STATE_COMMON"

export const POST_BATCHES = "POST_BATCHES"
export const POST_BATCHES_FAIL = "POST_BATCHES_FAIL"
export const POST_BATCHES_SUCCESS = "POST_BATCHES_SUCCESS"

export const CHECK_CALLS = "CHECK_CALLS"
export const CHECK_CALLS_SUCCESS = "CHECK_CALLS_SUCCESS"
export const CHECK_CALLS_FAIL = "CHECK_CALLS_FAIL"

export const GET_SIGNED_URLS = "GET_SIGNED_URLS"
export const GET_SIGNED_URLS_SUCCESS = "GET_SIGNED_URLS_SUCCESS"
export const GET_SIGNED_URLS_FAIL = "GET_SIGNED_URLS_FAIL"
