import {
  POST_BATCHES,
  POST_BATCHES_FAIL,
  POST_BATCHES_SUCCESS,
  CHECK_CALLS,
  CHECK_CALLS_SUCCESS,
  CHECK_CALLS_FAIL,
  GET_SIGNED_URLS,
  GET_SIGNED_URLS_SUCCESS,
  GET_SIGNED_URLS_FAIL,
  RESET_STATE_COMMON,
} from "./actionTypes"

const INIT_STATE = {
  success: null,
  error: null,
  detailError: null,
  checkedCalls: [],
  signedURLs: []
}

const Common = (state=INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE_COMMON:
      return INIT_STATE
    case POST_BATCHES:
      return {
        ...state,
        error: null,
      }
    case POST_BATCHES_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case POST_BATCHES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CHECK_CALLS:
      return {
        ...state,
        detailError: null
      }
    case CHECK_CALLS_SUCCESS:
      return {
        ...state,
        checkedCalls: action.payload,
      }
    case CHECK_CALLS_FAIL:
      return {
        ...state,
        detailError: action.payload,
      }
    
    case GET_SIGNED_URLS:
      return {
        ...state,
        error: null
      }
    case GET_SIGNED_URLS_SUCCESS:
      return {
        ...state,
        signedURLs: action.payload,
      }
    case GET_SIGNED_URLS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    
    default:
      return state
  }
}

export default Common
