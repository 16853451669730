export const GET_CALLS = "GET_CALLS"
export const GET_CALLS_SUCCESS = "GET_CALLS_SUCCESS"
export const GET_CALLS_FAIL = "GET_CALLS_FAIL"

export const GET_USERS_ON_SEARCH = "GET_USERS_ON_SEARCH"
export const GET_USERS_ON_SEARCH_SUCCESS = "GET_USERS_ON_SEARCH_SUCCESS"
export const GET_USERS_ON_SEARCH_FAIL = "GET_USERS_ON_SEARCH_FAIL"

export const GET_CALL_OPTIONS = "GET_CALL_OPTIONS"
export const GET_CALL_OPTIONS_SUCCESS = "GET_CALL_OPTIONS_SUCCESS"
export const GET_CALL_OPTIONS_FAIL = "GET_CALL_OPTIONS_FAIL"

export const GET_DYNAMIC_FILTER_OPTIONS = "GET_DYNAMIC_FILTER_OPTIONS"
export const GET_DYNAMIC_FILTER_OPTIONS_SUCCESS = "GET_DYNAMIC_FILTER_OPTIONS_SUCCESS"
export const GET_DYNAMIC_FILTER_OPTIONS_FAIL = "GET_DYNAMIC_FILTER_OPTIONS_FAIL"
