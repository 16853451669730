/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

// User options
export const GET_USER_OPTIONS = "GET_USER_OPTIONS";
export const GET_USER_OPTIONS_SUCCESS = "GET_USER_OPTIONS_SUCCESS";
export const GET_USER_OPTIONS_FAIL = "GET_USER_OPTIONS_FAIL";

// All users
export const ALL_USERS = "ALL_USERS";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";

// All branches
export const GET_BRANCH_DATA = "GET_BRANCH_DATA";
export const GET_BRANCH_DATA_SUCCESS = "GET_BRANCH_DATA_SUCCESS";
export const GET_BRANCH_DATA_FAIL = "GET_BRANCH_DATA_FAIL";

// Agent Dashboard Data
export const AGENT_DATA = "AGENT_DATA";
export const AGENT_DATA_SUCCESS = "AGENT_DATA_SUCCESS";
export const AGENT_DATA_FAIL = "AGENT_DATA_FAIL";

// Branch Options
export const BRANCH_OPTIONS = "BRANCH_OPTIONS";
export const BRANCH_OPTIONS_SUCCESS = "BRANCH_OPTIONS_SUCCESS";
export const BRANCH_OPTIONS_FAIL = "BRANCH_OPTIONS_FAIL";

// Branch Groups Options
export const BRANCH_GROUPS_OPTIONS = "BRANCH_GROUPS_OPTIONS";
export const BRANCH_GROUPS_OPTIONS_SUCCESS = "BRANCH_GROUPS_OPTIONS_SUCCESS";
export const BRANCH_GROUPS_OPTIONS_FAIL = "BRANCH_GROUPS_OPTIONS_FAIL";

export const GET_DYNAMIC_USER_OPTIONS = "GET_DYNAMIC_USER_OPTIONS";
export const GET_DYNAMIC_USER_OPTIONS_SUCCESS = "GET_DYNAMIC_USER_OPTIONS_SUCCESS";
export const GET_DYNAMIC_USER_OPTIONS_FAIL = "GET_DYNAMIC_USER_OPTIONS_FAIL";

export const GET_PENDING_REQUESTS = "GET_PENDING_REQUESTS";
export const GET_PENDING_REQUESTS_SUCCESS = "GET_PENDING_REQUESTS_SUCCESS";
export const GET_PENDING_REQUESTS_FAIL = "GET_PENDING_REQUESTS_FAIL";

export const RESEND_ACTIVATIONLINK = "RESEND_ACTIVATIONLINK";
export const RESEND_ACTIVATIONLINK_SUCCESS = "RESEND_ACTIVATIONLINK_SUCCESS";
export const RESEND_ACTIVATIONLINK_FAIL = "RESEND_ACTIVATIONLINK_FAIL";

export const UPDATE_PENDING_REQUESTS = "UPDATE_PENDING_REQUESTS";
export const UPDATE_PENDING_REQUESTS_SUCCESS = "UPDATE_PENDING_REQUESTS_SUCCESS";
export const UPDATE_PENDING_REQUESTS_FAIL = "UPDATE_PENDING_REQUESTS_FAIL";