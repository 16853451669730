import { KPI_CREATE, KPI_CREATE_FAIL, KPI_CREATE_SUCCESS, KPI_DELETE, KPI_DELETE_FAIL, KPI_DELETE_SUCCESS, KPI_LIST, KPI_LIST_FAIL, KPI_LIST_SUCCESS, KPI_UPDATE, KPI_UPDATE_FAIL, KPI_UPDATE_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  loading: false,
  fieldSubmitting: false,
  error: null,
  kpis: []
}

const Configs = (state=INIT_STATE, action) => {
  switch (action.type) {
    case KPI_LIST:
      return {
          ...state,
          error: null,
          loading: true
      }
    case KPI_LIST_SUCCESS:
      return {
        ...state,
        kpis: action.payload,
        loading: false,
      }
    case KPI_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    
    case KPI_CREATE:
      return {
        ...state,
        formError: null,
        fieldSubmitting: true
      }
    case KPI_CREATE_SUCCESS:
      return {
        ...state,
        kpis: state.kpis.map(kpi => kpi.id===action.payload.kpi ? {...kpi, statement: action.payload.statement} : kpi),
        fieldSubmitting: false
      }
    case KPI_CREATE_FAIL:
      return {
        ...state,
        formError: action.payload,
        fieldSubmitting: false
      }

    case KPI_UPDATE:
      return {
        ...state,
        fieldSubmitting: true
      }
    case KPI_UPDATE_SUCCESS:
      return {
        ...state,
        kpis: state.kpis.map(kpi => kpi.id===action.payload.kpi ? {...kpi, statement: action.payload.statement} : kpi),
        fieldSubmitting: false
      }
    case KPI_UPDATE_FAIL:
      return {
        ...state,
        formError: action.payload,
        fieldSubmitting: false
      }
    case KPI_DELETE:
      return {
        ...state,
        error: null,
        fieldSubmitting: true
      }
    case KPI_DELETE_SUCCESS:
      return {
        ...state,
        error: null,
        kpis: state.kpis.filter(
          server => server.name.toString() !== action.payload.toString()
        ),
        fieldSubmitting: false
      }
    case KPI_DELETE_FAIL:
      return {
        ...state,
        error: action.payload,
        fieldSubmitting: false
      }
    default:
      return state
  }
}

export default Configs
