import React, { useEffect, useState } from 'react';
import "assets/yanthura/scss/common.scss"
import { isEmpty } from 'lodash';


const CheckboxDropdown = (props) => {
  const { options, defaultSelected, showText="Display Options", hideText="Select Options" } = props
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(isEmpty(defaultSelected) ? [] : defaultSelected);

  function handleCheckboxChange(option) {
    const currentIndex = selectedOptions.indexOf(option);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
  }

  function handleToggleClick() {
    setIsOpen(!isOpen);
    if (isOpen && !isEmpty(selectedOptions)) props.handleSelectedOptions(selectedOptions)
  }

  return (
    <div className="checkbox-dropdown">
      <div className="checkbox-dropdown__toggle" onClick={handleToggleClick}>
        { isOpen ? hideText : showText }
      </div>
      {isOpen && (
        <div className="checkbox-dropdown__options">
          {options.map(option => (
            <label key={option.value} className="checkbox-dropdown__option">
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
}

export default CheckboxDropdown;