import React, { useState } from "react"
import { Row, Col, CardBody, Card, Container } from "reactstrap"

import { Link } from "react-router-dom"

import profile from "assets/images/profile-img.png"
import logo from "assets/yanthura/images/logoIcon.png"
import OTPSend from "components/auth/OTPSend"
import SweetAlert from "react-bootstrap-sweetalert"
import RegisterVerification from "../Register/verify"
import ConfirmPassword from "../Register/ConfirmPassword"
import { useSelector } from "react-redux"

const ForgetPasswordPage = () => {
  const [state, setState] = useState('initiate')

  const { formError } = useSelector(state => ({
    formError: state.Account.formError,
  }))

  const handler = (state) => {
    setState(state)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Password reset</h5>
                        {/* <p>Please enter your email or phonenumber</p> */}
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="#">
                      <div className="avatar-md profile-user-wid mb-2">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  { state==='initiate' ?
                    <OTPSend handler={handler} />
                    : state === 'verification' ?
                    <RegisterVerification handler={handler} />
                  : state === 'confirmation' ? 
                    <ConfirmPassword handler={handler} purpose={"fp_change"} />
                  : state === 'finish' ? 
                    <SweetAlert
                      title="Password reset is success"
                      success
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        window.location = "/login"
                      }}
                      confirmBtnText="Login"
                    >
                      Your password has been changed successfully. Please login to seamless access
                    </SweetAlert>
                  :
                    null
                  }
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Login here{" "}
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Yanthura. All rights reserved
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ForgetPasswordPage
