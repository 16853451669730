import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import { isEmpty } from "lodash";
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { TestCRUModal } from "./modals";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getLocalStorageItem, updateURLParams } from "pages/HMS/common/common";

const Tests = (props) => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const [modals, setModals] = useState({ test: false, parameter: false, delete: false })
  const [tests, setTests] = useState({})
  const [test, setTest] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [columns, setColumns] = useState([])
  const [filters, setFilter] = useState({})
  const [editorStates, setEditorStates] = useState(EditorState.createEmpty());

  const { loading, options, hmsList, apiKey, error, formError, postResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
  }))

  useEffect(() => {
    if (apiKey === "tests_list") {
      setTests(hmsList)
    }
    if (apiKey === "tests_created") {
      window.location.replace(`/hms/diagnosis/tests/${postResponse.id}/detail`);
    }
    if (apiKey === "test_deleted") {
      setTests(hmsList)
      toggleDelete()
      toastr.success(`Test ${tests.name} is deleted`)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/diagnosis/tests/?options=table,search,daterange,filters,formschema", "test_options"))
    if (urlParams.length > 1) {
      dispatch(listHms("/hms/diagnosis/tests/", decodeURIComponent(urlParams).replace("?", ""), "tests_list"))
    } else {
      const savedFilters = getLocalStorageItem('filters')?.diagnosis_tests
      updateURLParams(history, savedFilters)
      dispatch(listHms("/hms/diagnosis/tests/", savedFilters, "tests_list"))
    }
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(options)) {
      if (options.filters && apiKey === "test_options") setFilter(options.filters);

      if (options.table_columns) {
        const buffer = options.table_columns.map(item => {
          if (item.dataField === "name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link to={`/hms/diagnosis/tests/${row.id}/detail`}>
                  {row.name}
                </Link>
              )
            };
          } else if (item.dataField === "category") {
            return {
              ...item,
              formatter: (cellContent, row) => row?.category_name || "---"
            };
          } else if (item.dataField === "method") {
            return {
              ...item,
              formatter: (cellContent, row) => row.method || "---"
            };
          } else if (item.dataField === "instrument") {
            return {
              ...item,
              formatter: (cellContent, row) => row.instrument || "---"
            };
          } else {
            return { ...item };
          }
        });

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle className="text-muted font-size-16 border-0" color="white" disabled={row?.status === "Expired"}>
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to="#" className="dropdown-item" onClick={() => handleEdit(row)}>
                  Modify
                </Link>
                <Link to="#" className="dropdown-item" onClick={() => handleDelete(row)}>
                  Remove
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        };

        setColumns([...buffer, actionColumn]);
      }
    }
  }, [options]);

  const handleEdit = (test_object) => {
    setTest(test_object)
    if (test_object?.interpretation) {
      const editorState = stateFromHTML(test_object?.interpretation);
      setEditorStates(EditorState.createWithContent(editorState));
    } else {
      setEditorStates(EditorState.createEmpty());
    }
    setRUDFlag(0)
    toggleTest(false)
  }

  const handleDelete = (test_object) => {
    setTest(test_object)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setTest({})
    setEditorStates(EditorState.createEmpty());
    setRUDFlag(1)
    toggleTest(false)
  }

  function toggleTest(def = modals.test) {
    setModals(prevModals => ({ ...prevModals, test: !def }));
  }

  function toggleDelete(def = modals.delete) {
    setModals(prevModals => ({ ...prevModals, delete: !def }));
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/diagnosis/tests/', decodeURIComponent(urlParams).replace("?", ""), "tests_list"))
      setTests({})
    } else {
      dispatch(listHms('/hms/diagnosis/tests/', null, "tests_list"))
      setTests({})
    }
  }

  function createButton() {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Add Test
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tests | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {error ? (
            <p className="text-center text-danger">{error?.detail}</p>
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  {(!isEmpty(columns)) && (
                    <GenericTable
                      moduleName="diagnosis_tests"
                      urlParams={urlParams}
                      filters={filters}
                      search={options?.search}
                      columns={columns}
                      keyField={"id"}
                      noDataIndication={"No tests created"}
                      data={tests}
                      loading={loading}
                      apiTrigger={apiTrigger}
                      defaultPageSize={options?.page_size}
                      createButton={createButton}
                    />
                  )}
                </Card>
              </Col>
            </Row>
          )}

          <HmsDeleteModal
            body={<>
              <ul>
                <li>If Test is removed. You cannot get it back</li>
                <li>If any data linked to this record will be vanished</li>
              </ul>
            </>}
            loading={loading}
            error={error}
            toggleDelete={toggleDelete}
            dispatchOperation={deleteHms("/hms/diagnosis/tests/", test.id, "test_deleted")}
            deleteModal={modals.delete}
          />

          <TestCRUModal
            modal={modals.test}
            toggle={toggleTest}
            rudFlag={rudFlag}
            data={test}
            editorStates={editorStates}
            setEditorStates={setEditorStates}
            apiStartKey={"test"}
            prepend={true}
            size="lg"
            options={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Tests;