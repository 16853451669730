import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, Container } from "reactstrap"
import { getHmsOptions, listHms, resetHmsState } from "store/actions";
import { formatDateTime, genderVerboseFetch } from "components/Common/common";
import { isEmpty } from "lodash";
import GenericTable from "pages/HMS/common/table";
import { getLocalStorageItem, Loader, updateURLParams } from "pages/HMS/common/common";

const Insurance = (props) => {
  const location = useLocation()
  const history = useHistory()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])
  const [insuranceList, setInsuranceList] = useState({})

  const { apiKey, options, error, hmsList } = useSelector(state => ({
    apiKey: state.Hms.apiKey,
    options: state.Hms.options,
    error: state.Hms.error,
    hmsList: state.Hms.hmsList,
    apiKey: state.Hms.apiKey,
  }));

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/insurance-inpatients/?options=table, search, daterange, insurance", "insurance_options"))
    return () => dispatch(resetHmsState())
  }, []);

  useEffect(() => {
    if (apiKey === "insurance_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/patient/insurance-inpatients/", decodeURIComponent(urlParams).replace("?", ""), "insurance_list"))
      } else {
        const savedFilters = getLocalStorageItem('filters')?.insurance
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/patient/insurance-inpatients/", savedFilters, "insurance_list"))
      }
    }
    if (apiKey === "insurance_list") {
      setInsuranceList(hmsList)
    }
  }, [apiKey])

  useEffect(() => {
    if (apiKey === "insurance_options") {
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "full_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <p>
                  <Link to={{ pathname: `/hms/patient/insurance/${row.id}/detail`, state: row }}>
                    {row.patient_data.first_name} {row.patient_data.last_name}
                  </Link>
                </p>
              )
            }
          } else if (item.dataField === "uid") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <p>{row.patient_data.uid}</p>
              )
            }
          } else if (item.dataField === "gender") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <p>{genderVerboseFetch(row.patient_data.user_data.gender)}</p>
              )
            }
          } else if (item.dataField === "phone_number") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <p>{row.patient_data.user_data.phone_number}</p>
              )
            }
          } else if (item.dataField === "created_at") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <p>{formatDateTime(row?.created_at)}</p>
              )
            }
          } else {
            return { ...item }
          }
        })
        setColumns(buffer)
      }
    }
  }, [options])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/patient/insurance-inpatients/", urlParams, "insurance_list"))
      setInsuranceList({})
    } else {
      dispatch(listHms("/hms/patient/insurance-inpatients/", null, "insurance_list"))
      setInsuranceList({})
    }
  }

  const refreshPage = () => {
    apiTrigger(decodeURIComponent(location.search).replace("?", ""))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Insurance Track | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_insurance_options" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="insurance"
              urlParams={urlParams}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField={'id'}
              noDataIndication={"No health insurance records found"}
              data={insuranceList}
              loading={apiKey === "list_insurance_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
              refreshPage={refreshPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment >
  );
};

export default Insurance;
