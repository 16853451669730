import { Modal, ModalHeader, ModalBody, Row, Col, Label } from "reactstrap"
import { useEffect, useRef, useState } from "react";
import { postDiagTestParam, updateDiagTestParam, postHms, updateHms, getHmsOptions, getHms } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr"
import { AvForm } from "availity-reactstrap-validation";
import { Loader } from "../../common/common";
import { EAsyncSelect, EAvFieldInput, EAvFieldNumber, EAvFieldSelect, ETextEditorInput } from "pages/HMS/common/errored-avfields";
import { stateToHTML } from 'draft-js-export-html';
import { REF_RESULT_MAX_LINES } from "pages/HMS/common/constants";
import { SingleFieldCRUModal } from "pages/HMS/common/hms-crud-modals";
import { get } from "helpers/api_helper"

export const ParamCRUModal = (props) => {
  const { modalLabel, modal, toggle, rudFlag, isHeader, setIsHeader, options, data, payload } = props
  const dispatch = useDispatch()
  const formRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [unitModal, setUnitModal] = useState(false)
  const [customError, setCustomError] = useState(null)
  const [state, setState] = useState({ unit: null, referenceResult: null })

  const { loading, apiKey, postResponse, updateResponse, formError, paramList } = useSelector(state => ({
    loading: state.Hms.loading,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
    formError: state.Hms.formError,
    paramList: state.Hms.paramList,
  }))

  useEffect(() => {
    setCustomError(formError)
  }, [formError])

  useEffect(() => {
    if (apiKey === "parameter_created") {
      paramToggle()
      toastr.success(`${postResponse.name} created successfully`)
      formRef.current?.reset()
    }
    if (apiKey === "parameter_updated") {
      paramToggle()
      setIsHeader(!updateResponse?.unit && !updateResponse?.value_type && !updateResponse?.reference_result)
      toastr.success(`${updateResponse.name} updated successfully`)
    }
    if (apiKey === "units_test_created") {
      setState(prevState => ({ ...prevState, unit: postResponse }));
    }
  }, [apiKey])

  const handleValidSubmit = (e, values) => {
    values = { ...values, ...payload }
    if (isHeader) {
      delete values.unit
      delete values.value_type
      delete values.reference_result
    } else if (values.reference_result?.split('\n').length > REF_RESULT_MAX_LINES) {
      setCustomError({ reference_result: `You cannot enter more than ${REF_RESULT_MAX_LINES} lines` });
      return;
    } else {
      setCustomError({})
    }
    values.unit = state?.unit?.id || data?.unit
    values.order = data?.order || getNewOrder()
    values.parent = data?.parent
    if (rudFlag === 0) {
      delete values.parent
      dispatch(updateDiagTestParam("/hms/diagnosis/parameters/", data?.id, values, `parameter_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postDiagTestParam("/hms/diagnosis/parameters/", values, `parameter_created`))
    }
  }

  const handleReferenceResultChange = (e) => {
    let inputText = e.target.value;
    let lines = inputText.split('\n');
    lines = lines.map(line => line.trim().slice(0, 160)).join('\n');
    setState(prevState => ({ ...prevState, referenceResult: lines }));
  };

  var suTo = null
  const searchUnit = (value, callback) => {
    value = value.trim()
    setIsMenuOpen(value.length > 0)
    if (value !== '' && value.length > 1) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        get(`/hms/diagnosis/test-units/?search=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 200)
    }
  }

  const getNewOrder = () => {
    if (paramList.length > 0) {
      const flatList = [];
      const flatten = (item) => {
        flatList.push(item);
        if (item.children && item.children.length > 0) {
          item.children.forEach(child => flatten(child));
        }
      };
      paramList.forEach(item => flatten(item));
      return (flatList.length > 0 ? Math.max(...flatList.map(item => item.order)) : 0) + 1;
    }
    return 1
  };

  const paramToggle = () => {
    setState({})
    setCustomError(null)
    toggle()
  }

  return (
    <>
      <Modal isOpen={modal} toggle={paramToggle} backdrop="static" size="lg">
        <ModalHeader toggle={paramToggle} tag="h4">
          {rudFlag === 0 ? `Modify ${modalLabel}` : rudFlag === 1 ? `Add ${modalLabel}` : ""}
        </ModalHeader>
        <ModalBody>
          {["get_parameter_detail", "options_parameter_options"].includes(apiKey) ? (
            <Loader />
          ) : (
            <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
              <Row>
                <Col sm={window.innerWidth <= 768 ? '12' : ''}>
                  <EAvFieldInput
                    field="name"
                    type="text"
                    value={data?.name}
                    isError={customError?.name && apiKey !== "fail_units_test_created"}
                    options={{ ...options?.form_schema?.name, label: isHeader ? "Header" : "Name" }}
                  />
                </Col>
                {!isHeader && (
                  <>
                    <Col sm={window.innerWidth <= 768 ? '12' : ''}>
                      <div className="d-flex align-items-center mb-3">
                        <div className="ajax-select select2-container" style={{ flex: "1" }}>
                          <Label className={customError?.unit ? "text-danger" : ''}>
                            {options?.form_schema?.unit?.label}
                          </Label>
                          <div className="d-flex align-items-center">
                            <div className="w-100">
                              <EAsyncSelect
                                onMenuClose={() => setIsMenuOpen(false)}
                                menuIsOpen={isMenuOpen}
                                selectedOption={[state?.unit || { id: data?.unit, name: data?.unit_name }]}
                                fetchOptions={searchUnit}
                                formError={customError?.unit}
                                onSelect={(value) => setState(prevState => ({ ...prevState, unit: value }))}
                                placeholder="Type to search units..."
                                getOptionLabel={e => e?.name}
                                getOptionValue={e => e?.id}
                              />
                            </div>
                            <div
                              title="Add unit"
                              className={`ms-2 cursor-pointer ${customError?.unit ? "mb-3" : ""}`}
                              onClick={() => setUnitModal(true)}
                            >
                              <i className="bx bx-plus-medical text-success" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={window.innerWidth <= 768 ? '12' : ''}>
                      <EAvFieldSelect
                        field="value_type"
                        isError={customError?.value_type}
                        value={data?.value_type}
                        options={options?.form_schema?.value_type}
                        choices={<>
                          <option value={""}>--Select an option--</option>
                          {options?.form_schema?.value_type?.choices?.map((item, cidx) =>
                            <option value={item.id} key={cidx}> {item.display_name} </option>
                          )}
                        </>}
                      />
                    </Col>
                    <Col sm="12">
                      <EAvFieldInput
                        field={"reference_result"}
                        type="textarea"
                        style={{ height: '120px', resize: 'none' }}
                        value={state?.referenceResult || data?.reference_result}
                        onChange={handleReferenceResultChange}
                        isError={customError?.reference_result}
                        options={{ ...options?.form_schema?.reference_result, max_length: 360 }}
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    {loading && apiKey === `update_parameter_updated` || apiKey === `post_parameter_created` ? (
                      <button disabled className="btn btn-primary btn-sm save-user">
                        SAVE <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary btn-sm save-user">
                        SAVE
                      </button>
                    )}
                  </div>
                </Col>
              </Row>
            </AvForm>
          )}
        </ModalBody>
      </Modal>

      <SingleFieldCRUModal
        modalLabel={"Test Unit"}
        modal={unitModal}
        toggle={() => setUnitModal(false)}
        rudFlag={1}
        options={{ label: "Name", required: true }}
        apiStartKey={"units_test"}
        apiEndpoint={"/hms/diagnosis/test-units/"}
      />
    </>
  )
}

export const TestCRUModal = (props) => {
  const { modal, toggle, rudFlag, data = null, editorStates, setEditorStates, apiStartKey, prepend = false, size = "lg", ...rest } = props
  const dispatch = useDispatch()
  const formRef = useRef()
  const [categories, setCategories] = useState([])
  const [customError, setCustomError] = useState(null)

  const { loading, apiKey, postResponse, updateResponse, formError, options, hmsObject } = useSelector(state => ({
    loading: state.Hms.loading,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
    updateResponse: state.Hms.updateResponse,
    formError: state.Hms.formError,
    options: state.Hms.options,
    hmsObject: state.Hms.hmsObject
  }))

  useEffect(() => {
    setCustomError(formError)
  }, [formError])

  useEffect(() => {
    if (apiKey === `${apiStartKey}_created`) {
      testToggle()
      toastr.success(`${postResponse.name} created successfully`)
      formRef.current?.reset()
    }
    if (apiKey === `${apiStartKey}_updated`) {
      testToggle()
      toastr.success(`${updateResponse.name} updated successfully`)
    }
    if (apiKey === "categories_options") {
      setCategories(hmsObject);
    }
  }, [apiKey])

  useEffect(() => {
    if (modal) {
      if (!rest.options) dispatch(getHmsOptions("/hms/diagnosis/tests/?options=formschema", "test_options"))
      dispatch(getHms("/hms/diagnosis/categories/fetch_all/", null, "categories_options"))
    }
  }, [modal])

  const testToggle = () => {
    setCustomError(null)
    toggle()
  }

  const handleValidSubmit = (e, values) => {
    values.interpretation = editorStates ? stateToHTML(editorStates?.getCurrentContent()) : ""
    if (rudFlag === 0) {
      dispatch(updateHms("/hms/diagnosis/tests/", data.id, values, `${apiStartKey}_updated`))
    } else if (rudFlag === 1) {
      delete values.uid
      dispatch(postHms("/hms/diagnosis/tests/", values, `${apiStartKey}_created`, prepend))
    }
  }

  return (
    <Modal isOpen={modal} toggle={testToggle} backdrop="static" size={size}>
      <ModalHeader toggle={testToggle} tag="h4">
        {rudFlag === 0 ? "Modify Test" : rudFlag === 1 ? "Add Test" : ""}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleValidSubmit} ref={formRef}>
          <Row>
            <Col md={"8"}>
              <EAvFieldInput
                field={"name"}
                type="text"
                value={data?.name}
                isError={customError?.name}
                options={options?.form_schema?.name}
              />
            </Col>
            <Col md={"4"}>
              <EAvFieldInput
                field={"short_name"}
                type="text"
                value={data?.short_name}
                isError={customError?.short_name}
                options={options?.form_schema?.short_name}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EAvFieldSelect
                field="category"
                value={data?.category}
                isError={customError?.category}
                options={options?.form_schema?.category}
                choices={<>
                  <option value={""}>--Select an option--</option>
                  {categories?.map((category, cidx) =>
                    <option value={category?.id} key={cidx}> {category?.name} </option>
                  )}
                </>}
              />
            </Col>
            <Col>
              <EAvFieldInput
                field={"method"}
                type="text"
                value={data?.method}
                isError={customError?.method}
                options={options?.form_schema?.method}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <EAvFieldInput
                field={"instrument"}
                type="text"
                value={data?.instrument}
                isError={customError?.instrument}
                options={options?.form_schema?.instrument}
              />
            </Col>
            <Col>
              <EAvFieldNumber
                field="fee"
                value={data?.fee}
                isError={customError?.fee}
                options={options?.form_schema?.fee}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ETextEditorInput
                editorState={editorStates}
                onEditorStateChange={newState => setEditorStates(newState)}
                formError={customError?.interpretation}
                options={options?.form_schema?.interpretation}
                height="300px"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                {loading && apiKey === `update_${apiStartKey}_updated` || apiKey === `post_${apiStartKey}_created` ? (
                  <button disabled className="btn btn-primary btn-sm save-user">
                    SAVE <i className="bx bx-loader bx-spin font-size-16 align-middle ms-2"></i>
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary btn-sm save-user">
                    SAVE
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}