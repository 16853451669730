import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class Doughnut extends Component {
  getOption = () => {
    return {
      toolbox: {
        show: false,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        // orient: "vertical",
        // x: "left",
        // data: ["Hyderabad", "Mumbai", "New Delhi", "Pune", "Banglore"],
        // textStyle: {
        //   color: ["#74788d"],
        // },
        show: false
      },
      color: ["#02a499", "#f8b425", "#ec4561", "#38a4f8", "#3c4ccf"],
      series: [
        {
          name: "Performance",
          type: "pie",
          radius: ["60%", "85%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center",
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: "16",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            { value: 70, name: "Hyderabad" },
            { value: 67, name: "Banglore" },
            { value: 88, name: "Kolkata" },
            { value: 97, name: "Pune" },
            { value: 49, name: "Mumbai" },
          ],
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "200px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default Doughnut
