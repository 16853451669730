export const countryTimezones = {
    "Asia/Kabul": "af",
    "Europe/Mariehamn": "ax",
    "Europe/Tirane": "al",
    "Africa/Algiers": "dz",
    "Pacific/Pago_Pago": "as",
    "Europe/Andorra": "ad",
    "Africa/Luanda": "ao",
    "America/Anguilla": "ai",
    "Antarctica/McMurdo": "aq",
    "Antarctica/South_Pole": "aq",
    "Antarctica/Rothera": "aq",
    "Antarctica/Palmer": "aq",
    "Antarctica/Mawson": "aq",
    "Antarctica/Davis": "aq",
    "Antarctica/Casey": "aq",
    "Antarctica/Vostok": "aq",
    "Antarctica/DumontDUrville": "aq",
    "Antarctica/Syowa": "aq",
    "America/Antigua": "ag",
    "America/Argentina/Buenos_Aires": "ar",
    "America/Argentina/Cordoba": "ar",
    "America/Argentina/Salta": "ar",
    "America/Argentina/Jujuy": "ar",
    "America/Argentina/Tucuman": "ar",
    "America/Argentina/Catamarca": "ar",
    "America/Argentina/La_Rioja": "ar",
    "America/Argentina/San_Juan": "ar",
    "America/Argentina/Mendoza": "ar",
    "America/Argentina/San_Luis": "ar",
    "America/Argentina/Rio_Gallegos": "ar",
    "America/Argentina/Ushuaia": "ar",
    "Asia/Yerevan": "am",
    "America/Aruba": "aw",
    "Australia/Lord_Howe": "au",
    "Antarctica/Macquarie": "au",
    "Australia/Hobart": "au",
    "Australia/Currie": "au",
    "Australia/Melbourne": "au",
    "Australia/Sydney": "au",
    "Australia/Broken_Hill": "au",
    "Australia/Brisbane": "au",
    "Australia/Lindeman": "au",
    "Australia/Adelaide": "au",
    "Australia/Darwin": "au",
    "Australia/Perth": "au",
    "Australia/Eucla": "au",
    "Europe/Vienna": "at",
    "Asia/Baku": "az",
    "America/Nassau": "bs",
    "Asia/Bahrain": "bh",
    "Asia/Dhaka": "bd",
    "America/Barbados": "bb",
    "Europe/Minsk": "by",
    "Europe/Brussels": "be",
    "America/Belize": "bz",
    "Africa/Porto-Novo": "bj",
    "Atlantic/Bermuda": "bm",
    "Asia/Thimphu": "bt",
    "America/La_Paz": "bo",
    "Europe/Sarajevo": "ba",
    "Africa/Gaborone": "bw",
    "America/Curacao": "an",
    "America/Noronha": "br",
    "America/Belem": "br",
    "America/Fortaleza": "br",
    "America/Recife": "br",
    "America/Araguaina": "br",
    "America/Maceio": "br",
    "America/Bahia": "br",
    "America/Sao_Paulo": "br",
    "America/Campo_Grande": "br",
    "America/Cuiaba": "br",
    "America/Santarem": "br",
    "America/Porto_Velho": "br",
    "America/Boa_Vista": "br",
    "America/Manaus": "br",
    "America/Eirunepe": "br",
    "America/Rio_Branco": "br",
    "Indian/Chagos": "io",
    "Asia/Brunei": "bn",
    "Europe/Sofia": "bg",
    "Africa/Ouagadougou": "bf",
    "Africa/Bujumbura": "bi",
    "Asia/Phnom_Penh": "kh",
    "Africa/Douala": "cm",
    "America/St_Johns": "ca",
    "America/Halifax": "ca",
    "America/Glace_Bay": "ca",
    "America/Moncton": "ca",
    "America/Goose_Bay": "ca",
    "America/Blanc-Sablon": "ca",
    "America/Montreal": "ca",
    "America/Toronto": "ca",
    "America/Nipigon": "ca",
    "America/Thunder_Bay": "ca",
    "America/Iqaluit": "ca",
    "America/Pangnirtung": "ca",
    "America/Resolute": "ca",
    "America/Atikokan": "ca",
    "America/Rankin_Inlet": "ca",
    "America/Winnipeg": "ca",
    "America/Rainy_River": "ca",
    "America/Regina": "ca",
    "America/Swift_Current": "ca",
    "America/Edmonton": "ca",
    "America/Cambridge_Bay": "ca",
    "America/Yellowknife": "ca",
    "America/Inuvik": "ca",
    "America/Creston": "ca",
    "America/Dawson_Creek": "ca",
    "America/Vancouver": "ca",
    "America/Whitehorse": "ca",
    "America/Dawson": "ca",
    "Atlantic/Cape_Verde": "cv",
    "America/Cayman": "ky",
    "Africa/Bangui": "cf",
    "Africa/Ndjamena": "td",
    "America/Santiago": "cl",
    "Pacific/Easter": "cl",
    "Asia/Shanghai": "cn",
    "Asia/Harbin": "cn",
    "Asia/Chongqing": "cn",
    "Asia/Urumqi": "cn",
    "Asia/Kashgar": "cn",
    "Indian/Christmas": "cx",
    "Indian/Cocos": "cc",
    "America/Bogota": "co",
    "Indian/Comoro": "km",
    "Africa/Brazzaville": "cg",
    "Africa/Kinshasa": "cd",
    "Africa/Lubumbashi": "cd",
    "Pacific/Rarotonga": "ck",
    "America/Costa_Rica": "cr",
    "Africa/Abidjan": "ci",
    "Europe/Zagreb": "hr",
    "America/Havana": "cu",
    "Asia/Nicosia": "cy",
    "Europe/Prague": "cz",
    "Europe/Copenhagen": "dk",
    "Africa/Djibouti": "dj",
    "America/Dominica": "dm",
    "America/Santo_Domingo": "do",
    "America/Guayaquil": "ec",
    "Pacific/Galapagos": "ec",
    "Africa/Cairo": "eg",
    "America/El_Salvador": "sv",
    "Africa/Malabo": "gq",
    "Africa/Asmara": "er",
    "Europe/Tallinn": "ee",
    "Africa/Addis_Ababa": "et",
    "Atlantic/Stanley": "fk",
    "Atlantic/Faroe": "fo",
    "Pacific/Fiji": "fj",
    "Europe/Helsinki": "fi",
    "Europe/Paris": "fr",
    "America/Cayenne": "gf",
    "Pacific/Tahiti": "pf",
    "Pacific/Marquesas": "pf",
    "Pacific/Gambier": "pf",
    "Indian/Kerguelen": "tf",
    "Africa/Libreville": "ga",
    "Africa/Banjul": "gm",
    "Asia/Tbilisi": "ge",
    "Europe/Berlin": "de",
    "Europe/Busingen": "de",
    "Africa/Accra": "gh",
    "Europe/Gibraltar": "gi",
    "Europe/Athens": "gr",
    "America/Godthab": "gl",
    "America/Danmarkshavn": "gl",
    "America/Scoresbysund": "gl",
    "America/Thule": "gl",
    "America/Grenada": "gd",
    "America/Guadeloupe": "gp",
    "Pacific/Guam": "gu",
    "America/Guatemala": "gt",
    "Europe/Guernsey": "gg",
    "Africa/Conakry": "gn",
    "Africa/Bissau": "gw",
    "America/Guyana": "gy",
    "America/Port-au-Prince": "ht",
    "Europe/Vatican": "va",
    "America/Tegucigalpa": "hn",
    "Asia/Hong_Kong": "hk",
    "Europe/Budapest": "hu",
    "Atlantic/Reykjavik": "is",
    "Asia/Kolkata": "in",
    "Asia/Calcutta": "in",
    "Asia/Jakarta": "id",
    "Asia/Pontianak": "id",
    "Asia/Makassar": "id",
    "Asia/Jayapura": "id",
    "Asia/Tehran": "ir",
    "Asia/Baghdad": "iq",
    "Europe/Dublin": "ie",
    "Europe/Isle_of_Man": "im",
    "Asia/Jerusalem": "il",
    "Europe/Rome": "it",
    "America/Jamaica": "jm",
    "Asia/Tokyo": "jp",
    "Europe/Jersey": "je",
    "Asia/Amman": "jo",
    "Asia/Almaty": "kz",
    "Asia/Qyzylorda": "kz",
    "Asia/Aqtobe": "kz",
    "Asia/Aqtau": "kz",
    "Asia/Oral": "kz",
    "Africa/Nairobi": "ke",
    "Pacific/Tarawa": "ki",
    "Pacific/Enderbury": "ki",
    "Pacific/Kiritimati": "ki",
    "Asia/Pyongyang": "kp",
    "Asia/Seoul": "kr",
    "Asia/Kuwait": "kw",
    "Asia/Bishkek": "kg",
    "Asia/Vientiane": "la",
    "Europe/Riga": "lv",
    "Asia/Beirut": "lb",
    "Africa/Maseru": "ls",
    "Africa/Monrovia": "lr",
    "Africa/Tripoli": "ly",
    "Europe/Vaduz": "li",
    "Europe/Vilnius": "lt",
    "Europe/Luxembourg": "lu",
    "Asia/Macau": "mo",
    "Europe/Skopje": "mk",
    "Indian/Antananarivo": "mg",
    "Africa/Blantyre": "mw",
    "Asia/Kuala_Lumpur": "my",
    "Asia/Kuching": "my",
    "Indian/Maldives": "mv",
    "Africa/Bamako": "ml",
    "Europe/Malta": "mt",
    "Pacific/Majuro": "mh",
    "Pacific/Kwajalein": "mh",
    "America/Martinique": "mq",
    "Africa/Nouakchott": "mr",
    "Indian/Mauritius": "mu",
    "Indian/Mayotte": "yt",
    "America/Mexico_City": "mx",
    "America/Cancun": "mx",
    "America/Merida": "mx",
    "America/Monterrey": "mx",
    "America/Matamoros": "mx",
    "America/Mazatlan": "mx",
    "America/Chihuahua": "mx",
    "America/Ojinaga": "mx",
    "America/Hermosillo": "mx",
    "America/Tijuana": "mx",
    "America/Santa_Isabel": "mx",
    "America/Bahia_Banderas": "mx",
    "Pacific/Chuuk": "fm",
    "Pacific/Pohnpei": "fm",
    "Pacific/Kosrae": "fm",
    "Europe/Chisinau": "md",
    "Europe/Monaco": "mc",
    "Asia/Ulaanbaatar": "mn",
    "Asia/Hovd": "mn",
    "Asia/Choibalsan": "mn",
    "Europe/Podgorica": "me",
    "America/Montserrat": "ms",
    "Africa/Casablanca": "ma",
    "Africa/Maputo": "mz",
    "Asia/Rangoon": "mm",
    "Africa/Windhoek": "na",
    "Pacific/Nauru": "nr",
    "Asia/Kathmandu": "np",
    "Europe/Amsterdam": "nl",
    "Pacific/Noumea": "nc",
    "Pacific/Auckland": "nz",
    "Pacific/Chatham": "nz",
    "America/Managua": "ni",
    "Africa/Niamey": "ne",
    "Africa/Lagos": "ng",
    "Pacific/Niue": "nu",
    "Pacific/Norfolk": "nf",
    "Pacific/Saipan": "mp",
    "Europe/Oslo": "no",
    "Asia/Muscat": "om",
    "Asia/Karachi": "pk",
    "Pacific/Palau": "pw",
    "Asia/Gaza": "ps",
    "Asia/Hebron": "ps",
    "America/Panama": "pa",
    "Pacific/Port_Moresby": "pg",
    "America/Asuncion": "py",
    "America/Lima": "pe",
    "Asia/Manila": "ph",
    "Pacific/Pitcairn": "pn",
    "Europe/Warsaw": "pl",
    "Europe/Lisbon": "pt",
    "Atlantic/Madeira": "pt",
    "Atlantic/Azores": "pt",
    "America/Puerto_Rico": "pr",
    "Asia/Qatar": "qa",
    "Indian/Reunion": "re",
    "Europe/Bucharest": "ro",
    "Europe/Kaliningrad": "ru",
    "Europe/Moscow": "ru",
    "Europe/Volgograd": "ru",
    "Europe/Samara": "ru",
    "Asia/Yekaterinburg": "ru",
    "Asia/Omsk": "ru",
    "Asia/Novosibirsk": "ru",
    "Asia/Novokuznetsk": "ru",
    "Asia/Krasnoyarsk": "ru",
    "Asia/Irkutsk": "ru",
    "Asia/Yakutsk": "ru",
    "Asia/Khandyga": "ru",
    "Asia/Vladivostok": "ru",
    "Asia/Sakhalin": "ru",
    "Asia/Ust-Nera": "ru",
    "Asia/Magadan": "ru",
    "Asia/Kamchatka": "ru",
    "Asia/Anadyr": "ru",
    "Africa/Kigali": "rw",
    "America/St_Barthelemy": "bl",
    "Atlantic/St_Helena": "sh",
    "America/St_Kitts": "kn",
    "America/St_Lucia": "lc",
    "America/Marigot": "mf",
    "America/Miquelon": "pm",
    "America/St_Vincent": "vc",
    "Pacific/Apia": "ws",
    "Europe/San_Marino": "sm",
    "Africa/Sao_Tome": "st",
    "Asia/Riyadh": "sa",
    "Africa/Dakar": "sn",
    "Europe/Belgrade": "rs",
    "Indian/Mahe": "sc",
    "Africa/Freetown": "sl",
    "Asia/Singapore": "sg",
    "Europe/Bratislava": "sk",
    "Europe/Ljubljana": "si",
    "Pacific/Guadalcanal": "sb",
    "Africa/Mogadishu": "so",
    "Africa/Johannesburg": "za",
    "Atlantic/South_Georgia": "gs",
    "Europe/Madrid": "es",
    "Africa/Ceuta": "es",
    "Atlantic/Canary": "es",
    "Asia/Colombo": "lk",
    "Africa/Khartoum": "sd",
    "America/Paramaribo": "sr",
    "Arctic/Longyearbyen": "sj",
    "Africa/Mbabane": "sz",
    "Europe/Stockholm": "se",
    "Europe/Zurich": "ch",
    "Asia/Damascus": "sy",
    "Asia/Taipei": "tw",
    "Asia/Dushanbe": "tj",
    "Africa/Dar_es_Salaam": "tz",
    "Asia/Bangkok": "th",
    "Asia/Dili": "tl",
    "Africa/Lome": "tg",
    "Pacific/Fakaofo": "tk",
    "Pacific/Tongatapu": "to",
    "America/Port_of_Spain": "tt",
    "Africa/Tunis": "tn",
    "Europe/Istanbul": "tr",
    "Asia/Ashgabat": "tm",
    "America/Grand_Turk": "tc",
    "Pacific/Funafuti": "tv",
    "Africa/Kampala": "ug",
    "Europe/Kiev": "ua",
    "Europe/Uzhgorod": "ua",
    "Europe/Zaporozhye": "ua",
    "Europe/Simferopol": "ua",
    "Asia/Dubai": "ae",
    "Europe/London": "gb",
    "America/New_York": "us",
    "America/Detroit": "us",
    "America/Kentucky/Louisville": "us",
    "America/Kentucky/Monticello": "us",
    "America/Indiana/Indianapolis": "us",
    "America/Indiana/Vincennes": "us",
    "America/Indiana/Winamac": "us",
    "America/Indiana/Marengo": "us",
    "America/Indiana/Petersburg": "us",
    "America/Indiana/Vevay": "us",
    "America/Chicago": "us",
    "America/Indiana/Tell_City": "us",
    "America/Indiana/Knox": "us",
    "America/Menominee": "us",
    "America/North_Dakota/Center": "us",
    "America/North_Dakota/New_Salem": "us",
    "America/North_Dakota/Beulah": "us",
    "America/Denver": "us",
    "America/Boise": "us",
    "America/Shiprock": "us",
    "America/Phoenix": "us",
    "America/Los_Angeles": "us",
    "America/Anchorage": "us",
    "America/Juneau": "us",
    "America/Sitka": "us",
    "America/Yakutat": "us",
    "America/Nome": "us",
    "America/Adak": "us",
    "America/Metlakatla": "us",
    "Pacific/Honolulu": "us",
    "Pacific/Johnston": "um",
    "Pacific/Midway": "um",
    "Pacific/Wake": "um",
    "America/Montevideo": "uy",
    "Asia/Samarkand": "uz",
    "Asia/Tashkent": "uz",
    "Pacific/Efate": "vu",
    "America/Caracas": "ve",
    "Asia/Ho_Chi_Minh": "vn",
    "America/Tortola": "vg",
    "America/St_Thomas": "vi",
    "Pacific/Wallis": "wf",
    "Africa/El_Aaiun": "eh",
    "Asia/Aden": "ye",
    "Africa/Lusaka": "zm",
    "Africa/Harare": "zw"
}

