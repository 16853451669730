import React, { useEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from "components/Common/Breadcrumb"
import { useDispatch, useSelector } from 'react-redux';
import Editable from "react-bootstrap-editable"
import { getKPIList as onGetKPIList, createKPI as onCreateKPI, updateKPI as onUpdateKPI, deleteKPI as onDeleteKPI } from "store/actions"
import { isEmpty } from 'lodash';
import toastr from 'toastr';

const KPI = () => {
  const dispatch = useDispatch()

  const { error, loading, kpis } = useSelector(state => ({
    error: state.Configs.error,
    loading: state.Configs.loading,
    kpis: state.Configs.kpis,
  }))

  useEffect(() => {
    dispatch(onGetKPIList())
  }, [dispatch])

  const confirmElement = (
    <button type="submit" className="btn editable-submit btn-sm me-1"><i className="mdi mdi-check"></i></button>
  )

  const cancelElement = (
    <button type="button" className="btn editable-cancel btn-sm"><i className="mdi mdi-close"></i></button>
  )

  const kpiSubmit = (value, kid, statement) => {
    if (!value && value.trim()==="") {
    } else {
      if (statement) {
        dispatch(onUpdateKPI(kid, { kpi: kid, statement: value }))
        toastr.success("KPI updated")
      } else {
        dispatch(onCreateKPI({ kpi: kid, statement: value }))
        toastr.success("KPI added successfully")
      }
    }
  }

  const kpiToggle = (kid, statement, value) => {
    dispatch(onUpdateKPI(kid, { is_active: value }))
    if (!statement) return
    {value ?
      toastr.success("KPI activated")
    :
      toastr.warning("KPI deactivated")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>KPI | Yanthura</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="KPI" breadcrumbItem="Configurations" />
          {loading ?
            <div className="">
              <p className="placeholder-glow"><span className="placeholder col-12"></span></p>
            </div>
            :
            <>
              {error ?
                <Card>
                  <CardBody>
                    <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {error.detail}</p>
                  </CardBody>
                </Card>
              :
              <>
              {!isEmpty(kpis) ?
                <Row>
                  <Col className="col-12">
                    <Card>
                      <div>
                        <h5 className="m-3 mb-0">KPI Configurations</h5>
                      </div>
                      <CardBody>
                        {kpis.map((kpi, idx) => (
                        <div className="form-check mb-3" key={idx}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`kpi${kpi.id}`}
                            defaultChecked={kpi.is_active}
                            onChange={(e) => kpiToggle(kpi.id, kpi.statement, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={`kpi${kpi.id}`}>
                            {kpi.name}
                          </label>
                          <Editable
                            alwaysEditing={false}
                            disabled={false}
                            editText={kpi.statement ? "Edit" : "Add"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            initialValue={kpi.statement}
                            onSubmit={(value) => kpiSubmit(value, kpi.id, kpi.statement)}
                            renderConfirmElement={confirmElement}
                            renderCancelElement={cancelElement}
                          />
                        </div>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                :
                <Card>
                  <CardBody>
                    <p className="text-center text-muted"><i className="bx bx-error-circle search-icon" /> No KPIs existed</p>
                  </CardBody>
                </Card>
              }
              </>
              }
            </>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default KPI