import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

const Reporter = (props) => {
  const { data } = props
  return (
    <React.Fragment>
      {data.owner &&
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">{data.name}</h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src="" alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0" title="Branch head">
          <Row>
            <Col sm="3">
              {!data.owner.avatar ?
                <div className="avatar-sm rounded-circle img-thumbnail">
                  <span className="avatar-title rounded-circle bg-success bg-soft text-success font-size-24">
                    {data.owner.full_name ? data.owner.full_name.charAt(0) : 'A'}
                  </span>
                </div>
              :
                <img
                  src={data.owner.avatar}
                  alt="hob"
                  className="avatar-sm rounded-circle img-thumbnail"
                />
              }
            </Col>
            <Col sm="9">
              <div className="pt-4">
                <h5 className="font-size-15">{data.owner.full_name}</h5>
                <p className="text-muted mb-0">{data.owner.email}</p>
                <p className="text-muted mb-0">{data.owner.phone_number}</p>
                {/* <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      }
    </React.Fragment>
  )
}
export default Reporter
