import { get, post, put, del } from "../../helpers/api_helper"

export const getSettingsAPI = () => get("/common/ops/storage")

export const checkStorageAPI = () => get("/common/check/storage")

export const createKeysAPI = () => post("/common/create/storage", {})

export const rotateKeysAPI = ({ settingId, data }) => {
    return put("/common/ops/storage", data)
}

export const updateBatchLimitAPI = ({ settingId, data }) => {
    return put("/common/ops/storage", data)
}

export const postNfsAPI = ({ data }) => {
    return post("/common/ops/storage", data)
}

export const updateNfsAPI = ({ data }) => {
    return put("/common/ops/storage", data)
}



export const getServersAPI = data => {
    let url = "/processor/create/server/list"
    if (data) url = `${url}?${data}`
    return get(url)
}
export const postServerAPI = ({data}) => post("/processor/create/server/list", data)
export const updateServerAPI = ({serverName, data}) => put(`/processor/server/${serverName}/rud`, data)
export const deleteServerAPI = serverName => del(`/processor/server/${serverName}/rud`)



export const getServicesAPI = ({serviceObject}) => {
    let url = `/processor/server/${serviceObject.name}/services`
    if (serviceObject.page) url = `${url}?${serviceObject.page}`
    return get(url)
}
export const postServiceAPI = ({server, data}) => post(`/processor/${server}/create/service`, data)
export const updateServiceAPI = ({serviceName, data}) => put(`/processor/service/${serviceName}/rud`, data)
export const deleteServiceAPI = serviceName => del(`/processor/service/${serviceName}/rud`)



