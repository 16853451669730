import AvField from 'availity-reactstrap-validation/lib/AvField'
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Input, Label } from 'reactstrap'
import { countryTimezones } from 'pages/HMS/common/timezones'


const MixedInput = props => {
  const { value } = props
  const [isEmail, setIsEmail] = useState(true)
  const [username, setUsername] = useState(value)
  const [phoneError, setPhoneError] = useState(true)

  const toggleMixedInput = () => {
    setIsEmail(!isEmail)
    setUsername(null)
  }

  const handleChange = value => {
    value = isEmail ? value : `+${value}`
    setUsername(value)
    props.onChange(value)
  }

  return (
    <div>
      {isEmail ?
        <div className="form-group">
          <Label>Email / Username</Label>
          <AvField
            name="email"
            className="form-control"
            placeholder="Enter email or username"
            type="text"
            required={isEmail}
            value={username}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
        :
        <div>
          <Label>Phonenumber</Label>
          <PhoneInput
            inputClass="w-100"
            country={'in'}
            value={username}
            specialLabel=""
            onChange={number => handleChange(number)}
            inputProps={{
              name: 'phonenumber',
              required: !isEmail
            }}
            isValid={phoneError}
          />
        </div>
      }
      <a onClick={toggleMixedInput}>
        <p className="text-end text-primary">Use {isEmail ? "phonenumber" : "e-mail"}</p>
      </a>
    </div>
  )
}

export default MixedInput;

export const DropdownInput = props => {
  const { placeholder, options } = props
  const [selectedOption, setSelectedOption] = useState(options.length !== 0 && options[0].value)

  const handleChange = (e) => {
    props.onChange(selectedOption, e.target.value)
  }

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value)
  }

  return (
    <div className="input-group">
      <select className="form-control" style={{ maxWidth: 'fit-content' }} onChange={handleSelectChange} defaultValue={selectedOption}>
        {options.map((value, idx) =>
          <option key={idx} value={value.value}>{value.label}</option>
        )}
      </select>
      <Input type="text" className="form-control" placeholder={placeholder} onChange={handleChange} />
    </div>
  )
}


export const PhonenumberInput = (props) => {
  const { label, name, value, error, required, updateValue, ...rest } = props;
  const countryCode = countryTimezones[(Intl.DateTimeFormat().resolvedOptions().timeZone)]

  useEffect(() => {
    updateValue(value)
  }, [value])

  return (
    <div className={`form-group ${error ? "has-error" : ""} mb-3`}>
      <Label>{label && <span className={error ? 'text-danger' : ''}>{label} {required && " *"}</span>}</Label>
      <PhoneInput
        inputClass="w-100"
        country={countryCode}
        value={value || ""}
        onChange={(number, e, e2, numberWithCountryCode) => updateValue(numberWithCountryCode.split(" ").length > 1 ? `+${number}` : null)}
        inputProps={{
          name: name,
          required: required,
          style: {
            border: error ? "1px solid #f46a6a" : "1px solid #ced4da",
          }
        }}
        {...rest}
      />
      {error && <div className="small text-danger">{error}</div>}
    </div>
  );
};