import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardTitle } from "reactstrap"
import moment from 'moment';
import { isEmpty } from "lodash";

const NER = (props) => {
  const {ner} = props
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-2">Entities</CardTitle>
          {isEmpty(ner) ?
            <p className="text-center text-muted">No entities created</p>
          :
          <div className="table-responsive" style={{maxHeight: '310px'}}>
            <table className="table align-middle table-nowrap table-sm">
              <tbody>
                {ner.map((obj, idx) => 
                  <tr key={idx}>
                    <td>
                      <p className="mb-0">{obj.text}</p>
                    </td>
                    <td>
                      <h6 className="mb-0">{obj.label}</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default NER
