import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, Badge } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { listHms, deleteHms, getHmsOptions, resetHmsState, postHms } from "store/actions"
import toastr from "toastr"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { DownloadModal, HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import { ConfigureCRUModal } from "./modals"
import { isEmpty } from "lodash"
import { HMSLink } from "pages/HMS/common/common"

const Configure = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [reportObj, setReportObj] = useState({ header_type: "Default" });
  const [reportList, setReportList] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [modals, setModals] = useState({ configureModal: false, deleteModal: false, downloadModal: false });

  const { loading, options, hmsList, apiKey, error, postResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
    postResponse: state.Hms.postResponse,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/hms-report-layouts/?options=table,formschema,search,filters", "fetch_config_options", false, true))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "fetch_config_options") {
      dispatch(listHms("/hms/hms-report-layouts/", null, "report_list"))
    }
    if (apiKey === "report_list") {
      setReportList(hmsList)
    }
    if (apiKey === "report_created") {
      setReportList(hmsList)
    }
    if (apiKey === "report_updated") {
      setReportList(hmsList)
    }
    if (apiKey === "report_deleted") {
      setReportList(hmsList)
      toggleModal('deleteModal')
      toastr.success(`Report ${reportObj.name} is deleted`)
    }
    if (apiKey === "preview_layout_report") {
      toggleModal("downloadModal")
    }
  }, [apiKey])

  useEffect(() => {
    if (apiKey === "fetch_config_options") {
      if (options.filters) setFilter(options.filters)
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "name") {
            return {
              ...item,
              formatter: (cellContent, row) => <Link to="#" onClick={() => handleEdit(row)}>{row.name}</Link>
            }
          } else if (item.dataField === "apply_on_all_pages") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                row.apply_on_all_pages ? <Badge color="success">All pages</Badge> : <Badge color="primary">First page</Badge>
              )
            }
          } else if (item.dataField === "qrcode") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <span>{row.qrcode ? (
                  <i className="bx bxs-check-circle font-size-16 text-success" />
                ) : (
                  <i className="bx bxs-x-circle font-size-16 text-danger" />
                )}</span>
              )
            }
          } else if (item.dataField === "action") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <UncontrolledDropdown className="ms-auto">
                  <DropdownToggle
                    className="text-muted font-size-16"
                    color="white"
                  >
                    <i className="mdi mdi-dots-horizontal"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu">
                    <HMSLink onClick={() => handlePreview(row)} apiStartKey="preview_layout_report">
                      Preview
                    </HMSLink>
                    <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
                      Remove
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )
            }
          } else {
            return { ...item }
          }
        })
        setColumns(buffer)
      }
    }
  }, [options])

  const toggleModal = (modalName) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: !prevModals[modalName],
    }));
  };

  const handleCreate = () => {
    setReportObj({ "header_type": "Default" })
    setRUDFlag(1)
    toggleModal("configureModal")
  }

  const handleEdit = report_object => {
    setReportObj(report_object)
    setRUDFlag(0)
    toggleModal("configureModal")
  }

  const handleDelete = report_object => {
    setReportObj(report_object)
    toggleModal("deleteModal")
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/hms-report-layouts/', decodeURIComponent(urlParams).replace("?", ""), "report_list"))
      setReportList({})
    } else {
      dispatch(listHms('/hms/hms-report-layouts/', null, "report_list"))
      setReportList({})
    }
  }

  function createButton() {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Add Report Layout
        </Button>
      </div>
    );
  }

  const handlePreview = report_object => {
    dispatch(postHms("/hms/hms-report-layouts/preview/", report_object, "preview_layout_report", false))
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            {error ? (
              <p className="text-center text-danger">{error?.detail}</p>
            ) : (
              <Col xs="12">
                <Card>
                  {(!isEmpty(columns)) && (
                    <GenericTable
                      urlParams={urlParams}
                      filters={filters}
                      search={options?.search}
                      columns={columns}
                      keyField={"id"}
                      noDataIndication={"No records created"}
                      data={reportList}
                      loading={apiKey === "list_report_list"}
                      apiTrigger={apiTrigger}
                      defaultPageSize={options?.page_size}
                      createButton={createButton}
                      removeQueryParams
                    />
                  )}

                  <HmsDeleteModal
                    body={<>
                      <ul>
                        <li>If any report using this layout will be unassigned</li>
                        <li>The header layout area will be replaced with empty space</li>
                      </ul>
                    </>}
                    loading={loading}
                    error={error}
                    toggleDelete={() => toggleModal("deleteModal")}
                    dispatchOperation={deleteHms("/hms/hms-report-layouts/", reportObj?.id, "report_deleted")}
                    deleteModal={modals.deleteModal}
                  />

                  <ConfigureCRUModal
                    modal={modals.configureModal}
                    toggle={() => toggleModal("configureModal")}
                    rudFlag={rudFlag}
                    data={reportObj}
                    apiStartKey="configure"
                  />

                  <DownloadModal
                    modal={modals.downloadModal}
                    toggle={() => toggleModal("downloadModal")}
                    apiStartKey={"preview_layout_report"}
                    base64String={postResponse}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Configure;