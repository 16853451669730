import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Col, Card, CardBody, Row, Table, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { deleteHms, getHms, resetHmsState } from "store/actions"
import { TableChip, formatDateTime, genderVerboseFetch, paymentVerboseFetch } from "components/Common/common"
import { Loader } from "pages/HMS/common/common"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { DownloadModal, HmsDeleteModal } from "pages/HMS/common/hms-crud-modals"
import toastr from "toastr"

const MedicineSaleDetails = (props) => {
  const dispatch = useDispatch()
  const [medicineSale, setMedicineSale] = useState(null)
  const [modals, setModals] = useState({ downloadModal: false, deleteModal: false })

  const { loading, hmsObject, apiKey, error } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
  }))

  const downloadReport = () => {
    if (medicineSale.id) {
      dispatch(getHms(`/hms/pharmacy/medicine_sales/${medicineSale.id}/download_report/`, null, "download_order_report"))
    }
  }

  useEffect(() => {
    dispatch(getHms(`/hms/pharmacy/medicine_sales/${props.match.params.tnxID}/`, null, "medicine_sales"))
    return () => dispatch(resetHmsState())
  }, [dispatch])

  useEffect(() => {
    if (apiKey === "medicine_sales") setMedicineSale(hmsObject)
    if (apiKey === "download_order_report") setModals(prevModals => ({ ...prevModals, downloadModal: true }))
    if (apiKey === "medicine_sale_detail_deleted") {
      toastr.success("Sold out medicine sale is deleted")
      setMedicineSale(null)
      toggleDelete()
      window.location.replace("/hms/pharmacy/sold-out-medicines")
    }
  }, [apiKey])

  const toggleDelete = (def = modals.deleteModal) => {
    setModals(prevModals => ({ ...prevModals, deleteModal: !def }))
  }

  const handleDelete = () => {
    toggleDelete(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Medicine Sale Details | Yanthura</title>
        </MetaTags>
        {apiKey === "get_fetch-order" ?
          <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching Order...</p>
          :
          <Card>
            <CardBody>
              {loading && apiKey === "get_medicine_sales" ? (
                <Loader />
              ) : error ? (
                <p className="text-center text-danger">{error.detail}</p>
              ) : (
                <Row>
                  <Col>
                    <div className="mt-2  d-flex justify-content-between">
                      <h5>Patient Information</h5>
                      <div className="text-sm-end ms-2">
                        <UncontrolledDropdown direction="down">
                          <DropdownToggle caret color="primary" size="sm">
                            Actions &nbsp;<i className="fa fa-caret-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link className="dropdown-item" to={`/hms/pharmacy/medicine-sale/${medicineSale?.id}`}>
                              Modify
                            </Link>
                            <Link className="dropdown-item" to={"#"} onClick={downloadReport}>
                              Print Receipt
                            </Link>
                            <Link className="dropdown-item" to={"#"} onClick={handleDelete}>
                              Remove
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    <hr />
                    <>
                      {medicineSale?.billing ? (
                        <>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Patient name"
                              value={medicineSale?.billing?.customer_name}
                            />
                            <TableChip
                              label="Patient ID"
                              value={medicineSale?.patient_uid}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Gender"
                              value={genderVerboseFetch(medicineSale?.patient?.gender)}
                            />
                            <TableChip
                              label="Date of birth"
                              value={medicineSale?.patient?.dob ? formatDateTime(medicineSale?.patient?.dob, "MMM D, YYYY") : null}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Mobile"
                              value={medicineSale?.patient?.phone_number}
                            />
                            <TableChip
                              label="Email"
                              value={medicineSale?.patient?.email}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Sale Date"
                              value={formatDateTime(medicineSale?.created_at)}
                            />
                            <TableChip
                              label="Transaction ID"
                              value={medicineSale?.billing?.uid}
                            />
                          </div>
                          <div className="d-flex justify-content-between">
                            <TableChip
                              label="Sale type"
                              value={
                                medicineSale?.outpatient ? (
                                  <Badge pill color="primary">Outpatient Sale</Badge>
                                ) : medicineSale?.inpatient ? (
                                  <Badge pill color="success">Inpatient sale</Badge>
                                ) : (
                                  <Badge pill color="secondary">Direct sale</Badge>
                                )
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <p className="text-center text-danger">Patient details not existed</p>
                      )}
                    </>
                    <Row>
                      <Col>
                        <div className="mt-2 d-flex justify-content-between">
                          <h5>Order Summary</h5>
                        </div>
                        <hr />
                        <div className="table-responsive">
                          <Table className="table mb-0 table table-sm">
                            <tbody>
                              <tr>
                                <td>Sub Total : </td>
                                <td>{medicineSale?.billing?.sub_total_amount}</td>
                              </tr>
                              <tr>
                                <td>Discount(%) : </td>
                                <td>{medicineSale?.billing?.discount_percent}</td>
                              </tr>
                              <tr>
                                <td>Discount Amount : </td>
                                <td>- {medicineSale?.billing?.discount_amount}</td>
                              </tr>
                              <tr>
                                <td className="w-50">
                                  <span>CGST(%) : </span>{medicineSale?.billing?.cgst_percent}</td>
                                <td className="w-50">
                                  <span>SGST(%) : </span>{medicineSale?.billing?.sgst_percent}</td>
                              </tr>
                              <tr>
                                <td>CGST : </td>
                                <td>{medicineSale?.billing?.cgst}</td>
                              </tr>
                              <tr>
                                <td>SGST : </td>
                                <td>{medicineSale?.billing?.sgst}</td>
                              </tr>
                              <tr>
                                <td className="w-50">
                                  Payment status : {medicineSale?.billing?.is_paid ? <Badge pill color="success">Paid</Badge> : <Badge pill color="danger">Unpaid</Badge>}
                                </td>
                                <td className="w-50">
                                  Payment mode : {paymentVerboseFetch(medicineSale?.billing?.payment_mode)}
                                </td>
                              </tr>
                              <tr>
                                <th>Grand Total : </th>
                                <th>{medicineSale?.billing?.grand_total_amount}</th>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <h5 className="mt-3">Sold Out Medicines Details</h5>
                    <hr />
                    <div className="table-responsive" style={{ overflowY: "auto", maxHeight: "566px" }}>
                      <Table className="table align-middle mb-0 table-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th>Medicine</th>
                            <th>MRP</th>
                            <th>Quantity</th>
                            <th colSpan="2">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {medicineSale?.medicines.map((med) => (
                            <tr key={med.id}>
                              <td>
                                <h5 className="font-size-14 text-truncate">
                                  {med.name}
                                </h5>
                              </td>
                              <td>{med.cost}</td>
                              <td>{med.quantity}</td>
                              <td style={{ width: "120px" }}>{(med.total_cost)}</td>
                            </tr>
                          ))}
                          <tr>
                            <td colSpan="2"></td>
                            <th style={{ textAlign: "right" }}>Sub Total:</th>
                            <td>{medicineSale?.billing?.sub_total_amount}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        }
      </div>

      <DownloadModal
        modal={modals.downloadModal}
        toggle={() => setModals(prevModals => ({ ...prevModals, downloadModal: false }))}
        apiStartKey={"download_order_report"}
      />

      <HmsDeleteModal
        body={<>
          <ul>
            <li>If sold out medicine is removed. You cannot get it back</li>
            <li>If any data linked to this record will be vanished</li>
          </ul>
        </>}
        loading={loading}
        error={error}
        toggleDelete={toggleDelete}
        dispatchOperation={deleteHms("/hms/pharmacy/medicine_sales/", medicineSale?.id, "medicine_sale_detail_deleted")}
        deleteModal={modals.deleteModal}
      />
    </React.Fragment >
  )
}

export default MedicineSaleDetails
