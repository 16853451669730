import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, Col, Container, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { listHms, deleteHms, getHmsOptions, resetHmsState } from "store/actions"
import toastr from "toastr"
import { useDispatch, useSelector } from "react-redux"
import GenericTable from "pages/HMS/common/table"
import { useLocation } from "react-router-dom"
import { HmsDeleteModal, SingleFieldCRUModal } from "../common/hms-crud-modals"

const TestUnits = props => {
  const location = useLocation()
  const urlParams = location.search
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [testUnit, setTestUnit] = useState({})
  const [rudFlag, setRUDFlag] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [testUnitsData, setTestUnitsData] = useState({})

  const { loading, options, hmsList, apiKey, error } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    hmsList: state.Hms.hmsList,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
  }))

  const columns = [{
    dataField: "test-units",
    text: "Test Units",
    sort: false,
    formatter: (cellContent, row) => (
      <Link to="#" onClick={() => handleEdit(row)}>{row.name}</Link>
    )
  }, {
    dataField: "action",
    isDummyField: true,
    text: "",
    formatter: (cellContent, row) => (
      <UncontrolledDropdown className="ms-auto">
        <DropdownToggle
          className="text-muted font-size-16"
          color="white"
        >
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu">
          <Link className="dropdown-item" to="#" onClick={() => handleEdit(row)}>
            Modify
          </Link>
          <Link className="dropdown-item" to="#" onClick={() => handleDelete(row)}>
            Remove
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    ),
  }
  ]

  useEffect(() => {
    if (apiKey === "unit_list") {
      setTestUnitsData(hmsList)
    }
    if (apiKey === "unit_created") {
      setTestUnitsData(hmsList)
    }
    if (apiKey === "unit_updated") {
      setTestUnitsData(hmsList)
    }
    if (apiKey === "unit_deleted") {
      setTestUnitsData(hmsList)
      toggleDelete()
      toastr.success(`Test unit ${testUnit.name} is deleted`)
    }
  }, [apiKey])

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/insurance_issuer/?options=table, search"))
    dispatch(listHms("/hms/diagnosis/test-units/", null, "unit_list"))
    return () => { dispatch(resetHmsState()) }
  }, [dispatch])

  const handleEdit = testUnitObject => {
    setTestUnit(testUnitObject)
    setRUDFlag(0)
    toggle(false)
  }

  const handleDelete = testUnitObject => {
    setTestUnit(testUnitObject)
    toggleDelete(false)
  }

  const handleCreate = () => {
    setTestUnit({})
    setRUDFlag(1)
    toggle(false)
  }

  const toggle = (def = modal) => {
    setModal(!def)
  }

  const toggleDelete = (state = true) => {
    setDeleteModal(!state)
  }

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms('/hms/diagnosis/test-units/', decodeURIComponent(urlParams).replace("?", ""), "unit_list"))
      setTestUnitsData({})
    } else {
      dispatch(listHms('/hms/diagnosis/test-units/', null, "unit_list"))
      setTestUnitsData({})
    }
  }

  const createButton = () => {
    return (
      <div className="text-sm-start">
        <Button
          type="button"
          color="primary"
          className="me-2 btn-sm"
          onClick={handleCreate}
        >
          <i className="mdi mdi-plus me-1" />
          Create
        </Button>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            {error ? (
              <p className="text-center text-danger">{error?.detail}</p>
            ) : (
              <Col xs="12">
                <Card>
                  {testUnitsData && (
                    <GenericTable
                      urlParams={urlParams}
                      search={options?.search}
                      columns={columns}
                      keyField={"id"}
                      noDataIndication={"No test units created"}
                      data={testUnitsData}
                      loading={loading}
                      apiTrigger={apiTrigger}
                      defaultPageSize={options?.page_size}
                      createButton={createButton}
                      removeQueryParams
                    />
                  )}

                  <HmsDeleteModal
                    isShowHeader={false}
                    body={<>
                      <p>Are you sure you want to delete this unit?</p>
                    </>}
                    loading={loading}
                    error={error}
                    toggleDelete={toggleDelete}
                    dispatchOperation={deleteHms("/hms/diagnosis/test-units/", testUnit?.id, "unit_deleted")}
                    deleteModal={deleteModal}
                  />

                  <SingleFieldCRUModal
                    modalLabel={"Test Unit"}
                    modal={modal}
                    toggle={toggle}
                    rudFlag={rudFlag}
                    options={{ label: "Name", required: true }}
                    data={testUnit}
                    apiStartKey={"units"}
                    apiEndpoint={"/hms/diagnosis/test-units/"}
                    prepend={true}
                  />
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TestUnits;