import {
  OTP_SEND,
  OTP_SEND_SUCCESS,
  OTP_SEND_ERROR,

  OTP_RESEND,
  OTP_RESEND_SUCCESS,
  OTP_RESEND_ERROR,

  OTP_VERIFY,
  OTP_VERIFY_SUCCESS,
  OTP_VERIFY_ERROR,

  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes"

export const otpSend = data => {
  return {
    type: OTP_SEND,
    payload: data,
  }
}
export const otpSendSuccess = data => {
  return {
    type: OTP_SEND_SUCCESS,
    payload: data,
  }
}
export const otpSendError = error => {
  return {
    type: OTP_SEND_ERROR,
    payload: error,
  }
}

export const otpResend = data => {
  return {
    type: OTP_RESEND,
    payload: data,
  }
}
export const otpResendSuccess = data => {
  return {
    type: OTP_RESEND_SUCCESS,
    payload: data,
  }
}
export const otpResendError = error => {
  return {
    type: OTP_RESEND_ERROR,
    payload: error,
  }
}

export const otpVerify = data => {
  return {
    type: OTP_VERIFY,
    payload: data,
  }
}
export const otpVerifySuccess = data => {
  return {
    type: OTP_VERIFY_SUCCESS,
    payload: data,
  }
}
export const otpVerifyError = error => {
  return {
    type: OTP_VERIFY_ERROR,
    payload: error,
  }
}

export const userForgetPassword = data => {
  return {
    type: FORGET_PASSWORD,
    payload: data,
  }
}
export const userForgetPasswordSuccess = data => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: data,
  }
}
export const userForgetPasswordError = error => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: error,
  }
}
