import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Card, CardBody, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { getHms, getHmsOptions, resetHmsState, updateHms } from "store/actions";
import { formatDateTime, TableChip } from "components/Common/common";
import InsuranceAddModal, { InsuranceHistoryModal } from "../insurance/modals";
import { Loader, PatientInformation } from "pages/HMS/common/common";

const InsuranceDetail = (props) => {
  const [insuranceDropdown, setInsuranceDropdown] = useState(false);
  const [inpatient, setInpatient] = useState({});
  const [insurance, setInsurance] = useState(null);
  const [modalState, setModalState] = useState({ insuranceAdd: false, insuranceHistory: false })
  const dispatch = useDispatch();

  const { options, error, hmsObject, apiKey, updateResponse } = useSelector(state => ({
    options: state.Hms.options,
    error: state.Hms.error,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    updateResponse: state.Hms.updateResponse,
  }));

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/health_insurance/", "health_insurance_options"))
    return () => dispatch(resetHmsState());
  }, []);

  useEffect(() => {
    if (apiKey === "health_insurance_options") {
      dispatch(getHms("/hms/patient/inpatients/", props.match.params.insuranceID, "ip_detail"));
    }
    if (apiKey === "ip_detail") {
      setInpatient(hmsObject);
      if (hmsObject?.patient_data?.health_insurance) {
        dispatch(getHms(`/hms/patient/health_insurance/${hmsObject?.patient_data?.health_insurance}/`, null, "insurance"))
      }
    }
    if (apiKey === "insurance") setInsurance(hmsObject);
    if (apiKey === "status_change") setInsurance(updateResponse);
  }, [apiKey]);

  const toggleModal = (modalKey, flag = null) => {
    setModalState(prevState => ({ ...prevState, [modalKey]: flag !== null ? flag : !modalState[modalKey] }))
  }

  const onStatusChange = (evt) => {
    dispatch(updateHms("/hms/patient/health_insurance/", insurance.id, { status: evt.target.value }, "status_change"))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{inpatient?.patient_data?.user_data?.full_name} | Yanthura</title>
        </MetaTags>
        <Card>
          <CardBody>
            {apiKey === "options_health_insurance_options" || apiKey === "get_ip_detail" ? (
              <Loader />
            ) : error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  {options?.status_choices &&
                    <select
                      value={insurance?.status ? insurance?.status : ""}
                      onChange={onStatusChange}
                      style={{
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        padding: '0',
                      }}
                      className="text-end me-3 text-muted mb-2"
                    >
                      <option value="">Status</option>
                      {options?.status_choices.map((choice, cidx) =>
                        <option value={choice.value} key={cidx}>{choice.display_name}</option>
                      )}
                    </select>
                  }
                  <div className="text-sm-end me-2 mb-2">
                    <Dropdown isOpen={insuranceDropdown} toggle={() => setInsuranceDropdown(!insuranceDropdown)} direction="down">
                      <DropdownToggle caret color="primary" size="sm">
                        INSURANCE &nbsp; <i className="fa fa-caret-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          data-toggle="modal"
                          onClick={() => toggleModal("insuranceAdd")}
                        >
                          CONFIGURE
                        </DropdownItem>
                        <DropdownItem
                          data-toggle="modal"
                          onClick={() => toggleModal("insuranceHistory")}
                          disabled={inpatient?.patient_data?.health_insurance ? false : true}
                        >
                          HISTORY
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <Row>
                  <Col>
                    <h5 className="mt-2">Insurance</h5>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip label="Policy number" value={insurance?.policy_number} />
                      <TableChip label="Issuer" value={insurance?.issuer_name} />
                      <TableChip label="Valid till" value={formatDateTime(insurance?.policy_valid_till, "ll", "NA")} />
                      <TableChip label="Current status" value={insurance?.status} />
                    </div>
                    <h5 className="mt-2">Emergency contact details</h5>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip label="Contact person" value={inpatient?.patient_data?.emergency_contact_name} />
                      <TableChip label="Relation" value={inpatient?.patient_data?.emergency_contact_relation} />
                      <TableChip label="Phonenumber" value={inpatient?.patient_data?.emergency_contact_number} />
                    </div>
                    <h5 className="mt-2">Caretakers</h5>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <TableChip label="Duty Doctor" value={inpatient?.duty_doctor} />
                      <TableChip label="Consulting Doctor" value={inpatient?.consulting_doctor} />
                      <TableChip label="Nurse" value={inpatient?.nurse} />
                    </div>
                  </Col>
                  <Col>
                    <h5 className="mt-2">Patient information</h5>
                    <hr />
                    {inpatient?.patient_data?.user_data ? (
                      <PatientInformation
                        data={inpatient?.patient_data}
                        includedFields={[
                          "patient_name",
                          "patient_id",
                          "gender",
                          "marital_status",
                          "date_of_birth",
                          "age",
                          "mobile",
                          "email",
                        ]}
                      />
                    ) : (
                      <p className="text-center text-danger">Patient details not existed</p>
                    )}
                  </Col>
                </Row>
              </>
            )}
          </CardBody>
        </Card>
      </div>
      <div>
        <InsuranceAddModal
          modalState={modalState.insuranceAdd}
          toggleModal={() => toggleModal("insuranceAdd")}
          inpatient={inpatient}
        />

        <InsuranceHistoryModal
          modalState={modalState.insuranceHistory}
          toggleModal={() => toggleModal("insuranceHistory")}
          insurance={insurance}
        />
      </div>
    </React.Fragment >
  );
};

export default InsuranceDetail;
