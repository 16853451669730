import React, { useEffect, useRef, useState } from "react"
import { Container, Row, Col, Button, Alert, InputGroup, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Components
import BarCard from "./card-user"
import MiniWidget from "./mini-widget"
import { useDispatch, useSelector } from "react-redux"
import { getBranchData as onGetBranchData } from "store/actions"
import { isEmpty } from "lodash"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import Reporter from "./reporter-card"
import ReactApexChart from "react-apexcharts"

const DashboardSaas = props => {
  const {uid, name} = props.location.state
  const [data, setData] = useState({})
  const { branchData, errorData, loading } = useSelector(state => ({
    branchData: state.DashboardGenericState.branchData,
    errorData: state.DashboardGenericState.errorData,
    loading: state.DashboardGenericState.loading,
  }))
  const dispatch = useDispatch()
  const [period, setPeriod] = useState({
    value: 1,
    unit: 'months'
  })
  const dateRangeRef = useRef()

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false
      }
    },
    colors: ["#556ee6", "#f1b44c"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
          formatter: function(val, timestamp) {
            return moment(new Date(timestamp)).format(data.period==="day" ? "MMM DD, YYYY" : "MMM YYYY")
        }
      }
    },
    yaxis: {
      labels: {
          formatter: function(val) {
            return val ? val.toFixed(2) : val
        }
      }
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  }

  useEffect(() => {
    dispatch(onGetBranchData(uid))
  }, [dispatch]);

  useEffect(() => {
    if(!isEmpty(branchData)) {
      setData(branchData)
    }
  }, [branchData]);
  
  function getURLParam(urlParams) {
    let urlParam = []
    Object.entries(urlParams).map(([k, v]) => {
      if (k!=='page' || k!==undefined || k!==null || k!=='') {
        if (typeof(v)=='object') {
          urlParam.push(`${k}=${v[0]}&${k}_end=${v[1]}`)
        } else {
          urlParam.push(`${k}=${v}`)
        }
      }
    })
    return urlParam.join('&')
  }

  const onPeriodChange = (period, unit) => {
    if (unit==='days') {
      period = Number(period)-1
    }
    let now = moment.utc()
    let end = now.format('YYYY-MM-DD')
    let start = now.subtract(period, unit).format('YYYY-MM-DD')
    let queryParam = getURLParam({
      start: start,
      end: end
    })
    dispatch(onGetBranchData(uid, queryParam))
    dateRangeRef.current.flatpickr.clear()
    if (period===6 && unit==='days') unit = 'weeks'
    setPeriod({
      value: period,
      unit: unit
    })
  }

  const onChangeDateRange = dateRange => {
    if (!isEmpty(dateRange)) {
      let queryParam = getURLParam({
        start: moment(dateRange[0]).format("YYYY-MM-DD"),
        end: moment(dateRange[1]).format("YYYY-MM-DD")
      })
      dispatch(onGetBranchData(uid, queryParam))
    }
    setPeriod({})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Dashboard | Yanthura
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem={name} />
          {errorData ? 
            <Alert color="danger" className="text-center">
              {errorData.msg}
            </Alert>
          :
          <div>
            {data &&
            <>
              <div className="d-flex flex-row mb-2">
                <div className="ms-auto">
                  <InputGroup>
                    <Flatpickr
                      className="form-control form-control-sm"
                      placeholder="Select a date range"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d"
                      }}
                      ref={dateRangeRef}
                      onClose={onChangeDateRange}
                    />
                  </InputGroup>
                </div>
                <div className="ms-2">
                  <Button className="btn-sm" color={period.unit==='days' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'days')}>Day</Button>
                  <Button className="btn-sm" color={period.unit==='weeks' ? 'primary' : 'light'} onClick={() => onPeriodChange(7, 'days')}>Week</Button>
                  <Button className="btn-sm" color={period.unit==='months' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'months')}>Month</Button>
                  <Button className="btn-sm" color={period.unit==='years' ? 'primary' : 'light'} onClick={() => onPeriodChange(1, 'years')}>Year</Button>
                </div>
              </div>

              {loading ?
                <p className="text-center mt-5">Fetching results. please wait{" "}<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i></p>
              :
              <>
                <Row>
                  <Col lg="4">
                    <Reporter data={data} />
                  </Col>
                  <Col lg="8">
                    <Row>
                      <MiniWidget period={period} data={data} />
                    </Row>
                    <BarCard branch={name} period={period} data={data} />
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <div className="d-flex flex-wrap">
                      <h5 className="card-title me-2">Score & Sentiment Trends</h5>
                    </div>

                    <div id="area-chart" dir="ltr">
                      {data.trends && data.trends[0].data.length>0 ?
                      <ReactApexChart
                        options={options}
                        series={data.trends}
                        type="area"
                        height={350}
                        className="apex-charts"
                      />
                      :
                      <p className="text-center">No trends existed</p>
                      }
                    </div>
                  </CardBody>
                </Card>
              </>
              }
            </>
            }
          </div>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardSaas
