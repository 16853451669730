import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'reactstrap';

const SuccessMessage = ({ data }) => {
  const successMessageStyle = {
    backgroundColor: '#FFFFFF',
    color: '#41B883',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    animation: 'slide-up 0.5s ease-in-out',
  };

  const contentContainerStyle = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  };

  const headingStyle = {
    fontSize: '24px',
    marginBottom: '10px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontWeight: 'bold',
    textAlign: 'center'
  };

  const infoStyle = {
    fontSize: '18px',
    marginBottom: '5px',
    lineHeight: '1.4',
    textAlign: 'center'
  };

  const infoStyleDetails = {
    fontSize: '16px',
    marginBottom: '5px',
    lineHeight: '1.4',
    color: 'gray',
  };

  return (
    <React.Fragment>
      <div style={successMessageStyle}>
        <div style={contentContainerStyle}>
          <h2 style={headingStyle}>Your appointment is set</h2>
          {data &&
            <div>
              <p style={infoStyleDetails}>Date: <span style={{ fontWeight: 'bold' }}>{moment(`${data?.appointment_date}T${data?.time_slot}`).format('MMM DD, hh:mm a')}</span></p>
              <p style={infoStyleDetails}>Patient: <span style={{ fontWeight: 'bold' }}>{data?.honorific} {data?.first_name} {data?.last_name}</span></p>
              <p style={infoStyleDetails}>Doctor: <span style={{ fontWeight: 'bold' }}>{data?.doctor_data?.user_data?.full_name}</span></p>
              <p style={infoStyleDetails}>Token number: <span style={{ fontWeight: 'bold' }}>{data?.token_number}</span></p>
            </div>
          }
        </div>
        <hr />
        <div className="d-flex justify-content-center">
          <Button className={window.innerWidth <= 425 ? 'btn-sm' : ''} color="primary" onClick={() => window.location.replace("/hms/patient/appointment/book")}>
            Add&nbsp;Another
          </Button>
          <Link to={"/hms/patient/appointments"} className={window.innerWidth <= 425 ? 'btn-sm btn btn-primary ms-1' : 'ms-1 btn btn-primary'}>
            Appointments
          </Link>
          <Button className={window.innerWidth <= 425 ? 'btn-sm ms-1' : 'ms-1'} color="primary" onClick={() => window.location.replace(`/hms/patient/appointment/book/${data?.id}`)}>
            Modify
          </Button>
          <Link
            to={`/hms/patient/appointment/${data?.id}/detail`}
            className={window.innerWidth <= 425 ? 'btn-sm ms-1 btn btn-primary' : 'ms-1 btn btn-primary'}
          >
            Explore
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SuccessMessage;
