import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
  Media,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import AsyncSelect from "react-select/async";
import { useSelector, useDispatch } from "react-redux"
import Breadcrumb2 from "components/Common/Breadcrumb2"
import makeAnimated from "react-select/animated"
import {
  getGroupDetail as onGetGroupDetail,
  addUsers as onAddUsers,
  deleteGroup as onDeleteGroup,
  getUnassignedGroupMembers as onGetUnassignedGroupMembers,
  removeGroupUser as onRemoveGroupUser
} from "store/actions"
import { Alert } from "reactstrap"
import { get } from "helpers/api_helper"
import FlashAlert from "components/Common/flash-message"
import { loadOrNoData } from "components/Common/common"
import toastr from "toastr"

const GroupDetail = props => {
  const dispatch = useDispatch()
  const animatedComponents = makeAnimated()

  const { group, members, error, loading, detailError } = useSelector(state => ({
    loading: state.Manage.loading,
    detailError: state.Manage.detailError,
    group: state.Manage.group,
    members: state.Manage.members,
    error: state.Manage.error,
  }))

  const [userList, setUserList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedUsers, setSelectedUsers] = useState([])
  
  const [modal, setModal] = useState(false)
  const [releaseUserModal, setReleaseUserModal] = useState(false)
  const [deleteEnabled, setReleaseEnabled] = useState(false)
  const [removableUser, setRemovableUser] = useState(null)

  const toggle = () => {
    setModal(!modal)
  }

  function toggleReleaseUser() {
    setReleaseUserModal(!releaseUserModal)
  }

  const UserColumns = toggleModal => [{
    dataField: "username",
    formatter: (cellContent, row) => (
      <Link to={`/user/${row.username}/dashboard`}>
        <Media className="m-5 mt-2 mb-2">
          <div className="align-self-center me-3">
            {row.avatar ?
              <img src={row.avatar} className="rounded-circle avatar-xs" alt="avatar" />
              :
              <div className="avatar-xs">
                <span className={"avatar-title rounded-circle bg-soft bg-success text-success font-size-14"}>
                  {row.full_name.charAt(0)}
                </span>
              </div>
            }
          </div>

          <Media className="overflow-hidden" body>
            <h5 className="text-truncate font-size-14 mb-1">{row.full_name}</h5>
            <p className="text-truncate mb-0">{row.email}</p>
            <p className="text-truncate mb-0">{row.username}</p>
          </Media>
          <Link to="#" onClick={() => handleRemoveUser(row)} title={`Remove ${row.full_name} from this group`}>
            <Badge pill className="badge-soft-danger ms-1">Remove</Badge>
          </Link>
        </Media>
      </Link>
    ),
  }
  ]
  useEffect(() => {
    dispatch(onGetGroupDetail(props.match.params.groupID))
  }, [dispatch])

  const fetchNewPage = (type, { page, sizePerPage }) => {
    setCurrentPage(page)
    dispatch(onGetUnassignedGroupMembers(`page=${page}`))
  }

  useEffect(() => {
    console.log(group);
    if (!isEmpty(group)) {
      setUserList(group.users)
      setSelectedUsers([])
    }
  }, [group])

  const handleAddUsers = () => {
    if (selectedUsers) {
      dispatch(onAddUsers(props.match.params.groupID, { users: selectedUsers.map(v => v.username) }))
      toastr.success("User(s) added to the group")
    }
  }

  const handleRemoveUser = (user) => {
    setRemovableUser(user)
    toggleReleaseUser()
  }

  const updateUsers = selectedUser => {
    setSelectedUsers(selectedUser)
  }

  var suTo = null
  const searchUsers = (value, callback) => {
    value = value.trim()
    if (value !== '' && value.length > 2) {
      clearTimeout(suTo)
      suTo = setTimeout(() => {
        // dispatch(onGetUnassignedGroupMembers(`query=${value}`))
        get(`/group/unassigners?query=${value}`).then((resp) => {
          callback(resp.results)
        })
      }, 1000)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Details | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb2 title={group ? group.name : "Detail"} breadcrumbItems={[{
            title: 'Manage'
          }, {
            title: (group && group.branch) && group.branch.name
          }, {
            title: group ? group.name : "Detail"
          }]} />
          {error === undefined ?
            <Alert color="danger">
              {error.detail}
            </Alert>
            : <div>
              <Row>
                <Col xl="4">
                  <Card>
                    <CardBody className="border-bottom">
                      <div>
                        <div className="mb-4 me-3">
                          <i className="mdi mdi-account-group-outline text-primary h1" />
                        </div>

                        <div>
                          <h5>{group.name}</h5>
                          <p className="text-muted mb-0">Key: {group.uid}</p>
                          <p className="text-muted mb-0">Branch: {group.branch && group.branch.name}</p>
                          <p className="text-muted mb-0">Group Lead: {group.owner && group.owner.full_name}</p>
                          <p className="text-muted mb-0">{group.owner && group.owner.email}</p>
                          <p className="text-muted mb-0">Members: <b>{group.users && group.users.length}</b></p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Assign users to group</CardTitle>
                      {detailError &&
                        <FlashAlert color="danger" delay={5000}>
                          {detailError.detail}
                        </FlashAlert>
                      }
                      <div className="mt-4 mb-3 ajax-select select2-container">
                        <AsyncSelect
                          isMulti
                          value={selectedUsers}
                          loadOptions={searchUsers}
                          onChange={updateUsers}
                          classNamePrefix="select2-selection"
                          placeholder="Type to search users..."
                          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                          // components={animatedComponents}
                          getOptionLabel={e => `${e.full_name}-${e.email}`}
                          getOptionValue={e => e.username}
                        />
                      </div>
                      {loading ?
                        <Button type="button" color="primary" disabled className="float-end">Submit{" "}<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i></Button>
                        :
                        <Button type="button" color="primary" onClick={handleAddUsers} className="float-end">Submit</Button>
                      }
                      {error &&
                        <Alert color="danger" className="mt-2">
                          {error.detail}
                        </Alert>
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Group members</CardTitle>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"username"}
                          responsive
                          bordered={false}
                          striped={false}
                          columns={UserColumns(toggle)}
                          data={userList}
                          noDataIndication={loadOrNoData(loading, "No users added to group")}
                          classes={"table align-middle table-nowrap table-check table-sm"}
                          headerWrapperClasses={"thead-light"}
                        />
                      </div>
                      <Modal isOpen={releaseUserModal} toggle={toggleReleaseUser} backdrop="static">
                        <ModalHeader toggle={toggleReleaseUser} tag="h4">
                          ⚠️ Warning: By removing user from group
                        </ModalHeader>
                        <ModalBody>
                          <Row>
                            <Col className="col-12">
                              <ul>
                                <li>User will be released from this group</li>
                                <li>His analytics will not be countable to this group and branch as well</li>
                                <li>You can reassign for this group at any time if he is not assigned to any group by the time</li>
                                <li>His analytics will be shifted to other group which he assigned to</li>
                              </ul>
                              <p>Type <i><b>{removableUser && removableUser.username}</b></i> to proceed remove operation</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12">
                              <div className="mb-3">
                                <input
                                  name="confirmation-text"
                                  type="text"
                                  className="form-control"
                                  required={false}
                                  onChange={(e) => {
                                    e.target.value === removableUser.username ? setReleaseEnabled(true) : setReleaseEnabled(false)
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="float-end">
                            <Col>
                              <button type="button" className="btn btn-outline-danger btn-sm me-2" disabled={!deleteEnabled}
                                onClick={() => {
                                  dispatch(onRemoveGroupUser(props.match.params.groupID, removableUser.username))
                                  toggleReleaseUser()
                                  toastr.success(`User ${removableUser.full_name} removed from group`)
                                }}>
                                Yes
                              </button>
                              <button type="button" className="btn btn-light btn-sm"
                                onClick={() => toggleReleaseUser()}>
                                No
                              </button>
                            </Col>
                          </Row>
                        </ModalBody>
                      </Modal>
                    </Col>
                  </CardBody>
                </Card>
              </Row>
            </div>
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

GroupDetail.propTypes = {
  group: PropTypes.object,
  onGetGroupDetail: PropTypes.func,
  onDeleteGroup: PropTypes.func,
  onUpdateGroup: PropTypes.func,
}

export default withRouter(GroupDetail)
