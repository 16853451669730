import React from "react"
import { Card, CardBody, CardTitle } from "reactstrap"
import moment from 'moment';

const CallProperties = (props) => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-2">Details</CardTitle>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap table-sm">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Filename</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h6 className="mb-0">{props.data.name}</h6>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Call ID</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h6 className="mb-0">{props.data.uid}</h6>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Duration</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h6 className="mb-0">{props.data.duration<60 ?
                      moment.utc(props.data.duration*1000).format('s[s]') : 
                      (props.data.duration>=60 || props.data.duration<3600) ? 
                      moment.utc(props.data.duration*1000).format('m[m] ss[s]') : 
                      moment.utc(props.data.duration*1000).format('h[h] m[m] ss[s]')}
                    </h6>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <p className="mb-0">Takeup time</p>
                  </td>
                  <td style={{ width: "25%" }}>
                    <h6 className="mb-0">{moment(props.data.created_at).format("D MMM, YYYY h:mm A")}</h6>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default CallProperties
