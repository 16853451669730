import { getDatesFromPeriod } from "components/Common/common"
import DefaultIfNone from "components/Common/default"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Col, Card, CardBody } from "reactstrap"

const MiniWidget = ({ period, data }) => {
  return (
    <React.Fragment>
      <Col sm="4">
        <Card>
          <CardBody>
            <Link to={{
              pathname: "/calls", 
              search: `?date=${getDatesFromPeriod(period)[0]}&date_end=${getDatesFromPeriod(period)[1]}&branch=${data.uid}`
            }}>
              <div className="d-flex align-items-center mb-3">
                <h5 className="font-size-14 mb-0">Calls</h5>
              </div>
              <div className=" d-flex justify-content-between text-muted mt-4">
                <h4><DefaultIfNone format={true} value={data.period_call_count} default_value={0} /></h4>
                <p>Total: {data.total_branch_calls}</p>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
      <Col sm="4">
        <Card>
          <CardBody>
            <Link to={{
              pathname: "/calls", 
              search: `?date=${getDatesFromPeriod(period)[0]}&date_end=${getDatesFromPeriod(period)[1]}&branch=${data.uid}&score=100`
            }}>
              <div className="d-flex align-items-center mb-3">
                <h5 className="font-size-14 mb-0">Well performed</h5>
              </div>
              <div className="text-muted mt-4">
                <h4>
                  <DefaultIfNone format={true} value={data.period_good_call_count} default_value={0} />
                </h4>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
      <Col sm="4">
        <Card>
          <CardBody>
            <Link to={{pathname: "/calls", search: `?date=${getDatesFromPeriod(period)[0]}&date_end=${getDatesFromPeriod(period)[1]}&branch=${data.uid}&score=${process.env.REACT_APP_SCORETHRESHOLD}`}}>
              <div className="d-flex align-items-center mb-3">
                <h5 className="font-size-14 mb-0">Under performed</h5>
              </div>
              <div className="text-muted mt-4">
                <h4>
                  <div>
                    <DefaultIfNone format={true} value={data.period_bad_call_count} default_value={0} />
                  </div>
                </h4>
              </div>
            </Link>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
}

export default MiniWidget
