import { GET_CALLS, GET_CALLS_FAIL, GET_CALLS_SUCCESS, GET_DYNAMIC_FILTER_OPTIONS, GET_DYNAMIC_FILTER_OPTIONS_FAIL, GET_DYNAMIC_FILTER_OPTIONS_SUCCESS, GET_CALL_OPTIONS, GET_CALL_OPTIONS_FAIL, GET_CALL_OPTIONS_SUCCESS, GET_USERS_ON_SEARCH, GET_USERS_ON_SEARCH_FAIL, GET_USERS_ON_SEARCH_SUCCESS } from "./actionTypes"


export const getCallUsers = data => ({
  type: GET_USERS_ON_SEARCH,
  data
})
export const getUsersSuccess = usersObject => ({
  type: GET_USERS_ON_SEARCH_SUCCESS,
  payload: usersObject,
})
export const getUsersFail = error => ({
  type: GET_USERS_ON_SEARCH_FAIL,
  payload: error,
})

export const getCallOptions = (params) => ({
  type: GET_CALL_OPTIONS,
  params
})
export const getCallOptionsSuccess = callOptions => ({
  type: GET_CALL_OPTIONS_SUCCESS,
  payload: callOptions,
})
export const getCallOptionsFail = error => ({
  type: GET_CALL_OPTIONS_FAIL,
  payload: error,
})

export const userDynamicFilterOptions = (payload) => ({
  type: GET_DYNAMIC_FILTER_OPTIONS,
  payload: payload,
})
export const userDynamicFilterOptionsSuccess = filterOptions => ({
  type: GET_DYNAMIC_FILTER_OPTIONS_SUCCESS,
  payload: filterOptions,
})
export const userDynamicFilterOptionsFail = error => ({
  type: GET_DYNAMIC_FILTER_OPTIONS_FAIL,
  payload: error,
})

export const getCalls = data => ({
  type: GET_CALLS,
  data
})
export const getCallsSuccess = callsObject => ({
  type: GET_CALLS_SUCCESS,
  payload: callsObject,
})
export const getCallsFail = error => ({
  type: GET_CALLS_FAIL,
  payload: error,
})
