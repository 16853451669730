import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button } from "reactstrap"
import { useEffect } from "react";
import { getHmsOptions, resetHmsState, updateHms } from "store/actions";
import toastr from "toastr"
import { useDispatch, useSelector } from "react-redux";
import { EAvFieldInput, EAvFieldSelect, RequiredFieldsMessage } from "pages/HMS/common/errored-avfields";
import { AvForm } from "availity-reactstrap-validation";
import { SubmitLoaderButton } from "pages/HMS/common/common";

export const InsuranceModal = (props) => {
  const { modals, toggleViewModal, patientID, insurance } = props
  const dispatch = useDispatch()

  const { loading, options, formError, apiKey } = useSelector(state => ({
    loading: state.Hms.loading,
    options: state.Hms.options,
    formError: state.Hms.formError,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/patient/health_insurance/",))
    return () => { dispatch(resetHmsState()) }
  }, [])

  useEffect(() => {
    if (apiKey === "insurance_update") {
      toastr.success("Insurance updated successfully")
      toggleViewModal('insuranceModal')
    }
  }, [apiKey])

  const handleSubmit = (e, values) => {
    if (patientID) {
      dispatch(updateHms(`/hms/patient/patients/`, patientID, {
        health_insurance_post: {
          issuer: values?.issuer,
          policy_number: values?.policy_number,
          policy_valid_till: values?.policy_valid_till,
          status: values?.status,
        }
      }, "insurance_update"))
    }
  };

  return (
    <Modal
      isOpen={modals.insuranceModal}
      toggle={() => toggleViewModal('insuranceModal')}
      backdrop="static"
    >
      <div className="modal-content">
        <AvForm onValidSubmit={handleSubmit} >
          <ModalHeader toggle={() => toggleViewModal('insuranceModal')}>Insurance</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <EAvFieldSelect
                  field="issuer"
                  value={insurance?.issuer}
                  isError={formError?.issuer}
                  options={{ required: true, label: <span>Insurance&nbsp;issuer</span> }}
                  choices={<>
                    <option value={""}>--Select an option--</option>
                    {options?.insurance_issuer?.map((choice, cidx) =>
                      <option value={choice.id} key={cidx}> {choice.name} </option>
                    )}
                  </>}
                />
              </Col>
              <Col>
                <EAvFieldInput
                  field="policy_number"
                  type="text"
                  value={insurance?.policy_number}
                  isError={formError?.policy_number}
                  options={{ label: <span>Policy&nbsp;number</span>, required: true, max_length: 50 }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EAvFieldInput
                  field="policy_valid_till"
                  type="date"
                  value={insurance?.policy_valid_till}
                  isError={formError?.policy_valid_till}
                  options={{ label: "Valid till", required: true }}
                  daterange={{ start: { value: -100, units: "years" }, end: { value: 100, units: "years" } }}
                />
              </Col>
              <Col>
                <EAvFieldSelect
                  field="status"
                  value={insurance?.status}
                  isError={formError?.status}
                  options={{ required: true, label: <span>Status</span> }}
                  choices={<>
                    <option value={""}>--Select an option--</option>
                    {options?.status_choices?.map((choice, cidx) =>
                      <option value={choice.value} key={cidx}> {choice.display_name} </option>
                    )}
                  </>}
                />
              </Col>
              <RequiredFieldsMessage />
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={() => toggleViewModal('insuranceModal')}>
              Close
            </Button>
            <SubmitLoaderButton type="submit" loading={apiKey === "update_insurance_update"}>
              Save
            </SubmitLoaderButton>
          </ModalFooter>
        </AvForm>
      </div>
    </Modal>
  )
}