import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { 
  addStatement as onCreateStatement,
  updateStatement as onUpdateStatement,
  deleteStatement as onRemoveStatement,
  getProformaDetail as onGetProformaStatements,
  addProforma as onCreateProforma,
  deleteProforma as onRemoveProforma
} from "store/proforma/actions";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import FlashAlert, { FlashBadge } from "components/Common/flash-message";
import axios from "axios";


const Proforma = props => {
  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])
  const [proformaObject, setProformaObject] = useState(props.match.params ? props.match.params : {})  // set from previous component (update)
  const [statementList, setStatementList] = useState([])
  const [save, setSave] = useState(false)
  const [canAddNextStatement, setCanAddNextStatement] = useState(true)

  const dispatch = useDispatch()

  const { proforma, statement, statements, formError, detailError } = useSelector(state => ({
    proforma: state.Proforma.proforma,
    statement: state.Proforma.statement,
    statements: state.Proforma.statements,
    formError: state.Proforma.formError,
    detailError: state.Proforma.detailError,
  }))

  useEffect(() => {
    if(props.match.params.action==='create' && props.match.params.proforma==='new') {
      dispatch(onCreateProforma())
    } else if(props.match.params.action==='edit') {
      dispatch(onGetProformaStatements(props.match.params.proforma))
    }
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(proforma)) {
      let tObj = {
        pid: proforma.id,
        proforma: proforma.pid,
        title: proforma.title
      }
      setProformaObject(tObj)
      setStatementList([])
    }
  }, [proforma])
  
  window.onbeforeunload = (event) => {
    if (isEmpty(statementList)) {
      dispatch(onRemoveProforma(proforma.pid))
    }
  }

  useEffect(() => {
    if (!isEmpty(statement)) {
      setStatementList(statementList.filter(stmt => stmt.id!==0))
      setStatementList([...statementList, statement])
    }
  }, [statement])

  useEffect(() => {
    if (!isEmpty(statements)) {
      setStatementList(statements.results)
    }
  }, [statements])

  // useEffect(() => {
  // }, [])

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]
      if (tab >= 1 && tab <= statementList.length) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  function saveProforma(e) {
    e.preventDefault()
    let statementObject = {
      proforma: e.target.proforma.value,
      title: e.target.title.value, 
      statement: e.target.statement.value
    }
    console.log(e.target.id.value);
    if (e.target.id.value==='0') {
      console.log(statementObject);
      dispatch(onCreateStatement(statementObject))
    } else {
      dispatch(onUpdateStatement(e.target.id.value, statementObject))
    }
    setSave(true);
  }

  function removeStatement(e, sid) {
    console.log(e.target, sid);
    dispatch(onRemoveStatement(sid))
    setStatementList(statementList.filter(stmt => stmt.id !== sid))
  }

  const updateState = (e, eid) => {
    setStatementList(statementList.map(stmt => {
      if (stmt.id===eid) {
        stmt[e.target.name] = e.target.value
      }
      if (stmt.id===0 && stmt.title && stmt.statement) {
        setCanAddNextStatement(true)
      }
      return stmt
    }))
  }

  function newStatement() {
    canAddNextStatement ? setStatementList([...statementList, {id: 0, title: "", statement: "", proforma: proformaObject.pid}]) : null
    setCanAddNextStatement(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create Proforma | Yanthura</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Create Proforma" breadcrumbItem={proformaObject.title} />
          <Row>
            <Col lg="8">
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    {save &&
                      <FlashBadge delay="5000" className="pe-4 ps-4 center" reset={() => setSave(false)}>Saved</FlashBadge>
                    }
                    <Button
                      type="button"
                      color="primary"
                      className="me-2 ms-auto"
                      onClick={newStatement}
                    >
                      <i className="mdi mdi-plus me-1" />
                      Add statement
                    </Button>
                  </div>
                  <hr />
                  {detailError &&
                    <FlashAlert delay="5000" color="danger" className="text-center">
                      {detailError.detail}
                    </FlashAlert>
                  }
                  {(formError && formError.non_field_errors) &&
                    <FlashAlert delay="5000" color="danger" className="text-center">
                      {formError.non_field_errors[0]}
                    </FlashAlert>
                  }
                  <div className="vertical-wizard wizard clearfix vertical">
                    <div className="steps clearfix">
                      <ul>
                        {statementList.map((v, idx) => (
                          <NavItem
                            className={classnames({
                              current: activeTabVartical===idx+1,
                            })}
                            key={idx}
                          >
                            <NavLink
                              className={classnames({
                                active: activeTabVartical===idx+1,
                              })}
                              onClick={() => {
                                toggleTabVertical(idx+1)
                              }}
                              disabled={!(passedStepsVertical || []).includes(idx+1)}
                            >
                              <span className="number">{idx+1}</span> {v.title}
                            </NavLink>
                          </NavItem>
                        ))}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTabVartical}
                        className="body"
                      >
                        {statementList.map((v, idx) => (
                          <TabPane tabId={idx+1} key={idx}>
                            <Form onSubmit={saveProforma} id={`proforma-form${idx}`}>
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Title
                                    </Label>
                                    <Input
                                      type="hidden"
                                      defaultValue={v.id}
                                      name="id"
                                      contentEditable={false}
                                    />
                                    <Input
                                      type="hidden"
                                      defaultValue={v.proforma}
                                      name="proforma"
                                      contentEditable={false}
                                    />
                                    <Input
                                      type="text"
                                      className="form-control"
                                      defaultValue={v.title}
                                      onChange={(e) => updateState(e, v.id)}
                                      name="title"
                                      invalid={(formError && formError.title) ? true : false}
                                      maxLength={1024}
                                    />
                                    <FormFeedback>
                                      {(formError && formError.title) && formError.title[0] }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="12">
                                  <FormGroup className="mb-3">
                                    <Label>
                                      Statement
                                    </Label>
                                    <Input
                                      type="textarea"
                                      className="form-control"
                                      rows="6"
                                      defaultValue={v.statement}
                                      onChange={(e) => updateState(e, v.id)}
                                      name="statement"
                                      invalid={(formError && formError.statement) ? true : false}
                                    />
                                    <FormFeedback>
                                      {(formError && formError.statement) && formError.statement[0] }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <div className="float-end">
                                <Button
                                  type="button"
                                  color="danger"
                                  className="me-2 ms-auto"
                                  onClick={(e) => removeStatement(e, v.id)}
                                >
                                  Remove
                                </Button>
                                <Button type="button" color="primary" className={`${activeTabVartical===1 ? "previous disabled": "previous"} me-2 ms-auto`} onClick={() => toggleTabVertical(activeTabVartical - 1)}>
                                  Previous
                                </Button>
                                <Button type="submit" color="primary" className="me-2 ms-auto" onClick={() => toggleTabVertical(activeTabVartical + 1)}>
                                  {activeTabVartical === statementList.length ? "Save" : "Next" }
                                </Button>
                              </div>
                            </Form>
                          </TabPane>
                        ))}
                      </TabContent>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="h-100">
                <CardBody>
                  <div className="">
                    <ul className="verti-timeline list-unstyled">
                      {/* Render Horizontal Timeline Events */}
                      {statementList.map((status, key) => (
                        <li key={key} className="event-list">
                          <div className="event-timeline-dot">
                            <i className="bx bx-comment-error text-success" />
                          </div>
                          <div className="media">
                            <div className="media-body">
                              <div>
                                <h5>{status.title}</h5>
                                <p className="text-muted">
                                  {status.statement}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Proforma
