import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags";
import { Card, CardBody, Container, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getHmsOptions, listHms, resetHmsState } from "store/actions";
import GenericTable from "pages/HMS/common/table";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { genderVerboseFetch } from "components/Common/common";
import { getLocalStorageItem, Loader, updateURLParams } from "pages/HMS/common/common";

const Doctors = props => {
  const location = useLocation()
  const urlParams = location.search
  const history = useHistory()
  const dispatch = useDispatch()
  const [filters, setFilter] = useState({})
  const [columns, setColumns] = useState([])
  const [doctorList, setDoctorList] = useState({})

  const { error, hmsList, options, apiKey } = useSelector(state => ({
    error: state.Hms.error,
    hmsList: state.Hms.hmsList,
    options: state.Hms.options,
    apiKey: state.Hms.apiKey,
  }))

  useEffect(() => {
    dispatch(getHmsOptions("/hms/staff/doctors/?options=table, search, daterange", "doctors_options"))
    return () => dispatch(resetHmsState())
  }, [])

  useEffect(() => {
    if (apiKey === "doctors_options") {
      if (options.filters && apiKey === "doctors_options") setFilter(options.filters)
      if (options.table_columns) {
        let buffer = options.table_columns.map(item => {
          if (item.dataField === "sno") {
            return {
              ...item,
              formatter: (cell, row, rowIndex, formatExtraData) => rowIndex + 1
            }
          } else if (item.dataField === "full_name") {
            return {
              ...item,
              formatter: (cellContent, row) => (
                <Link
                  to={`/hms/staff/doctors/${row.id}/detail`}
                  title={`Doctor ID: ${row.user_data.username}\n${row.user_data.email ? `Email: ${row.user_data.email}\n` : ""}Gender: ${genderVerboseFetch(row.user_data.gender)}`}
                >
                  {row.user_data.full_name}
                </Link>
              )
            }
          } else if (item.dataField === "phone_number") {
            return {
              ...item,
              formatter: (cellContent, row) => row.user_data.phone_number
            }
          } else if (item.dataField === "experience") {
            return {
              ...item,
              formatter: (cellContent, row) => row.experience || "---"
            }
          } else {
            return { ...item }
          }
        })

        const actionColumn = {
          dataField: "action",
          text: "",
          isDummy: true,
          formatter: (cellContent, row) => (
            <UncontrolledDropdown className="ms-auto">
              <DropdownToggle
                className="text-muted font-size-16"
                color="white"
              >
                <i className="mdi mdi-dots-horizontal"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link className="dropdown-item" to={`/hms/staff/doctors/add/${row.id}`}>
                  Modify
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
        setColumns([...buffer, actionColumn])
      }
    }
  }, [options])

  useEffect(() => {
    if (apiKey === "doctors_options") {
      if (urlParams.length > 1) {
        dispatch(listHms("/hms/staff/doctors/", decodeURIComponent(urlParams).replace("?", ""), "doctors_list"))
      } else {
        const savedFilters = getLocalStorageItem("filters")?.doctors
        updateURLParams(history, savedFilters)
        dispatch(listHms("/hms/staff/doctors/", savedFilters, "doctors_list"))
      }
    }
    if (apiKey === "doctors_list") {
      setDoctorList(hmsList)
    }
  }, [apiKey])

  const apiTrigger = (urlParams = null) => {
    if (urlParams) {
      dispatch(listHms("/hms/staff/doctors/", urlParams, "doctors_list"))
    } else {
      dispatch(listHms("/hms/staff/doctors/", null, "doctors_list"))
    }
  }

  const refreshPage = () => {
    apiTrigger(decodeURIComponent(location.search).replace("?", ""))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Doctors | Yanthura</title>
        </MetaTags>
        <Container fluid>
          {apiKey === "options_doctors_options" ? (
            <Loader />
          ) : error ? (
            <Card>
              <CardBody>
                <p className="text-center text-danger">{error.detail}</p>
              </CardBody>
            </Card>
          ) : (!isEmpty(columns)) && (
            <GenericTable
              moduleName="doctors"
              urlParams={urlParams}
              filters={filters}
              search={options.search}
              daterange={options.daterange}
              columns={columns}
              keyField={"id"}
              noDataIndication={"No doctors created"}
              data={doctorList}
              loading={apiKey === "list_doctors_list"}
              apiTrigger={apiTrigger}
              defaultPageSize={options.page_size}
              refreshPage={refreshPage}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Doctors
