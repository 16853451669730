import React from 'react'
import SweetAlert from "react-bootstrap-sweetalert";

function ConfirmationDialog({ activeOPAlert, setActiveOPAlert, state, onConfirm }) {
  return (
    <>
      {activeOPAlert && state?.title && state?.message &&
        <SweetAlert
          title={state?.title}
          warning
          showCancel
          confirmBtnText="Proceed"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={onConfirm}
          onCancel={() => setActiveOPAlert(false)}
        >
          {state?.message}
        </SweetAlert>
      }
    </>
  )
}

export default ConfirmationDialog