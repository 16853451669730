import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Col, Button, Card, CardBody, Row, Spinner, Label } from "reactstrap"
import { deleteDiagTestParam, getHms, getHmsOptions, listDiagTestParam, resetHmsState } from "store/actions";
import { TableChip } from "components/Common/common";
import { ParamCRUModal, TestCRUModal } from "./modals";
import { HMSViewModal, HmsDeleteModal } from "pages/HMS/common/hms-crud-modals";
import { isEmpty } from "lodash";
import toastr from "toastr"
import { Parameter } from "./parameter";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

const TestDetail = (props) => {
  const dispatch = useDispatch();
  const [test, setTest] = useState({})
  const [parameter, setParameter] = useState({})
  const [modals, setModals] = useState({ test: false, parameter: false, delete: false, interpretation: false });
  const [rudFlag, setRUDFlag] = useState(1)
  const [paramOptions, setParamOptions] = useState(null)
  const [editorStates, setEditorStates] = useState(EditorState.createEmpty());
  const [isHeader, setIsHeader] = useState(false);

  const { loading, hmsObject, apiKey, error, options, paramList, updateResponse } = useSelector(state => ({
    loading: state.Hms.loading,
    hmsObject: state.Hms.hmsObject,
    apiKey: state.Hms.apiKey,
    error: state.Hms.error,
    options: state.Hms.options,
    paramList: state.Hms.paramList,
    updateResponse: state.Hms.updateResponse,
  }));

  useEffect(() => {
    if (apiKey === "test_detail") {
      setTest(hmsObject)
      dispatch(listDiagTestParam(`/hms/diagnosis/tests/${props.match.params.testID}/all_test_parameters/`, null, "test_parameters"));
      dispatch(getHmsOptions("/hms/diagnosis/parameters/?options=formschema", "parameter_options"))
    }
    if (apiKey === "parameters_deleted") {
      toastr.success(`Parameter ${parameter.name} is deleted`)
      setParameter({})
      setModals(prevModals => ({ ...prevModals, delete: false }));
    }
    if (apiKey === "test_edit_updated") setTest(updateResponse);
    if (apiKey === "parameter_options") setParamOptions(options);
    if (apiKey === "parameter_detail") {
      setParameter(hmsObject);
      setIsHeader(!hmsObject?.unit && !hmsObject?.value_type && !hmsObject?.reference_result)
      setRUDFlag(0)
      setModals(prevModals => ({ ...prevModals, parameter: true }));
    }
  }, [apiKey]);

  useEffect(() => {
    dispatch(getHms("/hms/diagnosis/tests/", props.match.params.testID, "test_detail"));
    return () => {
      dispatch(resetHmsState())
    }
  }, []);

  function toggleTestEdit(def = modals.test) {
    setModals(prevModals => ({ ...prevModals, test: !def }));
    if (test?.interpretation) {
      const editorState = stateFromHTML(test?.interpretation);
      setEditorStates(EditorState.createWithContent(editorState));
    } else {
      setEditorStates(EditorState.createEmpty());
    }
  }

  const handleParamAdd = (parameter = {}, is_header = false) => {
    setRUDFlag(1)
    setParameter({ parent: parameter?.id, parameter_name: parameter?.name })
    setIsHeader(is_header)
    setModals(prevModals => ({ ...prevModals, parameter: true }));
  }

  const handleParamModify = (parameter_object) => {
    dispatch(getHms("/hms/diagnosis/parameters/", parameter_object?.id, "parameter_detail"));
  }

  const handleParamDelete = (parameter_object) => {
    setParameter(parameter_object)
    setIsHeader(false)
    setModals(prevModals => ({ ...prevModals, delete: true }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Test Detail | Yanthura</title>
        </MetaTags>
        <Card>
          <CardBody>
            {error ? (
              <p className="text-center text-danger">{error.detail}</p>
            ) : loading && apiKey === "get_test_detail" ? (
              <div className="text-center">
                <div className="avatar-md mx-auto">
                  <Spinner className="mt-5 fs-1" color="secondary" />
                </div>
                <div className="p-2 mt-4">
                  <h4>Fetching information...</h4>
                  <p className="text-muted">Please wait while we load the details</p>
                </div>
              </div>
            ) : (
              <Row>
                <Col md={"4"}>
                  <div className="d-flex justify-content-between">
                    <h5 className="mt-2 mb-0">Test Information</h5>
                    <Link title="Modify" className="text-muted" to="#" onClick={() => toggleTestEdit(false)}>
                      <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                    </Link>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <TableChip
                      label="Name (Shortname)"
                      value={`${test?.name} ${test?.short_name ? `(${test.short_name})` : ''}`}
                      defaultIfNull="NA"
                    />
                    <TableChip
                      label="Category"
                      value={test?.category_name}
                      defaultIfNull="NA"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <TableChip
                      label="Fee"
                      value={test?.fee}
                      defaultIfNull="NA"
                    />
                    <TableChip
                      label="Method"
                      value={test?.method}
                      defaultIfNull="NA"
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <TableChip
                      label="Instrument"
                      value={test?.instrument}
                      defaultIfNull="NA"
                    />
                    <div className="w-100 me-1">
                      <Label>Interpretation</Label>
                      {test?.interpretation && test?.interpretation !== "<p><br></p>" ? (
                        <p className="text-primary cursor-pointer" onClick={() => setModals(prevModals => ({ ...prevModals, interpretation: true }))}>
                          View <i className="mdi mdi-eye ms-1" />
                        </p>
                      ) : (
                        <p className="text-muted">No interpretation added</p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={"8"}>
                  <h5 className="mt-2">Parameters</h5>
                  <hr />
                  {!isEmpty(paramList) ? (
                    <ul className="mb-2">
                      {paramList?.map((parameter, index) => (
                        <Parameter
                          key={index}
                          index={index}
                          parameter={parameter}
                          isHeader={isHeader}
                          onParamAdd={handleParamAdd}
                          onParamModify={handleParamModify}
                          onParamDelete={handleParamDelete}
                        />
                      ))}
                    </ul>
                  ) : (
                    <p className="text-center text-danger">No parameters created</p>
                  )}
                  <div className="d-flex flex-column flex-sm-row justify-content-start">
                    <div className="text-sm-end mb-2 mt-2">
                      <Button type="button" color="primary" className="btn-sm me-1" onClick={handleParamAdd} >
                        Add Parameter
                      </Button>
                      <Button type="button" color="primary" className="btn-sm" onClick={() => handleParamAdd({}, true)} >
                        Add Header
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>

        <HmsDeleteModal
          body={<>
            <ul>
              <li>If Parameter is removed. You cannot get it back</li>
              <li>If any nested parameters linked to this parameter will be vanished completely</li>
            </ul>
          </>}
          loading={loading}
          error={error}
          toggleDelete={() => setModals(prevModals => ({ ...prevModals, delete: false }))}
          dispatchOperation={deleteDiagTestParam("/hms/diagnosis/parameters/", parameter?.id, "parameters_deleted")}
          deleteModal={modals.delete}
        />

        <ParamCRUModal
          modalLabel={parameter.parent && parameter.parameter_name ? `a nested parameter under ${parameter.parameter_name}` : parameter.parent ? "a nested parameter" : `${isHeader ? 'Header' : 'Parameter'}`}
          modal={modals.parameter}
          toggle={() => setModals(prevModals => ({ ...prevModals, parameter: false }))}
          rudFlag={rudFlag}
          data={parameter}
          payload={{ test: test?.id, parent: parameter?.parent }}
          isHeader={isHeader}
          setIsHeader={setIsHeader}
          options={paramOptions}
        />

        <HMSViewModal
          modalLabel="Interpretation"
          modal={modals.interpretation}
          toggle={() => setModals(prevModals => ({ ...prevModals, interpretation: false }))}
          size="xl"
          body={test?.interpretation && (
            <iframe
              srcDoc={test?.interpretation}
              style={{ border: "none", width: "100%", height: "75vh" }}
            />
          )}
        />

        <TestCRUModal
          modal={modals.test}
          toggle={toggleTestEdit}
          rudFlag={0}
          data={test}
          editorStates={editorStates}
          setEditorStates={setEditorStates}
          apiStartKey={"test_edit"}
          prepend={true}
          size="lg"
        />
      </div>
    </React.Fragment>
  );
};

export default TestDetail;
