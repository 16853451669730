import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE } from "./actionTypes"
import { profileSuccess, profileError, getProfileSuccess, getProfileFail } from "./actions"

import {
  modifyProfileAPI,
} from "../../../helpers/fakebackend_helper"
import { getProfileAPI } from "./api_helper"


function* getProfileSaga() {
  try {
    const response = yield call(getProfileAPI)
    yield put(getProfileSuccess(response))
  } catch (error) {
    yield put(getProfileFail(error.response.data))
  }
}

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(modifyProfileAPI, user)
    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error.response.data))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_PROFILE, getProfileSaga)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
