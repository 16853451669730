import {
  GET_SETTINGS,
  CREATE_KEYS,
  ROTATE_KEYS,
  BATCH_LIMIT_UPDATE,
  API_SUCCESS,
  API_FAIL,
  POST_NFS,
  UPDATE_NFS,
  POST_NFS_SUCCESS,
  POST_NFS_FAIL,
  GET_SERVERS,
  GET_SERVICES,
  SERVER_SUCCESS,
  SERVER_FAIL,
  SERVICE_SUCCESS,
  SERVICE_FAIL,
  POST_SERVICE,
  UPDATE_SERVICE,
  DELETE_SERVICE,
  POST_SERVICE_SUCCESS,
  POST_SERVICE_FAIL,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  POST_SERVER,
  POST_SERVER_SUCCESS,
  POST_SERVER_FAIL,
  UPDATE_SERVER,
  UPDATE_SERVER_SUCCESS,
  UPDATE_SERVER_FAIL,
  DELETE_SERVER,
  DELETE_SERVER_SUCCESS,
  DELETE_SERVER_FAIL,
  UPDATE_NFS_SUCCESS,
  UPDATE_NFS_FAIL,
  CHECK_STORAGE,
  CHECK_STORAGE_SUCCESS,
  CHECK_STORAGE_FAIL,
  RESET_STATE_SETTINGS,
} from "./actionTypes"

export const resetStateSettings = () => ({
  type: RESET_STATE_SETTINGS,
})

// Add branch
export const getSettings = () => ({
  type: GET_SETTINGS,
})
export const createKeys = () => ({
  type: CREATE_KEYS,
})
export const rotateKeys = (settingId, data) => ({
  type: ROTATE_KEYS,
  payload: {settingId, data},
})
export const updateBatchLimit = (settingId, data) => ({
  type: BATCH_LIMIT_UPDATE,
  payload: {settingId, data},
})
export const apiSuccess = data => ({
  type: API_SUCCESS,
  payload: data,
})
export const apiFail = error => ({
  type: API_FAIL,
  payload: error,
})

export const postNfs = (data) => ({
  type: POST_NFS,
  payload: {data},
})
export const postNfsSuccess = data => ({
  type: POST_NFS_SUCCESS,
  payload: data,
})
export const postNfsFail = error => ({
  type: POST_NFS_FAIL,
  payload: error,
})

export const updateNfs = (data) => ({
  type: UPDATE_NFS,
  payload: {data},
})
export const updateNfsSuccess = data => ({
  type: UPDATE_NFS_SUCCESS,
  payload: data,
})
export const updateNfsFail = error => ({
  type: UPDATE_NFS_FAIL,
  payload: error,
})




export const getServers = data => ({
  type: GET_SERVERS,
  data
})
export const serverSuccess = data => ({
  type: SERVER_SUCCESS,
  payload: data
})
export const serverFail = error => ({
  type: SERVER_FAIL,
  payload: error,
})
export const postServer = (data) => ({
  type: POST_SERVER,
  payload: {data},
})
export const postServerSuccess = service => ({
  type: POST_SERVER_SUCCESS,
  payload: service,
})
export const postServerFail = error => ({
  type: POST_SERVER_FAIL,
  payload: error,
})
export const updateServer = (serverName, data) => ({
  type: UPDATE_SERVER,
  payload: {serverName, data},
})
export const updateServerSuccess = serverName => ({
  type: UPDATE_SERVER_SUCCESS,
  payload: serverName,
})
export const updateServerFail = error => ({
  type: UPDATE_SERVER_FAIL,
  payload: error,
})
export const deleteServer = serverName => ({
  type: DELETE_SERVER,
  payload: serverName,
})
export const deleteServerSuccess = serverName => ({
  type: DELETE_SERVER_SUCCESS,
  payload: serverName,
})
export const deleteServerFail = error => ({
  type: DELETE_SERVER_FAIL,
  payload: error,
})




export const getServices = (serviceObject) => ({
  type: GET_SERVICES,
  payload: serviceObject
})
export const serviceSuccess = data => ({
  type: SERVICE_SUCCESS,
  payload: data
})
export const serviceFail = error => ({
  type: SERVICE_FAIL,
  payload: error,
})
export const postService = (server, data) => ({
  type: POST_SERVICE,
  payload: {server, data},
})
export const postServiceSuccess = service => ({
  type: POST_SERVICE_SUCCESS,
  payload: service,
})
export const postServiceFail = error => ({
  type: POST_SERVICE_FAIL,
  payload: error,
})
export const updateService = (serviceName, data) => ({
  type: UPDATE_SERVICE,
  payload: {serviceName, data},
})
export const updateServiceSuccess = serviceName => ({
  type: UPDATE_SERVICE_SUCCESS,
  payload: serviceName,
})
export const updateServiceFail = error => ({
  type: UPDATE_SERVICE_FAIL,
  payload: error,
})
export const deleteService = serviceName => ({
  type: DELETE_SERVICE,
  payload: serviceName,
})
export const deleteServiceSuccess = serviceName => ({
  type: DELETE_SERVICE_SUCCESS,
  payload: serviceName,
})
export const deleteServiceFail = error => ({
  type: DELETE_SERVICE_FAIL,
  payload: error,
})

export const checkStorage = () => ({
  type: CHECK_STORAGE,
})
export const checkStorageSuccess = data => ({
  type: CHECK_STORAGE_SUCCESS,
  payload: data,
})
export const checkStorageFail = error => ({
  type: CHECK_STORAGE_FAIL,
  payload: error,
})

