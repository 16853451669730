import {
    ADD_PROFORMA,
    ADD_PROFORMA_FAIL,
    ADD_PROFORMA_SUCCESS,
    ADD_STATEMENT,
    ADD_STATEMENT_FAIL,
    ADD_STATEMENT_SUCCESS,
    DELETE_PROFORMA,
    DELETE_PROFORMA_FAIL,
    DELETE_PROFORMA_SUCCESS,
    DELETE_STATEMENT,
    DELETE_STATEMENT_FAIL,
    DELETE_STATEMENT_SUCCESS,
    GET_PROFORMA_DETAIL,
    GET_PROFORMA_DETAIL_FAIL,
    GET_PROFORMA_DETAIL_SUCCESS,
    GET_PROFORMA_LIST, GET_PROFORMA_LIST_FAIL, GET_PROFORMA_LIST_SUCCESS, UPDATE_PROFORMA, UPDATE_PROFORMA_FAIL, UPDATE_PROFORMA_SUCCESS, UPDATE_STATEMENT, UPDATE_STATEMENT_FAIL, UPDATE_STATEMENT_SUCCESS
} from "./actionTypes";

export const getProformaList = () => ({
    type: GET_PROFORMA_LIST,
})
export const getProformaListSuccess = proformaList => ({
    type: GET_PROFORMA_LIST_SUCCESS,
    payload: proformaList,
})
export const getProformaListFail = detailError => ({
    type: GET_PROFORMA_LIST_FAIL,
    payload: detailError,
})



export const addProforma = proforma => ({
    type: ADD_PROFORMA,
    payload: proforma,
})
export const addProformaSuccess = proforma => ({
    type: ADD_PROFORMA_SUCCESS,
    payload: proforma,
})
export const addProformaFail = formError => ({
    type: ADD_PROFORMA_FAIL,
    payload: formError,
})

export const updateProforma = proformaID => ({
    type: UPDATE_PROFORMA,
    payload: proformaID,
})
export const updateProformaSuccess = ({proformaID, proforma}) => ({
    type: UPDATE_PROFORMA_SUCCESS,
    payload: {proformaID, proforma},
})
export const updateProformaFail = formError => ({
    type: UPDATE_PROFORMA_FAIL,
    payload: formError,
})

export const deleteProforma = proformaID => ({
    type: DELETE_PROFORMA,
    payload: proformaID,
})
export const deleteProformaSuccess = proformaID => ({
    type: DELETE_PROFORMA_SUCCESS,
    payload: proformaID,
})
export const deleteProformaFail = detailError => ({
    type: DELETE_PROFORMA_FAIL,
    payload: detailError,
})







export const getProformaDetail = proformaID => ({
    type: GET_PROFORMA_DETAIL,
    proformaID,
})
export const getProformaDetailSuccess = proforma => ({
    type: GET_PROFORMA_DETAIL_SUCCESS,
    payload: proforma,
})
export const getProformaDetailFail = detailError => ({
    type: GET_PROFORMA_DETAIL_FAIL,
    payload: detailError,
})

export const addStatement = statement => ({
    type: ADD_STATEMENT,
    payload: statement,
})
export const addStatementSuccess = statement => ({
    type: ADD_STATEMENT_SUCCESS,
    payload: statement,
})
export const addStatementFail = formError => ({
    type: ADD_STATEMENT_FAIL,
    payload: formError,
})

export const updateStatement = (statementID, statement) => ({
    type: UPDATE_STATEMENT,
    payload: {statementID, statement},
})
export const updateStatementSuccess = statementID => ({
    type: UPDATE_STATEMENT_SUCCESS,
    payload: statementID,
})
export const updateStatementFail = formError => ({
    type: UPDATE_STATEMENT_FAIL,
    payload: formError,
})

export const deleteStatement = statementID => ({
    type: DELETE_STATEMENT,
    payload: statementID,
})
export const deleteStatementSuccess = statementID => ({
    type: DELETE_STATEMENT_SUCCESS,
    payload: statementID,
})
export const deleteStatementFail = detailError => ({
    type: DELETE_STATEMENT_FAIL,
    payload: detailError,
})
