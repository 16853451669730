import {
  POST_BATCHES,
  POST_BATCHES_SUCCESS,
  POST_BATCHES_FAIL,
  CHECK_CALLS,
  CHECK_CALLS_SUCCESS,
  CHECK_CALLS_FAIL,
  GET_SIGNED_URLS_SUCCESS,
  GET_SIGNED_URLS_FAIL,
  GET_SIGNED_URLS,
  RESET_STATE_COMMON,
} from "./actionTypes"

export const resetStateCommon = () => ({
  type: RESET_STATE_COMMON,
})

// Add branch
export const postBatches = data => ({
  type: POST_BATCHES,
  payload: data,
})
export const postBatchesSuccess = data => ({
  type: POST_BATCHES_SUCCESS,
  payload: data,
})
export const postBatchesFail = error => ({
  type: POST_BATCHES_FAIL,
  payload: error,
})


export const checkCalls = data => ({
  type: CHECK_CALLS,
  data,
})
export const checkCallsSuccess = data => ({
  type: CHECK_CALLS_SUCCESS,
  payload: data,
})
export const checkCallsFail = error => ({
  type: CHECK_CALLS_FAIL,
  payload: error,
})


export const getSignedURLs = data => ({
  type: GET_SIGNED_URLS,
  data,
})
export const getSignedURLsSuccess = data => ({
  type: GET_SIGNED_URLS_SUCCESS,
  payload: data,
})
export const getSignedURLsFail = error => ({
  type: GET_SIGNED_URLS_FAIL,
  payload: error,
})
