import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  LIST_SERVICES,
  LIST_SERVICES_SUCCESS,
  LIST_SERVICES_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  services: {},
}

const Login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case LIST_SERVICES:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case LIST_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload
      }
    case LIST_SERVICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default Login
