import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportDownloadHms } from 'store/actions';
import { handleIncludedKeysError } from 'pages/HMS/common/common';
import { DownloadModal } from 'pages/HMS/common/hms-crud-modals';

const PreviewPDF = ({ buttonName = "DOWNLOAD REPORT", className, size = "xl", showIcon = true, apiEndpoint, apiStartKey }) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);

  const { loading, error, apiKey, base64Report } = useSelector(state => ({
    loading: state.Hms.loading,
    error: state.Hms.error,
    apiKey: state.Hms.apiKey,
    base64Report: state.Hms.base64Report,
  }))

  useEffect(() => {
    if (apiKey === `${apiStartKey}_download_report`) {
      setShowModal(true);
    }
    if (error && apiKey.startsWith(`fail_${apiStartKey}_download_report`)) {
      const includedKeys = ['detail']
      handleIncludedKeysError(error, apiKey, `fail_${apiStartKey}_download_report`, includedKeys, false)
    }
  }, [apiKey]);

  const fetchReport = () => {
    dispatch(reportDownloadHms(apiEndpoint, `${apiStartKey}_download_report`))
  }

  return (
    <>
      <div>
        {loading && apiKey === `get_${apiStartKey}_download_report` ? (
          <button type="button" className={className} disabled>
            {buttonName} {" "}<i className="bx bx-loader bx-spin align-middle ms-1"></i>
          </button>
        ) : (
          <button type="button" className={className} onClick={fetchReport}>
            {buttonName} {" "}{showIcon && <i className="mdi mdi-download align-middle ms-1"></i>}
          </button>
        )}
      </div>

      <DownloadModal
        modal={showModal}
        toggle={() => setShowModal(false)}
        apiStartKey={`${apiStartKey}_download_report`}
        size={size}
      />
    </>
  );
}

export default PreviewPDF;