import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_BRANCHES,
  ADD_BRANCH,
  UPDATE_BRANCH,
  DELETE_BRANCH,

  GET_UNAS_USERS,
  
  GET_BRANCH_GROUPS,
  GET_GROUP_DETAIL,
  ADD_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  ADD_USERS,
  REMOVE_USER_FROM_GROUP,
} from "./actionTypes"

import {
  getBranchesSuccess,
  getBranchesFail,

  addBranchSuccess,
  addBranchFail,

  updateBranchSuccess,
  updateBranchFail,

  deleteBranchSuccess,
  deleteBranchFail,

  getUnassignedGroupMembersSuccess,
  getUnassignedGroupMembersFail,

  getBranchGroupsSuccess,
  getBranchGroupsFail,

  getGroupDetailSuccess,
  getGroupDetailFail,

  addGroupSuccess,
  addGroupFail,

  updateGroupSuccess,
  updateGroupFail,

  deleteGroupSuccess,
  deleteGroupFail,
  addUsersSuccess,
  addUsersFail,
  removeGroupUserSuccess,
  removeGroupUserFail
} from "./actions"

import {
  getAllBranches,
  addNewBranch,
  updateABranch,
  deleteABranch,
  getAllUnassignedGroupMembers,
  getAllBranchGroups,
  getAGroupDetail,
  addNewGroup,
  updateAGroup,
  deleteAGroup,
  addUsersAPI,
  removeGroupUserAPI,
} from "./api_helper"

function* fetchBranches({data}) {
  try {
    const response = yield call(getAllBranches, data)
    yield put(getBranchesSuccess(response))
  } catch (error) {
    yield put(getBranchesFail(error.response.data))
  }
}
function* onAddNewBranch({ payload: branch }) {
  try {
    const response = yield call(addNewBranch, branch)
    yield put(addBranchSuccess(response))
  } catch (error) {
    yield put(addBranchFail(error.response.data))
  }
}
function* onUpdateBranch({ payload: branch }) {
  try {
    const response = yield call(updateABranch, branch)
    yield put(updateBranchSuccess(response))
  } catch (error) {
    yield put(updateBranchFail(error.response.data))
  }
}
function* onDeleteBranch({ payload: branch }) {
  try {
    yield call(deleteABranch, branch)
    yield put(deleteBranchSuccess(branch))
  } catch (error) {
    yield put(deleteBranchFail(error.response.data))
  }
}
function* fetchUnassignedGroupMembers({ data }) {
  try {
    const response = yield call(getAllUnassignedGroupMembers, data)
    yield put(getUnassignedGroupMembersSuccess(response))
  } catch (error) {
    yield put(getUnassignedGroupMembersFail(error.response.data))
  }
}
function* fetchBranchGroups({ data }) {
  try {
    const response = yield call(getAllBranchGroups, data)
    yield put(getBranchGroupsSuccess(response))
  } catch (error) {
    yield put(getBranchGroupsFail(error.response.data))
  }
}
function* fetchGroupDetail({ groupUid }) {
  try {
    const response = yield call(getAGroupDetail, groupUid)
    yield put(getGroupDetailSuccess(response))
  } catch (error) {
    yield put(getGroupDetailFail(error.response.data))
  }
}
function* onAddNewGroup({ payload: {branchID, group} }) {
  try {
    const response = yield call(addNewGroup, {branchID, group})
    yield put(addGroupSuccess(response))
  } catch (error) {
    yield put(addGroupFail(error.response.data))
  }
}
function* onUpdateGroup({ payload: {groupUid, data} }) {
  try {
    const response = yield call(updateAGroup, {groupUid, data})
    yield put(updateGroupSuccess(response))
  } catch (error) {
    yield put(updateGroupFail(error.response.data))
  }
}
function* onDeleteGroup({ payload: group }) {
  try {
    const response = yield call(deleteAGroup, group)
    yield put(deleteGroupSuccess(group))
  } catch (error) {
    yield put(deleteGroupFail(error.response.data))
  }
}
function* onAddUsers({groupUid, data}) {
  try {
    const response = yield call(addUsersAPI, {groupUid, data})
    yield put(addUsersSuccess(response))
  } catch (error) {
    yield put(addUsersFail(error.response.data))
  }
}
function* onRemoveGroupUser({groupUid, userID}) {
  try {
    const response = yield call(removeGroupUserAPI, {groupUid, userID})
    yield put(removeGroupUserSuccess(response))
  } catch (error) {
    yield put(removeGroupUserFail(error.response.data))
  }
}

function* manageSaga() {
  yield takeEvery(GET_BRANCHES, fetchBranches)
  yield takeEvery(ADD_BRANCH, onAddNewBranch)
  yield takeEvery(UPDATE_BRANCH, onUpdateBranch)
  yield takeEvery(DELETE_BRANCH, onDeleteBranch)

  yield takeEvery(GET_UNAS_USERS, fetchUnassignedGroupMembers)

  yield takeEvery(GET_BRANCH_GROUPS, fetchBranchGroups)
  yield takeEvery(GET_GROUP_DETAIL, fetchGroupDetail)
  yield takeEvery(ADD_GROUP, onAddNewGroup)
  yield takeEvery(UPDATE_GROUP, onUpdateGroup)
  yield takeEvery(DELETE_GROUP, onDeleteGroup)
  yield takeEvery(ADD_USERS, onAddUsers)
  yield takeLatest(REMOVE_USER_FROM_GROUP, onRemoveGroupUser)
}

export default manageSaga
