export const RESET_STATE_ACCOUNT = "RESET_STATE_ACCOUNT"

export const REGISTER_VERIFICATION = "REGISTER_VERIFICATION"
export const REGISTER_VERIFICATION_SUCCESSFUL = "REGISTER_VERIFICATION_SUCCESSFUL"
export const REGISTER_VERIFICATION_FAILED = "REGISTER_VERIFICATION_FAILED"

export const REGISTER_USER = "REGISTER_USER"
export const REGISTER_USER_SUCCESSFUL = "REGISTER_USER_SUCCESSFUL"
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED"

export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESSFUL = "ADD_USER_SUCCESSFUL"
export const ADD_USER_FAILED = "ADD_USER_FAILED"

export const MANUALSIGNUP_VERIFY = "MANUALSIGNUP_VERIFY"
export const MANUALSIGNUP_VERIFY_SUCCESSFUL = "MANUALSIGNUP_VERIFY_SUCCESSFUL"
export const MANUALSIGNUP_VERIFY_FAILED = "MANUALSIGNUP_VERIFY_FAILED"

export const MANUALSIGNUP_ACTIVATE = "MANUALSIGNUP_ACTIVATE"
export const MANUALSIGNUP_ACTIVATE_SUCCESSFUL = "MANUALSIGNUP_ACTIVATE_SUCCESSFUL"
export const MANUALSIGNUP_ACTIVATE_FAILED = "MANUALSIGNUP_ACTIVATE_FAILED"
