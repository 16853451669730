import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import { Alert, Button, Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import { manualSignupActivation as onManualSignupActivation, manualSignupActLinkVerify as onManualSignupActLinkVerify } from "store/actions"
import { useSelector, useDispatch } from "react-redux"
import { AvField, AvForm } from "availity-reactstrap-validation";
import { isEmpty } from "lodash";
import { hybridErrorAlertDisplay } from "components/Common/common";

const manualSignupActivation = (props) => {

  const dispatch = useDispatch()
  const [verified, setVerified] = useState(false)

  useEffect(() => {
    dispatch(onManualSignupActLinkVerify(props.match.params.token))
  }, [])

  const { loading, linkValidated, error, data, formError } = useSelector(state => ({
    loading: state.Account.loading,
    linkValidated: state.Account.linkValidated,
    error: state.Account.error,
    data: state.Account.data,
    formError: state.Account.formError,
  }))

  useEffect(() => {
    if (!isEmpty(data)) setVerified(true)
  }, [data])

  const handleSubmit = (event, values) => {
    const token = props.match.params.token
    let payload = {
      token: token,
      password: values.password,
      password2: values.password2
    }
    dispatch(onManualSignupActivation(payload))
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Email confirmation | Yanthura</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      {loading ?
                        <>
                          <div className="avatar-md mx-auto">
                            <Spinner className="mt-5 fs-1" color="secondary" />
                          </div>
                          <div className="p-2 mt-4">
                            <h4>Verifying Link...</h4>
                            <p className="text-muted">Please wait while we activate the verification link</p>
                          </div>
                        </>
                        :
                        <>
                          {error ?
                            <>
                              <div className="avatar-md mx-auto">
                                <div className="avatar-title rounded-circle bg-light">
                                  <i className="bx bx bx-error h1 mb-0 text-danger"></i>
                                </div>
                              </div>
                              <div className="p-2 mt-4">
                                <h4>In-valid Link</h4>
                                <p className="text-muted">{error && error.detail}</p>
                              </div>
                            </>
                            :
                            <>
                            {linkValidated &&
                              <div className="text-start">
                                <div className="p-2 mt-4">
                                  <h4>Set a password</h4>
                                  <Alert color="info">Do not refresh page untill you finish the verification</Alert>
                                  {formError &&
                                    hybridErrorAlertDisplay(formError)
                                  }
                                </div>
                                <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                                  <div className="mb-3">
                                    <AvField
                                      name="password"
                                      label="Password"
                                      type="password"
                                      required
                                      placeholder="Enter Password"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <AvField
                                      name="password2"
                                      label="Confirm password"
                                      type="password"
                                      required
                                      placeholder="Retype password"
                                    />
                                  </div>
                                  <div className="text-center mt-4">
                                    <Button color="primary" className="btn btn-primary" type="submit">Submit</Button>
                                  </div>
                                </AvForm>
                              </div>
                            }
                            {verified &&
                              <>
                                <div className="avatar-md mx-auto">
                                  <div className="avatar-title rounded-circle bg-light">
                                    <i className="bx bx bx-check h1 mb-0 text-success"></i>
                                  </div>
                                </div>
                                <div className="p-2 mt-4">
                                  <h4>Account activated</h4>
                                  <p className="text-muted">{data && data.detail}</p>
                                  <div className="mt-4">
                                    <Link to="/login" className="btn btn-primary">
                                      Go back to login
                                    </Link>
                                  </div>
                                </div>
                              </>
                            }
                            </>
                          }
                        </>
                      }
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {new Date().getFullYear()} © Yanthura. All rights reserved by <a href="https://yanthura.com" target='_blank'>yanthura</a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default manualSignupActivation
