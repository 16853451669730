import React from "react"
import { Badge, Card, CardBody, Progress } from "reactstrap"
import { isEmpty } from "lodash";


const ScoreCard = (props) => {
  const { loading, detailError, evaluationScores } = props

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {loading ?
            <p className="text-center mt-5"><i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Fetching scores...</p>
          :
            <>
              {detailError &&
                <p className="text-center text-danger"><i className="bx bx-error-circle search-icon" /> {detailError.detail}</p>
              }
              {(evaluationScores && !isEmpty(evaluationScores.score_by_kpis)) ? evaluationScores.score_by_kpis.map((kpi, idx) => (
                <div className="m-3" key={idx}>
                  <p className="mb-1">
                    {kpi.statement}
                    <Badge pill className="rounded-pill badge-soft-primary ms-1">
                      {kpi.score}
                    </Badge>
                  </p>
                  <Progress
                    value={kpi.score}
                    color={(kpi.score>0 && kpi.score<=40) ? "danger" : (kpi.score>40 && kpi.score<=75) ? "warning" : "success"  }
                    className="bg-transparent progress-sm"
                    size="sm"
                  />
                </div>
              ))
              :
              <p className="text-center text-muted">No scores existed for this call</p>
              }
            </>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default ScoreCard
